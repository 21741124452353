import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Locales from 'date-fns/locale';
import { DatePickerProps } from './DatePicker.types';

export type Languages = keyof typeof Locales;

export const DatePicker: FC<DatePickerProps> = ({
  id,
  value,
  onChange,
  minDate,
  maxDate,
  locale,
  placeholder,
  disabled,
  customButtonComponent,
  ...props
}) => {
  const { i18n } = useTranslation();
  const localeString = locale ? locale : (i18n?.language?.replace('-', '') as Languages);

  return (
    <ReactDatePicker
      id={id}
      // eslint-disable-next-line import/namespace
      locale={Locales[localeString]}
      dateFormat="P"
      selected={value ? new Date(value) : null}
      onChange={(date, event) => {
        event?.preventDefault();
        onChange(date ? format(date, 'yyyy-MM-dd') : null);
      }}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      customInput={customButtonComponent}
      minDate={minDate}
      maxDate={maxDate}
      placeholderText={placeholder || 'Select a Date'}
      showPopperArrow={false}
      disabled={disabled}
      autoComplete="off"
      {...props}
    />
  );
};
