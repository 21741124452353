import { Theme, ThemeUIStyleObject } from 'theme-ui';
import { buttons } from './buttons';

const commonFontStyle: ThemeUIStyleObject = {
  fontSize: 1,
  fontFamily: 'Inter',
};

export const commonErrorInput: ThemeUIStyleObject = {
  color: 'warning',
  borderWidth: 1,
  borderColor: 'warning',
  borderStyle: 'solid',
};

const commonFormInputStyles: ThemeUIStyleObject = {
  outline: 'none',
  width: '100%',
  minWidth: 8,
  height: 7,
  transition: 'base',
  backgroundColor: 'input',
  color: 'text',
  borderRadius: 4,
  borderWidth: 1,
  borderColor: 'input',
  borderStyle: 'solid',
  ...commonFontStyle,
  '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
    WebkitTextFillColor: 'white',
    boxShadow: 'inset 0 0 0 1000px var(--theme-ui-colors-input)',
  },
};

export const defaultCheckboxStyles: ThemeUIStyleObject = {
  color: 'text',
  'input:focus ~ &': {
    bg: 'transparent',
    color: 'text',
  },
  'input:checked ~ &': {
    bg: 'transparent',
    color: 'text',
  },
};
const option: ThemeUIStyleObject = {
  ...commonFontStyle,
  px: 3,
  py: 2,
  cursor: 'pointer',
  color: 'text',
  borderTopStyle: 'solid',
  borderTopColor: 'backgroundLight',
  borderTopWidth: 1,
  '&:hover': {
    bg: 'backgroundLight',
  },
};

const inputWithAction: ThemeUIStyleObject = {
  container: {
    width: '100%',
    flex: 1,
    position: 'relative',
  },
  inputContainer: {
    borderRadius: [0, 7],
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    height: ['auto', 9],
    flexDirection: ['column', 'row'],
    flex: 1,
    input: {
      pl: [3, 4],
      py: 0,
      height: 9,
      borderWidth: [3, 0],
      borderBottomLeftRadius: 7,
      borderTopLeftRadius: 7,
      fontSize: 4,
      fontFamily: 'MintGroteskV08',
      fontWeight: 'bold',
      color: 'white',
      outline: 'none',
      borderStyle: 'solid',
      borderColor: ['primary', 'transparent'],
      '&::placeholder': {
        color: 'text',
      },
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
        WebkitTextFillColor: 'white',
        boxShadow: [
          'inset 0 0 0 1000px var(--theme-ui-input-autofill-bg)',
          'inset 0 0 0 1000px var(--theme-ui-input-autofill-bg), 0 0 0 1000px var(--theme-ui-input-autofill-bg)',
        ],
      },
    },
    buttonContainer: {
      position: 'relative',
      flexShrink: 0,
      alignItems: 'center',
      mt: [3, 0],
    },
    innerLoadingContainer: { ml: 2, display: ['block', 'none'] },
  },
  suffix: { alignItems: 'center', position: 'absolute', right: 1, top: 0, bottom: 0 },
  actionButton: {
    cursor: 'pointer',
    height: 9,
    fontSize: 4,
    width: ['100%', 'auto'],
    bg: 'primary',
    color: 'background',
    fontFamily: 'MintGroteskV08',
    fontWeight: 'bold',
    px: 4,
    borderRadius: 7,
    transition: 'base',
    '&:enabled': {
      '&:hover, &:focus, &:active': {
        bg: 'primaryDark',
      },
    },
    '&:disabled': {
      opacity: 0.8,
      bg: 'primaryDarker',
      '&:hover, &:focus, &:active': {
        cursor: 'not-allowed',
      },
    },
  },
  borderedContainer: {
    borderRadius: 7,
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: ['transparent', 'primary'],
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    pointerEvents: 'none',
  },
  outerLoadingContainer: {
    display: ['none', 'flex'],
    flexDirection: 'row',
    alignItems: 'center',
    mr: -5,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    flexShrink: 0,
  },
};

export const forms: Theme['forms'] = {
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'light',
    color: 'text',
  },
  input: {
    ...commonFormInputStyles,
    '&:focus': {
      borderColor: 'text',
    },
    '&::placeholder': {
      color: 'text',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  textarea: {
    ...commonFormInputStyles,
    px: 3,
    height: 'auto',
    '&:focus': {
      outline: '0',
    },
    '&::placeholder': {
      color: 'textDarker',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  inputWithAction: {
    ...inputWithAction,
    LEM: {
      ...inputWithAction,
      container: {
        flexDirection: 'column',
      },
      inputContainer: {
        borderRadius: [0, 4],
        overflow: 'hidden',
        width: '100%',
        position: 'relative',
        height: ['auto', 9],
        flexDirection: ['column', 'row'],
        flex: 1,
        input: {
          pl: [3, 4],
          py: 0,
          height: 9,
          borderWidth: [3, 0],
          borderBottomLeftRadius: 4,
          borderTopLeftRadius: 4,
          borderBottomRightRadius: 4,
          borderTopRightRadius: 4,
          fontSize: 3,
          fontFamily: 'MintGroteskV08',
          fontWeight: 'medium',
          color: 'text',
          outline: 'none',
          borderStyle: 'solid',
          borderColor: ['primary', 'transparent'],
          '&::placeholder': {
            color: 'inputLightLight',
          },
        },
      },
      actionButton: {
        cursor: 'pointer',
        height: 9,
        fontSize: 4,
        width: ['100%', 'auto'],
        bg: 'primary',
        color: 'background',
        fontFamily: 'MintGroteskV08',
        fontWeight: 'medium',
        px: 4,
        borderRadius: 4,
        transition: 'base',
        '&:enabled': {
          '&:hover, &:focus, &:active': {
            bg: 'primaryDark',
          },
        },
        '&:disabled': {
          opacity: 0.8,
          bg: 'primaryDarker',
          '&:hover, &:focus, &:active': {
            cursor: 'not-allowed',
          },
        },
      },
      borderedContainer: {
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth: 3,
        borderColor: ['transparent', 'primary'],
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        pointerEvents: 'none',
      },
    },
  },
  switch: {
    m: 0,
    bg: 'highlight',
    'input:checked ~ &': {
      bg: 'highlight',
    },
    switchLabel: {
      letterSpacing: 2,
      fontSize: 0,
      transition: 'base',
      color: 'textDark',
      fontWeight: 'light',
      textTransform: 'uppercase',
    },
  },
  errorInput: {
    ...commonFormInputStyles,
    color: 'warning',
    pr: 4,
    '&:focus': {
      borderWidth: 1,
      borderColor: 'warning',
      borderStyle: 'solid',
    },
    '&::placeholder': {
      color: 'text',
    },
  },
  errorInputHighlighted: {
    ...commonFormInputStyles,
    ...commonErrorInput,
    '&::placeholder': {
      color: 'text',
    },
  },
  errorMessage: {
    color: 'warning',
    fontSize: 1,
  },
  checkboxLabel: {
    color: 'text',
    fontSize: 1,
    cursor: 'pointer',
  },
  checkbox: {
    ...defaultCheckboxStyles,
  },
  traceXCheckbox: {
    ...defaultCheckboxStyles,
    mr: 0,
  },
  select: {
    transition: 'base',
    control: {
      ...commonFormInputStyles,
    },
    placeholder: {
      ...commonFontStyle,
      color: 'text',
    },
    dropdownIndicator: {
      svg: { transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)' },
      p: 3,
      lineHeight: 0,
      color: 'text',
      '&:hover': {
        color: 'text',
      },
    },
    indicatorSeparator: {
      display: 'none',
    },
    optionActive: {
      ...option,
      bg: 'backgroundLighter',
      '&:hover': {
        bg: 'backgroundLight',
      },
    },
    optionDisabled: {
      ...option,
      bg: 'secondaryDark',
      '&:hover': {
        bg: 'backgroundDark',
      },
    },
    lem: {
      '&:focus, &:active': {
        outline: '1px solid',
        outlineColor: 'primary',
      },
    },
  },
  slider: {
    width: '100%',
    color: 'primary',
    bg: 'muted',
  },
  radio: {
    color: 'greyLight',
    '&:active': {
      color: 'primary',
    },
    '&:disabled': {
      color: 'muted',
    },
  },
  radioLabel: {
    fontSize: 1,
  },
  itemGroupLabel: {
    color: 'grey',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    pl: 3,
    fontSize: 1,
  },
};
