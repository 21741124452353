export enum StoreStatus {
  NOT_INITIALIZED = 'not_initialized',
  INITIALIZED = 'initialized',
}

export enum AsyncStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}
