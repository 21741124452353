import { memo } from 'react';
import { NoData } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { Table, TableLoadingOverlay } from '../..';
import { convertDatetimeToDate, convertDatetimeToTime } from '../../../lib/datetime-helpers';
import { BatterySharingEventsTableViewProps } from './battery-sharing-events-table.types';

const columns = (t: (text: string) => string) => [
  {
    title: t('START TIMESTAMP'),
    dataIndex: 'fromDateTime',
    key: 'fromDateTime',
    render: (record: string) =>
      record &&
      record !== 'Invalid date' && (
        <div>
          <strong>{convertDatetimeToDate(record)}</strong>
          <span> {convertDatetimeToTime(record)}</span>
        </div>
      ),
  },
  {
    title: t('END TIMESTAMP'),
    dataIndex: 'toDateTime',
    key: 'toDateTime',
    render: (record: string) =>
      record &&
      record !== 'Invalid date' && (
        <div>
          <strong>{convertDatetimeToDate(record)}</strong>
          <span> {convertDatetimeToTime(record)}</span>
        </div>
      ),
  },
  {
    title: t('EVENT TYPE'),
    dataIndex: 'eventType',
    key: 'eventType',
  },
  {
    title: t('EVENT STATUS'),
    dataIndex: 'eventStatus',
    key: 'eventStatus',
  },
  {
    title: t('POWER REQUESTED (kW)'),
    dataIndex: 'powerRequested',
    key: 'powerRequested',
  },
];

const BatterySharingEventsTableViewBase: React.FC<BatterySharingEventsTableViewProps> = ({
  data,
  isLoading = false,
  currentPageSize,
  tableData,
  handleChangePagination,
  showTotal,
}) => {
  const { t } = useTranslation();

  return (
    <Table
      locale={{
        emptyText: <NoData description={t('No events found')} />,
      }}
      columns={!isLoading && data.length ? columns(t) : []}
      dataSource={!isLoading ? tableData : []}
      loading={{
        spinning: isLoading,
        indicator: <TableLoadingOverlay active text={t('Loading events...')} color="text" />,
      }}
      pagination={{
        pageIndex: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSize: currentPageSize,
        pageSizeOptions: ['10', '25', '50', '100'],
        total: data.length,
        showTotal,
        onChange: handleChangePagination,
      }}
    />
  );
};

export const BatterySharingEventsTableView = memo(BatterySharingEventsTableViewBase);
