import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguageOverride = () => {
  const { i18n } = useTranslation();
  const browserIsEnglish = i18n.language.startsWith('en'); // English / English variant
  const [lngOverride, setLngOverride] = useState('');

  const toggleLngOverride = () => {
    if (lngOverride === 'en') {
      setLngOverride('');
    } else {
      setLngOverride('en');
    }
  };

  return { lngOverride, toggleLngOverride, browserIsEnglish };
};
