import { FC, ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  IconButton as IconButtonBase,
  IconButtonProps as IconButtonPropsBase,
  ThemeUIStyleObject,
} from 'theme-ui';
import { BasePalette } from '@power-ledger/styles';
import { Arrow, IconProps } from '../icons';

export { Button } from 'theme-ui';
export * from './ButtonGroup';

type SimpleButtonProps = {
  children: any;
  label?: string;
  to?: string;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
  variant?: string;
};

export const SimpleButton: FC<SimpleButtonProps> = ({ children, label, to, onClick, sx, ...props }) => {
  const history = useHistory();

  const variant = props.variant || 'action';

  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };
  return (
    <Box
      aria-label={label}
      variant={`buttons.${variant}`}
      sx={{
        appearance: 'none',
        background: 'transparent',
        color: 'inherit',
        border: 0,
        textAlign: 'center',
        lineHeight: 'inherit',
        textDecoration: 'none',
        fontSize: 'inherit',
        ...sx,
      }}
      as="button"
      onClick={handleOnClick}
      {...props}
    >
      {children}
    </Box>
  );
};

type IconButtonProps = {
  buttonProps?: IconButtonPropsBase;
  iconProps?: IconProps;
  label: string;
  color?: keyof BasePalette;
  icon: FC<IconProps>;
};

export const IconButton: FC<IconButtonProps> = ({
  icon: Icon,
  color = 'primary',
  label,
  iconProps = {},
  buttonProps = {},
}) => {
  const { sx: buttonStyles, ...buttonPropsFiltered } = buttonProps;
  return (
    <IconButtonBase
      aria-label={label}
      variant="buttons.icon"
      // @ts-ignore
      sx={{
        '& svg, & path': {
          color,
          fill: color,
        },
        '&:focus, &:hover': {
          '& svg, & path': {
            color: `${color}Darker`,
            fill: `${color}Darker`,
          },
        },
        ...(buttonStyles as any),
      }}
      {...buttonPropsFiltered}
    >
      <Icon {...iconProps} />
    </IconButtonBase>
  );
};

type ActionButtonProps = {
  children: any;
  label?: string;
  to?: string;
  onClick?: () => void;
  iconOnRight?: boolean;
  icon?: ReactNode;
  iconVariant?: string;
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
  type?: 'button' | 'submit' | 'reset';
};

export const ActionButton: FC<ActionButtonProps> = ({
  children,
  label,
  to,
  onClick,
  iconOnRight = false,
  icon,
  iconVariant = 'buttons.action.iconContainer',
  disabled = false,
  type,
  sx,
  ...props
}) => {
  const history = useHistory();

  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };
  const content = useMemo(() => {
    const boxMargin = iconOnRight ? { mr: 2 } : { ml: 2 };
    const iconContent = <Flex variant={iconVariant}>{icon || <Arrow size={4} color="text" />}</Flex>;
    const childrenContent = <Box sx={boxMargin}>{children}</Box>;
    return (
      <>
        {!iconOnRight && iconContent}
        {childrenContent}
        {iconOnRight && iconContent}
      </>
    );
  }, [icon, children, iconOnRight, iconVariant]);
  return (
    <Button
      aria-label={label}
      variant="buttons.action"
      type={type}
      sx={{
        appearance: 'none',
        background: 'transparent',
        color: 'inherit',
        border: 0,
        textAlign: 'center',
        lineHeight: 'inherit',
        textDecoration: 'none',
        fontSize: 'inherit',
        ...sx,
      }}
      disabled={disabled}
      onClick={handleOnClick}
      {...props}
    >
      {content}
    </Button>
  );
};

type BlockButtonProps = {
  id?: string;
  label?: string;
  displayName?: string;
  onClick?: () => void;
  active?: boolean;
  children: ReactNode;
  sx?: ThemeUIStyleObject;
};

export const BlockButton: FC<BlockButtonProps> = ({
  id,
  label,
  displayName,
  onClick,
  active,
  children,
  sx,
}) => (
  <Box
    role={'button'}
    aria-label={label}
    id={id}
    variant="buttons.block"
    sx={{
      borderColor: active ? 'secondaryLigher' : 'secondaryDarker',
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
    <Box
      sx={{
        variant: 'buttons.block.name',
      }}
    >
      {displayName}
    </Box>
  </Box>
);

type IconPillButtonProps = ButtonProps & {
  children: ReactNode;
  icon: ReactNode;
  onClick?: () => void;
  outlined?: boolean;
  small?: boolean;
  filled?: boolean;
  primary?: boolean;
};

export const IconPillButton: FC<IconPillButtonProps> = ({
  children,
  icon,
  onClick,
  outlined,
  small,
  filled,
  primary,
  ...props
}) => {
  const buttonVariant = useMemo(() => {
    if (primary) {
      if (outlined) return 'buttons.iconPill.primary.outlined';
      if (filled) return 'buttons.iconPill.primary.filled';
      if (small) return 'buttons.iconPill.primary.small';
      return 'buttons.iconPill.primary';
    }
    if (outlined) return 'buttons.iconPill.secondary.outlined';
    if (small) return 'buttons.iconPill.secondary.small';
    return 'buttons.iconPill.secondary';
  }, [primary, outlined, small, filled]);
  return (
    <Button variant={buttonVariant} onClick={onClick} {...props}>
      <Flex sx={{ alignItems: 'center' }}>
        {children}
        {icon}
      </Flex>
    </Button>
  );
};
