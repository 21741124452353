import { useEffect, useLayoutEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';
import { useAuthService } from '@power-ledger/auth-service';

import { useTranslation } from 'react-i18next';
import { FullScreenLoadingOverlay, TermsOfAccessOverlay } from '../components';
import { useAppDispatch } from '../hooks/use-app-dispatch';
import { useAppSelector } from '../hooks/use-app-selector';
import Main from '../layouts';
import { PublicRouter } from '../layouts/routes';
import { getMeterGroups } from '../middleware';
import { loadUserProfile } from '../states/slices/auth';
import { updateMeterGroup } from '../states/slices/meters';
import { useHostConfig } from '../contexts/host-config-context';
import { errorNotification } from '../lib/notifications';
import { isInLoginForm } from '../layouts/public/login/login-form';

export const AuthedRouteSelector = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { isAuthenticated, signOut } = useAuthService();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuthed = isAuthenticated();
  const authProfile = useAppSelector(({ auth }) => auth.profile, shallowEqual);
  const history = useHistory();
  const { tradingGroup } = useHostConfig(true);

  useLayoutEffect(() => {
    if (!isAuthed) return;

    const fetchProfile = async () => {
      await dispatch(loadUserProfile());
    };

    const fetchMeterGroup = async () => {
      const { body } = await getMeterGroups();

      await dispatch(updateMeterGroup(body[0]));
    };

    const init = async () => {
      setIsLoading(true);

      await Promise.all([fetchProfile(), fetchMeterGroup()]);

      setIsLoading(false);
    };

    init();
  }, [isAuthed, dispatch, history]);

  useEffect(() => {
    (async () => {
      if (authProfile && tradingGroup.ref !== authProfile.tradingGroupName) {
        await signOut();

        errorNotification({
          description: t("The credentials that you've entered are incorrect."),
        });
      }
    })();
  }, [authProfile, isAuthed, tradingGroup.ref, t, signOut]);

  if ((isLoading && !isInLoginForm) || (isAuthed && !authProfile)) return <FullScreenLoadingOverlay active />;

  if (isAuthed && authProfile && tradingGroup.ref === authProfile.tradingGroupName) {
    return (
      <TermsOfAccessOverlay>
        <Main />
      </TermsOfAccessOverlay>
    );
  }

  return <PublicRouter />;
};
