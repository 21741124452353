import { useEffect } from 'react';

import { shallowEqual } from 'react-redux';
import { useHostConfig } from '../contexts/host-config-context';
import i18nBase, { changeI18nLanguage } from '../lib/i18n';
import { StoreStatus } from '../../../types';
import { useNewUIEnabled } from './use-new-ui-enabled';
import { useAppSelector } from './use-app-selector';

export const useHostTranslation = () => {
  const { isLoading, hostHasFlag, config } = useHostConfig();
  const isNewUIEnabled = useNewUIEnabled();
  const meterGroupStatus: StoreStatus = useAppSelector(
    ({ meters: metersStore }) => metersStore?.meterGroupStatus,
    shallowEqual
  );
  const featureSetLoading = meterGroupStatus === StoreStatus.NOT_INITIALIZED;
  const { isInitialized, language } = i18nBase;

  useEffect(() => {
    if (isLoading || !config || !isInitialized || featureSetLoading) return;

    if (hostHasFlag('isTATA')) {
      changeI18nLanguage('en-TATA');
    }

    const isHostEstmkUsingOldUiInGermanLanguage =
      hostHasFlag('isESTMK') && !isNewUIEnabled && language.startsWith('de');

    if (isHostEstmkUsingOldUiInGermanLanguage) {
      changeI18nLanguage('de-ESTMK');
    }
  }, [config, isLoading, hostHasFlag, isNewUIEnabled, isInitialized, featureSetLoading, language]);
};
