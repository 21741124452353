import { useMemo, useRef } from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';

import { useElementSize } from '../../hooks/use-element-size';
import { useNewUIEnabled } from '../../hooks/use-new-ui-enabled';

export const siderWidth = 240;
export const mobileSiderHeight = 64;
export const defaultHeaderHeight = 152;

export interface LayoutProps {
  children?: React.ReactNode;
  sider?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  scrollDisabled?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  sider,
  header,
  footer,
  scrollDisabled = false,
}) => {
  const headerContainerRef = useRef<HTMLDivElement | null>(null);
  const isNewUIEnabled = useNewUIEnabled();

  const elementSize = useElementSize(headerContainerRef);
  const disableScrollStyles = useMemo<ThemeUIStyleObject>(
    () => (scrollDisabled ? { position: 'fixed', height: '100vh', width: '100vw' } : {}),
    [scrollDisabled]
  );

  return (
    <Box sx={{ height: '100%', paddingLeft: [0, null, siderWidth] }}>
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          ...disableScrollStyles,
        }}
      >
        <Box
          sx={{
            marginTop: elementSize.offsetHeight || 0,
            flex: 1,
            bg: isNewUIEnabled ? 'newUIMuted' : 'muted',
            p: [3, 4],
          }}
        >
          {children}
        </Box>
        {footer}
      </Flex>
      <Box
        ref={headerContainerRef}
        sx={{
          left: [0, null, siderWidth],
          right: 0,
          top: 0,
          zIndex: 11,
          position: 'fixed',
        }}
      >
        {header}
      </Box>
      <Box
        sx={{
          width: ['100%', null, siderWidth],
          left: 0,
          top: 0,
          right: [0, null, 'auto'],
          height: ['auto', null, '100vh'],
          position: 'fixed',
          zIndex: 12,
        }}
      >
        {sider}
      </Box>
    </Box>
  );
};
