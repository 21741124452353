import { initialState } from '../store/initial-state';

// Subpages reducer
const subpages = (state = initialState.subpages, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default subpages;
