import {
  AUTH_MFA_URL,
  AUTH_SERVER_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  DASH_API_URL,
  OKTA_ISSUER,
  OKTA_CLIENT_ID,
  OKTA_BASE_URL,
  SENTRY_RELEASE_ID,
} from '../lib/envHelpers';
import { convertToApiV2 } from './api-helpers';

let apiConfig = {
  DASH_API_URL,
  AUTH_SERVER_URL,
  AUTH_MFA_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  OKTA_ISSUER,
  OKTA_CLIENT_ID,
  OKTA_BASE_URL,
};

if (window.appConfig) {
  Object.assign(
    apiConfig,
    Object.fromEntries(Object.entries(window.appConfig).filter(([_, value]) => !!value))
  );
}

window.API_CONFIG = apiConfig;

export const BASE_URL = apiConfig.DASH_API_URL;
export { AUTH_SERVER_URL, AUTH_MFA_URL, CLIENT_ID, CLIENT_SECRET };

export const auth = {
  loginUserURL: `${BASE_URL}sessions/create`,
  acceptTermsURL: `${BASE_URL}user/update/username/`,
  resetPasswordURL: `${BASE_URL}user/reset-password/`,
  checkResetTokenURL: `${BASE_URL}user/check-reset/`,
  updatePasswordURL: `${BASE_URL}user/update-password`,
  profileURL: `${BASE_URL}user/profile`,

  // DashAPI endpoints
  mfaSettingsURL: `${BASE_URL}mfa/settings`,
  updateMobileURL: `${BASE_URL}mfa/setup/sms/`, // {mobile}
  verifyUserMFASMSURL: `${BASE_URL}mfa/setup/sms/verify/`, // {smsCode}
  resendMFASMSURL: `${BASE_URL}mfa/setup/sms/resend`,

  // AuthServer endpoints
  requestMFATokenURL: `${AUTH_MFA_URL}token/`,
  loginUserMFASMSURL: `${AUTH_MFA_URL}token/sms/login/`,
  initialUpdateMobileURL: `${AUTH_MFA_URL}token/sms/mobile/`, // for updating  mobile number prior to login
  initialVerifyUserMFASMSURL: `${AUTH_MFA_URL}token/sms/verify/`, //  for verifying  mobile number prior to login
  initialResendMFASMSURL: `${AUTH_MFA_URL}token/sms/resend`, //  for resending verification code prior to login
};

export const dashboard = {
  groupURL: `${BASE_URL}group`,
  siteWeatherURL: `${BASE_URL}dashboard/weather`,
  meterGroupsURL: `${BASE_URL}group/get`,
  metersURL: `${BASE_URL}group`,
  userAccountsURL: `${BASE_URL}user/accounts`,
  billingPeriodsURL: `${BASE_URL}billing/billing-period`,
  funfactsURL: `${BASE_URL}dashboard/fun-facts`,
  transactionSummaryURL: `${BASE_URL}transactions/summary/group/`,
  transactionsListURL: `${BASE_URL}transactions/group/`,
  groupTransactionsListURL: `${BASE_URL}transactions/daily/summary/group/`,
  meterRankURL: `${BASE_URL}dashboard/ranking/summary/group/`,
};

export const transactions = {
  indexURL: `${BASE_URL}transactions/`,
  aggregateURL: `${BASE_URL}transactions/aggregate`,
  statisticsURL: `${BASE_URL}transactions/statistics`,
  totalsURL: `${BASE_URL}transactions/total-stats/`,
  transactionsPageURL: `${BASE_URL}transactions/page`,
  leaderboardURL: `${BASE_URL}transactions/leaderboard`,
  searchURL: `${BASE_URL}transactions/search`,
};

export const energyMix = {
  statisticsURL: `${BASE_URL}usages/statistics/energymix`,
};

export const usage = {
  indexURL: `${BASE_URL}usage/`,
  statisticsURL: `${BASE_URL}usages/statistics`,
  readingsURL: `${BASE_URL}reading/group/`,
};

export const billing = {
  exportCSVURL: `${BASE_URL}billing/export-csv`,
  downloadCSVURL: `${BASE_URL}billing/download-csv/group/`,
  downloadStatementURL: `${BASE_URL}billing/download-statement`,
};

export const invoices = {
  searchInvoicesURL: `${BASE_URL}invoices/search`,
  downloadInvoiceURL: `${BASE_URL}invoices/{invoiceNumber}/generate-download-link`,
};

export const vpp = {
  vppConfigURL: `${BASE_URL}vpp/config/group/`,
  forecastPricesURL: `${BASE_URL}vpp/pricing/`,
  vppEventsURL: `${BASE_URL}vpp/vpp-events/`,
  dermEventsURL: `${BASE_URL}vpp/derms-events/`,
  dermEventsCommandURL: `${BASE_URL}vpp/derms-events/commands/`,
  meterBatteryURL: `${BASE_URL}vpp/batteries/meter/`,
  meterBatteriesURL: `${BASE_URL}vpp/batteries`,
  clusterSummaryURL: `${BASE_URL}vpp/cluster`,
  transactionsURL: `${BASE_URL}vpp/transactions/aggregate`,
  eventRecordsURL: `${BASE_URL}vpp/events/group/`,
};

export const pricing = {
  groupURL: `${BASE_URL}pricing/group/`,
  meterURL: `${BASE_URL}pricing/meter/`,
  updateRatesURL: `${BASE_URL}pricing/update`,
};

export const prefTrade = {
  ppaURL: `${BASE_URL}ppa`,
};

export const blockchain = {
  transactionURL: `${BASE_URL}blockchain/transaction/`,
  transferURL: `${BASE_URL}blockchain/transfer/`,
};

export const account = {
  indexURL: `${BASE_URL}user/account`,
  updateCurrentPasswordURL: `${BASE_URL}user/change-password`,
  // updateMobileURL: BASE_URL + 'user/mobile/'
};

export const notifications = {
  notificationsURL: `${BASE_URL}notifications`,
  scheduledNotificationsURL: `${BASE_URL}scheduled-notifications`,
};

export const themes = {
  themeURL: `${BASE_URL}themes/`,
};

export const projectState = {
  projectStateURL: `${BASE_URL}group/project-state/`,
};

export const rankings = {
  rankingsURL: `${BASE_URL}rankings`,
};

export const communityMap = {
  communityMapURL: `${BASE_URL}energy-community`,
};

export const ioaManagement = {
  uploadDayAheadReadingsURL: `${BASE_URL}reading/upload-day-ahead`,
  uploadRealtimeReadingsURL: `${BASE_URL}reading/upload-real-time`,
  downloadReadingTemplates: `${BASE_URL}reading/download-templates`,
};

export const aggregatedExcess = {
  getDayAheadAggregatedExcessURL: `${BASE_URL}aggregated-excess/day-ahead`,
  getRealtimeAggregatedExcessURL: `${BASE_URL}aggregated-excess/real-time`,
};

export const loyaltyP2P = (campaignId) => ({
  userCampaignsURL: `${BASE_URL}loyalty-campaigns/user-campaigns`,
  campaignsURL: `${BASE_URL}loyalty-campaigns`,
  metersURL: `${BASE_URL}loyalty-campaigns/meters`,
  createCampaignURL: `${BASE_URL}loyalty-campaigns`,
  updateCampaignURL: `${BASE_URL}loyalty-campaigns/${campaignId}`,
  updateCampaignMaxNumberOfParticipantsURL: `${BASE_URL}loyalty-campaigns/${campaignId}/update-max-number-of-participants`,
  vouchersURL: `${BASE_URL}loyalty-campaigns/vouchers`,
  meterEventURL: `${BASE_URL}loyalty-campaigns/${campaignId}/meter-events`,
  userVouchersURL: `${BASE_URL}loyalty-campaigns/vouchers`,
});

export const okta = {
  oktaBaseUrl: apiConfig.OKTA_BASE_URL,
  oktaIssuerUrl: apiConfig.OKTA_ISSUER,
  oktaClientId: apiConfig.OKTA_CLIENT_ID,
};

export const hostConfig = {
  hostConfigEndpoint: apiConfig.HOST_CONFIG_ENDPOINT,
};

export const sentryConfig = {
  dsn: apiConfig.SENTRY_DSN,
  env: apiConfig.SENTRY_ENV,
  release: SENTRY_RELEASE_ID,
};
