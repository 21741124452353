import { UPDATE_PREF_TRADE_OFFERS, RESET_PREF_TRADE_OFFERS } from '../constants';
import { initialState } from '../store/initial-state';

// Pref trade reducer
const prefTrade = (state = initialState.prefTrade, action) => {
  switch (action.type) {
    case UPDATE_PREF_TRADE_OFFERS:
      return {
        ...state,
        offers: action.offers,
        totalOffers: action.totalOffers,
      };
    case RESET_PREF_TRADE_OFFERS:
      return initialState.prefTrade;
    default:
      return state;
  }
};

export default prefTrade;
