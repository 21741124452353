import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { sortItems } from '../../lib/helpers';
import { StoreStatus } from '../../../../types';
import i18n from '../../lib/i18n';
import { successNotification, errorNotification, successRegex } from '../../lib/notifications';
import {
  getAdminSearchMeters,
  getMeterGroups,
  getSearchMeters as getSearchMetersApi,
  getUserMeters,
  updateDisplayName,
} from '../../middleware/api';

const getDefaultMeter = () => {
  return {
    id: 0,
    displayName: '',
    accountNumber: '',
  };
};

const formatMeterGroupPayload = (meterGroup, meters = null) => {
  if (!Array.isArray(meters)) {
    return {
      payload: {
        updatedMeterGroup: { ...meterGroup },
      },
    };
  }

  const filteredMeters =
    meterGroup && meterGroup.id ? meters.filter((val) => meterGroup.id === val.tradingGroupId) : meters;

  const sortedMeters = sortItems(filteredMeters, 'displayName');
  const meter = sortedMeters.length ? sortedMeters[0] : { uid: '', displayName: '' };

  const updatedMeterGroup = {
    ...meterGroup,
    ...(meterGroup && !meterGroup.timeZone ? { timeZone: 'UTC' } : {}),
    ...(meterGroup && !meterGroup.settlementInterval ? { settlementInterval: 900 } : {}),
    ...(meterGroup && !meterGroup.features ? { features: [] } : {}),
    ...(meterGroup && !meterGroup.peakOffPeakPeriods
      ? { peakOffPeakPeriods: { peak: null, offPeak: null } }
      : {}),
  };

  return {
    payload: {
      updatedMeterGroup,
      meter,
      meters: sortedMeters,
    },
  };
};

const formatMetersPayload = (meters) => {
  if (!meters) {
    return {
      payload: {
        meter: getDefaultMeter(),
        meters: [],
      },
    };
  }

  const sortedMeters = sortItems([...meters], 'displayName');
  const meter = sortedMeters?.length ? sortedMeters[0] : getDefaultMeter();

  return {
    payload: {
      meter,
      meters: sortedMeters,
    },
  };
};

export const getMeterGroup = createAsyncThunk('meters/getMeterGroup', async (_, { rejectWithValue }) => {
  const { status, body } = await getMeterGroups();

  if (successRegex.test(status) && Array.isArray(body)) {
    return formatMeterGroupPayload(body[0]).payload;
  }

  return rejectWithValue({ status, body });
});

export const updateMeterDisplayName = async (props, token = null) => {
  const { status, body } = await updateDisplayName(props, token);

  let results = { status, content: [] };

  if (successRegex.test(status) && body) {
    results = {
      status,
      ...{ content: body.results || [] },
    };
    successNotification({
      message: i18n.t('Display name has been updated!'),
      description: i18n.t('Display name has been updated!'),
    });
  } else if (status !== 499) {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not update meter display name at this time!'),
    });
  }

  return results;
};

export const getAllUserMeters = createAsyncThunk(
  'meters/getMeters',
  async ({ groupName, token = null }, { rejectWithValue }) => {
    const { status, body } = await getUserMeters(groupName, token);

    if (successRegex.test(status) && body) {
      return body;
    }
    return rejectWithValue({ status, body });
  }
);

export const getMeters = createAsyncThunk(
  'meters/getMeters',
  async ({ props, token = null }, { rejectWithValue }) => {
    const request = { searchTerm: '', size: 50, ...props };
    const { status, body } = await getAdminSearchMeters(request, token);

    if (successRegex.test(status) && body) {
      const results = (body.content || []).map((val) => ({
        uid: val.meterUid,
        displayName: val.meterDisplayName,
        assetType: val.meterAssetType,
        accountNumber: val.accountNumber,
      }));

      const sortedMeters = sortItems(results, 'displayName');
      const meter = sortedMeters.length ? sortedMeters[0] : { uid: '', displayName: '' };

      return {
        meter,
        meters: sortedMeters,
      };
    }
    return rejectWithValue({ status, body });
  }
);

export const getSearchMeters = async (props, isAdmin, token = null) => {
  const { status, body } = isAdmin
    ? await getAdminSearchMeters(props, token)
    : await getSearchMetersApi(props, token);

  let results = { status, content: [] };

  if (successRegex.test(status) && body) {
    results = {
      status,
      ...(isAdmin ? { content: body.content || [] } : { content: body.results || [] }),
    };
  } else if (status !== 499) {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not search for meters at this time!'),
    });
  }

  return results;
};

const metersSlice = createSlice({
  name: 'meters',
  initialState: {
    meterGroup: {
      name: '',
      timeZone: '',
      code: '',
      symbol: '',
      centSymbol: '',
      sparkzConversionRate: null,
      peakOffPeakPeriods: {
        peak: null,
        offPeak: null,
      },
      features: [],
    },
    meter: getDefaultMeter(),
    meters: [],
    metersStatus: StoreStatus.NOT_INITIALIZED,
    meterGroupStatus: StoreStatus.NOT_INITIALIZED,
  },
  reducers: {
    updateMeters: {
      prepare: formatMetersPayload,
      reducer: (state, { payload: { meter, meters } }) => {
        state.meter = meter;
        state.meters = meters;
        state.metersStatus = StoreStatus.INITIALIZED;
      },
    },

    updateMeterGroup: {
      prepare: formatMeterGroupPayload,
      reducer(state, { payload: { updatedMeterGroup, meter, meters } }) {
        if (updatedMeterGroup) {
          state.meterGroup = updatedMeterGroup;
          state.meterGroupStatus = StoreStatus.INITIALIZED;
        }
        if (meter) state.meter = meter;
        if (meters) state.meters = meters;
      },
    },
  },

  extraReducers: {
    [getMeterGroup.fulfilled]: (state, { payload: { updatedMeterGroup, meter, meters } }) => {
      if (updatedMeterGroup) {
        state.meterGroup = updatedMeterGroup;
        state.meterGroupStatus = StoreStatus.INITIALIZED;
      }
      if (meter) state.meter = meter;
      if (meters) {
        state.meters = meters;
        state.metersStatus = StoreStatus.INITIALIZED;
      }
    },
    [getMeterGroup.rejected]: (state, { payload: { status, body } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve trading group at this time!'),
      });
    },
    [getAllUserMeters.fulfilled]: (state, { payload: { meters } }) => {
      if (meters) state.meters = meters;
      state.metersStatus = StoreStatus.INITIALIZED;
    },
    [getAllUserMeters.rejected]: (state, { payload: { status, body } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve meters at this time!'),
      });
    },
    [getMeters.fulfilled]: (state, { payload: { meter, meters } }) => {
      state.meter = meter;
      state.meters = meters;
      state.metersStatus = StoreStatus.INITIALIZED;
    },
    [getMeters.rejected]: (state, { payload: { status, body } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve meters at this time!'),
      });
    },
  },
});

export const { updateMeters, updateMeterGroup } = metersSlice.actions;

export default metersSlice.reducer;
