import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { HostConfigContext } from '../../contexts/host-config-context';

export const DocumentHead = () => {
  const ctx = useContext(HostConfigContext);
  const appTitle = ctx?.tradingGroup?.name || 'Powerledger';

  return (
    <Helmet>
      <title>{appTitle}</title>
    </Helmet>
  );
};
