import { memo } from 'react';
import { Box, Text } from 'theme-ui';
import { LogoPlDeviceSVGLazy } from '@power-ledger/assets';

import { LoadingOverlayViewProps } from './loading-overlay.types';

const LoadingOverlayViewBase: React.FC<LoadingOverlayViewProps> = ({
  text,
  spinner = <LogoPlDeviceSVGLazy />,
  active,
  color = 'primary',
  sx,
}) => (
  <Box
    sx={{
      display: active ? 'flex' : 'none',
      alignItems: 'center',
      justifyContent: 'center',
      gap: text ? 1 : 0,
      ...sx,
    }}
    data-testid="loading-spinner"
  >
    <span data-testid="loading-spinner-icon">{spinner}</span>
    <Text color={'color'} sx={{ fontSize: 3 }}>
      {text}
    </Text>
  </Box>
);

export const LoadingOverlayView = memo(LoadingOverlayViewBase);
