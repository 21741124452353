/* eslint-disable */
import axios from 'axios';

import { IS_TEST, NODE_ENV } from '../lib/envHelpers';
import { AUTH_SERVER_URL, CLIENT_ID, CLIENT_SECRET } from './endpoints';

const envMode = NODE_ENV;
const isTest = IS_TEST;

export const getAccessToken = () => {
  if (oktaService) {
    return oktaService.getAccessToken();
  }
  return '';
};

const writeParam = (key, value) => `${key}=${encodeURIComponent(value)}`;

export const stringify = (params) =>
  `${Object.entries(params)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((element) => writeParam(key, element)).join('&')
        : writeParam(key, value)
    )
    .join('&')}`;

// custom Axios request for authentication server to handle login
// uses a set of CLIENT_ID and CLIENT_SECRET based on the environment config
const authServerRequest = (data) => {
  const { username, password } = data;

  // encode client id and secret values together to pass as an authorization for the auth server
  const clientToken = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);

  return axios({
    method: 'POST',
    url: AUTH_SERVER_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${clientToken}`,
    },
    data: stringify({
      username,
      password,
      grant_type: 'password',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
  })
    .then((response) => ({ body: response.data, status: response.status }))
    .catch((error) => {
      let body = { error: undefined };
      let status = 500;

      if (axios.isCancel(error)) {
        status = 499; // Client closed request
      } else if (error.response && error.response.data && error.response.status) {
        body = error.response.data;
        status = error.response.status === 401 ? error.response.status : 400;
      }

      return { body, status };
    });
};

/**
 * @note authenticated param is now deprecated and injected using axios interceptors
 * apps/xgrid/src/app/lib/axios-config.tsx
 * @returns
 */
// standard request to handle any endpoints to connect to data services
const request = (method, url, authenticated, data = {}, timeout = 180000, options = {}) => {
  // get the token to cancel the Axios request if the user decides to abort
  // delete token from body params immediately after assigning the cancel token
  // token.token because the Axios CancelToken is formatted this way.
  const cancelToken = data.token ? data.token.token : null;
  delete data.token;

  // if URL is login, use a custom Axios request to connect to auth server instead of dashboard api
  // auth-server should only be used for local, development and production modes
  if (!isTest && envMode !== 'test' && url.indexOf('sessions/create') !== -1) {
    return authServerRequest(data);
  }

  // to handle certain scenarios where a request body is just an array
  let requestData = data.arrayData && Array.isArray(data.arrayData) ? data.arrayData : data;

  return axios({
    timeout, // 3 minutes before request aborts
    method,
    url,
    cancelToken,
    responseType: url.indexOf('download') !== -1 && url.indexOf('download-link') === -1 ? 'blob' : '',
    headers: {
      'Content-Type': 'application/json',
    },
    data: requestData,
    ...options,
  })
    .then((response) => ({ body: response.data, status: response.status }))
    .catch((error) => {
      let body = { error: undefined };
      let status = 500;

      // if request has been intentionally cancelled, return 499 status code with no message
      if (axios.isCancel(error)) {
        status = 499; // Client Closed Request
      } else if (error.response) {
        // if the condition passes, can confirm the error is valid from the data services
        // otherwise, default to 'Unknown Server Error' on timeout or invalid request
        if (error.response.data) body = error.response.data;
        if (error.response.status) status = error.response.status;
      } else if (error.code === 'ECONNABORTED') {
        status = 408;
      }

      return { body, status };
    });
};

export const GET = (endpoint, authenticated, token = null, timeout = 180000, options = {}) =>
  request('GET', endpoint, authenticated, { token }, timeout, options);

export const POST = (endpoint, authenticated, body, authToken = '') =>
  request('POST', endpoint, authenticated, body, authToken);

export const PUT = (endpoint, authenticated, body) => request('PUT', endpoint, authenticated, body);

export const DELETE = (endpoint, authenticated, body) => request('DELETE', endpoint, authenticated, body);
