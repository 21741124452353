import { Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';

export const SortByContainer: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ flexDirection: 'column', '& > *': { my: 3 } }}>
      {t('Sort by')}
      {children}
    </Flex>
  );
};
