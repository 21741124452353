import { useFeatureSet } from '../../../hooks/use-feature-set';
import { TransactionType } from '../../../../../types/transactions';

const TRANSACTION_FEES = [
  TransactionType.PER_DAY_FEE_AH,
  TransactionType.PER_DAY_FEE_OTHER,
  TransactionType.PER_DAY_FEE_PL,
  TransactionType.PER_WH_FEE_AH,
  TransactionType.PER_WH_FEE_OTHER,
  TransactionType.PER_WH_FEE_PL,
];

export const useForbiddenTransactionTypes = () => {
  const { hasVPP, hasRenewables, hasP2P } = useFeatureSet();

  return [
    ...TRANSACTION_FEES,
    ...(hasP2P ? [] : ['P2P', 'DYNAMIC']),
    ...(hasRenewables ? [] : ['ALLOCATION']),
    ...(hasVPP ? [] : ['VPP']),
  ];
};
