import styled from '@emotion/styled';
import { variant as styledSystemVariant } from 'styled-system';
import { Box, Flex, css } from 'theme-ui';
/** @jsxImportSource theme-ui */
import { Children, FC, isValidElement, ReactElement } from 'react';

export type TabPaneProps = { key: string; tab?: string };
export type TabPaneType = FC<TabPaneProps>;

const StyledTab = styled.div<{
  active?: boolean;
  variant: string;
  rightMost?: boolean;
}>`
  ${styledSystemVariant({
    scale: 'tabs',
    variants: {
      primary: {},
      secondary: {},
    },
  })}
  ${({ theme, active, variant }) =>
    active && (theme as any).tabs[variant] && css((theme as any).tabs[variant].active)}
  ${({ rightMost }) => rightMost && css({ borderRight: 0 })}
`;

const StyledTabPane = styled.div<{ variant: string }>`
  ${styledSystemVariant({
    scale: 'tabs.panes',
    variants: {
      primary: {},
      secondary: {},
    },
  })}
`;

export type TabsProps = {
  activeKey?: string;
  variant?: string;
  onChange?: any;
};

export type TabsType = TabsProps & {
  children?: ReactElement<TabPaneProps> | ReactElement<TabPaneProps>[];
  TabPane?: TabPaneType;
};

export const Tabs = ({ children = [], variant = 'primary', activeKey = '', onChange }: TabsType) => {
  let activeChild = null;
  Children.forEach(children, (child) => {
    if (child?.key === activeKey) activeChild = child;
  });

  return (
    <Box>
      <Flex sx={{ alignItems: 'center', mb: 3 }} data-testid="tabs">
        {Children.map(children, (child, idx) => {
          if (isValidElement(child)) {
            return (
              <StyledTab
                variant={variant}
                active={activeKey === child.key}
                key={child.key}
                onClick={() => onChange(child.key)}
                rightMost={idx === (children as []).length - 1}
                data-testid={`tab-${child.key}`}
              >
                {child.props.tab || 'Tab Title'}
              </StyledTab>
            );
          }
          return null;
        })}
      </Flex>
      <StyledTabPane variant={variant}>{activeChild}</StyledTabPane>
    </Box>
  );
};

const TabPane: TabPaneType = ({ children }) => <Box sx={{ variant: 'tabs.tabPanes' }}>{children}</Box>;

Tabs.TabPane = TabPane;
