import { memo, useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { LegendProps } from '@nivo/legends';
import { Box } from 'theme-ui';

import { safelyParseNumber } from '../../../../utils/number';
import { TransactionPieChartStatisticsViewProps } from './pie-chart-statistics.types';

const legendOptions: LegendProps[] = [
  {
    anchor: 'bottom',
    direction: 'row',
    justify: false,
    translateX: 0,
    translateY: 24,
    itemsSpacing: 0,
    itemWidth: 100,
    itemHeight: 18,
    itemTextColor: '#999',
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    symbolSize: 18,
    symbolShape: 'circle',
    effects: [
      {
        on: 'hover',
        style: {
          itemTextColor: '#000',
        },
      },
    ],
  },
];

const TransactionPieChartStatisticsViewBase: React.FC<TransactionPieChartStatisticsViewProps> = ({
  normalizedPieChartData,
  chartColor,
  showLegends = false, // We don't display legends in the pie chart for now
}) => {
  const shouldShowPieChart = useMemo(
    () => normalizedPieChartData.some((data) => safelyParseNumber(data.value) > 0),
    [normalizedPieChartData]
  );

  if (!shouldShowPieChart) return null;

  // This is the workaround solution for unable to use flex wrap on a nivo responsive pie
  return (
    <Box sx={{ position: 'relative', width: ['300px', '400px'], height: ['300px', '400px'] }}>
      <Box sx={{ position: 'absolute', width: ['300px', '400px'], height: ['300px', '400px'] }}>
        <ResponsivePie
          data={normalizedPieChartData}
          margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          valueFormat="~%"
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          enableArcLinkLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#ffffff"
          colors={chartColor}
          legends={showLegends ? legendOptions : undefined}
        />
      </Box>
    </Box>
  );
};

export const TransactionPieChartStatisticsView = memo(TransactionPieChartStatisticsViewBase);
