import { UPDATE_BILLING_PERIODS, RESET_BILLING, UPDATE_INVOICES, RESET_INVOICES } from '../constants';
import { initialState } from '../store/initial-state';

// Billing reducer
const billing = (state = initialState.billing, action) => {
  switch (action.type) {
    case UPDATE_BILLING_PERIODS:
      return {
        ...state,
        billingPeriods: action.billingPeriods || [],
      };
    case RESET_BILLING:
      return initialState.billing;
    case UPDATE_INVOICES:
      return {
        ...state,
        invoices: action.invoices || [],
      };
    case RESET_INVOICES:
      return initialState.billing;
    default:
      return state;
  }
};

export default billing;
