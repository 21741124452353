import React, { useEffect, useState } from 'react';
import { useAuthService } from '@power-ledger/auth-service';
import axios from 'axios';

import { useHostConfig } from '../contexts/host-config-context';

interface AxiosConfigProps {
  children: JSX.Element;
}

export const AxiosConfig: React.FC<AxiosConfigProps> = ({ children }) => {
  const { config: hostConfig } = useHostConfig();
  const { getTokens } = useAuthService();
  const [isInstrumented, setIsInstrumented] = useState(false);

  useEffect(() => {
    const id = axios.interceptors.request.use(async (config) => {
      const tokens = await getTokens();

      // Enforce auth to only API requests
      const isAPI = hostConfig && config.url && config.url.includes(hostConfig.apiEndpoint);

      if (tokens && isAPI) {
        config.headers = {
          ...(config.headers || {}),
          Authorization: `Bearer ${tokens.accessToken}`,
        };
      }

      return config;
    });

    setIsInstrumented(true);

    return () => {
      setIsInstrumented(false);
      axios.interceptors.request.eject(id);
    };
  }, [getTokens, hostConfig, setIsInstrumented]);

  return isInstrumented ? children || null : null;
};
