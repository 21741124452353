import { useCallback } from 'react';

import { useHostConfig } from '../contexts/host-config-context';

/**
 * This function use's Host Specific code and should avoided
 * in new implementations or further refactoring
 * @deprecated
 */
export const useHostSpecificFn = () => {
  const { tradingGroup } = useHostConfig();
  const hostName = tradingGroup ? tradingGroup.ref : null;

  const getHostCurrency = useCallback(
    (symbol: string, code: string) => {
      let currency = `${symbol} ${code}`;

      if (hostName?.toLowerCase() === 'seda' || symbol === code) {
        currency = symbol;
      }

      return currency;
    },

    [hostName]
  );

  const isHostAmerican = useCallback(() => hostName?.toLocaleLowerCase() === 'apn', [hostName]);

  const isHostRenewNexus = useCallback(() => hostName?.toLocaleLowerCase() === 'renewnexus', [hostName]);

  const isHostWongan = useCallback(() => hostName?.toLocaleLowerCase() === 'wonganhills', [hostName]);

  /**
   * These functions use's Host Specific code and should avoided
   * in new implementations or further refactoring
   * @deprecated
   */
  return {
    /**
     * This functions use's Host Specific code and should avoided
     * in new implementations or further refactoring
     * @deprecated
     */
    getHostCurrency,
    /**
     * This functions use's Host Specific code and should avoided
     * in new implementations or further refactoring
     * @deprecated
     */
    isHostAmerican,
    /**
     * This functions use's Host Specific code and should avoided
     * in new implementations or further refactoring
     * @deprecated
     */
    isHostRenewNexus,
    /**
     * This functions use's Host Specific code and should avoided
     * in new implementations or further refactoring
     * @deprecated
     */
    isHostWongan,
  };
};
