import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, Flex, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { Modal, LoadingOverlay, Button } from '..';
import { getOfferUserDecisions, signOffer } from '../../states/actions/pref-trade';
import { getSignType } from '../../states/selectors/pref-trade';
import { asyncLoad } from '../../lib/helpers';
import { registerUserEvent } from '../../lib/auth-helpers';
import { withTranslation } from '../../hocs/with-translation';
import { COLOR_BY_STATUS, niceStatusTransMap } from '../../services';
import { StatusBadge } from '../status-badge';

const acceptEvent = { signingType: 'ACCEPT', agreed: true };
const declineEvent = { signingType: 'ACCEPT', agreed: false };
const withdrawEvent = { signingType: 'CANCEL', agreed: true, isSeller: true };
const cancelEvent = { signingType: 'CANCEL', agreed: true };

const loadingMessageTransMap = (t) => ({
  init: t('Loading offer details...'),
  update: t('Updating offer'),
  accept: t('Accepting offer'),
  decline: t('Declining offer'),
  withdraw: t('Withdrawing offer'),
  cancel: t('Cancelling offer'),
});

const AcceptOrDeclineAction = ({ isSubmitting, submitForm }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        variant="outlined"
        color="primary"
        disabled={isSubmitting}
        onClick={() => submitForm(declineEvent)}
        sx={{ marginRight: 1 }}
      >
        <Text>{t('Decline')}</Text>
      </Button>
      <Button disabled={isSubmitting} onClick={() => submitForm(acceptEvent)}>
        <Text>{t('Accept')}</Text>
      </Button>
    </Box>
  );
};

const WithdrawAction = ({ isSubmitting, submitForm }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Button disabled={isSubmitting} onClick={() => submitForm(withdrawEvent)}>
        <Text>{t('Withdraw')}</Text>
      </Button>
    </Box>
  );
};

const CancelAction = ({ isSubmitting, submitForm }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Button disabled={isSubmitting} onClick={() => submitForm(cancelEvent)}>
        <Text>{t('Cancel offer')}</Text>
      </Button>
    </Box>
  );
};

const UserActions = ({ isSubmitting, userAction, submitForm }) => {
  switch (userAction) {
    case 'ACCEPT_OR_DECLINE':
      return <AcceptOrDeclineAction isSubmitting={isSubmitting} submitForm={submitForm} />;
    case 'WITHDRAW':
      return <WithdrawAction isSubmitting={isSubmitting} submitForm={submitForm} />;
    case 'CANCEL':
      return <CancelAction isSubmitting={isSubmitting} submitForm={submitForm} />;
    default:
      return null;
  }
};

const DetailsRow = ({ title, info }) => (
  <Box sx={{ display: 'flex' }}>
    <Text>{title}</Text>
    <Box sx={{ display: 'inline', fontWeight: 'light', pl: 2 }}>{info}</Box>
  </Box>
);

class OfferDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      isSubmitting: false,
      loadingText: loadingMessageTransMap(this.props.t).init,
      userAction: null,
      requestToken: axios.CancelToken.source(),
    };
  }

  componentWillUnmount = () => this.state.requestToken.cancel();

  openModal = async () => {
    const { uid, username, niceStatus } = this.props;
    const requestToken = axios.CancelToken.source();

    this.setState({
      visible: true,
      isLoading: true,
      loadingText: loadingMessageTransMap(this.props.t).init,
      userAction: null,
      requestToken,
    });

    const response = await asyncLoad(() => getOfferUserDecisions(uid, requestToken));
    const decisions = response && response.length ? response : [];

    let userAction = null;

    if (decisions) {
      const userDecisionIdx = decisions.findIndex(
        (val) => val.username && username && val.username.toLowerCase() === username.toLowerCase()
      );

      if (userDecisionIdx !== -1) {
        const { type, decided, signingEvent } = decisions[userDecisionIdx];

        if (type) {
          if (niceStatus === 'Pending acceptance') {
            if (!decided && !signingEvent && type === 'BUYER') {
              userAction = 'ACCEPT_OR_DECLINE';
            } else if (
              decided &&
              signingEvent &&
              type === 'SELLER' &&
              signingEvent.signingType === 'ACCEPT' &&
              signingEvent.agreed
            ) {
              userAction = 'WITHDRAW';
            }
          } else if (niceStatus === 'Active' || niceStatus === 'Confirmed') {
            const acceptedDecisions = decisions.filter(
              (v) => v.signingEvent && v.signingEvent.signingType === 'ACCEPT' && v.signingEvent.agreed
            );

            if (acceptedDecisions.length) {
              userAction = 'CANCEL';
            }
          }
        }
      }
    }

    this.setState({ isLoading: false, userAction });
  };

  closeModal = () => {
    this.state.requestToken.cancel();
    this.setState({ visible: false, isSubmitting: false });
  };

  submitForm = async (signingEvent) => {
    const { uid, getOffers, t } = this.props;
    const { signingType, agreed, isSeller } = signingEvent;

    const signType = getSignType(signingType, agreed, isSeller);

    registerUserEvent('actioning offer', 'data');
    this.state.requestToken.cancel();

    const requestToken = axios.CancelToken.source();

    this.setState({
      isSubmitting: true,
      loadingText: loadingMessageTransMap(t)[signType],
      requestToken,
    });

    const isSuccess = await signOffer({ ...signingEvent, uid }, requestToken);

    if (isSuccess) {
      this.setState({ visible: false, isSubmitting: false });
      await getOffers();
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const {
      username,
      isAdmin,
      isBuyer,
      includesTax,
      niceStatus,
      buyerMeterDisplayName,
      sellerMeterDisplayName,
      niceOfferStart,
      niceOfferEnd,
      amount,
      formattedRate,
      formattedRateNetTax,
      t,
      hideAllocatedAmount,
    } = this.props;
    const { visible, isLoading, isSubmitting, loadingText, userAction } = this.state;
    const hasActions = !!(!isAdmin && !isLoading && username && username.length && userAction);

    const getModalFooter = () => {
      if (isLoading || !hasActions) return null;

      return (
        <Flex sx={{ justifyContent: 'flex-end', px: 3, pt: 2, pb: 3 }}>
          <UserActions
            hasActions={hasActions}
            isSubmitting={isSubmitting}
            userAction={userAction}
            submitForm={this.submitForm}
          />
        </Flex>
      );
    };

    const getFormattedRate = () => {
      return isBuyer && includesTax ? formattedRateNetTax : formattedRate;
    };

    const renderModalContent = () => {
      if (isLoading || isSubmitting) return <LoadingOverlay active text={loadingText} color="text" />;

      return (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          {isAdmin && <DetailsRow title={t('Seller')} info={sellerMeterDisplayName} />}

          <DetailsRow
            title={isBuyer ? t('Seller') : t('Buyer')}
            info={isBuyer ? sellerMeterDisplayName : buyerMeterDisplayName}
          />
          <DetailsRow title={t('Offer from')} info={niceOfferStart} />
          <DetailsRow title={t('Offer to')} info={niceOfferEnd} />
          {!hideAllocatedAmount && (
            <DetailsRow title={t('Allocation')} info={typeof amount === 'number' ? `${amount}%` : '-'} />
          )}
          <DetailsRow title={t('Rate')} info={!isLoading ? getFormattedRate() : '-'} />
          <DetailsRow
            title={t('Status')}
            info={
              <StatusBadge
                color={COLOR_BY_STATUS[niceStatus]}
                statusText={niceStatusTransMap(t)[niceStatus]}
                variant="subtle"
              />
            }
          />
        </Box>
      );
    };

    return (
      <>
        <Button variant="pill" onClick={this.openModal}>
          {t('Details')}
        </Button>
        <Modal
          title={t('Offer details')}
          visible={visible}
          maskClosable={!isSubmitting}
          onOk={this.closeModal}
          onCancel={this.closeModal}
          bodyStyle={
            isLoading || !hasActions
              ? {
                  minHeight: '80px',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                }
              : {}
          }
          footer={getModalFooter()}
        >
          {renderModalContent()}
        </Modal>
      </>
    );
  }
}

OfferDetails.defaultProps = {
  username: '',
  isAdmin: false,
  isBuyer: false,
  hideAllocatedAmount: false,
};

OfferDetails.propTypes = {
  username: PropTypes.string,
  niceStatus: PropTypes.string,
  buyerMeterDisplayName: PropTypes.string,
  sellerMeterDisplayName: PropTypes.string,
  uid: PropTypes.string,
  niceOfferStart: PropTypes.string,
  niceOfferEnd: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formattedRate: PropTypes.string,
  isAdmin: PropTypes.bool,
  isBuyer: PropTypes.bool,
  getOffers: PropTypes.func,
  t: PropTypes.func.isRequired,
  hideAllocatedAmount: PropTypes.bool,
};

AcceptOrDeclineAction.propTypes = {
  isSubmitting: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
};

DetailsRow.propTypes = {
  title: PropTypes.any,
  info: PropTypes.any,
};

WithdrawAction.propTypes = {
  isSubmitting: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
};

CancelAction.propTypes = {
  isSubmitting: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
};

UserActions.propTypes = {
  isSubmitting: PropTypes.bool,
  userAction: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
};

export default withTranslation()(OfferDetails);
