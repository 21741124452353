import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { pageview } from 'react-ga';

import { eraseCookie, setCookie } from '../../lib/cookie-helpers';
import { formatRelativeTime } from '../../lib/datetime-helpers';
import i18n from '../../lib/i18n';
import { errorNotification, isSuccess, successNotification, notification } from '../../lib/notifications';
import * as api from '../../middleware/api';
import { RequestToken, TokenMeter } from '../../../../types';
import { updateMeterGroup } from './meters';

/**
 * With the implementation of OKTA this file is no longer used
 * A Spike has been created to validate this XGRID-3452
 */

export const receiveLogin = createAction('auth/receiveLogin');

export const resetPasswordRequest = createAction('auth/resetPasswordRequest', (hasReset = false) => ({
  payload: hasReset,
}));

export const acceptTerms = createAction('auth/acceptTerms', (id, secret) => ({
  payload: {
    id,
    secret,
  },
}));

export const acceptHostTerms = createAction('auth/acceptHostTerms');

export const setMFAToken = createAction<string>('auth/setMFAToken');

export const setMFAStatus = createAction('auth/setMFAStatus', (status) => ({
  payload: {
    mfaStatus: status,
    mfaEnabledDate: Date.now(),
  },
}));

export type LoginPayload = { creds: any; history: any };

export const loginUser = createAsyncThunk<string | number, LoginPayload, { rejectValue: any }>(
  'auth/loginUser',
  async ({ creds, history }, { dispatch, rejectWithValue }) => {
    const response = creds.mfaCode
      ? await api.loginUserMFASMS({
          mfaCode: creds.mfaCode,
          authToken: creds.mfaToken,
        })
      : await api.loginUser({
          username: creds.username.toLowerCase(),
          password: creds.password,
        });
    const { status, body = {} } = response;

    let tempStatus = status;

    if (status === 202 && body.mfa_token) {
      dispatch(setMFAToken(body.mfa_token));
      history.push('/login-mfa');
      return tempStatus;
    }

    if (status === 206 && body.mfa_token) {
      dispatch(setMFAToken(body.mfa_token));
      history.push('/enable-mfa');
      return tempStatus;
    }

    if (
      status === 401 &&
      body.error === 'unauthorized' &&
      body.error_description &&
      body.error_description.indexOf('blocked') !== -1
    ) {
      const errorDesc = body.error_description ? body.error_description.split(' ') : null;

      let descriptionText;
      if (errorDesc) {
        const lockedTime = formatRelativeTime(errorDesc.pop());
        i18n.t('key this is a {{value}}', { value: 'fasdf' });

        descriptionText =
          i18n.t(`Your account has been temporarily locked due to too many failed login attempts.
        Please try again ${lockedTime}`);
      } else {
        descriptionText =
          i18n.t(`Your account has been temporarily locked due to too many failed login attempts.
        Please try again later`);
      }

      return rejectWithValue({
        code: status,
        errorCode: body.errorCode,
        message: i18n.t('Account locked out'),
        description: descriptionText,
        duration: 10,
      });
    }
    if (isSuccess(status)) {
      // user has accepted terms - proceed as normal to the dashboard
      // otherwise update-password page given they have passed the onboarding process
      const userToken = jwtDecode<any>(body.access_token);
      if (body.terms_accepted) {
        if (!userToken || !userToken.exp) {
          tempStatus = 500;
          return rejectWithValue({ code: tempStatus, errorCode: tempStatus });
        }

        const { exp } = userToken;

        const signedToken = body.access_token;
        setCookie('plt', signedToken, exp);
        // registerUserEvent(
        //   'logged in',
        //   'navigation',
        //   (creds.username && creds.username.toLowerCase()) || body.user_name
        // );
        const mgResp = await api.getMeterGroups();

        if (!isSuccess(mgResp.status) || !mgResp.body || !mgResp.body.length) {
          eraseCookie('plt');
          return rejectWithValue({
            code: mgResp.status,
            errorCode: body.errorCode,
            description: i18n.t('An error has occurred. Please try again later'),
          });
        }

        dispatch((updateMeterGroup as any)(mgResp.body[0], body.meters));
        dispatch(receiveLogin());
        history.push('/dashboard');
      } else if (history.action === 'PUSH' && history.location.pathname === '/terms') {
        // terms still not accepted after 1st attempt - output unknown server error
        return rejectWithValue({});
      } else {
        // user has not accepted terms - redirect to terms page to update
        let userId = null;
        let userSecret = null;

        if (userToken && userToken.user_id) {
          userId = userToken.user_id;
          userSecret = body.access_token;
        }

        dispatch(acceptTerms(userId, userSecret));
        pageview('/terms');
        // registerUserEvent('navigated to terms', 'navigation', creds.username.toLowerCase());
        history.push('/terms');
      }
    } else if (status === 206) {
      return rejectWithValue({
        code: status,
        errorCode: body.errorCode,
        description: isSuccess(status) ? '' : i18n.t('MFA is mandatory for this user account'),
      });
    } else {
      tempStatus = isSuccess(status) ? 401 : status;
      return rejectWithValue({
        code: tempStatus,
        errorCode: body.errorCode,
        description: isSuccess(status) ? '' : i18n.t('Invalid username/password combination!'),
      });
    }
    return tempStatus;
  }
);

export const loginUserAcceptTerms = createAsyncThunk<any, LoginPayload, { rejectValue: any }>(
  'auth/loginUserAcceptTerms',
  async ({ creds, history }, { dispatch, rejectWithValue }) => {
    const { username = '', secret = '' } = creds;
    const { status } = await api.acceptTerms(username.toLowerCase(), {
      secret,
    });

    return isSuccess(status) ? dispatch(loginUser({ creds, history })) : rejectWithValue({ code: 500 });
  }
);

export const resetPassword = createAsyncThunk<boolean, { username: string; token?: RequestToken }>(
  'auth/resetPassword',
  async ({ username, token = null }, { dispatch }) => {
    const { status } = await api.resetPassword(username, { token });
    const success = isSuccess(status);
    dispatch(resetPasswordRequest(success));
    return success;
  }
);

export const checkResetToken = createAsyncThunk<number, any>('auth/checkResetToken', async (resetToken) => {
  const { status } = await api.checkResetToken(resetToken);
  return status;
});

export type UpdatePasswordParams = Record<any, any>;

export const updatePassword = createAsyncThunk<any, UpdatePasswordParams, { rejectValue: any }>(
  'auth/updatePassword',
  async (creds, { rejectWithValue }) => {
    const { status, body } = await api.updatePassword(creds);

    return isSuccess(status) ? { status, body } : rejectWithValue({ status, body });
  }
);

export type UpdateMobileNumberArgs = {
  mfaMobile: string;
  token?: RequestToken;
  authToken?: string;
};

export const updateMobileNumber = createAsyncThunk<any, UpdateMobileNumberArgs, { rejectValue: any }>(
  'auth/updateMobileNumber',
  async ({ mfaMobile, token = null, authToken = null }, { rejectWithValue }) => {
    const { status, body } = authToken
      ? await api.initialUpdateMFAMobile({ authToken, mfaMobile, token })
      : await api.updateMFAMobile({ mfaMobile, token });

    return isSuccess(status) ? { mfaMobile } : rejectWithValue({ status, body });
  }
);

export type VerifyMobileCodeArgs = {
  mfaCode: string;
  token?: RequestToken;
  authToken?: string;
};

export type VerifyMobileCodeReturn = { authToken: string | null };

export const verifyMobileCode = createAsyncThunk<
  VerifyMobileCodeReturn,
  VerifyMobileCodeArgs,
  {
    rejectValue: any;
  }
>('auth/verifyMobileCode', async ({ mfaCode, token = null, authToken = null }, { rejectWithValue }) => {
  const { status, body } = authToken
    ? await api.initialVerifyMobileCode({ authToken, mfaCode, token })
    : await api.verifyMobileCode({ mfaCode, token });

  return isSuccess(status) ? { authToken, status } : rejectWithValue({ status, body });
});

export const resendMobileCode = createAsyncThunk<boolean, string | undefined | null>(
  'auth/resendMobileCode',
  async (authToken = null) => {
    const { status } = authToken
      ? await api.initialResendMobileCode({ authToken })
      : await api.resendMobileCode();
    return isSuccess(status);
  }
);

export const removeMFAMobile = createAsyncThunk<any, any, { rejectValue: any }>(
  'auth/removeMFAMobile',
  async (mfaMobile, { rejectWithValue }) => {
    const { status, body } = await api.removeMFAMobile(mfaMobile);

    return isSuccess(status) ? { mfaEnabledDate: Date.now() } : rejectWithValue({ status, body });
  }
);

export const getMFADetails = createAsyncThunk<any, string | undefined | null, { rejectValue: any }>(
  'auth/getMFADetails',
  async (token = null, { rejectWithValue }) => {
    const { status, body } = await api.getMFASettings(token);

    return isSuccess(status)
      ? { status, body, mfaEnabledDate: Date.now() }
      : rejectWithValue({ status, body });
  }
);

export const loadUserProfile = createAsyncThunk<
  {
    state: number;
    body: {
      username: string;
      firstName: string;
      lastName: string;
      userRoles: string[];
      tradingGroupName: string;
      accountNumbers: string[];
      meters: TokenMeter[];
      termsAcceptedDateTime: string;
      user: boolean;
      admin: boolean;
    };
  },
  void,
  {
    rejectValue: { status: number };
  }
>('auth/loadUserProfile', async (param: any, { rejectWithValue }: any) => {
  const { status, body } = await api.getAuthProfile();

  return isSuccess(status) ? { status, body } : rejectWithValue({ status });
});

interface AuthState {
  id: string;
  secret?: string;
  username: string;
  password: string;
  mfaToken: string;
  mfaStatus: string;
  mfaMobile: string;
  mfaEmail: string;
  isAuthenticated: boolean;
  hasResetPassword: boolean;
  hasUpdatedPassword: boolean;
  hasAcceptedHostTerms: boolean;
  hasAcceptedPLTerms: boolean;
  mfaEnabledDate?: number;
  profile?: {
    username: string;
    firstName?: string;
    lastName?: string;
    userRoles: string[];
    tradingGroupName: string;
    accountNumbers: string[];
    meters: TokenMeter[];
    termsAcceptedDateTime: string;
    user: boolean;
    admin: boolean;
  };
}

const authSlice = createSlice<AuthState, any, string>({
  name: 'auth',
  initialState: {
    id: '',
    username: '',
    password: '',
    mfaToken: '',
    mfaStatus: '',
    mfaMobile: '',
    mfaEmail: '',
    isAuthenticated: false,
    hasResetPassword: false,
    hasUpdatedPassword: false,
    hasAcceptedHostTerms: false,
    hasAcceptedPLTerms: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getMFADetails.fulfilled,
        (
          state,
          {
            payload: {
              body: { enabled, mfaMethods },
              mfaEnabledDate,
            },
          }
        ) => {
          const sortedMFAMethods = Array.isArray(mfaMethods)
            ? mfaMethods.sort((a, b) => (a.priority || 0) - (b.priority || 0))
            : [];
          const mfaStatus = (enabled && ((sortedMFAMethods && sortedMFAMethods[0]) || {}).type) || '';
          const mfaMobile =
            (sortedMFAMethods.find((v) => v.type.toUpperCase() === 'SMS') || {}).identifier || '';
          const mfaEmail =
            (sortedMFAMethods.find((v) => v.type.toUpperCase() === 'EMAIL') || {}).identifier || '';

          state.mfaStatus = mfaStatus;
          state.mfaMobile = mfaMobile;
          state.mfaEmail = mfaEmail;
          state.mfaEnabledDate = mfaEnabledDate;
        }
      )
      .addCase(getMFADetails.rejected, (state, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not retrieve MFA details at this time!'),
        });
      })
      .addCase(setMFAStatus, (state, { payload: { mfaStatus, mfaEnabledDate } }) => {
        state.mfaStatus = mfaStatus;
        state.mfaEnabledDate = mfaEnabledDate;
      })
      .addCase(setMFAToken, (state, { payload: mfaToken }) => {
        state.mfaToken = mfaToken;
      })
      .addCase(
        loginUser.pending,
        (
          state,
          {
            meta: {
              arg: { creds },
            },
          }
        ) => {
          notification.destroy();
          eraseCookie('plt-expire');
          state.username = creds.username || state.username || '';
          state.password = creds.password || state.password || '';
          state.isAuthenticated = false;
        }
      )
      .addCase(receiveLogin, (state) => {
        state.password = '';
        state.mfaToken = '';
        state.hasAcceptedHostTerms = false;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        errorNotification(payload);
        state.isAuthenticated = false;
      })
      .addCase(resetPasswordRequest, (state, { payload }) => {
        state.username = '';
        state.password = '';
        state.hasResetPassword = payload;
      })
      .addCase(updatePassword.rejected, (state, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t(
            'This link has expired, please return to the login screen and try again.'
          ) as any,
        });
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.hasUpdatedPassword = true;
      })
      .addCase(acceptTerms, (state, { payload: { id, secret } }) => {
        state.id = id;
        state.secret = secret;
      })
      .addCase(acceptHostTerms, (state) => {
        state.hasAcceptedHostTerms = true;
      })
      .addCase(updateMobileNumber.fulfilled, (state, { payload: { mfaMobile } }) => {
        state.mfaMobile = mfaMobile;
      })
      .addCase(updateMobileNumber.rejected, (state, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
        });
      })
      .addCase(verifyMobileCode.fulfilled, (state, { payload: { authToken } }) => {
        if (authToken) {
          state.mfaToken = '';
        }
      })
      .addCase(verifyMobileCode.rejected, (state, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          message: i18n.t('Invalid verification code') as any,
        });
      })
      .addCase(removeMFAMobile.fulfilled, (state, { payload: { mfaEnabledDate } }) => {
        successNotification({
          description: i18n.t('SMS verification has been removed') as any,
        });
        state.mfaStatus = '';
        state.mfaEnabledDate = mfaEnabledDate;
        state.mfaMobile = '';
      })
      .addCase(removeMFAMobile.rejected, (state, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          message: i18n.t('Invalid access token') as any,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(loginUserAcceptTerms.rejected, (state, { payload }) => {
        errorNotification(payload);
      })
      .addCase(loadUserProfile.fulfilled, (state, { payload }) => {
        state.profile = payload.body;
      })
      .addCase(loadUserProfile.rejected, (state) => {
        state.profile = undefined;
      });
  },
});

export default authSlice.reducer;
