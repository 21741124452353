import { FC } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

export type DropdownProps = {
  children?: any;
  overlay?: any;
  visible?: boolean;
  dataTestid?: string;
  dropdownStyles?: ThemeUIStyleObject;
};

export const Dropdown: FC<DropdownProps> = ({
  overlay,
  visible = false,
  children,
  dataTestid,
  dropdownStyles,
}) => (
  <Box sx={{ position: 'relative' }} data-testid={dataTestid}>
    <Box> {children}</Box>
    {overlay && (
      <Box
        sx={{
          variant: 'dropdowns',
          ...(visible ? { transform: 'scaleY(1)', opacity: 1 } : {}),
          ...dropdownStyles,
        }}
      >
        {overlay}
      </Box>
    )}
  </Box>
);
