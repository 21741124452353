export const NewDropdownArrowSVG: React.FC<{ width?: string; height?: string }> = ({
  width = '20px',
  height = '20px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      aria-labelledby="title-dropdown-arrow"
      aria-describedby="desc-dropdown-arrow"
      fill="#101828"
    >
      <title id="title-dropdown-arrow">Dropdown arrow</title>
      <path
        d="M6 9L12 15L18 9"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
