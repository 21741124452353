import { FC } from 'react';
import { dashboardSVG } from '@power-ledger/assets';
import { createIconStyles } from '@power-ledger/components';
import { IconProps, IconWrapper } from './../common';

export const DashboardIcon: FC<IconProps> = (props) => (
  <IconWrapper
    icon={dashboardSVG}
    sx={createIconStyles({ ...props, color: 'none', stroke: 'currentColor' })}
    {...props}
  />
);
