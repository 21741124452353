import React from 'react';
import { Box } from 'theme-ui';
import { LoadingOutlined } from '../../components';
import { useProviderLoaderLogic } from './use-provider-logic';

export const ProviderLoader: React.FC<{ children: React.ReactNode; skipLoading?: boolean }> = ({
  children = null,
  skipLoading,
}) => {
  const { isLoading } = useProviderLoaderLogic();

  return !skipLoading && isLoading ? (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingOutlined />
    </Box>
  ) : (
    (children as any)
  );
};
