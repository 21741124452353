import { Box, BoxProps } from 'theme-ui';
import { FC } from 'react';

export const ButtonGroup: FC<BoxProps> = ({ children, sx = {}, ...props }) => (
  <Box
    {...props}
    sx={{
      display: ['block', 'flex'],
      justifyContent: 'flex-end',
      '& button': { width: 'initial', mx: 2, py: 1 },
      ...sx,
    }}
  >
    {children}
  </Box>
);
