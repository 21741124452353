import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import { HostConfigResponse } from '../../../../types/trading-group-config-body';
import { HostConfigContextType } from '../../contexts/host-config-context';
import { HOSTNAME_OVERRIDE, HOST_CONFIG_ENDPOINT } from '../../lib/envHelpers';
import { hostConfig } from '../../middleware/endpoints';
import { stringToSHA1 } from '../../utils/string-to-sha1';

export const CONFIG_ENDPOINT =
  HOST_CONFIG_ENDPOINT || hostConfig.hostConfigEndpoint || 'https://dev-config.powerledger.io';

const domainFromHost = (url: string) => new URL(url).hostname;

const deriveDomainHash = (hostURL?: string) => {
  // Global access required to access current location of browser
  // eslint-disable-next-line no-restricted-globals
  const host = domainFromHost(hostURL || HOSTNAME_OVERRIDE || location.origin);
  return stringToSHA1(host);
};

/**
 * Fetches and configures a Host's configuration in accordance to the host's URL given
 * @param hostURL Optional overwrite of current host name form URL
 * @returns Host Config Provider Context Value
 */
export const useHostConfigProviderCtx = (hostURL?: string): HostConfigContextType => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState<boolean | string>(false);
  const [hostConfig, setHostConfig] = useState<HostConfigResponse | null>(null);

  const domainHash = useMemo(() => {
    try {
      return deriveDomainHash(hostURL);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      return null;
    }
  }, [hostURL]);

  useEffect(() => {
    if (!domainHash) {
      return;
    }

    setIsLoading(true);
    setIsError(false);
    setHostConfig(null);

    const abortController = new AbortController();
    axios
      .get(`${CONFIG_ENDPOINT}/${domainHash}/config.json`, {
        signal: abortController.signal,
      })
      .then((resp) => resp.data)
      .then(setHostConfig)
      .then(() => {
        setIsLoading(false);
        setIsError(false);
      })
      .catch((error) => {
        // TODO: Capture error in Sentry
        setIsLoading(false);
        setIsError(error.isAxiosError && error.request.status === 404 ? 'Host Not Found' : true);
        setHostConfig(null);
      });
    return () => abortController.abort();
  }, [domainHash]);

  const hostHasFlag = useCallback(
    (flag) => {
      if (!hostConfig) {
        throw new Error('Request against Host Config prior to Load');
      }
      return (hostConfig.customFlags || []).includes(flag);
    },
    [hostConfig]
  );

  return useMemo(
    () => ({
      isLoading,
      isError,
      tradingGroup: hostConfig
        ? {
            id: hostConfig.id,
            ref: hostConfig.ref,
            name: hostConfig.name,
            status: hostConfig.status,
            domain: hostConfig.domain,
            timeZone: hostConfig.timeZone,
            logo: hostConfig.logo ? `${CONFIG_ENDPOINT}/${hostConfig.logo}` : undefined,
            logoInverse: hostConfig.logoInverse ? `${CONFIG_ENDPOINT}/${hostConfig.logoInverse}` : undefined,
            logoWidth: hostConfig.logoWidth,
          }
        : undefined,
      config: hostConfig
        ? {
            customFlags: hostConfig.customFlags,
            apiEndpoint: hostConfig.apiEndpoint,
            auth: {
              ...hostConfig.auth,
              providers: hostConfig.auth.providers
                ? hostConfig.auth.providers.map((provider) => ({
                    ...provider,
                    icon: provider.icon ? `${CONFIG_ENDPOINT}/${provider.icon}` : undefined,
                  }))
                : undefined,
            },
          }
        : undefined,
      theme: hostConfig ? hostConfig.theme : undefined,
      hostHasFlag,
    }),
    [isLoading, isError, hostConfig, hostHasFlag]
  );
};

/**
 * Used for testing purposes only!
 * @private
 * @omit
 */
export const exportForTesting = {
  domainFromHost,
  deriveDomainHash,
};
