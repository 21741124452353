import React from 'react';
import PropTypes from 'prop-types';

export const SharingEventClockSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    id="Layer_1"
    viewBox="0 0 60 60"
    aria-labelledby="title-sharing-event"
    aria-describedby="desc-sharing-event"
    role="img"
  >
    <title id="title-sharing-event">Sharing event clock icon</title>
    <description id="desc-sharing-event">
      An illustration showing a clock at what appears to be 4:55.
    </description>
    <path
      d="M29.75 0C13.3188 0 0 13.3188 0 29.75C0 46.1812 13.3188 59.5 29.75 59.5C46.1812 59.5
    59.5 46.1812 59.5 29.75C59.5 13.3188 46.1812 0 29.75 0ZM29.75 54.8516C15.8864 54.8516 4.64844
    43.6136 4.64844 29.75C4.64844 15.8864 15.8864 4.64844 29.75 4.64844C43.6136 4.64844 54.8516
    15.8864 54.8516 29.75C54.8516 43.6136 43.6136 54.8516 29.75 54.8516Z"
      fill="#008E95"
    />
    <path
      d="M29.75 16.7344C22.5631 16.7344 16.7344 22.5631 16.7344 29.75C16.7344 36.9369 22.5631
    42.7656 29.75 42.7656C36.9369 42.7656 42.7656 36.9369 42.7656 29.75C42.7656 22.5631 36.9369
    16.7344 29.75 16.7344ZM29.75 40.3252C23.9104 40.3252 19.1748 35.5877 19.1748 29.75C19.1748
    23.9104 23.9104 19.1748 29.75 19.1748C35.5877 19.1748 40.3252 23.9104 40.3252 29.75C40.3252
    35.5877 35.5877 40.3252 29.75 40.3252Z"
      fill="#008E95"
    />
    <path
      d="M29.75 31.6094C28.7222 31.6094 27.8906 30.7778 27.8906 29.75C27.8906 28.7222 28.7222
    27.8906 29.75 27.8906C30.7778 27.8906 31.6094 28.7222 31.6094 29.75C31.6094 30.7778 30.7778
    31.6094 29.75 31.6094Z"
      fill="#008E95"
    />
    <path
      d="M28.5952 29.9099L29.9118 28.5952L33.4162 32.0998L32.1015 33.4162L28.5952 29.9099Z"
      fill="#008E95"
    />
    <path
      d="M27.3062 23.7597L29.111 23.313L30.8215 30.2094L29.0167 30.6561L27.3062 23.7597Z"
      fill="#008E95"
    />
    <path
      d="M43.3469 16.1499C41.6473 14.4468 38.8873 14.4468 37.1841 16.1499L43.3469
    22.3127C45.0501 20.6113 45.0501 17.8495 43.3469 16.1499Z"
      fill="#008E95"
    />
    <path
      d="M16.1513 16.1534C14.4499 17.853 14.4499 20.613 16.1513 22.3161L22.316 16.1534C20.6128
    14.4502 17.8528 14.4502 16.1513 16.1534Z"
      fill="#008E95"
    />
  </svg>
);

SharingEventClockSVG.defaultProps = {
  width: '60px',
  height: '60px',
};

SharingEventClockSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
