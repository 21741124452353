import { UPDATE_APP_NOTIFICATIONS, RESET_APP_NOTIFICATIONS } from '../constants';
import { initialState } from '../store/initial-state';

// App notifications reducer
const appNotifications = (state = initialState.appNotifications, action) => {
  switch (action.type) {
    case UPDATE_APP_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        totalNotifications: action.totalNotifications,
      };
    case RESET_APP_NOTIFICATIONS:
      return initialState.appNotifications;
    default:
      return state;
  }
};

export default appNotifications;
