import React from 'react';
import PropTypes from 'prop-types';

export const ClapSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 37 38"
    aria-labelledby="title-clap-icon"
    aria-describedby="desc-clap-icon"
    role="img"
  >
    <title id="title-clap-icon">Clap icon</title>
    <description id="desc-clap-icon">A symbol representing two hands clapping.</description>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.720986187">
      <g transform="translate(-261.000000, -162.000000)" fill="#323232" fillRule="nonzero">
        <g transform="translate(261.000000, 162.000000)">
          <path
            d="M36.9579554,25.8240644 L36.9579554,25.7658073 C36.8715512,24.9857787
          36.6526469,24.2268946 36.3109784, 23.5229071 L32.2284717,12.1960528 C32.0053014,11.3522375
          31.3451219,10.7005156 30.5086595,10.4982731 C29.5038788, 10.2690325 28.4538729,10.5837939
          27.7323911,11.3305181 C27.134377,11.9532739 26.9191687,12.8584041 27.1714048,13.6899326
          L27.1714048,13.7606734 L21.639342,8.10556875 C20.3412933,6.78646045 18.74023,7.03197272
          17.8270916,7.95576465 C17.4869953,8.29864511 17.2474008,8.7308611 17.1350719,9.20413213
          L15.80017,7.84757281 C14.8124966,6.77342636 13.156417,6.7120592 12.0943841,7.71025238
          C11.4042017,8.30850659 11.0424249,9.20978277 11.1239186,10.1279241 C9.78380413,10.1136347
          8.65757816,11.1476667 8.53601064,12.5039835 L7.02912753,11.3430017 C6.28959576,
          10.7209155 5.27389781,10.5611358 4.38389251,10.9268792 C3.44444429,11.3378112
          2.78558846,12.2189579 2.6477011,13.2488427 C2.53603017,14.1921474 2.92970753,15.1240434
          3.67958844,15.6914818 C3.21236374,15.8536215 2.80324038,16.1534909 2.50438341,16.5528553
          C1.71818353,17.6056452 1.75094185,19.303425 3.32334162,20.5226639 L4.47397791,
          21.4090048 C3.94872249,21.5749541 3.49137827,21.9111043 3.17183435,22.3660865
          C2.45115113,23.4022315 2.44296154,25.1083338 4.11773109,26.4024747 L17.3152427,36.601637
          C19.9277717,38.6241425 23.5954606,38.434177 25.9921049,36.1522247 C28.2249203,36.6309841
          30.5446279,35.9312948 32.1588603,34.2921571 L35.025214,31.3792997 C36.3940993,29.9864929
          37.108434,28.0633246 36.9866189,26.0987053 C36.9972256,26.006131 36.9874346,25.9123182
          36.9579554,25.8240644 Z M9.9732823,12.0046365 C10.2121935,11.6251135 10.6232725,11.3935527
          11.0665915,11.3887752 C11.3017453,11.3918812 11.5329979,11.4502762 11.742232,11.5593854
          L11.8773601,11.7133508 C10.9680895,11.904864 10.2363112,12.5893424 9.9732823,13.494355
          C9.67324577,13.0445863 9.67324577,12.4544052 9.9732823,12.0046365 Z M3.86794883,13.4444203
          C3.95574117,12.8616843 4.33132613,12.3653453 4.86298306,12.1294732 C5.34582708,11.9279171
          5.89932701,12.0213951 6.29206514,12.3708243 L9.48190737,14.825947 L10.1206948,15.3169715
          C10.1411687,15.3710675 10.1616427,15.4251634 10.1862114,15.4792593 C10.3693839,15.8499516
          10.6114998,16.1873832 10.9027999,16.4779533 L11.9142133,17.5099371 C11.6677189,17.5546794
          11.4290348,17.6359989 11.2058144,17.7512881 C10.665666,18.0300954 10.2436398,18.4988981
          10.018325,19.0703964 L6.55003698,16.39889 L6.48861511,16.3406329 L4.4780727,14.7926572
          C4.03742686,14.4990498 3.80049433,13.9754821 3.86794883,13.4444203 Z
          M18.0604946,35.6071042 L4.87117264,25.445393 C3.83928529,24.650599 3.78195822,23.706001
          4.18734253,23.1234295 C4.4383758,22.7644784 4.84737326,22.5543269 5.28065175,22.5616641
          C5.69208752,22.576215 6.09046476,22.7121257 6.42719324,22.9528193 L12.3032184,27.4843932
          C12.4773531,27.6287826 12.7151853,27.6649935 12.9233143,27.5788051 C13.1314433,27.4926167
          13.2766322,27.297793 13.3018628,27.0708443 C13.3270933,26.8438956 13.2283364,26.6210645
          13.0443756,26.4898604 L7.42632226,22.1580253 L7.38127956,22.116413 L7.29119416,22.0498334
          L4.07678318,19.5530985 C3.08993853,18.7957556 3.06946458,17.8886085 3.48713326,17.3351656
          C3.90480195,16.7817227 4.7524237,16.5611778 5.72698397,17.2977146 L5.77202667,17.3434881
          L9.86681773,20.5018578 C10.0097209,21.2262741 10.3702538,21.8877975 10.8987051,22.3952151
          L17.0408917,28.6370525 L21.0865452,32.7483427 C21.804867,33.5652918 22.5852819,34.3235841
          23.4205761,35.0162103 C23.7716254,35.2735982 24.1459312,35.4965307 24.5384541,35.6820063
          L24.6162551,35.7194573 C22.6434681,37.1478804 19.9847633,37.1023153 18.0604946,35.6071042
          Z M34.1325496,30.5137649 L31.2661958,33.4266224 C29.1065437,35.6176357
          25.6088918,35.6176357 23.4492397,33.4266224 L11.7708956,21.5171967 C11.3770025,21.1563229
          11.1253013,20.6624955 11.0624967,20.1273475 C11.017615,19.5962326 11.2947225,19.0906641
          11.762706,18.8498515 C12.3975242,18.590607 13.1232267,18.7394105 13.6094568,19.228523
          L18.8589789,24.5632133 C19.100989,24.7923793 19.4781193,24.7856173 19.7120236,24.5479181
          C19.945928,24.3102189 19.952582,23.9269701 19.7270746,23.6810336 L11.7708956,15.5832899
          C11.5163943,15.3380839 11.3215307,15.0360601 11.2017196,14.7011102 C10.9797583,14.1183735
          11.1920074,13.45738 11.7094737,13.1198448 C12.1721851,12.8243978 12.937911,12.7952692
          13.7323005,13.5942244 C13.7323005,13.5942244 13.7323005,13.5942244 13.7323005,13.623353
          L21.6884795,21.7002906 C21.9304897,21.9294566 22.3076199,21.9226946 22.5415242,21.6849954
          C22.7754286,21.4472962 22.7820826,21.0640474 22.5565752,20.8181109 L14.6167754,12.7370121
          C14.6093138,12.7253794 14.6011065,12.7142587 14.5922066,12.7037223 L12.8109725,10.8811058
          C12.748727,10.8202565 12.6924964,10.7533575 12.6430861,10.681367 C12.41068,10.3775958
          12.3075945,9.99192773 12.3568303,9.61041378 C12.4060662,9.22889983 12.6035262,8.88328707
          12.9051527,8.65068922 C13.1817885,8.37460577 13.5581759,8.22660887 13.945627,8.24157088
          C14.3330781,8.25653288 14.6973278,8.43313061 14.9525482,8.72975249 L24.7022457,18.6334678
          C24.9462556,18.778837 25.2564068,18.7360719 25.4534778,18.5298844 C25.6505488,18.3236969
          25.6835817,18.0074021 25.5334883,17.7637718 L18.8425997,10.9476854 C18.0768738,10.1695363
          18.2160967,9.32896888 18.6951873,8.83794434 C19.1742778,8.3469198 20.0014256,8.20543815
          20.7712463,8.98774843 L28.0272161,16.361439 C28.2255964,16.5625819 28.5327941,16.6017694
          28.7739406,16.4566946 C29.0150871,16.3116197 29.1290321,16.0190708 29.0509138,15.7455777
          L28.362989,13.3403897 C28.2250448,12.9376487 28.3251806,12.4902261 28.6209608,12.1877304
          C29.0415587,11.7678731 29.6410253,11.5898015 30.2179293,11.7133508 C30.6416667,11.8144836
          30.9701045,12.1547308 31.0614563,12.587208 L35.1562473,23.984803 L35.1849109,24.0472214
          C35.4777945,24.6440329 35.6622868,25.2896599 35.7295181,25.9530624 C35.728562,25.9724693
          35.728562,25.9919127 35.7295181,26.0113196 C35.7295181,26.0113196 35.7295181,26.0404481
          35.7295181,26.057093 C35.8706621,27.7093776 35.2861419,29.3406022 34.1325496,30.5137649
          Z"
          />
          <path
            d="M2.98497621,2.19304186 C2.84556904,2.03286731 2.62903176,1.96503914
          2.42430266,2.01741639 C2.21957356,2.06979365 2.06116407,2.23354704 2.01413769,2.44141817
          C1.9671113,2.64928931 2.03939531,2.86623711 2.20130051,3.00315466 L6.05593505,6.84929765
          C6.2785036,7.05683667 6.62390982,7.04909368 6.83713447,6.83178545 C7.05035911,6.61447723
          7.05476979,6.26570377 6.84711004,6.04297043 L2.98497621,2.19304186 Z"
          />
          <path
            d="M4.39217192,8.99996811 C4.6954805,9.0028884 4.95374276,8.80489747
          4.99449881,8.53820779 C5.03525487,8.27151812 4.84573997,8.01965657 4.55250656,7.95080882
          L0.744558884,7.01545664 C0.428782969,6.94676735 0.108667648,7.1132441
          0.0219289266,7.39126211 C-0.0648097952,7.66928011 0.113253362,7.95811396
          0.423889606,8.0432771 L4.23183728,8.97862928 C4.28395124,8.99213228 4.33788634,8.99931046
          4.39217192,8.99996811 Z"
          />
          <path
            d="M8.73465585,0.00228015786 C8.519354,0.0154854697 8.32027287,0.0942365966
          8.18278128,0.220586856 C8.04528969,0.346937116 7.98110165,0.510121719
          8.00484504,0.672952344 L8.39904942,4.44245324 C8.43228378,4.75653913 8.77732706,4.99890914
          9.19278526,5 L9.25671029,5 C9.47822758,4.98824404 9.68330457,4.90710729
          9.82276187,4.77604711 C9.96221916,4.64498693 10.0234781,4.47582461 9.99184818,4.30912684
          L9.59764381,0.539625945 C9.55007391,0.212507389 9.16697567,
          -0.0260315429 8.73465585,0.00228015786 Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

ClapSVG.defaultProps = {
  width: '18px',
  height: '18px',
};

ClapSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
