import { Flex, FlexProps } from 'theme-ui';
import { FC } from 'react';

export const PageLayout: FC<FlexProps> = ({ children, sx, ...props }) => (
  <Flex
    {...props}
    sx={{
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      '& > *:not(.untouchable)': { my: 3, maxWidth: ['100%', '90vw', '60vw'], width: '100%' },
      ...sx,
    }}
  >
    {children}
  </Flex>
);
