import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Box, Flex, Label, Text } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { DropdownArrowSVG } from '@power-ledger/assets';
import { DateTime } from 'luxon';

import {
  areDatesWithinOneDay,
  areDatesWithinRange,
  getMonthsForLocale,
  parseDateWithoutTimeZone,
  parseDateWithTimeZone,
  formatMediumDate,
  getPrevFourWeekPeriod,
  formatDayMonthYear,
  parseDate,
} from '../../../lib/datetime-helpers';
import { ErrorMessage, Form } from '../../Form';
import { Select } from '../../Select';
import { DatePicker } from '../DatePicker';
import { Button, Modal, ResponsiveContainer } from '../../index';
import {
  getControls,
  initializeControlsFromTimeZone,
  updatePeriodicalDates,
} from '../../../states/slices/controls';
import { getCurrentLanguage } from '../../../lib/i18n';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useAppDispatch } from '../../../hooks/use-app-dispatch';
import { useAppSelector } from '../../../hooks/use-app-selector';
import { clearStartEndTimeOptionsMemorizerCache } from './StartEndTimeOptionsMemorizer';
import { usePeriodRangeOptions } from './usePeriodRangeOptions';

const yearOptions = Array.from({ length: DateTime.now().year - 2018 + 1 }, (_, i) => {
  const year = `${2018 + i}`;

  return { value: year, label: year };
});

const formFieldStyles: ThemeUIStyleObject = {
  flexDirection: 'column',
  display: 'inline-flex',
  my: 2,
};

interface SelectedDateAndPeriod {
  startDate: DateTime;
  endDate: DateTime;
  period: string;
}

export type SelectablePeriodType = 'day' | 'week' | 'month' | 'prevFourWeek' | 'year' | 'custom';

export interface TimeFramePickerProps {
  selectablePeriods?: SelectablePeriodType[];
}

/**
 *
 * @param selectablePeriods - array of selectable periods. If not provided, all periods are selectable
 * @returns React.FC<TimeFramePickerProps>
 */
export const TimeFramePicker: React.FC<TimeFramePickerProps> = ({ selectablePeriods }) => {
  const { t, i18n } = useTranslation();
  const { hasIOAManagement, useEUTimeFormat } = useFeatureSet();
  const dispatch = useAppDispatch();

  const dateFormat = useEUTimeFormat ? 'dd MMM, yyyy' : 'MMM dd, yyyy';
  const inputDateFormat = useEUTimeFormat ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

  useEffect(() => {
    return () => {
      clearStartEndTimeOptionsMemorizerCache();
    };
  }, []);

  const { timeZone } = useAppSelector(
    ({ meters: metersState }: any) => ({
      timeZone: metersState?.meterGroup?.timeZone || 'utc',
      interval: metersState?.meterGroup?.settlementInterval,
    }),
    shallowEqual
  );

  const { storePeriod, storeStartDate, storeEndDate } = useAppSelector(
    ({ controls: { startDate, endDate, period } }: any) => ({
      storePeriod: period,
      storeStartDate: startDate && parseDateWithTimeZone(startDate, timeZone),
      storeEndDate: endDate && parseDateWithTimeZone(endDate, timeZone),
    }),
    shallowEqual
  );

  const {
    startDate: initialRequestStartDate,
    endDate: initialRequestEndDate,
    period: initialRequestPeriod,
  } = useMemo(() => getPrevFourWeekPeriod(timeZone, hasIOAManagement), [timeZone, hasIOAManagement]);

  useEffect(() => {
    const { period: localPeriod } = getControls();

    if (
      selectablePeriods?.length &&
      selectablePeriods.length > 0 &&
      !selectablePeriods?.includes(localPeriod)
    ) {
      dispatch(initializeControlsFromTimeZone(timeZone, hasIOAManagement, true));
    }
  }, [selectablePeriods, dispatch, hasIOAManagement, timeZone]);

  const defaultSelectedDateAndPeriod = useMemo(() => {
    return {
      startDate: storeStartDate ? parseDate(storeStartDate, timeZone) : initialRequestStartDate,
      endDate: storeEndDate ? parseDate(storeEndDate, timeZone) : initialRequestEndDate,
      period: storePeriod || initialRequestPeriod,
    };
  }, [
    initialRequestEndDate,
    initialRequestPeriod,
    initialRequestStartDate,
    storeEndDate,
    storePeriod,
    storeStartDate,
    timeZone,
  ]);

  const [selectedDateAndPeriod, setSelectedDateAndPeriod] = useState<SelectedDateAndPeriod>(
    defaultSelectedDateAndPeriod
  );

  const { periodRangeOptions: basePeriodRangeOptions, limit } = usePeriodRangeOptions({
    unlimited: storePeriod === 'year',
  });
  const periodRangeOptions = selectablePeriods
    ? basePeriodRangeOptions.filter(({ value }) => selectablePeriods.includes(value as SelectablePeriodType))
    : basePeriodRangeOptions;

  const { monthOptions, monthNumMap } = useMemo(() => {
    const monthValToNum: Record<string, number> = {};
    const monthOptionsArr = getMonthsForLocale(getCurrentLanguage(i18n)).map((month, index) => {
      const monthOption = {
        value: month,
        label: t(month),
        enValue: month,
      };
      monthValToNum[month] = index + 1;
      return monthOption;
    });
    return { monthOptions: monthOptionsArr, monthNumMap: monthValToNum };
  }, [i18n, t]);

  const [visible, setVisible] = useState(false);

  const onDatesUpdate = useCallback(
    ({ startDate, endDate, period }) => {
      dispatch(
        // Store the selected start/end date-times in the application hosts' timezone
        updatePeriodicalDates(
          startDate.setZone(timeZone, { keepLocalTime: true }).toISO(),
          // @ts-ignore
          endDate.setZone(timeZone, { keepLocalTime: true }).toISO(),
          period
        )
      );
    },
    [dispatch, timeZone]
  );

  const findYearOption = (values: SelectedDateAndPeriod) => {
    return yearOptions.find(({ value }) => value === values.startDate.year.toString());
  };

  return (
    <Formik
      initialValues={defaultSelectedDateAndPeriod}
      validationSchema={yup.object().shape({
        period: yup.string().required(),
        startDate: yup
          .mixed()
          .test('is-within-range', t('Dates must be within {{limit}} days', { limit }), (value, { parent }) =>
            areDatesWithinRange(value, parent.endDate, limit)
          ),
      })}
      onSubmit={onDatesUpdate as any}
      enableReinitialize
    >
      {({ values, setFieldValue, handleReset, submitForm, handleSubmit }) => {
        const weekValueHandler = (val: Date) => {
          const startDate = parseDate(val, timeZone).startOf('day');
          const endDate = parseDate(val, timeZone).plus({ days: 6 }).endOf('day');

          setSelectedDateAndPeriod({ startDate, endDate, period });
        };

        const prevFourWeekValueHandler = (value: number) => {
          const dateValue = new Date(value);

          if (hasIOAManagement) dateValue.setDate(dateValue.getDate() + 1);

          const startDate = parseDate(dateValue, timeZone).startOf('day').minus({ days: 27 }); // 27 since endDate counts as 1
          const endDate = parseDate(dateValue, timeZone).endOf('day');

          setSelectedDateAndPeriod({ startDate, endDate, period });
        };

        const monthValueHandlers = {
          month: (option?: { value: string | number }) => {
            if (!option) return;

            const baseDate = parseDate(selectedDateAndPeriod.startDate, timeZone).set({
              month: monthNumMap[option.value],
            });
            const startDate = baseDate.startOf('month').startOf('day');
            const endDate = baseDate.endOf('month').endOf('day');

            setSelectedDateAndPeriod({ startDate, endDate, period });
          },
          year: (option?: { value: string | number }) => {
            if (!option) return;

            const baseDate = parseDate(selectedDateAndPeriod.startDate, timeZone).set({
              year: Number(option.value),
            });
            const startDate = baseDate.startOf('month').startOf('day');
            const endDate = baseDate.endOf('month').endOf('day');

            setSelectedDateAndPeriod({ startDate, endDate, period });
          },
        };

        const yearValueHandler = (option?: { value: string | number }) => {
          if (!option) return;

          const baseDate = parseDate(selectedDateAndPeriod.startDate, timeZone).set({
            year: Number(option.value),
          });
          const startDate = baseDate.startOf('year').startOf('day');
          const endDate = baseDate.endOf('year').endOf('day');

          setSelectedDateAndPeriod({ startDate, endDate, period });
        };

        const dateValueHandler = (value: Date) => {
          const startDate = parseDate(value, timeZone).startOf('day');
          const endDate = parseDate(value, timeZone).endOf('day');

          setSelectedDateAndPeriod({ startDate, endDate, period });
        };

        const { startDate, endDate, period } = values;

        const formattedDateRange = (
          <Box sx={{ mt: 2 }} data-testid="formatted-date-range">
            {`${selectedDateAndPeriod.startDate
              .startOf('day')
              .toFormat(dateFormat)} - ${selectedDateAndPeriod.endDate.startOf('day').toFormat(dateFormat)}`}
          </Box>
        );

        let formFields;
        switch (period) {
          case 'year':
            formFields = (
              <>
                <Label sx={{ width: 'initial', display: 'inline-block' }} data-testid="year-picker-container">
                  {t('Year')}
                  <Select
                    value={findYearOption(selectedDateAndPeriod)}
                    onChange={yearValueHandler}
                    options={yearOptions}
                    menuPosition="fixed"
                  />
                </Label>
                {formattedDateRange}
              </>
            );
            break;
          case 'month':
            formFields = (
              <>
                <Flex sx={{ flexDirection: 'row' }}>
                  <Label
                    sx={{ width: 'initial', display: 'inline-block' }}
                    data-testid="month-picker-container"
                  >
                    {t('Month')}
                    <Select
                      value={monthOptions[selectedDateAndPeriod.startDate.month - 1]}
                      onChange={monthValueHandlers.month}
                      options={monthOptions}
                      menuPosition="fixed"
                    />
                  </Label>
                  <Label
                    sx={{ width: 'initial', display: 'inline-block', ml: 3 }}
                    data-testid="year-picker-container"
                  >
                    {t('Year')}
                    <Select
                      value={yearOptions.find(
                        ({ value }) => value === selectedDateAndPeriod.startDate.year.toString()
                      )}
                      onChange={monthValueHandlers.year}
                      options={yearOptions}
                      menuPosition="fixed"
                    />
                  </Label>
                </Flex>
                {formattedDateRange}
              </>
            );
            break;
          case 'day':
            formFields = (
              <Box data-testid="day-picker-container">
                <Label>{t('Date')}</Label>
                <DatePicker
                  id="selectedDate-datepicker"
                  timeZone={timeZone}
                  onChange={dateValueHandler}
                  dateValue={selectedDateAndPeriod.startDate}
                  dateFormat={inputDateFormat}
                />
              </Box>
            );
            break;
          case 'week':
            formFields = (
              <Box data-testid="week-beginning-on-input-container">
                <Label>{t('Week beginning on')}</Label>
                <DatePicker
                  id="selectedDate-datepicker"
                  timeZone={timeZone}
                  onChange={weekValueHandler}
                  dateValue={selectedDateAndPeriod.startDate}
                  dateFormat={inputDateFormat}
                />
                {formattedDateRange}
              </Box>
            );
            break;
          case 'custom': {
            const endDateLimitation = selectedDateAndPeriod.startDate.plus({ year: 1 }).endOf('day');
            const startDateLimitation = selectedDateAndPeriod.endDate.minus({ year: 1 }).startOf('day');

            const getValidatedEndDate = (date: Date) => {
              const parsedDate = parseDate(date, timeZone).endOf('day');

              if (parsedDate > endDateLimitation)
                return {
                  startDate: parsedDate.minus({ year: 1 }).startOf('day'),
                  endDate: parsedDate,
                };

              if (parsedDate <= selectedDateAndPeriod.startDate.endOf('day'))
                return {
                  startDate: parsedDate.minus({ days: 1 }).startOf('day'),
                  endDate: parsedDate,
                };

              return {
                startDate: selectedDateAndPeriod.startDate,
                endDate: parsedDate,
              };
            };

            const getValidatedStartDate = (date: Date) => {
              const parsedDate = parseDate(date, timeZone).startOf('day');

              if (parsedDate < startDateLimitation)
                return {
                  startDate: parsedDate,
                  endDate: parsedDate.plus({ year: 1 }).endOf('day'),
                };

              if (parsedDate >= selectedDateAndPeriod.endDate.startOf('day'))
                return {
                  startDate: parsedDate,
                  endDate: parsedDate.plus({ days: 1 }).endOf('day'),
                };

              return {
                startDate: parsedDate,
                endDate: selectedDateAndPeriod.endDate,
              };
            };

            formFields = (
              <>
                <Flex sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: [2, 3] }}>
                  <Box
                    sx={{
                      width: ['100%', 'initial'],
                      display: 'inline-block',
                    }}
                    data-testid="start-date-picker-container"
                  >
                    <Text sx={{ fontWeight: 300, fontSize: '0.875rem' }}>{t('Start Date')}</Text>
                    <DatePicker
                      id="startDate-datepicker"
                      timeZone={timeZone}
                      onChange={(val) => {
                        const { startDate, endDate } = getValidatedStartDate(val);

                        return setSelectedDateAndPeriod({
                          ...selectedDateAndPeriod,
                          startDate,
                          endDate,
                        });
                      }}
                      dateValue={selectedDateAndPeriod.startDate}
                      dateFormat={inputDateFormat}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: ['100%', 'initial'],
                      display: 'inline-block',
                    }}
                    data-testid="end-date-picker-container"
                  >
                    <Text sx={{ fontWeight: 300, fontSize: '0.875rem' }}>{t('End Date')}</Text>
                    <DatePicker
                      id="endDate-datepicker"
                      timeZone={timeZone}
                      onChange={(val) => {
                        const { startDate, endDate } = getValidatedEndDate(val);

                        return setSelectedDateAndPeriod({
                          ...selectedDateAndPeriod,
                          startDate,
                          endDate,
                        });
                      }}
                      dateValue={selectedDateAndPeriod.endDate}
                      dateFormat={inputDateFormat}
                    />
                  </Box>
                </Flex>
                {formattedDateRange}
              </>
            );
            break;
          }
          default:
            formFields = null;
        }

        const closeModal = () => {
          handleReset();
          setVisible(false);
        };

        const openModal = () => {
          handleReset();
          setFieldValue('startDate', parseDateWithoutTimeZone(startDate));
          setFieldValue('endDate', parseDateWithoutTimeZone(endDate));
          setVisible(true);
          setSelectedDateAndPeriod(defaultSelectedDateAndPeriod);
        };

        const dateDisplay = areDatesWithinOneDay(startDate, endDate) ? (
          <ResponsiveContainer mobile={formatDayMonthYear(startDate)} desktop={formatMediumDate(startDate)} />
        ) : (
          <ResponsiveContainer
            mobile={
              <>
                {formatDayMonthYear(startDate)} - {formatDayMonthYear(endDate)}
              </>
            }
            desktop={
              <>
                {formatMediumDate(startDate, useEUTimeFormat)} - {formatMediumDate(endDate, useEUTimeFormat)}
              </>
            }
          />
        );

        return (
          <>
            <Button
              data-testid="time-frame-picker"
              variant="secondary"
              onClick={openModal}
              sx={{ minWidth: 'fit-content', padding: 0 }}
            >
              <Box sx={{ mr: 2 }}>{dateDisplay}</Box>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  minWidth: '8px',
                  svg: {
                    minWidth: '8px',
                    minHeight: '6px',
                  },
                }}
              >
                <DropdownArrowSVG width="8px" />
              </Box>
            </Button>
            <Modal
              title={t('Please select a time period')}
              visible={visible}
              okType="submit"
              onOk={async () => {
                const { startDate: submittedStartDate, endDate: submittedEndDate } = selectedDateAndPeriod;

                setFieldValue('startDate', submittedStartDate, true);
                setFieldValue('endDate', submittedEndDate, true);

                await submitForm();
                handleReset();
                closeModal();
              }}
              onCancel={() => {
                setSelectedDateAndPeriod(defaultSelectedDateAndPeriod);
                closeModal();
              }}
            >
              <Form onSubmit={handleSubmit} data-testid="time-period-form">
                <Box sx={{ ...formFieldStyles, mb: 4 }}>
                  <Label>
                    {t('Period range')}
                    <Select
                      menuPosition="fixed"
                      isSearchable={false}
                      value={periodRangeOptions.find((option) => option.value === values.period)}
                      onChange={(option: any) => {
                        if (!option) return;

                        setFieldValue('period', option.value, true);

                        const startDate = values.startDate.toJSDate();

                        switch (option.value) {
                          case 'prevFourWeek':
                            prevFourWeekValueHandler(Date.now());
                            break;
                          case 'day':
                            dateValueHandler(startDate);
                            break;
                          case 'week':
                            weekValueHandler(startDate);
                            break;
                          case 'month':
                            monthValueHandlers.month(monthOptions[values.startDate.month - 1]);
                            monthValueHandlers.year(findYearOption(values));
                            break;
                          case 'year':
                            yearValueHandler(findYearOption(values));
                            break;
                          case 'custom':
                            setFieldValue('startDate', values.startDate, true);
                            setFieldValue('endDate', values.endDate, true);
                            break;
                          default:
                            break;
                        }
                      }}
                      options={periodRangeOptions}
                      defaultValue={periodRangeOptions[0]}
                    />
                  </Label>
                </Box>
                {formFields}
                <ErrorMessage name="startDate" />
                <ErrorMessage name="endDate" />
              </Form>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};
