import { event } from 'react-ga';

export const containsAdminRole = (roles: string[] = []) =>
  Boolean(roles.map((v) => v.toUpperCase()).find((v) => v === 'ROLE_ADMIN' || v === 'ADMIN'));

// register user's event in GA
export const registerUserEvent = (action: any, label: any, username = '') => {
  const user = username;

  event({ category: user, action, label });
};
