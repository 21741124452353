import { useCallback } from 'react';
import { useAuthService } from '@power-ledger/auth-service';
import { useTranslation } from 'react-i18next';

import { successNotification } from '../lib/notifications';

let isLoggingOut = false;

export const useLogOut = () => {
  const { signOut } = useAuthService();
  const { t } = useTranslation();

  const logOut = useCallback(async () => {
    successNotification({
      action: 'LOGOUT',
      description: t('Logged out successfully'),
    });

    isLoggingOut = true;

    await signOut();

    isLoggingOut = false;
  }, [signOut, t]);

  return { logOut, isLoggingOut };
};
