import { memo, useRef } from 'react';
import { Box, Input, Button, Text } from 'theme-ui';

import { CloseOutlined } from '../../components/icons';
import { useTheme } from '../../hooks/use-theme';
import { FileInputProps } from './file-input.types';

const FileInputBase: React.FC<FileInputProps> = ({
  accept,
  disabled = false,
  selectedFile,
  onChange,
  chooseFileLabel,
  hint,
  error,
}) => {
  const { theme } = useTheme();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    onChange?.(file);
  };

  const removeFileInput = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onChange?.(undefined);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Input
        key={selectedFile?.name || chooseFileLabel}
        type="file"
        accept={accept}
        onChange={handleFileInputChange}
        disabled={disabled}
        sx={{ display: 'none' }}
        ref={fileInputRef}
      />
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{ width: '100%', position: 'relative' }}
        disabled={disabled}
      >
        <Text
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: selectedFile ? 'calc(100% - 40px)' : 'unset',
          }}
        >
          {selectedFile ? selectedFile.name : chooseFileLabel}
        </Text>
        {selectedFile && (
          <Box
            onClick={removeFileInput}
            sx={{
              display: 'flex',
              position: 'absolute',
              right: 1,
            }}
          >
            <CloseOutlined />
          </Box>
        )}
      </Button>

      {!!hint && !error && (
        <Text variant="small" sx={{ display: 'block', fontSize: 0, fontWeight: 500, lineHeight: '16px' }}>
          {hint}
        </Text>
      )}
      {!!error && (
        <Text
          variant="small"
          sx={{
            display: 'block',
            fontSize: 0,
            fontWeight: 500,
            lineHeight: '16px',
            color: theme.colors.warningLight,
          }}
        >
          {error}
        </Text>
      )}
    </Box>
  );
};

export const FileInput = memo(FileInputBase);
