import { Flex, Text } from 'theme-ui';

import { useTranslation } from 'react-i18next';
import { NoData } from '../../../components';

export const NoMeterPlaceHolder = () => {
  const { t } = useTranslation();

  return (
    <NoData
      text={
        <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
          <Text>{t("Looks like you don't have a meter connected yet.")}</Text>
          <Text>{t('You need a meter connected to view the page.')}</Text>
        </Flex>
      }
    />
  );
};
