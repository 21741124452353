// Initial default state when the application is first loaded into the browser
export const initialState = {
  subpages: {
    transactions: [
      {
        key: 1,
        tab: 'Overview',
        tabTitle: 'Overview',
        route: 'transactions',
      },
      {
        key: 2,
        tab: 'Records',
        tabTitle: 'Records',
        route: 'transactions/records',
      },
    ],
    usage: [
      {
        key: 1,
        tab: 'Overview',
        tabTitle: 'Overview',
        route: 'usage',
      },
      {
        key: 2,
        tab: 'Readings',
        tabTitle: 'Readings',
        route: 'usage/readings',
      },
      {
        key: 3,
        tab: 'Estimates',
        tabTitle: 'Estimates',
        route: 'usage/estimates',
      },
    ],
    batterySharing: [
      {
        key: 1,
        tab: 'Overview',
        tabTitle: 'Overview',
        route: 'battery-sharing',
      },
      {
        key: 2,
        tab: 'Events',
        tabTitle: 'Events',
        route: 'battery-sharing/events',
      },
      {
        key: 3,
        tab: 'Records',
        tabTitle: 'Records',
        route: 'battery-sharing/records',
      },
    ],
    trading: [
      {
        key: 1,
        tab: 'Direct trades',
        tabTitle: 'Direct trades',
        route: 'trading/direct-trades',
      },
      {
        key: 2,
        tab: 'Preferential trading',
        tabTitle: 'Preferential trading',
        route: 'trading/trades',
      },
      {
        key: 3,
        tab: 'Pricing',
        tabTitle: 'Pricing',
        route: 'trading/pricing',
      },
      {
        key: 4,
        tab: 'IOA Data',
        tabTitle: 'IOA Data',
        route: 'trading/ioa-data',
      },
      {
        key: 5,
        tab: 'Excess demand',
        tabTitle: 'Excess demand',
        route: 'trading/excess-demand',
      },
      {
        key: 6,
        tab: 'Excess energy',
        tabTitle: 'Excess energy',
        route: 'trading/excess-energy',
      },
    ],
    loyalty: [
      {
        key: 1,
        tab: 'My Campaigns',
        tabTitle: 'My Campaigns',
        route: 'loyalty/user-campaigns',
      },
      {
        key: 2,
        tab: 'Campaigns',
        tabTitle: 'Campaigns',
        route: 'loyalty/campaigns',
      },
      {
        key: 3,
        tab: 'Vouchers',
        tabTitle: 'Vouchers',
        route: 'loyalty/vouchers',
      },
    ],
  },
  settings: {
    includeEstimates: false,
    isEstimate: false,
  },
  dashboard: {
    billingStart: '',
    billingEnd: '',
    billingFrequency: '',
    accountId: 0,
    accountNumber: '',
    accountBalance: null,
    funFacts: [],
    meterTradeSummary: {},
    groupTradeSummary: {},
    meterTrades: [],
    lastMeterTrades: [],
    groupTrades: [],
    lastGroupTrades: [],
    rankings: {},
    weatherBySite: {},
    totalSavings: {},
  },
  transactions: {
    p2pTrade: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    gridTrade: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    renewablesTrade: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    vppTrade: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    lp2pTrade: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    ppaTrade: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    dailyFeeAH: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    transactionFeeAH: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    dailyFeePL: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    transactionFeePL: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    dailyFeeOther: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    transactionFeeOther: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
    },
    topSellers: [],
    topBuyers: [],
    intervals: [],
    tableTransactions: [],
    totalTableTransactions: 0,
    transactionSummaries: [],
  },
  energyMix: {
    totals: [],
    intervals: [],
    co2Savings: [],
  },
  usage: {
    usageImport: {
      amount: null,
      percent: null,
      average: null,
    },
    usageExport: {
      amount: null,
      percent: null,
      average: null,
    },
    imported: null,
    exported: null,
    usages: [],
    readings: [],
    totalReadings: 0,
  },
  billing: {
    billingPeriods: [],
    invoices: [],
  },
  batterySharing: {
    vppConfig: {
      isActive: true,
      mode: 'MANUAL',
      recurringDermsEventConfig: [],
      region: '',
      smartArbitrageConfig: [],
      tradingGroupName: '',
    },
    batteries: [],
    batterySummary: {
      serial: null,
      chargePercent: null,
      maxPower: null,
      lastUpdateStatus: null,
    },
    clusterSummary: {
      tradingGroup: null,
      serial: null,
      maxPower: null,
      description: null,
      chargePercent: null,
      availableBatteries: null,
    },
    vppSummary: {
      bought: null,
      boughtNetTax: null,
      imported: null,
      sold: null,
      soldNetTax: null,
      exported: null,
      count: null,
    },
    prices: [],
    dermEvents: [],
    events: [],
    totalEvents: 0,
  },
  prefTrade: {
    offers: [],
    totalOffers: 0,
  },
  pricing: {
    ratePeriods: [],
    dailyRates: [],
    gridRates: [],
    meterRates: [],
    totalMeterRates: 0,
    dynamicRates: {},
  },
  blockchain: {
    transaction: {
      transactionHash: '',
      buyerAddress: '',
      sellerAddress: '',
      amount: null,
      timestamp: 0,
    },
    transfer: {
      id: '',
      amount: null,
      destinationAccountId: '',
      metadata: {},
      payerAccountId: '',
      productType: '',
    },
  },
  appNotifications: {
    notifications: [],
    totalNotifications: 0,
  },
  rankings: {
    interstate: {
      components: [],
      data: {},
    },
    homestate: {
      youRank: 0,
      yourTotal: 0,
      components: [],
      data: {},
    },
    intrastate: {
      yourRank: 0,
      yourTotal: 0,
      yourState: '',
      components: [],
      data: {},
    },
  },
  controls: {},
};
