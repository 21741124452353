export enum AggregatedExcessType {
  AHEAD = 'AHEAD',
  REAL_TIME = 'REAL-TIME',
}

export interface AggregatedExcessData {
  minBuyRate: number;
  quantum: number;
  timestamp: string;
  tradingGroup: string;
  sell: number;
  type: AggregatedExcessType;
}
