import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { AuthStatus } from '../../auth-service-types';
import { CreateClientInstance } from '../adaptor-types';
import { OktaAdaptorTypes } from './okta-adaptor-types';

/**
 * Initiates the Okta Client with the given configuration. This will also subscribe to required events
 * @param config
 * @param helpers
 * @returns Client Instance and Destroy fn
 */
export const createClientInstance: CreateClientInstance<OktaAdaptorTypes> = (config, { onStatusChange }) => {
  const okta = new OktaAuth({
    issuer: `${config.baseURL}/oauth2/${config.issuerId}`,
    clientId: config.clientId,
    redirectUri: `${window.location.origin}/login/callback`,
    pkce: config.pkce ? OktaAuth.features.isPKCESupported() : false,
    restoreOriginalUri: async () => {
      // Intentionally left empty as we do not want to redirect
    },
  });

  const onStateUpdate = (manager: AuthState) => {
    console.warn('STATE SET: ' + manager.isAuthenticated);
    onStatusChange(manager.isAuthenticated ? AuthStatus.AUTHENTICATED : AuthStatus.NOT_AUTHENTICATED);
  };

  okta.authStateManager.subscribe(onStateUpdate);
  okta.start();

  const destroy = () => {
    okta.authStateManager.unsubscribe(onStateUpdate);
    okta.stop();
  };

  // Create Final Client Object
  const client = {
    baseURL: config.baseURL,
    okta,
  };

  client.okta.isAuthenticated().then((isAuth) => {
    onStatusChange(isAuth ? AuthStatus.AUTHENTICATED : AuthStatus.NOT_AUTHENTICATED);
  });

  return { client, destroy };
};
