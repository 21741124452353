import { memo } from 'react';
import { Box, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { ChartEmptyPlaceholderProps } from './chart-placeholder.types';

export const ChartPlaceholderBase: React.FC<ChartEmptyPlaceholderProps> = ({
  label = 'No data in selected date range',
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: [64, 0],
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-testid="chart-placeholder"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid',
          borderColor: 'textLightest',
          padding: 2,
        }}
      >
        <Text sx={{ fontSize: 1, color: 'textLightest' }}>{t(label)}</Text>
      </Box>
    </Box>
  );
};

export const ChartPlaceholder = memo(ChartPlaceholderBase);
