import React from 'react';
import PropTypes from 'prop-types';

export const LogoSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 39"
    aria-labelledby="title-power-ledger-logo"
    aria-describedby="desc-power-ledger-logo"
    role="img"
  >
    <title id="title-power-ledger-logo">Powerledger icon</title>
    <description id="desc-power-ledger-logo">
      A symbol representing the circular device from the Powerledger logo.
    </description>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-169.000000, -11.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g transform="translate(169.000000, 11.000000)">
          <rect
            opacity="0.86"
            transform="translate(18.500000, 6.500000) rotate(-45.000000)
          translate(-18.500000, -6.500000) "
            x="14"
            y="2"
            width="9"
            height="9"
          />
          <rect
            opacity="0.5"
            transform="translate(18.500000, 32.500000) rotate(-45.000000)
          translate(-18.500000, -32.500000) "
            x="14"
            y="28"
            width="9"
            height="9"
          />
          <rect
            opacity="0.56"
            transform="translate(12.500000, 29.500000) rotate(-14.999329)
          translate(-12.500000, -29.500000) "
            x="8"
            y="25"
            width="9"
            height="9"
          />
          <rect
            opacity="0.2"
            transform="translate(24.500000, 8.500000) rotate(-14.999329)
          translate(-24.500000, -8.500000) "
            x="20"
            y="4"
            width="9"
            height="9"
          />
          <rect
            opacity="0.62"
            transform="translate(8.500000, 24.500000) rotate(-75.000671)
          translate(-8.500000, -24.500000) "
            x="4"
            y="20"
            width="9"
            height="9"
          />
          <rect
            opacity="0.26"
            transform="translate(29.500000, 12.500000) rotate(-75.000671)
          translate(-29.500000, -12.500000) "
            x="25"
            y="8"
            width="9"
            height="9"
          />
          <rect
            opacity="0.68"
            transform="translate(6.500000, 18.500000) rotate(-45.000000)
          translate(-6.500000, -18.500000) "
            x="2"
            y="14"
            width="9"
            height="9"
          />
          <rect
            opacity="0.32"
            transform="translate(31.500000, 18.500000) rotate(-45.000000)
          translate(-31.500000, -18.500000) "
            x="27"
            y="14"
            width="9"
            height="9"
          />
          <rect
            opacity="0.74"
            transform="translate(8.500000, 12.500000) rotate(-14.999329)
          translate(-8.500000, -12.500000) "
            x="4"
            y="8"
            width="9"
            height="9"
          />
          <rect
            opacity="0.38"
            transform="translate(29.500000, 24.500000) rotate(-14.999329)
          translate(-29.500000, -24.500000) "
            x="25"
            y="20"
            width="9"
            height="9"
          />
          <rect
            opacity="0.8"
            transform="translate(12.500000, 8.500000) rotate(-75.000671)
          translate(-12.500000, -8.500000) "
            x="8"
            y="4"
            width="9"
            height="9"
          />
          <rect
            opacity="0.44"
            transform="translate(24.500000, 29.500000) rotate(-75.000671)
          translate(-24.500000, -29.500000) "
            x="20"
            y="25"
            width="9"
            height="9"
          />
        </g>
      </g>
    </g>
  </svg>
);

LogoSVG.defaultProps = {
  width: '42px',
  height: '42px',
};

LogoSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
