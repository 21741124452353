/** @jsxImportSource theme-ui */
import { FC, SVGProps } from 'react';
import { Button, Flex, ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { defaultTheme } from '@power-ledger/styles';

export type ControlledToggleProps = {
  optionOne: string;
  optionTwo: string;
  selected: boolean;
  onClick: () => void;
  icons?: SVGProps<SVGSVGElement>[];
  style?: ThemeUIStyleObject;
};
export type ControlledToggleType = FC<ControlledToggleProps>;

export const ControlledToggle: ControlledToggleType = ({
  optionOne,
  optionTwo,
  icons,
  selected,
  onClick,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{
        display: 'flex',
        width: 200,
        height: 50,
        borderRadius: 30,
        backgroundColor: 'inactiveLight',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-around',
        position: 'relative',
        '&:enabled:focus-visible': {
          backgroundColor: 'inactiveLight',
          outline: '-webkit-focus-ring-color auto 1px',
        },
        '&:hover,&:enabled:hover, &:enabled:focus': {
          backgroundColor: 'inactiveLight',
        },
        '&:before': {
          position: 'absolute',
          zIndex: 3,
        },
        ...style,
      }}
      onClick={onClick}
      aria-label={selected ? optionOne : optionTwo}
    >
      <Flex
        sx={{
          width: '50%',
          height: '90%',
          m: 1,
          borderRadius: 30,
          justifyContent: 'space-around',
          alignItems: 'center',
          cursor: 'pointer',
          position: 'absolute',
          backgroundColor: 'background',
          left: 0,
          transform: selected && `translateX(calc(100% - ${defaultTheme.space[1] * 2}px))`,
          '*': {
            stroke: selected ? 'text' : 'black',
            color: selected ? 'text' : 'black',
            transform: selected && `translateX(calc(100% - ${defaultTheme.space[1] * 2}px))`,
          },
          transitionProperty: ['color', 'transform'],
          transition: '.4s ease-in-out',
        }}
      />
      <Flex
        sx={{
          position: 'relative',
          alignItems: 'center',
          color: selected ? 'black' : 'text',
          '*': {
            stroke: selected ? 'black' : 'text',
            transitionProperty: 'stroke',
            transition: '.2s ease',
          },
          transitionProperty: 'color',
          transition: '.4s ease',
        }}
      >
        {t(optionOne)}
        <Flex sx={{ ml: 2 }}>{icons && icons?.length > 1 && icons[0]}</Flex>
      </Flex>
      <Flex
        sx={{
          position: 'relative',
          alignItems: 'center',
          color: selected ? 'text' : 'black',
          '*': {
            stroke: selected ? 'text' : 'black',
            transitionProperty: 'stroke',
            transition: '.2s ease',
          },
          transitionProperty: 'color',
          transition: '.4s ease',
        }}
      >
        {t(optionTwo)}
        <Flex sx={{ ml: 2 }}>{icons && icons?.length > 1 && icons[1]}</Flex>
      </Flex>
    </Button>
  );
};
