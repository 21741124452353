import { FC, SVGProps, useMemo } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '../icons';
import { IconProps } from '../icons/common';

export type TogglingEyeProps = {
  hidden: boolean;
} & IconProps &
  SVGProps<any>;

export const TogglingEye: FC<TogglingEyeProps> = ({ hidden, ...props }) => {
  const Icon = useMemo(() => (hidden ? EyeInvisibleOutlined : EyeOutlined), [hidden]);
  return <Icon style={{ cursor: 'pointer' }} {...props} />;
};
