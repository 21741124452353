import { memo } from 'react';
import { Box } from 'theme-ui';

import { LoadingOverlay } from '..';
import { FullScreenLoadingOverlayViewProps } from './full-screen-loading-overlay.types';

const FullScreenLoadingOverlayViewBase: React.FC<FullScreenLoadingOverlayViewProps> = (props) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 50,
        width: 50,
      }}
    >
      <LoadingOverlay {...props} />
    </Box>
  );
};

export const FullScreenLoadingOverlayView = memo(FullScreenLoadingOverlayViewBase);
