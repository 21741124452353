import { SignOut } from '../adaptor-types';
import { OktaAdaptorTypes } from './okta-adaptor-types';

export const signOut: SignOut<OktaAdaptorTypes> = async (client) => {
  // Due to Okta requiring a redirect when using, we need to manually perform
  // a clear of tokens rather then use the signOut functionality
  // https://github.com/okta/okta-auth-js#signout
  await client.okta.revokeAccessToken();
  await client.okta.revokeRefreshToken();
  await client.okta.closeSession();
};
