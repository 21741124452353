import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TransactionTypeTranslationKeyMapping } from '../../../../../../types';
import { LegendLabelProps } from './legend-label.types';

interface DisplayTooltipElementProps {
  top: number;
  left: number;
  text: string;
}

const createTooltipElement = () => {
  const tooltipElement = document.createElement('div');

  tooltipElement.style.position = 'fixed';
  tooltipElement.style.background = 'rgb(8, 31, 41)';
  tooltipElement.style.color = 'white';
  tooltipElement.style.opacity = '0.9';
  tooltipElement.style.padding = '8px 16px';
  tooltipElement.style.borderRadius = '8px';
  tooltipElement.style.fontWeight = '300';
  tooltipElement.style.zIndex = '10';
  tooltipElement.style.pointerEvents = 'none';
  tooltipElement.style.fontSize = '0.875rem';

  document.body.appendChild(tooltipElement);

  const cleanup = () => {
    tooltipElement.remove();
  };

  const displayTooltip = ({ top, left, text }: DisplayTooltipElementProps) => {
    tooltipElement.style.display = 'block';
    tooltipElement.style.top = `${top + 24}px`;
    tooltipElement.style.left = `${left}px`;
    tooltipElement.innerHTML = text;
  };

  const hideTooltip = () => {
    tooltipElement.style.display = 'none';
  };

  return { tooltipElement, displayTooltip, hideTooltip, cleanup };
};

export const LegendLabel: React.FC<LegendLabelProps> = ({ labelKey, label }) => {
  const ref = useRef<SVGGElement>(null);
  const tooltipControl = useRef<ReturnType<typeof createTooltipElement>>();
  const { t, i18n } = useTranslation();

  // TODO: We need to find the actual grid meter name and replace it here instead of hard-coding it to smartENERGY.
  const tooltipText = t('All late readings are traded with {{ gridMeterName }}.', {
    gridMeterName: 'smartENERGY',
  });

  const isLateReading = i18n.language === 'de' && labelKey === TransactionTypeTranslationKeyMapping.CATCH_UP;

  useEffect(() => {
    if (!isLateReading) return;

    tooltipControl.current = createTooltipElement();

    return () => {
      tooltipControl.current?.cleanup();
    };
  }, [isLateReading]);

  useEffect(() => {
    const containerElement = ref.current?.parentElement?.parentElement;

    if (!isLateReading || !containerElement) return;

    ref.current.parentElement.innerHTML = label;

    const containerElementMouseEnterHandler = () => {
      const { top, left } = containerElement.getBoundingClientRect();

      tooltipControl.current?.displayTooltip({ top, left, text: tooltipText });
    };

    const containerElementMouseLeaveHandler = () => {
      tooltipControl.current?.hideTooltip();
    };

    containerElement.addEventListener('mouseenter', containerElementMouseEnterHandler);
    containerElement.addEventListener('mouseleave', containerElementMouseLeaveHandler);

    return () => {
      containerElement.removeEventListener('mouseenter', containerElementMouseEnterHandler);
      containerElement.removeEventListener('mouseleave', containerElementMouseLeaveHandler);
    };
  }, [label, isLateReading, tooltipText]);

  if (!isLateReading) return <>{label}</>;

  return <g ref={ref}>{label}</g>;
};
