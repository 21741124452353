/** @jsxImportSource theme-ui */
import { ThemeUIStyleObject, Box, Flex } from 'theme-ui';
import { Children, FC, isValidElement, ReactElement } from 'react';

export type TabPaneProps = { key: string; tab?: string | ReactElement };
export type TabPaneType = FC<TabPaneProps>;

export type TabsProps = {
  activeKey?: string;
  variant?: string;
  onChange?: any;
  large?: boolean;
  noLeftTabBorderRadius?: boolean;
  noRightTabBorderRadius?: boolean;
  sx?: ThemeUIStyleObject;
  activeStyles?: ThemeUIStyleObject;
};

export type TabsType = TabsProps & {
  children?: ReactElement<TabPaneProps> | ReactElement<TabPaneProps>[];
  TabPane?: TabPaneType;
};

const conditionalTabStyles = ({
  active,
  activeStyles,
  leftMost,
  rightMost,
  noLeftTabBorderRadius,
  noRightTabBorderRadius,
  large,
}: {
  activeStyles: ThemeUIStyleObject;
  active: boolean;
  leftMost: boolean;
  rightMost: boolean;
  noLeftTabBorderRadius: boolean;
  noRightTabBorderRadius: boolean;
  large: boolean;
}) => {
  let styles = {};
  if (leftMost && !noLeftTabBorderRadius) styles = { ...styles, borderTopLeftRadius: 4 };
  if (rightMost) styles = { ...styles, borderRightWidth: 0 };
  if (rightMost && !noRightTabBorderRadius) styles = { ...styles, borderTopRightRadius: 4 };
  if (large) {
    styles = {
      ...styles,
      fontSize: 3,
      textTransform: 'capitalize',
    };
  }
  if (active) {
    styles = {
      ...styles,
      bg: 'secondaryDark',
      color: 'textDarkerLightest',
      borderBottomColor: 'transparent',
    };

    if (Object.keys(activeStyles).length > 0) {
      styles = {
        ...styles,
        ...activeStyles,
      };
    }
  }

  return styles;
};

export const Tabs = ({
  children = [],
  activeKey = '',
  noLeftTabBorderRadius = false,
  noRightTabBorderRadius = false,
  onChange,
  large = false,
  sx,
  activeStyles = {},
}: TabsType) => {
  let activeChild = null;
  Children.forEach(children, (child) => {
    if (child?.key === activeKey) activeChild = child;
  });

  return (
    <Flex sx={{ flexDirection: 'column', height: '100%', ...sx }}>
      <Flex sx={{ alignItems: 'center' }}>
        {Children.map(children, (child, idx) => {
          if (isValidElement(child)) {
            return (
              <Flex
                variant="tabs"
                key={child.key}
                onClick={() => onChange(child.key)}
                sx={conditionalTabStyles({
                  activeStyles: activeStyles,
                  active: activeKey === child.key,
                  leftMost: !idx,
                  rightMost: idx === (children as []).length - 1,
                  noLeftTabBorderRadius,
                  noRightTabBorderRadius,
                  large,
                })}
              >
                {child.props.tab || 'Tab Title'}
              </Flex>
            );
          }
          return null;
        })}
      </Flex>
      <Box sx={{ overflowY: 'auto', height: '100%' }}>{activeChild}</Box>
    </Flex>
  );
};

const TabPane: TabPaneType = ({ children }) => <Box sx={{ variant: 'tabs.tabPanes' }}>{children}</Box>;

Tabs.TabPane = TabPane;
