export type ResetPasswordText = {
  title: string;
  description: string;
  successTitle: string;
  successDescription: string;
  returnToLoginText: string;
};

export const defaultText = (t: any): ResetPasswordText => ({
  title: t('Enter your new password'),
  description: t(
    'modal.reset-password-confirmation',
    'Your password must be at least 8 characters long, containing at least one uppercase letter, one lowercase letter and one number.'
  ),
  successTitle: t("You've reset your password"),
  successDescription: t(
    'modal.reset-password-success',
    'You can now use your new password to access your account. Click the link below to return to login.'
  ),
  returnToLoginText: t('Return to login'),
});

export default defaultText;
