import { VerifyPasswordResetResponse } from '../../auth-service-types';
import { VerifyPasswordReset } from '../adaptor-types';
import { OktaAdaptorTypes } from './okta-adaptor-types';

export const verifyPasswordReset: VerifyPasswordReset<OktaAdaptorTypes> = async (
  client,
  { password, recoveryToken }
) => {
  const tokenCheckTx = await client.okta.verifyRecoveryToken({ recoveryToken });

  if (tokenCheckTx.status !== 'SUCCESS') {
    throw new Error('Recovery token failed');
  }

  if (!tokenCheckTx.resetPassword) {
    throw new Error('Unable to reset password with given token');
  }

  await tokenCheckTx.resetPassword({ newPassword: password });

  return VerifyPasswordResetResponse.SUCCESS;
};
