import { ThemeUIStyleObject } from 'theme-ui';
import { graphsScheme } from '../colors';
import { CustomThemeProperty } from './types';

const graphMargin = {
  top: 20,
  bottom: 50,
  right: 50,
  left: 50,
};

const chartConfig = {
  line: {
    tickWidth: 24,
  },
  bar: {
    tickWidth: 24,
  },
};

export const graphs: ThemeUIStyleObject & {
  colorScheme: any;
  margins: any;
  chartConfig: any;
} = CustomThemeProperty('graphs', {
  colorScheme: graphsScheme,
  chartConfig,
  margins: graphMargin,
  background: 'transparent',
  fontFamily: 'body',
  fontSize: 0,
  fontWeight: 'light',
  textColor: 'text',
  symbolSize: 12,
  axis: {
    domain: {
      line: {
        stroke: '#b2bfca',
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: '#b2bfca',
        strokeWidth: 1,
      },
      text: {
        fontWeight: 'light',
      },
    },
    legend: {
      text: {
        fontSize: 2,
        fontWeight: 'light',
      },
    },
  },
  grid: {
    line: {
      stroke: '#b2bfca',
      strokeWidth: 1,
    },
  },
  legends: {
    hidden: {
      symbol: {
        fill: 'greyDark',
        opacity: 0.6,
      },
      text: {
        fill: '#92a4b4',
        opacity: 0.6,
      },
    },
    text: {
      fontWeight: 'light',
    },
  },
  labels: {
    text: {},
  },
  markers: {
    lineColor: '#000000',
    lineStrokeWidth: 1,
    text: {},
  },
  dots: {
    text: {},
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    backgroundColor: 'rgb(8,31,41)',
    borderRadius: 4,
    py: 2,
    px: 3,
    fontWeight: 'light',
    opacity: 0.9,
    container: {
      background: 'background',
      color: 'inherit',
      fontSize: 'inherit',
      borderRadius: '2px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '5px 9px',
    },
    basic: {
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
    },
    chip: {
      marginRight: 7,
    },
    table: {},
    tableCell: {
      padding: '3px 5px',
    },
    tableCellValue: {
      fontWeight: 'bold',
    },
  },
  crosshair: {
    line: {
      stroke: '#000000',
      strokeWidth: 1,
      strokeOpacity: 0.75,
      strokeDasharray: '6 6',
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    outline: {
      fill: 'none',
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
  },
}) as any;
