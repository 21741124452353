import { useCallback, useEffect } from 'react';

interface UseClickAwayProps<T extends HTMLElement> {
  containerRef: React.RefObject<T | null>;
  clickAwayHandler?: () => void;
  isClickingAway?: (event: MouseEvent) => boolean;
}

export const useClickAway = <T extends HTMLElement>({
  containerRef,
  clickAwayHandler,
  isClickingAway,
}: UseClickAwayProps<T>) => {
  const handleClickAway = useCallback(
    (event: MouseEvent) => {
      if (!containerRef.current || containerRef.current.contains(event.target as HTMLElement)) return;

      if (typeof isClickingAway === 'function' && !isClickingAway(event)) return;

      clickAwayHandler?.();
    },
    [containerRef, clickAwayHandler, isClickingAway]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickAway);

    return () => {
      window.removeEventListener('click', handleClickAway);
    };
  }, [handleClickAway]);
};
