import { useLocation } from 'react-router';

export enum PageName {
  DASHBOARD = 'Dashboard',
  TRANSACTIONS = 'Transactions',
  USAGE = 'Usage',
  BILLING = 'Billing',
  BATTERY_SHARING = 'Battery Sharing',
  LOYALTY = 'Loyalty',
  TRADING = 'Trading',
  PRICING = 'Pricing',
  ACCOUNT = 'Account',
  NOTIFICATIONS = 'Notifications',
  RANKINGS = 'Rankings',
  COMMUNITY_MAP = 'Community Map',
  YOUR_ENERGY_MIX = 'Your Energy Mix',
}

const nameToPathMapping = {
  [PageName.DASHBOARD]: '/dashboard',
  [PageName.TRANSACTIONS]: '/transactions',
  [PageName.USAGE]: '/usage',
  [PageName.BILLING]: '/billing',
  [PageName.BATTERY_SHARING]: '/battery-sharing',
  [PageName.LOYALTY]: '/loyalty',
  [PageName.TRADING]: '/trading',
  [PageName.PRICING]: '/pricing',
  [PageName.ACCOUNT]: '/account',
  [PageName.NOTIFICATIONS]: '/notifications',
  [PageName.RANKINGS]: '/rankings',
  [PageName.COMMUNITY_MAP]: '/community-map',
  [PageName.YOUR_ENERGY_MIX]: '/your-energy-mix',
};

const getCurrentPageName = (pathName: string) => {
  for (const [pageName, path] of Object.entries(nameToPathMapping)) {
    if (pathName.startsWith(path)) return pageName;
  }

  return 'Dashboard';
};

export const useCurrentPage = () => {
  const location = useLocation();

  return getCurrentPageName(location.pathname);
};
