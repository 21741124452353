import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Circle, GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { Grid, Box, Text, Heading } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { MeterLatLng } from '../../../../types';
import { GOOGLE_MAPS_API_KEY } from '../../lib/envHelpers';
import { countDecimalPlaces } from '../../utils/number';
import { MeterMapProps, MarkerClustererGroupProps, DisplayedLocation } from './meter-map.types';

const DEFAULT_ZOOM = 12;

const mapsOptions = {
  googleMapsApiKey: GOOGLE_MAPS_API_KEY as string,
  // enable the geometry library for the computation of geometric data on the surface of the earth
  libraries: ['geometry'],
};

const fitMeterBounds = (map: google.maps.Map, meterLocations: MeterLatLng[]) => {
  if (meterLocations.length === 0) return;

  const bounds = new google.maps.LatLngBounds();

  meterLocations.forEach(({ latitude, longitude }) => {
    bounds.extend({ lat: latitude, lng: longitude });
  });
  // set the viewport to contain the given bounds
  map.setCenter(bounds.getCenter());
  map.setZoom(DEFAULT_ZOOM);
};

const MarkerClustererGroup: React.FC<MarkerClustererGroupProps> = memo(
  ({ locations, zoom, setSelectedLocation }) => {
    const shouldRemoveMarker = zoom < 14;
    const [displayedLocations, setDisplayedLocations] = useState<DisplayedLocation[]>([]);

    useEffect(() => {
      const uniqueLocations: DisplayedLocation[] = [];

      for (const location of locations) {
        const foundIndex = uniqueLocations.findIndex(
          (displayedLocation) =>
            displayedLocation.longitude === location.longitude &&
            displayedLocation.latitude === location.latitude
        );

        if (foundIndex === -1) {
          uniqueLocations.push({
            longitude: location.longitude,
            latitude: location.latitude,
            info: [location],
          });
        } else {
          uniqueLocations[foundIndex].info.push(location);
        }

        setDisplayedLocations(uniqueLocations);
      }
    }, [locations]);

    const handleSelectLocation = (item: DisplayedLocation) => {
      // Workaround solution to reset list of item.info displayed on UI
      setSelectedLocation(() => null);
      setSelectedLocation(() => item);
    };

    return (
      <>
        {displayedLocations.map((item) => {
          const shouldDisplayAreaRadius =
            [1, 2].includes(countDecimalPlaces(item.latitude)) &&
            [1, 2].includes(countDecimalPlaces(item.longitude));

          const hasHomeMeter = item.info.some((location) => !!location.isHomeMeter);
          const iconURL = `https://maps.google.com/mapfiles/ms/icons/${
            hasHomeMeter ? 'yellow' : 'blue'
          }-dot.png`;

          return shouldDisplayAreaRadius ? (
            <>
              <Circle
                radius={1000}
                center={{ lat: item.latitude, lng: item.longitude }}
                onClick={() => handleSelectLocation(item)}
                options={{
                  strokeColor: '#FFBF00',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#FFBF00',
                  fillOpacity: 0.3,
                }}
              />
              {shouldRemoveMarker ? (
                <Marker
                  position={{ lat: item.latitude, lng: item.longitude }}
                  animation={google.maps.Animation.DROP}
                  onClick={() => handleSelectLocation(item)}
                  icon={{ url: iconURL }}
                />
              ) : undefined}
            </>
          ) : (
            <Marker
              position={{ lat: item.latitude, lng: item.longitude }}
              animation={google.maps.Animation.DROP}
              onClick={() => handleSelectLocation(item)}
              icon={{ url: iconURL }}
            />
          );
        })}
      </>
    );
  }
);

const MeterMapBase = (
  { myMeterLocations, myNearbyMeterLocations, loadNeighbouringMeters, mapStyles }: MeterMapProps,
  ref: React.ForwardedRef<{ handleHome: () => void }>
) => {
  const { t } = useTranslation();

  const labels = useSelector(({ host }: any) => host.labels);
  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const [selectedLocation, setSelectedLocation] = useState<DisplayedLocation | null>(null);
  const [zoomIndex, setZoomIndex] = useState<number>(DEFAULT_ZOOM);
  const [currentMapLocation, setCurrentMapLocation] = useState<google.maps.LatLngLiteral>();

  const { isLoaded, loadError } = useLoadScript(mapsOptions as any);

  const displayedMyMeterLocations = myMeterLocations.map((location) => ({ ...location, isHomeMeter: true }));

  const userTypes = [
    { type: 'household', label: t('Household') },
    { type: 'business', label: t('Business') },
    { type: 'farmer', label: t('Farmer') },
    { type: 'municipality', label: t('Municipality') },
    { type: 'others', label: t('Others') },
  ];

  const generationTypes = [
    { type: 'solar_pv', label: t('Solar PV') },
    { type: 'wind', label: t('Wind') },
    { type: 'biomass', label: t('Biomass') },
    { type: 'hydro', label: t('Hydro') },
    { type: 'nuclear', label: t('Nuclear') },
  ];

  const assetTypes = [
    { type: 'unknown', label: t('Unknown') },
    { type: 'grid', label: labels.gridName || t('Grid') },
    { type: 'consumer', label: t('Consumer') },
    { type: 'producer', label: t('Producer') },
    { type: 'prosumer', label: t('Prosumer') },
  ];

  // formats the user type for display
  const formatUserType = (type: string) => {
    const typeIdx = userTypes.findIndex((val) => type && type.toLowerCase() === val.type);

    return typeIdx !== -1 ? userTypes[typeIdx].label : '';
  };

  // formats the generation type for display
  const formatGenerationType = (type: string) => {
    const typeIdx = generationTypes.findIndex((val) => type && type.toLowerCase() === val.type);

    return typeIdx !== -1 ? generationTypes[typeIdx].label : '';
  };

  // formats the asset type for display
  const formatAssetType = (type: string) => {
    const typeIdx = assetTypes.findIndex((val) => type && type.toLowerCase() === val.type);

    return typeIdx !== -1 ? assetTypes[typeIdx].label : '';
  };

  useEffect(() => {
    if (mapRef) {
      // set the map viewport for the given meter locations
      fitMeterBounds(mapRef, myMeterLocations);
    }
  }, [mapRef, myMeterLocations]);

  useEffect(() => {
    let mounted = true;
    let watchPositionRef: number;
    if (navigator.geolocation) {
      watchPositionRef = navigator.geolocation.watchPosition((position) => {
        if (mounted) {
          setCurrentMapLocation({
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          });
        }
      });
    }
    return () => {
      mounted = false;
      if (watchPositionRef) {
        navigator.geolocation.clearWatch(watchPositionRef);
      }
    };
  }, []);

  const onMapZoom = useCallback(() => {
    const zoom = mapRef?.getZoom();

    if (zoom) {
      setZoomIndex(zoom);
    }
  }, [mapRef]);

  const onMapPositionChanged = useCallback(() => {
    if (mapRef && mapRef.getBounds()) {
      const bounds = mapRef.getBounds();
      const mapCenter = mapRef.getCenter();

      if (bounds && mapCenter) {
        const neLatLng = bounds.getNorthEast();
        const radiusInMetres = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, neLatLng);
        loadNeighbouringMeters(mapCenter, radiusInMetres);
      }
    }
  }, [loadNeighbouringMeters, mapRef]);

  const handleHome = useCallback(() => {
    if (mapRef) {
      fitMeterBounds(mapRef, myMeterLocations);
    }
  }, [mapRef, myMeterLocations]);

  const onLoad = useCallback((map: google.maps.Map) => {
    // store a reference to the google map instance in state
    setMapRef(map);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        handleHome,
      };
    },
    [handleHome]
  );

  if (loadError) {
    return <Box>{t('Map cannot be loaded right now.')}</Box>;
  }

  if (!isLoaded || myMeterLocations.length === 0) {
    return <Box>{t('Meters missing location.')}</Box>;
  }

  return (
    <GoogleMap
      id="meter-locations"
      mapContainerStyle={{
        height: '100vh',
        width: '100%',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        overflow: 'hidden',
      }}
      // store a reference to the google map instance in state
      onLoad={onLoad}
      onDragEnd={onMapPositionChanged}
      onZoomChanged={() => {
        onMapPositionChanged();
        onMapZoom();
      }}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy',
      }}
    >
      {currentMapLocation ? <Marker position={currentMapLocation} /> : null}
      <MarkerClustererGroup
        locations={[...displayedMyMeterLocations, ...myNearbyMeterLocations]}
        setSelectedLocation={setSelectedLocation}
        zoom={zoomIndex}
      />
      {selectedLocation ? (
        <InfoWindow
          options={{ maxWidth: 300 }}
          position={{ lat: selectedLocation.latitude, lng: selectedLocation.longitude }}
        >
          <>
            {selectedLocation.info.map((selectedMeter) => (
              <Box
                key={selectedMeter.meterUid}
                sx={{
                  ':not(:last-child)': {
                    paddingBottom: 3,
                    borderBottom: '1px solid',
                    borderColor: 'greyLight',
                  },
                }}
              >
                <Heading as="h5" sx={{ variant: 'styles.h4' }}>
                  {selectedMeter.meterName} {selectedMeter.isHomeMeter ? ` - (${t('My meter')})` : ''}
                </Heading>
                <Grid columns={[1, null, 2]} gap={2}>
                  {selectedMeter.userType && (
                    <>
                      <Text>{t('User type')}</Text>
                      <Text>{formatUserType(selectedMeter.userType)}</Text>
                    </>
                  )}
                  {selectedMeter.generationType && (
                    <>
                      <Text>{t('Generation type')}</Text>
                      <Text>{formatGenerationType(selectedMeter.generationType)}</Text>
                    </>
                  )}
                  {selectedMeter.assetSize && (
                    <>
                      <Text>{t('Size (kW)')}</Text>
                      <Text>{selectedMeter.assetSize / 1000}</Text>
                    </>
                  )}
                  {selectedMeter.assetType && (
                    <>
                      <Text>{t('Asset type')}</Text>
                      <Text>{formatAssetType(selectedMeter.assetType)}</Text>
                    </>
                  )}
                </Grid>
              </Box>
            ))}
          </>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  );
};

export const MeterMap = memo(forwardRef(MeterMapBase));
