import { FC } from 'react';
import { Box, IconButton, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { FarNext } from '../icons/FarNext';
import { Next } from '../icons/Next';
import { Select } from '../Select';

export type PaginationPropsType = {
  goToPage: (page: number) => void;
  pageCount: number;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  setPageSize: (pageSize: number) => void;
  itemsLabel?: string;
  currentPage?: number;
};

export const Pagination: FC<PaginationPropsType> = ({
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  goToPage,
  pageCount,
  currentPage,
}) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ padding: 2, color: 'primary' }}>
      <IconButton
        aria-label={t('first page')}
        sx={{ variant: 'tables.normal.pagination.button' }}
        disabled={currentPage === 0}
        onClick={() => goToPage(0)}
        css={{
          transform: 'rotate(180deg)',
        }}
      >
        <FarNext />
      </IconButton>
      <IconButton
        aria-label={t('previous page')}
        sx={{ variant: 'tables.normal.pagination.button' }}
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
        css={{
          transform: 'rotate(180deg)',
        }}
      >
        <Next />
      </IconButton>
      <Box>
        <Select />
      </Box>
      <IconButton
        aria-label={t('Next Page')}
        sx={{ variant: 'tables.normal.pagination.button' }}
        disabled={!canNextPage}
        onClick={() => nextPage()}
      >
        <Next />
      </IconButton>

      <IconButton
        aria-label={t('last page')}
        sx={{ variant: 'tables.normal.pagination.button' }}
        disabled={currentPage === pageCount - 1}
        onClick={() => goToPage(pageCount - 1)}
      >
        <FarNext />
      </IconButton>
    </Flex>
  );
};

export default Pagination;
