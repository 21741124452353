/** @jsxImportSource theme-ui */
import { FC, useCallback, useState, useMemo } from 'react';
import { Box } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { FormInput, Eye, NoEye } from '../..';
import { PasswordStrengthBar } from './PasswordStrengthBar';
import { PasswordInputProps, StrengthResult } from './PasswordInput.types';

export const PasswordInput: FC<PasswordInputProps> = ({
  name,
  placeholder,
  disabled,
  value,
  showStrength = false,
  action,
  hasErrorIndicator = true,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  const togglePasswordShown = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const checkStrength = (password: any) => {
    let standardStrength = 0;
    let extraStrength = 0;
    const missingRequirements = [];

    const specialCharacters = /[!~`@#$%^&*(),.?":{}|<>]/;
    const letters = /[a-z]/;
    const capitalLetters = /[A-Z]/;
    const numbers = /[0-9]/;

    if (!password) {
      return {
        strength: {
          standardStrength: 0,
          extraStrength: 0,
        },
        missingRequirements: [],
        length: 0,
      };
    }

    // Password Requirments
    password.length >= 12 ? standardStrength++ : missingRequirements.push(t('Minimum 12 characters'));
    letters.test(password) ? standardStrength++ : missingRequirements.push(t('Include a lower case letter'));
    numbers.test(password) ? standardStrength++ : missingRequirements.push(t('Include a number'));
    capitalLetters.test(password)
      ? standardStrength++
      : missingRequirements.push(t('Include a capital letter'));
    specialCharacters.test(password)
      ? standardStrength++
      : missingRequirements.push(t('No special characters found'));

    // Great Password condition
    password.length >= 18 && extraStrength++;

    return {
      strength: {
        standardStrength: standardStrength,
        extraStrength: extraStrength,
      },
      missingRequirements: missingRequirements,
      length: password.length,
    };
  };

  const [strengthResults, setStrengthResults] = useState<StrengthResult>({
    strength: {
      standardStrength: 0,
      extraStrength: 0,
    },
    missingRequirements: [''],
    length: 0,
  });

  const eyeComponent = useMemo(
    () => (
      <button
        onClick={togglePasswordShown}
        aria-label="show-password"
        type="button"
        sx={{
          display: 'flex',
          cursor: 'pointer',
          alignContent: 'center',
          backgroundColor: 'transparent',
          border: 'none',
        }}
      >
        {showPassword ? (
          <NoEye size={4} color="" noSvgPositioning />
        ) : (
          <Eye size={4} color="" noSvgPositioning />
        )}
      </button>
    ),
    [showPassword, togglePasswordShown]
  );

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <FormInput
        {...props}
        name={name}
        hasErrorIndicator={hasErrorIndicator}
        value={value}
        placeholder={placeholder}
        onInput={(event) =>
          setStrengthResults(() => checkStrength(event?.currentTarget ? event?.currentTarget?.value : ''))
        }
        disabled={disabled}
        type={showPassword ? 'text' : 'password'}
        onKeyUp={({ key }: { key: string }) => {
          if (action && key === 'Enter') action.onClick();
        }}
        sx={{ color: strengthResults.strength.standardStrength < 5 && showStrength && 'red' }}
        suffix={eyeComponent}
        aria-label="password-input"
        autoCapitalize="none"
      />
      {showStrength && <PasswordStrengthBar strengthResult={strengthResults} />}
    </Box>
  );
};

export default PasswordInput;
