import appNotifications from './app-notifications';
import batterySharing from './battery-sharing';
import billing from './billing';
import blockchain from './blockchain';
import dashboard from './dashboard';
import prefTrade from './pref-trade';
import pricing from './pricing';
import settings from './settings';
import subpages from './subpages';
import transactions from './transactions';
import usage from './usage';
import rankings from './rankings';
import energyMix from './energy-mix';

// Main app reducer that integrates all reducers into one to be used across the components
export default {
  appNotifications,
  batterySharing,
  billing,
  blockchain,
  dashboard,
  prefTrade,
  pricing,
  settings,
  subpages,
  transactions,
  usage,
  rankings,
  energyMix,
};
