import { FC, useMemo } from 'react';

import { ResponsiveLine, Layer, CustomLayerProps, SliceTooltipProps } from '@nivo/line';
// @ts-ignore
import { linearGradientDef, DotsItem, useTheme } from '@nivo/core';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { format } from 'date-fns';
import { map } from 'lodash';

import { Button, ButtonGroup } from '../Button';

export type PerformanceChartDotType = { x: string; y: number; volume?: number };

export type PerformanceChartDataType = [{ id: string; data: PerformanceChartDotType[]; color: string }];

export type LegendItemType = FC<{
  text: string;
  color: string;
  sx?: ThemeUIStyleObject;
}>;

export type PerformanceChartTooltipInfoType = { label: string; value: string };

export type PerformanceChartFilterType = {
  label: string;
  value: string;
  filter: string;
};

export type PerformancePrecisionType = 'hour' | 'day' | 'month' | 'year';

export type PerformanceChartPropsType = {
  priceData: PerformanceChartDataType;
  otherData?: PerformanceChartDataType;
  title?: string;
  headerValue: number;
  headerLabel?: string;
  precision?: PerformancePrecisionType;
  filters: { label: string; value: string }[];
  activeFilter: string;
  setFilter: (value: string) => void;
  hideLegend?: boolean;
};

type CurrentSlice = {
  currentSlice: SliceTooltipProps['slice'];
};

export const PerformanceChartTooltipInfo: FC<PerformanceChartTooltipInfoType> = ({ label, value }) => (
  <Flex sx={{ alignItems: 'center', mb: 1 }}>
    <Box sx={{ fontWeight: 'bold', fontSize: 0, color: 'textDarker', mr: 2 }}>{`${label}:`}</Box>
    <Box sx={{ fontWeight: 'light', fontSize: 0 }}>{value}</Box>
  </Flex>
);

const LegendItem: LegendItemType = ({ text, color, sx }) => (
  <Flex sx={{ alignItems: 'center', ...sx }}>
    <Box sx={{ width: 2, height: 2, borderRadius: 6, bg: color, mr: 2 }} />
    <Box sx={{ fontSize: 0, color: 'textDarker' }}>{text}</Box>
  </Flex>
);

export const PerformanceChart: FC<PerformanceChartPropsType> = ({
  priceData,
  otherData = [],
  title = '',
  headerValue,
  headerLabel,
  filters = [],
  activeFilter,
  setFilter,
  hideLegend,
}) => {
  const { t } = useTranslation();

  const bottomAxisConfig = useMemo(() => {
    if (activeFilter === '1D') return { format: '%H:%M', tickValues: 'every 2 hour', tickRotation: 0 };
    if (activeFilter === '1W') return { format: '%d/%m', tickValues: 'every day', tickRotation: 0 };
    if (activeFilter === '1M') return { format: '%d/%m', tickValues: 'every 3 day', tickRotation: 0 };
    if (activeFilter === '3M') return { format: '%d/%m', tickValues: 'every 8 day', tickRotation: 0 };
    return { format: '%m/%Y', tickValues: 'every month', tickRotation: 45 };
  }, [activeFilter]);

  const getMinMaxValues = () => {
    const {
      0: { data: values },
    } = priceData;
    const yValues = values.map((v) => v.y);
    return [Math.min(...yValues).toFixed(2), Math.max(...yValues).toFixed(2)];
  };

  const [currencyInt, currencyFrac] = useMemo(() => {
    const currencyTotal = currency(headerValue, {
      separator: ',',
      symbol: '',
    }).format();
    return currencyTotal.split('.');
  }, [headerValue]);

  const PerformanceChartFilters = useMemo(
    () =>
      filters.length && (
        <ButtonGroup>
          {filters.map(({ label, value }) => (
            <Button
              key={value}
              variant="text"
              sx={value === activeFilter ? { color: 'primary' } : {}}
              onClick={() => setFilter(value)}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      ),
    [filters, activeFilter, setFilter]
  );

  const ActivePoint = ({ currentSlice, ...props }: CustomLayerProps & CurrentSlice) => {
    const theme = useTheme();

    return (
      <g>
        {currentSlice?.points.map((point) => (
          <DotsItem
            key={point.id}
            x={point.x}
            y={point.y}
            datum={point.data}
            symbol={props.pointSymbol as any}
            size={props.pointSize as any}
            color="white"
            borderWidth={2}
            borderColor={point.color}
            labelYOffset={props.pointLabelYOffset}
          />
        ))}
      </g>
    );
  };

  return (
    <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
      <ResponsiveLine
        data={[...priceData, ...otherData]}
        margin={{ top: 70, right: 0, bottom: 70, left: 0 }}
        enablePoints={false}
        pointSize={12}
        enableGridY={false}
        enableGridX={false}
        enableSlices="x"
        animate={false}
        xScale={{
          format: '%Y-%m-%dT%H:%M:%S.%L%Z',
          type: 'time',
          min: 'auto',
          max: 'auto',
        }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        sliceTooltip={({ slice }) => {
          const { points } = slice;
          return (
            <Box sx={{ bg: 'foreground', p: 2, borderRadius: 3, pr: 4 }}>
              {points.map(({ data: pointData, color, serieId }: any) => (
                <Box key={serieId}>
                  <Box
                    sx={{
                      color,
                      fontWeight: 'bold',
                      fontSize: 0,
                      fontFamily: 'MintGroteskV08',
                    }}
                  >
                    {serieId}
                  </Box>
                  <PerformanceChartTooltipInfo
                    label={t('Time')}
                    value={format(pointData.x, 'MMM d, yyyy h:mm aa')}
                  />

                  <PerformanceChartTooltipInfo
                    label={t('Unit Price')}
                    value={currency(pointData.y as number, {
                      separator: ',',
                    }).format()}
                  />
                  {!!pointData.volume && (
                    <>
                      <PerformanceChartTooltipInfo
                        label={t('Volume')}
                        value={currency(pointData.volume, {
                          separator: ',',
                          symbol: '',
                          precision: 0,
                        }).format()}
                      />
                      <PerformanceChartTooltipInfo
                        label={t('Value')}
                        value={currency(pointData.volume * pointData.y).format()}
                      />
                    </>
                  )}
                </Box>
              ))}
            </Box>
          );
        }}
        axisBottom={{
          ...bottomAxisConfig,
          tickPadding: 35,
          tickSize: 0,
        }}
        axisLeft={null}
        axisRight={{
          tickSize: 0,
          tickValues: getMinMaxValues(),
          tickPadding: -60,
        }}
        enableArea
        areaOpacity={1}
        colors={{ datum: 'color' }}
        theme={{
          axis: {
            legend: { text: { fill: 'white', opacity: 0.5, letterSpacing: 2 } },
            ticks: {
              text: {
                fill: 'white',
                opacity: 0.5,
              },
            },
          },
          crosshair: {
            line: {
              stroke: 'white',
              strokeWidth: 1,
              strokeOpacity: 1,
            },
          },
        }}
        defs={[
          linearGradientDef('gradient', [
            { offset: 0, color: 'inherit', opacity: 0.25 },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradient' }]}
        layers={
          ['grid', 'axes', 'areas', 'lines', 'crosshair', 'slices', 'mesh', 'legends', ActivePoint] as Layer[]
        }
      />
      <Flex
        sx={{
          display: 'none',
          position: 'absolute',
          top: 0,
          color: 'textDark',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: 1,
          width: '100%',
          p: 3,
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          {title && <Box sx={{ mr: 4 }}>{title}</Box>}
          <Flex sx={{ alignItems: 'start' }}>
            {headerLabel && <Box sx={{ mr: 1 }}>{headerLabel}</Box>}
            <Box>{t('USD$')}</Box>
            <Box sx={{ position: 'relative' }}>
              <Flex
                sx={{
                  color: 'text',
                  alignItems: 'start',
                  position: 'absolute',
                }}
              >
                <Box sx={{ fontSize: 4, mt: -1 }}>{currencyInt}</Box>
                <Box sx={{ fontSize: 0 }}>.{currencyFrac}</Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: 'center' }}>
          {!hideLegend && (
            <LegendItem text={t(priceData[0].id)} color={priceData[0].color || 'secondary'} sx={{ mr: 2 }} />
          )}
          {!hideLegend &&
            map(otherData, (od) => (
              <LegendItem key={od.id} text={t(od.id)} color={od.color} sx={{ mr: 2 }} />
            ))}
          {PerformanceChartFilters}
        </Flex>
      </Flex>
    </Box>
  );
};

export default PerformanceChart;
