import { shallowEqual } from 'react-redux';
import { useAppSelector } from './use-app-selector';
import { PageName, useCurrentPage } from './use-current-page';
import { useFeatureSet } from './use-feature-set';

/**
 * For any given page, if the All Meter integration is done,
 * and the page has new UI enabled then the All Meter option should be available
 */
export const useHasAllMetersOption = () => {
  // All Meter option is only available on the new UI
  const {
    showNewDashboardPage,
    showNewUsagePage,
    showVisionEnergyMixPage,
    showNewTransactionsPage,
    showNewTradingPage,
  } = useFeatureSet();

  const pageWithNewUiAndAllMeterOptionEnabled: Record<string, boolean> = {
    [PageName.DASHBOARD]: showNewDashboardPage,
    [PageName.USAGE]: showNewUsagePage,
    [PageName.YOUR_ENERGY_MIX]: showVisionEnergyMixPage,
    [PageName.TRANSACTIONS]: showNewTransactionsPage,
    [PageName.TRADING]: showNewTradingPage,
  };

  const currentPage = useCurrentPage();

  const meters = useAppSelector(({ meters: metersStore }) => metersStore.meters, shallowEqual);

  return {
    hasAllMeterOption: pageWithNewUiAndAllMeterOptionEnabled[currentPage] && meters?.length > 1,
  };
};
