import { Theme, ThemeUIStyleObject } from 'theme-ui';

const commonStyles: ThemeUIStyleObject = {
  fontSize: 1,
  fontFamily: 'MintGroteskV08',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: 'bold',
  p: 3,
  color: 'background',
  transition: 'base',
  outline: 'none',
  '&:disabled': {
    backgroundColor: 'inactiveDark',
    cursor: 'not-allowed',
    color: 'inactiveLight',
    'div svg path': {
      stroke: 'inactiveLight',
    },
  },
};

const commonIconPillStyles: ThemeUIStyleObject = {
  py: 2,
  justifyContent: 'space-between',
  fontSize: 0,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 6,
  'div svg': {
    ml: 2,
    mb: 1,
  },
  '&:disabled': {
    backgroundColor: 'inactiveDark',
    borderColor: 'inactiveDark',
    cursor: 'not-allowed',
    color: 'inactiveLight',
    'div svg path': {
      color: 'inactiveLight',
      fill: 'inactiveLight',
    },
  },
};

export const buttons: Theme['buttons'] = {
  primary: {
    ...commonStyles,
    borderRadius: 6,
    bg: 'primary',
    '&:enabled:hover, &:enabled:focus': {
      bg: 'primaryDark',
    },
  },
  secondary: {
    ...commonStyles,
    fontSize: 1,
    p: 0,
    pb: 1,
    bg: 'inherit',
    color: 'white',
    borderRadius: 0,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'primary',
    '&:enabled:hover, &:enabled:focus': {
      color: 'primaryDark',
      borderBottomColor: 'primaryDark',
    },
  },
  pill: {
    ...commonStyles,
    bg: 'primary',
    color: 'background',
    borderRadius: 6,
    'div svg path': {
      transition: 'base',
      color: 'background',
      stroke: 'background',
      fill: 'none',
    },
    '&:enabled:hover, &:enabled:focus': {
      bg: 'primaryDark',
      color: 'background',
    },
    compact: {
      py: 2,
      px: 3,
      fontSize: 0,
      borderRadius: 6,
      bg: 'primary',
      color: 'background',
      cursor: 'pointer',
      borderWidth: 1,
      borderColor: 'primary',
      borderStyle: 'solid',
      fontWeight: 'bold',
      fontFamily: 'MintGroteskV08',
      transition: 'base',
      '&:enabled:hover': {
        bg: 'primaryDark',
        borderColor: 'primaryDark',
        color: 'background',
      },
      '&:disabled': {
        backgroundColor: 'muted',
        borderColor: 'muted',
        cursor: 'not-allowed',
        color: 'background',
        opacity: 0.3,
      },
    },
    compactSecondary: {
      py: 2,
      px: 3,
      fontSize: 0,
      borderRadius: 6,
      bg: 'secondary',
      color: 'text',
      cursor: 'pointer',
      borderWidth: 1,
      borderColor: 'secondary',
      borderStyle: 'solid',
      fontWeight: 'bold',
      fontFamily: 'MintGroteskV08',
      transition: 'base',
      '&:enabled:hover': {
        bg: 'secondaryDark',
        borderColor: 'secondaryDark',
      },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.3,
      },
    },
  },
  pillInverted: {
    ...commonStyles,
    fontSize: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'primary',
    color: 'primary',
    bg: 'transparent',
    borderRadius: 6,
    'div svg path': {
      transition: 'base',
      color: 'primary',
      fill: 'primary',
    },
    '&:enabled:hover, &:enabled:focus': {
      color: 'background',
      bg: 'primary',
      'div svg path': {
        color: 'background',
        fill: 'background',
      },
    },
    '&:disabled': {
      backgroundColor: 'muted',
      borderColor: 'muted',
      cursor: 'not-allowed',
      color: 'background',
      opacity: 0.3,
    },
    compact: {
      ...commonStyles,
      fontSize: 0,
      py: 2,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'primary',
      color: 'primary',
      bg: 'transparent',
      borderRadius: 6,
      'div svg path': {
        transition: 'base',
        color: 'primary',
        fill: 'primary',
      },
      '&:enabled:hover, &:enabled:focus': {
        color: 'background',
        bg: 'primary',
        'div svg path': {
          color: 'background',
          fill: 'background',
        },
      },
    },
  },
  avatar: {
    ...commonStyles,
    padding: 0,
    width: 7,
    height: 7,
    borderRadius: 6,
    color: 'white',
    bg: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'div svg path': {
      transition: 'base',
      color: 'white',
      fill: 'white',
    },
    '&:enabled:hover, &:enabled:focus': {
      bg: 'primary',
      color: 'background',
      'div svg path': {
        transition: 'base',
        color: 'background',
        fill: 'background',
      },
    },
  },
  avatarSecondary: {
    ...commonStyles,
    padding: 0,
    width: 7,
    height: 7,
    borderRadius: 6,
    color: 'white',
    bg: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'secondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'div svg path': {
      transition: 'base',
      color: 'white',
      fill: 'white',
    },
    '&:enabled:hover, &:enabled:focus': {
      bg: 'secondary',
      color: 'background',
      'div svg path': {
        transition: 'base',
        color: 'background',
        fill: 'background',
      },
    },
  },
  text: {
    fontFamily: 'MintGroteskV08',
    backgroundColor: 'transparent',
    color: 'text',
    border: 'none',
    padding: 0,
    margin: 0,
    display: 'inline-flex',
    width: 'initial',
    height: 'initial',
    outline: 'none',
    cursor: 'pointer',
    transition: 'base',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
      color: 'text',
      cursor: 'not-allowed',
    },
    '&:enabled:hover, &:active, &:enabled:focus': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      '&:disabled': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: 'text',
      },
    },
  },
  underlined: {
    fontFamily: 'MintGroteskV08',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    color: 'text',
    borderBottomWidth: '2',
    borderBottomColor: 'primary',
    borderBottomStyle: 'solid',
    borderRadius: 0,
    padding: 0,
    margin: 0,
    display: 'inline-flex',
    width: 'initial',
    height: 'initial',
    cursor: 'pointer',
    transition: 'base',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      textDecoration: 'none',
      color: 'text',
      cursor: 'not-allowed',
    },
    '&:enabled:hover, &:active, &:enabled:focus': {
      borderBottomColor: 'primaryDark',
    },
  },
  icon: {
    ...commonStyles,
    transition: 'none',
    padding: 0,
    color: 'primary',
    '&:disabled': {
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
      opacity: 0.3,
    },
  },
  iconWithBackground: {
    ...commonStyles,
    transition: 'none',
    padding: 2,
    borderRadius: 6,
    color: 'primary',
    bg: 'secondary',
  },
  action: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: 'MintGroteskV08',
    '&:enabled:hover, &:enabled:active, &:enabled:focus': {
      textDecoration: 'underline',
    },
    '&:disabled:hover, &:disabled:active, &:disabled:focus': {
      cursor: 'default !important',
    },
    iconContainer: {
      flexShrink: 0,
      width: 5,
      height: 5,
      bg: 'secondary',
      borderRadius: 6,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  block: {
    cursor: 'pointer',
    bg: 'secondaryDarker',
    position: 'relative',
    width: 150,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    transition: 'base',
    '&:enabled:hover': {
      borderColor: 'secondaryLighter',
    },
    name: {
      fontSize: 1,
      color: 'primary',
      textTransform: 'uppercase',
      textAlign: 'center',
      px: 3,
      transition: 'base',
    },
  },
  link: {
    ...commonStyles,
    py: 2,
    px: 3,
    borderRadius: 6,
    fontSize: 1,
    color: 'primaryDarkest',
    bg: 'white',
  },
  iconPill: {
    primary: {
      ...commonStyles,
      ...commonIconPillStyles,
      borderColor: 'transparent',
      color: 'text',
      bg: 'transparent',
      'div svg path': {
        transition: 'base',
        color: 'primary',
        fill: 'primary',
      },
      '&:enabled:hover, &:enabled:focus': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'primary',
      },
      outlined: {
        ...commonStyles,
        ...commonIconPillStyles,
        borderColor: 'primary',
        color: 'text',
        bg: 'transparent',
        'div svg path': {
          transition: 'base',
          color: 'primary',
          fill: 'primary',
        },
        '&:enabled:hover, &:enabled:focus': {
          color: 'background',
          bg: 'primary',
          'div svg path': {
            color: 'background',
            fill: 'background',
          },
        },
      },
      small: {
        ...commonStyles,
        ...commonIconPillStyles,
        px: 2,
        py: 0,
        borderColor: 'transparent',
        color: 'text',
        bg: 'transparent',
        'div svg path': {
          transition: 'base',
          color: 'primary',
          fill: 'primary',
        },
        '&:enabled:hover, &:enabled:focus': {
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'primary',
        },
      },
      filled: {
        ...commonStyles,
        ...commonIconPillStyles,
        borderColor: 'transparent',
        color: 'background',
        bg: 'primary',
        'div svg path': {
          transition: 'base',
          color: 'background',
          fill: 'background',
        },
        '&:enabled:hover, &:enabled:focus': {
          color: 'background',
          bg: 'primaryDarker',
        },
      },
    },
    secondary: {
      ...commonStyles,
      ...commonIconPillStyles,
      borderColor: 'transparent',
      color: 'text',
      bg: 'transparent',
      'div svg path': {
        transition: 'base',
        color: 'secondary',
        fill: 'secondary',
      },
      '&:enabled:hover, &:enabled:focus': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'secondary',
      },
      outlined: {
        ...commonStyles,
        ...commonIconPillStyles,
        borderColor: 'secondary',
        color: 'text',
        bg: 'transparent',
        'div svg path': {
          transition: 'base',
          color: 'secondary',
          fill: 'secondary',
        },
        '&:enabled:hover, &:enabled:focus': {
          color: 'background',
          bg: 'secondary',
          'div svg path': {
            color: 'background',
            fill: 'background',
          },
          '&:disabled': {
            backgroundColor: 'muted',
            cursor: 'not-allowed',
            color: 'background',
            opacity: 0.3,
          },
        },
      },
      small: {
        ...commonStyles,
        ...commonIconPillStyles,
        px: 2,
        py: 0,
        borderColor: 'transparent',
        color: 'text',
        bg: 'transparent',
        'div svg path': {
          transition: 'base',
          color: 'secondary',
          fill: 'secondary',
        },
        '&:enabled:hover, &:enabled:focus': {
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'secondary',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          cursor: 'not-allowed',
          color: 'secondary',
          opacity: 0.3,
        },
      },
    },
  },
  /** LEM Button overrides **/
  LEM: {
    action: {
      fontFamily: 'MintGroteskV08',
      fontWeight: 'bold',
      fontSize: 3,
      cursor: 'pointer',
      color: 'secondaryDarker',
      transition: 'base',
      height: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:enabled:hover, &:enabled:active, &:enabled:focus': {
        bg: 'primaryDark',
      },
      '&:disabled': {
        bg: 'primaryDark',
        cursor: 'not-allowed',
      },
      '&:disabled:hover, &:disabled:active, &:disabled:focus': {
        bg: 'primaryDark',
      },
      iconContainer: {
        flexShrink: 0,
        width: 5,
        height: 5,
        bg: 'secondary',
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
};
