import { memo } from 'react';
import { Grid, Heading } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { useFeatureSet } from '../../../../hooks/use-feature-set';
import { useIsTATA } from '../../../../hooks/use-is-tata';
import { TradeType, TransactionType } from '../../../../../../types';
import { TransactionStatistic, Card, NoData } from '../../..';
import { TradingSummaryViewProps } from './trading-summary.types';

const TradingSummaryViewBase: React.FC<TradingSummaryViewProps> = ({
  tradeType,
  unit,
  symbol,
  transactionSummaries,
  translatedLabels,
}) => {
  const { t } = useTranslation();
  const isTATA = useIsTATA();
  const { displayZeroCurrencyValueAsDash } = useFeatureSet();

  return (
    <Card
      sx={{
        flex: 1,
        minWidth: 250,
        alignSelf: 'center',
      }}
      data-testid="trading-summary-panel"
      header={
        <Heading as="h2" sx={{ paddingY: 1 }} data-testid="trading-summary-panel-title">
          {t('Trading summary')}
        </Heading>
      }
    >
      {!transactionSummaries.length ? (
        <NoData text={t('No transactions found')} />
      ) : (
        <Grid columns={2} gap={3}>
          {transactionSummaries.map((transactionSummary) => {
            const foundDisplayedName = translatedLabels.find((label) =>
              label.type.includes(transactionSummary.name)
            );

            let displayedName = foundDisplayedName?.labelText || t('Unknown');

            if (isTATA && (foundDisplayedName?.type || []).includes(TransactionType.GRID)) {
              displayedName = t('{{ gridMeterName }} (will be confirmed after final settlement)', {
                gridMeterName: 'DISCOM',
              });
            }

            return (
              <TransactionStatistic
                unit={unit}
                label={displayedName}
                symbol={symbol}
                energyAmount={
                  tradeType === TradeType.BOUGHT ? transactionSummary.imported : transactionSummary.exported
                }
                price={
                  tradeType === TradeType.BOUGHT ? transactionSummary.viewBought : transactionSummary.viewSold
                }
                displayZeroCurrencyValueAsDash={displayZeroCurrencyValueAsDash}
              />
            );
          })}
        </Grid>
      )}
    </Card>
  );
};

export const TradingSummaryView = memo(TradingSummaryViewBase);
