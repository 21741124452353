export { default as clearDaySVG } from './clearDaySVG.svg';
export { default as clearNightSVG } from './clearNightSVG.svg';
export { default as cloudySVG } from './cloudySVG.svg';
export { default as fogSVG } from './fogSVG.svg';
export { default as hailSVG } from './hailSVG.svg';
export { default as partlyCloudyDaySVG } from './partlyCloudyDaySVG.svg';
export { default as partlyCloudyNightSVG } from './partlyCloudyNightSVG.svg';
export { default as rainSVG } from './rainSVG.svg';
export { default as sleetSVG } from './sleetSVG.svg';
export { default as snowSVG } from './snowSVG.svg';
export { default as thunderstormSVG } from './thunderstormSVG.svg';
export { default as tornadoSVG } from './tornadoSVG.svg';
export { default as windSVG } from './windSVG.svg';
