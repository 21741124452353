export { default as VoltCross } from './cross.svg';
export { default as VoltExport } from './export.svg';
export { default as VoltEye } from './eye.svg';
export { default as VoltEyeInvisible } from './eye-invisible.svg';
export { default as VoltLock } from './lock.svg';
export { default as VoltQuestionMark } from './question-mark.svg';
export { default as VoltUser } from './user.svg';
export { default as VoltHandshake } from './handshake.svg';
export { default as VoltLockDotted } from './lock-dotted.svg';
export { default as VoltSingleUser } from './single-user.svg';
