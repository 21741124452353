import { FC, useCallback } from 'react';
import { Flex } from 'theme-ui';

export type RadioButtonsProps = {
  value?: string | number;
  options: { label: string; value: string | number }[];
  onClick: any;
};

export const RadioButtons: FC<RadioButtonsProps> = ({ options, value, onClick }) => {
  const onKeyUp = useCallback(
    (e) => {
      if (!options.length) return;
      const keyPressed = e.code;
      const currentValueIndex = options.findIndex((option: any) => option.value === value);

      if (keyPressed === 'ArrowLeft' && currentValueIndex) onClick(options[currentValueIndex - 1].value);
      if (keyPressed === 'ArrowRight' && currentValueIndex < options.length - 1)
        onClick(options[currentValueIndex + 1].value);
    },
    [onClick, options, value]
  );

  return (
    <Flex sx={{ variant: 'radioButtons' }} tabIndex={0} onKeyUp={onKeyUp}>
      {options.map((option: any) => (
        <Flex
          key={option.value}
          onClick={() => onClick(option.value)}
          sx={{
            variant: 'radioButtons.button',
            ...(option.value === value
              ? {
                  bg: 'secondary',
                  color: 'black',
                  borderTopColor: 'secondary',
                  borderBottomColor: 'secondary',
                  borderLeftColor: 'secondary',
                  borderRightColor: 'secondary',
                }
              : {}),
          }}
        >
          {option.label}
        </Flex>
      ))}
    </Flex>
  );
};
