import { FC } from 'react';
import { Switch as ThemeSwitch, Flex, Box } from 'theme-ui';
import { useTranslation } from 'react-i18next';

export type SwitchProps = {
  uncheckedLabel?: string;
  checkedLabel?: string;
  checked?: boolean;
  onChange?: any;
};

export const Switch: FC<SwitchProps> = ({ checked, uncheckedLabel = 'Off', checkedLabel, onChange }) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Box
        sx={{
          variant: 'forms.switch.switchLabel',
          mr: 3,
          ...(!checked ? { color: 'highlight' } : {}),
        }}
      >
        {uncheckedLabel || t('On')}
      </Box>
      <Box sx={{ width: 'auto' }}>
        <ThemeSwitch checked={checked} onChange={(event) => onChange(event.target.checked)} />
      </Box>
      <Box
        sx={{
          variant: 'forms.switch.switchLabel',
          ml: 3,
          ...(checked ? { color: 'highlight' } : {}),
        }}
      >
        {checkedLabel || t('Off')}
      </Box>
    </Flex>
  );
};
