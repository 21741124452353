import { createSlice } from '@reduxjs/toolkit';

import { AsyncStatus } from '../../../../types';
import { uploadDayAheadReadings, uploadRealTimeReadings } from '../actions/ioa-management';

export interface IOAFileUploadState {
  status: AsyncStatus;
}

export interface IOAManagementState {
  dayAhead: IOAFileUploadState;
  realTime: IOAFileUploadState;
}

const initialState: IOAManagementState = {
  dayAhead: {
    status: AsyncStatus.IDLE,
  },
  realTime: {
    status: AsyncStatus.IDLE,
  },
};

const ioaManagementSlice = createSlice({
  name: 'ioaManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadDayAheadReadings.pending, (state) => {
      state.dayAhead.status = AsyncStatus.LOADING;
    });
    builder.addCase(uploadDayAheadReadings.fulfilled, (state) => {
      state.dayAhead.status = AsyncStatus.SUCCEEDED;
    });
    builder.addCase(uploadDayAheadReadings.rejected, (state) => {
      state.dayAhead.status = AsyncStatus.FAILED;
    });
    builder.addCase(uploadRealTimeReadings.pending, (state) => {
      state.realTime.status = AsyncStatus.LOADING;
    });
    builder.addCase(uploadRealTimeReadings.fulfilled, (state) => {
      state.realTime.status = AsyncStatus.SUCCEEDED;
    });
    builder.addCase(uploadRealTimeReadings.rejected, (state) => {
      state.realTime.status = AsyncStatus.FAILED;
    });
  },
});

export default ioaManagementSlice.reducer;
