import { lazy } from 'react';
import { LoginCallback } from './auth-callbacks/login';

export const AcceptTerms = lazy(() => import('./accept-terms'));
export const ForgotPassword = lazy(() => import('./forgot-password'));
export const EnableMFA = lazy(() => import('./enable-mfa'));
export const ResetPassword = lazy(() => import('./reset-password'));
export const LoginUser = lazy(() => import('./login/LoginUser'));
export const LoginUserMFASMS = lazy(() => import('./login/LoginUserMFASMS'));
export const EndOfProjectSplash = lazy(() => import('./end-of-project-splash'));

export const PublicRoutes = {
  AcceptTerms,
  ForgotPassword,
  EnableMFA,
  ResetPassword,
  LoginUser,
  LoginUserMFASMS,
  EndOfProjectSplash,
  LoginCallback,
};
export default PublicRoutes;
