import { Box, BoxProps } from 'theme-ui';
import { FC } from 'react';

export const FormContainer: FC<BoxProps> = ({ children, ...props }) => (
  <Box
    as="main"
    sx={{
      maxWidth: '500px',
      width: '100%',
      backgroundColor: 'background',
      padding: 4,
      borderRadius: 5,
    }}
    {...props}
  >
    {children}
  </Box>
);
