import { DateTime } from 'luxon';
import { IOAReadingsType, RequestToken } from '../../../../types';
import { ioaManagement } from '../endpoints';
import { uploadFile } from '../files';
import { ApiResponse } from '../types';
import { GET } from '../api-methods';

export const uploadDayAheadReadings = (
  file: File,
  token: RequestToken,
  settlementDate: DateTime
): Promise<ApiResponse> => {
  const urlSearchParams = new URLSearchParams({
    settlementDate: settlementDate.toFormat('yyyyMMdd'),
  });

  return uploadFile(file, `${ioaManagement.uploadDayAheadReadingsURL}?${urlSearchParams}`, token);
};

export const uploadRealTimeReadings = (
  file: File,
  token: RequestToken,
  settlementDate: DateTime
): Promise<ApiResponse> => {
  const urlSearchParams = new URLSearchParams({
    settlementDate: settlementDate.toFormat('yyyyMMdd'),
  });

  return uploadFile(file, `${ioaManagement.uploadRealtimeReadingsURL}?${urlSearchParams}`, token);
};

export const downloadReadingTemplates = (
  settlementDate: DateTime,
  readingsType: IOAReadingsType
): Promise<ApiResponse> => {
  const queryString = new URLSearchParams({
    settlementDate: settlementDate.toFormat('yyyyMMdd'),
    readingsType: readingsType === IOAReadingsType.DAY_AHEAD ? 'AHEAD' : 'REAL_TIME',
  });

  return GET(`${ioaManagement.downloadReadingTemplates}?${queryString}`);
};
