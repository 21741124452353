import { CustomThemeProperty } from './types';

export const tabs = CustomThemeProperty('tabs', {
  height: 8,
  px: 2,
  fontSize: 0,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  color: 'primary',
  bg: 'secondaryDarker',
  letterSpacing: 1,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: 'secondaryDarker',
  borderRightWidth: 1,
  borderRightStyle: 'solid',
  borderRightColor: 'secondaryDarker',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  fontWeight: 'light',
  position: 'relative',
  textTransform: 'uppercase',
  textAlign: 'center',
});
