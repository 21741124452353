import React, { useMemo } from 'react';
import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { Box, Flex, Text } from 'theme-ui';

import { useTranslation } from 'react-i18next';
import { useDynamicGraphTicks, useStandardBarChartConfig } from '..';
import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { HorizontalPercentageChartTooltip } from './HorizontalPercentageTooltip';

interface HorizontalPercentageChartProps {
  header?: React.FC | React.ReactNode | HTMLElement | string;
  values: {
    [id: string]: number;
  };
  labels?: (id: string | number) => string;
  chartKeys?: string[];
  colours?: Record<string, string>;
}

export const HorizontalPercentageChart = ({
  header,
  values,
  labels,
  chartKeys,
  colours,
}: HorizontalPercentageChartProps) => {
  const data = [{ ...values, date: 1 }];
  const keys = chartKeys || Object.keys(values);
  const chartHasData = Object.keys(values).length > 0;

  const { chartConfig } = useThemedColorScale();
  const { t } = useTranslation();
  const { ref, width } = useDynamicGraphTicks(data, chartConfig.bar.tickWidth);
  const { legends, ...commonBarProps } = useStandardBarChartConfig({
    width,
    legendData: keys,
  });

  const tooltipGenerator = useMemo(
    () =>
      (
        point: BarTooltipProps<{
          date: number;
        }>
      ) =>
        <HorizontalPercentageChartTooltip point={point} labels={labels} />,
    [labels]
  );

  const colorSchemeWithOverrides = useMemo(
    () => (colours ? keys.map((key) => colours[key]) : commonBarProps.colors),
    [keys, colours, commonBarProps]
  );

  const modifiedCommonBarProps = useMemo(() => {
    const {
      theme: { axis, ...theme },
      ...restOfProps
    } = commonBarProps as any;

    return {
      ...restOfProps,
      // Omit axis from theme as it is not needed
      theme,
    };
  }, [commonBarProps]);

  const realLegend = useMemo(
    () =>
      legends.map(({ legendData, ...legend }: any) => ({
        ...legend,
        data: legendData.map((key: string) => ({
          id: key,
          label: labels ? labels(key) : key,
          color: colorSchemeWithOverrides[keys.indexOf(key)],
        })),
      })),
    [legends, labels, keys, colorSchemeWithOverrides]
  );

  return (
    <Box mb={4}>
      {header && <Box sx={{ variant: 'statistic.header' }}>{header}</Box>}
      <Box sx={{ height: chartHasData ? 80 + 20 * legends.length : 60 }} ref={ref}>
        {chartHasData ? (
          <ResponsiveBar
            {...modifiedCommonBarProps}
            data={data}
            keys={keys}
            margin={{
              top: 30 + 20 * legends.length,
              bottom: 0,
              right: 0,
              left: 0,
            }}
            padding={0}
            legendLabel={labels ? (value) => labels(value.id) : undefined}
            indexBy="date"
            layout="horizontal"
            maxValue={1}
            minValue={0}
            indexScale={{ type: 'band', round: true }}
            borderColor="transparent"
            enableGridY={false}
            enableGridX={false}
            axisBottom={false}
            axisLeft={false}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            tooltip={tooltipGenerator}
            enableLabel
            labelSkipWidth={32}
            label={displayLabelAsPercentage}
            colors={colorSchemeWithOverrides}
            legends={realLegend}
          />
        ) : (
          <Flex
            sx={{
              marginTop: 3,
              height: 50,
              width: '100%',
              backgroundColor: '#DEE3E8',
              fontSize: '12px',
              color: '#92A4B4',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text sx={{ fontSize: 1 }}>{t('No data available')}</Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

const displayLabelAsPercentage = (args: { value: number | null }) =>
  `${Math.floor((args.value || 0) * 10000) / 100}%`;

export default HorizontalPercentageChart;
