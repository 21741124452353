import { useHostConfig } from '../../contexts/host-config-context';

export const useHostLogoLogic: (inverse: boolean) => { logo: string | undefined } = (inverse = false) => {
  const { tradingGroup } = useHostConfig();

  if (!tradingGroup) {
    return { logo: undefined };
  }

  return { logo: !inverse ? tradingGroup.logo : tradingGroup.logoInverse };
};
