import { questionMarkSVG } from '@power-ledger/assets';

import { createIconStyles, IconProps, IconWrapper } from './common';

export const QuestionCircleOutlined: React.FC<IconProps> = ({ sx, ...rest }) => (
  <IconWrapper
    icon={questionMarkSVG}
    sx={createIconStyles({ variant: 'outlined', sx, ...rest }, true)}
    {...rest}
  />
);
