import { Input as ThemeInput, Flex, Box, InputProps as InputPropsBase, ThemeUIStyleObject } from 'theme-ui';
import { FC, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import { Stop } from '..';

export type InputProps = {
  prefix?: FC | ReactNode | HTMLElement | string;
  suffix?: FC | ReactNode | HTMLElement | string;
  variant?: string;
  containerSx?: ThemeUIStyleObject;
} & Omit<InputPropsBase, 'prefix'>;

const component: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { suffix, prefix, variant = 'input', name = '', onInput, placeholder, containerSx, sx, ...props },
  ref
) => (
  <Flex
    sx={{
      alignItems: 'center',
      position: 'relative',
      ...containerSx,
    }}
  >
    <ThemeInput
      as={ThemeInput}
      name={name}
      variant={variant}
      placeholder={placeholder}
      onInput={onInput}
      sx={{
        pl: prefix ? 4 : 3,
        pr: suffix ? 4 : 3,
        position: 'relative',
        ...sx,
      }}
      {...props}
      ref={ref}
    />
    {prefix && (
      <Box
        sx={{
          position: 'absolute',
          left: 2,
          color: 'textDarker',
          fontWeight: 'light',
        }}
      >
        {prefix}
      </Box>
    )}
    {suffix && (
      <Flex
        sx={{
          flexShrink: 0,
          ml: 2,
          color: 'textDarker',
          fontWeight: 'light',
        }}
      >
        {suffix}
      </Flex>
    )}
  </Flex>
);

export const Input = forwardRef(component);
