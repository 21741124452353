import { TradeUnit } from '../../../../types';
import { ItemValue, Statistic } from '..';
import { TransactionStatisticViewProps } from './transaction-statistic.types';

export const TransactionStatisticView: React.FC<TransactionStatisticViewProps> = ({
  unit,
  label,
  price = null,
  symbol,
  energyAmount,
  loading,
  displayZeroCurrencyValueAsDash = false,
}) => {
  const displayedEnergyAmount = energyAmount || null;
  const displayedPrice = energyAmount ? price : null;

  const shouldDisplayDash = displayZeroCurrencyValueAsDash && price === 0;

  if (unit === TradeUnit.USAGE) {
    return (
      <Statistic
        header={label}
        type="energy"
        numberType="meta"
        suffix={
          <ItemValue
            value={shouldDisplayDash ? '-' : displayedPrice}
            type={shouldDisplayDash ? 'plaintext' : 'currency'}
            symbol={symbol}
          />
        }
        loading={loading}
      >
        {displayedEnergyAmount}
      </Statistic>
    );
  }

  return (
    <Statistic
      header={label}
      symbol={symbol}
      suffix={<ItemValue value={displayedEnergyAmount} type="energy" numberType="meta" />}
      loading={loading}
      type={shouldDisplayDash ? 'plaintext' : 'currency'}
    >
      {shouldDisplayDash ? '-' : displayedPrice}
    </Statistic>
  );
};
