import { FC } from 'react';
import { ResponsiveLine, Layer, CustomLayerProps, SliceTooltipProps } from '@nivo/line';
// @ts-ignore
import { linearGradientDef, DotsItem, useTheme } from '@nivo/core';
import { Box, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
// @ts-ignore

export type AreaChartDataType = [{ id: string; data: { x: number; y: number }[]; color: string }];

type CurrentSlice = {
  currentSlice: SliceTooltipProps['slice'];
};

export type AreaMargin = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

export type AreaChartPropsType = {
  data: AreaChartDataType;
  margin?: AreaMargin;
  height?: string;
};

export type AreaChartTooltipInfoType = {
  label: string;
  value: string | number;
};

export const AreaChartTooltipInfo: FC<AreaChartTooltipInfoType> = ({ label, value }) => (
  <Flex sx={{ alignItems: 'center' }}>
    <Box sx={{ fontWeight: 'bold', fontSize: 0, color: 'textDarker', mr: 2 }}>{`${label}:`}</Box>
    <Box sx={{ fontWeight: 'light', fontSize: 0 }}>{value}</Box>
  </Flex>
);

export const AreaChart: FC<AreaChartPropsType> = ({ data, margin = {}, height = '400px' }) => {
  const { t } = useTranslation();

  const ActivePoint = ({ currentSlice, ...props }: CustomLayerProps & CurrentSlice) => {
    const theme = useTheme();

    return (
      <g>
        {currentSlice?.points.map((point) => (
          <DotsItem
            key={point.id}
            x={point.x}
            y={point.y}
            datum={point.data}
            symbol={props.pointSymbol as any}
            size={props.pointSize as any}
            color="white"
            borderWidth={2}
            borderColor={point.color}
            labelYOffset={props.pointLabelYOffset}
          />
        ))}
      </g>
    );
  };
  return (
    <Box sx={{ height }}>
      <ResponsiveLine
        data={data}
        margin={margin}
        enablePoints={false}
        pointSize={12}
        enableGridX={false}
        enableSlices="x"
        animate={false}
        xScale={{
          type: 'linear',
          stacked: false,
          min: 'auto',
          max: 'auto',
        }}
        sliceTooltip={({ slice }) => {
          const { points } = slice;
          return (
            <Box sx={{ bg: 'foreground', p: 2, borderRadius: 3, pr: 4 }}>
              {points.map(({ data: pointData, color, serieId }) => (
                <Box key={serieId}>
                  <Box sx={{ color, fontWeight: 'bold', fontSize: 0 }}>{serieId}</Box>

                  <AreaChartTooltipInfo label={t('Units')} value={pointData.yFormatted} />
                  <AreaChartTooltipInfo
                    label={t('Price')}
                    value={currency(pointData.x as number, {
                      separator: ',',
                    }).format()}
                  />
                </Box>
              ))}
            </Box>
          );
        }}
        axisRight={{
          legend: 'UNITS',
          legendOffset: 30,
          legendPosition: 'middle',
          tickValues: [],
        }}
        axisLeft={{ tickPadding: 20 }}
        enableArea
        axisBottom={{
          format: (value) => currency(value, { separator: ',' }).format(),
        }}
        areaOpacity={1}
        colors={{ datum: 'color' }}
        theme={{
          axis: {
            legend: { text: { fill: 'white', opacity: 0.5, letterSpacing: 2 } },
            ticks: {
              text: {
                fill: 'white',
                opacity: 0.5,
              },
            },
          },
          grid: {
            line: {
              stroke: 'white',
              strokeWidth: 1,
              strokeOpacity: 0.25,
            },
          },
          crosshair: {
            line: {
              stroke: 'white',
              strokeWidth: 1,
              strokeOpacity: 1,
            },
          },
        }}
        defs={[
          linearGradientDef('gradient', [
            { offset: 0, color: 'inherit', opacity: 0.25 },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradient' }]}
        layers={
          ['grid', 'axes', 'areas', 'lines', 'crosshair', 'slices', 'mesh', 'legends', ActivePoint] as Layer[]
        }
      />
    </Box>
  );
};

export default AreaChart;
