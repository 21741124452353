import { ThemeUIStyleObject } from 'theme-ui';

import { CustomThemeProperty } from './types';

const option: ThemeUIStyleObject = {
  fontFamily: 'MintGroteskV08',
  bg: 'primary',
  color: 'background',
  px: 3,
  py: 2,
  cursor: 'pointer',
  '&:hover': {
    bg: 'primaryLight',
  },
};

export const recGraph = CustomThemeProperty('recGraph', {
  selectors: {
    control: {
      fontFamily: 'MintGroteskV08',
      color: 'background',
      bg: 'primary',
      px: 2,
      borderRadius: 6,
      fontWeight: 'bold',
    },
    option: {
      ...option,
    },
    optionActive: {
      ...option,
      bg: 'primaryLight',
    },
    dropdownIndicator: {
      svg: { transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)' },

      lineHeight: 0,
      padding: 0,
      height: 48,
      width: 48,
      justifyContent: 'center',
      alignItems: 'center',
    },
    indicatorSeparator: {
      display: 'none',
    },
  },
  loadingOverlay: {
    position: 'absolute',
    bg: 'foreground',
    height: '100%',
    width: '100%',
    transition: '0.3s ease opacity',
    pointerEvents: 'none',
    zIndex: 1,
  },
});
