import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CrossPath = styled.path`
  fill: ${(props) => props.theme.grey700};
`;

const Cross = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 205 205" fill="none">
    <CrossPath
      d="M181.688.687988L102.5 79.875 23.312.687988.687988 23.312
    79.875 102.5.687988 181.688 23.312 204.312l79.188-79.187 79.188 79.187
    22.624-22.624-79.187-79.188 79.187-79.188L181.688.687988z"
    />
  </svg>
);

Cross.defaultProps = {
  width: '12px',
  height: '12px',
};

Cross.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Cross;
