import { setCookie } from '../cookie-helpers';
import i18n from '../i18n';
import { notification } from './notifications.utils';
import {
  ErrorNotification,
  ErrorNotificationParams,
  Notification,
  NotificationParams,
} from './notifications.types';

export const successRegex = /(2[0-9]+[0-9]+)/;

export const isSuccess = (toTest: string | number) => successRegex.test(`${toTest}`);

// Notifications - success
export const successNotification: Notification = (params = {}) => {
  const newParams: NotificationParams = {};
  newParams.message = params.message || i18n.t('Success!');
  newParams.duration = params.duration || 3;

  if (params.action && params.action === 'LOGOUT') {
    setCookie('plt-expire', true, new Date().getTime());
  }

  notification.success(newParams);
};

// Notifications - info
export const infoNotification: Notification = (params = {}) => {
  const newParams: NotificationParams = { ...params };
  newParams.description = params.description || '';
  newParams.duration = params.duration || 3;

  if (params.message && params.message.length) {
    notification.info(newParams);
  }
};

/**
 * Creates an error notification.
 *
 * By providing a component a generic error message will be generated. i.e.
 *
 * errorNotification({ code: status, component: readings });
 *
 * You can also provide both the action and the component which resulted in
 * this error notification, for a more customised generic error message:
 *
 * errorNotification({ code: status, action: t('update'), component: t('rates') });
 *
 * @param {object} params The parameters containing the error notification details.

 *
 * @returns {Function} errorNotification The error notification function.
 */
export const errorNotification: ErrorNotification = (params = { code: 500 }) => {
  let message = '';
  let description = '';

  if (params.action && params.component) {
    description = i18n.t(`Could not {{0}} {{1}} at this time!`, {
      0: params.action,
      1: params.component,
    });
  } else if (params.component) {
    description = i18n.t(`Could not retrieve {{0}} at this time!`, {
      0: params.component,
    });
  }
  const newParams: ErrorNotificationParams = {};

  if (params.code && params.code === 499) {
    console.error('Request cancelled by user');
    return;
  }
  // Default messages and descriptions
  if (params.errorCode) {
    switch (params.errorCode || params.code) {
      case 400:
      case 'DA-0400':
        message = i18n.t('Oops!');
        break;
      case 401:
      case 'DA-0401':
      case 'E0000004': // Octa authentication exception.
        message = i18n.t('Unauthorized access!');
        newParams.key = 401;
        break;
      case 403:
      case 'DA-0403':
        message = i18n.t('You do not have permissions to access this resource!');
        newParams.key = 403;
        break;
      case 404:
      case 'DA-0404':
        message = i18n.t('Requested record not found');
        break;
      case 408:
      case 'DA-0408':
        message = i18n.t('Oops!');
        description = i18n.t('Request timed out! Please try again later.');
        break;
      case 410:
      case 'DA-0410':
        message = i18n.t('This link can no longer be used to complete your request');
        break;
      case 'DA-0420':
        newParams.message = i18n.t(`Adding a new contract will cause the meter to exceed its export
          limit of 100%`);
        newParams.description = i18n.t(`You have exceeded your maximum allocation capacity of 100%.
          Please cancel any active or pending offers before creating a new offer.`);
        break;
      case 500:
      case 'DA-0500':
        message = i18n.t('Failed to process requested data. Internal server error!');
        break;
      default:
        message = i18n.t('Unknown server error!');
        break;
    }
  }

  // If a custom error message or description have been provided use this instead of the default
  if (params.message) message = params.message;
  if (params.description) description = params.description;

  newParams.message = i18n.t(message);
  newParams.description = i18n.t(description);
  newParams.duration = params.duration || 3;

  notification.error(newParams);
};

// Notifications - expired jwt token
export const expiredNotification = () => {
  notification.error({
    key: 401,
    message: i18n.t('Session expired. Please login again'),
    duration: 0,
  });
};
