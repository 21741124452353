import { useLocation } from 'react-router';

const nameToPathMapping = {
  trades: /^trades$/,
  pricing: /^pricing$/,
  'direct-trades': /^direct-trades$/,
  'ioa-data': /^ioa-data$/,
  records: /^records$/,
  readings: /^readings$/,
  estimates: /^estimates$/,
  campaigns: /^campaigns$/,
  campaign: /^campaigns\/.*$/,
  'user-campaigns': /^user-campaigns$/,
  vouchers: /^vouchers$/,
  events: /^events$/,
  'excess-demand': /^excess-demand$/,
  'excess-energy': /^excess-energy$/,
};

const getCurrentSubpathName = (pathName: string) => {
  const subpath = pathName.split('/').slice(2).join('/');

  for (const [pageName, path] of Object.entries(nameToPathMapping)) {
    if (subpath.match(path)) return pageName;
  }

  return '';
};

export const useCurrentSubpage = () => {
  const location = useLocation();

  return getCurrentSubpathName(location.pathname);
};
