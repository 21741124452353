import { Checkbox as ThemeCheckbox, Label } from 'theme-ui';
import { memo } from 'react';
import { CheckboxProps } from './checkbox.types';

const CheckboxBase: React.FC<CheckboxProps> = ({ id, label, checked, onChange, sx = {} }) => (
  <Label
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx,
    }}
    htmlFor={id}
    variant="checkboxLabel"
  >
    <ThemeCheckbox id={id} checked={checked} variant="checkbox" onChange={onChange} />
    {label}
  </Label>
);

export const Checkbox = memo(CheckboxBase);
