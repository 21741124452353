import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { WindowHistoryAdapter } from 'use-query-params/adapters/window';
import { DocumentHead } from '../components/document-head';
import { AppThemeProvider } from './app-theme-provider';
import { HostConfigProvider } from './host-config-provider';
import { ProviderLoader } from './provider-loader';
import { XGridAuthProvider } from './xgrid-auth-provider';

export const AppProviders: React.FC<{ children: React.ReactNode; skipLoading?: boolean }> = ({
  children,
  skipLoading,
}) => (
  <QueryParamProvider adapter={WindowHistoryAdapter}>
    <HostConfigProvider>
      <DocumentHead />
      <AppThemeProvider>
        <XGridAuthProvider>
          <ProviderLoader skipLoading={skipLoading}>{children}</ProviderLoader>
        </XGridAuthProvider>
      </AppThemeProvider>
    </HostConfigProvider>
  </QueryParamProvider>
);
