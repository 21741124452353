import { useCallback } from 'react';
import styled from '@emotion/styled';
import { variant as styledSystemVariant } from 'styled-system';
import { css, Theme, ThemeUIStyleObject } from 'theme-ui';
import { SwitchProps } from './switch.types';
import { SwitchButton } from './switch-button';

const StyledSwitch = styled.div<{
  variant?: string;
  sx?: ThemeUIStyleObject;
  hidden: boolean;
}>`
  ${styledSystemVariant({
    scale: 'switches',
    variants: {
      primary: null,
      inverted: null,
      tabs: null,
    },
  })}
  display: ${({ hidden }) => (hidden ? 'none' : null)};
  ${({ sx }) => sx && css(sx)}
  ${({ theme }: { theme: Theme }) =>
    theme.colors &&
    css({
      border: 'none',
      boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
      '&:focus': { boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)' },
    })}
`;

export const Switch = <T extends string>({
  sx,
  value,
  onClick,
  options = [],
  variant = 'primary',
  ...rest
}: SwitchProps<T>) => {
  // Handle arrow key input (left and right)
  const onKeyDown = useCallback(
    (e) => {
      if (!options.length) return;
      const keyPressed = e.code;
      const currentValueIndex = options.findIndex((option: any) => option.value === value);

      if (keyPressed === 'ArrowLeft' && currentValueIndex) onClick(options[currentValueIndex - 1].value);
      if (keyPressed === 'ArrowRight' && currentValueIndex < options.length - 1)
        onClick(options[currentValueIndex + 1].value);
    },
    [onClick, options, value]
  );

  return (
    <StyledSwitch
      variant={variant}
      sx={sx}
      tabIndex={0}
      onKeyDown={onKeyDown}
      hidden={options.length <= 1}
      {...rest}
    >
      {options.map((option, key) => (
        <SwitchButton
          variant={variant}
          key={option.value}
          active={option.value === value}
          leftMost={!key}
          rightMost={key === options.length - 1}
          onClick={() => onClick(option.value)}
          data-testid={option.id}
        >
          {option.content}
        </SwitchButton>
      ))}
    </StyledSwitch>
  );
};
