import React from 'react';
import { HostConfigContext } from '../../contexts/host-config-context';
import { useHostConfigProviderCtx } from './use-host-config-provider-ctx';

export const HostConfigProvider: React.FC<{ children: React.ReactNode; hostname?: string }> = ({
  children,
  hostname,
}) => {
  const contextValue = useHostConfigProviderCtx(hostname);
  return <HostConfigContext.Provider value={contextValue} children={children} />;
};
