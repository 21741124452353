import { Button } from 'theme-ui';

import { useHistory } from 'react-router-dom';

import { FC } from 'react';

export type LinkProps = {
  children?: any;
  to?: string;
  active?: boolean;
  tabIndex?: number;
  onClick?: () => void;
};
export type LinkType = FC<LinkProps>;

const activeStyles = (active = false) =>
  active
    ? {
        bg: 'primary',
        color: 'white',
      }
    : {};

export const Link: LinkType = ({ children, to, active, tabIndex = 0, onClick }) => {
  const history = useHistory();
  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };
  return (
    <Button
      variant="link"
      tabIndex={tabIndex}
      sx={activeStyles(active)}
      onClick={handleOnClick}
      onKeyUp={(e) => {
        const key = e.code;
        return key === 'Enter' ? handleOnClick() : e;
      }}
    >
      {children}
    </Button>
  );
};
