/** @jsxImportSource theme-ui */
import {
  Column,
  Row,
  useTable,
  useSortBy,
  usePagination,
  useExpanded,
  useRowSelect,
  HeaderGroup,
} from 'react-table';
import { keyBy, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Theme, Themed, ThemeUICSSObject } from 'theme-ui';
import { useEffect, useCallback, useMemo, useRef, useState, ReactNode, ChangeEvent } from 'react';
import { getColor } from '@theme-ui/color';
import { ArrowDownSVG } from '@power-ledger/assets';
import { useTheme } from '@power-ledger/hooks';
import { TableVariants } from '@power-ledger/styles';
import { FastForward, Sort } from '../icons';
import { LoadingOverlay, Checkbox } from '..';
import Pagination from './Pagination';
import ServerSidePagination from './ServerSidePagination';

type TableStyle = {
  [key: string]: (args: Record<string, any>) => Record<string, ThemeUICSSObject>;
};

const tableStyle = (theme: Theme): TableStyle => {
  return {
    getStyles: ({ variant }: { variant?: string }) => ({
      table: {
        variant: `tables.${variant}`,
        borderRadius: 4,
        border: 0,
        backgroundColor: 'secondaryDarker',
        padding: '10px',
      },
      expandToggleBlock: {
        width: '50px',
      },
      expandedBlock: {
        backgroundColor: `#4D4C70`,
      },
    }),
    getExpandedStyles: (args?: Record<string, boolean>): Record<string, ThemeUICSSObject> => {
      return {
        'main-column': {
          background: args?.expanded ? `${theme?.colors?.background}` : '',
          '&:last-of-type': {
            borderTopRightRadius: args?.expanded ? '10px' : '',
          },
        },
        'expanded-column': {
          borderTopLeftRadius: args?.expanded ? '10px' : '',
          background: args?.expanded ? `${theme?.colors?.background}` : '',
          justifyContent: 'center',
        },
        'expanded-svg-container': {
          svg: {
            transform: args?.expanded ? '' : 'rotate(180deg)',
            path: {
              fill: 'primary',
            },
          },
        },
      };
    },
  };
};

const SliderButtonsContainer = ({
  side,
  onClick,
  children,
}: {
  side: string;
  onClick: () => void;
  children: ReactNode;
}) => {
  return (
    <Button
      aria-label={`scroll ${side}`}
      sx={{
        background: 'black',
        opacity: 0.5,
        position: 'absolute',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        borderRadius: side === 'left' ? '0 10px 10px 0' : '10px 0 0 10px',
        height: '40px',
        left: side === 'left' ? 0 : 'unset',
        right: side === 'right' ? 0 : 'unset',
        zIndex: 1,
        top: '50%',
        cursor: 'pointer',
        '&:hover,&:enabled:hover': {
          opacity: 0.8,
        },
        '&:enabled:focus,&:focus': {
          background: 'black',
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const canScrollLeft = (targetNode: HTMLDivElement | null) => {
  return !!(targetNode && targetNode?.scrollLeft > 0);
};

export const canScrollRight = (targetNode: HTMLDivElement | null) => {
  return !!(
    targetNode && Math.ceil(targetNode?.scrollLeft) - targetNode?.scrollWidth + targetNode?.clientWidth < 0
  );
};
export interface ExpandableTableObject extends Object {
  expandedData?: Record<string, string | number>;
}

export interface TableProps<T extends ExpandableTableObject> {
  columns: Column<T>[];
  dataSource: T[];
  loading?: boolean;
  noHover?: boolean;
  itemsLabel?: string;
  startSelected?: boolean;
  isMultiSelect?: boolean;
  /** Naming convention for data-testid="my-component--some-value" */
  testId?: string;
  variant?: TableVariants;
  hideHeader?: boolean;
  selectedColor?: string;
  pageAmount?: number;
  pageInfo?: any;
  manualPageSize?: number;
  setEndCursor?: (endCursor: string) => void;
  fetchData?: (arg: { pageSize: number; pageIndex: number }) => void;
  getSelectedRow?: (arg: any) => void;
  getRows?: (arg: any) => void;
  checkboxDisabled?: boolean;
  initialState?: any;
  totalItems?: number;
  noDataText?: string;
  autoUnselect?: boolean;
  selectAll?: boolean;
  showScrollArrows?: boolean;
  expandable?: boolean;
  renderExpandedComponent?: (data: Record<string, string | number>) => ReactNode;
  getInstanceCallback?: (arg: any) => void;
  /** Will render if not passed with pagination or manualPagination */
  customPagination?: ReactNode | null;
  pagination?: boolean;
  manualPagination?: boolean;
}

export const Table = <T extends ExpandableTableObject>({
  customPagination,
  columns,
  dataSource,
  loading = false,
  pagination = true,
  noHover = false,
  expandable = false,
  testId,
  itemsLabel,
  hideHeader = false,
  startSelected = false,
  isMultiSelect = false,
  manualPagination = false,
  pageAmount,
  pageInfo,
  selectedColor,
  setEndCursor = () => null,
  fetchData,
  getSelectedRow,
  getRows,
  variant = TableVariants.Normal,
  manualPageSize = 10,
  checkboxDisabled,
  initialState,
  totalItems = 0,
  noDataText,
  autoUnselect = false,
  selectAll = false,
  showScrollArrows = false,
  renderExpandedComponent,
  getInstanceCallback,
}: TableProps<T>) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollable, setScrollable] = useState({
    left: false,
    right: false,
  });
  const { theme } = useTheme();
  const getTableStyle = tableStyle(theme);
  const tableCss = getTableStyle?.getStyles({ variant: variant as string });
  const [currentLength, setCurrentLength] = useState(0);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const columnsByKey = keyBy(
    columns.map((c) => ({ ...c, width: c.width })),
    (c) => c.accessor
  );
  const setColumnWidth = (column: Column) =>
    column?.width
      ? {
          width: typeof column.width === 'number' ? `${column.width}px` : column.width,
        }
      : {};

  const tableInstance = useTable(
    {
      data: dataSource,
      columns,
      // @ts-ignore
      defaultState: { pageIndex: 0 },
      initialState,
      manualPagination,
      pageCount: pageAmount || -1,
      autoResetPage: false,
      autoResetSelectedRows: false,
      getInstanceCallback,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (getSelectedRow && !isMultiSelect) {
        hooks.visibleColumns.push((visibleColumns) => [
          {
            id: 'selection',
            Cell: ({ row }: { row: Row }) => (
              <Checkbox
                disabled={checkboxDisabled}
                // @ts-ignore
                {...row.getToggleRowSelectedProps()}
                rounded
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  toggleAllRowsSelected(false);
                  // @ts-ignore
                  row.toggleRowSelected(event.target.checked);
                  getSelectedRow(event.target.checked ? row : undefined);
                }}
              />
            ),
          },
          ...visibleColumns,
        ]);
      }
      if (isMultiSelect) {
        hooks.visibleColumns.push((visibleColumns) => [
          ...visibleColumns,
          {
            id: 'selection',
            // @ts-ignore
            Header: ({ getToggleAllRowsSelectedProps, getToggleAllPageRowsSelectedProps }) =>
              pagination && !selectAll ? (
                <div>
                  <Checkbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ) : (
                <div>
                  <Checkbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
            Cell: ({ row }: { row: Row }) => (
              <Checkbox
                disabled={checkboxDisabled}
                sx={{ fill: 'primary' }}
                // @ts-ignore
                {...row.getToggleRowSelectedProps()}
                // @ts-ignore
                checked={row.isSelected}
                aria-label={'table row ' + row.index + ' checkbox'}
                onChange={(e) => {
                  // @ts-ignore
                  row.toggleRowSelected(e.target.checked);
                  getSelectedRow && getSelectedRow(row);
                }}
              />
            ),
          },
        ]);
      }
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    // @ts-ignore
    state: { pageSize, pageIndex },

    // @ts-ignore
    setPageSize,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    page,
    // @ts-ignore
    pageCount,
    rowsById,
    // @ts-ignore
    toggleAllRowsSelected,
    flatRows,
  } = tableInstance;
  // @ts-ignore
  const tableState = tableInstance?.selectedFlatRows;
  useEffect(() => {
    if (getInstanceCallback) {
      getInstanceCallback(tableState);
    }
  }, [getInstanceCallback, tableState]);

  useEffect(() => {
    flatRows.length !== currentLength && autoUnselect && toggleAllRowsSelected(false);
    setCurrentLength(flatRows.length || 0);
  }, [autoUnselect, currentLength, flatRows, toggleAllRowsSelected]);

  const isExpanded = (index: number) => expandedRows[index];

  const getCellStyles = useCallback(
    ({ row, cellIdx }) => {
      if (row.isExpanded && cellIdx <= 1) return { style: { borderBottomColor: 'transparent' } };
      if (row.isExpanded && cellIdx > 1) return { style: { borderBottomColor: getColor(theme, 'text') } };

      if (row.depth) {
        const { original: parentRow } = rowsById[row.id[0]];
        const isLastSubRow =
          // @ts-ignore
          parentRow.subRows[parentRow.subRows.length - 1].id === row.id;

        if (row.depth && cellIdx <= 1 && !isLastSubRow)
          return { style: { borderBottomColor: 'transparent' } };
      }

      if (row.isSelected) {
        return {
          style: { backgroundColor: getColor(theme, selectedColor ? selectedColor : 'background') },
        };
      }

      return {};
    },
    [theme, rowsById, selectedColor]
  );

  const paginationLabel = useMemo(
    () =>
      `${totalItems >= 0 ? totalItems : dataSource.length || 0} ${
        itemsLabel ? itemsLabel : totalItems === 1 ? t('Item') : t('Items')
      }`,
    [totalItems, dataSource, itemsLabel, t]
  );

  useEffect(() => {
    startSelected && toggleAllRowsSelected(true);
  }, [startSelected, toggleAllRowsSelected]);

  useEffect(() => {
    if (pagination) {
      if (pageSize) setPageSize(pageSize);
    }
  }, [pagination, setPageSize, pageSize]);

  useEffect(() => {
    if (manualPagination && fetchData) {
      fetchData({ pageIndex, pageSize });
    }
  }, [fetchData, pageIndex, manualPagination, pageSize]);

  const checkCanScroll = useMemo(
    () =>
      debounce(() => {
        if (showScrollArrows) {
          const leftScrollable = canScrollLeft(scrollRef?.current);
          const rightScrollable = canScrollRight(scrollRef?.current);
          setScrollable({
            left: leftScrollable,
            right: rightScrollable,
          });
        }
      }, 100),
    [showScrollArrows]
  );

  useEffect(() => {
    checkCanScroll();
    if (!loading && showScrollArrows) {
      window.addEventListener('resize', checkCanScroll);
      return () => {
        window.removeEventListener('resize', checkCanScroll);
        checkCanScroll.cancel();
      };
    }
    return;
  }, [checkCanScroll, loading, showScrollArrows]);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          ref={scrollRef}
          onScroll={checkCanScroll}
          sx={{
            overflowX: 'auto',
            borderBottomLeftRadius: pagination ? 0 : 3,
            borderBottomRightRadius: pagination ? 0 : 3,
          }}
        >
          {showScrollArrows && (
            <>
              {scrollable.left && (
                <SliderButtonsContainer
                  side="left"
                  onClick={() => {
                    scrollRef.current?.scrollTo({
                      left: scrollRef.current.scrollLeft - 500,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <FastForward
                    width={25}
                    height={25}
                    css={{
                      svg: {
                        path: { fill: '#ffff' },
                      },

                      transform: 'rotate(180deg)',
                    }}
                  />
                </SliderButtonsContainer>
              )}
              {scrollable.right && (
                <SliderButtonsContainer
                  side="right"
                  aria-label="Scroll Right"
                  onClick={() => {
                    scrollRef.current?.scrollTo({
                      left: scrollRef.current.scrollLeft + 500,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <FastForward
                    width={25}
                    height={25}
                    css={{
                      svg: {
                        path: { fill: '#ffff' },
                      },
                    }}
                  />
                </SliderButtonsContainer>
              )}
            </>
          )}
          <LoadingOverlay loading={loading} />
          <Themed.table data-testid={testId} sx={tableCss.table} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: HeaderGroup<T>) => (
                <Themed.tr {...headerGroup.getHeaderGroupProps()}>
                  {/* Empty thead for the "expand row arrow" column */}
                  {expandable && <Themed.th sx={tableCss.expandToggleBlock}></Themed.th>}
                  {headerGroup.headers.map((column) => (
                    <Themed.th
                      sx={{
                        ...setColumnWidth(columnsByKey[column.id] as Column),
                      }}
                      // @ts-ignore
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {!hideHeader && (
                        <Flex
                          sx={{
                            // @ts-ignore
                            color: column.isSorted ? 'primary' : 'textDarker',
                            justifyContent: 'start',
                            alignItems: 'center',
                          }}
                        >
                          {column.render('Header')}
                          {
                            // @ts-ignore
                            column.canSort && (
                              <Box
                                sx={{
                                  display: 'inline-flex',
                                  minHeight: 4,
                                  height: '100%',
                                  width: 4,
                                  ml: 1,
                                }}
                              >
                                <Sort
                                  sx={{
                                    // @ts-ignore
                                    transform: `rotate(${!column.isSortedDesc && '180deg'})`,
                                    transition: 'transform 0.2s ease',
                                    // @ts-ignore
                                    color: column.isSorted ? 'primary' : 'textDarker',
                                  }}
                                />
                              </Box>
                            )
                          }
                        </Flex>
                      )}
                    </Themed.th>
                  ))}
                </Themed.tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {(pagination ? page : rows).length < 1 && (
                <Themed.tr>
                  {/* Empty td for the "expand row arrow" column */}
                  {expandable && <Themed.td sx={{ padding: '10px' }}></Themed.td>}
                  <Themed.td
                    sx={tableCss.column}
                    colSpan={getSelectedRow || isMultiSelect ? columns.length + 1 : columns.length}
                  >
                    <Flex sx={{ justifyContent: 'center', color: 'textDarker', fontSize: 2 }}>
                      {t(noDataText || 'No data')}
                    </Flex>
                  </Themed.td>
                </Themed.tr>
              )}
            </tbody>
            {
              // @ts-ignore
              (pagination ? page : rows).map((row: Row<T>) => {
                prepareRow(row);
                const expandStyle = getTableStyle?.getExpandedStyles({ expanded: isExpanded(row.index) });

                return (
                  <tbody
                    key={`row-${row.index}`}
                    sx={{
                      position: 'relative',
                      '&:before':
                        !isExpanded(row.index) && !isExpanded(row.index - 1)
                          ? {
                              content: "''",
                              borderColor: 'textDarker',
                              borderTopWidth: '0.5px',
                              borderTopStyle: 'solid',
                              borderBottom: 0,
                              width: '100%',
                              position: 'absolute',
                              top: 0,
                            }
                          : {},
                    }}
                  >
                    <Themed.tr aria-label={`row index ${row.index}`} aria-expanded={isExpanded(row.index)}>
                      {expandable && (
                        <Themed.td
                          sx={{
                            ...expandStyle?.['expanded-column'],
                          }}
                        >
                          <Button
                            sx={{
                              background: 'transparent',
                              '&:enabled:hover, &:enabled:focus, &:enabled:active': {
                                background: 'transparent',
                              },
                              ...expandStyle?.['expanded-svg-container'],
                            }}
                            aria-label={`expand row ${row.index + 1}`}
                            onClick={() => {
                              setExpandedRows({
                                ...expandedRows,
                                [row.index]: !expandedRows[row.index],
                              });
                            }}
                          >
                            <ArrowDownSVG />
                          </Button>
                        </Themed.td>
                      )}
                      {row.cells.map(
                        // @ts-ignore
                        (cell, cellIdx) => {
                          return (
                            <Themed.td
                              key={cellIdx}
                              sx={{
                                ...expandStyle?.['main-column'],
                              }}
                              className={noHover ? 'no-hover' : ''}
                            >
                              {cell.render('Cell')}
                            </Themed.td>
                          );
                        }
                      )}
                    </Themed.tr>
                    {isExpanded(row.index) && (
                      <Themed.tr>
                        <Themed.td
                          colSpan={row.cells.length + 1 || 5}
                          className="no-hover"
                          sx={{
                            padding: '0 !important',
                            transition: '0.5s ease-in-out',
                            lineHeight: '40px',
                          }}
                        >
                          <Box>
                            <Themed.table>
                              <tbody>
                                <Themed.tr>
                                  <Themed.td
                                    className="no-hover"
                                    sx={{
                                      ...tableCss.expandToggleBlock,
                                      ...tableCss.expandedBlock,
                                      borderBottomLeftRadius: '10px',
                                    }}
                                  ></Themed.td>
                                  <Themed.td
                                    sx={{
                                      ...tableCss.expandedBlock,

                                      borderBottomRightRadius: '10px',
                                    }}
                                    className="no-hover"
                                  >
                                    {renderExpandedComponent &&
                                      renderExpandedComponent(row.original?.expandedData || {})}
                                  </Themed.td>
                                </Themed.tr>
                              </tbody>
                            </Themed.table>
                          </Box>
                        </Themed.td>
                      </Themed.tr>
                    )}
                  </tbody>
                );
              })
            }
          </Themed.table>
        </Box>
      </Box>
      {!customPagination && pagination && (
        <Box>
          {manualPagination ? (
            <ServerSidePagination
              goToPage={gotoPage}
              pageInfo={pageInfo}
              setEndCursor={setEndCursor}
              pageCount={pageAmount || pageCount}
              setPageSize={setPageSize}
              itemsLabel={paginationLabel}
            />
          ) : (
            <Pagination
              goToPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageCount={pageAmount || pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              setPageSize={setPageSize}
              itemsLabel={paginationLabel}
            />
          )}
        </Box>
      )}
      {customPagination && !pagination && customPagination}
    </>
  );
};
