import { CSSProperties, memo } from 'react';
import { Box } from 'theme-ui';

import { ArrowCircleRight, ArrowCircleLeft } from '../../icons';
import { IconButton } from '../../icon-button';
import { ChartNavArrowsProps } from './chart-nav-arrows.types';

const ChartNavArrowsBase: React.FC<ChartNavArrowsProps> = ({
  onClickLeft,
  onClickRight,
  placeNavArrowsBelowChart = false,
  disabled,
  style: styleArg,
}) => {
  const buttonStyle = {
    top: placeNavArrowsBelowChart ? -20 : undefined,
    position: (placeNavArrowsBelowChart ? 'relative ' : 'absolute') as CSSProperties['position'],
    pointerEvents: disabled ? 'none' : ('auto' as CSSProperties['pointerEvents']),
    margin: 0,
    ...styleArg,
  };
  const iconSx = {
    minWidth: 32,
    width: 32,
    height: 32,
    '> svg': { width: 24, height: 24, '> path': { fill: 'textLightest', transition: 'all 0.2s' } },
  };

  return (
    <Box
      sx={{
        position: placeNavArrowsBelowChart ? 'relative' : 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        paddingTop: placeNavArrowsBelowChart ? 2 : 0,
      }}
    >
      <IconButton
        onClick={onClickLeft}
        disabled={disabled}
        sx={{
          left: placeNavArrowsBelowChart ? '30%' : -32,
          '&:hover': { 'svg path': { fill: 'textLighter' } },
          ...buttonStyle,
        }}
        data-testid="chart-go-back-button"
      >
        <ArrowCircleLeft sx={iconSx} />
      </IconButton>
      <IconButton
        onClick={onClickRight}
        disabled={disabled}
        sx={{
          right: placeNavArrowsBelowChart ? '30%' : -32,
          '&:hover': { 'svg path': { fill: 'textLighter' } },
          ...buttonStyle,
        }}
        data-testid="chart-go-next-button"
      >
        <ArrowCircleRight sx={iconSx} />
      </IconButton>
    </Box>
  );
};

export const ChartNavArrows = memo(ChartNavArrowsBase);
