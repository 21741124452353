import { ComponentType, FC } from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { SentryRoute } from './sentry-route';

export type RestrictedRouteProps = {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  conditional: boolean;
} & RouteProps;

export const RestrictedRoute: FC<RestrictedRouteProps> = ({ component: Component, conditional, ...rest }) => {
  return (
    <SentryRoute
      {...rest}
      render={(props) => (conditional ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};
