import { AuthApiError } from '@okta/okta-auth-js';
import { SignInResponse } from '../../auth-service-types';
import { SignIn } from '../adaptor-types';
import { OktaAdaptorTypes } from './okta-adaptor-types';

/**
 * Converts an Okta Sing In Response status to an Auth Service Sign In Response
 * @param status
 */
const oktaSignInStatusToSignInResponse = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return SignInResponse.SUCCESS;
    case 'MFA_REQUIRED':
      return SignInResponse.REQ_MFA;
    case 'MFA_ENROL':
      return SignInResponse.REQ_MFA_ENROLL;
    case 'PASSWORD_EXPIRED':
      return SignInResponse.PW_EXPIRED;
    case 'LOCK_OUT':
      return SignInResponse.LOCKED_OUT;

    default:
      throw new Error(`Unknown Sign In Status: ${status}`);
  }
};

export const signIn: SignIn<OktaAdaptorTypes> = async (client, { username, password }) => {
  try {
    const loginTransaction = await client.okta.signInWithCredentials({
      username,
      password,
    });

    const signInStatus = oktaSignInStatusToSignInResponse(loginTransaction.status);

    if (signInStatus !== SignInResponse.SUCCESS) {
      return signInStatus;
    }

    // Fetch Access Tokens
    const response = await client.okta.token.getWithoutPrompt({
      responseType: 'token',
      sessionToken: loginTransaction.sessionToken,
    });
    client.okta.tokenManager.setTokens(response.tokens);

    return SignInResponse.SUCCESS;
  } catch (error: unknown) {
    if (error instanceof AuthApiError) {
      if (error?.errorCode === 'E0000004') {
        return SignInResponse.INVALID_CRED;
      }
    }

    throw error;
  }
};
