import styled from '@emotion/styled';
import { Props as PhoneInputBaseProps } from 'react-phone-number-input';
import { css as themeCss } from '@theme-ui/css';
import { FC, lazy, Suspense } from 'react';
import { useFormikContext } from 'formik';
import 'react-phone-number-input/style.css';
import { LoadingOutlined } from '../icons';

const PhoneInputBase = lazy(() => import('react-phone-number-input'));

const LazyPhoneInputBase = (props: PhoneInputBaseProps<any>) => (
  <Suspense fallback={<LoadingOutlined size={3} />}>
    <PhoneInputBase {...props} />
  </Suspense>
);

const StyledPhoneInput = styled(LazyPhoneInputBase, {
  shouldForwardProp: (key) => key !== 'hasError',
})`
  .PhoneInputInput {
    ${({ theme, hasError }: any) =>
      themeCss({
        borderStyle: 'solid',
        borderRadius: 2,
        borderWidth: 2,
        ...theme.forms.input,
        height: 4,
        ...(hasError ? { borderColor: 'warningLight' } : {}),
      })(theme)}
  }
`;

export type PhoneInputProps = {
  name: string;
  placeholder?: string;
} & Omit<PhoneInputBaseProps<any>, 'onChange' | 'value'>;

export const PhoneInput: FC<PhoneInputProps> = ({ name, placeholder, ...props }) => {
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext<any>();
  return (
    <StyledPhoneInput
      {...props}
      international
      defaultCountry="AU"
      placeholder={placeholder}
      // @ts-ignore
      countryCallingCodeEditable={false}
      name={name}
      value={values[name]}
      onChange={(val: any) => handleChange({ target: { name, value: val || '' } })}
      onBlur={(val: any) => {
        handleBlur({ target: { name, value: val || '' } });
      }}
      hasError={touched[name] && !!errors[name]}
    />
  );
};
