import { useCallback } from 'react';
import ReactSelect, {
  components as selectComponents,
  DropdownIndicatorProps,
  GroupBase,
  MenuPosition,
  OptionProps,
  Props as BaseSelectProps,
  MenuListProps,
} from 'react-select';
import { Box, css, Flex, ThemeUIStyleObject } from 'theme-ui';
import AsyncReactSelect from 'react-select/async';
import { DropdownArrowSVG } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../hooks/use-theme';

export interface SelectOption {
  value: any;
  label: string;
}

export interface SelectProps extends BaseSelectProps<SelectOption> {
  sx?: ThemeUIStyleObject;
  borderless?: boolean;
  inputStyles?: ThemeUIStyleObject;
  menuStyles?: Record<string, string | number>;
  dropdownIndicatorStyles?: ThemeUIStyleObject;
  controlStyles?: ThemeUIStyleObject;
  singleValueStyles?: ThemeUIStyleObject;
  value?: SelectOption;
  menuIsOpen?: boolean;
  options?: SelectOption[];
  onChange?: any;
  isOptionDisabled?: (opt: { value: string; label: string }) => boolean;
  DropdownIndicator?: (
    props: DropdownIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>
  ) => React.ReactElement;
  dataTestid?: string;
  menuListDataTestid?: string;
}

export interface AsyncSelectProps extends SelectProps {
  sx?: ThemeUIStyleObject;
  defaultOptions?: Array<any>;
  loadOptions?: any;
  noOptionsMessage?: any;
  isOptionSelected?: any;
  menuPosition?: MenuPosition;
}

interface UseThemedSelectStylesProps {
  borderless: boolean;
  inputStyles?: ThemeUIStyleObject;
  controlStyles?: ThemeUIStyleObject;
  dropdownIndicatorStyles?: ThemeUIStyleObject;
  singleValueStyles?: ThemeUIStyleObject;
  hasCustomDropdownIndicator?: boolean;
}

const useThemedSelectStyles = ({
  borderless,
  inputStyles,
  dropdownIndicatorStyles,
  controlStyles,
  singleValueStyles = {},
  hasCustomDropdownIndicator = false,
}: UseThemedSelectStylesProps) => {
  const { theme } = useTheme();

  const placeholder = useCallback((baseStyle: any, state: any) => {
    const { menuIsOpen, isSearchable } = state.selectProps;
    return {
      ...baseStyle,
      display: menuIsOpen && isSearchable ? 'none' : 'block',
    };
  }, []);

  const singleValue = useCallback(
    (styles = {}) => ({
      ...styles,
      ...singleValueStyles,
    }),
    [singleValueStyles]
  );

  const multiValue = useCallback(
    (styles = {}) => ({
      ...styles,
      background: theme.colors.primary,
    }),
    [theme]
  );

  const multiValueLabel = useCallback(
    (styles = {}) => ({
      ...styles,
      color: 'white',
    }),
    []
  );

  const multiValueRemove = useCallback(
    (styles = {}) => ({
      ...styles,
      color: 'white',
      '&:hover': {
        background: theme.colors.primaryLightest,
        color: 'white',
      },
    }),
    [theme]
  );

  const option = useCallback(
    (_, state: OptionProps<SelectOption, boolean, GroupBase<SelectOption>>) =>
      css({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(state.isSelected
          ? {
              variant: 'forms.select.optionActive',
            }
          : { variant: 'forms.select.option' }),

        ...(state.isDisabled ? { variant: 'forms.select.optionDisabled' } : {}),
      })(theme),
    [theme]
  );

  const control = useCallback(
    () =>
      css({
        display: 'flex',
        variant: 'forms.select.control',
        minWidth: 'unset',
        '&:hover': {
          ...theme.forms.select.control['&:hover'],
          svg: {
            path: {
              fill: 'accentDark',
            },
          },
        },
        svg: {
          path: {
            fill: 'greyLightest',
          },
        },
        ...(borderless ? { borderStyle: 'none', cursor: 'pointer' } : {}),
        ...(inputStyles || {}),
        ...(controlStyles || {}),
      })(theme),
    [theme, borderless, inputStyles, controlStyles]
  );

  const dropdownIndicator = useCallback(
    (_, state: DropdownIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>) =>
      css({
        ...(!hasCustomDropdownIndicator ? { variant: 'forms.select.dropdownIndicator' } : {}),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        svg: {
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        },
        ...(dropdownIndicatorStyles || {}),
      })(theme),
    [theme, hasCustomDropdownIndicator, dropdownIndicatorStyles]
  );

  const indicatorSeparator = useCallback(
    () =>
      css({
        variant: 'forms.select.indicatorSeparator',
      })(theme),
    [theme]
  );

  return {
    control,
    placeholder,
    option,
    multiValue,
    multiValueLabel,
    multiValueRemove,
    dropdownIndicator,
    indicatorSeparator,
    singleValue,
  };
};

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>) => {
  return (
    <selectComponents.DropdownIndicator {...props}>
      <DropdownArrowSVG width="12px" />
    </selectComponents.DropdownIndicator>
  );
};

interface CustomMenuListProps extends MenuListProps<SelectOption, boolean, GroupBase<SelectOption>> {
  dataTestid?: string;
}

const MenuList = (props: CustomMenuListProps) => {
  return (
    <selectComponents.MenuList {...props}>
      <Flex
        sx={{
          flexDirection: 'column',
          '& > *': {
            width: '100% !important',
          },
        }}
        data-testid={props.dataTestid}
      >
        {props.children}
      </Flex>
    </selectComponents.MenuList>
  );
};

export const Select: React.FC<SelectProps> = ({
  sx,
  borderless = false,
  inputStyles,
  menuStyles = {},
  dropdownIndicatorStyles,
  controlStyles,
  singleValueStyles,
  DropdownIndicator: CustomDropdownIndicator,
  dataTestid,
  menuListDataTestid,
  ...props
}) => {
  const { t } = useTranslation();
  const selectStyles = useThemedSelectStyles({
    borderless,
    inputStyles,
    dropdownIndicatorStyles,
    controlStyles,
    singleValueStyles,
    hasCustomDropdownIndicator: Boolean(CustomDropdownIndicator),
  });

  return (
    <Box sx={sx} data-testid={dataTestid}>
      <ReactSelect
        name="select"
        components={{
          DropdownIndicator: CustomDropdownIndicator || DropdownIndicator,
          MenuList: (props) => <MenuList {...props} dataTestid={menuListDataTestid} />,
        }}
        styles={{
          ...selectStyles,
          valueContainer: (baseStyle) => ({
            ...baseStyle,
            ...(controlStyles ? { display: 'flex' } : {}),
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            ...menuStyles,
          }),
        }}
        placeholder={t('Select...')}
        {...props}
      />
    </Box>
  );
};

export const AsyncSelect: React.FC<AsyncSelectProps> = ({
  sx,
  borderless = false,
  inputStyles,
  dropdownIndicatorStyles,
  DropdownIndicator: CustomDropdownIndicator,
  dataTestid,
  menuListDataTestid,
  ...props
}) => {
  const { t } = useTranslation();
  const selectStyles = useThemedSelectStyles({
    borderless,
    inputStyles,
    dropdownIndicatorStyles,
    hasCustomDropdownIndicator: Boolean(CustomDropdownIndicator),
  });

  return (
    <Box sx={sx} data-testid={dataTestid}>
      {/* @ts-ignore */}
      <AsyncReactSelect
        name="select"
        components={{
          DropdownIndicator: CustomDropdownIndicator || DropdownIndicator,
          MenuList: (props) => <MenuList {...props} dataTestid={menuListDataTestid} />,
        }}
        styles={selectStyles}
        placeholder={t('Select...')}
        {...props}
      />
    </Box>
  );
};
