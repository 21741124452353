export const toastTracex = {
  '.Toastify__toast': {
    color: 'black',
    borderLeftWidth: '10px',
    borderLeftStyle: 'solid',
    backgroundColor: 'white',
  },
  '.Toastify__toast--info': {
    borderLeftColor: '#605BC8',
  },
  '.Toastify__toast--success': {
    borderLeftColor: '#00B19F',
  },
  '.Toastify__toast--error': {
    borderLeftColor: '#DA1E28',
  },
  ':root ': {
    '--toastify-icon-color-info': '#605BC8',
    '--toastify-icon-color-success': '#00B19F',
    '--toastify-icon-color-error': '#DA1E28',
  },
};
