import {
  ErrorMessageProps as ErrorMessageBaseProps,
  ErrorMessage as ErrorMessageBase,
  useFormikContext,
} from 'formik';
import { Text, TextProps } from 'theme-ui';

type ErrorMessageProps = ErrorMessageBaseProps & TextProps;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, sx, ...rest }) => (
  <ErrorMessageBase
    component={Text}
    // @ts-ignore
    sx={{ variant: 'forms.errorMessage', ...sx }}
    {...rest}
  />
);

// TODO find out why ErrorMessage doesn't work with withFormkik forms (see CreateOfferModal)
export const ErrorMessageManual: React.FC<ErrorMessageProps> = ({ name, sx, ...props }) => {
  const { errors } = useFormikContext<any>();

  if (!errors[name]) return null;

  return (
    <Text sx={{ variant: 'forms.errorMessage', ...sx }} {...props}>
      {errors[name]}
    </Text>
  );
};
