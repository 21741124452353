import { Component, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, Paragraph, Switch, Flex, Label } from 'theme-ui';
import { DropdownArrowSVG } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Select, AsyncSelect } from '..';
import { toggleSearchMeterVisible, updateSelectedMeter } from '../../states/slices/controls';
import { getSearchMeters } from '../../states/slices/meters';
import { getRecentlySearchedMeters, updateRecentlySearchedMeters } from '../../states/selectors/controls';
import {
  categoriseMeters,
  categoriseMeterUsers,
  categoriseOtherMeters,
  renderMeterUserItem,
} from '../../lib/component-helpers';
import { hasMatchingText } from '../../lib/helpers';
import { withTranslation } from '../../hocs/with-translation';
import { containsAdminRole } from '../../lib/auth-helpers';

const SearchMeterContainer = ({
  selectedMeter,
  searchText,
  searchedMeters,
  searchLoading,
  onUpdateMeter,
  onUpdateSearchedMeters,
}) => {
  const { t } = useTranslation();
  const recentMeters = getRecentlySearchedMeters();
  const [showInactive, setShowInactive] = useState(false);

  let loadingComp = null;

  if (searchLoading) {
    loadingComp = !searchText.length ? t('Loading meters...') : t('Searching for match...');
  } else if (!searchedMeters.length) {
    loadingComp = t('No results found');
  }

  const currentMeter = useMemo(() => {
    if (!selectedMeter) return null;
    let result = searchedMeters.find(({ meterUid }) => meterUid === selectedMeter.uid) || null;
    if (result) result = { value: result.meterUid, label: result.meterDisplayName };
    return result;
  }, [searchedMeters, selectedMeter]);

  const onSearchUpdate = useCallback(
    (searchText) => onUpdateSearchedMeters(searchText, { showInactive }),
    [showInactive, onUpdateSearchedMeters]
  );

  return (
    <>
      <Paragraph>{t('modal.search-meter')}</Paragraph>
      <label>{t('Find a meter')}</label>
      <AsyncSelect
        // AsyncSelect provides no way to trigger a reload or sources
        // Using key update to perform this
        key={`selector_with_inactive_${showInactive}`}
        value={currentMeter}
        defaultOptions
        noOptionsMessage={() => loadingComp}
        loadOptions={onSearchUpdate}
        isOptionSelected={(option) => (selectedMeter ? option.value === selectedMeter.displayName : false)}
        onChange={(option) => (option && option.value ? onUpdateMeter(option.value) : onUpdateMeter(null))}
        menuPosition="fixed"
      />
      <Flex
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: 2,
        }}
      >
        <Label htmlFor="toggle-inactive-meters" sx={{ textAlign: 'right', paddingRight: 2 }}>
          {t('Show inactive meters')}
        </Label>
        <Box>
          <Switch
            id="toggle-inactive-meters"
            checked={showInactive}
            onChange={() => setShowInactive(!showInactive)}
          />
        </Box>
      </Flex>
      {!!(recentMeters && recentMeters.length) && (
        <Box sx={{ mt: 2 }}>
          <label>{t('Recently searched')}</label>
          <ul>
            {recentMeters.map((val) => (
              <li key={val.displayName}>
                <Button
                  variant="text"
                  onClick={() => {
                    onUpdateMeter(val.uid);
                  }}
                >
                  {val.displayName}
                </Button>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </>
  );
};

SearchMeterContainer.propTypes = {
  selectedMeter: PropTypes.any,
  searchText: PropTypes.any,
  searchedMeters: PropTypes.any,
  searchLoading: PropTypes.any,
  onUpdateMeter: PropTypes.any,
  onUpdateSearchedMeters: PropTypes.any,
};

class SearchableMeter extends Component {
  _isMounted = false;

  _isSearching = false;

  constructor(props) {
    super(props);
    this.state = {
      isSearchAll: !this.props.selectedMeter || this.props.enableSearchAll,
      selectedMeter: this.props.selectedMeter,
      searchText: '',
      searchedMeters: [],
      meterOptions: [],
      searchLoading: false,
      requestToken: axios.CancelToken.source(),
    };
  }

  componentDidUpdate = (prevProps) => {
    this._isMounted = true;
    const { selectedMeter } = this.props;

    if (prevProps.selectedMeter !== selectedMeter && selectedMeter) {
      updateRecentlySearchedMeters(selectedMeter);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this._isSearching = false;
    this.state.requestToken.cancel();
  };

  updateSelected = (e) => {
    const { dispatch, enableSearchAll } = this.props;
    const { selectedMeter } = this.state;
    e.persist();

    if ((!enableSearchAll && selectedMeter) || enableSearchAll) {
      dispatch(updateSelectedMeter(selectedMeter));
    }

    this.toggleModal(false);
  };

  toggleModal = (visible) => {
    this.setState({ isSearchAll: !this.props.selectedMeter, selectedMeter: this.props.selectedMeter });
    this.props.dispatch(toggleSearchMeterVisible(visible));
  };

  updateMeter = (selectedMeterUid) => {
    const { searchedMeters } = this.state;
    let selectedMeter = null;

    if (searchedMeters && searchedMeters.length) {
      const selectedMeterIdx = searchedMeters.findIndex((v) => v.meterUid === selectedMeterUid);

      if (selectedMeterIdx !== -1) {
        const { meterUid, meterDisplayName, meterAssetType, accountNumber } =
          searchedMeters[selectedMeterIdx];
        selectedMeter = {
          uid: meterUid,
          displayName: meterDisplayName,
          assetType: meterAssetType,
          accountNumber,
        };
      }
    } else {
      const meterList = getRecentlySearchedMeters();
      const selectedMeterIdx = meterList.findIndex((v) => v.uid === selectedMeterUid);

      if (selectedMeterIdx !== -1) {
        selectedMeter = meterList[selectedMeterIdx];
      }
    }

    if (selectedMeter) this.setState((ps) => ({ ...ps, selectedMeter }));
  };

  updateSearchedMeters = (searchText, { showInactive } = {}) => {
    const { meterGroup } = this.props;
    const { requestToken } = this.state;
    const newToken = axios.CancelToken.source();
    this._isSearching = true;

    requestToken.cancel();

    const keysToCheck = ['meterUid', 'meterDisplayName', 'firstName', 'lastName', 'address'];

    this.setState((ps) => ({
      searchLoading: true,
      searchedMeters: ps.searchedMeters.filter(
        (v) =>
          !!Object.keys(v).filter((x) => keysToCheck.includes(x) && hasMatchingText(searchText, v[x])).length
      ),
    }));

    const search = async () => {
      const { t } = this.props;
      const request = {
        tradingGroupName: meterGroup.name,
        searchTerm: searchText,
        assetType: ['PROSUMER', 'PRODUCER', 'CONSUMER'],
        size: 150,
      };

      const { status, content } = await getSearchMeters(request, this.props.isAdmin || false);

      if (this._isMounted)
        // eslint-disable-next-line consistent-return
        this.setState((ps) => {
          this._isSearching = false;
          if (status !== 499) {
            const searchedMeters = content.map((v) => ({
              ...v,
              ...((v.firstName || v.lastName) && {
                fullName: `
                ${v.firstName ? `${v.firstName} ` : ''}
                ${v.lastName ? `${v.lastName}` : ''}
              `,
                uid: v.meterUid,
              }),
            }));
            return { ...ps, searchedMeters };
          }
        });

      if (status !== 499)
        setTimeout(() => {
          if (this._isMounted && !this._isSearching) this.setState({ searchLoading: false });
        }, 300);

      const searchedMeters = content
        .map((v) => ({
          ...v,
          ...((v.firstName || v.lastName) && {
            fullName: `
        ${v.firstName ? `${v.firstName} ` : ''}
        ${v.lastName ? `${v.lastName}` : ''}
      `,
          }),
          label: v.meterDisplayName,
          value: v.meterUid,
        }))
        .filter((meter) => showInactive || meter.meterActive);

      let categorisedMeters = {};
      let otherTypeMeters = [];
      let groupedOtherMeters = {};

      if (searchedMeters && searchedMeters.length) {
        categorisedMeters = categoriseMeters(searchedMeters);
        otherTypeMeters = categoriseOtherMeters(searchedMeters);
        groupedOtherMeters = categoriseMeterUsers(otherTypeMeters);
      }

      let meterOptions = Object.keys(categorisedMeters)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .map((val) => {
          const meterParent = categoriseMeterUsers(categorisedMeters[val]);
          const groupedMeters = Object.keys(meterParent).map((v) => meterParent[v]);

          return {
            label: val,
            options: groupedMeters.map((v) => ({
              value: v[0].meterUid,
              label: renderMeterUserItem(searchText, v),
            })),
          };
        });

      if (otherTypeMeters && otherTypeMeters.length) {
        meterOptions = [
          ...meterOptions,
          {
            label: t('Others'),
            options: Object.keys(groupedOtherMeters).map((v) => ({
              value: groupedOtherMeters[v][0].meterUid,
              label: renderMeterUserItem(searchText, groupedOtherMeters[v]),
            })),
          },
        ];
      }

      return meterOptions;
    };

    return search().then((meterOptions) => {
      if (this._isMounted) this.setState({ searchText, requestToken: newToken, meterOptions });
      return meterOptions;
    });
  };

  render() {
    const { meters, enableSearchAll, searchMeterIsVisible, selectedMeter, t } = this.props;
    const { isSearchAll } = this.state;

    let meterPlaceholder = meters.length && !enableSearchAll ? meters[0].displayName : '';

    if (enableSearchAll) {
      meterPlaceholder = selectedMeter ? selectedMeter.displayName : t('All meters');
    } else {
      meterPlaceholder = selectedMeter ? selectedMeter.displayName : meterPlaceholder;
    }

    const searchableMeterOptions = [
      { value: true, label: t('Display all meters') },
      { value: false, label: t('Search for a meter') },
    ];

    return (
      <>
        <Button data-testid="meter-select" variant="secondary" onClick={() => this.toggleModal(true)}>
          <Box sx={{ mr: 2 }}>{meterPlaceholder}</Box>
          <DropdownArrowSVG />
        </Button>
        <Modal
          title={t('Select a meter')}
          visible={searchMeterIsVisible}
          onOk={(event) => this.updateSelected(event)}
          okButtonContent={t('Apply')}
          okDisabled={!isSearchAll && !this.state.selectedMeter}
          onCancel={() => this.toggleModal(false)}
        >
          <div>
            {enableSearchAll ? (
              <>
                <label>{t('Meter selection type')}</label>
                <Select
                  value={searchableMeterOptions.find(({ value }) => value === isSearchAll)}
                  options={searchableMeterOptions}
                  onChange={({ value }) => {
                    this.setState({
                      ...(value === true
                        ? { isSearchAll: true, selectedMeter: null }
                        : { isSearchAll: false }),
                    });
                  }}
                  menuPosition="fixed"
                />
                {!isSearchAll && (
                  <SearchMeterContainer
                    meters={meters}
                    onUpdateMeter={this.updateMeter}
                    onUpdateSearchedMeters={this.updateSearchedMeters}
                    {...this.state}
                  />
                )}
              </>
            ) : (
              <SearchMeterContainer
                meters={meters}
                onUpdateMeter={this.updateMeter}
                onUpdateSearchedMeters={this.updateSearchedMeters}
                {...this.state}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

SearchableMeter.defaultProps = {
  enableSearchAll: true,
  searchMeterIsVisible: false,
  selectedMeter: null,
};

SearchableMeter.propTypes = {
  meters: PropTypes.array.isRequired,
  meterGroup: PropTypes.any,
  t: PropTypes.any,
  dispatch: PropTypes.any,
  enableSearchAll: PropTypes.bool,
  searchMeterIsVisible: PropTypes.bool,
  selectedMeter: PropTypes.any,
};

const mapStateToProps = ({ meters, controls, auth }) => ({
  ...meters,
  ...controls,
  isAdmin: containsAdminRole(auth.profile?.userRoles),
});

export default connect(mapStateToProps)(withTranslation()(SearchableMeter));
