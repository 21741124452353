import { ChangeEventHandler, FC, useRef } from 'react';
import { Box, Checkbox as ThemeCheckbox, Label, ThemeUIStyleObject } from 'theme-ui';
import { RoundedCheckbox } from '../icons';

export type CheckboxProps = {
  label?: any;
  id?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  sx?: ThemeUIStyleObject;
  rounded?: boolean;
  disabled?: boolean;
  variant?: string;
};

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  checked,
  onChange,
  sx = {},
  rounded = false,
  disabled,
  ...props
}) => {
  const checkboxRef = useRef<HTMLInputElement>();

  return (
    <Label
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        ...sx,
      }}
      htmlFor={id}
      variant="checkboxLabel"
    >
      {rounded && (
        <RoundedCheckbox
          // @ts-ignore
          checked={checked}
          onClick={() => checkboxRef?.current?.click()}
          size={5}
          {...props}
        />
      )}
      <Box sx={{ display: rounded ? 'none' : 'contents' }}>
        <ThemeCheckbox
          {...props}
          id={id}
          // @ts-ignore
          ref={checkboxRef}
          disabled={disabled}
          checked={checked}
          variant={props.variant || 'checkbox'}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
      </Box>
      {label}
    </Label>
  );
};
