/** @jsxImportSource theme-ui */
import { FC, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Input, ThemeUICSSObject } from 'theme-ui';
import { Overlay, useRootClose } from 'react-overlays';
import { DateTime } from 'luxon';
import { parseDate, UndeterminedDate } from '../../../lib/datetime-helpers';

export type TimePickerProps = {
  value: UndeterminedDate;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
  showSecond?: boolean;
};

const buttonStyling: ThemeUICSSObject = {
  variant: 'timePickers.timeButton',
};

const displayFormat = 'HH:mm';

export const TimePicker: FC<TimePickerProps> = ({ value, onChange, disabled }) => {
  const [combinedDate, setCombinedDate] = useState<DateTime>(parseDate(value));
  const containerRef = useRef(null);
  const triggerRef = useRef(null);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const handleRootClose = () => setShowTimePicker(false);

  useRootClose(containerRef, handleRootClose, {
    disabled: !showTimePicker,
  });

  const clickHandler = useCallback(
    (type: 'hours' | 'minutes' | 'seconds'): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        // @ts-ignore
        const newVal = Number(e.target.textContent);
        const newDate = combinedDate.set({ [type]: newVal });
        setCombinedDate(newDate);
        onChange(newDate);
        setShowTimePicker(false);
      },
    [combinedDate, onChange]
  );

  const hours = useMemo(
    () =>
      Array.from({ length: 24 }, (_, i) => (
        <button type="button" onClick={clickHandler('hours')} key={i} sx={buttonStyling}>
          {i.toString().padStart(2, '0')}
        </button>
      )),
    [clickHandler]
  );

  const minutes = useMemo(
    () =>
      Array.from({ length: 60 }, (_, i) => (
        <button type="button" onClick={clickHandler('minutes')} key={i} sx={buttonStyling}>
          {i.toString().padStart(2, '0')}
        </button>
      )),
    [clickHandler]
  );

  return (
    <Box sx={{ maxWidth: '170px', position: 'relative' }} ref={containerRef}>
      <Input
        value={combinedDate.toFormat(displayFormat)}
        ref={triggerRef}
        onChange={(e) => e}
        onFocus={() => {
          setShowTimePicker(true);
        }}
        disabled={disabled}
      />
      <Overlay show={showTimePicker} container={containerRef} target={triggerRef}>
        {() => (
          <div
            sx={{
              variant: 'timePickers.overlay',
            }}
          >
            <div
              sx={{
                variant: 'timePickers.scrollColumn',
                borderRightStyle: 'solid',
                borderRightColor: 'muted',
                borderRightWidth: 2,
              }}
            >
              {hours}
            </div>
            <div
              sx={{
                variant: 'timePickers.scrollColumn',
              }}
            >
              {minutes}
            </div>
          </div>
        )}
      </Overlay>
    </Box>
  );
};
