import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { merge } from 'theme-ui';
import pickBy from 'lodash/pickBy';
import { Settings } from 'luxon';

import { defaultTheme } from '../../styles/defaultTheme';
import { GET, POST } from '../../middleware/api-methods';
import { projectState, themes } from '../../middleware/endpoints';
import { infoNotification, successNotification, successRegex } from '../../lib/notifications';
import i18n, { changeNumeralLocale, getBaseLanguage } from '../../lib/i18n';
import { generateFullPalette } from '../../styles/colors';

let lastLockedLanguageRef;

const languageLocker = (lockLanguage) => {
  const languageLock = async (lng) => {
    changeNumeralLocale(lng);
    // eslint-disable-next-line prefer-destructuring
    Settings.defaultLocale = getBaseLanguage(lng);
    if (lng !== lockLanguage) {
      if (i18n.store.data[lockLanguage]) {
        await i18n.changeLanguage(lockLanguage);
      } else {
        console.error(`Could not retrieve translations for ${lockLanguage}`);
      }
    }
  };
  lastLockedLanguageRef = languageLock;
  return languageLock;
};

const changeLanguage = async (forcedLocale) => {
  if (lastLockedLanguageRef) {
    i18n.off('languageChanged', lastLockedLanguageRef);
  }
  i18n.on('languageChanged', languageLocker(forcedLocale));
  await i18n.changeLanguage(forcedLocale);
};

/**
 * Get our trading group/host theme and active project status.
 * These are exposed publicly, and are also fetched with our hostname which is derived from
 * the window object.
 */
export const getHostData = createAsyncThunk('host/getHostData', async ({ host, ...params }) => {
  const timeout = 10000; // If host theme data is not received in ten seconds, timeout the request

  const themeDataGET = GET(themes.themeURL + host, true, params, timeout);

  const projectStateGET = GET(projectState.projectStateURL + window.location.hostname, true, params, timeout);

  const [themeResponse, projectStateResponse] = await Promise.all([themeDataGET, projectStateGET]);

  if (!successRegex.test(themeResponse.status)) {
    console.warn('Could not retrieve host theme');
    throw new Error('Could not retrieve host theme');
  }
  if (!successRegex.test(projectStateResponse.status)) {
    console.warn('Could not get end of project state');
    throw new Error('Could not get end of project state');
  }

  const forcedLocale = themeResponse.body.locale;

  if (forcedLocale) {
    i18n.loadLanguages(forcedLocale, async (err) => {
      if (err) {
        console.error(err);
      } else {
        await changeLanguage(forcedLocale);
      }
    });
  }

  return {
    ...themeResponse.body,
    ...projectStateResponse.body,
  };
});

export const setHostData = createAsyncThunk('host/setHostData', async ({ host, theme }) => {
  const response = await POST(themes.themeURL + host, true, theme);
  const { status, body } = response;

  if (successRegex.test(status)) {
    return body;
  }
  throw new Error('Could not save theme settings at this time');
});

const handleThemeMerge = (oldTheme, newTheme) => {
  if (!newTheme) return oldTheme;
  const newColors = newTheme.colors;
  const oldBaseColors = pickBy(
    oldTheme.colors,
    (val, key) => !key.includes('Dark') && !key.includes('Light')
  );
  const mergedBaseColors = { ...oldBaseColors, ...newColors };
  const fullNewPalette = generateFullPalette(mergedBaseColors);
  const fullNewTheme = { ...newTheme, colors: fullNewPalette };
  return merge(oldTheme, fullNewTheme);
};

const options = {
  name: 'host',
  initialState: {
    theme: defaultTheme,
    /** Active project or not? */
    active: true,
    locales: {},
    labels: {},
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [getHostData.fulfilled]: (state, { payload }) => {
      const newTheme = handleThemeMerge(state.theme, payload.theme);

      return {
        ...state,
        ...payload,
        theme: newTheme,
        loading: false,
      };
    },
    [getHostData.pending]: (state) => {
      state.loading = true;
    },
    [getHostData.rejected]: (state) => {
      state.loading = false;
      infoNotification({
        message: i18n.t('Problem getting settings'),
        description: i18n.t('Could not get host specific settings at this time'),
      });
    },
    [setHostData.pending]: (
      state,
      {
        meta: {
          arg: { theme },
        },
      }
    ) => ({
      ...state,
      theme: { ...theme },
      oldThemeState: {
        ...state.theme,
      },
    }),
    [setHostData.fulfilled]: (state, { payload }) => {
      successNotification({
        message: i18n.t('Theme Updated'),
        description: i18n.t('Theme config has been updated'),
      });
      return {
        ...state,
        ...payload,
      };
    },
    [setHostData.rejected]: (state) => {
      infoNotification({
        message: i18n.t('Problem saving settings'),
        description: i18n.t('Could not save theme settings at this time'),
      });
      if (state.oldThemeState) {
        state.theme = state.oldThemeState;
      } else {
        state.theme = defaultTheme;
      }
    },
  },
};

const hostSlice = createSlice(options);

export default hostSlice.reducer;
