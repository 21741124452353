import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';

import { CloseOutlined, Hamburger } from '../icons';
import { useScreenSize } from '../../hooks/use-screen-size';

export const mobileMenuStyles: ThemeUIStyleObject = {
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export interface NewSiderProps {
  logo?: React.ReactNode;
  poweredByLogo?: React.ReactNode;
  closed?: boolean;
  collapseMenu?: () => void;
  secondaryMenu?: React.ReactNode;
  sx?: ThemeUIStyleObject;
}

export const NewSider: React.FC<NewSiderProps> = ({
  logo,
  poweredByLogo,
  closed = false,
  collapseMenu,
  secondaryMenu,
  children,
  sx,
}) => {
  const { isMediumDown } = useScreenSize();

  return (
    <Box
      sx={{
        variant: 'layouts.newSiders',
        height: closed ? [0, 0, '100vh'] : '100vh',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: ['flex', null, 'flex'],
          justifyContent: ['space-between', null, 'center'],
          alignItems: 'center',
          flex: 0,
          maxHeight: [8, '100%'],
          p: 2,
          width: '100%',
          minHeight: [8, 'fit-content'],
        }}
      >
        {isMediumDown ? (
          <Box
            sx={{
              display: ['flex', null, 'none'],
              outline: '0',
              cursor: 'pointer',
              '&:focus': {
                boxShadow: 'focus',
              },
              justifyContent: 'space-between',
              width: '100%',
            }}
            tabIndex={0}
            onClick={collapseMenu}
            onKeyDown={(e) => {
              const key = e.code;

              return key === 'Enter' || key === 'Space' ? collapseMenu?.() : e;
            }}
          >
            {logo}
            <CloseOutlined color="white" size={5} />
          </Box>
        ) : (
          logo
        )}
      </Box>
      {!isMediumDown ? (
        <Box
          sx={{
            display: ['none', null, 'block'],
            flex: 1,
          }}
        >
          {children}
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: ['flex', null, 'none'],
            justifyContent: 'center',
            transition: 'base',
            opacity: [closed ? 0 : 1, null, 1],
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              alignItems: 'center',
              height: ['auto', '50vh'],
            }}
          >
            <Flex sx={mobileMenuStyles}>{children}</Flex>
            <Box
              sx={{
                width: ['100%', 'auto'],
                height: ['auto', '100%'],
                my: [2, 0],
                borderTopStyle: ['solid', 'none'],
                borderTopWidth: [1, 0],
                borderLeftStyle: ['none', 'solid'],
                borderLeftWidth: [0, 2],
                borderColor: 'white',
              }}
            ></Box>
            <Flex
              sx={{
                ...mobileMenuStyles,
                justifyContent: 'flex-start',
              }}
            >
              {secondaryMenu}
            </Flex>
          </Flex>
        </Box>
      )}

      {poweredByLogo}
    </Box>
  );
};
