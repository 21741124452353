import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { sentryConfig } from '../middleware';
import { appHistory } from './history';

const isProdOrSandbox = (env: string) => ['sandbox', 'production'].includes(env.toLowerCase());

if (sentryConfig.dsn && sentryConfig.env && sentryConfig.release) {
  Sentry.init({
    dsn: sentryConfig.dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(appHistory),
      }),
    ],
    environment: sentryConfig.env,
    release: sentryConfig.release,
    tracesSampleRate: isProdOrSandbox(sentryConfig.env) ? 0.8 : 0,
  });
} else {
  console.warn('Sentry Not Configured');
}
