import { DateTime } from 'luxon';

import { formatNiceOfferDateTime, parseDate } from '../../lib/datetime-helpers';
import { capitalise } from '../../lib/helpers';
import { checkCommonUpdates, checkPaginationUpdates } from './controls';
import { convertSparkzWhToSparkzKWh } from './pricing';

export const checkUpdates = (currentProps, nextProps, currentState, nextState) => {
  if (checkCommonUpdates(currentProps, nextProps, currentState, nextState)) {
    return true;
  }

  return (
    checkPaginationUpdates(currentState, nextState) ||
    currentState.category !== nextState.category ||
    JSON.stringify(currentState.offers) !== JSON.stringify(nextState.offers)
  );
};

export const getIconState = (status) => {
  switch (status) {
    case 'ACCEPTED':
    case 'COMPLETED':
      return 'active';
    case 'PENDING_ACCEPTANCE':
    case 'PENDING_CANCELLATION':
      return 'pending';
    case 'CANCELLED':
      return 'inactive';
    default:
      return 'inactive';
  }
};

export const getNiceStatus = (status, effectiveFrom, effectiveTo) => {
  switch (status) {
    case 'ACCEPTED': {
      let acceptState = 'Accepted';

      if (DateTime.now() < parseDate(effectiveFrom)) {
        acceptState = 'Confirmed';
      } else if (DateTime.now() < parseDate(effectiveTo) && DateTime.now() >= parseDate(effectiveFrom)) {
        acceptState = 'Active';
      } else if (DateTime.now() >= parseDate(effectiveTo)) {
        acceptState = 'Completed';
      }

      return acceptState;
    }
    case 'PENDING_ACCEPTANCE': {
      let pendingAcceptState = 'Pending acceptance';

      if (DateTime.now() >= parseDate(effectiveTo)) {
        pendingAcceptState = 'Expired';
      }

      return pendingAcceptState;
    }
    case 'PENDING_CANCELLATION':
      return 'Pending cancellation';
    default:
      return capitalise(status);
  }
};

export const getCriteria = (config) => {
  switch (config) {
    case 'ANY':
      return 'Any party';
    case 'ALL':
      return 'All parties';
    default:
      return '-';
  }
};

export const getSignType = (signingType, agreed, isSeller = false) => {
  let signType = 'update';

  if (signingType === 'ACCEPT' && !isSeller) {
    signType = agreed ? 'accept' : 'decline';
  } else if (signingType === 'CANCEL' && agreed) {
    signType = isSeller ? 'withdraw' : 'cancel';
  }

  return signType;
};

export const roundEnergyPrice = (val) => parseFloat(Math.round(val * 10000) / 10000);

export const mapOffers = (offers, meterGroup) => {
  const { timeZone, centSymbol, sparkzConversionRate } = meterGroup;

  if (!offers?.length) return [];

  return offers.map((val, key) => {
    const status =
      val.status === 'CANCELLED' && val.cancelReason && val.cancelReason !== 'TERMINATED'
        ? val.cancelReason
        : val.status;

    return {
      ...val,
      key,
      niceStatus: getNiceStatus(status, val.effectiveFrom, val.effectiveTo),
      niceOfferStart: formatNiceOfferDateTime(val.effectiveFrom, timeZone),
      niceOfferEnd: formatNiceOfferDateTime(val.effectiveTo, timeZone),
      energyPrice:
        typeof val.energyPrice === 'number'
          ? convertSparkzWhToSparkzKWh(val.energyPrice, sparkzConversionRate)
          : '-',
      energyPriceNetTax:
        typeof val.energyPriceNetTax === 'number' ? convertSparkzWhToSparkzKWh(val.energyPriceNetTax) : '-',
      formattedRate:
        typeof val.energyPrice === 'number'
          ? `${convertSparkzWhToSparkzKWh(val.energyPrice)} ${centSymbol}/kWh`
          : '-',
      formattedRateNetTax:
        typeof val.energyPriceNetTax === 'number'
          ? `${convertSparkzWhToSparkzKWh(val.energyPriceNetTax)} ${centSymbol}/kWh`
          : '-',
    };
  });
};
