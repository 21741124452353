export { default as BiogasPrimary } from './biogasPrimary.svg';
export { default as BiogasSecondary } from './biogasSecondary.svg';
export { default as BiomassPrimary } from './biomassPrimary.svg';
export { default as BiomassSecondary } from './biomassSecondary.svg';
export { default as FuelcellPrimary } from './fuelcellPrimary.svg';
export { default as FuelcellSecondary } from './fuelcellSecondary.svg';
export { default as GasPrimary } from './gasPrimary.svg';
export { default as GasSecondary } from './gasSecondary.svg';
export { default as GeothermalPrimary } from './geothermalPrimary.svg';
export { default as GeothermalSecondary } from './geothermalSecondary.svg';
export { default as GridPrimary } from './gridPrimary.svg';
export { default as GridSecondary } from './gridSecondary.svg';
export { default as HydrogenPrimary } from './hydrogenPrimary.svg';
export { default as HydrogenSecondary } from './hydrogenSecondary.svg';
export { default as HydroPrimary } from './hydroPrimary.svg';
export { default as HydroSecondary } from './hydroSecondary.svg';
export { default as OtherPrimary } from './otherPrimary.svg';
export { default as OtherSecondary } from './otherSecondary.svg';
export { default as SolarPrimary } from './solarPrimary.svg';
export { default as SolarSecondary } from './solarSecondary.svg';
export { default as WavePrimary } from './wavePrimary.svg';
export { default as WaveSecondary } from './waveSecondary.svg';
export { default as WindPrimary } from './windPrimary.svg';
export { default as WindSecondary } from './windSecondary.svg';
