import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { PointTooltipProps } from '@nivo/line';
import { Grid, Heading, Flex, Text } from 'theme-ui';

import { DAY_MONTH_SHORT, parseDateWithTimeZone } from '../../../lib/datetime-helpers';
import { convertAndFormatUnitValue } from '../../../lib/unit';
import { WattHour } from '../../../../../types';

export type CommunityUsageChartTooltipProps = PointTooltipProps & {
  isDayFormat: boolean;
  isEUTimeFormat?: boolean;
  timeZone: string;
};

const translateDate = ({ date, t, key, isDayFormat, isEUTimeFormat = false }: any) =>
  t(key, {
    defaultValue: '{{date}}',
    replace: {
      date: !isDayFormat
        ? `${
            isEUTimeFormat
              ? `${date.toLocaleString({ day: 'numeric' })} ${date.toLocaleString({ month: 'short' })}`
              : date.toLocaleString(DAY_MONTH_SHORT)
          } (${date.toLocaleString({
            weekday: 'short',
          })})`
        : date.toLocaleString(DateTime.TIME_24_SIMPLE),
    },
  });

export const CommunityUsageChartTooltip: React.FC<CommunityUsageChartTooltipProps> = ({
  point,
  isDayFormat,
  isEUTimeFormat,
  fullData,
  timeZone,
}: any) => {
  const { t } = useTranslation();
  const idOfData = point.id.split('.').pop();

  const formattedData = useMemo(() => {
    return fullData.map(({ id, data }: any) => {
      const { y } = data[Number(idOfData)];
      const title = t(`dashboard.chart.tooltip.title.${id}`, {
        defaultValue: '{{title}}',
        replace: {
          title: id,
        },
      });
      return { title, id, y };
    });
  }, [fullData, idOfData, t]);

  const currentPeriodData = useMemo(
    () => formattedData.filter((data: any) => data?.id.includes('current')),
    [formattedData]
  );
  const previousPeriodData = useMemo(
    () => formattedData.filter((data: any) => data?.id.includes('previous')),
    [formattedData]
  );

  const date = parseDateWithTimeZone(point.data.x, timeZone);
  const previousDateNum = fullData.find(({ id }: any) => id.includes('previous'))?.data?.[idOfData]
    ?.previousDate;
  const previousDate = previousDateNum ? parseDateWithTimeZone(previousDateNum, timeZone) : null;

  const components = useMemo(() => {
    const dataDisplay = (data?: { title: string; id: string; y: number }) => {
      if (!data) return <div> - </div>;

      const { id, title, y } = data;

      const { formattedNewValue, newUnit } = convertAndFormatUnitValue(y, WattHour.kWh);

      return (
        <div key={id}>
          <Heading
            as="h5"
            sx={{
              display: 'inline-block',
              fontWeight: 'light',
              m: 0,
              p: 0,
            }}
          >
            {title}
          </Heading>
          <Text sx={{ fontSize: 1, pl: 2 }}>
            {Number.isNaN(y) ? '-' : [formattedNewValue, newUnit].join(' ')}
          </Text>
        </div>
      );
    };

    return (
      <>
        {currentPeriodData.length > 0 && (
          <>
            <Text key="current" sx={{ color: 'muted', fontWeight: 'light' }}>
              {translateDate({
                t,
                key: 'dashboard.chart.tooltip.currentTime',
                date: date,
                isDayFormat,
                isEUTimeFormat,
              })}
            </Text>
            <Flex sx={{ flexDirection: 'column' }}>
              {currentPeriodData.map((data: any) => data?.id && dataDisplay(data))}
            </Flex>
          </>
        )}
        {previousPeriodData.length > 0 && (
          <>
            <Text key="previous" sx={{ color: 'muted', fontWeight: 'light' }}>
              {translateDate({
                t,
                key: 'dashboard.chart.tooltip.previousTime',
                date: previousDate,
                isDayFormat,
                isEUTimeFormat,
              })}
            </Text>
            <Flex sx={{ flexDirection: 'column' }}>
              {previousPeriodData.map((data: any) => data?.id && dataDisplay(data))}
            </Flex>
          </>
        )}
      </>
    );
  }, [currentPeriodData, date, isDayFormat, previousDate, previousPeriodData, isEUTimeFormat, t]);

  return (
    <Grid gap={2} columns={[1, null, 2]} sx={{ alignItems: 'center' }}>
      {components}
    </Grid>
  );
};
