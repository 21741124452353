import { Trans } from 'react-i18next';

export const getHostInvitationDisplay = (t: any, isVB: boolean) => {
  if (isVB) {
    return {
      title: t('Welcome!'),
      description: (
        <Trans t={t}>
          You&apos;ve been invited to participate in the <strong>VB Solar Exchange</strong>. Please create a
          password in order to access your account.
        </Trans>
      ),
      successTitle: t('Your password has been set.'),
      successDescription: t(
        'You can now access your account. You can find your username in the invitation email. Click the link below to login.'
      ),
      returnToLoginText: t('Go to login'),
    };
  }
  return {
    title: t('Enter your new password'),
    description: t(
      'Your password must be at least 8 characters long, containing at least one uppercase letter, one lowercase letter and one number.'
    ),
    successTitle: t("You've reset your password"),
    successDescription: t(
      'You can now use your new password to access your account. Click the link below to return to login.'
    ),
    returnToLoginText: t('Return to login'),
  };
};
