import round from 'lodash/round';

import { WattHour, Weight, CO2Mass, Unit } from '../../../../types/unit';
import { ConvertAndFormatUnitValueResult } from './unit.types';

const UNIT_STRATEGY_MAPPING = {
  WattHour: {
    threshold: 1_000,
    conversionRate: 1000,
    orderedUnits: Object.values(WattHour),
    isOfType: (unit: Unit): unit is WattHour => {
      return Object.values(WattHour).includes(unit as WattHour);
    },
    isLastUnit: (unit: Unit): unit is WattHour => {
      return unit === WattHour.PWh;
    },
  },
  Weight: {
    threshold: 1_000,
    conversionRate: 1000,
    orderedUnits: Object.values(Weight),
    isOfType: (unit: Unit): unit is Weight => {
      return Object.values(Weight).includes(unit as Weight);
    },
    isLastUnit: (unit: Unit): unit is Weight => {
      return unit === Weight.Pt;
    },
  },
  CO2Mass: {
    threshold: 1_000,
    conversionRate: 1000,
    orderedUnits: Object.values(CO2Mass),
    isOfType: (unit: Unit): unit is CO2Mass => {
      return Object.values(CO2Mass).includes(unit as CO2Mass);
    },
    isLastUnit: (unit: Unit): unit is CO2Mass => {
      return unit === CO2Mass.PtCO2;
    },
  },
};

const findUnitStrategy = (unit: Unit) => {
  return Object.values(UNIT_STRATEGY_MAPPING).find((strategy) => strategy.isOfType(unit));
};

export const convertAndFormatUnitValue = (
  value = 0,
  unit: Unit,
  precision = 2
): ConvertAndFormatUnitValueResult => {
  const unitStrategy = findUnitStrategy(unit);

  if (!unitStrategy || unitStrategy.isLastUnit(unit) || value < unitStrategy.threshold) {
    return {
      newValue: value,
      formattedNewValue: round(value, precision),
      newUnit: unit,
    };
  }

  const newUnit =
    unitStrategy.orderedUnits[
      unitStrategy.orderedUnits.findIndex((converterUnit) => converterUnit === unit) + 1
    ];
  const newValue = value / unitStrategy.conversionRate;

  return convertAndFormatUnitValue(newValue, newUnit, precision);
};
