import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

export const tableSharedStyles: ThemeUIStyleObject = {
  position: 'relative',
  fontVariantNumeric: 'tabular-nums',
  mozFontFeatureSettings: 'tnum',
  webkitFontFeatureSettings: 'tnum',
  fontFeatureSettings: 'tnum',
  textRendering: 'optimizeLegibility',
  fontSize: [1, null, 2],
  fontWeight: 'light',
  listStyle: 'none',
  textAlign: 'left',
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'separate',
  border: 'none',
  placeholder: {
    display: 'flex',
    bg: 'background',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'th, td': {
    px: 3,
    borderTop: 0,
    py: 2,
    whiteSpace: 'nowrap',
    overflowWrap: 'break-word',
  },
  thead: {
    border: 'none',
    color: 'accent',
    tr: {
      'th:first-of-type': {
        borderTopLeftRadius: 4,
      },
      'th:last-of-type': {
        borderTopRightRadius: 4,
      },
      th: {
        bg: 'secondaryDarker',
        fontWeight: 'bold',
        transition: 'background 0.3s ease',
        fontSize: 1,
      },
    },
  },
  tbody: {
    tr: {
      td: {
        transition: 'all 300ms, border 0s',
        fontSize: 1,
      },
      'td.pagination': {
        bg: 'mutedLightest',
      },
      '&:hover': {
        'td:not(.pagination):not(.no-hover)': { bg: 'background' },
      },
    },
  },

  pagination: {
    td: {
      bg: 'foreground',
    },
    button: {
      my: 2,
      mr: 3,
      '&:enabled:hover, &:enabled:focus': {
        color: 'background',
        bg: 'primary',
        'div svg path': {
          color: 'background',
          fill: 'background',
        },
      },
      '&:disabled': {
        opacity: '50%',
        bg: 'transparent',
        cursor: 'not-allowed',
      },
    },
  },
};

export const lemTable = {
  ...tableSharedStyles,
  thead: {
    border: 'none',
    color: 'accent',
    tr: {
      'th:first-of-type': {
        borderTopLeftRadius: 3,
      },
      'th:last-of-type': {
        borderTopRightRadius: 3,
      },
      th: {
        bg: 'secondaryDarker',
        fontWeight: 'bold',
        transition: 'background 0.3s ease',
        fontSize: 1,
      },
    },
  },
};

export const mdxTableStyling: ThemeUIStyleObject = {
  'table:not(.CalendarMonth_table)': {
    ...tableSharedStyles,
    'th, td': {
      paddingRight: [2, null, 3],
      paddingLeft: [2, null, 3],
      whiteSpace: 'normal',
      overflowWrap: 'normal',
    },
  },
};

export enum TableVariants {
  Normal = 'normal',
  Lem = 'lem',
}

export const tables = CustomThemeProperty('tables', { normal: tableSharedStyles, lem: lemTable });
