import { FC, useState, useCallback, useEffect } from 'react';
import { Flex, Box, ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { Card, Tabs, LoadingOverlay } from '../index';
import AreaChart from '../AreaChart';
import OrdersList from './OrdersList';

export type RecGraphProps = {
  recValue: any;
  title: string;
};

export type RecGraphLegendItemType = FC<{
  text: string;
  color: string;
  sx?: ThemeUIStyleObject;
}>;

const LegendItem: RecGraphLegendItemType = ({ text, color, sx }) => (
  <Flex sx={{ alignItems: 'center', ...sx }}>
    <Box sx={{ width: 2, height: 2, borderRadius: 6, bg: color, mr: 2 }} />
    <Box sx={{ fontSize: 0, color: 'textDarker' }}>{text}</Box>
  </Flex>
);

export const RecGraph: FC<RecGraphProps> = ({ recValue, title }) => {
  const { t } = useTranslation();

  const loadOrders = useCallback(() => {
    setAltData(!useAltData);
    setLoading(true);
    // TODO: get REC orders data from API, set proper defaults based on API response
    setTimeout(() => {
      const tempOrdersResult = useAltData
        ? [
            {
              id: 'Bids',
              color: '#22DEAB',
              data: [
                { id: 'buy1', volume: 175, price: 16.8 },
                { id: 'buy2', volume: 135, price: 16.9 },
                { id: 'buy3', volume: 125, price: 17 },
                { id: 'buy4', volume: 100, price: 17.1 },
                { id: 'buy5', volume: 65, price: 17.2 },
                { id: 'buy6', volume: 50, price: 17.3 },
                { id: 'buy7', volume: 35, price: 17.4 },
                { id: 'buy8', volume: 1, price: 17.6 },
              ],
            },
            {
              id: 'Asks',
              color: '#E23145',
              data: [
                { id: 'sold1', volume: 5, price: 17.7 },
                { id: 'sold2', volume: 15, price: 17.8 },
                { id: 'sold3', volume: 50, price: 17.9 },
                { id: 'sold4', volume: 65, price: 18 },
                { id: 'sold5', volume: 85, price: 18.1 },
                { id: 'sold6', volume: 105, price: 18.2 },
                { id: 'sold7', volume: 115, price: 18.3 },
                { id: 'sold8', volume: 115, price: 18.4 },
                { id: 'sold9', volume: 150, price: 18.5 },
              ],
            },
          ]
        : [
            {
              id: 'Bids',
              color: '#22DEAB',
              data: [
                { id: 'buy1', volume: 110, price: 16.8 },
                { id: 'buy2', volume: 95, price: 16.9 },
                { id: 'buy3', volume: 86, price: 17 },
                { id: 'buy4', volume: 67, price: 17.1 },
                { id: 'buy5', volume: 67, price: 17.2 },
                { id: 'buy6', volume: 67, price: 17.3 },
                { id: 'buy7', volume: 43, price: 17.4 },
                { id: 'buy8', volume: 10, price: 17.6 },
              ],
            },
            {
              id: 'Asks',
              color: '#E23145',
              data: [
                { id: 'sold1', volume: 10, price: 17.7 },
                { id: 'sold2', volume: 20, price: 17.8 },
                { id: 'sold3', volume: 40, price: 17.9 },
                { id: 'sold4', volume: 100, price: 18 },
                { id: 'sold5', volume: 120, price: 18.1 },
                { id: 'sold6', volume: 125, price: 18.2 },
                { id: 'sold7', volume: 135, price: 18.3 },
                { id: 'sold8', volume: 145, price: 18.4 },
                { id: 'sold9', volume: 150, price: 18.5 },
              ],
            },
          ];

      // @ts-ignore
      setOrders(tempOrdersResult);

      const tableData = tempOrdersResult.map((dataSet) => ({
        ...dataSet,
        data: dataSet.data.map((order) => ({
          ...order,
          x: order.price,
          y: order.volume,
        })),
      }));
      // @ts-ignore
      setTableData(tableData);

      setLoading(false);
    }, 1000);
    // TODO: Remove this once the actual endpoint is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recValue]);

  useEffect(() => loadOrders(), [loadOrders]);

  const [currentTab, setCurrentTab] = useState('buy');

  const [orders, setOrders] = useState([{ data: [] }, { data: [] }]);
  const [tableData, setTableData] = useState([{ data: [] }, { data: [] }]);
  const [loading, setLoading] = useState(false);
  // TODO: For demo purposes only
  const [useAltData, setAltData] = useState(false);

  return (
    <Card sx={{ height: '500px', position: 'relative' }} variant="compact">
      <LoadingOverlay loading={loading} />
      <Flex sx={{ height: '100%' }}>
        <Box sx={{ width: '67%' }}>
          <Flex
            sx={{
              height: '50px',
              px: 4,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ color: 'textDarker' }}>{title}</Box>
            <Flex sx={{ alignItems: 'center' }}>
              <LegendItem text={t('Buy')} color="positive" />
              <LegendItem text={t('Sell')} color="negative" sx={{ ml: 3 }} />
            </Flex>
          </Flex>
          <AreaChart
            title={title}
            // @ts-ignore
            data={tableData}
            margin={{ top: 30, right: 60, bottom: 60, left: 60 }}
            height="350px"
          />
        </Box>
        <Box
          sx={{
            width: '33%',
            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: 'foregroundLight',
          }}
        >
          <Tabs activeKey={currentTab} noLeftTabBorderRadius onChange={setCurrentTab}>
            <Tabs.TabPane tab={t('Buy')} key="buy">
              <OrdersList orders={orders[0].data} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('Sell')} key="sell">
              <OrdersList orders={orders[1].data} />
            </Tabs.TabPane>
          </Tabs>
        </Box>
      </Flex>
    </Card>
  );
};

export { Selector } from './Selector';
