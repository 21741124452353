import { FC, ReactNode } from 'react';
import ReactSelect from 'react-select';
import { Box, css, ThemeUIStyleObject } from 'theme-ui';
import { getColor } from '@theme-ui/color';
import { useTheme } from '@power-ledger/hooks';

export type SelectOptionType = FC;

export type SelectOptGroupType = FC;

export type SelectType = FC<{
  sx?: ThemeUIStyleObject;
  value?: any;
  onChange?: any;
  options?: Array<any>;
  placeholder?: string | ReactNode;
  controlStyles?: any;
  disabled?: boolean;
}>;

const getSelectStyles = ({ theme = {}, controlStyles = {} }) => {
  const placeholder = (_: any, state: any) => {
    const { menuIsOpen, isSearchable } = state.selectProps;
    return css({
      variant: 'recGraph.selectors.placeholder',
      display: menuIsOpen && isSearchable ? 'none' : 'block',
    });
  };

  const menu = (styles = {}) => ({
    ...styles,
    background: getColor(theme, 'primary'),
  });

  const noOptionsMessage = (styles = {}) => ({
    ...styles,
    color: getColor(theme, 'background'),
  });

  const singleValue = (styles = {}) => ({
    ...styles,
    width: '100%',
    color: getColor(theme, 'background'),
  });

  const option = (_: any, state: any) =>
    css({
      ...(state.isSelected
        ? {
            variant: 'recGraph.selectors.optionActive',
          }
        : { variant: 'recGraph.selectors.option' }),
    });

  const control = () =>
    css({
      display: 'flex',
      variant: 'recGraph.selectors.control',
      ...controlStyles,
    });

  const dropdownIndicator = (_: any, state: any) =>
    css({
      variant: 'recGraph.selectors.dropdownIndicator',
      svg: {
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      },
    });

  const indicatorSeparator = () =>
    css({
      variant: 'recGraph.selectors.indicatorSeparator',
    });

  return {
    control,
    placeholder,
    singleValue,
    menu,
    option,
    dropdownIndicator,
    indicatorSeparator,
    noOptionsMessage,
  };
};

export const Selector: SelectType = ({
  sx,
  placeholder,
  options,
  value,
  onChange,
  controlStyles,
  disabled,
}) => {
  const { theme } = useTheme();

  const selectStyles = getSelectStyles({ theme, controlStyles });

  return (
    <Box sx={sx}>
      <ReactSelect
        name="select"
        placeholder={placeholder}
        // @ts-ignore
        styles={selectStyles}
        isSearchable={false}
        isClearable={false}
        // menuIsOpen
        options={options}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
      />
    </Box>
  );
};

export default Selector;
