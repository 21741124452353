/** @jsxImportSource theme-ui */
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo } from 'react';
import { Flex, Box } from 'theme-ui';
import { Column, usePagination, useTable, UseTableInstanceProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import findLastIndex from 'lodash/findLastIndex';
import { useBreakpointIndex } from '@theme-ui/match-media';

import { useResize } from '../../hooks/use-resize';
import { useTheme } from '../../hooks/use-theme';
import { isEmpty } from '../../utils/value';
import { Pagination } from './Pagination';
import { TablePlaceholder } from './TablePlaceholder';
import { ManualPagination } from './ManualPagination';

const getPxWidth = (width: string | number | undefined, tableWidth: null | undefined | number) => {
  if (!width || !tableWidth) return undefined;

  const widthString = `${width}`;

  if (/px$/.test(widthString) || !/%$/.test(widthString)) return width;

  return Math.round((Number(widthString.replace('%', '')) / 100) * tableWidth);
};

// TODO burn with fire
type DisgustingUseTableTypeHacks = UseTableInstanceProps<any> & Record<string, any> & { state: any };

export interface TableColumn {
  key?: string;
  title?: ReactNode;
  dataIndex?: number | string;
  width?: number | string;
  render?: any;
  sticky?: boolean;
  left?: number;
}

export type TableProps = {
  columns: TableColumn[];
  dataSource: any[];
  pagination?: any;
  loading?:
    | {
        spinning: boolean;
        indicator: ReactNode;
      }
    | false;
  onChange?: any;
  onRow?: any;
  tableLayout?: 'auto' | 'fixed';
  locale?: Record<string, any>;
  className?: string;
  pageSize?: number;
  hiddenColumns?: string[];
  stickyHeader?: boolean;
  dataTestid?: string;
};

export interface TableInstance {
  gotoPage: (pageIndex: number) => void;
}

const DEFAULT_PAGE_SIZE = 10;

const getCellStyle = (
  column: TableColumn,
  borderColor: string,
  breakpointIndex: number,
  isLastStickyColumn = false
) => {
  let style: React.CSSProperties = {
    paddingRight: breakpointIndex < 2 ? 8 : 16,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: borderColor,
    borderRadius: 0,
  };

  if (column?.sticky) {
    style = {
      ...style,
      position: 'sticky',
      minWidth: column.width,
      maxWidth: column.width,
      left: column.left,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...(isLastStickyColumn
        ? {
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: borderColor,
          }
        : {}),
    };
  }

  return style;
};

const TableBase = (
  {
    columns,
    dataSource,
    pagination = false,
    loading = false,
    tableLayout = 'auto',
    locale = {},
    onRow,
    pageSize: pageSizeProp,
    hiddenColumns = [],
    stickyHeader = true,
    dataTestid,
  }: TableProps,
  ref: React.ForwardedRef<TableInstance>
) => {
  const { spinning, indicator }: any = loading || {};
  const { t } = useTranslation();
  const { theme } = useTheme();
  const breakpointIndex = useBreakpointIndex();

  const {
    ref: tableRef,
    dimensions: { width: tableWidth, windowHeight },
  } = useResize();

  const mappedColumns = useMemo(
    () =>
      (columns as any).map(({ title, dataIndex, width, render }: any) => {
        const pxWidth = getPxWidth(width, tableWidth);
        const col: Column<any> = {
          Header: title,
          accessor: typeof dataIndex === 'number' ? dataIndex.toString() : dataIndex,
          width: pxWidth,
        };
        if (typeof render === 'function') {
          col.Cell = (data: any) => render(data.row.original[data.column.id], data.row.original);
        }
        return col;
      }),
    [columns, tableWidth]
  );

  const plugins = useMemo(
    () => [...(pagination || pageSizeProp ? [usePagination] : [])],
    [pageSizeProp, pagination]
  );

  const calculatedPageCount =
    pagination?.manualPagination === false
      ? undefined
      : ((Math.ceil(pagination.total / (pageSizeProp || pagination.pageSize)) ||
          dataSource.length) as number);

  const paginationSettings =
    dataSource.length > DEFAULT_PAGE_SIZE && !pagination ? { manualPagination: false } : pagination;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: DisgustingUseTableTypeHacks = useTable(
    {
      columns: mappedColumns,
      data: dataSource as any,
      initialState: {
        pageSize: pageSizeProp || DEFAULT_PAGE_SIZE,
        hiddenColumns,
      } as any,
      manualPagination:
        paginationSettings?.manualPagination === false ? paginationSettings.manualPagination : true,
      pageCount: calculatedPageCount,
    } as any,
    ...(plugins as any)
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        gotoPage,
      };
    },
    [gotoPage]
  );

  useEffect(() => {
    if (typeof pagination?.onChange === 'function' && !pagination?.manualPagination) {
      pagination.onChange({ pageIndex, pageSize });
    }
  }, [pagination, pageIndex, pageSize]);

  const numColumns = useMemo(
    () =>
      headerGroups
        .map((headerGroup) => headerGroup.headers.length)
        .reduce((acc, hNum) => (hNum > acc ? hNum : acc), 0),
    [headerGroups]
  );

  const visibleColumns = useMemo(
    () =>
      columns.filter((column) => {
        const columnKey = !isEmpty(column.key) ? column.key : column.dataIndex;

        return isEmpty(columnKey) || !hiddenColumns.includes(String(columnKey));
      }),
    [columns, hiddenColumns]
  );

  const lastStickyColumnIndex = useMemo(
    () => findLastIndex(visibleColumns, (column) => !!column.sticky),
    [visibleColumns]
  );

  const tableRows = useMemo(
    () =>
      (pagination || pageSizeProp ? page : rows).map((row: any, rowIndex: number) => {
        prepareRow(row);

        return (
          <tr {...{ ...row.getRowProps() }} {...(onRow ? onRow(row.original, rowIndex) : {})}>
            {row.cells.map((cell: any, index: number) => {
              const style = getCellStyle(
                visibleColumns[index],
                theme.colors.muted as string,
                breakpointIndex,
                lastStickyColumnIndex === index
              );

              return (
                <td
                  data-testid={`${cell.column.id}`}
                  {...cell.getCellProps()}
                  style={style}
                  {...(style.maxWidth ? { title: cell.value } : {})}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      }),
    [
      onRow,
      page,
      pagination,
      pageSizeProp,
      prepareRow,
      rows,
      visibleColumns,
      breakpointIndex,
      lastStickyColumnIndex,
      theme.colors.muted,
    ]
  );

  const tableData = useMemo(() => {
    if (tableRows.length > 0) return tableRows;

    return (
      <TablePlaceholder
        text={locale.emptyText || t('No data found')}
        numColumns={numColumns}
        loading={spinning}
      />
    );
  }, [tableRows, spinning, locale.emptyText, t, numColumns]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        minHeight: Number(windowHeight) < 570 ? 300 : 100,
        maxHeight: 'calc(100vh - 320px)',
        justifyContent: 'center',
        position: 'relative',
      }}
      data-testid={dataTestid}
    >
      {spinning && (
        <Flex
          sx={{
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: tableRows.length ? 'rgba(5, 10, 20, 0.2)' : 'none',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            zIndex: 10,
          }}
        >
          {indicator}
        </Flex>
      )}
      <Box sx={{ overflowX: 'auto' }}>
        <table sx={{ variant: 'tables', tableLayout }} {...getTableProps()} ref={tableRef}>
          {dataSource.length > 0 && (
            <thead sx={stickyHeader ? { position: 'sticky', top: 0, zIndex: 9 } : {}}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    const style = getCellStyle(
                      visibleColumns[index],
                      theme.colors.muted as string,
                      breakpointIndex,
                      lastStickyColumnIndex === index
                    );

                    return (
                      <th
                        {...{
                          ...column.getHeaderProps(),
                          style: {
                            width: `${column.width}px`,
                            minWidth: `${column.width}px`,
                            maxWidth: `${column.width}px`,
                            paddingRight: 16,
                            ...style,
                            backgroundColor: '#ffffff',
                          },
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>{tableData}</tbody>
        </table>
      </Box>

      {!pagination?.manualPagination && (
        <Pagination
          pageSizeOptions={pagination.pageSizeOptions}
          onChange={pagination.onChange}
          numColumns={numColumns}
          pageCount={pageCount}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}

      {pagination?.manualPagination && (
        <ManualPagination
          onChange={pagination.onChange}
          pageSize={pagination.pageSize}
          pageIndex={pagination.pageIndex}
          pageCount={pagination.pageCount}
        />
      )}
    </Flex>
  );
};

export const Table = forwardRef(TableBase);
