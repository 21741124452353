import { CustomThemeProperty } from './types';

export const layouts = CustomThemeProperty('layouts', {
  siders: {
    bg: 'secondary',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    px: [3, null, 0],
    transition: 'all 0.3s ease',
    overflow: 'hidden',
  },
  newSiders: {
    bg: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  headers: {
    display: ['none', null, 'flex'],
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 4,
    bg: 'primary',
    color: 'white',
    height: 80,
  },
  subheaders: {
    minHeight: [0, null, 50],
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    alignItems: ['start', null, 'center'],
    justifyContent: ['space-between', null, 'flex-end'],
    px: [3, null, 4],
    bg: 'white',
  },
  footers: {
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    alignItems: 'center',
    justifyContent: 'space-between',
    height: ['auto', null, 9],
    px: 4,
    py: [3, null, 0],
    fontSize: 0,
    bg: 'white',
    color: 'primaryDark',
    width: '100%',
  },
  login: {
    backgroundColor: ['white', 'muted'],
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    alignItems: 'center',
    pt: 6,
    height: '100%',
    container: {
      maxWidth: '500px',
      width: '100%',
      backgroundColor: 'background',
      padding: 4,
      borderRadius: [0, 5],
    },
  },
  poweredByLogo: {
    p: 2,
  },
});
