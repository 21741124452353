import React from 'react';
import PropTypes from 'prop-types';

const ClassIISREC = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
    <path
      d="M6.55469 34.8115L8.15625 28.7009C8.22656 28.4274 8.46094 28.1852 8.67188
    28.1852H31.3047C31.5078 28.1852 31.75 28.4274 31.8203 28.7009L33.4297 34.8349C37.4609 31.178 40
    25.8879 40 20.0039C40 8.95487 31.0469 0 20 0C8.95312 0 0 8.95487 0 20.0039C0 25.8722 2.53125
    31.1545 6.55469 34.8115ZM13.0234 9.45497L15.1016 8.90799L15.1719 6.76695L17.2812 7.17328L18.2812
    5.26665L20 6.54815L21.7188 5.26665L22.7188 7.16546L24.8281 6.75913L24.8984 8.90018L26.9766
    9.44716L26.1094 11.4085L27.7344 12.815L26.1016 14.2137L26.9688 16.175L24.8906 16.722L24.8203
    18.8631L22.7109 18.4567L21.7188 20.3634L20 19.0819L18.2812 20.3634L17.2812 18.4645L15.1719
    18.8709L15.1016 16.7298L13.0234 16.1828L13.8906 14.2215L12.2656 12.815L13.8984 11.4163L13.0234
    9.45497Z"
      fill="url(#paint_linear_class_ii_srec)"
    />
    <path
      d="M31.2969 28.1853H8.67188C8.46875 28.1853 8.22656 28.4275 8.15625 28.701L6.55469
    34.8116C6.77344 35.0148 7 35.2101 7.23437 35.3976L7.57812 34.0302H12.9141L12.5391
    36.7651H9.09375C9.38281 36.9526 9.67969 37.1402 9.98438 37.3121H12.4766L12.3125 38.4686C12.7109
    38.6327 13.1172 38.7889 13.5313 38.9296L13.7109 37.3121H19.4219L19.3906 39.9923C19.6016 40.0001
    19.8047 40.0001 20.0156 40.0001C20.2344 40.0001 20.4609 39.9923 20.6797 39.9845L20.6484
    37.3043H26.3594L26.5547 38.8983C26.9609 38.7577 27.3594 38.6092 27.7578 38.4373L27.5938
    37.2965H30.0469C30.3516 37.1245 30.6406 36.937 30.9375 36.7495H27.5313L27.1563
    34.0146H32.4922L32.8281 35.3586C33.0391 35.1789 33.25 35.0069 33.4531 34.8194L31.8438
    28.6854C31.7422 28.4275 31.5078 28.1853 31.2969 28.1853ZM12.9766 33.5223H7.71094L8.28906
    31.1312H13.3125L12.9766 33.5223ZM13.3906 30.6858H8.39844L8.94531 28.5291H13.6953L13.3906
    30.6858ZM19.4063 36.7729H13.7656L14.1094 34.038H19.4453L19.4063 36.7729ZM19.4375
    33.5223H14.1406L14.4453 31.1312H19.4688L19.4375 33.5223ZM19.4688 30.6858H14.4766L14.75
    28.5291H19.5L19.4688 30.6858ZM25.3203 28.5291L25.5625 30.6858H20.5703L20.5391
    28.5291H25.3203ZM25.5938 31.1312L25.8984 33.5223H20.6016L20.5703 31.1312H25.5938ZM20.6328
    36.7729L20.6016 34.038H25.9688L26.2734 36.7729H20.6328ZM26.3438 28.5291H31.0938L31.6406
    30.6858H26.6484L26.3438 28.5291ZM27.0625 33.5223L26.7188 31.1312H31.7422L32.3594
    33.5223H27.0625Z"
      fill="white"
    />
    <path d="M8.39844 30.6857H13.3906L13.6953 28.5291H8.94531L8.39844 30.6857Z" fill="#9DD4D7" />
    <path d="M14.4844 30.6857H19.4688L19.5078 28.5291H14.7578L14.4844 30.6857Z" fill="#9DD4D7" />
    <path d="M25.5547 30.6857L25.3203 28.5291H20.5312L20.5625 30.6857H25.5547Z" fill="#9DD4D7" />
    <path d="M31.0937 28.5291H26.3438L26.6484 30.6857H31.6406L31.0937 28.5291Z" fill="#9DD4D7" />
    <path d="M7.71094 33.5222H12.9766L13.3203 31.1311H8.29688L7.71094 33.5222Z" fill="#9DD4D7" />
    <path d="M14.1406 33.5222H19.4375L19.4688 31.1311H14.4453L14.1406 33.5222Z" fill="#9DD4D7" />
    <path d="M25.8984 33.5222L25.5937 31.1311H20.5625L20.6016 33.5222H25.8984Z" fill="#9DD4D7" />
    <path d="M26.7188 31.1311L27.0625 33.5222H32.3594L31.7422 31.1311H26.7188Z" fill="#9DD4D7" />
    <path
      d="M12.9062 34.0378H7.57813L7.23438 35.4053C7.82812 35.8976 8.44531 36.3508 9.08594
    36.7728H12.5312L12.9062 34.0378Z"
      fill="#9DD4D7"
    />
    <path d="M13.7656 36.7728H19.4063L19.4375 34.0378H14.1016L13.7656 36.7728Z" fill="#9DD4D7" />
    <path d="M20.6016 34.0378L20.6328 36.7728H26.2734L25.9687 34.0378H20.6016Z" fill="#9DD4D7" />
    <path
      d="M27.1328 34.0378L27.5078 36.7728H30.9141C31.5703 36.343 32.2031 35.882 32.7969
    35.3819L32.4609 34.0378H27.1328Z"
      fill="#9DD4D7"
    />
    <path
      d="M9.97656 37.3198C10.7188 37.7496 11.5 38.1403 12.2969 38.4763L12.4609 37.3198H9.97656Z"
      fill="#9DD4D7"
    />
    <path
      d="M13.6953 37.3198L13.5156 38.9373C15.3594 39.5703 17.3281 39.9375 19.375 40L19.4062
    37.3198H13.6953Z"
      fill="#9DD4D7"
    />
    <path
      d="M20.6328 37.3198L20.6641 40C22.7109 39.9297 24.6875 39.5546 26.5391 38.9217L26.3438
    37.3276H20.6328V37.3198Z"
      fill="#9DD4D7"
    />
    <path
      d="M27.7344 38.4529C28.5234 38.1247 29.2891 37.7418 30.0234 37.312H27.5781L27.7344
    38.4529Z"
      fill="#9DD4D7"
    />
    <path
      d="M13.8906 14.2058L13.0234 16.175L15.1016 16.722L15.1719 18.863L17.2812 18.4567L18.2812
    20.3555L20 19.074L21.7188 20.3555L22.7187 18.4567L24.8281 18.863L24.8984 16.722L26.9766
    16.175L26.1094 14.2137L27.7344 12.815L26.1016 11.4162L26.9688 9.45492L24.8906 8.90794L24.8203
    6.76689L22.7109 7.17322L21.7188 5.2666L20 6.5481L18.2812 5.2666L17.2812 7.16541L15.1719
    6.75908L15.1016 8.90012L13.0234 9.44711L13.8906 11.4084L12.2656 12.815L13.8906 14.2058ZM20
    7.65769C22.8516 7.65769 25.1562 9.97065 25.1562 12.815C25.1562 15.6671 22.8438 17.9722 20
    17.9722C17.1562 17.9722 14.8438 15.6593 14.8438 12.815C14.8438 9.96283 17.1484 7.65769 20
    7.65769Z"
      fill="#FFE600"
    />
    <path
      d="M23.6462 16.4616C25.6598 14.4476 25.6598 11.1823 23.6462 9.16824C21.6326 7.15423
    18.3679 7.15423 16.3542 9.16824C14.3406 11.1823 14.3406 14.4476 16.3542 16.4616C18.3679 18.4757
    21.6326 18.4757 23.6462 16.4616Z"
      fill="#F8A805"
    />
    <defs>
      <linearGradient
        id="paint_linear_class_ii_srec"
        x1="20"
        y1="0"
        x2="20"
        y2="34.8349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CDD7" />
        <stop offset="1" stopColor="#116C70" />
      </linearGradient>
    </defs>
  </svg>
);

ClassIISREC.defaultProps = {
  width: '40px',
  height: '40px',
};

ClassIISREC.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ClassIISREC;
