import { Children, cloneElement, FC } from 'react';
import { Flex, Box } from 'theme-ui';

export type HeaderProps = { children?: any };

export type HeaderType = FC<HeaderProps>;

export const Header: HeaderType = ({ children }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
    <Box
      sx={{
        variant: 'layouts.headers',
        display: 'flex',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        {Children.map(children, (child) => !!child && cloneElement(child))}
      </Flex>
    </Box>
  </Box>
);
