import { memo } from 'react';

import { useHeaderDropdown } from './use-header-dropdown';
import { HeaderDropdownView } from './header-dropdown.view';
import { HeaderDropdownProps } from './header-dropdown.types';

const HeaderDropdownBase: React.FC<HeaderDropdownProps> = (props) => {
  const logicProps = useHeaderDropdown(props);

  return <HeaderDropdownView {...logicProps} {...props} />;
};

export const HeaderDropdown = memo(HeaderDropdownBase);
