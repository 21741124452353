import { ThemeStyles, ThemeUIStyleObject } from 'theme-ui';

const heading: ThemeUIStyleObject = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

export const headingStyles: Pick<ThemeStyles, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'> = [
  1, 2, 3, 4, 5, 6,
].reduce(
  (acc, num) =>
    Object.assign(acc, {
      [`h${num}`]: {
        ...heading,
        fontSize: 6 - num,
      },
    }),
  {}
);

export const subheading = {
  fontFamily: 'Inter',
  fontWeight: 'light',
  fontSize: 4,
  color: 'textDarker',
};
