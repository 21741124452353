import { FC } from 'react';
import { IconProps, IconWrapper } from './common';

export const FarNext: FC<IconProps> = (props) => (
  <IconWrapper
    icon={() => (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.3 15.0333L0.433333 14.1667L6.6 8L0.433333 1.83333L1.3 0.933332L8.33333 8L1.3 15.0333ZM14.1 15.3333V0.666666H15.3333V15.3333H14.1Z"
          fill="#27D9E5"
        />
      </svg>
    )}
    {...props}
    long
  />
);
