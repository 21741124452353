import { FC } from 'react';

import { Badge as ThemeBadge, BadgeProps as ThemeBadgeProps, Box } from 'theme-ui';

export type BadgeProps = {
  count?: number;
  countOverflow?: number;
} & ThemeBadgeProps;

export const Badge: FC<BadgeProps> = ({ children, count = 0, countOverflow, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      display: 'inline-block',
    }}
  >
    {count > 0 && (
      <ThemeBadge {...props}>
        {countOverflow && count > countOverflow ? `${countOverflow}+` : count || 0}
      </ThemeBadge>
    )}
    {children}
  </Box>
);
