import { Box } from 'theme-ui';

import { ResponsiveContainerViewProps } from './responsive-container.types';

export const ResponsiveContainerView: React.FC<ResponsiveContainerViewProps> = ({ mobile, desktop }) => {
  return (
    <>
      <Box sx={{ display: ['none', 'flex'] }}>{desktop}</Box>
      <Box sx={{ display: ['flex', 'none'] }}>{mobile}</Box>
    </>
  );
};
