import { memo } from 'react';
import { WhenProps } from './when.types';

const WhenBase: React.FC<WhenProps> = ({ condition, children }) => {
  if (!condition) return null;

  return children;
};

export const When = memo(WhenBase);
