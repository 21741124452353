import { Theme } from 'theme-ui';

export const badges: Theme['badges'] = {
  primary: {
    bg: 'primary',
    borderRadius: 5,
    fontWeight: 'light',
    fontSize: 0,
    position: 'absolute',
    right: '-6px',
    top: '-6px',
    px: '6px',
  },
};
