import { FC, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Input, Label, Text } from 'theme-ui';

import { fitToRangeInclusive } from '../../lib/helpers';
import { Button, ButtonGroup } from '../Button';
import { Select } from '../Select';

interface ManualPaginationProps {
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  onChange: (props: { pageSize: number; pageIndex: number }) => void;
}

const pageSizeOptions = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const ManualPagination: FC<ManualPaginationProps> = ({ onChange, pageSize, pageIndex, pageCount }) => {
  const { t } = useTranslation();

  const selectedPageSize = useMemo(
    () => pageSizeOptions.find(({ value: val }: any) => val === pageSize),
    [pageSize]
  );

  const selectOnChange = useCallback(
    ({ value }: any) => {
      const pageSize = Number(value);
      onChange({
        pageSize,
        pageIndex: 0,
      });
    },
    [onChange]
  );

  const inputOnChange = useCallback(
    (e) => {
      const val =
        e.target.value !== ''
          ? fitToRangeInclusive(Number(e.target.value), Number(e.target.min), Number(e.target.max))
          : '';
      onChange({
        pageSize,
        pageIndex: val ? val - 1 : 0,
      });
    },
    [onChange, pageSize]
  );

  const { canNextPage, canPreviousPage } = useMemo(
    () => ({
      canPreviousPage: pageIndex > 0,
      canNextPage: pageIndex < pageCount - 1,
    }),
    [pageIndex, pageCount]
  );

  const { goToFirst, goToLast, goToPrevious, goToNext } = useMemo(() => {
    const goToFirstHandler = () => {
      onChange({
        pageSize,
        pageIndex: 0,
      });
    };

    const goToLastHandler = () => {
      onChange({
        pageSize,
        pageIndex: pageCount - 1,
      });
    };

    const goToPreviousHandler = () => {
      onChange({
        pageSize,
        pageIndex: pageIndex - 1,
      });
    };

    const goToNextHandler = () => {
      onChange({
        pageSize,
        pageIndex: pageIndex + 1,
      });
    };

    return {
      goToFirst: goToFirstHandler,
      goToLast: goToLastHandler,
      goToPrevious: goToPreviousHandler,
      goToNext: goToNextHandler,
    };
  }, [onChange, pageCount, pageIndex, pageSize]);

  if (pageCount === 0 || Number.isNaN(pageCount) || pageIndex < 0 || pageSize < 1) return null;

  return (
    <Flex
      sx={{
        flexDirection: ['column', null, null, 'row'],
        justifyContent: ['flex-start', null, null, 'center'],
        alignItems: 'center',
        px: 1,
        my: 2,
      }}
    >
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', rowGap: 1 }}>
        <ButtonGroup sx={{ display: 'flex', gap: 2, alignItems: 'center', '& > button': { margin: 0 } }}>
          <Button sx={{ minWidth: '36px' }} onClick={goToFirst} disabled={!canPreviousPage}>
            {'<<'}
          </Button>
          <Button sx={{ minWidth: '36px' }} onClick={goToPrevious} disabled={!canPreviousPage}>
            {'<'}
          </Button>
          <Button sx={{ minWidth: '36px' }} onClick={goToNext} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button sx={{ minWidth: '36px' }} onClick={goToLast} disabled={!canNextPage}>
            {'>>'}
          </Button>
        </ButtonGroup>
        <Text mx={2}>
          <Trans t={t} i18nKey="pagination.pageText">
            Page&nbsp;
            <strong>
              {{ pageStart: pageIndex + 1 }} of {{ pageEnd: pageCount || pageIndex + 1 }}
            </strong>
          </Trans>
        </Text>
      </Flex>
      <Text mx={1} sx={{ display: ['none', null, null, 'block'] }}>
        |
      </Text>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mt: [2, null, null, 0],
        }}
      >
        <Label
          sx={{ display: 'inline-block', width: 'auto', mx: 1, '& > input': { height: ['36px', '40px'] } }}
        >
          {t('goToPage', 'Go to page:')}
          <Input
            type="number"
            min={1}
            max={pageCount}
            defaultValue={1}
            value={pageIndex + 1}
            onChange={inputOnChange}
            sx={{
              width: ['40px', '64px'],
              minWidth: ['40px', '64px'],
              py: 0,
              px: 2,
              display: 'inline-block',
              ml: 2,
              appearance: 'textfield',
              minHeight: ['36px', '40px'],
            }}
          />
        </Label>
        <Box sx={{ display: 'inline-block', mx: 1, zIndex: 0 }}>
          <Select
            inputStyles={{
              width: ['72px', '96px'],
              minWidth: ['72px', '96px'],
              height: ['36px', '40px'],
              minHeight: ['36px', '40px'],
            }}
            options={pageSizeOptions}
            value={selectedPageSize}
            onChange={selectOnChange}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
