import { Flex, Box, Divider } from 'theme-ui';
import { SparkzSVG } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { Modal, Tooltip, InfoCircleOutlined, LoadingOverlay } from '..';
import NumberText from '../NumberText';
import { FadeInOutAnimator } from '../animation/WidgetAnimator';
import { formatFullDaySelectedDatetime } from '../../lib/datetime-helpers';
import { useAppSelector } from '../../hooks/use-app-selector';

interface BlockchainTransactionData {
  transactionHash: string;
  buyerAddress: string;
  sellerAddress: string;
  amount: number;
  timestamp: number;
}

interface BlockchainTransactionProps {
  data?: BlockchainTransactionData;
  isLoading?: boolean;
  symbol?: string;
  sparkzConversionRate?: number;
  visible?: boolean;
  onCloseModal: () => void;
  onAfterClose: () => void;
}

const BlockchainTransaction: React.FC<BlockchainTransactionProps> = ({
  data = {
    transactionHash: '',
    buyerAddress: '',
    sellerAddress: '',
    amount: 0,
    timestamp: 0,
  },
  symbol = '$',
  sparkzConversionRate = 100,
  visible = false,
  isLoading = false,
  onCloseModal,
  onAfterClose,
}) => {
  const { t } = useTranslation();
  const blockchainTransfer = useAppSelector(({ blockchain }) => blockchain.transfer, shallowEqual);

  return (
    <Modal
      title={t('Blockchain details')}
      visible={visible}
      afterClose={onAfterClose}
      onCancel={onCloseModal}
      footer={null}
    >
      {isLoading ? (
        <FadeInOutAnimator isEnter>
          <LoadingOverlay active text={t('Loading blockchain details...')} color="text" />
        </FadeInOutAnimator>
      ) : (
        <FadeInOutAnimator isEnter>
          {data.transactionHash && (
            <Flex sx={{ flexDirection: 'column' }}>
              <Box sx={{ fontWeight: 'body' }}>{t('Blockchain transaction ID')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2, lineBreak: 'anywhere' }}>
                {data.transactionHash || t('Not provided')}
              </Box>
              <Box sx={{ fontWeight: 'body' }}>{t('From')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2, lineBreak: 'anywhere' }}>
                {data.buyerAddress || t('Not provided')}
              </Box>
              <Box sx={{ fontWeight: 'body' }}>{t('To')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2, lineBreak: 'anywhere' }}>
                {data.sellerAddress || t('Not provided')}
              </Box>

              <Flex sx={{ mb: 2 }}>
                <Box sx={{ fontWeight: 'body', mr: 2 }}>{t('Sparkz amount')}</Box>
                <Tooltip
                  id="blockchain-sparkz-tt"
                  title={t('tooltip.blockchain-sparkz', {
                    symbol,
                    rate: 1 / sparkzConversionRate,
                  })}
                >
                  <InfoCircleOutlined tabIndex={0} />
                </Tooltip>
              </Flex>

              <Flex sx={{ fontWeight: 'light', mb: 2, '& > *': { mr: 2 } }}>
                <SparkzSVG />
                {data.amount !== null ? (
                  <NumberText value={data.amount ? data.amount : ''} />
                ) : (
                  <span>-</span>
                )}
              </Flex>
              <Box sx={{ fontWeight: 'body' }}>{t('Datetime')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2 }}>
                {data.timestamp && data.timestamp > 0
                  ? formatFullDaySelectedDatetime(data.timestamp * 1000)
                  : ''}
              </Box>
            </Flex>
          )}

          {data.transactionHash && blockchainTransfer.id && <Divider />}

          {blockchainTransfer.id && (
            <Flex sx={{ flexDirection: 'column' }}>
              <Box sx={{ fontWeight: 'body' }}>{t('Blockchain transfer ID')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2, lineBreak: 'anywhere' }}>
                {blockchainTransfer.id || t('Not provided')}
              </Box>
              <Box sx={{ fontWeight: 'body' }}>{t('From')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2, lineBreak: 'anywhere' }}>
                {blockchainTransfer.payerAccountId || t('Not provided')}
              </Box>
              <Box sx={{ fontWeight: 'body' }}>{t('To')}</Box>
              <Box sx={{ fontWeight: 'light', mb: 2, lineBreak: 'anywhere' }}>
                {blockchainTransfer.destinationAccountId || t('Not provided')}
              </Box>
              <Box sx={{ fontWeight: 'body' }}>{t('Amount')}</Box>
              <Flex sx={{ fontWeight: 'light', mb: 2, '& > *': { mr: 2 } }}>
                <SparkzSVG />
                {blockchainTransfer.amount !== null ? (
                  <NumberText value={blockchainTransfer.amount || ''} />
                ) : (
                  <span>-</span>
                )}
              </Flex>
            </Flex>
          )}
        </FadeInOutAnimator>
      )}
    </Modal>
  );
};

export default BlockchainTransaction;
