import { memo } from 'react';

import { TradingSummaryProps } from './trading-summary.types';
import { TradingSummaryView } from './trading-summary.view';
import { useTradingSummary } from './use-trading-summary';

const TradingSummaryBase: React.FC<TradingSummaryProps> = ({ transactionSummaries, ...rest }) => {
  const logicProps = useTradingSummary({ transactionSummaries });

  return <TradingSummaryView {...rest} {...logicProps} />;
};

export const TradingSummary = memo(TradingSummaryBase);
