/** @jsxImportSource theme-ui */
import { FC, ReactNode } from 'react';
import { variant as styledSystemVariant } from 'styled-system';
import styled from '@emotion/styled';

import { ThemeUIStyleObject, css } from 'theme-ui';

export type CardProps = {
  children?: ReactNode;
  variant?: string;
  sx?: ThemeUIStyleObject;
};

const StyledCard = styled.div<{ variant?: string; sx: any }>`
  ${styledSystemVariant({
    scale: 'card',
    variants: {
      primary: null,
      compact: null,
    },
  })}
  ${({ sx }) => sx && css(sx)}
`;

export const Card: FC<CardProps> = ({ children, variant = 'primary', sx }) => (
  <StyledCard variant={variant} sx={sx}>
    {children}
  </StyledCard>
);
