import { createPortal } from 'react-dom';

export const Portal = ({
  children,
  targetElementId,
}: {
  children: React.ReactNode;
  targetElementId: string;
}) => {
  const targetElement = document.getElementById(targetElementId);

  if (!targetElement) {
    return null;
  }

  return createPortal(children, targetElement);
};
