import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

export const defaultIconSize = 4;

const commonStyles: ThemeUIStyleObject = {
  width: defaultIconSize,
  height: defaultIconSize,
  color: 'primary',
  position: 'relative',
  overflow: 'hidden',
  display: 'inline-block',
};

const svgStyles: ThemeUIStyleObject = {
  position: 'absolute',
  top: '25%',
  bottom: '25%',
  left: '25%',
  right: '25%',
  fill: 'primary',
  path: {
    fill: 'primary',
  },
  width: '50%',
};

export const icons = CustomThemeProperty('icons', {
  ...commonStyles,
  outlined: {
    ...commonStyles,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '50%',
    borderColor: 'primary',
    svg: {
      ...svgStyles,
    },
  },
  svg: {
    ...svgStyles,
  },
});
