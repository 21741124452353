import { memo } from 'react';
import { Box } from 'theme-ui';

import { convertAndFormatUnitValue } from '../../../lib/unit';
import { formatAmount, formatCurrency } from '../../../lib/helpers';
import NumberText from '../../NumberText';
import { WattHour, Weight } from '../../../../../types';
import { isEmpty } from '../../../utils/value';
import {
  CurrencyItemProps,
  EnergyItemProps,
  MeasureItemProps,
  PercentItemProps,
  PlainTextItemProps,
  TextItemProps,
  WeightItemProps,
} from './items.types';

const MeasureItem: React.FC<MeasureItemProps> = memo(({ value = 0, numberType, suffix, unit, ...props }) => {
  if (numberType === 'meta') {
    const { formattedNewValue, newUnit } = convertAndFormatUnitValue(value, unit);

    return (
      <div>
        <NumberText value={formattedNewValue} suffix={newUnit} {...props} />
      </div>
    );
  }

  return (
    <div>
      <NumberText value={value} {...props} />
      <Box sx={{ variant: 'statistic.suffix' }}>{suffix}</Box>
    </div>
  );
});

export const EnergyItem: React.FC<EnergyItemProps> = memo((props) => (
  <MeasureItem value={props.value} unit={WattHour.kWh} {...props} />
));

export const WeightItem: React.FC<WeightItemProps> = memo((props) => (
  <MeasureItem value={props.value} unit={Weight.kg} {...props} />
));

export const PercentItem: React.FC<PercentItemProps> = memo(({ value, ...props }) => {
  return (
    <div>
      <NumberText value={formatAmount(value)} {...props} />
      <Box sx={{ variant: 'statistic.suffix' }}>%</Box>
    </div>
  );
});

export const PlainTextItem: React.FC<PlainTextItemProps> = memo(({ value }) => <div>{value}</div>);

export const TextItem: React.FC<TextItemProps> = memo(({ value, ...props }) => {
  if (isEmpty(value)) return null;

  return <NumberText value={value} {...props} />;
});

export const CurrencyItem: React.FC<Partial<CurrencyItemProps>> = memo(({ value, symbol }) => {
  return <NumberText value={formatCurrency(value, symbol as string)} prefix={symbol as string} />;
});
