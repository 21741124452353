import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const sharedItemStyles: ThemeUIStyleObject = {
  fontWeight: 'light',
  fontSize: 1,
  listStyle: 'none',
  py: 3,
  px: 3,
  cursor: 'pointer',
  transition: 'background 0.3s ease',
  color: 'primaryDarker',
};

export const contextMenu = CustomThemeProperty('contextMenu', {
  display: 'flex',
  flexDirection: 'column',
  p: 0,
  m: 0,
  borderRadius: 4,
  bg: 'white',
  overflow: 'hidden',
  items: {
    ...sharedItemStyles,
    '&:hover': {
      bg: 'primaryLightest',
    },
  },
  groups: {
    ...sharedItemStyles,
    '&:hover': {
      bg: 'primaryLightest',
    },
  },
});
