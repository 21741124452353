/** @jsxImportSource theme-ui */
import { Themed, ThemeUIStyleObject, Box } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import React from 'react';

export type MenuItemProps = {
  icon?: any;
  active?: boolean;
  tabIndex?: number;
  to?: string;
  onClick?: () => void;
  iconOnly?: boolean;
  isSubList?: boolean;
  sx?: ThemeUIStyleObject;
};
export type MenuItemType = React.FC<MenuItemProps>;

export type MenuProps = {
  children?: React.ReactElement<MenuItemProps> | React.ReactElement<MenuItemProps>[];
};

export type MenuType = React.FC<MenuProps> & {
  Item: MenuItemType;
  Group: MenuItemType;
};
export type MenuIconProps = {
  active?: boolean;
  isSubList?: boolean;
  iconOnly?: boolean;
  children?: React.ReactNode;
};
export const Menu: MenuType = ({ children }) => <Themed.ul sx={{ variant: 'menu' }}>{children}</Themed.ul>;
const MenuIcon: React.FC<MenuIconProps> = ({ active, iconOnly, children, isSubList }) => {
  return (
    <Box
      variant="menu.items.icon"
      sx={{
        ...(active
          ? {
              bg: 'secondary',
              'div svg path': {
                color: 'black',
                fill: 'black',
              },
            }
          : {}),
        ...(iconOnly ? { mx: 0 } : {}),
        ...(isSubList ? { width: 4, height: 4 } : {}),
      }}
    >
      {children}
    </Box>
  );
};

const MenuItem: MenuItemType = ({
  children,
  icon,
  to,
  tabIndex = 0,
  active = false,
  onClick,
  iconOnly,
  isSubList,
  sx,
}) => {
  const history = useHistory();
  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };

  return (
    <Themed.li
      sx={{
        variant: 'menu.items',
        ...(active
          ? {
              borderRight: [0, null, iconOnly ? 0 : 2],
              borderRightStyle: ['none', null, 'solid'],
              borderColor: ['none', null, 'secondary'],
              fontWeight: 'bold',
              color: 'primary',
            }
          : {}),
        ...(isSubList && !iconOnly ? { ml: 3 } : {}),
        ...(iconOnly ? { px: 0, justifyContent: 'center' } : {}),
        ...sx,
      }}
      tabIndex={tabIndex}
      onClick={handleOnClick}
      onKeyUp={(e) => {
        const key = e.code;
        return key === 'Enter' ? handleOnClick() : e;
      }}
    >
      {icon && (
        <MenuIcon active={active} isSubList={isSubList} iconOnly={iconOnly}>
          {icon}
        </MenuIcon>
      )}
      {!iconOnly && children}
    </Themed.li>
  );
};

const MenuGroup: MenuItemType = ({ children, active }) => (
  <Themed.li
    sx={{
      variant: 'menu.groups',
      ...(active ? { bg: 'secondaryDark' } : {}),
    }}
  >
    {children}
  </Themed.li>
);

Menu.Item = MenuItem;
Menu.Group = MenuGroup;
