import { FC } from 'react';
import { VoltQuestionMark } from '@power-ledger/assets';
import { createIconStyles, IconProps, IconWrapper } from './common';

export const QuestionCircleOutlined: FC<IconProps> = ({ sx, ...props }) => (
  <IconWrapper
    // @ts-ignore
    icon={VoltQuestionMark}
    sx={createIconStyles({ variant: 'outlined', sx, ...props }, true)}
    {...props}
  />
);
