import { DateTime } from 'luxon';

import { formatNiceDatetime } from '../../lib/datetime-helpers';
import { capitalise, formatAmount } from '../../lib/helpers';
import { registerUserEvent } from '../../lib/auth-helpers';
import { safelyParseNumber } from '../../utils/number';
import { checkCommonUpdates } from './controls';

// constantly checks if parent component needs to be re-rendered based on current state and props
export const checkUpdates = (currentProps, nextProps, currentState, nextState) => {
  if (checkCommonUpdates(currentProps, nextProps, currentState, nextState)) {
    return true;
  }

  return (
    JSON.stringify(currentProps.dynamicRates) !== JSON.stringify(nextProps.dynamicRates) ||
    JSON.stringify(currentProps.meterRates) !== JSON.stringify(nextProps.meterRates) ||
    currentState.exportState !== nextState.exportState
  );
};

export const registerPricingEvent = (meterName, startDate, endDate) => {
  const action = `pricing-${meterName} (${startDate} to ${endDate})`;
  registerUserEvent(action, 'data');
};

// rounds the given rate to the nearest decimal places
export const roundRate = (val) => (val ? safelyParseNumber(Math.round(val * 100) / 100) : 0);

export const convertCurrencyToSubCurrency = (currencyValue, conversionRate = 100) => {
  const convertedValue = currencyValue * conversionRate;

  return safelyParseNumber(formatAmount(convertedValue, 2));
};

// converts the rate from wh to kWh based on the Sparkz conversion rate
export const convertSparkzWhToSparkzKWh = (val) => {
  let amount = 0;

  if (val) {
    // sparkzRate usually === currency_conversion_$_to_c
    const unitRate = 1000; // kWh = 1000Wh * sparkzRate / currency_conversion_$_to_c
    amount = safelyParseNumber((val * unitRate).toFixed(8));
  }

  return amount;
};

export const convertSparkzKWhToSparkzWh = (val) => {
  let amount = 0;

  if (val) {
    const sparkzDecimals = (val.toString().split('.')[1] || '').length;
    amount = safelyParseNumber((val / 1000).toFixed(8 + sparkzDecimals));
  }

  return amount;
};

// conveniently sorts the rate periods to set 'ANY' sub-category period ast the last and
// prioritise weekday rate periods
export const sortRatePeriods = (ratePeriods = []) =>
  ratePeriods.sort(
    (a, b) =>
      a.subCategory.indexOf('ANY') - b.subCategory.indexOf('ANY') ||
      b.rateUid.indexOf('weekday') - a.rateUid.indexOf('weekday')
  );

// conveniently sorts the rate-periods by Peak first
export const sortPeakPeriods = (ratePeriods, field) =>
  ratePeriods.sort((a, b) => {
    if (a[field] && a[field].toLowerCase() === 'peak') {
      return -1;
    }
    if (b[field] && b[field].toLowerCase() === 'peak') {
      return 1;
    }
    if (a[field] && b[field]) {
      return a[field].localeCompare(b[field]);
    }

    return -1;
  });

// conveniently sorts the sub-category periods by meter followed by peak buy then buy
export const sortSubCategoryPeriods = (periods) =>
  periods.sort(
    (a, b) =>
      b.key.indexOf('meter') - a.key.indexOf('meter') ||
      b.key.indexOf('peakBuy') - a.key.indexOf('peakBuy') ||
      b.key.indexOf('Buy') - a.key.indexOf('Buy')
  );

// convenient helper to convert the buy/sell rates based on the group Sparkz conversion rate
export const formatRates = (rates, category = undefined) => {
  let formattedRates = rates || [];

  if (category !== undefined) {
    formattedRates = formattedRates.filter((val) => val.category === category);
  }

  formattedRates = formattedRates.map((val) => ({
    ...val,
    buyRate: convertSparkzWhToSparkzKWh(val.buyRate),
    buyRateNetTax: convertSparkzWhToSparkzKWh(val.buyRateNetTax),
    sellRate: convertSparkzWhToSparkzKWh(val.sellRate),
    sellRateNetTax: convertSparkzWhToSparkzKWh(val.sellRateNetTax),
  }));

  formattedRates = sortPeakPeriods(formattedRates, 'subCategory');

  return formattedRates;
};

// re-construct the grid rates hash map into a list with formatted grid tariff name and
// formatted buy/sell rates
export const formatGridRates = (gridRates, selectedPeriod) =>
  gridRates.map((val) => {
    let formattedTariff = val.tariffName;
    const splitNames = val.tariffName.split('tariff-');

    if (splitNames.length > 1) {
      formattedTariff = capitalise(splitNames[splitNames.length - 1]);
      formattedTariff = formattedTariff.replace(/[_-]/g, ' ');
    }

    return {
      tariff: formattedTariff,
      rates: formatRates(val.ratePeriodPrices, selectedPeriod),
    };
  });

// constructs, formats and maps the sub-categories dynamic buy/sell rates for each meter
export const mapMeterRates = (
  rates,
  subPeriods,
  includesBuyerTax = false,
  includesSellerTax = false,
  conversionRate = 100
) =>
  rates.map((val, key) => ({
    key,
    meter: val.meterDisplayName || '-',
    ...val.dynamicRates.reduce((acc, v) => {
      const subPeriodIdx = subPeriods.findIndex((sp) => sp.value === v.subCategory);

      if (subPeriodIdx !== -1) {
        const subPeriod = subPeriods[subPeriodIdx];
        /* eslint-disable no-param-reassign */
        acc[`${subPeriod.dataKey}Buy`] = convertCurrencyToSubCurrency(
          includesBuyerTax ? v.buyRateNetTax : v.buyRate,
          conversionRate
        );
        acc[`${subPeriod.dataKey}Sell`] = convertCurrencyToSubCurrency(
          includesSellerTax ? v.sellRateNetTax : v.sellRate,
          conversionRate
        );
        /* eslint-enable no-param-reassign */
      }

      return acc;
    }, {}),
  }));

// formats each interval's date and rates to be displayed on chart
export const formatDailyRates = (dailyRates, timeZone, conversionRate = 100) => {
  // TODO: What is the timezone of dailyRates dates?? Assuming UTC for now
  let startDate = DateTime.utc().minus({ days: 30 });
  const formattedDailyRates = [];

  const isDateEqualToStartDate = (v) => v.date === startDate.toFormat('yyyy-MM-dd');

  for (let i = 0; i < 30; i++) {
    const formattedStartDate = startDate.setZone(timeZone).toFormat('dd/M');
    // eslint-disable-next-line no-loop-func
    const rateIdx = dailyRates.findIndex(isDateEqualToStartDate);
    const existingRate = rateIdx !== -1 ? dailyRates[rateIdx] : null;

    formattedDailyRates.push({
      date: formattedStartDate,
      niceDate: formatNiceDatetime(startDate, timeZone),
      avgRateNetTax: existingRate
        ? convertCurrencyToSubCurrency(existingRate.avgRateNetTax, conversionRate)
        : null,
      maxRateNetTax: existingRate
        ? convertCurrencyToSubCurrency(existingRate.maxRateNetTax, conversionRate)
        : null,
      minRateNetTax: existingRate
        ? convertCurrencyToSubCurrency(existingRate.minRateNetTax, conversionRate)
        : null,
    });

    startDate = startDate.plus({ days: 1 });
  }

  return formattedDailyRates;
};

// formats grid rates and switch the data to either the regular rates or tiered.
export const sortAndCategoriseGridRates = (gridRates) => {
  if (!gridRates || gridRates.length === 0) {
    return {
      isTiered: false,
      gridRates: [],
    };
  }

  // determine if there is a 'tiered' hierarchy within rates
  let firstUsage;
  const isTiered = gridRates.some(({ fromUsage }, index) => {
    if (index === 0) {
      firstUsage = fromUsage;
    }
    return firstUsage !== fromUsage;
  });

  // switch return either ordinary grid rates or tiered grid rates.
  if (isTiered) {
    const sortedRates = gridRates.sort((x, y) => x.fromUsage - y.fromUsage);

    let tier = { rates: [] };

    const tiers = [];
    tiers.push(tier);

    sortedRates.forEach((val, index) => {
      const gridRate = val;

      if (index !== 0 && gridRate.fromUsage !== tier.fromUsage) {
        tier = { rates: [] };
        tiers.push(tier);
      }

      tier.fromUsage = gridRate.fromUsage;
      tier.toUsage = gridRate.toUsage;
      tier.rates.push(gridRate);
    });

    return {
      isTiered,
      tiers,
    };
  }

  return {
    isTiered,
    gridRates,
  };
};
