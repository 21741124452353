import { useDispatch } from 'react-redux';
import { DateTime, DurationLike } from 'luxon';

import { useTimeFrameDateData } from '../components/charts';
import { updatePeriodicalDates } from '../states/slices/controls';
import {
  parseDate,
  parseDateWithoutTimeZone,
  parseDateWithTimeZone,
  UndeterminedDate,
} from '../lib/datetime-helpers';

// Manipulate with the date by adding duration
const getNextPeriod = (date: string, period: DurationLike) =>
  DateTime.fromISO(date, { setZone: true }).plus(period);

// Manipulate with the date by removing duration
const getPreviousPeriod = (date: string, period: DurationLike) =>
  DateTime.fromISO(date, { setZone: true }).minus(period);

// Utils for plus date manipulations
const getNextDay = (date: string) => getNextPeriod(date, { day: 1 }).toJSDate();
const getNextWeek = (date: string) => getNextPeriod(date, { week: 1 }).toJSDate();
const getNextFourWeeks = (date: string, timeZone: string) =>
  parseDateWithTimeZone(date, timeZone).startOf('day').plus({ days: 27 });
const getNextMonth = (date: string, timeZone: string, options?: { endOfMonth: boolean }) => {
  // endOfMonth modifier makes sure the end date is properly set
  if (options?.endOfMonth) {
    return getNextPeriod(date, { month: 1 })
      .setZone(timeZone, { keepLocalTime: true })
      .endOf('month')
      .toJSDate();
  }

  return getNextPeriod(date, { month: 1 }).setZone(timeZone, { keepLocalTime: true }).toJSDate();
};

// Utils for minus date manipulations
const getPreviousDay = (date: string) => getPreviousPeriod(date, { day: 1 }).toJSDate();
const getPreviousWeeks = (date: string) => getPreviousPeriod(date, { week: 1 }).toJSDate();
const getPreviousFourWeek = (date: string, timeZone: string) =>
  parseDateWithTimeZone(date, timeZone).startOf('day').minus({ days: 27 });
const getPreviousMonth = (date: string, timeZone: string, options?: { endOfMonth: boolean }) => {
  // endOfMonth modifier makes sure the end date is properly set
  if (options?.endOfMonth) {
    return getPreviousPeriod(date, { month: 1 })
      .setZone(timeZone, { keepLocalTime: true })
      .endOf('month')
      .toJSDate();
  }

  return getPreviousPeriod(date, { month: 1 }).setZone(timeZone, { keepLocalTime: true }).toJSDate();
};

/**
 * useTimeFramePeriodOffset
 * Provide previous/next handlers that update startDate and endDate on controls slice.
 */
export const useTimeFramePeriodOffset = () => {
  const dispatch = useDispatch();
  const { startDate, endDate, timeZone, period } = useTimeFrameDateData();
  const onDatesUpdate = ({ startDate, endDate, period }: any) => {
    dispatch(
      // Store the selected start/end date-times in the application hosts' timezone
      updatePeriodicalDates(
        startDate.setZone(timeZone).toISO(),
        // @ts-ignore
        endDate.setZone(timeZone).toISO(),
        period
      )
    );
  };

  const onNext = () => {
    let start: UndeterminedDate = new Date(startDate);
    let end: UndeterminedDate = new Date(endDate);

    if (period === 'day') {
      start = getNextDay(startDate);
      end = getNextDay(endDate);
    }

    if (period === 'week') {
      start = getNextWeek(startDate);
      end = getNextWeek(endDate);
    }

    if (period === 'prevFourWeek') {
      start = getNextFourWeeks(startDate, timeZone);
      end = getNextFourWeeks(endDate, timeZone);
    }

    if (period === 'month') {
      start = getNextMonth(startDate, timeZone);
      end = getNextMonth(endDate, timeZone, { endOfMonth: true });
    }

    if (period === 'custom') {
      const { days } = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'days').toObject();

      const daysFinal = days ? Math.ceil(days) : 0;

      start = getNextPeriod(startDate, { days: daysFinal }).toJSDate();
      end = getNextPeriod(endDate, { days: daysFinal }).toJSDate();
    }

    if (period === 'year') {
      start = DateTime.fromISO(startDate, { setZone: true }).plus({ years: 1 });
      end = DateTime.fromISO(endDate, { setZone: true }).plus({ years: 1 });
    }

    onDatesUpdate({
      startDate: parseDateWithoutTimeZone(start),
      endDate: parseDateWithoutTimeZone(end),
      period,
    });
  };

  const onPrevious = () => {
    let start: UndeterminedDate = new Date(startDate);
    let end: UndeterminedDate = new Date(endDate);

    if (period === 'day') {
      start = getPreviousDay(startDate);
      end = getPreviousDay(endDate);
    }

    if (period === 'week') {
      start = getPreviousWeeks(startDate);
      end = getPreviousWeeks(endDate);
    }

    if (period === 'prevFourWeek') {
      start = getPreviousFourWeek(startDate, timeZone);
      end = getPreviousFourWeek(endDate, timeZone);
    }

    if (period === 'month') {
      start = getPreviousMonth(startDate, timeZone);
      end = getPreviousMonth(endDate, timeZone, { endOfMonth: true });
    }

    if (period === 'custom') {
      const { days } = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), 'days').toObject();

      const daysFinal = days ? Math.ceil(days) : 0;

      start = getPreviousPeriod(startDate, { days: daysFinal }).toJSDate();
      end = getPreviousPeriod(endDate, { days: daysFinal }).toJSDate();
    }

    if (period === 'year') {
      start = DateTime.fromISO(startDate, { setZone: true }).minus({ years: 1 });
      end = DateTime.fromISO(endDate, { setZone: true }).minus({ years: 1 });
    }

    onDatesUpdate({
      startDate: parseDate(start, timeZone),
      endDate: parseDate(end, timeZone),
      period,
    });
  };

  return {
    onPrevious,
    onNext,
  };
};
