/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Heading, Paragraph } from 'theme-ui';
import { NoData, BinSVG } from '@power-ledger/assets';

import { Button, Table, TableLoadingOverlay } from '..';
import NotificationDetails from '../modal/NotificationDetails';
import DeleteNotificationConfirmation from '../modal/DeleteNotificationConfirmation';
import {
  deleteAppNotification,
  getAppNotification,
  updateReadNotification,
} from '../../states/actions/app-notifications';
import { paginationOpts } from '../../lib/pagination';
import { withTranslation } from '../../hocs/with-translation';

const columns = (category, openConfirmModal) => [
  {
    title: '',
    dataIndex: 'id',
    key: 'key',
    render: (_, { subject, preview, readDatetime }) => (
      <div tabIndex={0}>
        <div>
          {category === 'inbox' && !readDatetime && <div />}
          <Heading as="h3">{subject}</Heading>
        </div>
        <Paragraph>{preview}</Paragraph>
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'niceDateTime',
    key: 'notificationDatetime',
    align: 'right',
    className: 'notification-datetime-column',
    render: (niceDateTime, { readDatetime }) =>
      category === 'inbox' && !readDatetime ? <strong>{niceDateTime}</strong> : <span>{niceDateTime}</span>,
  },
  ...(category === 'scheduled'
    ? [
        {
          title: '',
          dataIndex: 'uid',
          key: 'uid',
          align: 'center',
          fixed: 'right',
          className: 'notification-action-column',
          render: (_, record) => (
            <div>
              <Button onClick={() => openConfirmModal(record)}>
                <BinSVG width="12px" height="14px" />
              </Button>
            </div>
          ),
        },
      ]
    : []),
];

class NotificationsTableBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      detailsVisible: false,
      details: {
        uid: null,
        title: '',
        message: '',
        fullDateTime: '',
        readDatetime: true,
      },
      confirmVisible: false,
      isSubmitting: false,
      requestToken: axios.CancelToken.source(),
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    return this.state.requestToken.cancel();
  };

  componentDidUpdate = (prevProps) => {
    const { category, data } = this.props;

    if (prevProps.category !== category || JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ tableData: data });
    }
  };

  openDetailsModal = async (details) => {
    const { category } = this.props;
    const { uid, readDatetime } = details;

    this.state.requestToken.cancel();
    const requestToken = axios.CancelToken.source();

    if (category === 'inbox' && uid && !readDatetime) {
      updateReadNotification(uid, requestToken);
    }

    const resp = await getAppNotification({ uid, category }, requestToken);

    const updatedDetails = { ...details, ...resp };

    this.setState({
      detailsVisible: true,
      details: updatedDetails,
      requestToken,
    });
  };

  closeDetailsModal = () => {
    const { category } = this.props;
    const { tableData, details } = this.state;
    const { uid, readDatetime } = details;

    this.setState({
      detailsVisible: false,
      ...(category === 'inbox' &&
        uid &&
        !readDatetime && {
          tableData: tableData.map((v) => ({
            ...v,
            ...(v.uid === uid && { readDatetime: true }),
          })),
        }),
    });
  };

  openConfirmModal = (details) => this.setState({ confirmVisible: true, details });

  closeConfirmModal = () => {
    this.state.requestToken.cancel();
    this.setState({ confirmVisible: false });
  };

  deleteNotification = async () => {
    const { getNotifications } = this.props;
    const { details } = this.state;
    const { uid } = details;

    this.state.requestToken.cancel();
    const requestToken = axios.CancelToken.source();

    this.setState({ isSubmitting: true, requestToken });

    const isSuccess = await deleteAppNotification({ arrayData: [uid] }, requestToken);

    if (isSuccess) {
      this.setState({ confirmVisible: false, isSubmitting: false });
      await getNotifications();
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { total, offset, limit, isLoading, loadingText, category, onUpdateNotificationsByPageOptions, t } =
      this.props;
    const { detailsVisible, details, confirmVisible, isSubmitting, tableData } = this.state;

    return (
      <>
        <NotificationDetails
          {...this.props}
          details={details}
          visible={detailsVisible}
          closeModal={this.closeDetailsModal}
        />
        <DeleteNotificationConfirmation
          visible={confirmVisible}
          isSubmitting={isSubmitting}
          closeModal={this.closeConfirmModal}
          deleteNotification={this.deleteNotification}
        />
        <Table
          locale={{
            emptyText: <NoData description={isLoading ? '' : t('No notifications found')} />,
          }}
          columns={columns(category, this.openConfirmModal)}
          dataSource={!isLoading && total > 0 ? tableData : []}
          loading={{
            spinning: isLoading,
            indicator: <TableLoadingOverlay active text={loadingText} color="text" />,
          }}
          pagination={paginationOpts(offset, limit, total, onUpdateNotificationsByPageOptions, t)}
          onRow={(notificationItem) => ({
            onClick: (e) => {
              if (!e.target.className || e.target.className.indexOf('delete-notification') === -1) {
                this.openDetailsModal(notificationItem);
              }
            },
            onKeyDown: (e) => {
              const key = e.which || window.e.keyCode;
              return key === 13 ? this.openDetailsModal(notificationItem) : e;
            },
          })}
        />
      </>
    );
  }
}

NotificationsTableBase.defaultProps = {
  data: [],
  total: 0,
  offset: 0,
  limit: 10,
  isLoading: false,
  category: 'inbox',
  loadingText: 'Loading inbox...',
};

NotificationsTableBase.propTypes = {
  data: PropTypes.array,
  total: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  category: PropTypes.string,
  onUpdateNotificationsByPageOptions: PropTypes.func.isRequired,
  getNotifications: PropTypes.any,
  t: PropTypes.any,
};

export const NotificationsTable = withTranslation()(NotificationsTableBase);
