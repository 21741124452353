import { ResponsiveBar } from '@nivo/bar';
import { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getColor } from '@theme-ui/color';
import { Box } from 'theme-ui';

import { ChartNavArrows } from '../chart-nav-arrows';
import { checkIfChartDataIsValid, ChartData, formatAxisLeftValue, determineTimeScale } from '../charts.utils';
import { transactionChartColorScheme } from '../../../styles/colors/index';
import { ChartPlaceholder } from '../chart-placeholder';
import { useDynamicGraphTicks, useTimeFrameDateData } from '../hooks';
import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useTheme } from '../../../hooks/use-theme';
import { useStandardBarChartConfig } from '../../charts/hooks/useStandardBarChartConfig';
import { TradeType, TradeUnit } from '../../../../../types';
import { Choose, When } from '../..';
import { useIsTATA } from '../../../hooks/use-is-tata';
import { useScreenSize } from '../../../hooks/use-screen-size';
import { TransactionsChartTooltip } from './TransactionsChartTooltip';
import { useFormattedTransactionsData } from './useFormattedTransactionsData';
import { LegendLabel } from './legend-label';

interface TransactionsStackChartProps {
  tradeType: TradeType;
  unit: TradeUnit;
  currency: string;
  symbol: string;
  onPreviousTimeFramePeriodOffset: () => void;
  onNextTimeFramePeriodOffset: () => void;
  loading?: boolean;
}

export const TransactionsStackChart = ({
  tradeType,
  unit,
  currency,
  symbol,
  onNextTimeFramePeriodOffset,
  onPreviousTimeFramePeriodOffset,
  loading = false,
}: TransactionsStackChartProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { includesBuyerTax, includesSellerTax, useEUTimeFormat } = useFeatureSet();
  const { chartConfig } = useThemedColorScale();
  const isTATA = useIsTATA();
  const intervals = useSelector(({ transactions }: any) => transactions.intervals, shallowEqual);
  const { ref, chunkSize, width } = useDynamicGraphTicks(intervals, chartConfig.bar.tickWidth);
  const [hiddenIds, setHiddenIds] = useState<string[]>([]);
  const { startDate, endDate, timeZone } = useTimeFrameDateData();
  const { data, keys, types } = useFormattedTransactionsData({
    intervals,
    hiddenIds,
    tradeType,
    unit,
    startDate,
    endDate,
    timeZone,
    chunkSize,
    t,
    includesBuyerTax,
    includesSellerTax,
    isTATA,
  });

  const { isSmallDown } = useScreenSize();

  const { isDayFormat, ...xScaleProps } = useMemo(
    () =>
      determineTimeScale({
        startDate,
        endDate,
        timeZone,
        width,
        numValues: data.length,
        isEUTimeFormat: useEUTimeFormat,
      }),
    [data.length, endDate, startDate, timeZone, width, useEUTimeFormat]
  );

  const { legends, ...commonBarProps } = useStandardBarChartConfig({
    width,
    legendData: keys,
  });

  const colorSchemeWithOverrides = useMemo(
    () =>
      types.map((type: string, index: number) => {
        const overrideColor = transactionChartColorScheme[type];

        return overrideColor
          ? getColor(theme, overrideColor)
          : commonBarProps.colors[index % commonBarProps.colors.length];
      }),
    [theme, commonBarProps.colors, types]
  );

  const chartLegends = useMemo(
    () => [
      ...legends.map(({ legendData, ...legend }: any) => ({
        ...legend,
        data: legendData.map((legendLabel: string, index: number) => {
          return {
            id: legendLabel,
            label: <LegendLabel labelKey={types[index]} label={legendLabel} />,
            color: hiddenIds.includes(legendLabel)
              ? 'rgba(1, 1, 1, .1)'
              : colorSchemeWithOverrides[keys.indexOf(legendLabel)],
          };
        }),
        onClick: (datum: any) => {
          setHiddenIds((state) =>
            state.includes(datum.id) ? state.filter((item) => item !== datum.id) : [...state, datum.id]
          );
        },
      })),
    ],
    [colorSchemeWithOverrides, hiddenIds, keys, legends, types]
  );

  const doesChartHaveValidData = useMemo(
    () => checkIfChartDataIsValid(data as ChartData, keys),
    [data, keys]
  );

  const dataNotYetFormatted = useMemo(() => {
    return intervals.length && !data.length;
  }, [data, intervals]);

  const Tooltip = useMemo(
    () => (point: Record<string, unknown>) =>
      (
        <TransactionsChartTooltip
          point={point}
          isDayFormat={isDayFormat}
          timeZone={timeZone}
          unit={unit}
          symbol={symbol}
        />
      ),
    [isDayFormat, timeZone, unit, symbol]
  );

  const hideChartData = loading || dataNotYetFormatted || !doesChartHaveValidData;
  const chartData = hideChartData ? [] : data;

  return (
    <Box
      sx={{
        height: 350,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: isSmallDown ? 4 : 3,
      }}
      ref={ref}
      data-testid="transactions-bar-chart"
    >
      <Choose>
        <When condition={loading || dataNotYetFormatted}>
          <ChartPlaceholder label="Loading..." />
        </When>
        <When condition={!doesChartHaveValidData}>
          <ChartPlaceholder />
        </When>
      </Choose>

      <ResponsiveBar
        {...commonBarProps}
        {...xScaleProps}
        margin={{
          ...commonBarProps.margin,
          left: 64,
          right: 20,
        }}
        data={chartData}
        keys={keys}
        colors={colorSchemeWithOverrides}
        legends={chartLegends}
        tooltip={Tooltip}
        axisLeft={{
          ...commonBarProps.axisLeft,
          format: (value: number) =>
            formatAxisLeftValue(
              value,
              unit === TradeUnit.USAGE ? unit : TradeUnit.AMOUNT,
              unit !== TradeUnit.USAGE ? currency : undefined
            ),
        }}
        motionConfig={{
          mass: 1,
          tension: 500,
          friction: 1,
          clamp: true,
          precision: 0.01,
          velocity: 0,
        }}
      />
      <ChartNavArrows
        onClickLeft={onPreviousTimeFramePeriodOffset}
        onClickRight={onNextTimeFramePeriodOffset}
        placeNavArrowsBelowChart={isSmallDown}
      />
    </Box>
  );
};
