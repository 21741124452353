import { DateTime } from 'luxon';
import { GET } from '../api-methods';
import { ApiResponse } from '../types';
import { aggregatedExcess } from '../endpoints';
import { AggregatedExcessData } from './types';

export const getDayAheadAggregatedExcess = (date: DateTime): Promise<ApiResponse<AggregatedExcessData[]>> => {
  const queryString = new URLSearchParams({
    date: date.toFormat('yyyyMMdd'),
  });

  return GET(`${aggregatedExcess.getDayAheadAggregatedExcessURL}?${queryString}`);
};

export const getRealtimeAggregatedExcess = (date: DateTime): Promise<ApiResponse<AggregatedExcessData[]>> => {
  const queryString = new URLSearchParams({
    date: date.toFormat('yyyyMMdd'),
  });

  return GET(`${aggregatedExcess.getRealtimeAggregatedExcessURL}?${queryString}`);
};
