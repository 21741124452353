import { BarTooltipProps } from '@nivo/bar';
import { FC, memo } from 'react';
import { Box, Heading, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { TradeUnit, WattHour } from '../../../../../types';
import { InfoCircleOutlined } from '../../icons';
import {
  DAY_MONTH_SHORT,
  UndeterminedDate,
  formatNiceTime,
  parseDateWithTimeZone,
} from '../../../lib/datetime-helpers';
import { convertAndFormatUnitValue } from '../../../lib/unit';
import { CurrencyItem } from '../../item-value';
import { useFeatureSet } from '../../../hooks/use-feature-set';

export type TransactionsChartTooltipProps = Partial<BarTooltipProps<any>> & {
  point: Record<string, unknown>;
  isDayFormat: boolean;
  timeZone: string;
  unit: TradeUnit;
  symbol: string;
};

export const TransactionsChartTooltip: FC<TransactionsChartTooltipProps> = memo(
  ({ point: { indexValue, data }, isDayFormat, timeZone, unit, symbol }) => {
    const { t } = useTranslation();
    const { useEUTimeFormat } = useFeatureSet();

    const dataNodes = Object.entries(data as Record<string, number>).map(([key, value]) => {
      if (key === 'date') return null;

      let displayedValue = '0';

      if (unit === TradeUnit.USAGE) {
        const { formattedNewValue, newUnit } = convertAndFormatUnitValue(value, WattHour.kWh, 2);

        displayedValue = formattedNewValue === 0 ? '0' : `${formattedNewValue} ${newUnit}`;
      }

      return (
        <Box key={key} sx={{ fontSize: 1 }}>
          <Text sx={{ fontWeight: 'body' }}>{key}</Text>&nbsp;
          {unit === TradeUnit.USAGE ? (
            <Text>{displayedValue}</Text>
          ) : (
            <CurrencyItem value={value} symbol={symbol} />
          )}
        </Box>
      );
    });

    const date = parseDateWithTimeZone(indexValue as UndeterminedDate, timeZone);
    const showLateReadingsTip = dataNodes.some(
      (dataNode) => dataNode?.key === t('transaction.chart.typeName.missed')
    );

    return (
      <>
        <Heading as="h4">
          {t('transaction.chart.tooltip.time', {
            defaultValue: '{{date}}',
            replace: {
              date: isDayFormat
                ? formatNiceTime(indexValue as UndeterminedDate, timeZone)
                : `${
                    useEUTimeFormat
                      ? `${date.toLocaleString({ day: 'numeric' })} ${date.toLocaleString({
                          month: 'short',
                        })}`
                      : date.toLocaleString(DAY_MONTH_SHORT)
                  } (${date.toLocaleString({
                    weekday: 'short',
                  })})`,
            },
          })}
        </Heading>
        {dataNodes}
        {showLateReadingsTip && (
          <Box sx={{ maxWidth: '200px', marginTop: 2 }}>
            <InfoCircleOutlined size={3} sx={{ marginRight: 1, marginBottom: -1 }} />
            <Text sx={{ fontSize: '0.875rem' }}>{t('transaction.chart.tooltip.lateReadingTip')}</Text>
          </Box>
        )}
      </>
    );
  }
);
