export const datePicker = {
  '.DateInput': {
    background: 'transparent',
  },
  '.SingleDatePicker': {
    width: '100%',
  },
  '.SingleDatePickerInput .DateInput': {
    width: '100%',
  },
  '.DateRangePickerInput .DateInput': {
    width: 'unset',
    '& .DateInput_input': {
      textAlign: 'center',
    },
  },
  '.DateInput_input': {
    fontSize: 1,
    fontWeight: 'light',
    fontFamily: 'Inter',
    width: '100%',
    minWidth: 8,
    height: 7,
    border: 0,
    transition: 'base',
    backgroundColor: 'input',
    color: 'text',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'input',
    borderStyle: 'solid',
    '&:disabled': {
      color: 'muted',
    },
    '&::placeholder': {
      color: 'text',
    },
    '&:focus': {
      borderColor: 'text',
    },
  },
  '.SingleDatePickerInput': {
    width: '100%',
    border: 0,
    background: 'transparent',
  },
  '.DateRangePickerInput': {
    width: '100%',
    border: 0,
    borderRadius: 4,
    backgroundColor: 'input',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.DateRangePickerInput_arrow': {
    width: 20,
    height: '2px',
    bg: 'text',
    mx: 1,
    mb: 'auto',
    mt: 'auto',
  },
  '.DateRangePickerInput_calendarIcon': {
    m: 0,
    p: 0,
    mr: -2,
    mt: 1,
  },
  '.CalendarDay__selected_span': {
    bg: 'primary',
    color: 'white',
  },
  '.CalendarDay__selected_span:hover': {
    bg: 'primaryLightest',
    color: 'white',
    borderColor: 'primaryLightest',
  },
  '.CalendarDay__selected, .CalendarDay__selected:active': {
    bg: 'primary',
    color: 'white',
    borderColor: 'primary',
  },
  '.CalendarDay__selected:hover': {
    bg: 'primaryLightest',
    color: 'white',
    borderColor: 'primaryLightest',
  },
  '.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover': {
    bg: 'primary',
    color: 'white',
    borderStyle: 'solid',
    border: 1,
    borderColor: 'warningLight',
  },
  '.react-datepicker__input-container input': {
    fontSize: 1,
    fontWeight: 'light',
    fontFamily: 'Inter',
    width: '100%',
    minWidth: 8,
    height: 7,
    border: 0,
    px: 3,
    transition: 'base',
    backgroundColor: 'input',
    color: 'text',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'input',
    borderStyle: 'solid',
    '&:disabled': {
      color: 'muted',
    },
    '&::placeholder': {
      color: 'text',
    },
    '&:focus': {
      borderColor: 'text',
      outline: 0,
    },
  },
  '.react-datepicker': {
    fontWeight: 'normal',
    border: 'none',
    p: 2,
    fontSize: 1,
    color: '',
  },
  '.react-datepicker__current-month': {
    display: 'none',
  },
  '.react-datepicker__header': {
    bg: 'white',
    border: 'none',
    pb: 0,
  },
  '.react-datepicker__navigation': {
    mt: 2,
    mx: 3,
  },
  '.react-datepicker__day-names': {
    mt: 2,
  },
  '.react-datepicker__day-name, .react-datepicker__day': {
    m: 1,
  },
  '.react-datepicker__day-name': {
    color: 'grey',
  },
  '.react-datepicker__day': {
    color: 'greyDarkest',
  },
  '.react-datepicker__day:hover': {
    borderRadius: 999,
  },
  '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected:hover':
    {
      color: 'white',
      bg: 'primary',
      borderRadius: 999,
    },
  '.react-datepicker__day--disabled': {
    color: 'greyLightest',
    cursor: 'not-allowed',
  },
};
