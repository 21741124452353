import { VerifyPasswordResetResponse } from '../../auth-service-types';
import { VerifyPasswordReset } from '../adaptor-types';
import { MockAdaptorTypes } from './mock-adaptor-types';

export const verifyPasswordReset: VerifyPasswordReset<MockAdaptorTypes> = async (
  client,
  { recoveryToken, password }
) => {
  const outcome = await client.client.verifyPasswordReset({
    code: recoveryToken,
    password,
  });
  return outcome ? VerifyPasswordResetResponse.SUCCESS : VerifyPasswordResetResponse.INVALID_CODE;
};
