import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, ThemeUIStyleObject, css as themeUICSS } from 'theme-ui';

import { useTheme } from '../../hooks/use-theme';
import { LoadingOutlined } from '../icons';

type ButtonType = 'button' | 'submit' | 'reset';

export interface ModalProps {
  element?: string;
  maskClosable?: boolean;
  visible: boolean;
  onCancel?: React.MouseEventHandler<any>;
  cancelButtonContent?: any;
  onOk?: React.MouseEventHandler<any>;
  okButtonContent?: any;
  okDisabled?: boolean;
  title: React.ReactNode;
  bodyStyle?: ThemeUIStyleObject;
  footer?: any;
  afterClose?: React.MouseEventHandler<any>;
  confirmLoading?: boolean;
  compact?: boolean;
  okType?: ButtonType;
}

export interface ModalActionProps {
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => Promise<void> | void;
  type?: ButtonType;
  dataTestid?: string;
}

export const ModalOverlay = styled(Flex)<{ visible: boolean }>`
  ${themeUICSS({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: 'modal',
  })}

  ${({ visible }) =>
    themeUICSS({
      display: visible ? 'initial' : 'none',
    })}
`;

export const StyledModalAction = styled.button<{ disabled?: boolean }>`
  ${themeUICSS({
    width: 'initial',
    height: 8,
    px: 3,
    bg: 'muted',
    color: 'secondary',
    border: 0,
    borderLeft: '1px solid',
    borderColor: 'grey',
    fontWeight: 'light',
    display: 'flex',
    alignItems: 'center',
    transition: 'base',
    cursor: 'pointer',
    '&:hover': { bg: 'white' },
  })}

  ${({ disabled }) =>
    disabled &&
    themeUICSS({
      cursor: 'not-allowed',
      '&:hover': { bg: 'muted', color: 'white' },
    })}
`;

export const ModalAction: React.FC<ModalActionProps> = ({
  disabled,
  onClick,
  children,
  type = 'button',
  dataTestid,
}) => (
  <StyledModalAction disabled={disabled} onClick={onClick} type={type as ButtonType} data-testid={dataTestid}>
    {children}
  </StyledModalAction>
);

export const Modal: React.FC<ModalProps> = ({
  children,
  confirmLoading = false,
  afterClose,
  bodyStyle,
  onOk: onOkBase,
  okButtonContent,
  okDisabled,
  title,
  onCancel,
  cancelButtonContent,
  maskClosable = true,
  element = 'div',
  visible,
  footer,
  compact,
  okType = 'submit',
}) => {
  const { t } = useTranslation();
  const [container] = useState(document.createElement(element));
  const { theme } = useTheme();

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  const onClose = useCallback(
    async (e) => {
      if (onCancel) await onCancel(e);
      if (afterClose) afterClose(e);
    },
    [afterClose, onCancel]
  );

  const onOk = useCallback(
    async (e) => {
      if (onOkBase) await onOkBase(e);
      if (afterClose) afterClose(e);
    },
    [afterClose, onOkBase]
  );

  const okButton = useMemo(
    () => (
      <ModalAction disabled={okDisabled} onClick={onOk} type={okType} dataTestid="ok-button">
        {confirmLoading ? <LoadingOutlined color="background" /> : okButtonContent || t('Ok')}
      </ModalAction>
    ),
    [confirmLoading, okButtonContent, okDisabled, okType, onOk, t]
  );

  const cancelButton = useMemo(
    () => (
      <ModalAction onClick={onClose} dataTestid="cancel-button">
        {cancelButtonContent || t('Cancel')}
      </ModalAction>
    ),
    [cancelButtonContent, onClose, t]
  );

  const footerSection = useMemo(
    () =>
      footer === null || footer === false
        ? null
        : footer || (
            <Flex
              sx={{
                justifyContent: 'flex-end',
                bg: 'muted',
                borderTop: '1px solid',
                borderColor: 'grey',
              }}
            >
              {cancelButton}
              {okButton}
            </Flex>
          ),
    [footer, okButton, cancelButton]
  );

  const headingSection = useMemo(
    () => (typeof title === 'string' ? <Heading sx={{ width: '100%' }}>{title}</Heading> : title),
    [title]
  );

  const modalMargin = 6;

  const marginPixelValue = useMemo(() => {
    if (!theme.space) return 0;

    const spaceValue = theme.space[modalMargin];

    if (typeof spaceValue !== 'number') return 0;

    return spaceValue;
  }, [theme.space]);

  const handleMaskClick = useCallback(
    async (e: React.MouseEvent) => {
      if (!maskClosable) return;

      await onClose(e);
    },
    [onClose, maskClosable]
  );

  return createPortal(
    <>
      {visible && (
        <Box
          onClick={handleMaskClick}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 'modal',
          }}
        />
      )}
      {visible ? (
        <Global
          styles={css`
            body {
              min-height: 100vh;
              max-width: 100vw;
              overflow: hidden;
            }
          `}
        />
      ) : null}
      <ModalOverlay
        visible={visible}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            backgroundColor: 'background',
            my: modalMargin,
            mx: 'auto',
            maxWidth: ['90vw', null, '50vw'],
            borderRadius: 4,
            maxHeight: `calc(100vh - ${marginPixelValue * 2}px)`,
            overflow: 'hidden',
            pointerEvents: 'auto',
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              bg: 'secondary',
              color: 'white',
              paddingLeft: 3,
            }}
          >
            <Flex sx={{ flex: 1, alignItems: 'center' }}>{headingSection}</Flex>
            <Flex
              sx={{
                alignItems: 'center',
                borderLeftStyle: 'solid',
                borderLeftWidth: 1,
                borderColor: 'grey',
                cursor: 'pointer',
                p: 3,
                transition: 'base',
                '&:hover': { bg: 'grey' },
              }}
              onClick={onClose}
              data-testid="x-button"
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <Box style={{ width: '1em', textAlign: 'center' }} sx={{ fontSize: [3, 3, 4] }}>
                x
              </Box>
            </Flex>
          </Flex>
          <Box
            sx={{
              p: compact ? 0 : 3,
              overflowY: 'auto',
              ...bodyStyle,
              maxHeight: `calc(100vh - ${marginPixelValue}px)`,
            }}
          >
            {children}
          </Box>
          {footerSection}
        </Flex>
      </ModalOverlay>
    </>,
    container
  );
};
