import { TOGGLE_ESTIMATE, TOGGLE_IOA_ESTIMATE } from '../constants';
import { initialState } from '../store/initial-state';

// Settings reducer
const settings = (state = initialState.settings, action) => {
  switch (action.type) {
    case TOGGLE_ESTIMATE:
      return {
        ...state,
        includeEstimates: action.includeEstimates,
      };
    case TOGGLE_IOA_ESTIMATE:
      return {
        ...state,
        isEstimate: action.isEstimate,
      };
    default:
      return state;
  }
};

export default settings;
