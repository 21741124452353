import React from 'react';
import PropTypes from 'prop-types';

export const SettingsSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    aria-labelledby="title-settings"
    aria-describedby="desc-settings"
    role="img"
  >
    <title id="title-settings">Settings icon</title>
    <description id="desc-settings">A symbol representing a cog.</description>
    <path
      d="M20.8907 8.42533C20.8223 8.33411 20.7311 8.27938 20.617 8.26114L18.2362 7.89626C18.1039
    7.4812 17.926 7.05247 17.7026 6.61917C17.8576 6.40025 18.0948 6.09466 18.405 5.70241C18.7197
    5.30561 18.9386 5.01826 19.0709 4.83582C19.1393 4.74004 19.1758 4.6397 19.1758 4.5348C19.1758
    4.41165 19.1438 4.31587 19.0845 4.24745C18.7744 3.80504 18.0538 3.06615 16.9363 2.03537C16.8314
    1.94871 16.7219 1.9031 16.6125 1.9031C16.4802 1.9031 16.3799 1.94415 16.2978 2.02168L14.4506
    3.41279C14.0948 3.23035 13.7026 3.07071 13.2784 2.92932L12.9135 0.534797C12.8998 0.429894
    12.8497 0.334112 12.763 0.261136C12.6718 0.18816 12.5669 0.151672 12.4437
    0.151672H9.55205C9.30119 0.151672 9.14612 0.274819 9.08226 0.516553C8.96824 0.949848 8.84509
    1.75715 8.7037 2.93845C8.29777 3.07071 7.90096 3.23491 7.51784 3.43559L5.7208 2.04449C5.60678
    1.95783 5.49732 1.91222 5.38329 1.91222C5.19173 1.91222 4.78124 2.22237 4.15182 2.84266C3.5224
    3.46296 3.09823 3.92818 2.87018 4.23833C2.79264 4.35236 2.75159 4.4527 2.75159 4.53936C2.75159
    4.64426 2.7972 4.74916 2.88386 4.85407C3.46767 5.55646 3.92833 6.15395 4.27497 6.6511C4.05604
    7.05247 3.88728 7.44928 3.7687 7.85065L1.3468 8.21553C1.25102 8.23377 1.16892 8.2885 1.10051
    8.38428C1.03209 8.4755 0.995605 8.57585 0.995605 8.68075V11.5724C0.995605 11.6864 1.03209
    11.7868 1.10051 11.878C1.16892 11.9692 1.26014 12.024 1.37417 12.0422L3.75501 12.3934C3.87816
    12.8176 4.05604 13.2463 4.28865 13.6842C4.13358 13.9031 3.8964 14.2087 3.58626 14.6009C3.27155
    14.9977 3.05262 15.2851 2.92035 15.4675C2.85193 15.5633 2.81545 15.6636 2.81545 15.7685C2.81545
    15.8826 2.84737 15.9829 2.90667 16.0696C3.24418 16.5394 3.96026 17.2691 5.0549 18.2589C5.15068
    18.3546 5.26014 18.4002 5.37873 18.4002C5.511 18.4002 5.6159 18.3592 5.70256 18.2817L7.54065
    16.886C7.8964 17.0684 8.28865 17.2281 8.71282 17.3695L9.0777 19.764C9.08683 19.878 9.137 19.9692
    9.22822 20.0422C9.31944 20.1152 9.42434 20.1517 9.54749 20.1517H12.4437C12.6946 20.1517 12.8542
    20.0285 12.9135 19.7868C13.0275 19.3535 13.1507 18.5462 13.2921 17.3649C13.698 17.2326 14.0948
    17.0684 14.4779 16.8678L16.275 18.2725C16.3981 18.3501 16.5076 18.3911 16.6125 18.3911C16.804
    18.3911 17.21 18.081 17.8348 17.4652C18.4597 16.8495 18.8884 16.3797 19.1256 16.0605C19.2031
    15.9738 19.2442 15.8735 19.2442 15.7594C19.2442 15.6454 19.2031 15.5359 19.1119 15.4356C18.4871
    14.6693 18.0218 14.0719 17.7208 13.6386C17.8941 13.3193 18.0629 12.9225 18.2271 12.4527L20.6353
    12.0878C20.7402 12.0696 20.8268 12.0148 20.8953 11.9191C20.9637 11.8233 21.0002 11.7229 21.0002
    11.618V8.72636C20.9956 8.62146 20.9637 8.51655 20.8907 8.42533ZM13.3514 12.5074C12.6991 13.1597
    11.9146 13.4835 10.9933 13.4835C10.072 13.4835 9.28751 13.1597 8.63529 12.5074C7.98306 11.8552
    7.65923 11.0707 7.65923 10.1494C7.65923 9.22807 7.98306 8.44358 8.63529 7.79135C9.28751 7.13913
    10.072 6.8153 10.9933 6.8153C11.9146 6.8153 12.6991 7.13913 13.3514 7.79135C14.0036 8.44358
    14.3274 9.22807 14.3274 10.1494C14.332 11.0707 14.0036 11.8598 13.3514 12.5074Z"
      fill="#00838D"
    />
  </svg>
);

SettingsSVG.defaultProps = {
  width: '22px',
  height: '22px',
};

SettingsSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
