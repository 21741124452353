import { Heading } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const Container = styled.div`
  height: 100vh;

  h1 {
    font-size: 3em;
    text-align: center;
    margin: auto;
    color: black;
  }
`;

const NoIe = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Heading as="h1">{t('Internet Explorer is not supported')}</Heading>
    </Container>
  );
};

export default NoIe;
