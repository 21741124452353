import { shallowEqual } from 'react-redux';
import { memo, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { MeterGroup } from '../../../../types';
import { useAppSelector } from '../../hooks/use-app-selector';
import { WeatherItemProps, WeatherWidgetViewProps, shouldDisplayWeatherItem } from '../../components';
import { formatSiteWeather, getWeatherIcon } from '../../lib/weather-helpers';
import { useAppDispatch } from '../../hooks/use-app-dispatch';
import { getSiteWeather } from '../../states/actions/dashboard';
import { useCancelToken } from '../../hooks/use-cancel-token';

const WeatherItem = ({ weather, sx, first = false, last = false }: WeatherItemProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      key={weather.day}
      sx={{
        alignItems: 'center',
        ...(!first ? { paddingLeft: [0] } : {}),
        gap: 2,
        ...sx,
      }}
    >
      {getWeatherIcon(weather.icon, 'newUIPrimary')}

      <Text
        sx={{
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.25rem',
        }}
      >
        {weather.day}
      </Text>
      <Text sx={{ fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem' }}>
        {t('{{temperature}}°', { temperature: weather.temperature })}
      </Text>

      {!last && (
        <Box
          sx={{
            marginLeft: 1,
            height: 3,
            width: '1px',
            backgroundColor: '#000',
          }}
        />
      )}
    </Flex>
  );
};

const WeatherWidgetViewBase = ({ current, daily, sx }: WeatherWidgetViewProps) => {
  const { t } = useTranslation();
  const breakpointIndex = useBreakpointIndex();
  const numberOfDisplayedDailyItems = useMemo(
    () =>
      daily.reduce(
        (numberOfItems, currentItem, currentIndex) =>
          shouldDisplayWeatherItem(breakpointIndex, currentIndex) ? numberOfItems + 1 : numberOfItems,
        0
      ),
    [daily, breakpointIndex]
  );

  const dailyIcons = useMemo(
    () =>
      daily.map((weather, index) => (
        <WeatherItem
          key={weather.day}
          weather={weather}
          last={index === numberOfDisplayedDailyItems - 1}
          sx={{
            display: shouldDisplayWeatherItem(breakpointIndex, index) ? 'flex' : 'none',
          }}
        />
      )),
    [daily, breakpointIndex, numberOfDisplayedDailyItems]
  );

  return (
    <>
      {current?.day && daily.length > 0 ? (
        <Box
          sx={{
            height: 3,
            width: '1px',
            backgroundColor: '#000',
            marginLeft: 3,
          }}
        />
      ) : null}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: 2,
          ...sx,
        }}
      >
        {current && current.icon && current.temperature && (
          <WeatherItem
            weather={{ ...current, day: t('Now') }}
            first
            last={numberOfDisplayedDailyItems === 0}
          />
        )}
        {dailyIcons}
      </Box>
    </>
  );
};

export const WeatherWidgetView = memo(WeatherWidgetViewBase);

export const WeatherInfo = () => {
  const dispatch = useAppDispatch();
  const requestToken = useCancelToken();
  const meterGroup = useAppSelector(
    ({ meters: metersState }: any) => metersState.meterGroup,
    shallowEqual
  ) as MeterGroup;

  const weatherBySite = useAppSelector(({ dashboard }) => dashboard.weatherBySite, shallowEqual);

  useEffect(() => {
    dispatch(getSiteWeather(meterGroup, requestToken as any));
  }, [meterGroup, dispatch, requestToken]);

  const formattedSiteWeather = useMemo(
    () => formatSiteWeather(weatherBySite, meterGroup),
    [weatherBySite, meterGroup]
  );

  return <WeatherWidgetView {...formattedSiteWeather} />;
};
