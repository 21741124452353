import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Theme } from '@power-ledger/styles';

const ArrowPath = styled.path`
  ${(props) => props.theme.grey600}
`;

const LeftArrow = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 4 8" fill={Theme.grey600}>
    <ArrowPath fillRule="evenodd" d="M4 8L-1.74846e-07 4L4 -1.74846e-07L4 8Z" />
  </svg>
);

LeftArrow.defaultProps = {
  width: '4px',
  height: '8px',
};

LeftArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default LeftArrow;
