import { FC } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { PasswordStrengthBarType } from './PasswordInput.types';

export const PasswordStrengthBar: FC<PasswordStrengthBarType> = ({ strengthResult }) => {
  const { t } = useTranslation();
  const { standardStrength, extraStrength } = strengthResult.strength;

  const getMeasurements = () => {
    let passwordStrength = '';
    let color = '';

    if (standardStrength > 0 && standardStrength < 3) {
      passwordStrength = t('Weak');
      color = 'red';
    } else if (standardStrength >= 3 && standardStrength < 5) {
      passwordStrength = t('Okay');
      color = 'yellow';
    } else if (standardStrength >= 5 && extraStrength < 1) {
      passwordStrength = t('Good');
      color = 'teal';
    } else if (standardStrength >= 5 && extraStrength > 0) {
      passwordStrength = t('Great');
      color = 'positive';
    }

    return color && <Text sx={{ color: color }}>{passwordStrength}</Text>;
  };

  return (
    <Box>
      <Flex sx={{ width: '93%', py: strengthResult.length > 0 && 2 }}>
        {standardStrength > 0 && (
          <Box
            sx={{
              height: 1,
              width: '100%',
              maxWidth: '25%',
              mr: 1,
              backgroundColor: 'red',
            }}
            data-testid="weak"
          ></Box>
        )}
        {standardStrength >= 3 && (
          <Box
            sx={{
              height: 1,
              width: '100%',
              maxWidth: '25%',
              mx: 1,
              backgroundColor: 'yellow',
            }}
            data-testid="okay"
          ></Box>
        )}
        {standardStrength >= 5 && (
          <Box
            sx={{
              height: 1,
              width: '100%',
              maxWidth: '25%',
              mx: 1,
              backgroundColor: 'teal',
            }}
            data-testid="good"
          ></Box>
        )}
        {standardStrength >= 5 && extraStrength > 0 && (
          <Box
            sx={{
              height: 1,
              width: '100%',
              maxWidth: '25%',
              ml: 1,
              backgroundColor: 'positive',
            }}
            data-testid="great"
          ></Box>
        )}
      </Flex>
      <Box>{getMeasurements()}</Box>
    </Box>
  );
};
