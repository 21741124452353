import { FC, useMemo } from 'react';
import { Flex, Box, Text, ThemeUIStyleObject } from 'theme-ui';
import currency from 'currency.js';
import { RecBadge } from '../index';

export type RecTileProps = {
  abbreviation: string;
  change?: number;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
};

export const RecTile: FC<RecTileProps> = ({ abbreviation, change = 0, onClick, sx }) => {
  const signIcon = useMemo(() => {
    if (change > 0) return <Text sx={{ fontWeight: 'bold', color: 'positive' }}>+</Text>;
    if (change < 0) return <Text sx={{ fontWeight: 'bold', color: 'negative' }}>-</Text>;
    return null;
  }, [change]);

  return (
    <Box
      sx={{
        px: 1,
        py: 2,
        bg: 'foreground',
        ...(onClick ? { cursor: 'pointer' } : {}),
        ...sx,
      }}
      onClick={onClick}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 8,
          height: 8,
          flexDirection: 'column',
          borderRadius: 2,
        }}
      >
        <RecBadge abbreviation={abbreviation} />
        {!!change && (
          <Box
            sx={{
              fontSize: 1,
              mt: 1,
              fontWeight: 'bold',
              fontFamily: 'MintGroteskV08',
            }}
          >
            {signIcon}
            <Text>
              {currency(change * 100, {
                precision: 0,
                symbol: '%',
                pattern: `#!`,
                negativePattern: `#!`,
              }).format()}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
