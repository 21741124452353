import { useMemo } from 'react';
import chunk from 'lodash/chunk';

import { parseDateWithTimeZone } from '../../../lib/datetime-helpers';

type EnergyTransactionInterval = {
  gridMoney: number;
  windMoney: number;
  hydroMoney: number;
  solarMoney: number;
  biomassMoney: number;
  nuclearMoney: number;
  unknownMoney: number;
  gridUsage: number;
  windUsage: number;
  hydroUsage: number;
  solarUsage: number;
  unknownUsage: number;
  nuclearUsage: number;
  biomassUsage: number;
  dateTime: string;
};

type EnergyTransactionConfigData = {
  intervals?: EnergyTransactionInterval[];
  hiddenIds: string[];
  unit: 'usage' | 'money' | 'moneyNetTax';
  startDate: string;
  endDate: string;
  timeZone: string;
  chunkSize: number;
  t: any;
};

export const useFormattedEnergyMixData = ({
  unit,
  t,
  intervals,
  hiddenIds,
  timeZone,
  chunkSize,
}: EnergyTransactionConfigData): { data: any[]; keys: string[] } => {
  const mapUnit = unit.charAt(0).toUpperCase() + unit.slice(1);

  const mapKey = (key: any) => {
    const newKey = key.replace('Usage', '').replace('Money', '');
    if (newKey === 'solar') return 'solar_pv';
    return newKey;
  };

  // Filter intervals outside of start and end datetimes and chunk them
  const filteredAndChunkedIntervals = useMemo(() => {
    if (Number.isNaN(chunkSize)) {
      return [];
    }

    return chunk(
      (intervals || []).map((interval) =>
        Object.keys(interval).reduce<any>((acc, key) => {
          if (!key.endsWith(mapUnit) && !['dateTime', 'estimate'].includes(key)) return acc; // remove unselected unit properties (Kw/Amount)
          const newKey = mapKey(key);
          // eslint-disable-next-line no-param-reassign
          acc[newKey] = interval[key as keyof EnergyTransactionInterval];
          // eslint-enable-next-line no-param-reassign

          return acc;
        }, {})
      ),
      chunkSize
    );
  }, [chunkSize, intervals, mapUnit]);

  const { parsedData, keys } = useMemo(() => {
    const newTypeSet = new Set<string>();

    const newParsedData = filteredAndChunkedIntervals.reduce<any[]>((acc, chunkArr) => {
      /* eslint-disable no-param-reassign */
      const { dateTimes, ...transactionAverages } = chunkArr.reduce<any>(
        (sumAcc, interval: any) => {
          sumAcc.dateTimes = [
            ...sumAcc.dateTimes,
            parseDateWithTimeZone(interval.dateTime, timeZone).toMillis(),
          ];

          Object.entries<any>(interval)
            .filter(([v]) => !['dateTime', 'estimate'].includes(v))
            .forEach(([key, value]) => {
              // TODO: This is a small filter to hide Generation Types that show no values,
              // In future we want the API to determine these for us and this can be removed.
              if (value >= 0.00001) {
                newTypeSet.add(mapKey(key));
              }

              sumAcc[key] = typeof sumAcc[key] === 'undefined' ? interval[key] : sumAcc[key] + interval[key];
            });

          return sumAcc;
        },
        { dateTimes: [] } as Record<string, number[]>
      );

      /* eslint-enable no-param-reassign */
      const dateTime = parseDateWithTimeZone(Math.min(...dateTimes), timeZone);
      acc.push({
        date: dateTime.toMillis(),
        ...transactionAverages,
      });

      return acc;
    }, []);
    const newTypes = Array.from(newTypeSet).sort();
    return {
      parsedData: newParsedData,
      keys: newTypes,
      translated: newTypes.map((key) => t(`transaction.chart.typeName.${key}`)),
    };
  }, [filteredAndChunkedIntervals, t, timeZone]);

  // Hide data based on the hiddenIds array
  const data = useMemo(
    () =>
      parsedData.map(({ date, ...dataSeries }) => {
        const formattedDataSeries = Object.entries(dataSeries).reduce((acc, [key, val]) => {
          if (!hiddenIds.includes(key)) {
            /* eslint-disable no-param-reassign */
            acc[key] = val;
            /* eslint-enable no-param-reassign */
          }
          return acc;
        }, {} as any);
        return { date, ...formattedDataSeries };
      }),
    [hiddenIds, parsedData]
  );

  return { data, keys };
};
