import { IconButtonProps as IconButtonPropsBase, IconButton as IconButtonBase } from 'theme-ui';
import { FC } from 'react';
import { IconProps } from '../icons/common';
import { BasePalette } from '../../styles/colors';

export { Button } from 'theme-ui';
export * from './ButtonGroup';

type IconButtonProps = {
  buttonProps?: IconButtonPropsBase;
  iconProps?: IconProps;
  label: string;
  color?: keyof BasePalette;
  icon: FC<IconProps>;
  sx?: { [key: string]: string | number };
};

export const IconButton: FC<IconButtonProps> = ({
  icon: Icon,
  color = 'primary',
  label,
  iconProps = {},
  buttonProps = {},
  sx = {},
}) => (
  <IconButtonBase
    aria-label={label}
    sx={{
      '& svg, & path': {
        color,
        fill: color,
      },
      '&:focus, &:hover': {
        '& svg, & path': {
          color: `${color as string}Darker`,
          fill: `${color as string}Darker`,
        },
      },
      '*': {
        'user-select': 'none',
      },
      ...sx,
    }}
    {...buttonProps}
  >
    <Icon {...iconProps} />
  </IconButtonBase>
);
