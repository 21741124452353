import { FC } from 'react';
import { ErrorMessageProps as ErrorMessageBaseProps, ErrorMessage as ErrorMessageBase } from 'formik';
import { Text, TextProps } from 'theme-ui';

type ErrorMessageProps = ErrorMessageBaseProps & TextProps;

export const ErrorMessage: FC<ErrorMessageProps> = ({ children, sx, ...props }) => (
  <ErrorMessageBase {...props}>
    {(msg) => <Text sx={{ variant: 'forms.errorMessage', ...sx }}>{msg}</Text>}
  </ErrorMessageBase>
);

export default ErrorMessage;
