import { CustomThemeProperty } from './types';

export const loadingOverlay = CustomThemeProperty('loadingOverlay', {
  position: 'absolute',
  bg: 'foreground',
  height: '100%',
  width: '100%',
  transition: 'base',
  pointerEvents: 'none',
  zIndex: 1,
  inset: 0,
});
