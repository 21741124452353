import { FC } from 'react';

import { Radio as ThemeRadio, Label, LabelProps } from 'theme-ui';

export type RadioProps = {
  label?: string;
  id?: string;
  value?: boolean;
  name?: string;
} & LabelProps;

export const Radio: FC<RadioProps> = ({ label, id, value, name, sx, ...props }) => (
  <Label
    htmlFor={id}
    variant="radioLabel"
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx,
    }}
    {...props}
  >
    <ThemeRadio id={id} defaultChecked={value} name={name} variant="radio" />
    {label}
  </Label>
);
