import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const commonStyles: ThemeUIStyleObject = {
  borderWidth: 1,
  borderStyle: 'solid',
  fontSize: 0,
  display: 'inline-block',
  px: 2,
};

export const tags = CustomThemeProperty('tags', {
  primary: {
    ...commonStyles,
    borderColor: '#d9d9d9',
    bg: 'muted',
  },
  cyan: {
    ...commonStyles,
    borderColor: '#87e8de',
    bg: '#e6fffb',
    color: '#08979c',
  },
  red: {
    ...commonStyles,
    borderColor: '#ffa39e',
    bg: '#fff1f0',
    color: '#cf1322',
  },
});
