/** @jsxImportSource theme-ui */
import { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Themed } from 'theme-ui';

export type MenuItemProps = {
  active?: boolean;
  tabIndex?: number;
  to?: string;
  onClick?: () => void;
  dataTestid?: string;
  newStyles?: boolean;
};
export type MenuItemType = FC<MenuItemProps>;

export type MenuProps = {
  children?: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
  newStyles?: boolean;
};

export type MenuType = FC<MenuProps & { dataTestid?: string }> & {
  Item: MenuItemType;
  Group: MenuItemType;
};

export const Menu: MenuType = ({ children, dataTestid, newStyles = false }) => {
  if (newStyles) {
    return <Box as="ul">{children}</Box>;
  }

  return (
    <Box>
      <Themed.ul sx={{ variant: 'menus' }} data-testid={dataTestid}>
        {children}
      </Themed.ul>
    </Box>
  );
};

const MenuItem: MenuItemType = ({
  children,
  to,
  tabIndex = 0,
  active = false,
  onClick,
  dataTestid,
  newStyles = false,
}) => {
  const history = useHistory();
  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };

  if (newStyles) {
    return (
      <Flex
        as="li"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          marginY: 1,
          '&:first-child': {
            marginTop: 0,
          },
          '&:last-child': {
            marginBottom: 0,
          },
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          padding: '12px',
          paddingLeft: 0,
          borderRadius: '8px',
          listStyle: 'none',
          height: '48px',
          cursor: 'pointer',
          '&:hover': {
            bg: 'newUIPrimaryLight',
            color: 'newUIHighlightText',
          },
          ...(active ? { bg: 'newUIPrimaryLight', color: 'newUIHighlightText' } : {}),
        }}
        data-testid={dataTestid}
        tabIndex={tabIndex}
        onClick={handleOnClick}
        onKeyDown={(e) => {
          const key = e.code;
          return key === 'Enter' ? handleOnClick() : e;
        }}
      >
        {children}
      </Flex>
    );
  }

  return (
    <Themed.li
      data-testid={dataTestid}
      sx={{
        variant: active ? 'menus.items.active' : 'menus.items',
      }}
      tabIndex={tabIndex}
      onClick={handleOnClick}
      onKeyDown={(e) => {
        const key = e.code;
        return key === 'Enter' ? handleOnClick() : e;
      }}
    >
      {children}
    </Themed.li>
  );
};

const MenuGroup: MenuItemType = ({ children, active }) => (
  <Themed.li
    sx={{
      variant: 'menus.groups',
      ...(active ? { bg: 'primaryDark' } : {}),
    }}
  >
    {children}
  </Themed.li>
);

Menu.Item = MenuItem;
Menu.Group = MenuGroup;
