import {
  UPDATE_VPP_MODE,
  UPDATE_VPP_CONFIG,
  UPDATE_VPP_PRICE_THRESHOLD,
  UPDATE_BATTERY_FLEET,
  UPDATE_CLUSTER_SUMMARY,
  UPDATE_BATTERY_SUMMARY,
  UPDATE_VPP_PRICES,
  UPDATE_DERM_EVENTS,
  UPDATE_EVENT_RECORDS,
  UPDATE_VPP_SUMMARY,
  RESET_BATTERY_SHARING,
} from '../constants';
import { initialState } from '../store/initial-state';

// Battery sharing reducer
const batterySharing = (state = initialState.batterySharing, action) => {
  switch (action.type) {
    case UPDATE_VPP_MODE:
      return {
        ...state,
        vppConfig: {
          ...state.vppConfig,
          mode: action.mode,
        },
      };
    case UPDATE_VPP_PRICE_THRESHOLD:
      return {
        ...state,
        vppConfig: {
          ...state.vppConfig,
          smartArbitrageConfig: state.vppConfig.smartArbitrageConfig.map((v) =>
            v.type === action.configType.toUpperCase() ? { ...v, priceThreshold: action.threshold } : v
          ),
        },
      };
    case UPDATE_VPP_CONFIG:
      return {
        ...state,
        vppConfig: { ...state.vppConfig, ...action.vppConfig },
      };
    case UPDATE_VPP_PRICES:
      return {
        ...state,
        prices: action.prices || [],
      };
    case UPDATE_DERM_EVENTS:
      return {
        ...state,
        dermEvents: action.dermEvents || [],
      };
    case UPDATE_EVENT_RECORDS:
      return {
        ...state,
        events: action.events || [],
        totalEvents: action.totalEvents || 0,
      };
    case UPDATE_BATTERY_SUMMARY:
      return {
        ...state,
        batterySummary: action.batterySummary,
      };
    case UPDATE_BATTERY_FLEET:
      return {
        ...state,
        batteries: action.batteries || [],
      };
    case UPDATE_CLUSTER_SUMMARY:
      return {
        ...state,
        clusterSummary: action.clusterSummary,
      };
    case UPDATE_VPP_SUMMARY:
      return {
        ...state,
        vppSummary: action.vppSummary,
      };
    case RESET_BATTERY_SHARING:
      return initialState.batterySharing;
    default:
      return state;
  }
};

export default batterySharing;
