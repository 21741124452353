import { DateTime } from 'luxon';

let startEndTimeOptionsMemorizerCache: any = {};

type StartEndTimeOptions = {
  startOptions: any[];
  endOptions: any[];
};

export const startEndTimeOptionsMemorizer = (
  array: any[],
  values: Record<string, DateTime>,
  key: 'hour' | 'minute'
): StartEndTimeOptions => {
  const strArray = JSON.stringify(array);
  const cacheStr = `${values.startDate[key]}:${values.endDate[key]}`;
  const cacheHit = startEndTimeOptionsMemorizerCache?.[strArray]?.[cacheStr];
  if (cacheHit) return cacheHit;

  if (!startEndTimeOptionsMemorizerCache[strArray]) {
    startEndTimeOptionsMemorizerCache[strArray] = {};
  }
  startEndTimeOptionsMemorizerCache[strArray][cacheStr] = array.reduce(
    (acc, value) => {
      const label = value;
      const datesTheSame = values.startDate.hasSame(values.endDate, 'day');
      acc.startOptions.push({
        value,
        label,
        isDisabled: datesTheSame && Number(value) > values.endDate[key],
      });
      acc.endOptions.push({
        value,
        label,
        isDisabled: datesTheSame && Number(value) < values.startDate[key],
      });
      return acc;
    },
    {
      startOptions: [],
      endOptions: [],
    }
  );
  return startEndTimeOptionsMemorizerCache[strArray][cacheStr];
};

export const clearStartEndTimeOptionsMemorizerCache = () => {
  startEndTimeOptionsMemorizerCache = {};
};
