import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const commonButtonStyles: ThemeUIStyleObject = {
  border: 2,
  px: 2,
  borderStyle: 'solid',
  cursor: 'pointer',
};

export const popconfirms = CustomThemeProperty('popconfirm', {
  position: 'absolute',
  bottom: 5,
  width: '450px',
  background: 'white',
  overflow: 'hidden',
  borderRadius: 4,
  p: 3,
  transformOrigin: 'bottom',
  transform: 'scaleY(0)',
  opacity: 0,
  transition: 'base',
  boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
  confirmButton: {
    ...commonButtonStyles,
    color: 'white',
    bg: 'primary',
    borderColor: 'primary',
    transition: 'base',
    '&:hover': { bg: 'primaryLighter', borderColor: 'primaryLighter' },
  },
  cancelButton: {
    ...commonButtonStyles,
    mr: 2,
    borderColor: 'muted',
    transition: 'base',
    '&:hover': { color: 'primaryLighter', borderColor: 'primaryLighter' },
  },
  arrow: {
    position: 'absolute',
    bg: 'white',
    transform: 'rotate(45deg)',
    width: 2,
    height: 2,
    bottom: -1,
    right: '50%',
  },
});
