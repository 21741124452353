import { Flex, Text } from 'theme-ui';
import { useHostSpecificTimezone } from '../../components';
import { CalendarIcon } from '../../components/icons/app-bar/Calendar';

export const DateTimeInfo = () => {
  const { hostSpecificTimezone, timeZoneAbbreviation } = useHostSpecificTimezone();

  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'center', color: 'newUIPrimary' }}>
      <CalendarIcon size={6} />
      <Text
        sx={{
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.25rem',
          color: '#000',
        }}
      >
        {hostSpecificTimezone} ({timeZoneAbbreviation})
      </Text>
    </Flex>
  );
};
