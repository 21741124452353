export const CRSSmall = ({ width = 17, height = 18 }: { width?: number; height?: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 86 90"
    style={{ minWidth: '17px' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.21.13C28.9 1.98 19.8 7.31 12.33 12.98 6.56 17.35 1.27 25.45.48 30.26c-.08.48-.13.95-.11 1.36 0 0-.5 21.06-.33 27.32.1 3.87 1.04 6.2 1.74 7.42.24.34.5.72.77 1.07-2.24-5.38 4.19-17.2 8.85-23.12.12-.15.24-.31.36-.46.96-1.19 1.82-2.14 2.49-2.73 10-9.06 19.9-14.05 31.23-17.72 1.12-7.59 1-19.77-3.26-23.26l-.01-.01Z"
      fill="#FFC20E"
    />
    <path
      d="M57.81 19.35C55.01 12.36 47.99.88 42.21.13c4.25 3.49 4.38 15.67 3.26 23.26 3.99-1.29 8.15-2.42 12.55-3.5-.07-.18-.13-.36-.21-.54Z"
      fill="#BC9109"
    />
    <path
      d="M2.55 67.42c8.26 10.6 17.42 15.82 26.07 19.46 6.67 2.81 16.33 3.34 20.89 1.63.46-.17.89-.36 1.23-.58 0 0 18.49-10.1 23.83-13.38 3.3-2.03 4.85-4.01 5.55-5.22.18-.38.37-.79.54-1.2-3.54 4.63-16.99 4.97-24.44 3.9-.2-.03-.39-.06-.58-.09-1.51-.23-2.76-.51-3.61-.79-12.84-4.13-22.12-10.21-30.96-18.18-7.14 2.83-17.62 9.02-18.52 14.45Z"
      fill="#F7941D"
    />
    <path
      d="M11.39 44.3C6.73 50.23.3 62.05 2.55 67.42c.9-5.43 11.38-11.62 18.52-14.45-3.11-2.81-6.17-5.84-9.3-9.12-.12.15-.24.29-.37.45h-.01Z"
      fill="#B76E11"
    />
    <path
      d="M80.66 68.13c5.05-12.45 4.99-23 3.82-32.31-.9-7.19-5.27-15.81-9.03-18.9-.38-.31-.76-.59-1.12-.78 0 0-17.99-10.96-23.5-13.95C47.42.35 44.92 0 43.52 0c-.42.03-.87.07-1.31.13 5.78.74 12.8 12.22 15.6 19.22.07.19.14.37.21.54.55 1.42.94 2.65 1.12 3.52 2.84 13.19 2.22 24.26-.27 35.9 6.02 4.77 16.63 10.75 21.77 8.81l.02.01Z"
      fill="#F37021"
    />
    <path
      d="M56.21 72.03c7.46 1.07 20.91.73 24.44-3.9-5.15 1.94-15.76-4.04-21.77-8.81-.88 4.1-1.98 8.27-3.25 12.62.19.03.37.06.58.09Z"
      fill="#B45314"
    />
    <path
      d="M72.29 3.8h-2.14V2.7h5.47v1.1h-2.14v5.64h-1.19V3.8ZM76.87 2.7h1.26l2.05 3.19 2.05-3.19h1.26v6.74h-1.18V4.61l-2.13 3.18h-.04l-2.11-3.16v4.82h-1.17V2.7h.01Z"
      fill="#fff"
    />
  </svg>
);
