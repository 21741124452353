import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Commented out this import to prevent app from crashing
// api.getLeaderboardByState() was not exported by apiConfig
// and the file is deleted now anyway
// import { api } from '../middleware/apiConfig';
const api = {};

const getLeaderboardByState = createAsyncThunk('getLeaderboardByState', async (state) => {
  const response = await api.getLeaderboardByState(state);
  return { data: response.data, state };
});

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: [],
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [getLeaderboardByState.fulfilled]: (state, action) => {
      // Add user to the state array
      state.leaderboards[action.payload.state] = action.payload.data;
    },
  },
});

// Export the reducer, either as a default or named export
export default leaderboardSlice.reducer;
