import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const sharedItemStyles: ThemeUIStyleObject = {
  fontWeight: 'light',
  fontSize: [4, null, 1],
  listStyle: 'none',
  py: [0, null, 3],
  pl: [0, null, 4],
  cursor: 'pointer',
  textAlign: ['center', null, 'left'],
  transition: 'base',
  color: 'white',
  mx: [3, 4, 0],
  my: [2, 3, 0],
  outline: '0',
};

const commonMenuItemsStyles = {
  ...sharedItemStyles,
  '&:hover': {
    bg: [null, null, 'primaryDark'],
  },
  '&:focus': {
    boxShadow: ['focus', null, 'none'],
  },
};

export const menus = CustomThemeProperty('menu', {
  display: 'flex',
  flexDirection: 'column',
  p: 0,
  m: 0,
  groups: {
    ...sharedItemStyles,
    '&:hover, &:focus': {
      bg: 'primaryDark',
    },
  },
  items: {
    ...commonMenuItemsStyles,
    active: {
      ...commonMenuItemsStyles,
      bg: ['none', null, 'primaryDark'],
      borderLeft: ['none', null, 4],
      borderLeftStyle: ['none', null, 'solid'],
      borderColor: ['none', null, 'primaryLightest'],
      fontWeight: ['bold', null, 'light'],
    },
  },
});
