export class MockAuthAdaptorEmitter {
  private target = new EventTarget();

  public dispatchAuthenticated() {
    this.target.dispatchEvent(new Event('auth-event'));
  }

  public dispatchNotAuthenticated() {
    this.target.dispatchEvent(new Event('auth-event'));
  }

  public subscribe(callback: () => void) {
    this.target.addEventListener('auth-event', callback as any);
  }

  public unSubscribe(callback: () => void) {
    this.target.removeEventListener('auth-event', callback as any);
  }
}
