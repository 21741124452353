import { useCallback, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';
import { Box } from 'theme-ui';

import { ChartNavArrows } from '../chart-nav-arrows';
import { useStandardBarChartConfig } from '../hooks/useStandardBarChartConfig';
import { checkIfChartDataIsValid, ChartData, formatAxisLeftValue, determineTimeScale } from '../charts.utils';
import { ChartPlaceholder } from '../chart-placeholder';
import {
  labelOfAssetGenTypeId,
  orderOfAssetGenTypeId,
} from '../../../layouts/common/energy-mix/components/energy-mix-tools';
import { useDynamicGraphTicks, useTimeFrameDateData } from '../hooks';
import { useAppSelector } from '../../../hooks/use-app-selector';
import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { useTimeFramePeriodOffset } from '../../../hooks/use-date-switcher';
import { TradeUnit } from '../../../../../types';
import { Choose, When } from '../..';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useScreenSize } from '../../../hooks/use-screen-size';
import { EnergyMixChartTooltip } from './EnergyMixChartTooltip';
import { useFormattedEnergyMixData } from './useFormattedEnergyMixData';

export interface EnergyMixStackChartProps {
  unit: TradeUnit.USAGE | 'money' | 'moneyNetTax';
  currency: string;
  colors?: Record<string, string>;
  loading?: boolean;
}

export const EnergyMixStackChart = ({
  unit,
  currency,
  colors,
  loading = false,
}: EnergyMixStackChartProps) => {
  const { t } = useTranslation();
  const { isSmallDown } = useScreenSize();
  const getLabel = useCallback((id: string) => labelOfAssetGenTypeId(t, `${id}`), [t]);
  const { useEUTimeFormat } = useFeatureSet();

  const { chartConfig } = useThemedColorScale();
  const { onPrevious, onNext } = useTimeFramePeriodOffset();

  const intervals = useAppSelector(({ energyMix }) => energyMix.intervals, shallowEqual);

  const [hiddenIds, setHiddenIds] = useState<string[]>([]);

  const { ref, chunkSize, width } = useDynamicGraphTicks(intervals, chartConfig.bar.tickWidth);

  const { startDate, endDate, timeZone } = useTimeFrameDateData();

  const { data, keys: unSortedKeys } = useFormattedEnergyMixData({
    intervals,
    hiddenIds,
    unit,
    startDate,
    endDate,
    timeZone,
    chunkSize,
    t,
  });
  const keys = [...unSortedKeys].sort((a, b) => orderOfAssetGenTypeId(a) - orderOfAssetGenTypeId(b));
  const { isDayFormat, ...xScaleProps } = useMemo(
    () =>
      determineTimeScale({
        startDate,
        endDate,
        timeZone,
        width,
        numValues: data.length,
        isEUTimeFormat: useEUTimeFormat,
      }),
    [data.length, endDate, startDate, timeZone, width, useEUTimeFormat]
  );

  const { legends, ...commonBarProps } = useStandardBarChartConfig({
    width,
    legendData: keys,
  });

  const colorSchemeWithOverrides = useMemo(
    () => (colors ? keys.map((key) => colors[key.toUpperCase()]) : commonBarProps.colors),
    [keys, colors, commonBarProps]
  );

  const realLegend = useMemo(
    () =>
      legends.map(({ legendData, ...legend }: any) => ({
        ...legend,
        data: legendData.map((key: string) => ({
          id: key,
          label: getLabel(key),
          color: hiddenIds.includes(key) ? 'rgba(1, 1, 1, .1)' : colorSchemeWithOverrides[keys.indexOf(key)],
        })),
        onClick: (datum: any) =>
          setHiddenIds((state) =>
            state.includes(datum.id) ? state.filter((item) => item !== datum.id) : [...state, datum.id]
          ),
      })),
    [colorSchemeWithOverrides, hiddenIds, keys, legends, getLabel]
  );

  const Tooltip = useMemo(
    () => (point: any) =>
      (
        <EnergyMixChartTooltip
          point={point}
          getLabel={getLabel}
          isDayFormat={isDayFormat}
          timeZone={timeZone}
          t={t}
        />
      ),
    [isDayFormat, t, timeZone, getLabel]
  );

  const doesChartHaveValidData = useMemo(
    () => checkIfChartDataIsValid(data as ChartData, keys),
    [data, keys]
  );

  const dataNotYetFormatted = useMemo(() => {
    return !intervals?.length && !data.length;
  }, [data, intervals]);

  const hideChartData = loading || dataNotYetFormatted || !doesChartHaveValidData;
  const chartData = hideChartData ? [] : data;

  return (
    <Box
      sx={{
        height: 350,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: isSmallDown ? 4 : 3,
      }}
      ref={ref}
    >
      <Choose>
        <When condition={loading || dataNotYetFormatted}>
          <ChartPlaceholder label="Loading..." />
        </When>
        <When condition={!doesChartHaveValidData}>
          <ChartPlaceholder />
        </When>
      </Choose>
      <ResponsiveBar
        {...commonBarProps}
        {...xScaleProps}
        margin={{
          ...commonBarProps.margin,
          left: isSmallDown ? 0 : 100,
        }}
        data={chartData}
        keys={keys}
        colors={colorSchemeWithOverrides}
        legends={realLegend}
        tooltip={Tooltip}
        axisLeft={{
          ...commonBarProps.axisLeft,
          format: (value: number) =>
            formatAxisLeftValue(
              value,
              unit === TradeUnit.USAGE ? unit : TradeUnit.AMOUNT,
              unit !== TradeUnit.USAGE ? currency : undefined
            ),
        }}
      />
      <ChartNavArrows
        disabled={loading}
        onClickLeft={onPrevious}
        onClickRight={onNext}
        placeNavArrowsBelowChart={isSmallDown}
      />
    </Box>
  );
};
