import { SignInResponse, useAuthService } from '@power-ledger/auth-service';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Label } from 'theme-ui';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { shallowEqual } from 'react-redux';
import {
  Button,
  ErrorMessage,
  Form,
  Input,
  LoadingOutlined,
  LockOutlined,
  TogglingEye,
  UserOutlined,
} from '../../../components';
import { errorNotification } from '../../../lib/notifications';
import { useAppSelector } from '../../../hooks/use-app-selector';

export let isInLoginForm = false;

export const LoginForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordShown = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  const authProfile = useAppSelector(({ auth }) => auth.profile, shallowEqual);

  const { signIn, signOut, getTokens, isAuthenticated } = useAuthService();
  const isAuthed = isAuthenticated();

  const handleLogin = useCallback(
    async (values: { username: string; password: string }) => {
      try {
        const resp = await signIn(values);

        if (resp === SignInResponse.SUCCESS) {
          const tokens = await getTokens();

          if (!tokens) {
            errorNotification({
              description: t('An unexpected error occurred. Please try again or contact an administrator.'),
            });

            return;
          }
        } else if (resp === SignInResponse.INVALID_CRED) {
          errorNotification({
            description: t("The credentials that you've entered are incorrect."),
          });
        } else {
          errorNotification({
            description: t(
              'An account state was given that is not currently supported by this app. Please try again or contact an administrator.'
            ),
          });
        }
      } catch (error: any) {
        errorNotification({
          errorCode: error.errorCode,
          description: t('An unexpected error occurred. Please try again or contact an administrator.'),
        });
      }
    },
    [getTokens, signIn, t]
  );

  const navigate = () => {
    history.push('/forgot-password');
  };

  useEffect(() => {
    isInLoginForm = true;

    return () => {
      isInLoginForm = false;
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={object().shape({
          username: string().required(t('Username is required')),
          password: string().required(t('Password is required')),
        })}
        onSubmit={handleLogin}
      >
        {({ handleSubmit, isSubmitting }) => {
          const isAuthenticating = isSubmitting || (isAuthed && !authProfile?.username);

          return (
            <Form onSubmit={handleSubmit}>
              <Form.Item>
                <Label>
                  {t('Username')}
                  <Input
                    sx={{ variant: 'forms.loginInput', pl: '46px' }}
                    name="username"
                    data-testid="username-input"
                    type="text"
                    disabled={isAuthenticating}
                    autoCapitalize="none"
                    placeholder={t('Username')}
                    prefix={<UserOutlined color="mutedDark" size={5} />}
                  />
                </Label>
                <ErrorMessage name="username" data-testid="username-error" />
              </Form.Item>
              <Form.Item>
                <Label>
                  {t('Password')}
                  <Input
                    sx={{ variant: 'forms.loginInput', pl: '46px' }}
                    name="password"
                    data-testid="password-input"
                    placeholder={t('Password')}
                    disabled={isAuthenticating}
                    type={showPassword ? 'text' : 'password'}
                    prefix={<LockOutlined color="mutedDark" size={5} />}
                    suffix={
                      <TogglingEye
                        color="mutedDark"
                        size={5}
                        hidden={!showPassword}
                        onClick={togglePasswordShown}
                        data-testid="toggle-password-visibility-button"
                      />
                    }
                  />
                </Label>
                <ErrorMessage name="password" data-testid="password-error" />
              </Form.Item>
              <Button type="submit" disabled={isAuthenticating} sx={{ p: 2 }} data-testid="login-button">
                {isAuthenticating ? <LoadingOutlined color="background" /> : t('Login')}
              </Button>
            </Form>
          );
        }}
      </Formik>

      <Button
        variant="text"
        sx={{ width: '100%', my: 3 }}
        onClick={navigate}
        data-testid="forget-password-button"
      >
        {t('Forgot your password?')}
      </Button>
    </>
  );
};
