import { Input as ThemeInput, Flex, Box, InputProps as InputPropsBase } from 'theme-ui';
import { FC, forwardRef, ReactNode, useMemo } from 'react';
import { Field, useField } from 'formik';
import { useDebouncedInput } from '@power-ledger/hooks';
import { Stop } from '..';

export type FormInputProps = {
  errorInputVariant?: 'errorInput' | 'errorInputHighlighted';
  hasErrorIndicator?: boolean;
  prefix?: FC | ReactNode | HTMLElement | string;
  suffix?: FC | ReactNode | HTMLElement | string;
  debounce?: boolean;
} & Omit<InputPropsBase, 'prefix'>;

export const FormInput: FC<FormInputProps> = forwardRef(
  (
    {
      suffix,
      prefix,
      name = '',
      hasErrorIndicator = true,
      errorInputVariant = 'errorInputHighlighted',
      debounce = false,
      ...props
    },
    ref
  ) => {
    const [{ onChange, value, onBlur }, meta] = useField({ name });
    const isShowingError = useMemo(() => meta.error && meta.touched, [meta]);
    const { handleFieldChange, localState, handleFieldBlur } = useDebouncedInput({
      onChange,
      name,
      value,
      onBlur,
      shouldDebounce: debounce,
    });
    return (
      <Flex
        sx={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            ...props.sx,
          }}
        >
          <Field
            as={ThemeInput}
            {...props}
            name={name}
            value={localState}
            onChange={handleFieldChange}
            variant={isShowingError ? errorInputVariant : 'input'}
            ref={ref}
            onBlur={handleFieldBlur}
            sx={{
              pl: prefix ? 4 : 3,
              pr: suffix ? 4 : 3,
              position: 'relative',
              ...props.sx,
            }}
            autofillBackgroundColor="foregroundLight"
          />
          {prefix && (
            <Box
              sx={{
                position: 'absolute',
                left: 2,
                color: 'textDarker',
                fontWeight: 'light',
              }}
            >
              {prefix}
            </Box>
          )}
          {hasErrorIndicator && isShowingError && (
            <Stop
              color="warning"
              size={5}
              sx={{
                position: 'absolute',
                right: 2,
              }}
            />
          )}
        </Flex>
        {suffix && (
          <Flex
            sx={{
              flexShrink: 0,
              ml: 2,
              color: 'textDarker',
              fontWeight: 'light',
            }}
          >
            {suffix}
          </Flex>
        )}
      </Flex>
    );
  }
);
