import React, { useEffect, useMemo } from 'react';
import { ThemeProvider } from 'theme-ui';
import { useHostConfig } from '../../contexts/host-config-context';
import { mergeWithDefaultTheme } from '../../styles/defaultTheme';
import { setCurrentTheme } from '../../styles/currentTheme';

export const AppThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme = {} } = useHostConfig();
  const currentTheme = useMemo(() => mergeWithDefaultTheme(theme), [theme]);

  useEffect(() => {
    setCurrentTheme(currentTheme);
  }, [currentTheme]);

  return <ThemeProvider theme={currentTheme} children={children} />;
};
