import React from 'react';
import PropTypes from 'prop-types';

export const P2pSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 220 220"
    aria-labelledby="title-p2p"
    aria-describedby="desc-p2p"
    role="img"
  >
    <title id="title-p2p">P2P icon</title>
    <description id="desc-p2p">A symbol showing two arrows, representing a transaction.</description>
    <path
      d="M36.6 139l18.2-18.1 44.3-44.4L81 58.4.4 139 81 219.6l18.1-18.1zM183.4 81l-18.2
    18.1-44.3 44.4 18.1 18.1L219.6 81 139 .4l-18.1 18.1z"
    />
  </svg>
);

P2pSVG.defaultProps = {
  width: '22px',
  height: '22px',
};

P2pSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
