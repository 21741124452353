import { AxisTick } from '@nivo/axes';
import { DatumValue, ValueFormat } from '@nivo/core';
import { Datum, LineProps } from '@nivo/line';

import {
  areDatesWithinOneDay,
  DAY_MONTH_SHORT,
  getPresetTimeFormat,
  parseDateWithTimeZone,
} from '../../lib/datetime-helpers';
import { TradeUnit, WattHour } from '../../../../types';
import { convertAndFormatUnitValue } from '../../lib/unit';

export type ChartData = Array<Record<string, any>> | Array<Datum>;

/**
 * If chartData has at least one data point which satisfies the following then consider the data valid:
 * 1. has a date represented in milliseconds
 * 2. has at least one other value that is a number
 * E.g. [{ date: 1638331200000, grid: 123.456 }]
 */
export function checkIfChartDataIsValid(chartData: ChartData, keys?: string[]): boolean {
  if (chartData.length === 0 || (Array.isArray(keys) && keys.length === 0)) return false;

  const millisecondDateRegex = /^1\d{12}$/; // 1 followed by 12 digits to represent millisecond date

  return chartData.some((dataPoint) => {
    let hasValidDate = false;
    let hasValidValue = false;

    for (const [key, value] of Object.entries(dataPoint)) {
      if (typeof value !== 'number') continue;

      const isDateValue = millisecondDateRegex.test(value.toString());

      if (isDateValue) {
        hasValidDate = true;

        continue;
      }

      if (!Array.isArray(keys)) {
        hasValidValue = true;

        continue;
      }

      if (keys.includes(key)) hasValidValue = true;
    }

    return hasValidDate && hasValidValue;
  });
}

export const formatAxisLeftValue = (value: number, unit?: TradeUnit, currency?: string, precision = 3) => {
  if (unit === TradeUnit.USAGE) {
    const { formattedNewValue, newUnit } = convertAndFormatUnitValue(value, WattHour.kWh, precision);

    return [formattedNewValue, newUnit].join(' ');
  }

  if (unit === TradeUnit.AMOUNT && currency) {
    return [value, currency].join(' ');
  }

  return value;
};

const baseXScaleProps: LineProps['xScale'] = {
  type: 'point',
};

const minTickWidthWithValue = 20;

type ScaleLineProps = Pick<Required<LineProps>, 'xScale' | 'xFormat' | 'axisBottom'> & {
  isDayFormat: boolean;
};

export interface DetermineTimeScaleProps {
  startDate: string;
  endDate: string;
  timeZone: string;
  width?: number;
  numValues: number;
  isMobile?: boolean;
  isEUTimeFormat?: boolean;
}

export const determineTimeScale = ({
  startDate,
  endDate,
  timeZone,
  width,
  numValues,
  isMobile = false,
  isEUTimeFormat = false,
}: DetermineTimeScaleProps): ScaleLineProps => {
  const isDayFormat = areDatesWithinOneDay(startDate, endDate);
  // const isYearOptionSelected = doDatesSpanOneYear(startDate, endDate);
  //TODO: Find a solution to display monthly breakdown for year filter option
  const isYearOptionSelected = false;
  let monthRenderedMapping: Record<number, number> = {};

  const formatter: ValueFormat<DatumValue> = (date) => {
    if (isDayFormat) {
      return parseDateWithTimeZone(date, timeZone).toLocaleString(getPresetTimeFormat());
    }

    return isEUTimeFormat
      ? `${parseDateWithTimeZone(date, timeZone).toLocaleString({ day: 'numeric' })} ${parseDateWithTimeZone(
          date,
          timeZone
        ).toLocaleString({ month: 'short' })}`
      : parseDateWithTimeZone(date, timeZone).toLocaleString(DAY_MONTH_SHORT);
  };

  const widthWithDefault = width || 400;
  const maximumNumberOfAllowedTicks = widthWithDefault / minTickWidthWithValue;
  const numValuesWithDefault = numValues || 1;
  const tickMod = Math.ceil(numValuesWithDefault / maximumNumberOfAllowedTicks);

  const renderTick: any = (props: any) => {
    if (props.tickIndex % tickMod !== 0) return null;

    if (!isYearOptionSelected || isMobile) return <AxisTick {...props} />;

    const date = parseDateWithTimeZone(props.value, timeZone);

    if (props.tickIndex === 0 && monthRenderedMapping[1]) monthRenderedMapping = {};

    if (monthRenderedMapping[date.month] > 1) return null;

    if (typeof monthRenderedMapping[date.month] === 'undefined') {
      monthRenderedMapping[date.month] = 1;
    } else {
      monthRenderedMapping[date.month] += 1;
    }

    return <AxisTick {...props} />;
  };

  return {
    xScale: {
      ...baseXScaleProps,
    },
    xFormat: formatter,
    axisBottom: {
      format: formatter,
      tickRotation: 45,
      renderTick,
    },
    isDayFormat,
  };
};
