import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const commonTabsStyles: ThemeUIStyleObject = {
  py: 2,
  fontSize: 1,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
};

export const tabs = CustomThemeProperty('tabs', {
  primary: {
    ...commonTabsStyles,
    mx: 2,
    '&:hover': { color: 'primary' },
    borderBottom: 2,
    borderBottomStyle: 'solid',
    borderColor: 'transparent',
    active: { color: 'primary', borderColor: 'primary' },
  },
  secondary: {
    ...commonTabsStyles,
    px: 3,
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderColor: 'mutedDark',
    bg: 'muted',
    color: 'grey',
    '&:hover': { color: 'primary' },
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    active: {
      bg: 'white',
      borderBottomColor: 'white',
      color: 'primary',
    },
  },
  panes: {
    secondary: {
      px: 3,
      py: 2,
    },
  },
});
