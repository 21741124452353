import { useMemo, useState } from 'react';
import { Heading, Paragraph, Link } from 'theme-ui';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Modal } from '..';
import { NewModal } from '../new-ui/new-modal';
import { useNewUIEnabled } from '../../hooks/use-new-ui-enabled';

// FAQ modal that displays the Frequently Asked Questions within its content box
const Faq = () => {
  const [visible, setVisible] = useState(false);
  const isNewUIEnabled = useNewUIEnabled();
  const { t } = useTranslation();

  const faq = useMemo(
    () => (
      <div>
        <Trans t={t} i18nKey="pl.faq">
          <Heading as="h3">How do I set my peer to peer energy price?</Heading>
          <Paragraph my={3}>
            If you are a consumer, you can set the maximum price you are willing to pay for local energy for
            both peak (3pm -9pm daily) and off-peak periods (all other times).
          </Paragraph>
          <Paragraph my={3}>
            If you are a prosumer (consumer who also produces energy), you can set the minimum price you are
            willing to sell your energy to local buyers. These prices are configured by entering your rate in
            the box under the <em>Pricing</em> tab on the Powerledger Platform. These prices are set in cents.
          </Paragraph>
          <Heading as="h3">Can I set my buy price to be higher than the Synergy price?</Heading>
          <Paragraph my={3}>
            Consumers are able to set their maximum buy price for equal to or higher than the grid price if
            they place a higher value on renewable, local energy.
          </Paragraph>
          <Heading as="h3">How does the trading work?</Heading>
          <Paragraph my={3}>
            Prosumers with the lowest sell prices will get first priority for peer to peer trading. If there
            is no leftover demand to satisfy in the trading environment, those with the higher sell prices who
            do not find a buyer for their energy will sell to Synergy. Consumers with higher buy prices will
            get first priority for buying peer to peer energy. If there is more demand than available energy
            in the trading environment, those with lower buy prices will purchase energy from Synergy.
          </Paragraph>
          <Heading as="h3">How does the trading work if prices are the same?</Heading>
          <Paragraph my={3}>
            If users have the same price, they get an equal allocation of the energy. For example, if three
            consumers set their maximum buy price at 9c, all three buyers will get an equal third of the
            energy being sold.
          </Paragraph>
          <Heading as="h3">How do I log into the Powerledger Platform?</Heading>
          <Paragraph my={3}>
            Enter the URL{' '}
            <Link href="https://www.renewnexus.powerledger.io" target="_blank" rel="noreferrer">
              https://www.renewnexus.powerledger.io
            </Link>{' '}
            in your internet browser and use the username and password provided to you.
          </Paragraph>
          <Heading as="h3">What are the default peer to peer energy rates when I log in?</Heading>
          <Paragraph my={3}>
            When you log in to the Powerledger Platform for the first time, your energy rates will be set at
            the same price as Synergy&apos;s Renew Nexus everyday grid rates. This means the maximum rate you
            will pay is 9.9c during peak times and 5.72c during off-peak times, and all your excess solar
            energy will be sold for 4c. You will still be trading with your peers, but paying and receiving
            the same rates as you would if you were buying only from the grid.
          </Paragraph>
          <Heading as="h3">How do I complete an energy trade?</Heading>
          <Paragraph my={3}>
            The Platform will be automatically trading for you at the default rates when the trial begins. If
            you would like to change your rates, click on the <em>Pricing</em> tab on the left hand side of
            the Platform homescreen. Enter the maximum price you are willing to pay for peer to peer energy,
            for the peak period as well as the off-peak period. If you have solar panels, enter the minimum
            price you would be willing to sell your energy at in the peer to peer energy market.
          </Paragraph>
          <Heading as="h3">How do I view my previous peer to peer energy trades?</Heading>
          <Paragraph my={3}>
            By clicking on <em>Transactions</em> on the left hand side of the Platform home screen, you can
            select the date range you would like to view. From there you can see the amount you owe
            (purchased) for P2P energy and grid energy, and the amount you have earned from selling to peers
            and the grid. You can view the trade price paid or received per interval.
          </Paragraph>
          <Heading as="h3">How do I analyse my peer to peer trades?</Heading>
          <Paragraph my={3}>
            By clicking on <em>Transactions</em> on the left hand side of the Platform home screen, you can
            select the date range for which you would like to view and download the CSV file, which will open
            in Microsoft Excel and allow you to analyse your energy data.
          </Paragraph>
          <Heading as="h3">How do I view the blockchain?</Heading>
          <Paragraph my={3}>
            The Blockchain can be viewed by clicking on the <em>Blockchain</em> tab on the left hand side of
            the Platform homescreen. This will open to a new tab which contains the transactions being
            recorded on the blockchain.
          </Paragraph>
          <Heading as="h3">How do I view my energy consumption and generation?</Heading>
          <Paragraph my={3}>
            By clicking <em>Usage</em> on the left hand side of the Platform home screen, you can view the
            amount you are importing (consuming from the grid) and exporting (to the grid) every 30 minute
            interval. You can determine the timeframe for which you would like to view this information.
          </Paragraph>
          <Heading as="h3">How do I update my password?</Heading>
          <Paragraph my={3}>
            By clicking on <em>Account</em> on the left hand side of the Platform home screen, you can update
            your password at any time.
          </Paragraph>
        </Trans>
      </div>
    ),
    [t]
  );

  return (
    <>
      <Button variant="text" onClick={() => setVisible(true)}>
        {t('FAQs')}
      </Button>
      {isNewUIEnabled ? (
        <NewModal
          header={t('Frequently Asked Questions')}
          open={visible}
          onClose={() => setVisible(false)}
          footer={null}
        >
          {faq}
        </NewModal>
      ) : (
        <Modal
          title={t('Frequently Asked Questions')}
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          {faq}
        </Modal>
      )}
    </>
  );
};

export default Faq;
