import { useAuthService } from '@power-ledger/auth-service';
import { useHostConfig } from '../../contexts/host-config-context';

export const useProviderLoaderLogic = () => {
  const { isLoading: isHostConfigLoading } = useHostConfig();
  const { isLoading: isAuthLoading } = useAuthService();

  const isLoading = isHostConfigLoading || isAuthLoading();

  return { isLoading };
};
