import * as api from '../../middleware/api';
import { UPDATE_BLOCKCHAIN_TRANSACTION, UPDATE_BLOCKCHAIN_TRANSFER } from '../constants';
import { errorNotification } from '../../lib/notifications';
import i18n from '../../lib/i18n';
import { isResponseSuccessful } from '../../middleware/api-helpers';

export const updateBlockchainTransaction = (transaction = {}) => ({
  type: UPDATE_BLOCKCHAIN_TRANSACTION,
  transaction,
});

export const updateBlockchainTransfer = (transfer = {}) => ({
  type: UPDATE_BLOCKCHAIN_TRANSFER,
  transfer,
});

// Services
// gets a single blockchain transaction details via its txid
export const getBlockchainTransaction =
  ({ txid }, token = null) =>
  async (dispatch) => {
    const response = await api.getBlockchainTransaction(txid, token);

    if (isResponseSuccessful(response)) {
      dispatch(updateBlockchainTransaction(response.body));

      return true;
    }

    errorNotification({
      code: response.status,
      errorCode: response.body.errorCode,
      description: i18n.t('Could not retrieve transaction at this time!'),
    });

    return false;
  };

export const getBlockchainTransfer =
  ({ txn_signature }, token = null) =>
  async (dispatch) => {
    const response = await api.getBlockchainTransfer(txn_signature, token);

    if (isResponseSuccessful(response)) {
      dispatch(updateBlockchainTransfer({ id: txn_signature, ...response.body }));

      return true;
    }

    errorNotification({
      code: response.status,
      errorCode: response.body.errorCode,
      description: i18n.t('Could not retrieve transaction at this time!'),
    });

    return false;
  };
