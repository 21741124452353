import { useMemo } from 'react';
import { Flex, Box, ThemeUIStyleObject } from 'theme-ui';

export interface CardProps {
  header?: React.FC | React.ReactNode;
  children?: React.ReactNode;
  variant?: string;
  sx?: ThemeUIStyleObject;
  bodyStyles?: { [key: string]: string | number };
  headingStyles?: { [key: string]: string | number };
}

export const Card: React.FC<CardProps> = ({
  header,
  children,
  variant,
  sx,
  bodyStyles,
  headingStyles,
  ...props
}) => {
  const styles = useMemo(() => {
    const compact = variant === 'compact';

    return {
      card: { variant: compact ? 'card.compact' : 'card' },
      header: {
        variant: compact ? 'card.compact.cardHeader' : 'card.cardHeader',
        ...headingStyles,
      },
      body: { variant: compact ? 'card.compact.cardBody' : 'card.cardBody', ...bodyStyles },
    };
  }, [bodyStyles, headingStyles, variant]);

  return (
    <Box {...props} sx={{ ...styles.card, ...sx }}>
      {header && <Flex sx={styles.header}>{header}</Flex>}
      <Box sx={styles.body}>{children}</Box>
    </Box>
  );
};
