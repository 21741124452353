export const safelyParseNumber = (value: string | number) => {
  if (typeof value === 'number') return value;

  // Numeric strings in some languages (e.g. German - de) use commas instead of dots for decimal points.
  // Parsing these strings without replacing the commas with dots can cause unexpected issues.
  return parseFloat(value.replace(',', '.'));
};

export const isNumber = (value?: number | null): value is number => typeof value === 'number';

/**
 * Calculate how many number of decimal places does a number have.
 * @param value the number to be examined
 * @returns The number of decimal places for that value
 */
export const countDecimalPlaces = (value: number) => {
  if (value % 1 !== 0) return value.toString().split('.')[1].length;

  return 0;
};
