import { Theme } from 'theme-ui';

export const fonts: Theme['fonts'] = {
  body: 'CentraNo2, Helvetica, Arial, sans-serif',
  heading: 'inherit',
  monospace: 'Menlo, monospace',
  table: '"CentraNo2", Helvetica, Arial, sans-serif',
};

export const fontSizes: Theme['fontSizes'] = [
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.125rem',
  '1.25rem',
  '1.75rem',
  '3rem',
  '4rem',
  '6rem',
];

export const fontWeights: Theme['fontWeights'] = {
  light: 300,
  body: 400,
  heading: 700,
  bold: 700,
};

export const lineHeights: Theme['lineHeights'] = {
  body: 1.5,
  heading: 1.125,
  none: 0,
};
