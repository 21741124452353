import { memo, useMemo, useRef } from 'react';
import isChromatic from 'chromatic';
import chunk from 'lodash/chunk';
import { useTranslation } from 'react-i18next';

import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { useScreenSize } from '../../../hooks/use-screen-size';

const ITEM_HEIGHT = 20;

interface CustomSymbolProps {
  size: number;
  borderWidth: number;
  borderColor: string;
}

const CustomSymbol = memo(({ size, borderWidth, borderColor }: CustomSymbolProps) => (
  <g>
    <circle r={size / 3} strokeWidth={borderWidth} stroke={borderColor} fill={borderColor} />
  </g>
));

export interface UseStandardChartConfigProps {
  width?: number;
  legendData: any[];
  legendConfigOverrides?: Record<string, unknown>;
  alwaysDisplayLegend?: boolean;
}

const MOBILE_CHARACTER_SIZE = 0.52;
const DESKTOP_CHARACTER_SIZE_BY_LANG: Record<string, number> = {
  default: 0.75,
  es: 0.6,
};

export const useStandardChartConfig = ({
  width,
  legendData,
  legendConfigOverrides,
  alwaysDisplayLegend = false,
}: UseStandardChartConfigProps) => {
  const { i18n } = useTranslation();
  const { colorScheme, chartTheme, margin } = useThemedColorScale();
  const marginRef = useRef(margin);
  const { isSmallDown } = useScreenSize();

  const desktopCharacterSize =
    DESKTOP_CHARACTER_SIZE_BY_LANG[i18n.language] || DESKTOP_CHARACTER_SIZE_BY_LANG.default;

  const legends = useMemo(() => {
    marginRef.current = { ...margin };

    const maxWidth = Math.max(
      ...legendData.map((props) => {
        const { label } = typeof props === 'string' ? { label: props } : props;

        return (
          (label.length + 1) *
            (isSmallDown ? MOBILE_CHARACTER_SIZE : desktopCharacterSize) *
            chartTheme.fontSize +
          chartTheme.symbolSize
        );
      })
    );

    const legendConfig = {
      anchor: 'top-left',
      direction: 'row',
      translateX: 0,
      itemWidth: maxWidth,
      itemHeight: ITEM_HEIGHT,
      itemsSpacing: 5,
      symbolShape: 'square',
      symbolSize: chartTheme.symbolSize,
      translateY: -marginRef.current.top,
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        },
      ],
      ...legendConfigOverrides,
    };

    const allowedLegendItems =
      Math.floor(
        (Number(width) - marginRef.current.left - legendConfig.translateX) / legendConfig.itemWidth
      ) || (alwaysDisplayLegend ? 1 : 0);

    return chunk(legendData, allowedLegendItems).map((item, index, data) => {
      const legendTopAdjustment = ITEM_HEIGHT;
      marginRef.current.top += legendTopAdjustment;
      legendConfig.translateY += -legendTopAdjustment;

      return {
        ...legendConfig,
        legendData: item,
        translateY: (data.length + 1 - index) * -legendConfig.itemHeight,
      };
    });
  }, [
    chartTheme.fontSize,
    chartTheme.symbolSize,
    legendData,
    margin,
    width,
    alwaysDisplayLegend,
    isSmallDown,
    legendConfigOverrides,
    desktopCharacterSize,
  ]);

  return {
    theme: chartTheme,
    colors: colorScheme,
    margin: marginRef.current,
    animate: !isChromatic(),
    pointSymbol: CustomSymbol,
    legends,
  } as any;
};
