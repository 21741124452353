import { FC } from 'react';
import { Box, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';

export interface LoadingOverlayProps {
  loading: boolean;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        variant: 'loadingOverlay',
        opacity: loading ? 0.95 : 0,
      }}
      data-testid={'loading-overlay'}
    >
      <Flex
        sx={{
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t(`Loading...`)}
      </Flex>
    </Box>
  );
};

export default LoadingOverlay;
