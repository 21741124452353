import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const defaultHeaderStyles: ThemeUIStyleObject = {
  px: 4,
  py: 3,
  justifyContent: ['center', 'left'],
};

const defaultBodyStyles: ThemeUIStyleObject = {
  flex: 1,
  bg: 'background',
  p: [3, 4],
};

const defaultCardStyles: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& > *:first-of-type': { borderTopLeftRadius: 4, borderTopRightRadius: 4 },
  '& > *:last-of-type': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
};

export const card = CustomThemeProperty('card', {
  ...defaultCardStyles,
  cardHeader: {
    ...defaultHeaderStyles,
    color: 'background',
    bg: 'secondary',
    py: [1, null, null, 2],
    px: 3,
  },
  cardBody: {
    ...defaultBodyStyles,
  },
  compact: {
    ...defaultCardStyles,
    cardHeader: {
      ...defaultHeaderStyles,
      color: 'background',
      bg: 'secondary',
      py: [0, null, null, 1],
      px: 3,
    },
    cardBody: {
      ...defaultBodyStyles,
      p: 0,
    },
  },
});
