import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BlockchainSVG, NoData } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';
import { Box } from 'theme-ui';

import { useBreakpointIndex } from '@theme-ui/match-media';
import { IconButton, Table, Tooltip, TableLoadingOverlay } from '..';
import BlockchainTransactionModal from '../modal/BlockchainTransaction';
import {
  getBlockchainTransaction,
  getBlockchainTransfer,
  updateBlockchainTransaction,
  updateBlockchainTransfer,
} from '../../states/actions/blockchain';
import { serverSidePaginationOpts } from '../../lib/pagination';
import { convertDatetimeToDate, convertDatetimeToTime } from '../../lib/datetime-helpers';
import { infoNotification } from '../../lib/notifications';
import { useAppDispatch } from '../../hooks/use-app-dispatch';
import { useFeatureSet } from '../../hooks/use-feature-set';
import { useIsTATA } from '../../hooks/use-is-tata';
import { formatAmount, formatCurrency } from '../../lib/helpers';
import { safelyParseNumber } from '../../utils/number';
import { useScreenSize } from '../../hooks/use-screen-size';

const columns = (symbol, onModalOpen, t, isSmallScreen, isTATA, displayZeroCurrencyValueAsDash) => {
  const blockChainIconColumnWidth = isSmallScreen ? 40 : 56;
  const dateColumnWidth = isSmallScreen ? 140 : 176;
  const shouldDisplayCurrencyValue = (value) => {
    return (
      typeof value === 'string' && (displayZeroCurrencyValueAsDash ? safelyParseNumber(value) !== 0 : true)
    );
  };

  return [
    {
      title: '',
      dataIndex: 'blockchain',
      key: 'blockchain',
      width: blockChainIconColumnWidth,
      className: 'transaction-blockchain-icon',
      sticky: true,
      left: 0,
      render: (_, record) => (
        <IconButton
          icon={BlockchainSVG}
          sx={{
            svg: {
              minWidth: 16,
            },
          }}
          buttonProps={{
            onClick: () => {
              if (record.txid || record.txn_signature) {
                onModalOpen(record);
              } else {
                infoNotification({
                  message: t('No blockchain details found for this transaction.'),
                });
              }
            },
          }}
        />
      ),
    },
    {
      title: t('DATE'),
      dataIndex: 'date',
      key: 'key',
      width: dateColumnWidth,
      left: blockChainIconColumnWidth,
      sticky: true,
      render: (record) =>
        record &&
        record !== 'Invalid date' && (
          <Box>
            <strong>{convertDatetimeToDate(record)}</strong>
            &nbsp;
            <span>{convertDatetimeToTime(record)}</span>
          </Box>
        ),
    },
    {
      title: t('BUYER'),
      dataIndex: 'buyer_name',
      key: 'buyer_id',
      ...(isSmallScreen ? {} : { left: blockChainIconColumnWidth + dateColumnWidth, sticky: true }),
    },
    {
      title: t('SELLER'),
      dataIndex: 'seller_name',
      key: 'seller_id',
    },
    {
      title: t('TRADE TYPE'),
      dataIndex: 'type',
      key: 'type',
      render: (type, { buyer_id: buyerId, seller_id: sellerId, rawType }) => (
        <div>
          <span>{`${type}${isTATA && ['GRID_BUY', 'GRID_SELL'].includes(rawType) ? '*' : ''}`}</span>
          {type === 'Peer to peer' && buyerId === sellerId && (
            <Tooltip title={t('tooltip.self-trade')} trigger={['focus', 'hover']}>
              <span>{t('Self trade')}</span>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: t('ENERGY AMOUNT'),
      dataIndex: 'amount_kw',
      key: 'amount_kw',
      render: (amountKw, { rate = null }) => {
        const formattedRate = rate ? ` @ ${symbol}${formatAmount(safelyParseNumber(rate))}/kWh` : '';

        return rate !== null || amountKw !== null ? (
          <div>
            <strong>{formatAmount(safelyParseNumber(amountKw))}</strong>
            <span>{formattedRate}</span>
          </div>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: t('AMOUNT'),
      dataIndex: 'amount',
      key: 'amount',
      render: (record) =>
        shouldDisplayCurrencyValue(record) ? (
          <strong>
            {symbol}
            {formatCurrency(safelyParseNumber(record))}
          </strong>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: t('TAX'),
      dataIndex: 'amount_tax',
      key: 'amount_tax',
      render: (record) =>
        shouldDisplayCurrencyValue(record) ? (
          <strong>
            {symbol}
            {formatCurrency(safelyParseNumber(record))}
          </strong>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: t('TOTAL'),
      dataIndex: 'amount_net_tax',
      key: 'amount_net_tax',
      render: (record) =>
        shouldDisplayCurrencyValue(record) ? (
          <strong>
            {symbol}
            {formatCurrency(safelyParseNumber(record))}
          </strong>
        ) : (
          <span>-</span>
        ),
    },
  ];
};

export const TransactionsTable = (props) => {
  const [visible, setVisible] = useState(false);
  const [requestToken, setRequestToken] = useState(axios.CancelToken.source());
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isTATA = useIsTATA();
  const { includesBuyerTax, displayZeroCurrencyValueAsDash } = useFeatureSet();
  const { isSmallDown } = useScreenSize();
  const [isLoadingBlockchainDetails, setIsLoadingBlockchainDetails] = useState(false);

  const closeModal = () => {
    requestToken.cancel();
    setVisible(false);
    setRequestToken(axios.CancelToken.source());
  };

  const openModal = async ({ txid, txn_signature }) => {
    setIsLoadingBlockchainDetails(true);
    setVisible(true);
    let promiseList = [];

    if (txid) {
      promiseList.push(dispatch(getBlockchainTransaction({ txid }, requestToken)));
    }

    if (txn_signature) {
      promiseList.push(dispatch(getBlockchainTransfer({ txn_signature }, requestToken)));
    }

    const promiseResults = await Promise.allSettled(promiseList);

    setIsLoadingBlockchainDetails(false);

    if (promiseResults.every((result) => result.status === 'rejected' || !result.value)) closeModal();
  };

  const filterTaxColumns = (columns) => {
    const filteredColumns = props.hideTaxColumn
      ? columns.filter((column) => column.dataIndex !== 'amount_tax')
      : columns;

    return !includesBuyerTax
      ? filteredColumns.filter((c) => !['amount_tax', 'amount_net_tax'].includes(c.dataIndex))
      : filteredColumns;
  };

  const resetTransaction = () => {
    dispatch(updateBlockchainTransaction());
    dispatch(updateBlockchainTransfer());
  };

  const {
    data,
    total,
    symbol,
    sparkzConversionRate,
    offset,
    limit,
    isLoading,
    blockchainTransaction,
    onUpdateTransactionsByPageOptions,
  } = props;

  return (
    <>
      <BlockchainTransactionModal
        data={blockchainTransaction}
        symbol={symbol}
        sparkzConversionRate={sparkzConversionRate}
        visible={visible}
        onCloseModal={closeModal}
        onAfterClose={resetTransaction}
        isLoading={isLoadingBlockchainDetails}
      />
      <Table
        locale={{
          emptyText: <NoData description={t('No transactions found')} />,
        }}
        columns={
          total > 0
            ? filterTaxColumns(
                columns(symbol, openModal, t, isSmallDown, isTATA, displayZeroCurrencyValueAsDash)
              )
            : []
        }
        dataSource={total > 0 ? data : []}
        loading={{
          spinning: isLoading,
          indicator: <TableLoadingOverlay active text={t('Loading transactions...')} color="text" />,
        }}
        pagination={serverSidePaginationOpts({
          offset,
          limit,
          total,
          updatePageOpts: onUpdateTransactionsByPageOptions,
        })}
      />
    </>
  );
};

TransactionsTable.defaultProps = {
  data: [],
  total: 0,
  symbol: '$',
  sparkzConversionRate: 100,
  offset: 0,
  limit: 10,
  isLoading: false,
  hideTaxColumn: false,
  blockchainTransaction: {},
};

TransactionsTable.propTypes = {
  data: PropTypes.array,
  total: PropTypes.number,
  symbol: PropTypes.string,
  sparkzConversionRate: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
  hideTaxColumn: PropTypes.bool,
  blockchainTransaction: PropTypes.shape({}),
  onUpdateTransactionsByPageOptions: PropTypes.func.isRequired,
  loadingBar: PropTypes.any,
};
