import * as api from '../../middleware/api';
import { RESET_PREF_TRADE_OFFERS, UPDATE_PREF_TRADE_OFFERS } from '../constants';
import { getSignType } from '../selectors/pref-trade';
import { errorNotification, successNotification, successRegex } from '../../lib/notifications';
import { formatTimeZone } from '../../lib/datetime-helpers';
import i18n from '../../lib/i18n';

export const updatePrefTradeOffers = ({ offers, totalOffers }) => ({
  type: UPDATE_PREF_TRADE_OFFERS,
  offers,
  totalOffers,
});

export const resetPrefTrade = { type: RESET_PREF_TRADE_OFFERS };

const createSortString = (sortBy, sortOrder) => {
  if (!sortBy) return '&sort=creationTime,desc';

  const creationTime = sortBy === 'status' ? '&sort=creationTime,desc' : '';

  return `&sort=${sortBy},${sortOrder}${creationTime}`;
};

// Services
export const getPrefTradeOffers =
  (props, token = null) =>
  async (dispatch) => {
    const { offset, limit, sortBy, sortOrder } = props;

    const request = {
      page: offset / limit,
      size: limit,
      sort: createSortString(sortBy, sortOrder),
    };

    const { status, body } = await api.getOffers(request, token);
    if (successRegex.test(status) && body) {
      const { results, total } = body;
      dispatch(updatePrefTradeOffers({ offers: results, totalOffers: total }));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve offers at this time!'),
      });
    }
    return status !== 499;
  };

export const getMeterOffers =
  (props, token = null) =>
  async (dispatch) => {
    const { offset, limit, sortBy, sortOrder, isBuyer, meterUid } = props;

    const request = {
      userType: isBuyer ? 'BUYER' : 'SELLER',
      page: offset / limit,
      size: limit,
      sort: createSortString(sortBy, sortOrder),
      ...(meterUid ? { meterUid } : {}),
    };

    const { status, body } = await api.getOffers(request, token);

    if (successRegex.test(status) && body) {
      const { results, total } = body;
      dispatch(updatePrefTradeOffers({ offers: results, totalOffers: total }));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve offers at this time!'),
      });
    }

    return status !== 499;
  };

export const validatePpaOfferDuplication = (errorMessage) => {
  return errorMessage.includes(
    'PPA with overlapping timeline and same buyer meter and seller meter already exists'
  );
};

export const createOffer = async (props, token = null) => {
  const {
    buyerMeterUid,
    buyerMeterDisplayName = '',
    sellerMeterUid,
    sellerMeterDisplayName = '',
    offerPeriod,
    amount,
    energyPrice,
    timeZone, // acceptanceConfig, cancellationConfig
    ppaType,
  } = props;
  const [offerStart, offerEnd] = offerPeriod;

  const request = {
    buyerMeterUid,
    buyerMeterDisplayName,
    sellerMeterUid,
    sellerMeterDisplayName,
    amount,
    energyPrice,
    ppaType,
    effectiveFrom: formatTimeZone(offerStart, timeZone),
    effectiveTo: formatTimeZone(offerEnd, timeZone),
    token,
  };

  const { status, body } = await api.createOffer(request);

  if (successRegex.test(status)) {
    successNotification({ description: i18n.t('Offer has been created!') });
  } else {
    const isDuplicatedPpaOffer = validatePpaOfferDuplication(body.errorMessage);

    errorNotification({
      code: status,
      errorCode: isDuplicatedPpaOffer ? '' : body.errorCode,
      description: isDuplicatedPpaOffer
        ? i18n.t(
            'Unable to create offer: Direct Trade already exists with the same buyer and same active period.'
          )
        : body.errorMessage,
      duration: 7,
    });
  }

  return status;
};

export const signOffer = async (props, token = null) => {
  const { isSeller, signingType, agreed } = props;
  const { status, body } = await api.signOffer(props, token);
  const signType = getSignType(signingType, agreed, isSeller);

  if (successRegex.test(status)) {
    successNotification({
      description: {
        update: i18n.t('Offer has been updated'),
        accept: i18n.t('Offer has been accepted'),
        decline: i18n.t('Offer has been declined'),
        withdraw: i18n.t('Offer has been withdrawn'),
        cancel: i18n.t('Offer has been cancelled'),
      }[signingType],
    });
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: {
        update: i18n.t('Could not update offer at this time!'),
        accept: i18n.t('Could not accept offer at this time!'),
        decline: i18n.t('Could not decline offer at this time!'),
        withdraw: i18n.t('Could not withdraw offer at this time!'),
        cancel: i18n.t('Could not cancel offer at this time!'),
      }[signType],
    });
  }

  return successRegex.test(status);
};

export const getOfferUserDecisions = async (offerUid, token = null) => {
  const { status, body } = await api.getOfferUserDecisions(offerUid, token);

  let decisions = [];

  if (successRegex.test(status) && body) {
    decisions = body;
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not get offer details at this time!'),
    });
  }

  return decisions;
};

export const getAllocation = async (token = null) => {
  const { status, body } = await api.getAllocation(token);

  let allocation = 0;

  if (successRegex.test(status) && body != null) {
    allocation = body;
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not get allocation at this time!'),
    });
  }

  return allocation;
};
