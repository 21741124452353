import { Input as ThemeInput, Flex, Box, InputProps as InputPropsBase, ThemeUIStyleObject } from 'theme-ui';
import { FC, forwardRef, ReactNode } from 'react';
import { Field, useField } from 'formik';

export * from './PhoneInput';

export type InputProps = {
  prefix?: FC | ReactNode | HTMLElement | string;
  suffix?: FC | ReactNode | HTMLElement | string;
  suffixStyles?: ThemeUIStyleObject;
} & Omit<InputPropsBase, 'prefix'>;

export const Input: FC<InputProps> = forwardRef(
  ({ suffix, prefix, name = '', sx, suffixStyles, ...props }, ref) => {
    const [_, meta] = useField({ name });
    return (
      <Flex
        sx={{
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {prefix && (
          <Box
            sx={{
              zIndex: 1,
              position: 'absolute',
              left: 2,
            }}
          >
            {prefix}
          </Box>
        )}
        <Field
          as={ThemeInput}
          {...props}
          name={name}
          variant={meta.error && meta.touched ? 'errorInput' : 'input'}
          innerRef={ref}
          sx={{
            pl: prefix ? '40px' : 2,
            pr: suffix ? '40px' : 2,
            position: 'relative',
            ...sx,
          }}
        />
        {suffix && (
          <Box
            sx={{
              position: 'absolute',
              right: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...suffixStyles,
            }}
          >
            {suffix}
          </Box>
        )}
      </Flex>
    );
  }
);
