import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tradeTypeLabels } from '../../../../states/selectors/transactions';
import { useFeatureSet } from '../../../../hooks/use-feature-set';
import { TradeType, TradeUnit } from '../../../../../../types';
import { useForbiddenTransactionTypes } from '../useForbiddenTransactionTypes';
import { UseTradingSummaryProps, AvailableTransactionSummaryField } from './trading-summary.types';

export const useTradingSummary = ({ transactionSummaries }: UseTradingSummaryProps) => {
  const { t } = useTranslation();
  const { includesBuyerTax, includesSellerTax } = useFeatureSet();
  const translatedLabels = tradeTypeLabels(t);
  const forbiddenTypes = useForbiddenTransactionTypes();
  const allowedTransactionSummaries = useMemo(
    () => transactionSummaries.filter((summary) => !forbiddenTypes.includes(summary.name)),
    [transactionSummaries, forbiddenTypes]
  );

  const viewStrategy = useMemo(
    () => ({
      [TradeUnit.AMOUNT]: {
        valueKey: {
          [TradeType.BOUGHT]: includesBuyerTax ? 'boughtNetTax' : 'bought',
          [TradeType.SOLD]: includesSellerTax ? 'soldNetTax' : 'sold',
        },
      },
      [TradeUnit.USAGE]: {
        valueKey: {
          [TradeType.BOUGHT]: 'imported',
          [TradeType.SOLD]: 'exported',
        },
      },
    }),
    [includesBuyerTax, includesSellerTax]
  );

  const viewTransactionSummaries = useMemo(
    () =>
      allowedTransactionSummaries.map((allowedTransactionSummary) => {
        const viewBought =
          allowedTransactionSummary[
            viewStrategy[TradeUnit.AMOUNT].valueKey[TradeType.BOUGHT] as AvailableTransactionSummaryField
          ];
        const viewSold =
          allowedTransactionSummary[
            viewStrategy[TradeUnit.AMOUNT].valueKey[TradeType.SOLD] as AvailableTransactionSummaryField
          ];

        return {
          ...allowedTransactionSummary,
          viewBought,
          viewSold,
        };
      }),
    [allowedTransactionSummaries, viewStrategy]
  );

  return {
    viewStrategy,
    allowedTransactionSummaries,
    transactionSummaries: viewTransactionSummaries,
    translatedLabels,
  };
};
