import React from 'react';
import PropTypes from 'prop-types';

export const BalanceSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 220 220"
    aria-labelledby="title-balance"
    aria-describedby="desc-balance"
    role="img"
  >
    <title id="title-balance">Balance icon</title>
    <description id="desc-balance">A symbol representing a line chart.</description>
    <path
      d="M138.4 105.3l-18.1-18.2L102.1 69l-72.5 72.6 18.1 18.1 54.4-54.4 18.2 18.1 18.1 18.2L211
    69l-18.1-18.1zM0 0h10v220H0z"
    />
    <path d="M0 210h220v10H0z" />
  </svg>
);

BalanceSVG.defaultProps = {
  width: '22px',
  height: '22px',
};

BalanceSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
