import { FC } from 'react';
import { Textarea as ThemeTextarea } from 'theme-ui';

export type TextareaProps = {
  rows?: number;
  name?: string;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
};

export const Textarea: FC<TextareaProps> = ({ value, rows, name, placeholder, ...props }) => (
  <ThemeTextarea value={value} rows={rows} name={name} placeholder={placeholder} {...props} />
);
