import { Suspense } from 'react';
import { Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { ExclamationCircleOutlined, LoadingOutlined } from '../../components';
import { fallbackLng } from '../../lib/i18n';
import { hostDocs } from './hostDocs';

export const WrappedDynamicFile = ({
  lng,
  hostname = 'pl',
  translationKey,
}: {
  lng: string;
  hostname?: string;
  translationKey: string;
}) => {
  const File = useDocSelector(lng, translationKey, hostname);

  return <Suspense fallback={<LoadingOutlined />}>{File ? <File /> : <MessageMissingFile />}</Suspense>;
};

export default WrappedDynamicFile;

const useDocSelector = (lng: string, key: string, host: string) => {
  lng = lng.split('-')[0];

  if (hostDocs[lng] && hostDocs[lng][`${host}.${key}`]) {
    return hostDocs[lng][`${host}.${key}`];
  }

  if (hostDocs[fallbackLng] && hostDocs[fallbackLng][`${host}.${key}`]) {
    return hostDocs[fallbackLng][`${host}.${key}`];
  }

  if (hostDocs.default[`${host}.${key}`]) {
    return hostDocs.default[`${host}.${key}`];
  }

  // Utilise global fallback of document (aka PL Documents)
  if (hostDocs[lng] && hostDocs[lng][`default.${key}`]) {
    return hostDocs[lng][`default.${key}`];
  }

  if (hostDocs[fallbackLng] && hostDocs[fallbackLng][`default.${key}`]) {
    return hostDocs[fallbackLng][`default.${key}`];
  }

  if (hostDocs.default[`default.${key}`]) {
    return hostDocs.default[`default.${key}`];
  }

  return null;
};

const MessageMissingFile = () => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <ExclamationCircleOutlined size="48px" />
      <Flex>{t('File could not be loaded')}</Flex>
    </Flex>
  );
};
