import { FC } from 'react';
import { calendarSVG } from '@power-ledger/assets';
import { createIconStyles } from '@power-ledger/components';
import { IconProps, IconWrapper } from './../common';

export const CalendarIcon: FC<IconProps> = (props) => (
  <IconWrapper
    icon={calendarSVG}
    sx={createIconStyles({ ...props, color: 'none', stroke: 'currentColor', width: 4, height: 4 })}
    {...props}
  />
);
