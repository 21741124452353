import { ThemeUIStyleObject } from '@theme-ui/css';
import { Box, BoxProps, Flex } from 'theme-ui';

export type TooltipProps = {
  children?: React.ReactNode;
  title: React.ReactNode;
  trigger?: string;
  triggerOff?: string;
  placement?: string;
  visible?: boolean;
  html?: boolean;
  disabled?: boolean;
  innerSx?: ThemeUIStyleObject;
} & BoxProps;

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  trigger,
  triggerOff,
  placement,
  disabled,
  sx,
  html,
  ...rest
}) => {
  return (
    <Box sx={{ display: 'inline-block', cursor: 'pointer', ...sx }} {...rest}>
      <Flex data-tooltip-content={title} data-tooltip-id="tooltip">
        {children}
      </Flex>
    </Box>
  );
};
