import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { Modal, LoadingOverlay } from '..';

const DeleteNotificationConfirmation = ({ visible, isSubmitting, closeModal, deleteNotification }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Delete notification')}
      visible={visible}
      onOk={deleteNotification}
      okButtonContent={t('Confirm')}
      okDisabled={isSubmitting}
      onCancel={closeModal}
    >
      <LoadingOverlay active={isSubmitting} text={<span>{t('Deleting notification...')}</span>} />
      <div>
        <label>{t('Please note')}</label>
        <br />
        <span>
          <Trans t={t} i18nKey="note.delete-notification-warning">
            You will not be able to recover the scheduled notification once it has been deleted. Click&nbsp;
            <strong>Confirm</strong> to delete.
          </Trans>
        </span>
      </div>
    </Modal>
  );
};

DeleteNotificationConfirmation.defaultProps = {
  visible: false,
  isSubmitting: false,
};

DeleteNotificationConfirmation.propTypes = {
  visible: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};

export default DeleteNotificationConfirmation;
