import { createContext, useContext } from 'react';
import { HostConfigContextType } from './host-config-context-types';

export const HostConfigContext = createContext<HostConfigContextType | null>(null);

/**
 * Returns the current host configuration stored in its related context
 * @param throwNotLoaded When true, the function will throw if the trading group config has not been loaded
 */
function useHostConfig(throwNotLoaded?: false): HostConfigContextType;
function useHostConfig(throwNotLoaded?: true): Omit<HostConfigContextType, 'tradingGroup' | 'config'> & {
  tradingGroup: Exclude<HostConfigContextType['tradingGroup'], undefined>;
  config: Exclude<HostConfigContextType['config'], undefined>;
};
function useHostConfig(throwNotLoaded = false) {
  const ctx = useContext(HostConfigContext);
  if (!ctx) {
    throw new Error('Unable to find Trading Group Config context');
  }

  if (throwNotLoaded === true && (!ctx.tradingGroup || !ctx.config)) {
    throw new Error('Trading Group not loaded when expected');
  }

  return ctx;
}

export { useHostConfig };
