import TweenOne from 'rc-tween-one';

const fadeInOutEnterAnimations = (onComplete?: () => void) => [
  { opacity: 0, duration: 0 },
  { opacity: 1, duration: 500, onComplete },
];

const fadeInOutExitAnimations = (onComplete?: () => void) => [
  { opacity: 1, duration: 0 },
  { opacity: 0, duration: 500, onComplete },
];

interface FadeInOutAnimatorProps {
  isEnter?: boolean;
  paused?: boolean;
  className?: string;
  children?: React.ReactNode;
  onComplete?: () => void;
}

export const FadeInOutAnimator: React.FC<FadeInOutAnimatorProps> = ({
  isEnter = false,
  paused = false,
  className = '',
  children,
  onComplete,
  ...props
}) => (
  <TweenOne
    className={className}
    paused={paused}
    animation={isEnter ? fadeInOutEnterAnimations(onComplete) : fadeInOutExitAnimations(onComplete)}
    {...props}
  >
    {children}
  </TweenOne>
);
