import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import * as aggregatedExcessApi from '../../middleware/aggregated-excess';
import { isResponseSuccessful } from '../../middleware/api-helpers';
import { errorNotification } from '../../lib/notifications';

export const getDayAheadAggregatedExcess = createAsyncThunk(
  'aggregatedExcess/getDayAheadAggregatedExcess',
  async ({ date }: { date: DateTime }, { rejectWithValue }) => {
    const response = await aggregatedExcessApi.getDayAheadAggregatedExcess(date);

    if (isResponseSuccessful(response)) {
      return response.body;
    }

    errorNotification({ description: 'Could not get day ahead aggregated excess demand' });

    return rejectWithValue(false);
  }
);

export const getRealtimeAggregatedExcess = createAsyncThunk(
  'aggregatedExcess/getRealtimeAggregatedExcess',
  async ({ date }: { date: DateTime }, { rejectWithValue }) => {
    const response = await aggregatedExcessApi.getRealtimeAggregatedExcess(date);

    if (isResponseSuccessful(response)) {
      return response.body;
    }

    errorNotification({ description: 'Could not get real-time aggregated excess demand' });

    return rejectWithValue(false);
  }
);
