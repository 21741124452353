import { Children, cloneElement } from 'react';
import { Flex, Box } from 'theme-ui';

import { HeaderProps } from './types';

const mobileSiderHeight = 56;

const headingStyles = {
  fontSize: 3,
  marginBottom: 1,
};

const subheadingStyles = {
  fontSize: 0,
  fontWeight: 'light',
};

export const Header: React.FC<HeaderProps> = ({ children, subheader, heading, subheading }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
    <Box
      sx={{
        mt: [mobileSiderHeight, null, 0],
      }}
    >
      {subheader}
    </Box>
    <Box
      sx={{
        variant: 'layouts.headers',
        display: ['none', null, 'flex'],
      }}
    >
      <Box data-testid="header-left-section">
        <Box sx={headingStyles}>{heading}</Box>
        <Box sx={subheadingStyles}>{subheading}</Box>
      </Box>
      <Flex sx={{ alignItems: 'center' }}>
        {Children.map(children, (child) => !!child && <Box sx={{ ml: 3 }}>{cloneElement(child)}</Box>)}
      </Flex>
    </Box>
  </Box>
);
