import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageLayout } from '../components';
import { useHostConfig } from '../contexts/host-config-context';
import { useFeatureSet } from '../hooks/use-feature-set';
import { getHostInvitationDisplay } from '../lib/get-host-invitation-display';
import { useNewUIEnabled } from '../hooks/use-new-ui-enabled';
import AdminRoutes from './admin';
import { PublicRoutes } from './public';
import { ResetPasswordText } from './public/reset-password/defaultText';
import UserRoutes from './user';
import { SentryRoute, RestrictedRoute } from './common/custom-routes';

const Loading = () => <div />;

export const useNavRoutes = ({
  hasCommunityMap,
  hasEnergyMix,
  hasLP2P,
  hasLP2PMeter,
  hasPrefTrade,
  hasPricing,
  hasProsumer,
  hasProducer,
  hasRankings,
  hasStatementOfUse,
  hasVPP,
  isAdmin,
  t,
}: {
  hasCommunityMap: boolean;
  hasEnergyMix: boolean;
  hasLP2P: boolean;
  hasLP2PMeter: boolean;
  hasPrefTrade: boolean;
  hasPricing: boolean;
  hasProsumer: boolean;
  hasProducer: boolean;
  hasRankings: boolean;
  hasStatementOfUse: boolean;
  hasVPP: boolean;
  isAdmin: boolean;
  t: any;
}) => {
  const {
    showVisionEnergyMixPage,
    showVisionBillingPage,
    showNewDashboardPage,
    showNewUsagePage,
    showNewTransactionsPage,
    showNewTradingPage,
    hideDashboardPage,
    hideTradingOverviewTab,
  } = useFeatureSet();
  const { hostHasFlag } = useHostConfig(true);
  const hostnameIsVB = hostHasFlag('isVB');

  /**
   * Initialise our default trading route
   * This will be what the user first lands on when they select trading
   **/
  let tradingRoute = 'trading/direct-trades';

  if (isAdmin) {
    tradingRoute = 'trading/trades';
  }

  /**
   * Initialise our default loyalty route
   * This will be what the user first lands on when they select loyalty
   **/
  let loyaltyRoute = hasLP2PMeter ? 'loyalty/user-campaigns' : 'loyalty/campaigns';

  if (isAdmin) {
    loyaltyRoute = 'loyalty/campaigns';
  }

  /**
   * End users who have preferential trading and pricing features enabled should see
   * /trading/pricing by default when hitting the Trading link.
   */
  if (hasPrefTrade && hasPricing) {
    tradingRoute = 'trading/pricing';
  }

  return [
    showNewDashboardPage
      ? {
          path: 'dashboard',
          title: t('Dashboard'),
        }
      : {
          path: 'dashboard',
          title: hostnameIsVB ? t('Beer') : t('Dashboard'),
        },
    showNewTradingPage
      ? {
          path: isAdmin ? 'trading/pricing' : hideTradingOverviewTab ? 'trading/pricing' : 'trading/overview',
          title: t('Trading'),
        }
      : {
          path: hasPrefTrade ? tradingRoute : 'pricing',
          title: hasPrefTrade ? t('Trading') : t('Pricing'),
          hide: !hasPricing && !hasPrefTrade,
        },
    showVisionEnergyMixPage
      ? {
          path: 'your-energy-mix/consumption',
          title: t('Your Energy Mix'),
        }
      : {
          path: 'your-energy-mix',
          title: t('Your Energy Mix'),
          hide: !hasEnergyMix,
        },
    showNewTransactionsPage
      ? {
          path: 'transactions/overview',
          title: t('Transactions'),
        }
      : {
          path: 'transactions',
          title: t('Transactions'),
          hide: hostnameIsVB,
        },
    showNewUsagePage
      ? {
          path: 'usage/overview',
          title: t('Usage'),
        }
      : {
          path: 'usage',
          title: hostnameIsVB ? t('Energy') : t('Usage'),
        },
    showVisionBillingPage
      ? {
          path: 'billing',
          title: t('Billing'),
        }
      : {
          path: 'billing',
          title: t('Billing'),
          hide: !hasStatementOfUse,
        },
    {
      path: 'battery-sharing',
      title: t('Battery Sharing'),
      hide: !hasVPP,
    },
    {
      path: 'rankings',
      title: t('Rankings'),
      hide: !hasRankings || isAdmin,
    },
    {
      path: 'community-map',
      title: t('Community Map'),
      hide: !hasCommunityMap,
    },
    // Hide loyalty link if group doesn't have LP2P
    // Also hide if user only has a consumer meter with canOfferLoyaltyP2p set to false
    {
      path: loyaltyRoute,
      title: t('Loyalty'),
      hide: !hasLP2P || (!isAdmin && !hasLP2PMeter && !hasProducer && !hasProsumer),
    },
  ].filter((route) => (hideDashboardPage ? route.path !== 'dashboard' : true));
};

export const PublicRouter = () => {
  const { t } = useTranslation();
  const { hostHasFlag } = useHostConfig();
  const isVB = hostHasFlag('isVB');

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <SentryRoute exact path="/" component={PublicRoutes.LoginUser} />
        <SentryRoute path="/login/callback" component={PublicRoutes.LoginCallback} />
        <SentryRoute path="/login-mfa" component={PublicRoutes.LoginUserMFASMS} />
        <SentryRoute path="/enable-mfa" component={PublicRoutes.EnableMFA} />
        <SentryRoute path="/forgot-password" component={PublicRoutes.ForgotPassword} />
        <SentryRoute exact path="/update-password/:token" component={PublicRoutes.ResetPassword} />
        <SentryRoute
          exact
          path="/invite/:token"
          component={() => (
            <PublicRoutes.ResetPassword
              resetPasswordText={getHostInvitationDisplay(t, isVB) as ResetPasswordText}
            />
          )}
        />
        <SentryRoute exact path="/terms" component={PublicRoutes.AcceptTerms} />
        <SentryRoute render={() => <Redirect to="/" />} />
      </Switch>
    </Suspense>
  );
};

export const AdminRouter = () => {
  const {
    hasVPP,
    hasPrefTrade,
    hasEstimates,
    hasPricing,
    hasNotifications,
    hasEnergyMix,
    hasLP2P,
    hasIOAManagement,
    hasExcessDemand,
    hasExcessEnergy,
    showVisionEnergyMixPage,
    showNewUsagePage,
    showNewTransactionsPage,
    showNewTradingPage,
    hideDashboardPage,
    hideEnergyMixEmissionsTab,
    hideUsageRankingTab,
    hideTradingPeerToPeerTab,
    hideTradingPreferentialTradingTab,
    hideTransactionsSavingsTab,
    hideTransactionsTaxAndFeeTab,
    hideTransactionsBlockchainRecordsTab,
  } = useFeatureSet();
  const isNewUIEnabled = useNewUIEnabled();
  const defaultRoutePath = getDefaultRoutePath(hideDashboardPage, isNewUIEnabled);

  return (
    <Suspense fallback={<Loading />}>
      <PageLayout>
        <Switch>
          <SentryRoute exact path="/" render={() => <Redirect to={defaultRoutePath} />} />
          {!hideDashboardPage ? <SentryRoute path="/dashboard" component={AdminRoutes.Dashboard} /> : null}
          <RestrictedRoute
            conditional={hasPricing && !hasPrefTrade}
            path="/pricing"
            component={AdminRoutes.Pricing}
          />
          <RestrictedRoute
            conditional={hasPrefTrade}
            path="/trading/trades"
            component={AdminRoutes.PreferentialTrading}
          />
          <RestrictedRoute
            conditional={hasPrefTrade && hasPricing}
            path="/trading/pricing"
            component={AdminRoutes.Pricing}
          />
          <RestrictedRoute
            conditional={hasIOAManagement}
            path="/trading/ioa-data"
            component={AdminRoutes.IOAManagement}
          />
          <RestrictedRoute
            conditional={hasExcessDemand}
            path="/trading/excess-demand"
            component={AdminRoutes.ExcessDemand}
          />
          <RestrictedRoute
            conditional={hasExcessEnergy}
            path="/trading/excess-energy"
            component={AdminRoutes.ExcessEnergy}
          />
          <RestrictedRoute
            conditional={hasEnergyMix}
            exact
            path="/your-energy-mix"
            component={AdminRoutes.EnergyMix}
          />
          <SentryRoute exact path="/transactions" component={AdminRoutes.Transactions} />
          <SentryRoute path="/transactions/records" component={AdminRoutes.TransactionsRecords} />
          <SentryRoute exact path="/usage" component={AdminRoutes.Usage} />
          <SentryRoute path="/usage/readings" component={AdminRoutes.UsageReadings} />
          <RestrictedRoute
            conditional={hasEstimates && !hasIOAManagement}
            path="/usage/estimates"
            component={AdminRoutes.UsageEstimates}
          />
          <SentryRoute path="/billing" component={AdminRoutes.Billing} />
          <RestrictedRoute
            exact
            conditional={hasLP2P}
            path="/loyalty/campaigns"
            component={AdminRoutes.LoyaltyP2PCampaigns}
          />
          <RestrictedRoute
            exact
            conditional={hasLP2P}
            path="/loyalty/campaigns/:id"
            component={AdminRoutes.LoyaltyP2PCampaignDetails}
          />
          {/* TODO: The vouchers page is not yet implemented for admins, temporarily route to campaigns page */}
          <RestrictedRoute
            exact
            conditional={hasLP2P}
            path="/loyalty/vouchers"
            component={AdminRoutes.LoyaltyP2PCampaigns}
          />
          <RestrictedRoute
            conditional={hasVPP}
            exact
            path="/battery-sharing"
            component={AdminRoutes.BatterySharing}
          />
          <RestrictedRoute
            conditional={hasVPP}
            path="/battery-sharing/events"
            component={AdminRoutes.BatterySharingEvents}
          />
          <RestrictedRoute
            conditional={hasVPP}
            path="/battery-sharing/records"
            component={AdminRoutes.BatterySharingRecords}
          />
          <RestrictedRoute
            conditional={hasNotifications}
            path="/notifications"
            component={AdminRoutes.AppNotifications}
          />
          <SentryRoute path="/account" component={AdminRoutes.Account} />
          <SentryRoute path="/community-map" component={AdminRoutes.CommunityMap} />
          {/* Define new UI routes so that it is not redirected back to home */}
          {showVisionEnergyMixPage ? <Route path="/your-energy-mix/consumption" /> : null}
          {showVisionEnergyMixPage && !hideEnergyMixEmissionsTab ? (
            <Route path="/your-energy-mix/emissions" />
          ) : null}
          {showNewTransactionsPage ? <Route path="/transactions/overview" /> : null}
          {showNewTransactionsPage ? <Route path="/transactions/records" /> : null}
          {showNewTransactionsPage && !hideTransactionsBlockchainRecordsTab ? (
            <Route path="/transactions/block-chain-records" />
          ) : null}
          {showNewTransactionsPage && !hideTransactionsSavingsTab ? (
            <Route path="/transactions/savings" />
          ) : null}
          {showNewTransactionsPage && !hideTransactionsTaxAndFeeTab ? (
            <Route path="/transactions/tax-and-fees" />
          ) : null}
          {showNewUsagePage ? <Route path="/usage/overview" /> : null}
          {showNewUsagePage ? <Route path="/usage/readings" /> : null}
          {showNewUsagePage && !hideUsageRankingTab ? <Route path="/usage/ranking" /> : null}
          {showNewTradingPage && !hideTradingPreferentialTradingTab ? (
            <Route path="/trading/preferential-trading" />
          ) : null}
          {showNewTradingPage && !hideTradingPeerToPeerTab ? <Route path="/trading/peer-to-peer" /> : null}
          <SentryRoute render={() => <Redirect to="/" />} />
        </Switch>
      </PageLayout>
    </Suspense>
  );
};

export const UserRouter = () => {
  const {
    hasVPP,
    hasPrefTrade,
    hasProsumer,
    hasConsumer,
    hasProducer,
    hasEstimates,
    hasPricing,
    hasNotifications,
    hasEnergyMix,
    hasLP2P,
    hasLP2PMeter,
    hasIOAManagement,
    hasExcessDemand,
    hasExcessEnergy,
    showVisionEnergyMixPage,
    showNewUsagePage,
    showNewTransactionsPage,
    showNewTradingPage,
    hideDashboardPage,
    hideEnergyMixEmissionsTab,
    hideUsageRankingTab,
    hideTradingOverviewTab,
    hideTransactionsSavingsTab,
    hideTransactionsBlockchainRecordsTab,
  } = useFeatureSet();

  const { hostHasFlag } = useHostConfig(true);
  const isVB = hostHasFlag('isVB');
  const isNewUIEnabled = useNewUIEnabled();
  const defaultRoutePath = getDefaultRoutePath(hideDashboardPage, isNewUIEnabled);

  return (
    <Suspense fallback={<Loading />}>
      <PageLayout>
        <Switch>
          <SentryRoute exact path="/" render={() => <Redirect to={defaultRoutePath} />} />
          {!hideDashboardPage ? <SentryRoute path="/dashboard" component={UserRoutes.Dashboard} /> : null}
          <RestrictedRoute
            conditional={hasPricing && !hasPrefTrade}
            path="/pricing"
            component={UserRoutes.Pricing}
          />
          <RestrictedRoute
            conditional={hasPrefTrade && hasPricing}
            path="/trading/pricing"
            component={UserRoutes.Pricing}
          />
          <RestrictedRoute
            conditional={hasPrefTrade && (hasProsumer || hasConsumer || hasProducer)}
            path="/trading/direct-trades"
            component={UserRoutes.DirectTrades}
          />
          <RestrictedRoute
            conditional={hasIOAManagement}
            path="/trading/ioa-data"
            component={AdminRoutes.IOAManagement}
          />
          <RestrictedRoute
            conditional={hasExcessDemand}
            path="/trading/excess-demand"
            component={UserRoutes.ExcessDemand}
          />
          <RestrictedRoute
            conditional={hasExcessEnergy}
            path="/trading/excess-energy"
            component={UserRoutes.ExcessEnergy}
          />
          <RestrictedRoute
            conditional={hasLP2P && (hasProducer || hasProsumer || hasLP2PMeter)}
            path="/loyalty"
            component={UserRoutes.LoyaltyP2PRoutes}
          />
          <RestrictedRoute
            conditional={hasEnergyMix}
            exact
            path="/your-energy-mix"
            component={UserRoutes.EnergyMix}
          />
          <RestrictedRoute
            conditional={!isVB}
            exact
            path="/transactions"
            component={UserRoutes.Transactions}
          />
          <RestrictedRoute
            conditional={!isVB}
            path="/transactions/records"
            component={UserRoutes.TransactionsRecords}
          />
          <SentryRoute exact path="/usage" component={UserRoutes.Usage} />
          <RestrictedRoute conditional={!isVB} path="/usage/readings" component={UserRoutes.UsageReadings} />
          <RestrictedRoute
            conditional={!isVB && hasEstimates && !hasIOAManagement}
            path="/usage/estimates"
            component={UserRoutes.UsageEstimates}
          />
          <RestrictedRoute conditional={!isVB} path="/billing" component={UserRoutes.Billing} />
          <RestrictedRoute
            conditional={hasVPP}
            exact
            path="/battery-sharing"
            component={UserRoutes.BatterySharing}
          />
          <RestrictedRoute
            conditional={hasVPP}
            path="/battery-sharing/records"
            component={UserRoutes.BatterySharingRecords}
          />
          <RestrictedRoute
            conditional={hasNotifications}
            path="/notifications"
            component={UserRoutes.AppNotifications}
          />
          <SentryRoute path="/account" component={UserRoutes.Account} />
          <SentryRoute path="/rankings" component={UserRoutes.Rankings} />
          <SentryRoute path="/community-map" component={UserRoutes.CommunityMap} />
          {/* Define new UI routes so that it is not redirected back to home */}
          {showVisionEnergyMixPage ? <Route path="/your-energy-mix/consumption" /> : null}
          {showVisionEnergyMixPage && !hideEnergyMixEmissionsTab ? (
            <Route path="/your-energy-mix/emissions" />
          ) : null}
          {showNewTransactionsPage ? <Route path="/transactions/overview" /> : null}
          {showNewTransactionsPage ? <Route path="/transactions/records" /> : null}
          {showNewTransactionsPage && !hideTransactionsSavingsTab ? (
            <Route path="/transactions/savings" />
          ) : null}
          {showNewTransactionsPage && !hideTransactionsBlockchainRecordsTab ? (
            <Route path="/transactions/block-chain-records" />
          ) : null}
          {showNewUsagePage ? <Route path="/usage/overview" /> : null}
          {showNewUsagePage ? <Route path="/usage/readings" /> : null}
          {showNewUsagePage && !hideUsageRankingTab ? <Route path="/usage/ranking" /> : null}
          {showNewTradingPage && !hideTradingOverviewTab ? <Route path="/trading/overview" /> : null}
          <SentryRoute render={() => <Redirect to="/" />} />
        </Switch>
      </PageLayout>
    </Suspense>
  );
};

export const SplashPageRouter = () => (
  <Switch>
    <SentryRoute exact path="/" component={PublicRoutes.EndOfProjectSplash} />
    <SentryRoute render={() => <Redirect to="/" />} />
  </Switch>
);

export const getDefaultRoutePath = (hideDashboardPage: boolean, isNewUIEnabled: boolean) => {
  if (!hideDashboardPage) {
    return '/dashboard';
  }

  return isNewUIEnabled ? '/transactions/overview' : '/transactions';
};
