/** @jsxImportSource theme-ui */
import { ResponsiveBar } from '@nivo/bar';
import React, { useCallback, useMemo } from 'react';
import { Box } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { useDynamicGraphTicks, useStandardBarChartConfig } from '..';
import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { formatAmount } from '../../../lib/helpers';
import { CO2Saving, Weight } from '../../../../../types';
import { convertAndFormatUnitValue } from '../../../lib/unit';

interface Co2ChartProps {
  header?: React.ReactNode;
  values: CO2Saving[];
  colors?: Record<string, string>;
}

const DATA_KEYS = ['EMISSIONS', 'SAVINGS'];

const useAggregatedValues = (values: CO2Saving[]) => ({
  gramsSaved: useMemo(() => Math.round(values.reduce((prev, value) => prev + value.grams, 0)), [values]),
  baselineTotal: useMemo(() => Math.round(values.reduce((prev, value) => prev + value.base, 0)), [values]),
});

const deriveColorSchemeWithOverrides = (gramsSaved: number, colors?: Record<string, string>) => {
  const co2Colors: {
    [key: string]: string;
  } = {
    SAVINGS: gramsSaved > 0 ? '#e3fdc2' : '#d44c29',
    EMISSIONS: '#B2B2B2',
  };

  return colors ? DATA_KEYS.map((key) => colors[key]) : DATA_KEYS.map((key) => co2Colors[key]);
};

export const Co2Chart = ({ header, values, colors }: Co2ChartProps) => {
  const { t } = useTranslation();

  const { baselineTotal, gramsSaved } = useAggregatedValues(values);
  const gramsEmitted = baselineTotal - gramsSaved;

  const { newUnit: unitsSaved } = useMemo(
    () => convertAndFormatUnitValue(gramsSaved, Weight.g),
    [gramsSaved]
  );
  const { newUnit: unitsEmitted } = useMemo(
    () => convertAndFormatUnitValue(gramsEmitted, Weight.g),
    [gramsEmitted]
  );
  const { newUnit: baselineTotalUnits } = useMemo(
    () => convertAndFormatUnitValue(baselineTotal, Weight.g),
    [baselineTotal]
  );

  const colorSchemeWithOverrides = deriveColorSchemeWithOverrides(gramsSaved, colors);

  const translateTypeName = useCallback(
    (key: string) => {
      switch (key) {
        case 'EMISSIONS':
          return gramsSaved > 0 ? t('Total Emissions') : t('Baseline Emissions');

        case 'SAVINGS':
          return t('Total Savings');

        default:
          return t('Unknown');
      }
    },
    [gramsSaved, t]
  );

  const { chartConfig } = useThemedColorScale();

  const chartData = [
    {
      EMISSIONS: gramsSaved > 0 ? gramsEmitted : baselineTotal,
      SAVINGS: gramsSaved,
      date: 1,
    },
  ];

  const { ref, width } = useDynamicGraphTicks(chartData, chartConfig.bar.tickWidth);

  const { legends, ...commonBarProps } = useStandardBarChartConfig({
    width,
    legendData: DATA_KEYS,
  });

  const modifiedCommonBarProps = useMemo(() => {
    const {
      theme: { axis, ...theme },
      ...restOfProps
    } = commonBarProps as any;

    return {
      ...restOfProps,
      // Omit axis from theme as it is not needed
      theme,
    };
  }, [commonBarProps]);

  const realLegend = useMemo(
    () =>
      legends.map(({ legendData, ...legend }: any) => ({
        ...legend,
        data: legendData.map((key: string) => ({
          id: key,
          label: translateTypeName(key),
          color: colorSchemeWithOverrides[DATA_KEYS.indexOf(key)],
        })),
        itemWidth: 140,
      })),
    [legends, translateTypeName, colorSchemeWithOverrides]
  );

  const getBarLabel = useCallback(
    ({ value, id }: { value: number; id: 'EMISSIONS' | 'SAVINGS' }) => {
      const units: { EMISSIONS: string; SAVINGS: string } = {
        EMISSIONS: gramsSaved > 0 ? unitsEmitted : baselineTotalUnits,
        SAVINGS: unitsSaved,
      };
      const { newValue: unitAdjustedValue } = convertAndFormatUnitValue(value, Weight.g);

      return value ? `${formatAmount(unitAdjustedValue, 2)} ${units[id]}` : '';
    },
    [gramsSaved, unitsSaved, unitsEmitted, baselineTotalUnits]
  );

  const getLabelTextColor = ({ data }: any) => (data.value > 0 ? '#00000077' : '#FFFFFFDD');

  return (
    <Box mb={4}>
      {header && <Box sx={{ variant: 'statistic.header' }}>{header}</Box>}
      <div sx={{ height: 80 + 20 * legends.length }} ref={ref}>
        <ResponsiveBar
          {...modifiedCommonBarProps}
          data={chartData}
          keys={DATA_KEYS}
          margin={{
            top: 30 + 20 * legends.length,
            bottom: 0,
            right: 0,
            left: 0,
          }}
          padding={0}
          indexBy="date"
          layout="horizontal"
          indexScale={{ type: 'band', round: true }}
          borderColor="transparent"
          enableGridY={false}
          enableGridX={false}
          axisBottom={false}
          axisLeft={false}
          labelTextColor={getLabelTextColor}
          isInteractive={false}
          enableLabel
          labelSkipWidth={32}
          label={getBarLabel}
          colors={colorSchemeWithOverrides}
          legends={realLegend}
          reverse={gramsSaved <= 0}
        />
      </div>
    </Box>
  );
};

export default Co2Chart;
