import { createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from '../../../../types';
import { getDayAheadAggregatedExcess, getRealtimeAggregatedExcess } from '../actions/excess-energy';

export interface FetchAggregatedExcessState {
  status: AsyncStatus;
}

const initialState = {
  dayAhead: {
    status: AsyncStatus.IDLE,
  },
  realTime: {
    status: AsyncStatus.IDLE,
  },
};

const aggregatedExcessSlice = createSlice({
  name: 'aggregatedExcess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDayAheadAggregatedExcess.pending, (state) => {
      state.dayAhead.status = AsyncStatus.LOADING;
    });
    builder.addCase(getDayAheadAggregatedExcess.fulfilled, (state) => {
      state.dayAhead.status = AsyncStatus.SUCCEEDED;
    });
    builder.addCase(getDayAheadAggregatedExcess.rejected, (state) => {
      state.dayAhead.status = AsyncStatus.FAILED;
    });
    builder.addCase(getRealtimeAggregatedExcess.pending, (state) => {
      state.realTime.status = AsyncStatus.LOADING;
    });
    builder.addCase(getRealtimeAggregatedExcess.fulfilled, (state) => {
      state.realTime.status = AsyncStatus.SUCCEEDED;
    });
    builder.addCase(getRealtimeAggregatedExcess.rejected, (state) => {
      state.realTime.status = AsyncStatus.FAILED;
    });
  },
});

export default aggregatedExcessSlice.reducer;
