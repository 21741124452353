import { useCallback, useEffect, useState } from 'react';

import { getUnreadNotifications } from '../states/actions/app-notifications';
import { useFeatureSet } from './use-feature-set';

const DEFAULT_INTERVAL_AMOUNT = 50_000;

export const useUnreadNotifications = (intervalAmount = DEFAULT_INTERVAL_AMOUNT) => {
  const [numberOfUnread, setNumberOfUnread] = useState(0);
  const { hasNotifications } = useFeatureSet();

  const fetchUnreadNotifications = useCallback(async () => {
    if (hasNotifications) {
      const { total } = await getUnreadNotifications();

      setNumberOfUnread(total);
    }
  }, [hasNotifications]);

  useEffect(() => {
    const interval = setInterval(fetchUnreadNotifications, intervalAmount);

    return () => {
      clearInterval(interval);
    };
  }, [fetchUnreadNotifications, intervalAmount]);

  useEffect(() => {
    fetchUnreadNotifications();
  }, [fetchUnreadNotifications]);

  return { numberOfUnread };
};
