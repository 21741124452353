// checks if the selected meter, period or date-range has changed to re-render component
export const checkControlUpdates = (currentProps, nextProps) =>
  currentProps.selectedMeter !== nextProps.selectedMeter ||
  currentProps.period !== nextProps.period ||
  currentProps.startDate !== nextProps.startDate ||
  currentProps.endDate !== nextProps.endDate ||
  currentProps.mfaEnabledDate !== nextProps.mfaEnabledDate;

export const checkPaginationUpdates = (currentState, nextState) =>
  currentState.visible !== nextState.visible ||
  currentState.csvVisible !== nextState.csvVisible ||
  currentState.filterBy !== nextState.filterBy ||
  currentState.sortBy !== nextState.sortBy ||
  currentState.sortOrder !== nextState.sortOrder ||
  currentState.offset !== nextState.offset ||
  currentState.limit !== nextState.limit ||
  currentState.exportState !== nextState.exportState;

export const checkSearchOptionUpdates = (currentState, nextState) =>
  currentState.isSearchModalOpen !== nextState.isSearchModalOpen;

// constantly checks if component needs to be re-rendered based on current state and props
export const checkCommonUpdates = (currentProps, nextProps, currentState, nextState) =>
  !!(
    currentState.mfaLoaded !== nextState.mfaLoaded ||
    currentState.accountLoaded !== nextState.accountLoaded ||
    currentState.dataLoaded !== nextState.dataLoaded ||
    currentState.contentLoaded !== nextState.contentLoaded ||
    currentState.pageLoaded !== nextState.pageLoaded ||
    currentState.isLoading !== nextState.isLoading ||
    currentState.loadingText !== nextState.loadingText ||
    (currentProps.meterGroup !== nextProps.meterGroup && nextProps.meterGroup.id) ||
    currentProps.meters !== nextProps.meters ||
    checkControlUpdates(currentProps, nextProps) ||
    (currentProps.selectedMeter &&
      nextProps.selectedMeter &&
      currentProps.selectedMeter !== nextProps.selectedMeter) ||
    currentProps.includeEstimates !== nextProps.includeEstimates ||
    currentProps.isEstimate !== nextProps.isEstimate
  );

// gets the recently searched meters from local storage
export const getRecentlySearchedMeters = () => {
  const searchedMeters = localStorage.getItem('searchedMeters');
  let recentMeters = [];

  try {
    // recentMeters = searchedMeters;
    recentMeters = JSON.parse(`${searchedMeters}`);
  } catch (err) {
    console.error(err);
    localStorage.removeItem('searchedMeters');
  }

  return recentMeters;
};

// gets the existing recently searched meters from local storage and append new meter to the list
// if the searched meters are not found in local storage, set a new list with the new meter
export const updateRecentlySearchedMeters = (searchedMeter) => {
  const sessionMeters = getRecentlySearchedMeters();

  if (!sessionMeters || !sessionMeters.length) {
    localStorage.setItem('searchedMeters', JSON.stringify([searchedMeter]));
  } else {
    const existingMeterIdx = sessionMeters.findIndex((v) => v.uid === searchedMeter.uid);

    if (existingMeterIdx !== -1) {
      const existingMeter = sessionMeters[existingMeterIdx];
      sessionMeters.splice(existingMeterIdx, 1);
      sessionMeters.push(existingMeter);
    } else {
      sessionMeters.push(searchedMeter);
      if (sessionMeters.length > 3) sessionMeters.shift();
    }

    localStorage.setItem('searchedMeters', JSON.stringify(sessionMeters));
  }
};
