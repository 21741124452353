import { Box, Text } from 'theme-ui';

import { StatusBadgeProps } from './status-badge.types';
import { STATUS_BADGE_COLOR_MAPPING } from './status-badge.utils';

export const StatusBadge: React.FC<StatusBadgeProps> = ({ statusText, sx, color, variant = 'filled' }) => {
  const { backgroundColor, dotColor, textColor } = STATUS_BADGE_COLOR_MAPPING[color];

  return (
    <Box
      sx={{
        backgroundColor: variant === 'filled' ? backgroundColor : 'transparent',
        ...(variant === 'filled'
          ? {
              paddingY: 1,
              paddingX: 2,
            }
          : {}),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: 4,
        gap: 2,
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: variant === 'filled' ? dotColor : backgroundColor,
        }}
      />
      <Text
        sx={{
          color: variant === 'filled' ? textColor : 'textPrimary',
          fontSize: variant === 'filled' ? 1 : 2,
        }}
      >
        {statusText}
      </Text>
    </Box>
  );
};
