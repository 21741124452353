import { useMemo, useRef } from 'react';
import DatePickerLib, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Box } from 'theme-ui';
import { DateTime } from 'luxon';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';

import { parseDate, UndeterminedDate } from '../../lib/datetime-helpers';
import { getDatePickerStyles } from './date-picker.styles';

registerLocale('de', de);
registerLocale('es', es);
registerLocale('fr', fr);

export interface DatePickerProps extends ReactDatePickerProps {
  id?: string;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  dateValue?: UndeterminedDate;
  onChange: (value: Date) => void;
  timeZone?: string;
  fullWidth?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  id,
  onChange,
  disabled = false,
  dateValue,
  timeZone,
  fullWidth = false,
  excludeDates,
  minDate,
  maxDate,
  dateFormat = 'MM/dd/yyyy',
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const valueToUse = useMemo(
    () => new Date(parseDate(dateValue, timeZone).toISODate()),
    [dateValue, timeZone]
  );
  const containerRef = useRef(null);
  const containerSx = useMemo(() => getDatePickerStyles({ fullWidth }), [fullWidth]);

  return (
    <Box ref={containerRef} sx={containerSx}>
      <DatePickerLib
        id={id}
        selected={valueToUse}
        onChange={(value) => {
          if (!value) return;

          const dateTimeObject = DateTime.fromJSDate(value).setZone(timeZone, { keepLocalTime: true });

          onChange?.(dateTimeObject.toJSDate());
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        showPopperArrow={false}
        disabled={disabled}
        locale={locale}
        excludeDates={excludeDates}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
      />
    </Box>
  );
};
