import { throttle } from 'lodash';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Box } from 'theme-ui';
import { InfiniteScrollProps } from './infinite-scroll.types';

/**
 * Criteria: Make Sure the Child Div is Scrollable
 * This is made to also incorporate MenuListComponent of Select Component
 * @example
 *  <InfiniteScrollable {...props}>
 *  <ScrollableDiv>....</ScrollableDiv>
 *  </InfiniteScrollable>
 *
 * @param children
 * @param hasMoreData
 * @param getMoreData
 * @returns
 */
export const InfiniteScroll: FC<InfiniteScrollProps> = ({ children, hasMoreData, getMoreData }) => {
  const scrollRefParent = useRef<HTMLDivElement | null>(null);

  const handler = useMemo(
    () =>
      throttle((e: any) => {
        // If the person has reached the bottom of div
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight === 0) {
          if (hasMoreData && getMoreData) getMoreData();
        }
      }, 100),
    [hasMoreData, getMoreData]
  );

  useEffect(() => {
    /**
     * Select the first child div
     */
    const target = scrollRefParent.current?.querySelector('div');
    handler({
      target,
    });
    if (target) {
      target.addEventListener('scroll', handler);
    }
    return () => {
      handler.cancel();
      target && target.removeEventListener('scroll', handler);
    };
  }, [handler]);

  return <Box ref={scrollRefParent}>{children}</Box>;
};
