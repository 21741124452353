import { useCallback, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StoreStatus, Meter, MeterGroup } from '../../../types';
import { toggleEstimate } from '../states/actions/settings';
import { Layout } from '../components';
import { useAppDispatch } from '../hooks/use-app-dispatch';
import { useAppSelector } from '../hooks/use-app-selector';
import { useCancelToken } from '../hooks/use-cancel-token';
import { useFeatureSet } from '../hooks/use-feature-set';
import { usePrevious } from '../hooks/use-previous';
import { useTokenMeters } from '../hooks/use-token-meters';
import { useIsAdmin } from '../hooks/use-is-admin';
import { initializeControlsFromTimeZone } from '../states/slices/controls';
import { getMeterGroup, getMeters, updateMeters } from '../states/slices/meters';
import groupHasFeature from '../lib/group-has-feature';
import { useNewUIEnabled } from '../hooks/use-new-ui-enabled';
import Footer from './footer';
import Header from './header';
import { NewSidebar, Sidebar } from './common/sidebar';
import { LayoutMain } from './common/layout-main';
import NewHeader from './new-header/new-header';

export const Home = () => {
  const { t } = useTranslation();
  const { hasIOAManagement } = useFeatureSet();
  const isNewUIEnabled = useNewUIEnabled();
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [meterGroupOrControlInitialized, setMeterGroupOrControlInitialized] = useState(false);
  const requestToken = useCancelToken(null, [meterGroupOrControlInitialized]);
  const dispatch = useAppDispatch();
  const meterGroup = useAppSelector(
    ({ meters: metersState }: any) => metersState.meterGroup,
    shallowEqual
  ) as MeterGroup;
  const timeZone = useAppSelector(
    ({ meters: metersState }) => metersState.meterGroup && metersState.meterGroup.timeZone,
    shallowEqual
  );
  const meters: Meter[] = useAppSelector(({ meters: metersState }) => metersState.meters, shallowEqual);
  const metersStatus: StoreStatus = useAppSelector(
    ({ meters: metersState }) => metersState.metersStatus,
    shallowEqual
  );
  const selectedMeter = useAppSelector(
    ({ controls }) => controls.selectedMeter,
    shallowEqual
  ) as Meter | null;
  const { isAdmin } = useIsAdmin();
  const { hasFAQ } = useFeatureSet();

  const onMenuCollapse = useCallback(
    (menuState?: boolean) => setMenuCollapsed(menuState || !menuCollapsed),
    [menuCollapsed]
  );

  const updateWidth = useCallback(
    () => window.innerWidth > 992 && !menuCollapsed && onMenuCollapse(),
    [menuCollapsed, onMenuCollapse]
  );

  const prevUpdateWidth = usePrevious(updateWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    updateWidth();
    if (prevUpdateWidth && prevUpdateWidth !== updateWidth) {
      window.removeEventListener('resize', prevUpdateWidth as any);
    }
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [prevUpdateWidth, updateWidth]);

  useEffect(
    () => () => {
      requestToken.cancel();
    },
    [requestToken]
  );

  useEffect(() => {
    let mounted = true;

    if (meterGroupOrControlInitialized) return;

    // If regular user without a meter group or if admin
    if ((!isAdmin && !meterGroup.id) || isAdmin) {
      dispatch(getMeterGroup()).then(({ payload }) => {
        const meterGroup = (payload as { updatedMeterGroup: MeterGroup }).updatedMeterGroup;
        const hasIOAManagement = groupHasFeature('IOA_MANAGEMENT', meterGroup);

        dispatch(initializeControlsFromTimeZone(meterGroup.timeZone, hasIOAManagement));

        if (!mounted) return;

        setMeterGroupOrControlInitialized(true);
      });
      // Else (user with meter group)
      // controls initialized here instead of in getMeterGroup action
    } else {
      dispatch(initializeControlsFromTimeZone(timeZone, hasIOAManagement));

      if (!mounted) return;

      setMeterGroupOrControlInitialized(true);
    }

    return () => {
      mounted = false;
    };
  }, [dispatch, isAdmin, meterGroupOrControlInitialized, meterGroup.id, timeZone, hasIOAManagement]);

  const tokenMeters = useTokenMeters();

  useEffect(() => {
    if (!meterGroupOrControlInitialized) return;

    if (!meters.length) {
      if (isAdmin) {
        dispatch(
          (getMeters as any)({
            props: {
              tradingGroupName: meterGroup.name,
            },
            token: requestToken,
          })
        );
      } else {
        dispatch(updateMeters(tokenMeters));
      }
    }

    if (meterGroup.features && meterGroup.features.includes('ESTIMATES')) {
      dispatch(toggleEstimate(true));
    }
  }, [
    dispatch,
    isAdmin,
    meterGroupOrControlInitialized,
    meterGroup.features,
    meterGroup.name,
    meters.length,
    requestToken,
    tokenMeters,
  ]);

  const areMetersInitialized = metersStatus === StoreStatus.INITIALIZED;

  const sidebarProps = {
    menuCollapsed,
    collapseMenu: (menuState?: boolean) => onMenuCollapse(menuState),
  };

  return (
    <Layout
      sider={isNewUIEnabled ? <NewSidebar {...sidebarProps} /> : <Sidebar {...sidebarProps} />}
      footer={<Footer hasFAQ={hasFAQ} />}
      header={
        isNewUIEnabled ? (
          <NewHeader
            collapseMenu={(menuState?: boolean) => onMenuCollapse(menuState)}
            menuCollapsed={menuCollapsed}
          />
        ) : (
          <Header />
        )
      }
      scrollDisabled={!menuCollapsed}
    >
      <LayoutMain
        areMeterGroupOrControlsInitialized={meterGroupOrControlInitialized}
        areMetersInitialized={areMetersInitialized}
        meterGroup={meterGroup}
        isAdmin={isAdmin}
        selectedMeter={selectedMeter}
        loadingText={t('Fetching meters...')}
      />
    </Layout>
  );
};

export default Home;
