import { shallowEqual, useSelector } from 'react-redux';

import { useTimeFrameDateData } from '../hooks';

export const useUsageChartData = () => {
  const { timeZone, startDate, endDate } = useTimeFrameDateData();
  const usages = useSelector(({ usage }: any) => usage.usages, shallowEqual);
  return {
    timeZone,
    startDate,
    endDate,
    usages,
  };
};
