import { UPDATE_USAGE_STATISTICS, UPDATE_READINGS, RESET_USAGE } from '../constants';
import { initialState } from '../store/initial-state';

// Usage reducer
const usage = (state = initialState.usage, action) => {
  switch (action.type) {
    case UPDATE_USAGE_STATISTICS:
      return {
        ...state,
        usageImport: action.usageImport,
        usageExport: action.usageExport,
        imported: action.imported,
        exported: action.exported,
        usages: action.usages,
      };
    case UPDATE_READINGS:
      return {
        ...state,
        readings: action.readings,
        totalReadings: action.totalRecord,
      };
    case RESET_USAGE:
      return initialState.usage;
    default:
      return state;
  }
};

export default usage;
