import { ThemeUIStyleObject } from 'theme-ui';

import { CustomThemeProperty } from './types';

const sharedStyles: ThemeUIStyleObject = {
  alignItems: 'center',
  justifyContent: 'center',
};

export const recBadge = CustomThemeProperty('recBadge', {
  ...sharedStyles,
  bg: 'backgroundDark',
  color: 'textDarker',
  fontSize: 1,
  width: 6,
  height: 6,
  borderRadius: 6,
  large: {
    ...sharedStyles,
    fontSize: 4,
    bg: 'foreground',
    color: 'text',
    width: 9,
    height: 9,
    borderRadius: 7,
  },
});
