export enum TransactionType {
  UNSUPPORTED = 'UNSUPPORTED',
  GRID = 'GRID',
  CATCH_ALL = 'CATCH_ALL',
  CATCH_UP = 'CATCH_UP',
  VPP = 'VPP',
  P2P = 'P2P',
  DYNAMIC = 'DYNAMIC',
  ALLOCATION = 'ALLOCATION',
  ADJUSTMENT = 'ADJUSTMENT',
  LOYALTY_P2P = 'LOYALTY_P2P',
  CORRECTION_GRID = 'CORRECTION_GRID',
  PPA = 'PPA',
  SELF_TRADE = 'SELF_TRADE',
  COLLECTIVE_SELF_TRADE = 'COLLECTIVE_SELF_TRADE',
  PER_WH_FEE_AH = 'PER_WH_FEE_AH',
  PER_DAY_FEE_AH = 'PER_DAY_FEE_AH',
  PER_WH_FEE_PL = 'PER_WH_FEE_PL',
  PER_DAY_FEE_PL = 'PER_DAY_FEE_PL',
  PER_WH_FEE_OTHER = 'PER_WH_FEE_OTHER',
  PER_DAY_FEE_OTHER = 'PER_DAY_FEE_OTHER',
}

export const TATA_GRID_TYPE = 'TATA_GRID_TYPE';

export enum TransactionTypeTranslationKeyMapping {
  GRID = 'grid',
  CATCH_ALL = 'grid',
  CATCH_UP = 'missed',
  VPP = 'vpp',
  P2P = 'p2p',
  DYNAMIC = 'p2p',
  ALLOCATION = 'renewableAllocation',
  LOYALTY_P2P = 'LOYALTY_P2P',
  PPA = 'ppa',
  SELF_TRADE = 'SELF_TRADE',
  COLLECTIVE_SELF_TRADE = 'COLLECTIVE_SELF_TRADE',
  PER_WH_FEE_AH = 'PER_WH_FEE_AH',
  PER_DAY_FEE_AH = 'PER_DAY_FEE_AH',
  PER_WH_FEE_PL = 'PER_WH_FEE_PL',
  PER_DAY_FEE_PL = 'PER_DAY_FEE_PL',
  PER_WH_FEE_OTHER = 'PER_WH_FEE_OTHER',
  PER_DAY_FEE_OTHER = 'PER_DAY_FEE_OTHER',
  // the following keys might be outdated
  CORRECTION = 'correction',
  PER_WH_FEE = 'kWhFee',
  PER_DAY_FEE = 'dailyFee',
  PREFERENTIAL_TRADE = 'preferentialTrade',
}

export interface Transaction {
  boughtAmount: number;
  boughtAmountNetTax: number;
  boughtAmountTax: number;
  boughtKw: number;
  soldAmount: number;
  soldAmountNetTax: number;
  soldAmountTax: number;
  soldKw: number;
  type: TransactionType;
}

export interface TransactionSummary {
  bought: number;
  boughtNetTax: number;
  boughtTax: number;
  exported: number;
  imported: number;
  name: TransactionType;
  sold: number;
  soldNetTax: number;
  soldTax: number;
}

export interface TopTrader {
  amountKw: number;
  meterDisplayName: string;
  percentageOfTotal: number;
}

export interface TransactionInterval {
  dateTime: string;
  estimate: boolean;
  transactions: Transaction[];
}
