import { FC, ReactNode } from 'react';
import { Box } from 'theme-ui';

export type SiderProps = {
  logo?: ReactNode;
  poweredByLogo?: ReactNode;
  menu?: any;
};

export const Sider: FC<SiderProps> = ({ logo, poweredByLogo, children }) => (
  <Box
    sx={{
      variant: 'layouts.siders',
      height: '100vh',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 0,
        maxHeight: '100%',
        p: 2,
        height: '100%',
      }}
    >
      {logo}
    </Box>
    <Box
      sx={{
        flex: 1,
      }}
    >
      {children}
    </Box>
    {poweredByLogo}
  </Box>
);
