import { lazy } from 'react';

const Dashboard = lazy(() => import('./dashboard'));
const Transactions = lazy(() => import('./transactions'));
const TransactionsRecords = lazy(() => import('./transactions-records'));
const Usage = lazy(() => import('./usage'));
// @ts-ignore
const UsageReadings = lazy(() => import('../common/usage-readings'));
const UsageEstimates = lazy(() => import('./usage-estimates'));
const Billing = lazy(() => import('./billing'));
const BatterySharing = lazy(() => import('./battery-sharing'));
const BatterySharingEvents = lazy(() => import('./battery-sharing-events'));
const BatterySharingRecords = lazy(() => import('./battery-sharing-records'));
const PreferentialTrading = lazy(() => import('./preferential-trading'));
const Pricing = lazy(() => import('./pricing'));
const Account = lazy(() => import('./account'));
const AppNotifications = lazy(() => import('./app-notifications'));
const CommunityMap = lazy(() => import('../../components/community-map'));
const EnergyMix = lazy(() => import('../common/energy-mix'));
const LoyaltyP2PCampaigns = lazy(() => import('./loyalty-p2p/campaigns'));
const LoyaltyP2PCampaignDetails = lazy(() => import('../common/loyalty-p2p/campaign-details'));
const IOAManagement = lazy(() => import('../common/ioa-management'));
const ExcessDemand = lazy(() => import('../common/aggregated-excess/excess-demand'));
const ExcessEnergy = lazy(() => import('../common/aggregated-excess/excess-energy'));

const AdminRoutes = {
  Dashboard,
  Transactions,
  TransactionsRecords,
  Usage,
  UsageReadings,
  UsageEstimates,
  Billing,
  BatterySharing,
  BatterySharingEvents,
  BatterySharingRecords,
  PreferentialTrading,
  Pricing,
  Account,
  AppNotifications,
  CommunityMap,
  EnergyMix,
  LoyaltyP2PCampaigns,
  LoyaltyP2PCampaignDetails,
  IOAManagement,
  ExcessDemand,
  ExcessEnergy,
};

export default AdminRoutes;
