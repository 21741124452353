import { useTheme } from '@power-ledger/hooks';
import { getColor } from '@theme-ui/color';
import { FC, ReactElement } from 'react';
import { Tooltip as ReactToolTip } from 'react-tooltip';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { Info } from '../icons';

export type TooltipProps = {
  id: string;
  content: ReactElement;
  delayHide?: number;
  sx?: ThemeUIStyleObject;
};

export const Tooltip: FC<TooltipProps> = ({ id, content, delayHide, sx }) => {
  const { theme } = useTheme();

  return (
    <Box sx={{ ml: 1, mb: -2, ...sx }}>
      <ReactToolTip
        id={id}
        delayHide={delayHide}
        style={{ backgroundColor: getColor(theme, 'secondaryDark') }}
        render={() => <Box sx={{ maxWidth: 300, whiteSpace: 'pre-line' }}>{content}</Box>}
      />
      <Info color="primary" size={4} noSvgPositioning data-tip={content} data-for={id} />
    </Box>
  );
};
