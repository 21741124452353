import { FC, ReactNode, useMemo } from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import { Windmills, House } from '@power-ledger/assets';
import { Button, Card, LoadingOverlay } from '../index';

const imageComponents: Record<string, any> = {
  Windmills,
  House,
};

export type InfoCardProps = {
  title: string | ReactNode;
  subtitle?: string;
  buttonText?: string;
  onClick?: () => void;
  loading?: boolean;
  image?: string;
  sx?: ThemeUIStyleObject;
  noImage?: boolean;
  buttonInverted?: boolean;
  buttonSmall?: boolean;
};
export type InfoCardType = FC<InfoCardProps>;

export const InfoCard: InfoCardType = ({
  title,
  subtitle,
  buttonText,
  onClick,
  image,
  loading = false,
  sx = {},
  noImage,
  buttonInverted,
  buttonSmall,
}) => {
  const InfoCardImage = useMemo(
    () => (image && imageComponents[image] ? imageComponents[image] : imageComponents.Windmills),
    [image]
  );
  const buttonVariant = useMemo(() => {
    if (buttonInverted) {
      if (buttonSmall) return 'pillInverted.compact';
      return 'pillInverted';
    }
    if (buttonSmall) return 'pill.compact';
    return 'pill';
  }, [buttonInverted, buttonSmall]);

  return (
    <Card sx={{ position: 'relative', height: 260, ...sx }}>
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {subtitle && <Box sx={{ color: 'textDarker', mb: 3, fontSize: 1 }}>{subtitle}</Box>}
        <Box sx={{ fontSize: 3, fontWeight: 'bold', fontFamily: 'MintGroteskV08' }}>{title}</Box>
        <Flex
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: noImage ? 'flex-start' : 'space-between',
            alignItems: 'flex-end',
            flexGrow: 1,
          }}
        >
          {onClick && (
            <Button variant={buttonVariant} onClick={onClick}>
              {buttonText}
            </Button>
          )}
          {!noImage && (
            <Flex sx={{ width: '50%', justifyContent: 'flex-end' }}>
              <InfoCardImage />
            </Flex>
          )}
        </Flex>
      </Flex>

      <LoadingOverlay loading={loading} />
    </Card>
  );
};
