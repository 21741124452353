import { DateTime } from 'luxon';

import {
  formatRequestDate,
  parseDate,
  parseDateWithTimeZone,
  requestDateFormat,
} from '../../lib/datetime-helpers';
import { formatAmount } from '../../lib/helpers';
import groupHasFeature from '../../lib/group-has-feature';
import { checkCommonUpdates } from './controls';

// constantly checks if parent component needs to be re-rendered based on current state and props
export const checkUpdates = (currentProps, nextProps, currentState, nextState) => {
  if (checkCommonUpdates(currentProps, nextProps, currentState, nextState)) {
    return true;
  }

  if (JSON.stringify(currentProps.funFacts) !== JSON.stringify(nextProps.funFacts)) {
    return true;
  }
  return JSON.stringify(currentState.otherPanelsLoaded) !== JSON.stringify(nextState.otherPanelsLoaded);
};

// gets an estimated billing frequency based on the start and end dates
export const getBillingFrequency = (startDate, endDate) => {
  let frequency = 'bi-monthly';
  const diffDays = parseDate(endDate).diff(parseDate(startDate), 'days').days;

  if (diffDays >= 324) {
    frequency = 'yearly';
  } else if (diffDays >= 162) {
    frequency = 'half-yearly';
  } else if (diffDays >= 81) {
    frequency = 'quarterly';
  } else if (diffDays >= 27) {
    frequency = 'monthly';
  }

  return frequency;
};

// Calculates the number of days (multiple of 7 to match days) based on billing frequency,
// TODO: Is this necessary?: Add an additional week to avoid overlap with the current start date
const getBillingFrequencyDays = (frequency) => {
  let days = 35; // extra week (7 days) to avoid overlap issue

  if (typeof frequency === 'number') {
    days = Math.ceil(frequency / 7) * 7;
  }

  if (typeof frequency === 'string') {
    switch (frequency.toLowerCase()) {
      case 'bi-monthly':
        days = 21;
        break;
      case 'quarterly':
        days = 91; // (28 days * 3 months + 7)
        break;
      case 'half-yearly':
        days = 175; // (28 days * 6 months) or (84 * 2) + 7
        break;
      case 'yearly':
        days = 343; // (28 days * 12 months) or (168 * 2) + 7
        break;
      default:
        days = 35;
        break;
    }
  }

  return days;
};

// gets the last period of a date given the frequency (in days)
// ensures the date falls onto the same day as last period
export const getLastPeriodDate = (datetime, timeZone, frequency = 'monthly') => {
  const frequencyDays = getBillingFrequencyDays(frequency);
  return parseDateWithTimeZone(datetime, timeZone).minus({ days: frequencyDays }).toISO();
};

export const getTotalTrade = (tradeSummary, type, meterGroup) => {
  let totalAmount = 0;
  const tradeTypes = ['grid'];
  const includesTax = (type === 'Bought' && groupHasFeature('INCLUDES_TAX', meterGroup) && 'NetTax') || ''; // Tax only applied to "Bought" type
  groupHasFeature('P2P', meterGroup) && tradeTypes.push('p2p');
  groupHasFeature('RENEWABLES', meterGroup) && tradeTypes.push('renewable');

  if (tradeSummary) {
    totalAmount = tradeTypes.reduce((total, val) => {
      const tradeAmount = tradeSummary[`${val}${type}${includesTax}`];

      if (tradeAmount === null) {
        return null;
      }

      return tradeAmount ? total + parseFloat(tradeAmount) : total;
    }, null);
  }

  return totalAmount;
};

// gets the difference between 2 numbers
export const calcDifference = (inputA, inputB) => {
  const diff = inputB > 0 ? parseFloat(inputB) - parseFloat(inputA) : parseFloat(inputA);
  return Math.abs(diff);
};

export const getRandomFact = (averageImport, funFacts, t) => {
  const parsedImport = parseFloat(averageImport);
  let randomFact = '';

  if (funFacts && funFacts.length) {
    const { kwhrMultiplier, factoid } = funFacts[Math.floor(Math.random() * funFacts.length)];
    let convertedUsage = kwhrMultiplier * Math.abs(parsedImport);
    convertedUsage = /hour|km/.test(factoid)
      ? Math.round(convertedUsage * 100) / 100
      : Math.round(convertedUsage);

    randomFact = t(factoid).replace('|count|', convertedUsage);
  }

  return randomFact;
};

export const formatRanking = (rank) => {
  let formattedRank = '';
  const strRank = rank.toString();

  if (['11', '12', '13'].includes(strRank.slice(-2))) {
    formattedRank = `${rank}th`;
  } else if (strRank.slice(-1) === '1') {
    formattedRank = `${rank}st`;
  } else if (strRank.slice(-1) === '2') {
    formattedRank = `${rank}nd`;
  } else if (strRank.slice(-1) === '3') {
    formattedRank = `${rank}rd`;
  } else if (rank) {
    formattedRank = `${rank}th`;
  }

  return formattedRank;
};

// check if last period only has data before the actual last period's date-range
// if so, set them to 0. For example:
// consider the monthly frequency days is 35 and the actual current period 31 days (diff of 4 days)
// current period (2019/07/01 - 2019/08/01)
// last period (2019/05/27 - 2019/06/27)
// if there is only data between 2019/05/27 and 2019/05/31, set them to 0
const validateLastPeriodData = (data, daysInRange, isGroup = false) => {
  let validatedData = data;
  const lastPeriod = data.filter((v) => (isGroup && v.periodGroup > 0) || (!isGroup && v.period > 0));
  const frequencyDays = getBillingFrequencyDays(daysInRange);

  if (lastPeriod.length) {
    const lastPeriodLastIdx = lastPeriod[lastPeriod.length - 1].key;
    const diffDays = frequencyDays - data.length;

    if (diffDays >= lastPeriodLastIdx) {
      validatedData = data.map((val) => ({
        ...val,
        ...(val.key <= lastPeriodLastIdx && {
          ...(isGroup ? { periodGroup: null } : { period: null }),
        }),
      }));
    }
  }

  return validatedData;
};

// formats the given 2 lists of usage/transaction data and tradeTitle (bought/sold)
// (current period, last period)
export const formatPeriodComparisonData = (props, tradeTitle, type) => {
  const { meterGroup, periodStart, periodEnd, daysInRange, currentPeriod, lastPeriod } = props;
  const { timeZone } = meterGroup;
  const parsedPeriodStart = parseDateWithTimeZone(periodStart, timeZone);
  const parsedPeriodEnd = parseDateWithTimeZone(periodEnd, timeZone);
  const diffDays = parsedPeriodEnd.diff(parsedPeriodStart, 'days').days;

  let combinedData;
  const initialData = [];
  let indexDate = formatRequestDate(parsedPeriodStart, timeZone);

  for (let i = 0; i < diffDays; i++) {
    initialData.push({ key: i, date: formatRequestDate(indexDate, timeZone) });
    indexDate = parseDateWithTimeZone(indexDate, timeZone).plus({ days: 1 });
  }

  combinedData = initialData.map((val) => {
    const lastPeriodDate = getLastPeriodDate(val.date, timeZone, daysInRange);
    const formattedPeriodDate = formatRequestDate(lastPeriodDate, timeZone);

    const meterIdx = currentPeriod.findIndex((v) => v.transactionDate === val.date);
    const periodIdx = lastPeriod.findIndex((v) => v.transactionDate === formattedPeriodDate);

    return {
      ...val,
      ...(type === 'usage'
        ? {
            ...(meterIdx !== -1
              ? {
                  current:
                    tradeTitle === 'Bought'
                      ? currentPeriod[meterIdx].totalImport
                      : currentPeriod[meterIdx].totalExport,
                }
              : {}),
            period:
              periodIdx !== -1
                ? tradeTitle === 'Bought'
                  ? lastPeriod[periodIdx].totalImport
                  : lastPeriod[periodIdx].totalExport
                : null,
          }
        : {
            ...(meterIdx !== -1
              ? { current: getTotalTrade(currentPeriod[meterIdx], tradeTitle, meterGroup) }
              : {}),
            period: periodIdx !== -1 ? getTotalTrade(lastPeriod[periodIdx], tradeTitle, meterGroup) : null,
          }),
    };
  });

  combinedData = validateLastPeriodData(combinedData, daysInRange);

  return combinedData;
};

// formats the given 4 lists of usage data and the type (import/export) for chart
// (meter this period, community this period, community last period)
export const formatCommunityComparisonData = (props, type) => {
  const {
    meterGroup,
    billingStart,
    billingEnd,
    billingFrequency,
    meterTrades,
    groupTrades,
    lastMeterTrades,
    lastGroupTrades,
  } = props;
  const { timeZone } = meterGroup;
  const formattedBillStart = DateTime.fromFormat(billingStart, requestDateFormat);
  const formattedBillEnd = DateTime.fromFormat(billingEnd, requestDateFormat);
  const diffDays = formattedBillEnd.diff(formattedBillStart, 'days').days;

  let combinedData;
  const initialData = [];
  let indexDate = formatRequestDate(parseDateWithTimeZone(billingStart, timeZone), timeZone);

  for (let i = 0; i < diffDays; i++) {
    initialData.push({ key: i, date: formatRequestDate(indexDate, timeZone) });
    indexDate = parseDateWithTimeZone(indexDate, timeZone).plus({ days: 1 });
  }

  combinedData = initialData.map((val) => {
    const lastPeriodDate = getLastPeriodDate(val.date, timeZone, billingFrequency);
    const formattedPeriodDate = formatRequestDate(lastPeriodDate, timeZone);

    const meterIdx = (meterTrades || []).findIndex((v) => v.transactionDate === val.date);
    const meterPreviousIdx = (lastMeterTrades || []).findIndex(
      (v) => v.transactionDate === formattedPeriodDate
    );
    const groupIdx = (groupTrades || []).findIndex((v) => v.transactionDate === val.date);
    const groupPreviousIdx = (lastGroupTrades || []).findIndex(
      (v) => v.transactionDate === formattedPeriodDate
    );

    return {
      ...val,
      ...(meterIdx !== -1 && {
        currentMeter: formatAmount(
          type === 'import' ? meterTrades[meterIdx].totalImport : meterTrades[meterIdx].totalExport
        ),
      }),
      ...(groupIdx !== -1 && {
        currentGroup: formatAmount(
          type === 'import' ? groupTrades[groupIdx].averageImport : groupTrades[groupIdx].averageExport
        ),
      }),
      previousMeter:
        meterPreviousIdx !== -1
          ? formatAmount(
              type === 'import'
                ? lastMeterTrades[meterPreviousIdx].averageImport
                : lastMeterTrades[meterPreviousIdx].averageExport
            )
          : null,
      previousGroup:
        groupPreviousIdx !== -1
          ? formatAmount(
              type === 'import'
                ? lastGroupTrades[groupPreviousIdx].averageImport
                : lastGroupTrades[groupPreviousIdx].averageExport
            )
          : null,
    };
  });

  combinedData = validateLastPeriodData(combinedData, billingFrequency, true);

  return combinedData;
};
