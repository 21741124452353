import { Children, memo } from 'react';

import { isWhenElementRendered } from '../when/when.utils';
import { ChooseProps } from './choose.types';

const ChooseBase: React.FC<ChooseProps> = ({ children }) => {
  const childrenArray = Children.toArray(children);

  let childToRender: React.ReactNode = null;

  Children.forEach(childrenArray, (child) => {
    if (isWhenElementRendered(child) && !childToRender) childToRender = child;
  });

  return childToRender;
};

export const Choose = memo(ChooseBase);
