import { forwardRef, memo, useMemo } from 'react';
import { NoData } from '@power-ledger/assets';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'theme-ui';
import { DateTime } from 'luxon';
import { shallowEqual } from 'react-redux';

import { Table, TableInstance, TableLoadingOverlay } from '..';
import OfferDetails from '../modal/OfferDetails';
import { useFeatureSet } from '../../hooks/use-feature-set';
import { paginationOpts } from '../../lib/pagination';
import { GetOffersRequestParams } from '../../layouts/user/direct-trades';
import { Offer } from '../../../../types';
import { useAppSelector } from '../../hooks/use-app-selector';
import { truthy } from '../../utils/boolean';
import { StatusBadge } from '../status-badge';
import { COLOR_BY_STATUS, niceStatusTransMap } from '../../services';
import { formatAmount } from '../../lib/helpers';

const hiddenColumnsForBreakpoint = (breakpointIndex: number): string[] => {
  switch (breakpointIndex) {
    case 0:
    case 2:
    case 3:
      return ['amount'];
    default:
      return ['id'];
  }
};

const adminColumns = ({
  centSymbol,
  t,
  timeZone,
  breakpointIndex,
  hideAllocatedAmount = false,
  useEUTimeFormat = false,
}: {
  centSymbol: string;
  t: (text: string) => string;
  timeZone: string;
  breakpointIndex: number;
  hideAllocatedAmount?: boolean;
  useEUTimeFormat?: boolean;
}) => {
  const sellerColumnWidth = breakpointIndex < 1 ? 80 : 200;
  const buyerColumnWidth = breakpointIndex < 1 ? 80 : 200;
  const indexColumnWidth = breakpointIndex < 1 ? 36 : 64;

  return [
    {
      title: t('#'),
      dataIndex: 'key',
      key: 'key',
      width: indexColumnWidth,
      left: 0,
      sticky: true,
      render: (key: string) => <Text>{t(`${key + 1}`)}</Text>,
    },
    {
      title: t('SELLER'),
      dataIndex: 'sellerMeterDisplayName',
      key: 'sellerMeterUid',
      width: sellerColumnWidth,
      left: indexColumnWidth,
      sticky: true,
    },
    {
      title: t('BUYER'),
      dataIndex: 'buyerMeterDisplayName',
      key: 'buyerMeterUid',
      width: buyerColumnWidth,
      left: indexColumnWidth + sellerColumnWidth,
      sticky: true,
    },
    {
      title: t('CREATED ON'),
      dataIndex: 'creationTime',
      key: 'creationTime',

      //here
      render: (creationTime: string) => (
        <Box>
          <Text>
            {DateTime.fromJSDate(new Date(creationTime))
              .setZone(timeZone)
              .toFormat(useEUTimeFormat ? 'dd/MM/yyyy' : 'yyyy/MM/dd')}
          </Text>
        </Box>
      ),
    },
    {
      title: (
        <Text sx={{ display: 'flex', flexDirection: breakpointIndex < 1 ? 'column' : 'row' }}>
          <span>{t(`RATE (${centSymbol}/kWh)`)}&nbsp;</span>
          <span>{t('INCL. TAX')}</span>
        </Text>
      ),
      dataIndex: 'energyPriceNetTax',
      key: 'energyPriceNetTax',
      render: (rate: number) => <Text>{formatAmount(rate, 2)}</Text>,
    },
    !hideAllocatedAmount
      ? {
          title: t('ALLOCATED (%)'),
          dataIndex: 'amount',
          key: 'amount',
        }
      : null,
    {
      title: t('STATUS'),
      dataIndex: 'niceStatus',
      key: 'niceStatus',
      render: (niceStatus: string) =>
        niceStatus ? (
          <StatusBadge
            color={COLOR_BY_STATUS[niceStatus as keyof typeof COLOR_BY_STATUS]}
            statusText={niceStatusTransMap(t)[niceStatus]}
            variant="subtle"
          />
        ) : (
          <Text>-</Text>
        ),
    },
    {
      title: '',
      dataIndex: 'uid',
      key: 'uid',
      width: 90,
      fixed: 'right',
      render: (_: unknown, details: Offer) => (
        <OfferDetails isAdmin {...details} hideAllocatedAmount={hideAllocatedAmount} />
      ),
    },
  ].filter(truthy);
};

const columns = ({
  centSymbol,
  isBuyer,
  username,
  getOffers,
  includesTax,
  t,
  timeZone,
  breakpointIndex,
  hideAllocatedAmount = false,
  useEUTimeFormat = false,
}: {
  centSymbol: string;
  isBuyer: boolean;
  username: string;
  getOffers: () => void;
  includesTax: boolean;
  t: (text: string) => string;
  timeZone: string;
  breakpointIndex: number;
  hideAllocatedAmount?: boolean;
  useEUTimeFormat?: boolean;
}) => {
  const sellerColumnWidth = breakpointIndex < 1 ? 80 : 200;
  const buyerColumnWidth = breakpointIndex < 1 ? 80 : 200;
  const indexColumnWidth = breakpointIndex < 1 ? 36 : 64;

  return [
    {
      title: t('#'),
      dataIndex: 'key',
      key: 'key',
      width: indexColumnWidth,
      left: 0,
      sticky: true,
      render: (key: string) => <Text>{t(`${key + 1}`)}</Text>,
    },
    ...(isBuyer
      ? [
          {
            title: t('SELLER'),
            dataIndex: 'sellerMeterDisplayName',
            key: 'sellerMeterUid',
            width: sellerColumnWidth,
            left: indexColumnWidth,
            sticky: true,
          },
        ]
      : [
          {
            title: t('BUYER'),
            dataIndex: 'buyerMeterDisplayName',
            key: 'buyerMeterUid',
            width: buyerColumnWidth,
            left: indexColumnWidth,
            sticky: true,
          },
        ]),
    {
      title: t('CREATED ON'),
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: (creationTime: string) => (
        <Box>
          <Text>
            {DateTime.fromJSDate(new Date(creationTime))
              .setZone(timeZone)
              .toFormat(useEUTimeFormat ? 'dd/MM/yyyy' : 'yyyy/MM/dd')}
          </Text>
        </Box>
      ),
    },
    {
      title: t(`RATE (${centSymbol}/kWh)`),
      dataIndex: `energyPrice${includesTax && isBuyer ? 'NetTax' : ''}`,
      key: 'energyPrice',
      render: (rate: number) => <Text>{formatAmount(rate, 2)}</Text>,
    },
    !hideAllocatedAmount
      ? {
          title: t('ALLOCATED (%)'),
          dataIndex: 'amount',
          key: 'amount',
        }
      : null,
    {
      title: t('STATUS'),
      dataIndex: 'niceStatus',
      key: 'niceStatus',
      render: (niceStatus: string) =>
        niceStatus ? (
          <StatusBadge
            color={COLOR_BY_STATUS[niceStatus as keyof typeof COLOR_BY_STATUS]}
            statusText={niceStatusTransMap(t)[niceStatus]}
            variant="subtle"
          />
        ) : (
          <Text>-</Text>
        ),
    },
    {
      title: '',
      dataIndex: 'OfferDetails',
      key: 'OfferDetails',
      width: 90,
      fixed: 'right',
      render: (_: unknown, details: Offer) => (
        <OfferDetails
          isBuyer={isBuyer}
          includesTax={includesTax}
          username={username}
          getOffers={getOffers}
          hideAllocatedAmount={hideAllocatedAmount}
          {...details}
        />
      ),
    },
  ].filter(truthy);
};

interface PrefTradeOffersTableProps {
  username?: string;
  isAdmin?: boolean;
  isBuyer?: boolean;
  data?: any[];
  total?: number;
  offset?: number;
  limit?: number;
  centSymbol: string;
  isLoading?: boolean;
  loadingText?: string;
  getOffers: () => void;
  onUpdateOffersByPageOptions: (pageOptions: GetOffersRequestParams) => void;
}

const PrefTradeOffersTableBase = (
  {
    username = '',
    isAdmin = false,
    isBuyer = false,
    data = [],
    total = 0,
    offset = 0,
    limit = 10,
    centSymbol,
    isLoading = false,
    loadingText = '',
    getOffers,
    onUpdateOffersByPageOptions,
  }: PrefTradeOffersTableProps,
  ref: React.ForwardedRef<TableInstance>
) => {
  const { t } = useTranslation();
  const breakpointIndex = useBreakpointIndex();
  const { includesBuyerTax, ppaUsesEnergyAmount, useEUTimeFormat } = useFeatureSet();
  const timeZone = useAppSelector(
    ({ meters: metersState }) => metersState.meterGroup && metersState.meterGroup.timeZone,
    shallowEqual
  );

  const hiddenColumns = useMemo(() => hiddenColumnsForBreakpoint(breakpointIndex), [breakpointIndex]);

  const pageIndex = Math.floor((offset + limit) / limit);

  let tableColumns = [] as any;

  if (total > 0) {
    tableColumns = isAdmin
      ? adminColumns({
          centSymbol,
          t,
          timeZone,
          breakpointIndex,
          hideAllocatedAmount: ppaUsesEnergyAmount,
          useEUTimeFormat,
        })
      : columns({
          centSymbol,
          isBuyer,
          username,
          getOffers,
          includesTax: includesBuyerTax,
          t,
          timeZone,
          breakpointIndex,
          hideAllocatedAmount: ppaUsesEnergyAmount,
          useEUTimeFormat,
        });
  }

  const handlePaginationChange = (pageOptions: GetOffersRequestParams) => {
    if (limit !== pageOptions.limit || offset !== pageOptions.offset) {
      onUpdateOffersByPageOptions(pageOptions);
    }
  };

  return (
    <Table
      ref={ref}
      locale={{
        emptyText: <NoData description={isLoading ? '' : t('No offers found')} />,
      }}
      columns={tableColumns}
      hiddenColumns={hiddenColumns}
      dataSource={total > 0 ? data : []}
      loading={{
        spinning: isLoading,
        indicator: <TableLoadingOverlay active text={loadingText} color="text" />,
      }}
      pagination={paginationOpts(pageIndex, limit, total, handlePaginationChange, t)}
    />
  );
};

export const PrefTradeOffersTable = memo(forwardRef(PrefTradeOffersTableBase));
