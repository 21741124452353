import { CustomThemeProperty } from './types';

export const tables = CustomThemeProperty('tables', {
  textRendering: 'optimizeLegibility',
  fontSize: [1, null, 2],
  fontVariant: 'tabular-nums',
  fontFamily: 'table',
  fontWeight: 'light',
  listStyle: 'none',
  textAlign: 'left',
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'separate',
  border: 'none',
  placeholder: {
    display: 'flex',
    backgroundColor: 'background',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'th, td': {
    paddingTop: [2, null, 3],
    paddingRight: [3, null, 4],
    paddingBottom: [2, null, 3],
    paddingLeft: [2, null, 3],
    whiteSpace: 'nowrap',
    overflowWrap: 'break-word',
  },
  thead: {
    border: 'none',
    fontWeight: 'light',
    color: 'primaryDarkest',
    tr: {
      'th:first-of-type': {
        borderTopLeftRadius: 4,
      },
      'th:last-of-type': {
        borderTopRightRadius: 4,
      },
      th: {
        backgroundColor: 'background',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: 'muted',
        fontWeight: 'heading',
        transition: 'background 0.3s ease',
        '&:hover': {
          backgroundColor: 'muted',
        },
      },
    },
  },
  tbody: {
    tr: {
      td: {
        backgroundColor: 'background',
        transition: 'all 300ms, border 0s',
      },
      'td.pagination': {
        backgroundColor: 'mutedLightest',
      },
      '&:hover': {
        td: {
          '&:not(.pagination)': {
            backgroundColor: 'muted',
            '&.placeholder': {
              backgroundColor: 'unset',
            },
          },
        },
      },
    },
    'tr:not(:last-of-type)': {
      td: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: 'muted',
      },
    },
    'tr:last-of-type': {
      'td:first-of-type': {
        borderBottomLeftRadius: 4,
      },
      'td:last-of-type': {
        borderBottomRightRadius: 4,
      },
    },
  },
  pagination: {
    td: {
      backgroundColor: 'mutedLightest',
    },
  },
  rankings: {
    thead: {
      color: 'background',
      tr: {
        th: {
          padding: 2,
          backgroundColor: 'secondaryDark',
          '&:hover': {
            backgroundColor: 'secondaryDark',
          },
        },
      },
    },
    tbody: {
      tr: {
        td: {
          padding: 2,
        },
        '&:hover': {
          td: {
            '&:not(.pagination)': {
              backgroundColor: 'muted',
              '&.placeholder': {
                backgroundColor: 'unset',
              },
            },
          },
        },
      },
      'tr:nth-of-type(even)': {
        td: {
          backgroundColor: 'mutedLightest',
        },
      },
    },
  },
});
