import { Theme, ThemeUIStyleObject } from 'theme-ui';
import { defaultIconSize } from './icons';

// For New UI compatibility
const newUiSharedButtonStyles: ThemeUIStyleObject = {
  fontFamily: 'Roboto, Arial',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  gap: 1,
  transition: 'all .2s ease',
  width: 'auto',
  userSelect: 'none',
  position: 'relative',
  outline: 'none',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  ':active': {
    '> *': {
      transform: 'translateY(1px)',
      transition: 'transform .1s ease',
    },
  },
};

const commonStyles: ThemeUIStyleObject = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  p: [1, 2],
  transition: 'base',
  outline: 'none',
  '&:disabled': {
    backgroundColor: 'muted',
    cursor: 'not-allowed',
    color: 'text',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'muted',
      color: 'text',
    },
  },
  userSelect: 'none',
};

const smallerMobileFont: ThemeUIStyleObject = {
  fontSize: [1, null, 2],
};

export const buttons: Theme['buttons'] = {
  primary: {
    ...commonStyles,
    borderRadius: 4,
    bg: 'primary',
    '&:hover, &:focus': {
      bg: 'primaryDark',
    },
  },
  secondary: {
    ...commonStyles,
    ...smallerMobileFont,
    bg: 'inherit',
    color: 'primaryDark',
    '&:hover, &:focus': {
      color: 'primary',
    },
  },
  outlined: {
    ...commonStyles,
    bg: 'background',
    color: 'textLighter',
    borderColor: 'muted',
    borderWidth: 2,
    borderStyle: 'solid',
    '&:hover, &:focus': {
      color: 'primary',
      borderColor: 'primary',
      '&:disabled': {
        color: 'text',
        borderColor: 'muted',
      },
    },
  },
  pill: {
    ...commonStyles,
    ...smallerMobileFont,
    py: [1, 2],
    px: [2, 3],
    borderRadius: [5, 6],
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'secondary',
    color: 'secondary',
    bg: 'transparent',
    'div svg path': {
      transition: 'base',
      color: 'secondary',
      fill: 'secondary',
    },
    '&:hover, &:focus': {
      color: 'white',
      bg: 'secondary',
      'div svg path': {
        color: 'white',
        fill: 'white',
      },
    },
  },
  pillInverted: {
    ...commonStyles,
    ...smallerMobileFont,
    py: [1, 2],
    px: [2, 3],
    borderRadius: [5, 6],
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    bg: 'secondary',
    'div svg path': {
      transition: 'base',
      color: 'white',
      fill: 'white',
    },
    '&:hover, &:focus': {
      color: 'secondary',
      bg: 'white',
      'div svg path': {
        color: 'secondary',
        fill: 'secondary',
      },
    },
  },
  avatar: {
    ...commonStyles,
    padding: 0,
    width: 7,
    height: 7,
    borderRadius: 6,
    bg: 'white',
    color: 'primaryDark',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'div svg path': {
      transition: 'base',
      color: 'primaryDark',
      fill: 'primaryDark',
    },
    '&:hover, &:focus': {
      color: 'white',
      bg: 'primaryDark',
      'div svg path': {
        color: 'white',
        fill: 'white',
      },
    },
    text: {
      backgroundColor: 'transparent',
      color: 'primary',
      border: 'none',
      padding: 0,
      margin: 0,
      display: 'inline-flex',
      width: 'initial',
      height: 'initial',
      '&:hover, &:active, &:focus': {
        outline: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        color: 'primaryDark',
      },
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: 'primaryDark',
    border: 'none',
    padding: 0,
    margin: 0,
    display: 'inline-flex',
    width: 'initial',
    height: 'initial',
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'light',
    transition: 'base',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
      color: 'text',
      cursor: 'not-allowed',
    },
    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      color: 'primary',
      '&:disabled': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: 'text',
      },
    },
  },
  icon: {
    ...commonStyles,
    width: defaultIconSize,
    height: defaultIconSize,
    padding: 0,
    color: 'primary',
  },
  link: {
    ...commonStyles,
    py: [1, 2],
    px: [2, 3],
    borderRadius: [5, 6],
    fontSize: 1,
    color: 'primaryDarkest',
    bg: 'white',
  },
  newUI: {
    outline: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: '#0000001A',
      color: '#1C1C1C',
      ':hover': {
        bg: '#0000000D',
      },
      ':disabled': {
        color: '#0000001A',
        bg: 'transparent',
        pointerEvents: 'none',
      },
      ...newUiSharedButtonStyles,
    },
  },
};
