import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useAuthService } from '@power-ledger/auth-service';
import { shallowEqual } from 'react-redux';

import { BASE_URL } from '../middleware';
import {
  HOSTNAME_OVERRIDE,
  isProduction,
  LOCIZE_APIKEY,
  LOCIZE_PROJECTID,
  LOCIZE_REFLNG,
  LOCIZE_VERSION,
} from '../lib/envHelpers';
import { Meter, MeterGroup, StoreStatus } from '../../../types';
import { initializeControlsFromTimeZone } from '../states/slices/controls';
import { CONFIG_ENDPOINT } from '../providers/host-config-provider/use-host-config-provider-ctx';
import { useAppSelector } from './use-app-selector';
import { useNewUIEnabled } from './use-new-ui-enabled';
import { useAppDispatch } from './use-app-dispatch';
import { useFeatureSet } from './use-feature-set';

const lazyLoadTransactiveWebApp = async () => {
  // @ts-ignore
  const moduleExports = await import('@powerledger/transactive-web-app');

  return moduleExports;
};

export let transactiveNavigate: ((path: string, options?: { replace?: boolean }) => void) | undefined =
  undefined;

export const useTransactiveWebApp = () => {
  const { getTokens } = useAuthService();
  const isNewUIEnabled = useNewUIEnabled();
  const dispatch = useAppDispatch();

  const selectedMeter = useAppSelector(({ controls }) => controls.selectedMeter as Meter, shallowEqual);
  const { hasIOAManagement } = useFeatureSet();
  const { startDate, endDate, period } = useAppSelector(
    ({ controls: { startDate, endDate, period } }: any) => ({ startDate, endDate, period }),
    shallowEqual
  );
  const meterGroup = useAppSelector(
    ({ meters: metersState }: any) => metersState.meterGroup,
    shallowEqual
  ) as MeterGroup;
  const authProfile = useAppSelector(({ auth }) => auth.profile, shallowEqual);
  const metersStatus: StoreStatus = useAppSelector(
    ({ meters: metersState }) => metersState.metersStatus,
    shallowEqual
  );
  const timeZone = useAppSelector(
    ({ meters: metersState }) => metersState.meterGroup && metersState.meterGroup.timeZone,
    shallowEqual
  );

  const newAppContainerRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingTransactiveWebApp, setIsLoadingTransactiveWebApp] = useState(false);
  const [transactiveWebAppExports, setTransactiveWebAppExports] = useState<Record<string, any>>({});

  const isTransactiveWebAppLoaded = useMemo(
    () => !isEmpty(transactiveWebAppExports),
    [transactiveWebAppExports]
  );
  const {
    navigateFn,
    updateAppConfig,
    updateGetTokenFn,
    renderTransactiveAppAsLib,
    setSelectedMeter,
    setAreMetersInitialized,
    setDateState,
    setProfile,
    injectLocize,
  } = transactiveWebAppExports;

  useEffect(() => {
    if (!isNewUIEnabled || isTransactiveWebAppLoaded) return;

    (async () => {
      setIsLoadingTransactiveWebApp(true);

      const moduleExports = await lazyLoadTransactiveWebApp();

      setTransactiveWebAppExports(moduleExports);
      setIsLoadingTransactiveWebApp(false);
    })();
  }, [isTransactiveWebAppLoaded, isNewUIEnabled]);

  useEffect(() => {
    if (
      !newAppContainerRef.current ||
      typeof updateAppConfig !== 'function' ||
      typeof renderTransactiveAppAsLib !== 'function'
    )
      return;

    if (isNewUIEnabled) {
      updateAppConfig?.({
        DASH_API_URL: BASE_URL,
        HOST_CONFIG_ENDPOINT: CONFIG_ENDPOINT,
        HOSTNAME_OVERRIDE,
        IS_PRODUCTION: isProduction(),
        LOCIZE_APIKEY,
        LOCIZE_PROJECTID,
        LOCIZE_REFLNG,
        LOCIZE_VERSION,
      });

      injectLocize?.();

      return renderTransactiveAppAsLib(newAppContainerRef.current);
    }

    if (newAppContainerRef.current.childElementCount > 0) {
      newAppContainerRef.current.innerHTML = '';
    }
  }, [isNewUIEnabled, updateAppConfig, renderTransactiveAppAsLib, injectLocize]);

  useEffect(() => {
    updateGetTokenFn?.(getTokens);
  }, [getTokens, updateGetTokenFn]);

  useEffect(() => {
    setSelectedMeter?.(selectedMeter);
  }, [selectedMeter, setSelectedMeter]);

  useEffect(() => {
    setAreMetersInitialized?.(metersStatus === StoreStatus.INITIALIZED);
  }, [metersStatus, setAreMetersInitialized]);

  useEffect(() => {
    if (!period) {
      dispatch(initializeControlsFromTimeZone(meterGroup.timeZone, hasIOAManagement, true));
    }

    setDateState?.({ startDate, endDate, period, timeZone });
  }, [startDate, endDate, period, timeZone, setDateState, meterGroup.timeZone, dispatch, hasIOAManagement]);

  useEffect(() => {
    setProfile?.(authProfile);
  }, [setProfile, authProfile]);

  useEffect(() => {
    transactiveNavigate = navigateFn;
  }, [navigateFn]);

  return {
    isLoadingTransactiveWebApp,
    isTransactiveWebAppLoaded,
    isVisionUIEnabled: isNewUIEnabled,
    newAppContainerRef,
  };
};
