import {
  CurrentWeatherForecast,
  DailyWeatherForecast,
  FormattedDayWeather,
  FormattedWeatherBySite,
  MeterGroup,
  WeatherBySite,
} from '../../../types';
import {
  PartlyCloudyDay,
  PartlyCloudyNight,
  ClearDay,
  ClearNight,
  Cloudy,
  Fog,
  Hail,
  Rain,
  Sleet,
  Snow,
  Thunderstorm,
  Tornado,
  Wind,
} from '../components/icons';
import { IconProps } from '../components/icons/common';
import { formatWeatherDate } from './datetime-helpers';

export const isDailyWeatherForecast = (
  weatherForecast: CurrentWeatherForecast | DailyWeatherForecast
): weatherForecast is DailyWeatherForecast => {
  const dailyWeatherForecast = weatherForecast as DailyWeatherForecast;

  return (
    typeof dailyWeatherForecast.temperatureHigh !== 'undefined' ||
    typeof dailyWeatherForecast.temperatureLow !== 'undefined'
  );
};

const WEATHER_ICON_MAPPING: Record<string, React.FC<IconProps>> = {
  CLEAR_DAY: ClearDay,
  CLEAR_NIGHT: ClearNight,
  CLOUDY: Cloudy,
  FOG: Fog,
  HAIL: Hail,
  PARTLY_CLOUDY_DAY: PartlyCloudyDay,
  PARTLY_CLOUDY_NIGHT: PartlyCloudyNight,
  RAIN: Rain,
  SLEET: Sleet,
  SNOW: Snow,
  THUNDERSTORM: Thunderstorm,
  TORNADO: Tornado,
  WIND: Wind,
};

export const getWeatherIcon = (icon: string, color?: string, size = 6) => {
  const Icon = WEATHER_ICON_MAPPING[icon];

  if (!Icon) return null;

  return <Icon size={size} {...(color ? { color } : {})} />;
};

const formatWeather = (
  weather: CurrentWeatherForecast | DailyWeatherForecast,
  timeZone: string
): FormattedDayWeather => {
  const weatherTemp = isDailyWeatherForecast(weather) ? weather.temperatureHigh : weather.temperature;

  return {
    day: formatWeatherDate(weather.time, timeZone),
    icon: weather.icon,
    temperature: Math.round(weatherTemp),
  };
};

export const formatSiteWeather = (
  weatherBySite: WeatherBySite,
  meterGroup: MeterGroup,
  numberOfDailyItems = 2
): FormattedWeatherBySite => {
  let siteWeather: FormattedWeatherBySite = {
    current: { icon: '', temperature: 0 },
    daily: [],
  };

  if (weatherBySite && Object.keys(weatherBySite).length) {
    siteWeather = {
      ...siteWeather,
      ...(weatherBySite.currently
        ? {
            current: formatWeather(weatherBySite.currently, meterGroup.timeZone),
          }
        : {}),
      ...(weatherBySite.daily
        ? {
            daily: weatherBySite.daily.map((weather) => formatWeather(weather, meterGroup.timeZone)),
          }
        : {}),
      ...(weatherBySite.flags && weatherBySite.flags.units
        ? { unit: weatherBySite.flags.units }
        : { units: 'si' }),
    };

    if (siteWeather.daily) {
      const filteredSiteWeather = siteWeather.current
        ? siteWeather.daily.filter((weather) => weather.day !== siteWeather.current.day)
        : siteWeather.daily;

      siteWeather.daily = filteredSiteWeather.slice(0, numberOfDailyItems);
    }
  }

  return siteWeather;
};
