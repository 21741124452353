import * as api from '../../middleware/api';
import {
  UPDATE_USER_ACCOUNT,
  UPDATE_BILLING_PERIOD,
  UPDATE_FUN_FACTS,
  UPDATE_TRANSACTION_SUMMARY,
  UPDATE_LAST_TRANSACTION_SUMMARY,
  UPDATE_TRANSACTIONS_LIST,
  UPDATE_LAST_TRANSACTIONS_LIST,
  UPDATE_TOTAL_SAVINGS,
  UPDATE_LAST_TOTAL_SAVINGS,
  UPDATE_METER_RANK,
  UPDATE_SITE_WEATHER,
  RESET_DASHBOARD,
} from '../constants';
import { getBillingFrequency, getLastPeriodDate } from '../selectors/dashboard';
import { errorNotification, successRegex } from '../../lib/notifications';
import { formatRequestDate, sortByDateTime } from '../../lib/datetime-helpers';
import groupHasFeature from '../../lib/group-has-feature';
import i18n from '../../lib/i18n';

const mapTransactionData = (transactions) => {
  let transactionList = [];

  if (transactions && transactions.length) {
    transactionList = transactions.map((val) => {
      const {
        averageUsageBought,
        averageUsageSold,
        gridAmountBought,
        gridAmountBoughtNetTax,
        gridAmountSold,
        gridAmountSoldNetTax,
        lp2pAmountSold,
        lp2pAmountSoldNetTax,
        p2pAmountBought,
        p2pAmountBoughtNetTax,
        p2pAmountSold,
        p2pAmountSoldNetTax,
        renewableAmountBought,
        renewableAmountBoughtNetTax,
        renewableAmountSold,
        renewableAmountSoldNetTax,
        savingAmount,
        totalUsageBought,
        totalUsageSold,
        transactionDate,
      } = val;

      return {
        transactionDate,
        averageExport: averageUsageSold,
        averageImport: averageUsageBought,
        gridBought: gridAmountBought,
        gridBoughtNetTax: gridAmountBoughtNetTax,
        gridSold: gridAmountSold,
        gridSoldNetTax: gridAmountSoldNetTax,
        lp2pSold: lp2pAmountSold,
        lp2pSoldNetTax: lp2pAmountSoldNetTax,
        p2pBought: p2pAmountBought,
        p2pBoughtNetTax: p2pAmountBoughtNetTax,
        p2pSold: p2pAmountSold,
        p2pSoldNetTax: p2pAmountSoldNetTax,
        renewableBought: renewableAmountBought,
        renewableBoughtNetTax: renewableAmountBoughtNetTax,
        renewableSold: renewableAmountSold,
        renewableSoldNetTax: renewableAmountSoldNetTax,
        totalExport: totalUsageSold,
        totalImport: totalUsageBought,
        savingAmount,
      };
    });
  }

  return transactionList;
};

export const updateUserAccount = (accountId, accountNumber, accountBalance, billingFrequency) => ({
  type: UPDATE_USER_ACCOUNT,
  accountId,
  accountNumber,
  accountBalance,
  billingFrequency,
});

export const updateBillingPeriod = (billingStart, billingEnd, billingFrequency = null) => ({
  type: UPDATE_BILLING_PERIOD,
  billingStart,
  billingEnd,
  billingFrequency,
});

export const updateFunFacts = (funFacts) => ({
  type: UPDATE_FUN_FACTS,
  funFacts,
});

export const updateTransactionSummary = (transaction, isCurrent = true) => {
  const { transactionStatistic, balance } = transaction;
  const {
    averageUsageBought,
    averageUsageSold,
    gridAmountBought,
    gridAmountBoughtNetTax,
    gridAmountBoughtTax,
    gridAmountSold,
    gridAmountSoldNetTax,
    gridAmountSoldTax,
    gridUsageBought,
    gridUsageSold,
    lp2pAmountSold,
    lp2pAmountSoldNetTax,
    lp2pAmountSoldTax,
    lp2pUsageSold,
    meterUid,
    p2pAmountBought,
    p2pAmountBoughtNetTax,
    p2pAmountBoughtTax,
    p2pUsageBought,
    p2pAmountSold,
    p2pAmountSoldNetTax,
    p2pAmountSoldTax,
    p2pUsageSold,
    renewableAmountBought,
    renewableAmountBoughtNetTax,
    renewableAmountBoughtTax,
    renewableUsageBought,
    renewableAmountSold,
    renewableAmountSoldNetTax,
    renewableAmountSoldTax,
    renewableUsageSold,
    savingAmount,
    totalUsageBought,
    totalUsageSold,
    vppAmountSold,
    vppAmountSoldNetTax,
    vppAmountSoldTax,
    vppUsageSold,
  } = transactionStatistic;

  const transactionData = {
    averageExport: averageUsageSold,
    averageImport: averageUsageBought,
    gridBought: gridAmountBought,
    gridBoughtNetTax: gridAmountBoughtNetTax,
    gridBoughtTax: gridAmountBoughtTax,
    gridUsageBought,
    gridSold: gridAmountSold,
    gridSoldNetTax: gridAmountSoldNetTax,
    gridSoldTax: gridAmountSoldTax,
    gridUsageSold,
    lp2pSold: lp2pAmountSold,
    lp2pSoldNetTax: lp2pAmountSoldNetTax,
    lp2pSoldTax: lp2pAmountSoldTax,
    lp2pUsageSold,
    p2pBought: p2pAmountBought,
    p2pBoughtNetTax: p2pAmountBoughtNetTax,
    p2pBoughtTax: p2pAmountBoughtTax,
    p2pUsageBought,
    p2pSold: p2pAmountSold,
    p2pSoldNetTax: p2pAmountSoldNetTax,
    p2pSoldTax: p2pAmountSoldTax,
    p2pUsageSold,
    renewableBought: renewableAmountBought,
    renewableBoughtNetTax: renewableAmountBoughtNetTax,
    renewableBoughtTax: renewableAmountBoughtTax,
    renewableUsageBought,
    renewableSold: renewableAmountSold,
    renewableSoldNetTax: renewableAmountSoldNetTax,
    renewableSoldTax: renewableAmountSoldTax,
    renewableUsageSold,
    totalExport: totalUsageSold,
    totalImport: totalUsageBought,
    vppSold: vppAmountSold,
    vppSoldNetTax: vppAmountSoldNetTax,
    vppSoldTax: vppAmountSoldTax,
    savingAmount,
    vppUsageSold,
    balance,
  };

  return {
    type: isCurrent ? UPDATE_TRANSACTION_SUMMARY : UPDATE_LAST_TRANSACTION_SUMMARY,
    ...(meterUid ? { meterTradeSummary: transactionData } : { groupTradeSummary: transactionData }),
  };
};

export const updateTransactionsList = (transactions, isCurrent = true) => {
  const transactionList = mapTransactionData(transactions);
  const sortedTransactionList = sortByDateTime(transactionList, 'transactionDate');

  return {
    type: isCurrent ? UPDATE_TRANSACTIONS_LIST : UPDATE_LAST_TRANSACTIONS_LIST,
    meterTrades: sortedTransactionList,
  };
};

export const updateTotalSavings = (savings, isCurrent = true) => {
  return {
    type: isCurrent ? UPDATE_TOTAL_SAVINGS : UPDATE_LAST_TOTAL_SAVINGS,
    totalSavings: savings,
  };
};

export const updateGroupTransactionsList = (transactions, isCurrent = true) => {
  const transactionList = mapTransactionData(transactions);
  const sortedTransactionList = sortByDateTime(transactionList, 'transactionDate');

  return {
    type: isCurrent ? UPDATE_TRANSACTIONS_LIST : UPDATE_LAST_TRANSACTIONS_LIST,
    groupTrades: sortedTransactionList,
  };
};

export const updateMeterRank = (rankings) => ({
  type: UPDATE_METER_RANK,
  rankings,
});

export const updateSiteWeather = (weatherBySite) => ({
  type: UPDATE_SITE_WEATHER,
  weatherBySite,
});

export const resetDashboard = { type: RESET_DASHBOARD };

// Services
export const getFunFacts =
  (usageAmount, token = null) =>
  async (dispatch) => {
    const { status, body } = await api.getFunFacts(usageAmount, token);

    if (successRegex.test(status) && body) {
      dispatch(updateFunFacts(body.funFacts));
    } else {
      // NOTE: This has been suppressed for now...
      // errorNotification({
      //   code: status,
      //   errorCode: body.errorCode,
      //   description: (i18n.t('Could not get fun facts at this time!')),
      // });
    }

    return status !== 499;
  };

export const getGroupedTransactionSummary =
  (props, token = null, isCurrent = true) =>
  async (dispatch) => {
    const { daysInRange, startDate, endDate, meterGroup, includeEstimates, isEstimate } = props;
    const { name, timeZone } = meterGroup;
    let periodStartDate = isCurrent ? startDate : getLastPeriodDate(startDate, timeZone, daysInRange);
    let periodEndDate = isCurrent ? endDate : getLastPeriodDate(endDate, timeZone, daysInRange);

    const request = {
      groupName: name,
      fromDateTime: formatRequestDate(periodStartDate, timeZone),
      toDateTime: formatRequestDate(periodEndDate, timeZone),
      includeEstimates,
      isEstimate,
    };

    const { status, body } = await api.getGroupedTransactionSummary(request, token);

    if (successRegex.test(status)) {
      dispatch(updateTransactionSummary(body, isCurrent));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve transactions at this time!'),
      });
    }

    return status !== 499;
  };

export const getGroupedTransactionsList =
  (props, token = null, isCurrent = true) =>
  async (dispatch) => {
    const { startDate, endDate, meterGroup, includeEstimates, isEstimate } = props;
    const { name, timeZone } = meterGroup;
    const FOUR_WEEK_PERIOD_DAYS = 28;
    let periodStartDate = startDate;
    let periodEndDate = endDate;

    if (!isCurrent) {
      periodStartDate = getLastPeriodDate(startDate, timeZone, FOUR_WEEK_PERIOD_DAYS);
      periodEndDate = getLastPeriodDate(endDate, timeZone, FOUR_WEEK_PERIOD_DAYS);
    }

    const request = {
      groupName: name,
      fromDateTime: formatRequestDate(periodStartDate, timeZone),
      toDateTime: formatRequestDate(periodEndDate, timeZone),
      includeEstimates,
      isEstimate,
    };

    const { status, body } = await api.getGroupedTransactionsList(request, token);

    if (successRegex.test(status) && body) {
      dispatch(updateGroupTransactionsList(body.resultList, isCurrent));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve transactions at this time!'),
      });
    }

    return status !== 499;
  };

export const getTransactionSummary =
  (props, token = null, isCurrent = true) =>
  async (dispatch) => {
    const { daysInRange, startDate, endDate, meterGroup, meter, includeEstimates, isEstimate } = props;
    const { name, timeZone } = meterGroup;
    let periodStartDate = startDate;
    let periodEndDate = endDate;

    if (!isCurrent) {
      periodStartDate = getLastPeriodDate(startDate, timeZone, daysInRange);
      periodEndDate = getLastPeriodDate(endDate, timeZone, daysInRange);
    }

    const request = {
      groupName: name,
      meterUid: meter && meter.uid ? meter.uid : '',
      fromDateTime: formatRequestDate(periodStartDate, timeZone),
      toDateTime: formatRequestDate(periodEndDate, timeZone),
      includeEstimates,
      isEstimate,
    };

    const { status, body } = await api.getTransactionSummary(request, token);

    if (successRegex.test(status)) {
      dispatch(updateTransactionSummary(body, isCurrent));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve transactions at this time!'),
      });
    }

    return status !== 499;
  };

export const getTransactionsList =
  (props, token = null, isCurrent = true) =>
  async (dispatch) => {
    const { startDate, endDate, meterGroup, meter, includeEstimates, isEstimate } = props;
    const { name, timeZone } = meterGroup;
    const FOUR_WEEK_PERIOD_DAYS = 28;
    let periodStartDate = startDate;
    let periodEndDate = endDate;

    if (!isCurrent) {
      periodStartDate = getLastPeriodDate(startDate, timeZone, FOUR_WEEK_PERIOD_DAYS);
      periodEndDate = getLastPeriodDate(endDate, timeZone, FOUR_WEEK_PERIOD_DAYS);
    }

    const request = {
      groupName: name,
      meterUid: meter && meter.uid ? meter.uid : '',
      fromDateTime: formatRequestDate(periodStartDate, timeZone),
      toDateTime: formatRequestDate(periodEndDate, timeZone),
      includeEstimates,
      isEstimate,
    };

    const { status, body } = await api.getTransactionsList(request, token);

    if (successRegex.test(status)) {
      dispatch(updateTransactionsList(body.resultList, isCurrent));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve transactions at this time!'),
      });
    }

    return status !== 499;
  };

export const getMeterRank =
  (props, token = null) =>
  async (dispatch) => {
    const { meterGroup, meter, startDate, endDate, includeEstimates, isEstimate } = props;
    const { timeZone } = meterGroup;

    const request = {
      groupName: meterGroup && meterGroup.name ? meterGroup.name : 0,
      meterUid: meter && meter.uid ? meter.uid : 0,
      fromDateTime: formatRequestDate(startDate, timeZone),
      toDateTime: formatRequestDate(endDate, timeZone),
      includeEstimates,
      isEstimate,
    };

    const { status, body } = await api.getMeterRank(request, token);

    if (successRegex.test(status)) {
      const { rankings } = body;
      dispatch(updateMeterRank(rankings));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve rankings at this time!'),
      });
    }

    return status !== 499;
  };

export const getBillingPeriods =
  (props, token = null) =>
  async (dispatch) => {
    const { selectedMeter, meter } = props;
    let meterUid = meter && meter.uid ? meter.uid : '';
    meterUid = selectedMeter && selectedMeter.uid ? selectedMeter.uid : meterUid;

    const { status, body } = await api.getBillingPeriods(meterUid, token);

    let numBillingPeriods = 0;

    if (successRegex.test(status)) {
      const { billingPeriods } = body;

      if (billingPeriods && billingPeriods.length) {
        numBillingPeriods = billingPeriods.length;
        const lastBillingPeriod = billingPeriods[billingPeriods.length - 1];
        const { billingPeriodStartDate, billingPeriodEndDate } = lastBillingPeriod;
        const userBillingFrequency = getBillingFrequency(billingPeriodStartDate, billingPeriodEndDate);

        await Promise.resolve(
          dispatch(updateBillingPeriod(billingPeriodStartDate, billingPeriodEndDate, userBillingFrequency))
        );
      } else {
        errorNotification({
          code: 400,
          errorCode: body.errorCode,
          description: i18n.t('Could not find any billing period!'),
        });
      }
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve billing periods at this time!'),
      });
    }

    return numBillingPeriods;
  };

export const getTransactionSummaries =
  (request, isAdmin, hasMoreBillingPeriods, selectedMeter, token) => async (dispatch) => {
    const dataRequestList = isAdmin
      ? [
          dispatch(getGroupedTransactionSummary(request, token)), // current period's group
          dispatch(getGroupedTransactionsList(request, token)), // current period's daily
          ...(selectedMeter
            ? [
                dispatch(getTransactionSummary(request, token)), // current period's meter summary
                dispatch(getTransactionsList(request, token)), // current period's meter daily
              ]
            : [true]),
          ...(hasMoreBillingPeriods
            ? [
                dispatch(getGroupedTransactionSummary(request, token, false)),
                dispatch(getGroupedTransactionsList(request, token, false)),
                ...(selectedMeter
                  ? [
                      dispatch(getTransactionSummary(request, token, false)),
                      dispatch(getTransactionsList(request, token, false)),
                    ]
                  : [true]),
              ]
            : [true]),
        ]
      : [
          dispatch(getTransactionSummary(request, token)), // current period's meter summary
          dispatch(getTransactionsList(request, token)), // current period's meter daily
          ...(hasMoreBillingPeriods
            ? [
                dispatch(getTransactionSummary(request, token, false)),
                dispatch(getTransactionsList(request, token, false)),
              ]
            : [true]),
          ...(groupHasFeature('COMMUNITY_CHART', request.meterGroup)
            ? [
                dispatch(getGroupedTransactionSummary(request, token)),
                dispatch(getGroupedTransactionsList(request, token)),
                hasMoreBillingPeriods ? dispatch(getGroupedTransactionsList(request, token, false)) : true,
                groupHasFeature('COMMUNITY_RANKING', request.meterGroup)
                  ? dispatch(getMeterRank(request, token))
                  : true,
              ]
            : [true]),
        ];

    const responseList = await Promise.all(dataRequestList);

    return !responseList.filter((val) => !val).length;
  };

export const getTotalSavings =
  (props, token = null, isCurrent = true) =>
  async (dispatch) => {
    const { daysInRange, startDate, endDate, meterGroup, meter, includeEstimates } = props;
    const { name, timeZone } = meterGroup;
    let periodStartDate = startDate;
    let periodEndDate = endDate;

    if (!isCurrent) {
      periodStartDate = getLastPeriodDate(startDate, timeZone, daysInRange);
      periodEndDate = getLastPeriodDate(endDate, timeZone, daysInRange);
    }

    const request = {
      groupName: name,
      meterUid: meter && meter.uid ? meter.uid : '',
      fromDateTime: formatRequestDate(periodStartDate, timeZone),
      toDateTime: formatRequestDate(periodEndDate, timeZone),
      includeEstimates,
    };

    const { status, body } = await api.getTotalSavings(request, token);

    if (successRegex.test(status)) {
      dispatch(updateTotalSavings(body, isCurrent));
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve savings at this time!'),
      });
    }

    return status !== 499;
  };

export const getUserAccounts =
  (token = null) =>
  async (dispatch) => {
    const { status, body } = await api.getUserAccounts(token);

    if (successRegex.test(status)) {
      const { userAccounts } = body;
      let accountId = 0;
      let accountNumber = '';
      let accountBalance = 0;
      let billingFrequency = 'monthly';

      if (userAccounts && userAccounts.length) {
        accountId = userAccounts[0].id;
        accountNumber = userAccounts[0].accountNumber;
        accountBalance = userAccounts[0].balance;
        billingFrequency = userAccounts[0].billingPeriodFrequency;
      }

      await dispatch(updateUserAccount(accountId, accountNumber, accountBalance, billingFrequency));
    } else if (status !== 499) {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve accounts at this time!'),
      });
    }

    return status !== 499;
  };

export const getSiteWeather =
  (meterGroup, token = null) =>
  async (dispatch) => {
    const { status, body } = await api.getSiteWeather(meterGroup.id, token);

    if (successRegex.test(status)) {
      dispatch(updateSiteWeather(body));
    } else if (status !== 499) {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description:
          body && body.errorMessage
            ? body.errorMessage
            : i18n.t('Could not retrieve weather forecast at this time!'),
      });
    }

    return status !== 499;
  };

export const initDashboard =
  (
    {
      startDate,
      endDate,
      daysInRange,
      meterGroup,
      selectedMeter,
      meters = [{ uid: '' }],
      includeEstimates,
      isEstimate = false,
      defaultMeter = [{ uid: '' }],
    },
    token = null,
    isAdmin = false
  ) =>
  async (dispatch) => {
    const request = {
      startDate,
      endDate,
      daysInRange,
      meterGroup,
      meter: selectedMeter ? selectedMeter : defaultMeter || meters[0],
    };

    let hasMoreBillingPeriods = isAdmin;

    if (!isAdmin) {
      dispatch(getTotalSavings(request, token)).catch(() => {
        console.error('Error getting savings');
      });

      const dashboardRequestList = [];
      dashboardRequestList.push(dispatch(getUserAccounts(token)));
      dashboardRequestList.push(dispatch(getSiteWeather(meterGroup, token)));

      const responseList = await Promise.all(dashboardRequestList);
      if (!responseList[0]) throw new Error('Error getting user accounts');

      const numBillingPeriods = await dispatch(getBillingPeriods(request, token));
      if (numBillingPeriods === 0) throw new Error('No Billing Periods');
      hasMoreBillingPeriods = numBillingPeriods > 1;
    }

    const status = await dispatch(
      getTransactionSummaries(
        { ...request, includeEstimates, isEstimate },
        isAdmin,
        hasMoreBillingPeriods,
        selectedMeter,
        token
      )
    );

    return status;
  };
