import { useCallback, useState } from 'react';
import { Link } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '..';
import { useTranslatedFile } from '../../hooks/use-translated-file';
import { useLanguageOverride } from '../../hooks/use-language-override';
import { NewModal } from '../new-ui/new-modal';
import { useNewUIEnabled } from '../../hooks/use-new-ui-enabled';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { lngOverride, toggleLngOverride, browserIsEnglish } = useLanguageOverride();
  const toggleModal = useCallback((isVisible) => setVisible(isVisible), []);
  const isNewUIEnabled = useNewUIEnabled();

  const togglePolicyButtonText = lngOverride ? t('View in local language') : t('View in English');

  const togglePolicyLink = (
    <Link mx={3} onClick={toggleLngOverride} sx={{ cursor: 'pointer', userSelect: 'none' }}>
      {`( ${togglePolicyButtonText} )`}
    </Link>
  );

  const { File: Policy, loading } = useTranslatedFile('translation', 'privacyPolicy', lngOverride);
  return (
    <>
      <Button variant="text" onClick={() => toggleModal(true)}>
        {t('Privacy Policy')}
      </Button>
      {isNewUIEnabled ? (
        <NewModal
          header={`${t('Privacy Policy')} ${!browserIsEnglish ? togglePolicyLink : ''}`}
          open={visible}
          onClose={() => toggleModal(false)}
          footer={null}
        >
          {loading ? null : Policy}
        </NewModal>
      ) : (
        <Modal
          title={
            <>
              {t('Privacy Policy')} {!browserIsEnglish && togglePolicyLink}
            </>
          }
          visible={visible}
          onCancel={() => toggleModal(false)}
          footer={null}
        >
          {loading ? null : Policy}
        </Modal>
      )}
    </>
  );
};

export default PrivacyPolicy;
