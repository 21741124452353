import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentPage } from '../../../hooks/use-current-page';
import { useCurrentSubpage } from '../../../hooks/use-current-subpage';

type PageConfigType = {
  subpages: string[];
};

export const unlimitedDateRangePageConfig: Record<string, PageConfigType> = {
  transactions: { subpages: [] },
  usage: { subpages: [] },
  billing: { subpages: [] },
};

export interface UsePeriodRangeOptionsProps {
  unlimited?: boolean;
}

export const usePeriodRangeOptions = (props?: UsePeriodRangeOptionsProps) => {
  const { t } = useTranslation();
  const currentPage = useCurrentPage();
  const currentSubpage = useCurrentSubpage();

  const pageConfig = unlimitedDateRangePageConfig[currentPage.toLowerCase()];
  const hasUnlimitedDateRange =
    props?.unlimited ||
    (pageConfig &&
      (pageConfig.subpages.length === 0 || pageConfig.subpages.includes(currentSubpage.toLowerCase())));

  const limit = hasUnlimitedDateRange ? 0 : 366;

  const periodRangeOptions = useMemo(
    () => [
      { value: 'prevFourWeek', label: t('Previous 4 Week Period') },
      { value: 'day', label: t('Day') },
      { value: 'week', label: t('Week') },
      { value: 'month', label: t('Month') },
      { value: 'year', label: t('Year') },
      { value: 'custom', label: t('Custom') },
    ],
    [t]
  );

  return {
    periodRangeOptions,
    limit,
  };
};
