import { Box, Flex } from 'theme-ui';
import React, { memo, useCallback, useEffect } from 'react';
import { pageview } from 'react-ga';
import { shallowEqual } from 'react-redux';
import { registerUserEvent } from '../../lib/auth-helpers';
import { ProfileIcon } from '../../components/icons/app-bar/Profile';
import { useFeatureSet } from '../../hooks/use-feature-set';
import { HeaderDropdown } from '../common/header-dropdown';
import { useCurrentPage } from '../../hooks/use-current-page';
import { useHostConfig } from '../../contexts/host-config-context';
import { updateSelectedMeter } from '../../states/slices/controls';
import { useAppSelector } from '../../hooks/use-app-selector';
import { useIsAdmin } from '../../hooks/use-is-admin';
import { useCurrentSubpage } from '../../hooks/use-current-subpage';
import { useAppDispatch } from '../../hooks/use-app-dispatch';
import { useHasAllMetersOption } from '../../hooks/use-has-all-meters-option';
import { useAggregatedMeter } from '../../hooks/use-aggregated-meter';
import { CloseOutlined, Hamburger } from '../../components';
import { useScreenSize } from '../../hooks/use-screen-size';
import { WeatherInfo } from './weather-info';
import { DateTimeInfo } from './date-time-info';
import { MeterSelector } from './meter-selector';
import { NotificationInfo } from './notification-info';

interface NewHeaderProps {
  collapseMenu: () => void;
  menuCollapsed: boolean;
}

export const NewHeader: React.FC<NewHeaderProps> = ({ collapseMenu, menuCollapsed }) => {
  const { hasNotifications } = useFeatureSet();
  const { isAdmin } = useIsAdmin();
  const dispatch = useAppDispatch();
  const currentPage = useCurrentPage();
  const currentSubpage = useCurrentSubpage();
  const meters = useAppSelector(({ meters: metersStore }) => metersStore.meters, shallowEqual);
  const selectedMeter = useAppSelector(({ controls }) => controls.selectedMeter, shallowEqual);
  const aggregatedMeter = useAggregatedMeter();
  const { isMediumDown } = useScreenSize();

  const { hostHasFlag } = useHostConfig(true);
  const isVB = hostHasFlag('isVB');
  const isDashboard = !currentPage || currentPage === 'Dashboard';
  const shouldDisplayWeatherInfo = isDashboard && !isVB;

  const navigate = useCallback(() => {
    const pagePath = window.location.pathname + window.location.search;

    pageview(pagePath);
    registerUserEvent(`navigated to ${pagePath.substring(1)}`, 'navigation');
    window.scrollTo(0, 0);
  }, []);

  const { hasAllMeterOption } = useHasAllMetersOption();

  useEffect(() => {
    // For non-admin users
    if (!isAdmin) {
      // If the page has all meter option and selectedMeter is null/undefined, select the All Meter option
      if (hasAllMeterOption && !selectedMeter) {
        dispatch(updateSelectedMeter(aggregatedMeter));
      }
      // Else, if the page don't have all meter option and selectedMeter?.uid is null (meaning selected meter is All Meter)
      // And user has a list of meters, then select the first meter
      else if (!hasAllMeterOption && !selectedMeter?.uid && meters?.length) {
        dispatch(updateSelectedMeter(meters[0]));
      } else if (meters?.length === 0) {
        dispatch(updateSelectedMeter(null));
      }
    }
  }, [
    currentPage,
    currentSubpage,
    dispatch,
    isAdmin,
    meters,
    hasAllMeterOption,
    selectedMeter,
    aggregatedMeter,
  ]);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '72px',
        padding: ['16px 16px', '16px 16px', '18px 28px'],
        borderBottom: '1px solid #F9FAFB',
        background: 'newUISecondary',
      }}
    >
      {!isMediumDown ? (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DateTimeInfo />
          {shouldDisplayWeatherInfo ? <WeatherInfo /> : null}
        </Flex>
      ) : null}

      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: ['space-between', 'space-between', 'center'],
          width: ['100%', '100%', 'auto'],
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: ['flex', null, 'none'],
            outline: '0',
            cursor: 'pointer',
            '&:focus': {
              boxShadow: 'focus',
            },
          }}
          tabIndex={0}
          onClick={() => collapseMenu?.()}
          onKeyDown={(e) => {
            const key = e.code;

            return key === 'Enter' || key === 'Space' ? collapseMenu?.() : e;
          }}
        >
          {menuCollapsed ? <Hamburger color="#101828" size={4} /> : <CloseOutlined color="white" size={5} />}
        </Box>

        <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
          <MeterSelector />
          {hasNotifications && <NotificationInfo navigate={navigate} />}
          <HeaderDropdown
            navigate={navigate}
            dropdownStyles={{
              top: ['40px', 'auto'],
              left: 'auto',
            }}
            activator={
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: 'newUIPrimary',
                  padding: '10px',
                  width: '40px',
                  height: '40px',
                  '&:hover': {
                    bg: 'newUIPrimaryLighter',
                    borderRadius: '8px',
                  },
                }}
              >
                <ProfileIcon size={7} />
              </Flex>
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(NewHeader);
