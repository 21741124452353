/** @jsxImportSource theme-ui */
import { FC } from 'react';
import { Themed, Box } from 'theme-ui';

export type ChecklistProps = { items?: Array<any> };

export type ChecklistItemProps = { active?: boolean; label: any };
export type ChecklistItemType = FC<ChecklistItemProps>;

const ChecklistItem: ChecklistItemType = ({ label, active = false }) => (
  <Themed.li
    sx={{
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      width: ['100%', null, '50%'],
      pr: [0, null, 3],
      mb: 2,
    }}
  >
    <Box
      sx={{
        width: '8px',
        height: '8px',
        bg: 'muted',
        borderRadius: '6',
        transition: 'base',
        ...(active ? { bg: 'primary' } : {}),
      }}
    />
    <Box sx={{ ml: 2 }}>{label}</Box>
  </Themed.li>
);

export const Checklist: FC<ChecklistProps> = ({ items = [] }) => (
  <Themed.ul
    sx={{
      listStyleType: 'none',
      p: 0,
      m: 0,
      display: 'flex',
      flexWrap: 'wrap',
    }}
  >
    {items.map((val: any) => (
      <ChecklistItem active={val.active} label={val.label} key={val.label} />
    ))}
  </Themed.ul>
);
