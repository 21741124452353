import React from 'react';
import PropTypes from 'prop-types';

export const Edit = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    aria-labelledby="title-edit"
    aria-describedby="desc-edit"
    role="img"
  >
    <title id="title-edit">Edit icon</title>
    <description id="desc-edit">A symbol representing a simple edit.</description>
    <path
      d="M11.3209 0.678759C10.8683 0.226336 10.2749 0 9.68138 0C9.08791 0 8.49468 0.226336
    8.04183 0.678759L1.10615 7.61187C0.973962 7.744 0.87848 7.90811 0.828632 8.08809L0.030307
    10.9712C-0.117255 11.5044 0.295175 12 0.809039 12C0.881208 12 0.955114 11.9903 1.02976
    11.9695L3.90958 11.1673C4.08889 11.1172 4.25233 11.022 4.38402 10.8904L11.3207 3.95653C12.2264
    3.05119 12.2264 1.58385 11.3209 0.678759ZM3.60156 10.0951L1.24949 10.7503L1.90174
    8.3945L7.58897 2.70933L9.28953 4.40921L3.60156 10.0951ZM10.5318 3.16746L10.0787 3.62038L8.37812
    1.92051L8.83122 1.46759C9.05839 1.24051 9.36022 1.11556 9.68163 1.11556C10.0028 1.11556 10.3049
    1.24051 10.532 1.46759C11.0008 1.93637 11.0008 2.69892 10.5318 3.16746Z"
      fill="#ffffff"
    />
  </svg>
);

Edit.defaultProps = {
  width: '12px',
  height: '12px',
};

Edit.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
