import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const defaultCardStyles: ThemeUIStyleObject = {
  flex: 1,
  bg: 'secondaryDark',
  borderRadius: 4,
};

export const card = CustomThemeProperty('card', {
  primary: {
    ...defaultCardStyles,
    p: 4,
    dark: {
      ...defaultCardStyles,
      p: 4,
      bg: 'secondaryDarker',
    },
  },
  compact: {
    ...defaultCardStyles,
    p: 0,
    dark: {
      ...defaultCardStyles,
      p: 0,
      bg: 'secondaryDarker',
    },
  },
});
