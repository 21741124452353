import { CustomThemeProperty } from './types';

export const barChart = CustomThemeProperty('barChart', {
  item: {
    width: '100%',
    marginBottom: 2,
    fontWeight: '500',
  },
  value: {
    alignItems: 'center',
    width: '100%',
    marginBottom: 1,
  },
  bar: {
    height: 2,
    borderRadius: 5,
    bg: 'primary',
    transition: 'base',
    transitionDelay: '1s',
  },
});
