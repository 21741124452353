import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const sharedItemStyles: ThemeUIStyleObject = {
  fontFamily: 'MintGroteskV08',
  fontSize: 1,
  listStyle: 'none',
  pl: 4,
  cursor: 'pointer',
  color: 'white',
  my: 2,
  outline: '0',
};

export const menu = CustomThemeProperty('menu', {
  display: 'flex',
  flexDirection: 'column',
  p: 0,
  m: 0,
  groups: {
    ...sharedItemStyles,
    '&:hover, &:focus': {
      bg: 'secondaryDark',
    },
  },
  items: {
    ...sharedItemStyles,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: 'primary',
    },
    '&:focus': {
      color: 'primary',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 5,
      width: 5,
      mr: 2,
      borderRadius: 3,
      'div svg path': {
        transition: 'base',
        color: 'white',
        fill: 'white',
      },
    },
  },
});
