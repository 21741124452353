import styled from '@emotion/styled';
import { variant as styledSystemVariant } from 'styled-system';
import { css } from 'theme-ui';

import { SwitchButtonProps } from './switch.types';

export const SwitchButton = styled.div<SwitchButtonProps>`
  ${styledSystemVariant({
    scale: 'switches.buttons',
    variants: {
      primary: null,
      inverted: null,
    },
  })}
  ${({ leftMost }) => leftMost && css({ borderTopLeftRadius: 6, borderBottomLeftRadius: 6 })}
  ${({ rightMost }) => rightMost && css({ borderTopRightRadius: 6, borderBottomRightRadius: 6 })}
  ${({ active, theme, variant = 'primary' }) =>
    active &&
    (theme as any).switches.buttons[variant] &&
    css({
      ...(theme as any).switches.buttons[variant].active,
      backgroundColor: (theme as any).colors.primary,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: (theme as any).colors.primaryDark,
      },
    })}
    ${({ active, theme }) =>
    !active &&
    css({
      backgroundColor: '#ffffff',
      color: (theme as any).colors.textLight,
      '&:hover': {
        backgroundColor: (theme as any).colors.mutedLightest,
      },
    })}
`;
