import { useMemo } from 'react';
import { linearGradientDef } from '@nivo/core';

import { useStandardChartConfig, UseStandardChartConfigProps } from '../hooks';

export const useStandardLineChartConfig = ({
  width,
  legendData,
  legendConfigOverrides,
}: UseStandardChartConfigProps): any => {
  const standardConfig = useStandardChartConfig({
    width,
    legendData,
    legendConfigOverrides,
    alwaysDisplayLegend: true,
  });

  return useMemo(
    () => ({
      ...standardConfig,
      enableArea: true,
      areaOpacity: 0.5,
      areaBlendMode: 'normal',
      areaBaselineValue: 0,

      enableGridX: false,
      lineWidth: 1,
      curve: 'monotoneX',

      useMesh: true,

      yScale: { type: 'linear', min: 0, max: 'auto', reverse: false },
      yFormat: '>-.2f',

      pointColor: { theme: 'background' },
      pointBorderWidth: 1,
      pointBorderColor: { from: 'serieColor' },
      pointSize: 5,
      pointBackgroundColor: 'black',

      defs: [
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0 },
        ]),
      ],
      fill: [{ match: '*', id: 'gradientA' }],
    }),
    [standardConfig]
  );
};
