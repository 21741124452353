import { combineReducers, AnyAction } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';

import allReducers from '../reducers';
import leaderboard from '../slices/leaderboard';
import host from '../slices/host';
import account from '../slices/account';
import auth from '../slices/auth';
import meters from '../slices/meters';
import page from '../slices/page';
import controls from '../slices/controls';
import communityMap from '../slices/community-map';
import loyaltyP2P from '../slices/loyalty-p2p';
import ioaManagement from '../slices/ioa-management';
import aggregatedExcess from '../slices/aggregated-excess';
import { initialState } from './initial-state';

// Dispatch an action with type RESET_STORE to reset the store.
// For example, dispatch it when a user logs out.
export const RESET_STORE = 'RESET_STORE';

const appReducer = combineReducers({
  initialState: initialState as any,
  ...allReducers,
  leaderboard,
  host,
  account,
  auth,
  meters,
  page,
  controls,
  communityMap,
  loyaltyP2P,
  ioaManagement,
  aggregatedExcess,
});

export const rootReducer = (state: RootStateOrAny, action: AnyAction) => {
  const nextState = action.type === RESET_STORE ? { host: state.host, controls: {} } : state;

  return appReducer(nextState, action);
};
