import { AssetType, Meter, MeterGroup, TradeType } from '../../../../types';
import { hostTradeTypeLabels } from '../../states/selectors/transactions';
import { GetAvailableTradeTypeFilterOptionsProps } from './trade.types';

export const canMeterSell = (meter: Meter) => {
  return [AssetType.PROSUMER, AssetType.PRODUCER].includes(meter.assetType);
};

export const getTradeTypeByMeterAssetType = (assetType?: AssetType) => {
  if (!assetType || [AssetType.PROSUMER, AssetType.CONSUMER].includes(assetType)) return TradeType.BOUGHT;

  return TradeType.SOLD;
};

type NiceStatusTransMapType = (t: (text: string) => string) => {
  [key: string]: string;
};

export const niceStatusTransMap: NiceStatusTransMapType = (t) => ({
  Accepted: t('Accepted'),
  Active: t('Active'),
  Confirmed: t('Confirmed'),
  Completed: t('Completed'),
  Expired: t('Expired'),
  Cancelled: t('Cancelled'),
  Withdrawn: t('Withdrawn'),
  Declined: t('Declined'),
  'Pending acceptance': t('Pending acceptance'),
  'Pending cancellation': t('Pending cancellation'),
});

export const COLOR_BY_STATUS: Record<string, 'success' | 'warning' | 'error' | 'disabled'> = {
  Accepted: 'success',
  Active: 'success',
  Confirmed: 'success',
  Completed: 'disabled',
  Expired: 'disabled',
  Cancelled: 'error',
  Withdrawn: 'error',
  Declined: 'error',
  'Pending acceptance': 'warning',
  'Pending cancellation': 'warning',
};

export const getAvailableTradeTypeFilterOptions = ({
  t,
  lang,
  meterGroup,
  hasVPP = false,
  hideRedundantTradeTypes = false,
}: GetAvailableTradeTypeFilterOptionsProps) => {
  return hostTradeTypeLabels(t, meterGroup, hideRedundantTradeTypes)
    .filter((v) => hasVPP || (!hasVPP && v.label !== 'VPP Event'))
    .map(({ type, labelText }) => {
      let label = labelText;

      // This is a hacky/ad-hoc fix for ESTMK to avoid duplicated checkbox labels
      if (type[0] === 'DYNAMIC' && lang.includes('de')) {
        label = `${labelText} (Dynamisch)`;
      }

      return { type, label };
    });
};
