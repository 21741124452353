import { MutableRefObject } from 'react';
import { AuthAdaptorBase } from '../adaptors/adaptor-types';

type AuthAdaptorClient = AuthAdaptorBase['client'];

type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R ? (...args: P) => R : never;

/**
 * Verifies that a client has been generated and then applies it to the first parameter of the given function
 * @param adaptorClient
 * @param fn
 */
export const applyAdaptorsClient = <
  P extends any[],
  F extends (client: AuthAdaptorClient, ...args: P) => any
>(
  adaptorClient: MutableRefObject<AuthAdaptorClient | null>,
  fn: F
): OmitFirstArg<F> => {
  const client = adaptorClient.current;

  if (!client) {
    throw new Error('Client not Defined');
  }

  const resp: any = (...params: P) => fn(client, ...params);
  return resp;
};
