import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuthService } from '@power-ledger/auth-service';

import { LoadingOverlay } from '../../../components';
import { useHostConfig } from '../../../contexts/host-config-context';
import { errorNotification } from '../../../lib/notifications';
import * as api from '../../../middleware/api';

export const LoginCallback = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [code] = useQueryParam('code', StringParam);
  const [state] = useQueryParam('state', StringParam);
  const [error] = useQueryParam('error', StringParam);

  const { tradingGroup } = useHostConfig(true);
  const { useAuthCallbackHandler, getTokens, signOut } = useAuthService();
  const { loginCallback } = useAuthCallbackHandler();

  useEffect(() => {
    if (error) {
      errorNotification({
        description: t('User can not be authenticated'),
      });
      history.push({
        pathname: '/',
      });
      return;
    }
    if (code && state && !error) {
      loginCallback({ code, state }).then(async () => {
        const tokens = await getTokens();

        if (!tokens) {
          errorNotification({
            description: t('An unexpected error occurred. Please try again or contact an administrator.'),
          });
          history.push({
            pathname: '/',
          });
          return;
        }

        const { body } = await api.getUserProfileAuth(tokens?.accessToken);

        if (tradingGroup.ref !== body.tradingGroupName) {
          await signOut();
          errorNotification({
            description: t("The credentials that you've entered are incorrect."),
          });
        } else {
          history.push({
            pathname: '/dashboard',
          });
        }
      });
    }
  }, [
    useAuthCallbackHandler,
    code,
    getTokens,
    history,
    signOut,
    state,
    tradingGroup.ref,
    loginCallback,
    error,
    t,
  ]);

  return <LoadingOverlay />;
};
