import { LogoPlSVGLazy } from '@power-ledger/assets';
import { Image } from 'theme-ui';

export const HostLogoView: React.FC<{
  logo: string | undefined;
  maxHeight?: string;
  maxWidth?: string;
  isInverse: boolean;
}> = ({ logo, maxHeight = '92px', maxWidth = '220px', isInverse }) =>
  logo ? (
    <Image
      src={logo}
      alt="Host Logo"
      data-testid="host-logo"
      style={{
        width: maxWidth,
        maxHeight,
        objectFit: 'contain',
      }}
    />
  ) : (
    <LogoPlSVGLazy
      height={undefined}
      width="100%"
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      isReverse={isInverse}
    />
  );
