import { useEffect, useRef } from 'react';

export const useMountIndicator = () => {
  const mounted = useRef(true);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  return { mounted };
};
