import { Theme, ThemeUIStyleObject } from 'theme-ui';

const commonFontStyle: ThemeUIStyleObject = {
  fontSize: 1,
  fontWeight: 'light',
};

const commonFormInputStyles: ThemeUIStyleObject = {
  width: '100%',
  minWidth: 10,
  borderColor: 'greyLightest',
  borderStyle: 'solid',
  borderRadius: 4,
  height: [6, 8],
  transition: 'base',
  ...commonFontStyle,
};

const option: ThemeUIStyleObject = {
  ...commonFontStyle,
  px: 3,
  cursor: 'pointer',
  py: 2,
  '&:hover': {
    bg: 'accentLightest',
  },
};

const themeForms = {
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 1,
    fontWeight: 'light',
    color: 'greyDarkest',
  },
  input: {
    ...commonFormInputStyles,
    '&:hover': {
      borderColor: 'grey',
    },
    '&:focus': {
      outline: '0',
      borderColor: 'grey',
    },
    '&:disabled': {
      bg: 'muted',
    },
  },
  compactInput: {
    variant: 'forms.input',
    p: 2,
    height: 6,
  },
  loginInput: {
    variant: 'forms.input',
    height: 8,
  },
  errorInput: {
    ...commonFormInputStyles,
    borderColor: 'warning',
    '&:focus': {
      outline: '0',
    },
    '&:disabled': {
      bg: 'muted',
    },
  },
  errorMessage: {
    color: 'warning',
    fontWeight: 'light',
    fontSize: 1,
  },
  checkbox: {
    color: 'primary',
    bg: 'white',
  },
  textarea: {
    width: '100%',
    borderColor: 'lightgrey',
    transition: 'base',
    fontSize: 1,
    '&:hover': {
      borderColor: 'darkgrey',
    },
    '&:focus': {
      outline: '0',
      borderColor: 'darkgrey',
    },
    '&:disabled': {
      bg: 'muted',
    },
  },
  select: {
    control: {
      ...commonFormInputStyles,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 4,
      '&:hover': {
        borderColor: 'primary',
      },
      '&:focus': {
        outline: '0',
        borderColor: 'primary',
      },
      '&:disabled': {
        bg: 'muted',
      },
    },
    placeholder: {
      ...commonFontStyle,
    },
    dropdownIndicator: {
      svg: { transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)' },
      p: [2, 3],
      lineHeight: 0,
    },
    indicatorSeparator: {
      display: 'none',
    },
    option,
    optionActive: {
      ...option,
      bg: 'accentDark',
      color: 'white',
      '&:hover': {
        bg: 'accentDark',
      },
    },
    optionDisabled: {
      ...option,
      bg: 'muted',
      '&:hover': {
        bg: 'muted',
      },
    },
  },
  slider: {
    width: '100%',
    color: 'primary',
    bg: 'muted',
  },
  radio: {
    color: 'greyLight',
    '&:active': {
      color: 'primary',
    },
    '&:disabled': {
      color: 'muted',
    },
  },
  checkboxLabel: {
    fontSize: 1,
    cursor: 'pointer',
  },
  radioLabel: {
    fontSize: 1,
  },
  itemGroupLabel: {
    color: 'grey',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    pl: 3,
    fontSize: 1,
  },
};

export const forms = themeForms as typeof themeForms & Theme['forms'];
