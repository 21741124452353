import { FC } from 'react';
import { CountryRegionData } from 'react-country-region-selector';

import { Select } from '../..';
import { CountrySelectProps } from './CountrySelect.types';

export const CountrySelect: FC<CountrySelectProps> = ({
  placeholder,
  inputValue,
  onChange,
  onBlur,
  disabled,
}) => {
  const countryOptions = CountryRegionData.map(([countryName, shortCode]) => ({
    label: countryName,
    value: shortCode,
  }));

  return (
    <Select
      value={countryOptions.find((c) => c.value === inputValue) || ''}
      options={countryOptions}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};

export default CountrySelect;
