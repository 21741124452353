import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18n as I18n } from 'i18next';

import { WrappedDynamicFile } from '../assets/terms';
import { useHostConfig } from '../contexts/host-config-context';
import { fallbackLng } from '../lib/i18n';

const findLanguage = (i18n: I18n, ns: string, key: string) =>
  i18n.languages.find((lng) => i18n.getResource(lng, ns, key) !== undefined);

// TODO: Would like to review this at a later date.
export const useTranslatedFile = (ns: string, key: string, lngOverride?: string | null | undefined) => {
  const { t, i18n } = useTranslation();
  const [File, setFile] = useState<any>(false);
  const { tradingGroup } = useHostConfig(true);
  const hostname = tradingGroup.ref.toLowerCase();

  useEffect(() => {
    const lngInUse =
      findLanguage(i18n, ns, `${hostname}.${key}.${lngOverride}`) ||
      lngOverride ||
      i18n.language ||
      fallbackLng;
    i18n.addResource(lngInUse, ns, `${hostname}.${key}.${lngOverride}`, (() => (
      <WrappedDynamicFile lng={lngInUse} translationKey={key} hostname={hostname} />
    )) as any);
    setFile(t(`${hostname}.${key}.${lngOverride}`));
  }, [i18n, key, ns, t, hostname, lngOverride]);

  return { File, loading: File === false };
};
