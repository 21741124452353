import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Flex, Box, Button, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { getMyMeterLocations, getMyNearbyMeterLocations } from '../../states/slices/community-map';
import { CommunityMapState } from '../../../../types';
import { Card, MeterMap } from '..';
import { LoadingOverlay } from '../../components';
import { defaultMapStyles } from './community-map.styles';

export const CommunityMap = memo(() => {
  const { t } = useTranslation();
  const [myMeterLocationsLoaded, setMyMeterLocationsLoaded] = useState(false);
  const meterMapRef = useRef<{ handleHome: () => void } | null>(null);

  const dispatch = useDispatch();
  const myMeterLocations = useSelector(
    ({ communityMap }: { communityMap: CommunityMapState }) => communityMap.myMeterLocations
  );
  const myNearbyMeterLocations = useSelector(
    ({ communityMap }: { communityMap: CommunityMapState }) => communityMap.myNearbyMeterLocations
  );

  useEffect(() => {
    let mounted = true;
    if (!myMeterLocationsLoaded) {
      Promise.all([dispatch(getMyMeterLocations())]).then(() => {
        if (!mounted) {
          return;
        }
        setMyMeterLocationsLoaded(true);
      });
    }
    return () => {
      mounted = false;
    };
  }, [dispatch, myMeterLocationsLoaded, myMeterLocations]);

  const loadNeighbouringMeters = (center: google.maps.LatLng, radiusInMetres: number) => {
    if (myMeterLocationsLoaded) {
      dispatch(
        (getMyNearbyMeterLocations as any)({
          props: {
            latitude: center.lat(),
            longitude: center.lng(),
            radiusInMetres,
          },
        })
      );
    }
  };

  return (
    <>
      {!myMeterLocationsLoaded && (
        <LoadingOverlay
          active={!myMeterLocationsLoaded}
          text={<span>{t('Loading Energy Community Map...')}</span>}
        />
      )}

      {myMeterLocationsLoaded && (
        <Card
          header={
            <Flex
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Heading as="h2" sx={{ paddingY: 1 }}>
                {t('Energy Community Map')}
              </Heading>
              <Button onClick={meterMapRef.current?.handleHome}>
                <Text sx={{ fontSize: 1 }}>{t('Meter Home Location')}</Text>
              </Button>
            </Flex>
          }
          bodyStyles={{
            padding: '8px !important',
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column', null, 'row'],
              alignItems: ['flex-start', null, 'center'],
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flex: '1 1 auto', mb: [3, null, 0], width: '100%' }}>
              {myMeterLocations && (
                <MeterMap
                  ref={meterMapRef}
                  myMeterLocations={myMeterLocations}
                  myNearbyMeterLocations={myNearbyMeterLocations}
                  loadNeighbouringMeters={loadNeighbouringMeters}
                  mapStyles={defaultMapStyles}
                />
              )}
            </Box>
          </Flex>
        </Card>
      )}
    </>
  );
});
