import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import pick from 'lodash/pick';

import i18n from '../../lib/i18n';
import { errorNotification, successNotification, successRegex } from '../../lib/notifications';
import { getUserProfile, updateCurrentPassword } from '../../middleware/api';
import { getTimeFormatFromLocalStorage } from '../../lib/storage';

export const getAccountDetails = createAsyncThunk(
  'account/getAccountDetails',
  async ({ token = null }, { rejectWithValue }) => {
    const { status, body } = await getUserProfile(token);

    if (successRegex.test(status)) {
      return { ...body };
    }
    return rejectWithValue({ body: { ...body }, status });
  }
);

export const updatePassword = createAsyncThunk(
  'account/updatePassword',
  async ({ token = null, ...creds }, { rejectWithValue }) => {
    const { status, body } = await updateCurrentPassword({ ...creds, token });
    if (successRegex.test(status)) {
      return { ...body };
    }
    return rejectWithValue({ body: { ...body }, status });
  }
);

export const updateTimeFormat = createAsyncThunk('account/updateTimeFormat', async ({ timeFormat }) => {
  return { timeFormat };
});

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  mobile: '',
  addressLine: '',
  suburb: '',
  state: '',
  postcode: '',
  country: '',
  meterUids: [],
  batterySerials: [],
  mfaMandatory: false,
  timeFormat: getTimeFormatFromLocalStorage(),
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetAccount(state) {
      state.account = initialState;
    },
  },
  extraReducers: {
    [getAccountDetails.fulfilled]: (state, { payload }) => ({
      ...initialState,
      ...pick(payload, [
        'firstname',
        'lastname',
        'email',
        'mobile',
        'addressLine',
        'suburb',
        'state',
        'postcode',
        'country',
        'meterUids',
        'batterySerials',
        'mfaMandatory',
      ]),
      timeFormat: getTimeFormatFromLocalStorage(),
    }),

    [getAccountDetails.rejected]: (state, { payload: { body, status } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve account details at this time!'),
      });
    },

    [updatePassword.fulfilled]: () => {
      successNotification({
        description: i18n.t('Password has been updated!'),
      });
    },

    [updatePassword.rejected]: (state, { payload: { body, status } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not update password at this time!'),
      });
    },

    [updateTimeFormat.fulfilled]: (state, { payload: { timeFormat } }) => {
      localStorage.setItem('time-format', timeFormat);
      state.timeFormat = timeFormat;

      successNotification({
        description: i18n.t('Time format has been updated!'),
      });
    },

    [updateTimeFormat.rejected]: (_, { payload: { body, status } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not update time format at this time!'),
      });
    },
  },
});

export const { resetAccount } = accountSlice.actions;

export default accountSlice.reducer;
