import { Box, Flex } from 'theme-ui';

import { Link } from '..';
import { LayoutSubheaderProps } from './layout-subheader.types';

const isLoyaltyCampaignSubPage = (currentPath?: string, actionPath?: string) =>
  currentPath?.includes('/loyalty/campaigns') && currentPath?.includes(actionPath || '');

export const LayoutSubheaderView: React.FC<LayoutSubheaderProps> = ({ currentPath, actions, children }) => (
  <Flex
    sx={{
      variant: 'layouts.subheaders',
      height: 'fit-content',
      justifyContent: 'end',
      alignItems: 'center',
      flexFlow: 'wrap',
      padding: 2,
    }}
  >
    {actions && (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyItems: 'center',
          minWidth: 'fit-content',
        }}
        data-testid="subheader-tabs"
      >
        {actions.map((action: any) => (
          <Link
            dataTestid={action.path}
            to={`/${action.path}`}
            key={action.key || action.path}
            tabIndex={0}
            active={currentPath === `/${action.path}` || isLoyaltyCampaignSubPage(currentPath, action.path)}
            onClick={action.onClick}
          >
            {action.content}
          </Link>
        ))}
      </Box>
    )}
    {/* TODO: make the below a select if ever necessary */}
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'end',
      }}
    >
      {children}
    </Flex>
  </Flex>
);
