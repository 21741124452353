import { useCallback } from 'react';
import { Box, Text } from 'theme-ui';

import { parseDate, UndeterminedDate } from '../../../lib/datetime-helpers';
import { DatePicker } from '../index';

export interface RangePickerProps {
  startDateValue: UndeterminedDate;
  endDateValue: UndeterminedDate;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  isOutsideRange?: any;
  onChange: any;
  format?: string;
  disabled?: boolean;
  isDayBlocked?: any;
  timeZone?: string;
  useEUTimeFormat?: boolean;
}

export const RangePicker = ({
  startDateValue,
  endDateValue,
  onChange,
  startDatePlaceholderText,
  endDatePlaceholderText,
  format,
  disabled,
  timeZone,
  useEUTimeFormat = false,
}: RangePickerProps) => {
  const from = parseDate(startDateValue, timeZone).toJSDate();
  const to = !endDateValue ? undefined : parseDate(endDateValue, timeZone).toJSDate();
  const dateFormate = useEUTimeFormat ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

  const changeHandler = useCallback(
    (day: Date | undefined, rangeMarker: 'startDate' | 'endDate') =>
      onChange({
        startDate: from,
        endDate: to,
        [rangeMarker]: day,
      }),
    [from, onChange, to]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        '> div': { flexGrow: 1 },
        gap: '8px',
      }}
    >
      <DatePicker
        dateValue={from}
        placeholder={startDatePlaceholderText}
        maxDate={to}
        onChange={(date) => changeHandler(date, 'startDate')}
        format={format}
        disabled={disabled}
        timeZone={timeZone}
        fullWidth
        dateFormat={dateFormate}
      />
      <Text sx={{ fontWeight: 'light' }}>—</Text>
      <DatePicker
        dateValue={to}
        placeholder={endDatePlaceholderText}
        minDate={new Date()}
        onChange={(date) => changeHandler(date, 'endDate')}
        format={format}
        disabled={disabled}
        timeZone={timeZone}
        fullWidth
        dateFormat={dateFormate}
      />
    </Box>
  );
};
