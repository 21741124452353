import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from './use-app-selector';

export const useTokenMeters = () => {
  const { accountNumbers, meters = [] } = useAppSelector(({ auth }) => auth.profile, shallowEqual) || {};

  return useMemo(() => {
    const accountNumber = accountNumbers && accountNumbers.length ? accountNumbers[0] : '';

    return meters
      .map((meter) => ({
        uid: meter.uid,
        assetType: meter.assetType,
        displayName: meter.displayName,
        active: meter.active,
        accountNumber,
      }))
      .filter((meter) => meter.active);
  }, [accountNumbers, meters]);
};
