import { useContext } from 'react';
import { AuthServiceContext } from './auth-service';

export const useAuthService = () => {
  const ctx = useContext(AuthServiceContext);

  if (ctx === null) {
    throw new Error('Auth Service Provider Not Given');
  }

  return ctx;
};
