import { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { NoData } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';
import { Text } from 'theme-ui';

import { Button, Table, TableLoadingOverlay } from '..';
import { DownloadOutlined, LoadingOutlined } from '../icons';
import { Invoice } from '../../../../types/invoice';

interface InvoiceRowProps {
  invoiceNumber: string;
  onDownloadStatement: (token: CancelTokenSource, invoiceNumber: string) => Promise<void>;
}

export const InvoiceRow: React.FC<InvoiceRowProps> = ({ invoiceNumber, onDownloadStatement }) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);
  const [requestToken, setRequestToken] = useState(axios.CancelToken.source());

  useEffect(() => {
    return () => requestToken.cancel();
  }, [requestToken]);

  const downloadStatement = async () => {
    requestToken.cancel();
    const updatedToken = axios.CancelToken.source();

    setIsDownloading(true);
    setRequestToken(updatedToken);

    await onDownloadStatement(updatedToken, invoiceNumber);

    setIsDownloading(false);
  };

  return (
    <Button
      disabled={isDownloading}
      onClick={downloadStatement}
      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
    >
      <Text>{t('Download PDF')}</Text>
      {!isDownloading ? <DownloadOutlined size={4} color="background" /> : <LoadingOutlined size={4} />}
    </Button>
  );
};

interface InvoiceColumnsProps extends Omit<InvoiceRowProps, 'invoiceNumber'> {
  t: (translationKey: string) => string;
}

const InvoiceColumns = ({ t, onDownloadStatement }: InvoiceColumnsProps) => [
  {
    title: '#',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    width: '20%',
  },
  {
    title: t('INVOICE CYCLE'),
    dataIndex: 'period',
    key: 'period',
    width: '40%',
  },
  {
    title: '',
    dataIndex: 'download',
    key: 'download',
    render: (_: unknown, invoice: { invoiceNumber: string }) => {
      return <InvoiceRow invoiceNumber={invoice.invoiceNumber} onDownloadStatement={onDownloadStatement} />;
    },
  },
];

interface InvoiceTableProps {
  invoices?: Invoice[];
  isLoading?: boolean;
  onDownloadStatement: (token: CancelTokenSource, invoiceNumber: string) => Promise<void>;
}

export const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices = [],
  isLoading = false,
  onDownloadStatement,
}) => {
  const { t } = useTranslation();

  return (
    <Table
      locale={{
        emptyText: <NoData description={t('No statements found')} />,
      }}
      columns={invoices.length ? InvoiceColumns({ t, onDownloadStatement }) : []}
      dataSource={invoices}
      loading={{
        spinning: isLoading,
        indicator: <TableLoadingOverlay active text={t('Loading statements...')} color="text" />,
      }}
      pagination={{
        pageSizeOptions: ['5', '10', '25', '50'],
        manualPagination: false,
        total: invoices.length,
        showTotal: (total: number, range: unknown[]) => t(`${range[0]} - ${range[1]} of ${total}`),
      }}
    />
  );
};
