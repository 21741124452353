import { useFeatureSet } from './use-feature-set';

export const useNewUIEnabled = () => {
  const {
    showVisionEnergyMixPage,
    showVisionBillingPage,
    showNewDashboardPage,
    showNewUsagePage,
    showNewTransactionsPage,
    showNewTradingPage,
  } = useFeatureSet();

  return (
    showVisionEnergyMixPage ||
    showVisionBillingPage ||
    showNewDashboardPage ||
    showNewUsagePage ||
    showNewTransactionsPage ||
    showNewTradingPage
  );
};
