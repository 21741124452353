import { GetTokens } from '../adaptor-types';
import { OktaAdaptorTypes } from './okta-adaptor-types';

/**
 * Returns the current tokens for an authenticated user
 * @param client
 */
export const getTokens: GetTokens<OktaAdaptorTypes> = async (client) => {
  if (!client) {
    return null;
  }

  const tokens = await client.okta.tokenManager.getTokens();

  if (!tokens.accessToken || !tokens.idToken) {
    return null;
  }

  return {
    accessToken: tokens.accessToken.accessToken,
    idToken: tokens.idToken.idToken,
  };
};
