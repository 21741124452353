import { FC } from 'react';
import { Flex, Box, Text, ThemeUIStyleObject } from 'theme-ui';
import { alpha, getColor } from '@theme-ui/color';
import { useTheme } from '@power-ledger/hooks';
import { Warning } from '..';

export const SquareBlock: FC<{
  isEmpty?: boolean;
  error?: string;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
}> = ({ isEmpty, error, onClick, sx, children }) => {
  const { theme } = useTheme();
  return (
    <Box sx={sx}>
      <Box
        sx={{
          minWidth: 100,
          paddingBottom: '100%',
          position: 'relative',
        }}
      >
        <Flex
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            borderRadius: 4,
            borderWidth: 2,
            borderColor: isEmpty ? 'primary' : error ? 'warning' : 'secondaryDarker',
            borderStyle: isEmpty ? 'dashed' : 'solid',
            bg: isEmpty ? 'transparent' : error ? 'secondaryDarkest' : 'secondaryDarker',
            cursor: isEmpty ? 'pointer' : 'initial',
            color: isEmpty ? 'primary' : 'textDarker',
            boxShadow: error ? `inset 0 0 500px ${getColor(theme, 'warningDarkest')}` : '',

            '&:hover': isEmpty
              ? {
                  transition: 'base',
                  bg: alpha('primary', 0.1),
                }
              : {},
          }}
          onClick={isEmpty ? onClick : undefined}
        >
          {children}
        </Flex>
      </Box>
      {!!error && (
        <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Warning color="warning" />
          <Text sx={{ color: 'warning', fontSize: 1 }}>{error}</Text>
        </Flex>
      )}
    </Box>
  );
};
