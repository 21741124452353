import { LogoPoweredByPlSVGLazy } from '@power-ledger/assets';
import { useMemo } from 'react';
import { Box, BoxProps } from 'theme-ui';

import { useHostConfig } from '../../contexts/host-config-context';
import { FormContainer } from './FormContainer';

export const FullPageFormLayout: React.FC<
  { noContainer?: boolean; formContainerTestId?: string } & BoxProps
> = ({ children, noContainer = false, sx, formContainerTestId = '', ...props }) => {
  const { tradingGroup, hostHasFlag } = useHostConfig(true);

  const bottomBranding = useMemo(
    () =>
      tradingGroup && !hostHasFlag('hidePowerledgerLogo') ? (
        <Box sx={{ variant: 'layouts.poweredByLogo' }}>
          <LogoPoweredByPlSVGLazy />
        </Box>
      ) : null,
    [tradingGroup, hostHasFlag]
  );

  return (
    <Box
      sx={{
        variant: 'layouts.login',
        ...sx,
      }}
      {...props}
    >
      {noContainer ? children : <FormContainer data-testid={formContainerTestId}>{children}</FormContainer>}
      {bottomBranding}
    </Box>
  );
};
