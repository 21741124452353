import { ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

const commonSwitchStyles: ThemeUIStyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 6,
  cursor: 'pointer',
  outline: 0,
  '&:focus': {
    boxShadow: 'focus',
  },
};

const commonButtonStyles: ThemeUIStyleObject = {
  px: [2, 3],
  py: [1, 2],
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.3s ease',
  fontSize: 0,
};

export const switches = CustomThemeProperty('switches', {
  primary: {
    ...commonSwitchStyles,
    borderColor: 'secondary',
  },
  inverted: {
    ...commonSwitchStyles,
    borderColor: 'white',
  },
  buttons: {
    primary: {
      ...commonButtonStyles,
      color: 'secondary',
      bg: 'white',
      active: {
        color: 'white',
        bg: 'secondary',
      },
    },
    inverted: {
      ...commonButtonStyles,
      color: 'white',
      bg: 'secondary',
      active: {
        color: 'secondary',
        bg: 'white',
      },
    },
  },
});
