import { Theme, ThemeUIStyleObject } from 'theme-ui';
import { CustomThemeProperty } from './types';

export const fonts: Theme['fonts'] = {
  body: 'Inter, Helvetica, Arial, sans-serif',
  heading: 'MintGroteskV08',
  monospace: 'Menlo, monospace',
  table: 'MintGroteskV08, Helvetica, Arial, sans-serif',
};

export const fontSizes: Theme['fontSizes'] = [
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.25rem',
  '1.5rem',
  '2rem',
  '3rem',
  '4rem',
  '6rem',
];

export const fontWeights: Theme['fontWeights'] = {
  light: 300,
  body: 400,
  heading: 900,
  bold: 700,
  extrabold: 900,
};

export const lineHeights: Theme['lineHeights'] = {
  body: 1.5,
  heading: 1.125,
};

export const letterSpacings: Theme['letterSpacings'] = [0, 1, 2];

export const texts = CustomThemeProperty('texts', {
  tracexDefaultHeading: {
    fontWeight: 'bolder',
    fontSize: '2.3rem',
    padding: '10px 0',
  },
});
