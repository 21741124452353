import { AuthStatus } from '../../auth-service-types';
import { CreateClientInstance } from '../adaptor-types';
import { MockAdaptorTypes } from './mock-adaptor-types';
import { MockAuthClient } from './mock-client';

/**
 * Initiates the Okta Client with the given configuration. This will also subscribe to required events
 * @param config
 * @param helpers
 * @returns Client Instance and Destroy fn
 */
export const createClientInstance: CreateClientInstance<MockAdaptorTypes> = (config, { onStatusChange }) => {
  const mockClient = new MockAuthClient();

  const onStateUpdate = (client: MockAuthClient) => {
    onStatusChange(client.isAuth ? AuthStatus.AUTHENTICATED : AuthStatus.NOT_AUTHENTICATED);
  };

  mockClient.subscribe(onStateUpdate);

  const destroy = () => {
    mockClient.unSubscribe(onStateUpdate);
  };

  mockClient.init();

  // onStatusChange(mockClient.isAuth ? AuthStatus.AUTHENTICATED : AuthStatus.NOT_AUTHENTICATED);

  // Create Final Client Object
  const client = {
    client: mockClient,
    signInCtx: undefined,
  };

  return { client, destroy };
};
