import React from 'react';
import PropTypes from 'prop-types';

export const FunFactHouseSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    id="Layer_1"
    viewBox="0 0 104.9 103.1"
    aria-labelledby="title-fun-fact-house"
    aria-describedby="desc-fun-fact-house"
    role="img"
  >
    <title id="title-fun-fact-house">Green energy house icon</title>
    <description id="desc-fun-fact-house">An illustration showing a house with a greenleaf.</description>
    <path
      d="M103.7 40.7c-4.5-1.1-9.1-1.5-13.6-1.2L50.7 0 0 50.7l6.8 6.8 3-3v48h3v-51l37.8-37.8 27.8
    27.8c-7.6 2.3-14.7 6.4-20.6 12.3-12 12-16.9 28.9-13.1 45.4 0 .3 0
    .6.2.9l.1.4.4.1c.2.1.5.2.8.2h.2c3.6.8 7.3 1.2 10.9 1.2 12.8 0 25.2-5 34.5-14.4 5.2-5.2 9.1-11.3
    11.5-18.2.1-.3.1-.7 0-1 0-.1-.1-.2-.2-.3-.3-.4-.7-.7-1.3-.7H100c.8-2.9 1.4-5.9 1.6-8.9.3-3.9
    0-7.8-.8-11.6l3.1-3.1c.1-.1.1-.2.2-.3.4-.2.7-.5.8-1 .1-.8-.4-1.6-1.2-1.8zM60 55.9c1.9-1.9
    3.9-3.5 6-5v26.6L47.2 96.2C44.6 81.5 49.3 66.6 60 55.9zm.9 30.8h27.5c-10.6 9.8-24.9 14.1-39.1
    11.6l11.6-11.6zm30.5-3H63.9l13.3-13.3h22.5c-2 4.9-4.7 9.4-8.3 13.3zm7.2-25.4c-.2 3.1-.8 6.2-1.7
    9.1H80.2l12.1-12.1 1.1-1.1 1.1-1.1 2.6-2.6 1.1-1.1.1-.1v.3c.1.6.1 1.1.2 1.7.1.6.1 1.3.2 2 0 1.7
    0 3.4-.1 5zM97 46.4l-1 1-1.1 1.1-2.6 2.6-1.1 1.1-1.1 1.1-21.2 21.2V48.9c3.7-2.2 7.8-3.9 11.9-5
    .6-.1 1.1-.3 1.7-.4l1.8-.3c1.5-.3 3.1-.5 4.6-.6.7-.1 1.3-.1 2-.1H93c2.4 0 4.8.2 7.3.7L97
    46.4zM6.8 53.2l-2.6-2.6L50.7 4.2l35.6 35.6c-1.5.2-3 .4-4.4.8L50.7 9.4 6.8 53.2z"
      fill="#484f59"
    />
    <path d="M50.7 4.2L4.2 50.7l2.6 2.6L50.7 9.4l31.1 31.1c1.5-.3 2.9-.6 4.4-.8L50.7 4.2z" fill="#008e95" />
    <path
      d="M91.4 83.7c3.5-3.9 6.3-8.4 8.3-13.3H77.2L63.9 83.7h27.5zM93.1 42.4H91c-.7 0-1.3.1-2
    .1-1.5.1-3.1.3-4.6.6l-1.8.3c-.6.1-1.1.3-1.7.4-4.2 1.1-8.2 2.8-11.9 5v25.6l21.3-21.3 1.1-1.1
    1.1-1.1 2.6-2.6 1.1-1.1 1.1-1.1 3.3-3.3c-2.7-.1-5.1-.3-7.5-.4z"
      fill="#afd600"
    />
    <path
      d="M65.9 50.9c-2.1 1.5-4.1 3.2-6 5-10.6 10.7-15.3 25.6-12.7 40.3l18.7-18.7V50.9zM49.3
    98.4c14.2 2.5 28.6-1.8 39.1-11.6H60.9L49.3 98.4zM98.6
    53.3c0-.7-.1-1.3-.2-2-.1-.6-.1-1.1-.2-1.7v-.3l-.1.1-1.1 1.2-2.6 2.6-1.1 1.1-1.1 1.1-12
    12.1h16.7c.9-3 1.5-6 1.7-9.1.1-1.7.1-3.4 0-5.1z"
      fill="#8ead00"
    />
    <path
      d="M21 20.1c.1-3.8-1.3-7.4-4-10.1-1.5-1.5-3.4-2.7-5.4-3.3-1.2-.4-2.3-.6-3.5-.7H6.9c-.1 3.7
    1.3 7.4 4 10.1 2.7 2.6 6.3 4.1 10.1 4z"
      fill="#afd600"
    />
    <path
      d="M20.7 23.1c.6 0 1.3 0 1.9-.1l1.2-.1.1-1.2c.6-5.1-1.2-10.1-4.8-13.8C17.2 6 15 4.7 12.5
    3.8c-1.4-.4-2.8-.7-4.2-.8-1-.1-2 0-3 .1l-1.2.1L4 4.4c-.6 5.1 1.2 10.1 4.8 13.8 3.2 3.2 7.4 4.9
    11.9 4.9zM8.1 6c1.1.1 2.3.3 3.4.7 2.1.7 3.9 1.8 5.4 3.3 2.7 2.7 4.1 6.3 4
    10.1-3.8.1-7.4-1.4-10.1-4-2.6-2.7-4-6.4-3.9-10.1h1.2zM18 103.1h3V69.3h15.7v33.8h3V66.3H18z"
      fill="#484f59"
    />
  </svg>
);

FunFactHouseSVG.defaultProps = {
  width: '150px',
  height: '150px',
};

FunFactHouseSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
