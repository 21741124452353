import { Button } from 'theme-ui';

import { useHistory } from 'react-router-dom';

import { LinkType } from './types';

const activeStyles = (active = false) =>
  active
    ? {
        bg: 'primary',
        color: 'white',
      }
    : {};

export const Link: LinkType = ({ children, to, active, tabIndex = 0, onClick, dataTestid }) => {
  const history = useHistory();
  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };
  return (
    <Button
      variant="link"
      tabIndex={tabIndex}
      sx={activeStyles(active)}
      onClick={handleOnClick}
      onKeyDown={(e) => {
        const key = e.code;
        return key === 'Enter' ? handleOnClick() : e;
      }}
      data-testid={dataTestid}
    >
      {children}
    </Button>
  );
};
