import { Contract } from '../../../../types';

export const getCampaignContractSummary = (contracts: Contract[]) => {
  let totalEnergyDonated = 0;
  let totalValue = 0;
  let voucherRedeemedAmount = 0;

  contracts.forEach((contract) => {
    totalEnergyDonated += contract.energyDonated;
    totalValue += contract.valueAccrued;
    voucherRedeemedAmount += contract.redeemed;
  });

  return {
    totalEnergyDonated,
    totalValue,
    voucherRedeemedAmount,
    voucherPendingRedemptionAmount: totalValue - voucherRedeemedAmount,
  };
};

export const displayedStatusTransMap = (t: (text: string) => string) => ({
  DRAFT: t('Draft'),
  ACTIVE: t('Active'),
  PAUSED: t('Paused'),
  CANCELLED: t('Cancelled'),
  COMPLETED: t('Expired'),
});

export const COLOR_BY_CAMPAIGN_STATUS: Record<string, 'success' | 'warning' | 'error' | 'disabled' | 'info'> =
  {
    DRAFT: 'info',
    ACTIVE: 'success',
    PAUSED: 'warning',
    CANCELLED: 'error',
    COMPLETED: 'disabled',
  };
