import React from 'react';
import PropTypes from 'prop-types';

export const BlockchainSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    aria-labelledby="title-blockchain-icon"
    aria-describedby="desc-blockchain-icon"
    role="img"
  >
    <title id="title-blockchain-icon">Blockchain icon</title>
    <description id="desc-blockchain-icon">
      A symbol showing three connected blocks, representing the blockchain.
    </description>
    <path d="M0 8h2.7V2.7H8V0H0v8z" fill="#009aa4" />
    <path d="M4 12h2.7V6.7H12V4H4v8z" fill="#009aa4" />
    <path d="M16 16V8H8v8h8z" fill="#009aa4" />
  </svg>
);

BlockchainSVG.defaultProps = {
  width: '16px',
  height: '16px',
};

BlockchainSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
