import PropTypes from 'prop-types';
import { NoData } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { useBreakpointIndex } from '@theme-ui/match-media';
import { Text } from 'theme-ui';
import { sortSubCategoryPeriods } from '../../states/selectors/pricing';
import { paginationOpts } from '../../lib/pagination';
import { Table, TableLoadingOverlay, Tooltip } from '..';

const meterColumnWidthMapping = [200, 300, 300, 400];

const customColumns = (periods, t, breakpointIndex) => {
  const meterColumnWidth = meterColumnWidthMapping[breakpointIndex] || 600;

  let columns = [
    {
      title: t('METER'),
      dataIndex: 'meter',
      key: 'meter',
      width: meterColumnWidth,
      render: (meterName) => (
        <Tooltip title={meterName}>
          <Text
            sx={{
              width: meterColumnWidth * 0.8,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {meterName}
          </Text>
        </Tooltip>
      ),
    },
  ];

  periods.forEach((val) => {
    let buyLabel = t('BUY RATE');
    let sellLabel = t('SELL RATE');

    if (val.label.indexOf('ANY') === -1) {
      buyLabel = t(`${val.label} BUY`);
      sellLabel = t(`${val.label} SELL`);
    }

    columns.push({
      title: buyLabel,
      dataIndex: `${val.dataKey}Buy`,
      key: `${val.dataKey}Buy`,
      render: (record) => <span>{record !== null && record !== undefined ? record : '-'}</span>,
    });

    columns.push({
      title: sellLabel,
      dataIndex: `${val.dataKey}Sell`,
      key: `${val.dataKey}Sell`,
      render: (record) => <span>{record !== null && record !== undefined ? record : '-'}</span>,
    });
  });

  columns = sortSubCategoryPeriods(columns);

  return columns;
};

export const PricingTable = ({
  subPeriods,
  data,
  total,
  pageIndex,
  pageSize,
  isLoading,
  onUpdateRatesByPageOptions,
}) => {
  const { t } = useTranslation();
  const breakpointIndex = useBreakpointIndex();

  let tableColumns = [];

  if (total > 0) {
    tableColumns = subPeriods.length
      ? customColumns(subPeriods, t, breakpointIndex)
      : [
          {
            title: t('METER'),
            dataIndex: 'meter',
            key: 'meter',
          },
          {
            title: t('BUY RATE'),
            dataIndex: 'Buy',
            key: 'Buy',
            render: (record) => <span>{record !== null && record !== undefined ? record : '-'}</span>,
          },
          {
            title: t('SELL RATE'),
            dataIndex: 'Sell',
            key: 'Sell',
            render: (record) => <span>{record !== null && record !== undefined ? record : '-'}</span>,
          },
        ];
  }

  return (
    <Table
      locale={{ emptyText: <NoData description={t('No rates found')} /> }}
      columns={tableColumns}
      dataSource={total > 0 ? data : []}
      loading={{
        spinning: isLoading,
        indicator: <TableLoadingOverlay active text={t('Loading meter rates...')} color="text" />,
      }}
      pagination={paginationOpts(pageIndex, pageSize, total, onUpdateRatesByPageOptions, t)}
    />
  );
};

PricingTable.defaultProps = {
  data: [],
  total: 0,
  pageIndex: 0,
  pageSize: 10,
  isLoading: false,
};

PricingTable.propTypes = {
  subPeriods: PropTypes.array,
  data: PropTypes.array,
  total: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  isLoading: PropTypes.bool,
  onUpdateRatesByPageOptions: PropTypes.func.isRequired,
};
