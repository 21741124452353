import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNumber } from '../../../utils/number';
import { UseBatterySharingEventsTableProps } from './battery-sharing-events-table.types';

export const useBatterySharingEventsTable = ({ data }: UseBatterySharingEventsTableProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const tableData = useMemo(
    () => data.slice(currentPage * currentPageSize - currentPageSize, currentPage * currentPageSize),
    [data, currentPage, currentPageSize]
  );

  const handleChangePagination = ({ current, pageSize }: { current: number; pageSize: number }) => {
    if (isNumber(current) && currentPage !== current) setCurrentPage(current);
    if (currentPageSize !== pageSize) setCurrentPageSize(pageSize);
  };

  const showTotal = (total: number, range: number[]) => t(`${range[0]} - ${range[1]} of ${total}`);

  return {
    tableData,
    currentPage,
    currentPageSize,
    handleChangePagination,
    showTotal,
  };
};
