export interface UpdatePageOptions {
  offset: number;
  limit: number;
  pageIndex: number;
}

export type UpdatePageFunction = (options: UpdatePageOptions) => void;

export const paginationOpts = (
  current: number,
  pageSize: number,
  total: number,
  updatePageOpts: UpdatePageFunction,
  t: (key: string) => string
) => ({
  pageIndex: current,
  showQuickJumper: true,
  showSizeChanger: true,
  pageSize,
  pageSizeOptions: ['10', '25', '50', '100'],
  total,
  showTotal: (_: unknown, range: number[]) => t(`${range[0]} - ${range[1]} of ${total}`),
  ...(updatePageOpts && {
    onChange: ({ pageIndex, pageSize: limit }: { pageIndex: number; pageSize: number }) => {
      const request = { offset: pageIndex * pageSize, limit, pageIndex };
      updatePageOpts(request);
    },
  }),
});

export const serverSidePaginationOpts = ({
  offset,
  limit,
  total,
  updatePageOpts,
}: {
  offset: number;
  limit: number;
  total: number;
  updatePageOpts: UpdatePageFunction;
}) => {
  const pageIndex = Math.floor(offset / limit);
  const pageCount = Math.ceil(total / limit);

  return {
    pageIndex,
    pageSize: limit,
    pageCount,
    manualPagination: true,
    onChange: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
      const request = { offset: pageIndex * pageSize, limit: pageSize, pageIndex };
      updatePageOpts(request);
    },
  };
};
