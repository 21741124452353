import {
  OktaAuthAdaptor,
  PasswordUpdateResponse,
  RequestPasswordResetResponse,
} from '@power-ledger/auth-service';

import { generateOktaUsername } from '../../lib/helpers';
import { resetPassword, updateCurrentPassword } from '../../middleware/api';

/**
 * Sadly Transactive strays away from the pack...
 * These function override the Okta Auth Adaptor
 * functionality to support these requirements
 */
export const XGridOktaAuthAdaptor: typeof OktaAuthAdaptor = {
  ...OktaAuthAdaptor,

  /**
   * Override of Sign-In
   * @reason User accounts are wrapped in fake email addresses... This intercepts the requests
   * and wraps the given username into an email
   */
  signIn: (client, { username, password }) => {
    return OktaAuthAdaptor.signIn(client, {
      username: username.includes('@') ? username : generateOktaUsername(username),
      password,
    });
  },

  /**
   * Override of Request Password Reset
   * @reason Transactive requires custom themes in our password reset emails which are too complicated to
   * implement with the Okta Branding API. To resolve this we use an endpoint to reset the passwords instead
   */
  requestPasswordReset: async (client, opts) => {
    const { status } = await resetPassword(opts.username);

    if (status !== 200) {
      throw new Error('Failed to request password reset');
    }

    return RequestPasswordResetResponse.SUCCESS;
  },

  /**
   * Override of Password Update
   * @reason This is required as Okta Admin API is required to perform this functionality along with the
   * situation of everyone having their own API implementation to perform this task...
   */
  passwordUpdate: async (client, credentials) => {
    const { status } = await updateCurrentPassword({
      password: credentials.oldPassword,
      new_password: credentials.newPassword,
    });
    return status === 200 ? PasswordUpdateResponse.SUCCESS : PasswordUpdateResponse.INVALID_CRED;
  },
};
