import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createCampaign as createCampaignApi,
  getCampaigns as getCampaignsApi,
  getLP2PMeters as getLP2PMetersApi,
  getUserCampaigns as getUserCampaignsApi,
  getUserVouchers as getUserVouchersApi,
  updateCampaign as updateCampaignApi,
  updateMaxNumberOfParticipants as updateMaxNumberOfParticipantsApi,
} from '../../middleware/api';
import { errorNotification, isSuccess, successNotification } from '../../lib/notifications';
import i18n from '../../lib/i18n';
import { convertSparkzKWhToSparkzWh, convertSparkzWhToSparkzKWh } from '../../states/selectors/pricing';
import { StoreStatus, UserCampaign } from '../../../../types';

export const getUserCampaigns = createAsyncThunk<any, any, { rejectValue: any }>(
  'loyaltyP2P/getUserCampaigns',
  async (request, { rejectWithValue }) => {
    const { status, body } = await getUserCampaignsApi(request);

    if (isSuccess(status)) {
      return { campaigns: sparkzWhToSparkzKWhForPage(body) };
    }

    return rejectWithValue({ status, body });
  }
);

export const getCampaigns = createAsyncThunk<any, any, { rejectValue: any }>(
  'loyaltyP2P/getCampaigns',
  async (request, { rejectWithValue }) => {
    const { status, body } = await getCampaignsApi(request);

    if (isSuccess(status)) {
      return { campaigns: sparkzWhToSparkzKWhForPage(body) };
    }
    return rejectWithValue({ status, body });
  }
);

export const getVouchers = createAsyncThunk<any, any, { rejectValue: any }>(
  'loyaltyP2P/getVouchers',
  async ({ rejectWithValue }) => {
    const { status, body } = await getUserVouchersApi();

    if (isSuccess(status)) {
      return { vouchers: body };
    }
    return rejectWithValue({ status, body });
  }
);

export const getLP2PMeters = createAsyncThunk<any, undefined, { rejectValue: any }>(
  'loyaltyP2P/getLP2PMeters',
  async (_, { rejectWithValue }) => {
    const { status, body } = await getLP2PMetersApi();

    if (isSuccess(status)) {
      return { meters: body };
    }
    return rejectWithValue({ status, body });
  }
);

export const createCampaign = createAsyncThunk<any, UserCampaign, { rejectValue: any }>(
  'loyaltyP2P/createCampaign',
  async (campaign, { rejectWithValue }) => {
    const { status, body } = await createCampaignApi(sparkzKWhToSparkzWh(campaign));

    if (isSuccess(status)) {
      return { data: sparkzWhToSparkzKWh(body) };
    }

    return rejectWithValue({ status, body });
  }
);

export const updateCampaign = createAsyncThunk<any, UserCampaign, { rejectValue: any }>(
  'loyaltyP2P/updateCampaign',
  async (campaign, { rejectWithValue }) => {
    const { status, body } =
      campaign.status === 'ACTIVE'
        ? await updateMaxNumberOfParticipantsApi(sparkzKWhToSparkzWh(campaign))
        : await updateCampaignApi(sparkzKWhToSparkzWh(campaign));

    if (isSuccess(status)) {
      return { data: sparkzWhToSparkzKWh(body) };
    }

    return rejectWithValue({ status, body });
  }
);

export const activateCampaign = createAsyncThunk<any, UserCampaign, { rejectValue: any }>(
  'loyaltyP2P/activateCampaign',
  async (campaign, { rejectWithValue }) => {
    const { status, body } = await updateCampaignApi(sparkzKWhToSparkzWh(campaign));

    if (isSuccess(status)) {
      return { data: sparkzWhToSparkzKWh(body) };
    }
    return rejectWithValue({ status, body });
  }
);

export const pauseCampaign = createAsyncThunk<any, UserCampaign, { rejectValue: any }>(
  'loyaltyP2P/pauseCampaign',
  async (campaign, { rejectWithValue }) => {
    const { status, body } = await updateCampaignApi(sparkzKWhToSparkzWh(campaign));

    if (isSuccess(status)) {
      return { data: sparkzWhToSparkzKWh(body) };
    }
    return rejectWithValue({ status, body });
  }
);

export const resumeCampaign = createAsyncThunk<any, UserCampaign, { rejectValue: any }>(
  'loyaltyP2P/resumeCampaign',
  async (campaign, { rejectWithValue }) => {
    const { status, body } = await updateCampaignApi(sparkzKWhToSparkzWh(campaign));

    if (isSuccess(status)) {
      return { data: sparkzWhToSparkzKWh(body) };
    }
    return rejectWithValue({ status, body });
  }
);

export const cancelCampaign = createAsyncThunk<any, UserCampaign, { rejectValue: any }>(
  'loyaltyP2P/cancelCampaign',
  async (campaign, { rejectWithValue }) => {
    const { status, body } = await updateCampaignApi(sparkzKWhToSparkzWh(campaign));

    if (isSuccess(status)) {
      return { data: sparkzWhToSparkzKWh(body) };
    }
    return rejectWithValue({ status, body });
  }
);

const loyaltyP2PSlice = createSlice({
  name: 'loyaltyP2P',
  initialState: {
    vouchers: undefined,
    meters: [],
    userCampaigns: [],
    campaigns: [],
    status: {
      meters: StoreStatus.NOT_INITIALIZED,
      userCampaigns: StoreStatus.NOT_INITIALIZED,
      campaigns: StoreStatus.NOT_INITIALIZED,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCampaign.fulfilled, (state: { userCampaigns: UserCampaign[] }, { payload }) => {
        state.userCampaigns = [payload.data, ...state.userCampaigns];

        successNotification({
          message: i18n.t('Successfully created campaign'),
        });
      })
      .addCase(createCampaign.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not create campaign at this time!'),
        });
      })
      .addCase(updateCampaign.fulfilled, (state: { userCampaigns: UserCampaign[] }, { payload }) => {
        state.userCampaigns = state.userCampaigns.map((campaign) =>
          campaign.id === payload.data.id ? payload.data : campaign
        );
        successNotification({
          message: i18n.t('Successfully updated campaign'),
        });
      })
      .addCase(updateCampaign.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not update campaign at this time!'),
        });
      })
      .addCase(activateCampaign.fulfilled, (state: { userCampaigns: UserCampaign[] }, { payload }) => {
        state.userCampaigns = state.userCampaigns.map((campaign) =>
          campaign.id === payload.data.id ? payload.data : campaign
        );
        successNotification({
          message: i18n.t('Successfully activated campaign'),
        });
      })
      .addCase(activateCampaign.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t(body.errorMessage),
        });
      })
      .addCase(pauseCampaign.fulfilled, (state: { userCampaigns: UserCampaign[] }, { payload }) => {
        state.userCampaigns = state.userCampaigns.map((campaign) =>
          campaign.id === payload.data.id ? payload.data : campaign
        );
        successNotification({
          message: i18n.t('Successfully paused campaign'),
        });
      })
      .addCase(pauseCampaign.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not pause campaign at this time!'),
        });
      })
      .addCase(resumeCampaign.fulfilled, (state: { userCampaigns: UserCampaign[] }, { payload }) => {
        state.userCampaigns = state.userCampaigns.map((campaign) =>
          campaign.id === payload.data.id ? payload.data : campaign
        );
        successNotification({
          message: i18n.t('Successfully resumed campaign'),
        });
      })
      .addCase(resumeCampaign.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not resume campaign at this time!'),
        });
      })
      .addCase(cancelCampaign.fulfilled, (state: { userCampaigns: UserCampaign[] }, { payload }) => {
        state.userCampaigns = state.userCampaigns.map((campaign) =>
          campaign.id === payload.data.id ? payload.data : campaign
        );
        successNotification({
          message: i18n.t('Successfully cancelled campaign'),
        });
      })
      .addCase(cancelCampaign.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not cancel campaign at this time!'),
        });
      })
      .addCase(getUserCampaigns.fulfilled, (state, { payload: { campaigns } }) => {
        state.userCampaigns = campaigns.content;
      })
      .addCase(getUserCampaigns.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not retrieve campaigns at this time!'),
        });
      })
      .addCase(getCampaigns.fulfilled, (state, { payload: { campaigns } }) => {
        state.campaigns = campaigns.content;
        state.status.campaigns = StoreStatus.INITIALIZED;
      })
      .addCase(getCampaigns.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not retrieve campaigns at this time!'),
        });
      })
      .addCase(getVouchers.fulfilled, (state, { payload: { vouchers } }) => {
        state.vouchers = vouchers;
      })
      .addCase(getVouchers.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not retrieve vouchers at this time!'),
        });
      })
      .addCase(getLP2PMeters.fulfilled, (state, { payload: { meters } }) => {
        state.meters = meters;
        state.status.meters = StoreStatus.INITIALIZED;
      })
      .addCase(getLP2PMeters.rejected, (_, { payload: { status, body } }) => {
        errorNotification({
          code: status,
          errorCode: body.errorCode,
          description: i18n.t('Could not retrieve LP2P meters at this time!'),
        });
      });
  },
});

const sparkzKWhToSparkzWh = (campaign: UserCampaign) => {
  return { ...campaign, defaultConversionRate: convertSparkzKWhToSparkzWh(campaign.defaultConversionRate) };
};

const sparkzWhToSparkzKWh = (campaign: UserCampaign) => {
  return { ...campaign, defaultConversionRate: convertSparkzWhToSparkzKWh(campaign.defaultConversionRate) };
};

const sparkzWhToSparkzKWhForPage = (campaigns: any) => {
  return {
    ...campaigns,
    content: campaigns.content.map((campaign: UserCampaign) => sparkzWhToSparkzKWh(campaign)),
  };
};

export default loyaltyP2PSlice.reducer;
