import { FC } from 'react';
import { RoundedCheckboxOn, RoundedCheckboxOff } from '@power-ledger/assets';
import { IconProps, IconWrapper } from './common';

export const RoundedCheckbox: FC<IconProps> = (props) => (
  <IconWrapper
    // @ts-ignore
    icon={props?.checked ? RoundedCheckboxOn : RoundedCheckboxOff}
    {...props}
    // @ts-ignore
    color={props?.checked ? 'primary' : 'transparent'}
  />
);
