import { FC } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

export type RecBadgeProps = {
  abbreviation: string;
  large?: boolean;
  sx?: ThemeUIStyleObject;
};
export type RecBadgeType = FC<RecBadgeProps>;

export const RecBadge: RecBadgeType = ({ abbreviation, large, sx }) => (
  <Flex sx={{ variant: large ? 'recBadge.large' : 'recBadge', ...sx }}>{abbreviation}</Flex>
);

export default RecBadge;
