const parse = (val) => {
  if (typeof val === 'string') {
    if (val.toLocaleLowerCase() === 'true') return true;
    if (val.toLocaleLowerCase() === 'false') return false;
  }
  return val;
};

const isStorybook = !!process.env.STORYBOOK;

export const NODE_ENV = parse(process.env.NODE_ENV);

// Note: Storybook will not include envvars without the STORYBOOK prefix
export const LOCIZE_PROJECTID = parse(
  isStorybook ? process.env.STORYBOOK_XGRID_LOCIZE_PROJECTID : process.env.NX_XGRID_LOCIZE_PROJECTID
);
export const LOCIZE_APIKEY = parse(
  isStorybook ? process.env.STORYBOOK_XGRID_LOCIZE_APIKEY : process.env.NX_XGRID_LOCIZE_APIKEY
);
export const LOCIZE_REFLNG = parse(
  isStorybook ? process.env.STORYBOOK_XGRID_LOCIZE_REFLNG : process.env.NX_XGRID_LOCIZE_REFLNG
);
export const LOCIZE_VERSION = parse(
  isStorybook ? process.env.STORYBOOK_XGRID_LOCIZE_VERSION : process.env.NX_XGRID_LOCIZE_VERSION
);
export const DASH_API_URL = parse(
  isStorybook ? process.env.STORYBOOK_XGRID_DASH_API_URL : process.env.NX_XGRID_DASH_API_URL
);

export const BUILD_SHA = parse(process.env.NX_XGRID_BUILD_SHA);
export const IS_DEVELOPMENT = parse(process.env.NX_XGRID_IS_DEVELOPMENT);
export const IS_TEST = parse(process.env.NX_XGRID_IS_TEST);
export const IS_STAGING = parse(process.env.NX_XGRID_IS_STAGING);
export const IS_PRODUCTION = parse(process.env.NX_XGRID_IS_PRODUCTION);
export const AUTH_SERVER_URL = parse(process.env.NX_XGRID_AUTH_SERVER_URL);
export const AUTH_MFA_URL = parse(process.env.NX_XGRID_AUTH_MFA_URL);
export const OKTA_ISSUER = parse(process.env.NX_XGRID_OKTA_ISSUER);
export const OKTA_CLIENT_ID = parse(process.env.NX_XGRID_OKTA_CLIENT_ID);
export const OKTA_BASE_URL = parse(process.env.NX_XGRID_OKTA_BASE_URL);
export const CLIENT_ID = parse(process.env.NX_XGRID_CLIENT_ID);
export const CLIENT_SECRET = parse(process.env.NX_XGRID_CLIENT_SECRET);
export const GOOGLE_MAPS_API_KEY = parse(process.env.NX_XGRID_GOOGLE_MAPS_API_KEY);
export const CI_MODE = parse(process.env.NX_XGRID_CI_MODE);
export const HOST_CONFIG_ENDPOINT = parse(process.env.NX_XGRID_HOST_CONFIG_ENDPOINT);
export const HOSTNAME_OVERRIDE = parse(process.env.NX_XGRID_HOSTNAME_OVERRIDE);
export const SENTRY_DSN = parse(process.env.SENTRY_DSN);
export const SENTRY_ENV = parse(process.env.SENTRY_ENV);
export const SENTRY_RELEASE_ID = parse(process.env.NX_XGRID_SENTRY_RELEASE_ID);

export const isProduction = () => {
  return Boolean(NODE_ENV === 'production' || IS_PRODUCTION);
};
