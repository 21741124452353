import AcceptTerms from '../../layouts/public/accept-terms';
import { useFeatureSet } from '../../hooks/use-feature-set';
import { useTermsOfAccessOverlayLogic } from './use-terms-of-access-overlay-logic';

interface TermsOfAccessOverlayProps {
  children: JSX.Element;
}

export const TermsOfAccessOverlay: React.FC<TermsOfAccessOverlayProps> = ({ children }) => {
  const { hasAccepted, username } = useTermsOfAccessOverlayLogic();

  const { termsOfUseAccepted } = useFeatureSet();

  if (!username) return null;

  if (!hasAccepted && !termsOfUseAccepted) return <AcceptTerms username={username} />;

  return children;
};
