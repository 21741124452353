import { FC } from 'react';

import styled from '@emotion/styled';
import { variant as styledSystemVariant } from 'styled-system';
import { css, ThemeUIStyleObject } from 'theme-ui';

export type TagProps = {
  children?: any;
  variant?: string;
  sx?: ThemeUIStyleObject;
  onClick?: () => void;
};

const StyledTag = styled.div<{ variant: string; sx?: ThemeUIStyleObject }>`
  ${styledSystemVariant({
    scale: 'tags',
    variants: {
      primary: {},
      cyan: {},
      red: {},
    },
  })}
  ${({ sx }) => sx && css(sx)}
`;

export const Tag: FC<TagProps> = ({ children, sx, variant = '', ...props }) => (
  <StyledTag variant={variant} sx={sx} {...props}>
    {children}
  </StyledTag>
);
