import FileSaver from 'file-saver';
import i18next from 'i18next';
import debounce from 'lodash/debounce';
import numeral from 'numeral';

import { useTranslation } from 'react-i18next';
import { Campaign, UserCampaign } from '../../../types';
import { CheckCircleOutlined, CloseOutlined, InfoCircleOutlined } from '../components';
import { IS_TEST } from './envHelpers';

export { debounce };

// @ts-ignore
export const isIE = !!document.documentMode;

// conveniently gets the icon state based on the given status code
export const getActionState = (status: any) => {
  const validStates = [
    { code: 200, state: CheckCircleOutlined },
    { code: 204, state: InfoCircleOutlined },
  ];
  let result = null;

  if (status && status !== 499) {
    const foundState = validStates.filter((val) => val.code === status);
    result = foundState.length ? foundState[0].state : CloseOutlined;
  }

  return result;
};

// only allow file download if environment is not in TEST as it fails the unit tests
export const downloadFile = (data: any, filename = 'attachment') => {
  if (IS_TEST !== true) {
    let fileType =
      data.type && data.type.length && data.type.split('/').length > 1
        ? `.${data.type.substr(data.type.lastIndexOf('/') + 1)}`
        : '';

    if (fileType === '.octet-stream') {
      fileType = '.csv';
    }

    FileSaver(data, `${filename}${fileType}`);
  }
};

// converts data into a blob of CSV type followed by a download/display on the browser
export const exportCSV = (data: any = [], filename = 'attachment') => {
  const blob = new Blob(['\ufeff', data], { type: 'text/csv' });
  downloadFile(blob, filename);
};

// capitalises the given string - only makes the first character uppercase
export const capitalise = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// camelises the given string
export const camelise = (str: string) =>
  str
    .toLowerCase()
    .replace(/[_-]/g, ' ')
    .replace(/^\w|[A-Z]|\b\w/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, '');

// conveniently configure a name based on the user object
// containing firstname, lastname and username
// defaulted to username, prioritise firstname + lastname
export const getUsername = (user: any) => {
  const { firstName, lastName, username } = user;
  let primaryName = username || '';

  if (firstName) {
    primaryName = firstName;
    primaryName = `${primaryName}${lastName ? ` ${lastName}` : ''}`;
    primaryName = `${primaryName}${username ? ` (${username})` : ''}`;
  } else if (lastName) {
    primaryName = lastName;
    primaryName = `${primaryName}${username ? ` (${username})` : ''}`;
  }

  return primaryName;
};

// sort the items given a key in ascending order
export const sortItems = (items: any, key: any) =>
  items.sort((a: any, b: any) => {
    if (a && a[key]) {
      return b && b[key] ? a[key].localeCompare(b[key], undefined, { numeric: true }) : -1;
    }
    if (b && b[key]) {
      return a && a[key] ? b[key].localeCompare(a[key], undefined, { numeric: true }) : 1;
    }

    return -1;
  });

// strips out underscores and dashes given the raw string
export const formatStr = (meter: any) => meter.replace(/[_\- ]/g, '');

const getDecimalSeparator = (lang: string) => {
  return (1.1).toLocaleString(lang).slice(1, 2);
};

export const formatDecimalSeparator = (original: number) => {
  return String(original).replace('.', getDecimalSeparator(i18next.language));
};

// formats the given amount to 2 decimal places
export const formatCurrency = (val = 0, symbol = '$') => {
  const amount = val || 0;
  const decimal = symbol === '¥' ? 0 : 2;
  const userLang = i18next.language;

  const numFormat = new Intl.NumberFormat(userLang, {
    minimumFractionDigits: decimal,
  });

  // @ts-ignore
  return numFormat.format(Number(amount).toFixed(decimal));
};

// formats the amount to given num decimal places (2 by default to represent kWh)
export const formatAmount = (val = 0, decimal = 2, shortFormat = false) => {
  const amount = val || 0;

  const userLang = i18next.language;

  // This is a hard-coded fix for an edge case for the German language.
  // Eventually we want to remove it and make it work together with Intl.NumberFormat.
  // TODO: Make the hard-coded fix more generic and work with Intl.NumberFormat.
  if (/^de/.test(userLang) && shortFormat && val < 10 ** 6) {
    if (typeof decimal === 'number') {
      return `${(val / 1000).toFixed(decimal)} Tsd.`;
    }

    return `${val / 1000} Tsd.`;
  }

  const numFormat = new Intl.NumberFormat(userLang, {
    minimumFractionDigits: decimal,
    notation: shortFormat ? 'compact' : 'standard',
  });

  // @ts-ignore
  return numFormat.format(Number(amount).toFixed(decimal));
};

export const getCsvDelimiter = () => {
  const primaryLanguage = navigator.languages[0];
  if (primaryLanguage === 'de' || primaryLanguage.includes('de-') || primaryLanguage.includes('de_')) {
    return ';';
  } else {
    return ',';
  }
};

// checks if the search term matches any of the item characters
export const hasMatchingText = (searchTerm: any, item: any) => {
  const filtered = searchTerm.toLowerCase();
  const matchingFiltereIdx = item.toLowerCase().indexOf(filtered);

  let firstCharIdx = 0;
  let lastCharIdx = 0;

  if (matchingFiltereIdx !== -1) {
    firstCharIdx = matchingFiltereIdx;
    lastCharIdx = matchingFiltereIdx + filtered.length;
  }

  const matches = item.split('').filter((_: any, key: any) => firstCharIdx <= key && key < lastCharIdx);

  return !!matches.length;
};

// converts the given large/small exponential value to a string
export const convertExpo = (exponential: any) => {
  let decimal = typeof exponential === 'number' ? exponential.toString().toLowerCase() : '';

  if (decimal.includes('e+')) {
    const exponentialSplitted = decimal.split('e+');
    let postfix = '';

    for (
      let i = 0; // first index
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes('.') ? exponentialSplitted[0].split('.')[1].length : 0);
      i++ // increment
    ) {
      postfix = `${postfix}0`;
    }

    const addCommas = (text: any) => {
      let j = 3;
      let textLength = text.length;
      let nText = text;

      while (j < textLength) {
        nText = `${nText.slice(0, textLength - j)},${nText.slice(textLength - j, textLength)}`;
        textLength++;
        j += 3 + 1;
      }

      return nText;
    };

    decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
  } else if (decimal.toLowerCase().includes('e-')) {
    const exponentialSplitted = decimal.split('e-');
    let prefix = '0.';

    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix = `${prefix}0`;
    }

    decimal = prefix + exponentialSplitted[0].replace('.', '');
  }

  return decimal;
};

// expects an asynchronous function to execute alongside a timer given an optional timeout in ms
// mainly used to provide a smoother loading transition and prevents flash loads
export const asyncLoad = (func: any, timeout = 750) => {
  const funcPromise = new Promise((resolve) =>
    (async () => {
      resolve(await func());
    })()
  );
  const timeoutPromise = new Promise((resolve) => setTimeout(() => resolve(true), timeout));

  return Promise.all([funcPromise, timeoutPromise]).then((resps) => resps[0]);
};

// given the meter group's settlement interval and widthKey (key for specific width size)
// return the tick interval to set for charts' x-axis to be readable
export const setTickIntervals = (settlement: any, widthKey: any) => {
  const intervals = [
    { interval: 1800, points: [1, 3, 7] },
    { interval: 900, points: [3, 7, 11] },
    { interval: 300, points: [11, 23, 35] },
  ];
  let tickInterval = 1;

  const filteredIntervals = intervals.filter((val) => val.interval === settlement);

  if (
    filteredIntervals &&
    filteredIntervals.length &&
    widthKey >= 0 &&
    widthKey < filteredIntervals[0].points.length
  ) {
    tickInterval = filteredIntervals[0].points[widthKey];
  }

  return tickInterval;
};

// Gets a nicely formatted version of 'cents' based on the given currency cents symbol
export const useCentsLongText = (centSymbol?: string) => {
  const { t } = useTranslation();

  if (!centSymbol) {
    return '';
  }

  switch (centSymbol.toLowerCase()) {
    case 'c':
      return t('cents');
    case 'ct':
      return t('cent');
    case 'rp':
      return t('rappen');
    case 'sen':
      return t('sen');
    default:
      return '';
  }
};

export const convertUnits = (amount: any, power: any, decimals = 0) =>
  (amount * 10 ** power).toFixed(decimals);

export const fitToRangeInclusive = (num: any, min: any, max: any) => {
  if (num < min) return min;
  if (num > max) return max;
  return num;
};

// Converts an integer into an ordinal number
export const numToOrdinal = (num: any) => numeral(num).format('o');

// Test whether some of an objects properties are non zero numbers
export const objectPropertiesHaveNonZero = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => acc || (typeof obj[key] === 'number' && obj[key] !== 0), false);

export const generateOktaUsername = (username: any) => {
  const strippedUsername = username.replace(/\s/g, '');
  const urlPrefix = window.location.hostname.toLowerCase().split('-')[0];
  const loginPrefix =
    {
      localhost: 'dev_',
      dev: 'dev_',
      staging: 'staging_',
    }[urlPrefix] || '';

  return `${loginPrefix}${strippedUsername}@xgrid.com`;
};

// If any of the campaigns have ended (endDatetime is in the past) show COMPLETED status instead of ACTIVE
export const maskCampaignStatuses = (campaigns: (UserCampaign | Campaign)[]): (UserCampaign | Campaign)[] =>
  campaigns.map((campaign) => {
    const isPastDate = new Date(campaign.endDatetime) < new Date();

    if (campaign.status === 'ACTIVE' && isPastDate) {
      return { ...campaign, status: 'COMPLETED' };
    }

    return campaign;
  });
