import { FC, FocusEvent, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DebouncedFunc } from 'lodash';
import ReactSelect, {
  components as selectComponents,
  MenuPosition,
  MenuPlacement,
  OptionProps,
  MenuListProps,
  GroupBase,
  ValueContainerProps,
  ControlProps,
} from 'react-select';
import { Flex, Box, Text, css, ThemeUIStyleObject } from 'theme-ui';
import { getColor } from '@theme-ui/color';
import AsyncReactSelect from 'react-select/async';
import { useTheme } from '@power-ledger/hooks';
import { ExactTheme } from '@power-ledger/styles';
import { Tick } from '../icons';
import { InfiniteScroll } from '../InfiniteScroll';

export type SelectOptionType = FC;

export type SelectOptGroupType = FC;

export type SelectType = FC<{
  sx?: ThemeUIStyleObject;
  inputStyles?: ThemeUIStyleObject;
  value?: any;
  isPaginated?: boolean;
  onChange?: any;
  menuIsOpen?: boolean;
  getMoreData?: () => void;
  options?: Array<any>;
  menuPosition?: MenuPosition;
  placeholder?: string | ReactNode;
  prefix?: any;
  isClearable?: boolean;
  disabled?: boolean;
  disableOptions?: (type?: string) => boolean;
  formatOptionLabel?: any;
  onCustomSearch?: DebouncedFunc<(text?: string | undefined) => boolean>;
  isSearchable?: boolean;
  hasMoreData?: boolean;
  isLoading?: boolean;
  isOptionSelected?: any;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  defaultValue?: any;
  getOptionValue?: any;
  getOptionLabel?: any;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  showMultiSelectedValuesLength?: number;
  maxMenuHeight?: number;
  testid?: string;
}>;

export type AsyncSelectType = FC<{
  sx?: ThemeUIStyleObject;
  value?: any;
  onChange?: any;
  inputStyles?: ThemeUIStyleObject;
  menuIsOpen?: boolean;
  defaultOptions?: Array<any>;
  loadOptions?: any;
  disabled?: boolean;
  noOptionsMessage?: any;
  isOptionSelected?: any;
  menuPosition?: MenuPosition;
  isSearchable?: boolean;
  testid?: string;
}>;

const getSelectStyles = ({
  inputStyles = {},
  //@ts-ignore
  theme = { space: [] },
}: {
  inputStyles: ThemeUIStyleObject | undefined;
  theme: ExactTheme;
}) => {
  const textColor = getColor(theme, 'text');
  const themeSpacing = theme?.space && theme?.space[0];
  const themeBorderRadius = theme?.radii && theme?.radii[4];

  const placeholder = (styles: any, state: any) => {
    const { menuIsOpen, isSearchable } = state.selectProps;
    return css({
      ...styles,
      variant: 'forms.select.placeholder',
      display: menuIsOpen && isSearchable ? 'none' : 'block',
      ...inputStyles,
    })(theme);
  };

  const input = (styles = {}) => ({
    ...styles,
    color: textColor,
    fontWeight: 'light',
    paddingLeft: themeSpacing,
    paddingRight: themeSpacing,
  });

  const menu = (styles = {}) => ({
    ...styles,
    background: 'transparent',
    marginTop: 0,
    marginBottom: 0,
    zIndex: 100,
  });

  const menuList = (styles = {}, state: any) => {
    const menuPlacedAtTop = state.selectProps.menuPlacement === 'top';

    return {
      ...styles,
      background: getColor(theme, 'input'),
      padding: 0,
      borderBottomLeftRadius: menuPlacedAtTop ? 0 : themeBorderRadius,
      borderBottomRightRadius: menuPlacedAtTop ? 0 : themeBorderRadius,
      borderTopLeftRadius: menuPlacedAtTop ? themeBorderRadius : 0,
      borderTopRightRadius: menuPlacedAtTop ? themeBorderRadius : 0,
    };
  };

  const singleValue = (styles = {}) => ({
    ...styles,
    color: textColor,
    width: '100%',
    paddingLeft: themeSpacing,
    paddingRight: themeSpacing,
  });

  const multiValue = (styles = {}) => ({
    ...styles,
    background: getColor(theme, 'secondary'),
  });

  const multiValueLabel = (styles = {}) => ({
    ...styles,
    color: textColor,
  });

  const multiValueRemove = (styles = {}, state: any) => {
    return {
      ...styles,
      color: textColor,
      /** If data has a key then dont display the remove icon */
      display: state.data.key ? 'none' : '',
      '&:hover': {
        background: getColor(theme, 'secondaryDark'),
        color: textColor,
      },
    };
  };

  const option = (_: any, state: any) =>
    css({
      ...(state.isSelected
        ? {
            variant: 'forms.select.optionActive',
          }
        : {}),
      ...(state.isDisabled ? { variant: 'forms.select.optionDisabled' } : {}),
      py: 2,
      px: 3,
      fontSize: 1,
      backgroundColor: state.isFocused ? getColor(theme, 'inputDark') : undefined,
      '&:hover': {
        bg: getColor(theme, 'inputDark'),
        cursor: 'pointer',
      },
    })(theme);

  const control = (styles = {}, state: any) => {
    const { menuIsOpen, menuPlacement } = state.selectProps;

    const menuPlacedAtTop = menuPlacement === 'top';

    return css({
      display: 'flex',
      variant: 'forms.select.control',
      background: getColor(theme, 'input'),
      borderColor: getColor(theme, menuIsOpen ? 'text' : 'input'),
      borderTopLeftRadius: menuIsOpen && menuPlacedAtTop ? 0 : themeBorderRadius,
      borderTopRightRadius: menuIsOpen && menuPlacedAtTop ? 0 : themeBorderRadius,
      borderBottomLeftRadius: menuIsOpen && !menuPlacedAtTop ? 0 : themeBorderRadius,
      borderBottomRightRadius: menuIsOpen && !menuPlacedAtTop ? 0 : themeBorderRadius,
      ...inputStyles,
    })(theme);
  };

  const dropdownIndicator = (styles = {}, state: any) =>
    css({
      ...styles,
      variant: 'forms.select.dropdownIndicator',
      svg: {
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      },
    })(theme);

  const indicatorSeparator = () =>
    css({
      variant: 'forms.select.indicatorSeparator',
    })(theme);

  return {
    control,
    placeholder,
    input,
    singleValue,
    menu,
    menuList,
    option,
    multiValue,
    multiValueLabel,
    multiValueRemove,
    dropdownIndicator,
    indicatorSeparator,
  };
};

const ControlComponent = ({
  children,
  selectProps,
  ...rest
}: { children: any; selectProps: any } & ControlProps<Option>) => (
  <selectComponents.Control selectProps={selectProps} {...rest}>
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {selectProps.prefix}
      {children}
    </Flex>
  </selectComponents.Control>
);

export const MenuListComponent = ({ children, ...props }: { children: any } & MenuListProps<Option>) => {
  const { t } = useTranslation();

  if (!props.selectProps.isPaginated) {
    return <selectComponents.MenuList {...props}>{children}</selectComponents.MenuList>;
  }

  return (
    <InfiniteScroll hasMoreData={props.selectProps.hasMoreData} getMoreData={props.selectProps.getMoreData}>
      <selectComponents.MenuList {...props}>
        {children}
        {props.selectProps.hasMoreData && <Box sx={{ p: 2 }}>{t('Loading ...')}</Box>}
      </selectComponents.MenuList>
    </InfiniteScroll>
  );
};
const OptionComponent = ({
  children,
  ...props
}: {
  children: any;
} & OptionProps<Option>) => {
  if (!props.isMulti) return <selectComponents.Option {...props}>{children}</selectComponents.Option>;
  return (
    <selectComponents.Option {...props}>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {children}
        {props.isSelected && <Tick size={4} />}
      </Flex>
    </selectComponents.Option>
  );
};

const CustomValueContainer = ({
  children,
  ...props
}: {
  children: any;
} & ValueContainerProps<Option>) => {
  const { t } = useTranslation();
  if (!props.hasValue || !props.isMulti) {
    return <selectComponents.ValueContainer {...props}>{children}</selectComponents.ValueContainer>;
  }
  const CHIPSLIMIT = props.selectProps.chipsLimit || 1;
  const [chips, otherChildren] = children;
  const overflowCounter = props.getValue().length;
  const displayChips = chips.slice(0, CHIPSLIMIT);

  return (
    <selectComponents.ValueContainer {...props}>
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        {overflowCounter === props?.options?.length ? (
          <Box
            sx={{
              p: 1,
              fontSize: 0,
              bg: 'secondary',
              borderRadius: 2,
            }}
          >
            {t('All Selected')}
          </Box>
        ) : (
          <Flex sx={{ alignItems: 'center' }}>
            <Flex sx={{ flexWrap: 'wrap' }}>{displayChips}</Flex>
            {overflowCounter > CHIPSLIMIT && (
              <Text sx={{ fontSize: 0, ml: 1, color: 'textDarker' }}>{`+${
                overflowCounter - CHIPSLIMIT
              }`}</Text>
            )}
          </Flex>
        )}
        {otherChildren}
      </Flex>
    </selectComponents.ValueContainer>
  );
};
export type Option = {
  label?: string;
  value: string | null;
  key?: string;
};
export const Select: SelectType = ({
  sx,
  inputStyles,
  disabled,
  disableOptions,
  onCustomSearch,
  showMultiSelectedValuesLength,
  testid,
  ...props
}) => {
  const { theme } = useTheme();
  const selectStyles = getSelectStyles({ inputStyles, theme });

  /**
   * Function to handle custom filter in select
   */
  const customFilter = useCallback(
    (input: string, { action }) => {
      if (action === 'set-value') return props?.value?.label;
      if (onCustomSearch) onCustomSearch(input);
      return input;
    },
    [onCustomSearch, props.value]
  );

  return (
    <Box
      sx={{
        variant: 'forms.select',
        ...sx,
        ...(disabled ? { cursor: 'not-allowed', opacity: '0.5' } : {}),
      }}
      data-testid={testid}
    >
      <ReactSelect
        name="select"
        //@ts-ignore
        styles={selectStyles}
        isDisabled={disabled}
        onInputChange={customFilter}
        closeMenuOnSelect={!props.isMulti}
        isOptionDisabled={(option: Option) => !!(disableOptions && disableOptions(option?.key))}
        hideSelectedOptions={!props.isMulti}
        components={{
          Control: ControlComponent,
          ValueContainer: CustomValueContainer,
          Option: OptionComponent,
          MenuList: MenuListComponent,
        }}
        {...props}
      />
    </Box>
  );
};

export const AsyncSelect: AsyncSelectType = ({ sx, inputStyles, disabled, testid, ...props }) => {
  const { theme } = useTheme();

  const selectStyles = getSelectStyles({ theme, inputStyles });

  return (
    <Box sx={sx}>
      <AsyncReactSelect
        name="select"
        isDisabled={disabled}
        // @ts-ignore
        styles={selectStyles}
        {...props}
      />
    </Box>
  );
};

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    getMoreData?: () => void;
    hasMoreData?: boolean;
    chipsLimit?: number;
    isPaginated?: boolean;
    onCustomSearch?: (text: string) => boolean;
  }
}
