import { initialState } from '../store/initial-state';
import {
  UPDATE_INTERSTATE_RANKINGS,
  UPDATE_INTRASTATE_RANKINGS,
  UPDATE_HOMESTATE_RANKINGS,
} from '../constants';

const rankings = (state = initialState.rankings, action) => {
  switch (action.type) {
    case UPDATE_INTERSTATE_RANKINGS:
      return {
        ...state,
        interstate: {
          components: action.components,
          data: action.data,
        },
      };
    case UPDATE_INTRASTATE_RANKINGS:
      return {
        ...state,
        intrastate: {
          yourRank: action.yourRank,
          yourTotal: action.yourTotal,
          yourState: action.yourState,
          components: action.components,
          data: action.data,
        },
      };
    case UPDATE_HOMESTATE_RANKINGS:
      return {
        ...state,
        homestate: {
          yourRank: action.yourRank,
          yourTotal: action.yourTotal,
          components: action.components,
          data: action.data,
        },
      };
    default:
      return state;
  }
};

export default rankings;
