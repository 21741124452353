import { ThemeUIStyleObject } from 'theme-ui';

import { CustomThemeProperty } from './types';

const option: ThemeUIStyleObject = {
  fontFamily: 'MintGroteskV08',
  bg: 'foreground',
  color: 'text',
  fontSize: 0,
  px: 3,
  py: 2,
  cursor: 'pointer',
  '&:hover': {
    bg: 'foregroundLight',
  },
};

export const attributeSelector = CustomThemeProperty('attributeSelector', {
  primary: {
    control: {
      fontFamily: 'MintGroteskV08',
      color: 'text',
      bg: 'foreground',
      px: 2,
      borderRadius: 6,
      fontWeight: 'bold',
      fontSize: 0,
      cursor: 'pointer',
    },
    option: {
      ...option,
    },
    optionActive: {
      ...option,
      bg: 'foregroundLight',
    },
    dropdownIndicator: {
      svg: { transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)' },
      p: 2,
      color: 'primary',
      lineHeight: 0,
    },
    indicatorSeparator: {
      display: 'none',
    },
  },
});
