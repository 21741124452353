import { DateTime } from 'luxon';

import * as api from '../../middleware/api';
import { RESET_APP_NOTIFICATIONS, UPDATE_APP_NOTIFICATIONS } from '../constants';
import { errorNotification, successNotification, successRegex } from '../../lib/notifications';
import { formatTimeZone } from '../../lib/datetime-helpers';
import i18n from '../../lib/i18n';

export const updateAppNotifications = ({ notifications, totalNotifications }) => ({
  type: UPDATE_APP_NOTIFICATIONS,
  notifications,
  totalNotifications,
});

export const resetAppNotifications = { type: RESET_APP_NOTIFICATIONS };

// Services
export const getAppNotifications =
  (props, token = null) =>
  async (dispatch) => {
    const { category, offset, limit } = props;

    const request = {
      pageNumber: offset,
      pageSize: limit,
      ...(category !== 'inbox' && {
        status: category === 'sent' ? 'SENT' : 'PENDING',
      }),
    };

    const { status, body } =
      category === 'inbox'
        ? await api.getAppNotifications(request, token)
        : await api.getScheduledAppNotifications(request, token);

    if (successRegex.test(status) && body) {
      const { results, total } = body;
      dispatch(
        updateAppNotifications({
          notifications: results,
          totalNotifications: total,
        })
      );
    } else {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t(`Could not retrieve notifications at this time!`),
      });
    }

    return status !== 499;
  };

export const getAppNotification = async (props, token = null) => {
  const { uid, category } = props;

  let result = { content: '' };

  const { status, body } =
    category === 'inbox'
      ? await api.getAppNotification(uid, token)
      : await api.getScheduledAppNotification(uid, token);

  if (successRegex.test(status) && body) {
    result = body;
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not retrieve notification at this time!'),
    });
  }

  return result;
};

export const getAccountUsers = async (props, token = null) => {
  const { tradingGroupName, size, accountNumbers } = props;

  const request = {
    tradingGroupName,
    size,
    accountNumbers,
    page: 0,
    sort: 'displayName',
  };

  const { status, body } = await api.getAccountUsers(request, token);

  let results = { content: [], totalElements: 0 };

  if (successRegex.test(status) && body) {
    results = body;
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not get users at this time!'),
    });
  }

  return results;
};

export const getUnreadNotifications = async (token = null) => {
  const request = {
    read: false,
    pageNumber: 1,
    pageSize: 10,
  };

  const { status, body } = await api.getAppNotifications(request, token);

  return successRegex.test(status) && body ? body : { results: [], total: 0 };
};

export const updateReadNotification = async (uid, token = null) => {
  const { status, body } = await api.updateReadNotification(uid, token);

  if (status !== 200) {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not update notification status at this time!'),
    });
  }

  return status !== 499;
};

export const createAppNotification = async (props, token = null) => {
  const { timeZone, notificationDatetime } = props;
  const notiDateTime = notificationDatetime || DateTime.now();

  const request = {
    ...props,
    notificationDatetime: formatTimeZone(notiDateTime, timeZone),
    token,
  };

  const { status, body } = await api.createAppNotification(request);

  if (successRegex.test(status)) {
    successNotification({
      description: i18n.t('Notification has been created!'),
    });
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not create notification at this time!'),
    });
  }

  return status;
};

export const deleteAppNotification = async (props, token = null) => {
  const request = { ...props, token };
  const { status, body } = await api.deleteAppNotification(request);

  if (successRegex.test(status)) {
    successNotification({
      description: i18n.t('Notification has been deleted!'),
    });
  } else {
    errorNotification({
      code: status,
      errorCode: body.errorCode,
      description: i18n.t('Could not delete notification at this time!'),
    });
  }

  return successRegex.test(status);
};
