import { FC, useState, useEffect } from 'react';
import { Heading, Flex, Box } from 'theme-ui';

import NumberText from '../../NumberText';
import { formatAmount, formatCurrency } from '../../../lib/helpers';
import { convertAndFormatUnitValue } from '../../../lib/unit';
import { Unit } from '../../../../../types';
import { safelyParseNumber } from '../../../utils/number';

export type VisualBarProps = {
  name?: string;
  unit?: string;
  unitIsPrefix?: boolean;
  data: Array<{ value: number; label: string }>;
};

// component that compares each unit and its values
// unit is labelled on the left whereas value is labelled on the right
export const SmallBarChart: FC<VisualBarProps> = ({ name = '', unit = '', unitIsPrefix = false, data }) => {
  const [newData, setNewData] = useState(data);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(newData) && newData.length) {
      setNewData([]);
    } else if (!newData.length && data.length) {
      setNewData(data);
    }
  }, [data, newData, setNewData]);

  const values = newData.map((val) => safelyParseNumber(val.value)).filter((val) => val > 0);
  const formattedNewValueAndUnit = values.map((value) => convertAndFormatUnitValue(value, unit as Unit));
  const highestValue = Math.max(...values);

  return (
    <>
      {!!(name && name.length) && <Heading as="h3">{name}</Heading>}
      {newData.map((entry: any, index) => (
        <Box sx={{ variant: 'barChart.item' }} key={entry.label}>
          <Flex
            sx={{
              variant: 'barChart.value',
              alignItems: 'start',
              flexWrap: 'wrap',
            }}
          >
            <Box>{entry.label}&nbsp;-&nbsp;</Box>
            <NumberText
              value={
                unitIsPrefix
                  ? formatCurrency(formattedNewValueAndUnit[index].formattedNewValue)
                  : formatAmount(formattedNewValueAndUnit[index].formattedNewValue)
              }
              prefix={unitIsPrefix ? formattedNewValueAndUnit[index].newUnit : ''}
              suffix={
                (!unitIsPrefix ? ` ${formattedNewValueAndUnit[index].newUnit}` : '') + entry.suffix || ''
              }
            />
          </Flex>
          <Box
            sx={{
              variant: 'barChart.bar',
              width: `calc(100% * ${entry.value / highestValue})`,
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default SmallBarChart;
