import { FC, useMemo } from 'react';
import { Box, Flex } from 'theme-ui';
import { ResponsivePie } from '@nivo/pie';
import { orderBy, sumBy, eachRight, map } from 'lodash';
// @ts-ignore
import { linearGradientDef } from '@nivo/core';
import currency from 'currency.js';

export type PieTheme = {
  background?: string;
  textColor?: string;
  fontSize?: number;
  axis?: {
    domain?: {
      line?: {
        stroke?: string;
        strokeWidth?: number;
      };
    };
    ticks?: {
      line?: {
        stroke?: string;
        strokeWidth?: number;
      };
    };
  };
  grid?: {
    line?: {
      stroke?: string;
      strokeWidth?: number;
    };
  };
  tooltip?: {
    container?: {
      background?: string;
    };
  };
};

export type PieMargin = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

export type PieChartDataType = [{ id: string; label: string; value: string | number; color?: string }];

export type PieChartPropsType = {
  data: PieChartDataType;
  colors?: string[];
  theme?: PieTheme;
  margin?: PieMargin;
  enableArcLabels?: boolean;
  height?: string;
  enableArcLinkLabels?: boolean;
  legends?: any[];
  groupLeast?: boolean;
  groupLeastThreshold?: number;
  showLegends?: boolean;
};

export const PieChart: FC<PieChartPropsType> = ({
  data,
  colors = ['#6C66FB', '#21DEAB'],
  theme,
  enableArcLabels = false,
  enableArcLinkLabels = false,
  margin,
  legends,
  height,
  groupLeast = false,
  groupLeastThreshold = 0.1,
  showLegends = false,
}) => {
  const processedData = useMemo(() => {
    if (!groupLeast) return data;
    const _sortedData = orderBy(data, ['value'], ['desc']);
    const _totalValue = sumBy(data, 'value');
    const groupedLeastItems: any[] = [];
    let groupedSum = 0;
    eachRight(_sortedData, (sd) => {
      if ((groupedSum + Number(sd.value)) / _totalValue > groupLeastThreshold) return false;
      groupedLeastItems.push(sd);
      groupedSum += Number(sd.value);
      _sortedData.pop();
      return true;
    });
    const otherItems =
      groupedLeastItems.length <= 1
        ? groupedLeastItems
        : [{ id: 'other', label: 'Other', value: groupedSum }];
    const processed = [..._sortedData, ...otherItems];
    return map(processed, (p, i) => ({
      ...p,
      color: colors[i % colors.length],
    }));
  }, [data, groupLeast, groupLeastThreshold, colors]);

  return (
    <>
      <Box sx={{ height: height || 300, p: 3 }}>
        <ResponsivePie
          data={processedData}
          margin={margin}
          enableArcLabels={enableArcLabels}
          enableArcLinkLabels={enableArcLinkLabels}
          colors={colors}
          theme={theme}
          legends={legends}
          tooltip={({ datum: { label, value, color } }) => (
            <Flex
              sx={{
                p: 3,
                borderRadius: 3,
                bg: 'foreground',
                alignItems: 'center',
              }}
            >
              <Box sx={{ bg: color, p: 2, borderRadius: 3, mr: 2 }} />
              <Box>
                {label}: {currency(value, { separator: ',' }).format()}
              </Box>
            </Flex>
          )}
          innerRadius={0.65}
          padAngle={0.7}
          borderWidth={4}
          borderColor={{ from: 'color' }}
          defs={[
            linearGradientDef('gradient', [
              { offset: 0, color: 'inherit', opacity: 0.1 },
              { offset: 100, color: 'inherit', opacity: 0.1 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradient' }]}
        />
      </Box>
      {showLegends && (
        <Box sx={{ pb: 3 }}>
          {processedData.map((set: any) => (
            <Flex
              key={set.label}
              sx={{
                alignItems: 'center',
                mb: 1,
                fontFamily: 'inter',
                fontSize: 1,
              }}
            >
              <Flex sx={{ alignItems: 'center', mr: 'auto' }}>
                <Box
                  sx={{
                    height: 3,
                    width: 3,
                    borderRadius: 6,
                    mr: 2,
                    bg: set.color,
                  }}
                />
                <Box
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {set.label}
                </Box>
              </Flex>
              <Flex sx={{ ml: 'auto', alignItems: 'center' }}>
                {set.vintage && <Box sx={{ color: 'textDarker', mr: 2 }}>{set.vintage}</Box>}
                <Box>{currency(set.value, { separator: ',' }).format()}</Box>
              </Flex>
            </Flex>
          ))}
        </Box>
      )}
    </>
  );
};
