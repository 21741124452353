import { useEffect, useRef } from 'react';
import { useDebouncedState, useEventListener } from '@react-hookz/web';

export type Dimension = {
  width?: number;
  height?: number;
  windowWidth?: number;
  windowHeight?: number;
};

export const useResize = () => {
  const ref = useRef<any>();

  const [dimensions, setDimensions] = useDebouncedState<Dimension>({}, 300, 0);

  useEventListener(
    window as any,
    'resize' as any,
    (() => {
      const { innerWidth, innerHeight } = window;
      const { clientWidth, clientHeight } = ref.current || {};
      if (clientWidth && clientHeight) {
        setDimensions({
          width: clientWidth,
          height: clientHeight,
          windowWidth: innerWidth,
          windowHeight: innerHeight,
        });
      } else {
        setDimensions({
          ...dimensions,
          windowWidth: innerWidth,
          windowHeight: innerHeight,
        });
      }
    }) as any
  );

  useEffect(() => {
    const currentRef = ref.current || {};
    const { clientWidth, clientHeight } = currentRef;
    const { innerWidth, innerHeight } = window;
    setDimensions({
      width: clientWidth,
      height: clientHeight,
      windowWidth: innerWidth,
      windowHeight: innerHeight,
    });
  }, [setDimensions]);

  return { dimensions, ref };
};
