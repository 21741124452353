import { FC } from 'react';
import { IconProps, IconWrapper } from './common';

export const Close: FC<IconProps> = (props) => (
  <IconWrapper
    icon={() => (
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM0.292893 1.70711L16.2929 17.7071L17.7071 16.2929L1.70711 0.292893L0.292893 1.70711Z"
          fill="white"
        />
        <path
          d="M17.7071 1.70711C18.0976 1.31658 18.0976 0.683418 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM0.292894 16.2929C-0.0976303 16.6834 -0.0976304 17.3166 0.292894 17.7071C0.683419 18.0976 1.31658 18.0976 1.70711 17.7071L0.292894 16.2929ZM16.2929 0.292893L0.292894 16.2929L1.70711 17.7071L17.7071 1.70711L16.2929 0.292893Z"
          fill="white"
        />
      </svg>
    )}
    {...props}
  />
);
