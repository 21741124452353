// capitlalises the given path or the current path if not provided
import { KeyboardEvent } from 'react';
import { transform, isPlainObject } from 'lodash';

export const getCurrentPath = (path = window.location.pathname) =>
  path
    .replace(/-/g, ' ')
    .replace(/\//, '')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
// convenient function to check if current path is battery sharing

export const isPathBatterySharing = () => getCurrentPath().toLowerCase().includes('battery sharing');

// TODO Kill this for more specific mapper functions
export const theCursedMapStateToProps = ({
  controls,
  dashboard,
  meters,
  page,
  settings,
  host: { theme, labels },
  transactions,
}: any) => ({
  ...meters,
  ...page,
  ...controls,
  ...dashboard,
  ...settings,
  ...theme,
  ...transactions,
  labels,
});

export const keyToKeyCode = {
  Enter: 13,
  Space: 32,
};

export const genericKeyHandler = (
  event: KeyboardEvent<unknown>,
  key: keyof typeof keyToKeyCode,
  handler: () => void
) => {
  let code;
  if (event.key !== undefined) {
    code = event.key;
  } else if ((event as any).keyIdentifier !== undefined) {
    code = (event as any).keyIdentifier;
  } else if (event.keyCode !== undefined) {
    code = event.keyCode;
  }

  if (code === keyToKeyCode[key] || key === code) {
    handler();
    event.preventDefault();
  }
};

export const deepMap = (obj: Record<any, any>, iterator: any, context: any) =>
  transform<any, Record<any, any>>(obj, (result, val, key) => {
    /* eslint-disable no-param-reassign */
    result[key] = isPlainObject(val)
      ? deepMap(val, iterator, context)
      : iterator.call(context, val, key, obj);
    /* eslint-enable no-param-reassign */
  });
