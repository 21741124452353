/** @jsxImportSource theme-ui */
import { ComponentType, FC, HTMLAttributes, useMemo } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { SizeProps } from 'styled-system';
import pickBy from 'lodash/pickBy';
import { StandardCSSProperties } from '@styled-system/css';

export type IconWrapperProps = {
  icon: ComponentType | string;
  sx?: ThemeUIStyleObject;
  bg?: StandardCSSProperties['backgroundColor'];
  color?: StandardCSSProperties['color'];
  variant?: string;
} & SizeProps &
  HTMLAttributes<'div'>;

export const longSVGStyles = {
  maxWidth: '75%',
  maxHeight: '50%',
};

export type IconProps = Omit<IconWrapperProps, 'icon'>;

const zeroSafeIdentity = (a: any) => a !== undefined;
const stripUndefined = (styleOBj: ThemeUIStyleObject): ThemeUIStyleObject =>
  pickBy(styleOBj, zeroSafeIdentity);

export const createIconStyles = ({ variant, color, size, bg, sx }: IconProps, long?: boolean) => {
  const outlined = variant === 'outlined';

  return stripUndefined({
    variant: outlined ? 'icons.outlined' : 'icons',
    size,
    color,
    bg,
    borderColor: color,
    ...stripUndefined({
      svg: stripUndefined({
        variant: outlined ? 'icons.outlined.svg' : 'icons.svg',
        fill: color,
        ...stripUndefined({
          path: stripUndefined({
            variant: outlined ? 'icons.outlined.svg.path' : 'icons.svg.path',
            fill: color,
          }),
        }),
        ...(long ? longSVGStyles : {}),
      }),
    }),
    ...sx,
  });
};

export const IconWrapper: FC<IconWrapperProps> = ({
  icon: Icon,
  color,
  bg,
  sx = {},
  size,
  variant,
  ...props
}) => {
  const boxSx = useMemo(
    () => createIconStyles({ bg, color, size, sx, variant }),
    [bg, color, size, sx, variant]
  );

  return (
    <Box sx={{ minWidth: size, minHeight: size, ...boxSx }} {...(props as any)}>
      <Icon />
    </Box>
  );
};
