import { ComponentType, lazy, LazyExoticComponent } from 'react';

const PrivacyPolicyTermsEn = lazy(() => import('./PrivacyPolicy.en.mdx'));
const PrivacyPolicyTermsDe = lazy(() => import('./PrivacyPolicy.de.mdx'));
const PrivacyPolicyTermsFr = lazy(() => import('./PrivacyPolicy.fr.mdx'));

const TermsOfAccessEn = lazy(() => import('./TermsOfAccess.en.mdx'));
const TermsOfAccessFr = lazy(() => import('./TermsOfAccess.fr.mdx'));
const TermsOfAccessDe = lazy(() => import('./TermsOfAccess.de.mdx'));
const TermsOfAccessEnVB = lazy(() => import('./TermsOfAccess.vb.en.mdx'));
const TermsOfAccessEnSeda = lazy(() => import('./TermsOfAccess.seda.en.mdx'));
const TermsOfAccessEnRenewNexus = lazy(() => import('./TermsOfAccess.renewnexus.en.mdx'));

type DocumentRef = Record<string, LazyExoticComponent<ComponentType<any>>>;
type LanguageRef = Record<string, DocumentRef>;

export const hostDocs: LanguageRef = {
  // If the language is not found, use one of these files.
  default: {
    'default.privacyPolicy': PrivacyPolicyTermsEn,
    'default.termsOfAccess': TermsOfAccessEn,
    'vb.termsOfAccess': TermsOfAccessEnVB,
    'seda.termsOfAccess': TermsOfAccessEnSeda,
    'renewnexus.termsOfAccess': TermsOfAccessEnRenewNexus,
  },

  // Language specific files
  de: {
    'default.privacyPolicy': PrivacyPolicyTermsDe,
    'default.termsOfAccess': TermsOfAccessDe,
  },
  fr: {
    'default.privacyPolicy': PrivacyPolicyTermsFr,
    'default.termsOfAccess': TermsOfAccessFr,
  },
};
