import { memo } from 'react';
import { Box, Button, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { PersonSVG } from '@power-ledger/assets';

import { ContextMenu, Dropdown } from '../../../components';
import { HeaderDropdownViewProps } from './header-dropdown.types';

export const HeaderDropdownViewBase: React.FC<HeaderDropdownViewProps> = ({
  dropdownVisible,
  dropdownMenuRef,
  dropdownTriggerRef,
  myAccountRef,
  logOut,
  handleDropdownTriggerClick,
  contextNavigate,
  activator,
  dropdownStyles,
}) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      dataTestid="user-dropdown-container"
      dropdownStyles={dropdownStyles}
      overlay={
        <Box ref={dropdownMenuRef} data-testid="user-dropdown">
          <ContextMenu>
            <ContextMenu.Item
              key="1"
              to="/account"
              innerRef={myAccountRef}
              onClick={contextNavigate}
              data-testid="my-account"
            >
              {t('My Account')}
            </ContextMenu.Item>
            <ContextMenu.Item key="0" to="/" onClick={logOut} data-testid="log-out">
              {t('Log out')}
            </ContextMenu.Item>
          </ContextMenu>
        </Box>
      }
      visible={dropdownVisible}
    >
      <div ref={dropdownTriggerRef}>
        {activator ? (
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
            //@ts-ignore
            onClick={handleDropdownTriggerClick}
          >
            {activator}
          </Flex>
        ) : (
          <Button variant="avatar" onClick={handleDropdownTriggerClick}>
            <PersonSVG />
          </Button>
        )}
      </div>
    </Dropdown>
  );
};

export const HeaderDropdownView = memo(HeaderDropdownViewBase);
