import { AuthCallbackHandler } from '../adaptor-types';
import { MockAdaptorTypes } from './mock-adaptor-types';

export const useAuthCallbackHandler: AuthCallbackHandler<MockAdaptorTypes> = (client) => {
  if (!client) {
    throw new Error('Missing Client');
  }

  return {
    loginCallback: client.client.handelLogInCallback,
    logoutCallback: client.client.handelLogOutCallback,
  };
};
