import React from 'react';
import PropTypes from 'prop-types';

export const DropdownArrowSVG = ({ width = '6px', height = '6px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 220 120"
    aria-labelledby="title-dropdown-arrow"
    aria-describedby="desc-dropdown-arrow"
    role="img"
  >
    <title id="title-dropdown-arrow">Dropdown arrow</title>
    <description id="desc-dropdown-arrow">A downward pointing triangle.</description>
    <path
      d="M219.2 6.2C217.7 2.4 214 0 210 0H10C6 0 2.3 2.4.8 6.2-.7 9.9.1 14.2 3 17.1l100 100c2 2
    4.5 2.9 7.1 2.9s5.1-1 7.1-2.9l100-100c2.7-2.9 3.6-7.2 2-10.9z"
      fill="#232629"
    />
  </svg>
);

DropdownArrowSVG.defaultProps = {
  width: '6px',
  height: '6px',
};

DropdownArrowSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
