import { GetTokens } from '../adaptor-types';
import { MockAdaptorTypes } from './mock-adaptor-types';

/**
 * Returns the current tokens for an authenticated user
 * @param client
 * @returns
 */
export const getTokens: GetTokens<MockAdaptorTypes> = async (client) => {
  if (!client) {
    return null;
  }

  const tokens = await client.client.getTokens();

  if (!tokens.accessToken || !tokens.idToken) {
    return null;
  }

  return {
    accessToken: tokens.accessToken,
    idToken: tokens.idToken,
  };
};
