/** @jsxImportSource theme-ui */
import { Themed } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import { FC } from 'react';

export type ContextMenuItemProps = {
  active?: boolean;
  to?: string;
  innerRef?: any;
  onClick?: () => void;
};
export type ContextMenuItemType = FC<ContextMenuItemProps>;

export type ContextMenuType = FC & {
  Item: ContextMenuItemType;
  Group: ContextMenuItemType;
};

export const ContextMenu: ContextMenuType = ({ children }) => (
  <Themed.ul sx={{ variant: 'contextMenus' }}>{children}</Themed.ul>
);

const ContextMenuItem: ContextMenuItemType = ({ children, active, to, innerRef, onClick }) => {
  const history = useHistory();
  const handleOnClick = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) onClick();
  };

  return (
    <Themed.li
      sx={{
        variant: 'contextMenus.items',
        ...(active ? { bg: 'primaryLightest' } : {}),
      }}
      ref={innerRef}
      onClick={handleOnClick}
    >
      {children}
    </Themed.li>
  );
};

const ContextMenuGroup: ContextMenuItemType = ({ children, active }) => (
  <Themed.li
    sx={{
      variant: 'contextMenus.groups',
      ...(active ? { bg: 'primaryLightest' } : {}),
    }}
  >
    {children}
  </Themed.li>
);

ContextMenu.Item = ContextMenuItem;
ContextMenu.Group = ContextMenuGroup;
