import { ColorMode, ColorOrNestedColorScale } from '@theme-ui/css/src/types';
import camelCase from 'lodash/camelCase';
import { shade, tint } from 'polished';
import { TATA_GRID_TYPE, TransactionTypeTranslationKeyMapping } from '../../../../types';

// Not the nicest but it makes the typing good
type ColorKeys =
  | 'backgroundDark'
  | 'greenDark'
  | 'textDark'
  | 'primaryDark'
  | 'secondaryDark'
  | 'accentDark'
  | 'highlightDark'
  | 'mutedDark'
  | 'greyDark'
  | 'warningDark'
  | 'graphics1Dark'
  | 'graphics2Dark'
  | 'backgroundDarker'
  | 'greenDarker'
  | 'textDarker'
  | 'primaryDarker'
  | 'secondaryDarker'
  | 'accentDarker'
  | 'highlightDarker'
  | 'mutedDarker'
  | 'greyDarker'
  | 'warningDarker'
  | 'graphics1Darker'
  | 'graphics2Darker'
  | 'backgroundDarkest'
  | 'greenDarkest'
  | 'textDarkest'
  | 'primaryDarkest'
  | 'secondaryDarkest'
  | 'accentDarkest'
  | 'highlightDarkest'
  | 'mutedDarkest'
  | 'greyDarkest'
  | 'warningDarkest'
  | 'graphics1Darkest'
  | 'graphics2Darkest'
  | 'backgroundLight'
  | 'greenLight'
  | 'textLight'
  | 'primaryLight'
  | 'secondaryLight'
  | 'accentLight'
  | 'highlightLight'
  | 'mutedLight'
  | 'greyLight'
  | 'warningLight'
  | 'graphics1Light'
  | 'graphics2Light'
  | 'backgroundLighter'
  | 'greenLighter'
  | 'textLighter'
  | 'primaryLighter'
  | 'secondaryLighter'
  | 'accentLighter'
  | 'highlightLighter'
  | 'mutedLighter'
  | 'greyLighter'
  | 'warningLighter'
  | 'graphics1Lighter'
  | 'graphics2Lighter'
  | 'backgroundLightest'
  | 'greenLightest'
  | 'textLightest'
  | 'primaryLightest'
  | 'secondaryLightest'
  | 'accentLightest'
  | 'highlightLightest'
  | 'mutedLightest'
  | 'greyLightest'
  | 'warningLightest'
  | 'graphics1Lightest'
  | 'graphics2Lightest';

export type FullPalette = ColorMode & {
  [k in ColorKeys]: ColorOrNestedColorScale;
} & {
  grey: ColorOrNestedColorScale;
  warning: ColorOrNestedColorScale;
  graphics1: ColorOrNestedColorScale;
  graphics2: ColorOrNestedColorScale;
};

export const basePalette = {
  // ColorMode Definitions
  /**
   * Body background color
   */
  background: '#fff',

  /**
   * Body foreground color
   */
  text: '#333333FF',

  /**
   * Primary brand color for links, buttons, etc.
   */
  primary: '#009aa4',

  /**
   * A secondary brand color for alternative styling
   */
  secondary: '#00202A',

  /**
   * A contrast color for emphasizing UI
   */
  accent: '#049AA4',

  /**
   * A background color for highlighting text
   */
  highlight: '#FFAB00',

  /**
   * A faint color for backgrounds, borders, and accents that do not require
   * high contrast with the background color
   */
  muted: '#EAEEEF',

  // Define addition colors at your own risk, they may not be accept by all color theming systems
  grey: '#92a4b4',
  warning: '#F4222D',
  green: '#8DAD02',
  graphics1: '#01788d',
  graphics2: '#e67a00',
  chartPrimary: '#009aa4',

  // "Meter current" and "Meter previous 4 week average" on community usage comparison chart
  meterCurrentPeriod: '#27d9e5',
  meter4WeeksPrior: '#27d9e5',

  // "Community current period" and "Community previous 4 week average" on community usage comparison chart
  communityCurrentPeriod: '#e8a837',
  community4WeeksPrior: '#e8a837',

  // The primary color on enabling new UI
  newUIPrimary: '#245656',
  newUIPrimaryLight: '#368181',
  newUIPrimaryLighter: '#D4EDED',
  newUISecondary: '#EAECF0',
  newUIHighlightText: '#FFFFFF',
  newUIMuted: '#F2F4F7',
};

export type BasePalette = typeof basePalette;

export const adjustmentScale = {
  light: 0.2,
  lighter: 0.3,
  lightest: 0.7,
  dark: 0.2,
  darker: 0.3,
  darkest: 0.7,
};

export const generateFullPalette = (palette: Partial<FullPalette>) =>
  Object.entries(palette).reduce<FullPalette>((acc, [key, val]) => {
    /* eslint-disable no-param-reassign */
    acc[key] = val;
    Object.entries(adjustmentScale).forEach(([scaleKey, scaleValue]) => {
      const adjustedColorKey = camelCase(`${key} ${scaleKey}`);
      if (scaleKey.startsWith('dark')) {
        acc[adjustedColorKey as keyof FullPalette] = shade(scaleValue)(val as any);
      } else {
        acc[adjustedColorKey as keyof FullPalette] = tint(scaleValue)(val as any);
      }
    });
    /* eslint-enable no-param-reassign */
    return acc;
  }, {} as FullPalette);

export const colors: FullPalette = generateFullPalette(basePalette);

export type Colors = FullPalette;

export const graphsScheme = ['#0ACDD7', '#FFAB00', '#013A43', '#040052', '#90BD00'];

export const transactionChartColorScheme: Record<string, ColorOrNestedColorScale | undefined> = {
  [TransactionTypeTranslationKeyMapping.GRID]: '#82C828',
  [TransactionTypeTranslationKeyMapping.P2P]: '#6fb8c9',
  [TransactionTypeTranslationKeyMapping.CATCH_UP]: '#DADADA',
  [TransactionTypeTranslationKeyMapping.ALLOCATION]: '#040052',
  [TransactionTypeTranslationKeyMapping.PREFERENTIAL_TRADE]: colors['greenDark'],
  [TransactionTypeTranslationKeyMapping.PPA]: '#1F6E8C',
  [TransactionTypeTranslationKeyMapping.LOYALTY_P2P]: '#af92c5',
  [TransactionTypeTranslationKeyMapping.PER_WH_FEE_AH]: colors['background'],
  [TransactionTypeTranslationKeyMapping.PER_DAY_FEE_AH]: colors['highlightDarkest'],
  [TransactionTypeTranslationKeyMapping.PER_WH_FEE_PL]: colors['primaryDark'],
  [TransactionTypeTranslationKeyMapping.PER_DAY_FEE_PL]: colors['primaryDarkest'],
  [TransactionTypeTranslationKeyMapping.PER_WH_FEE_OTHER]: colors['highlightDark'],
  [TransactionTypeTranslationKeyMapping.PER_DAY_FEE_OTHER]: colors['highlightDarker'],
  [TransactionTypeTranslationKeyMapping.COLLECTIVE_SELF_TRADE]: '#ffc44c',
  [TransactionTypeTranslationKeyMapping.SELF_TRADE]: '#A98C5C',
  [TATA_GRID_TYPE]: '#9DCB84',
};

export const communityUsageComparisonChartColorScheme: Record<string, string> = {
  meterCurrentPeriod: 'meterCurrentPeriod',
  meter4WeeksPrior: 'meter4WeeksPrior',
  communityCurrentPeriod: 'communityCurrentPeriod',
  community4WeeksPrior: 'community4WeeksPrior',
};

// TODO: Need to talk to Jill about getting more colours for the app
export const chartSourceColours = {
  SOLAR_PV: '#F4D34C',
  WIND: '#69EE5F',
  HYDRO: '#27D9E5',
  BIOMASS: '#FF9D6C',
  NUCLEAR: '#C5A1E9',
  GRID: '#B2B2B2', // textLight
  UNKNOWN: '#DEE3E8', // greyLightest
};
