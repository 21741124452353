import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Flex } from 'theme-ui';
import { LogoPlDeviceSVG } from '@power-ledger/assets';

import './app/lib/sentry';
import './app/lib/i18n';
import App from './app/App';
import { setupAnalytics } from './app/analytics/setupAnalytics';
import { GlobalErrorBoundary } from './app/components';
import NoIE from './app/layouts/public/no-internet-explorer';
import { BUILD_SHA, NODE_ENV } from './app/lib/envHelpers';
import { isIE } from './app/lib/helpers';
import { AppProviders } from './app/providers';
import { AxiosConfig } from './app/lib/axios-config';
import { appHistory } from './app/lib/history';
import { ReduxStoreProvider } from './app/states/store/redux-store-provider';

// TODO: Fix these TS hacks by defining the window object in a .d.ts file.
(window as any).BUILD_SHA = BUILD_SHA;
(window as any).ENV = NODE_ENV;

setupAnalytics();

const documentRoot = document.getElementById('root');

const WrappedApp = () => (
  <StrictMode>
    <GlobalErrorBoundary>
      <AppProviders>
        <AxiosConfig>
          <ReduxStoreProvider>
            <Router history={appHistory}>
              <Suspense
                fallback={
                  <Flex
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <LogoPlDeviceSVG />
                  </Flex>
                }
              >
                {!isIE ? <App /> : <NoIE />}
              </Suspense>
            </Router>
          </ReduxStoreProvider>
        </AxiosConfig>
      </AppProviders>
    </GlobalErrorBoundary>
  </StrictMode>
);

ReactDOM.render(<WrappedApp />, documentRoot);
