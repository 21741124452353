import { FC, useMemo } from 'react';
import { Spinner, SpinnerProps } from 'theme-ui';

import { useTheme } from '../../hooks/use-theme';

export const LoadingOutlined: FC<SpinnerProps> = ({ size, ...props }) => {
  const { theme } = useTheme();

  const calculatedSize: typeof size = useMemo(() => {
    const defaultSize = 24;
    if (typeof size === 'string') return size;
    if (size && theme.sizes) {
      return Number.isInteger(theme.sizes[size]) ? theme.sizes[size] : defaultSize;
    }
    return defaultSize;
  }, [size, theme.sizes]);

  // @ts-ignore
  return <Spinner variant="icon" {...props} size={calculatedSize} />;
};
