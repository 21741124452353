import React from 'react';
import PropTypes from 'prop-types';

const ClassIREC = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
    <path
      d="M40 20C40 31.0469 31.0469 40 20 40C8.95312 40 0 31.0469 0 20C0 8.95312 8.95312 0 20
    0C31.0469 0 40 8.95312 40 20Z"
      fill="url(#paint_linear_class_i_rec)"
    />
    <path
      d="M18.6094 18.625V39.9531C19.0703 39.9844 19.5313 40 20 40C20.4688 40 20.9297 39.9844
    21.3906 39.9531V18.625H18.6094Z"
      fill="#636669"
    />
    <path
      d="M22.1641 13.7031C22.1641 9.39062 21.1953 5.89062 20 5.89062C18.8047 5.89062 17.8359
    9.39062 17.8359 13.7031C17.8359 18.0156 18.8047 21.5156 20 21.5156C21.1953 21.5156 22.1641
    18.0156 22.1641 13.7031Z"
      fill="white"
    />
    <path
      d="M27.5078 22.1251C23.7734 19.9688 20.2578 19.0548 19.6562 20.0938C19.0547 21.1329
    21.6015 23.7188 25.3359 25.8751C29.0703 28.0313 32.5859 28.9454 33.1875 27.9063C33.789 26.8673
    31.25 24.2813 27.5078 22.1251Z"
      fill="white"
    />
    <path
      d="M20.3359 20.0938C19.7344 19.0548 16.2266 19.9688 12.4844 22.1251C8.74219 24.2813
    6.20313 26.8751 6.80469 27.9063C7.40625 28.9454 10.9141 28.0313 14.6563 25.8751C18.3906 23.7188
    20.9375 21.1251 20.3359 20.0938Z"
      fill="white"
    />
    <path
      d="M22.625 20.3047C22.625 21.7578 21.4453 22.9297 20 22.9297C18.5547 22.9297 17.375 21.75
    17.375 20.3047C17.375 18.8594 18.5547 17.6797 20 17.6797C21.4453 17.6797 22.625 18.8516 22.625
    20.3047Z"
      fill="#BEC2C5"
    />
    <defs>
      <linearGradient
        id="paint_linear_class_i_rec"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AFD600" />
        <stop offset="1" stopColor="#8EAD00" />
      </linearGradient>
    </defs>
  </svg>
);

ClassIREC.defaultProps = {
  width: '40px',
  height: '40px',
};

ClassIREC.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ClassIREC;
