import { FC } from 'react';
import { Box, IconButton, Flex, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { Arrow as ArrowIcon } from '../icons/Arrow';

export type PaginationPropsType = {
  pageInfo: any;
  setEndCursor?: (endCursor: string) => void;
  goToPage: (page: number) => void;
  pageCount: number;
  setPageSize: (pageSize: number) => void;
  itemsLabel?: string;
};

export const Pagination: FC<PaginationPropsType> = ({
  setEndCursor,
  pageInfo = {
    endCursor: '',
    startCursor: '',
    hasPreviousPage: false,
    hasNextPage: false,
  },
  itemsLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        bg: 'foregroundLight',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        px: 3,
        alignItems: 'center',
      }}
    >
      <Box sx={{ mr: 'auto' }}>
        <Text>{itemsLabel}</Text>
      </Box>
      <Box>
        <IconButton
          aria-label={t('Previous Page')}
          sx={{ variant: 'tables.normal.pagination.button' }}
          disabled={!pageInfo.hasPreviousPage}
          onClick={() => (setEndCursor ? setEndCursor(pageInfo.startCursor || null) : null)}
        >
          <ArrowIcon />
        </IconButton>
        <IconButton
          aria-label={t('Next Page')}
          sx={{ variant: 'tables.normal.pagination.button' }}
          disabled={!pageInfo.hasNextPage}
          onClick={() => (setEndCursor ? setEndCursor(pageInfo.endCursor || null) : null)}
        >
          <ArrowIcon sx={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      </Box>
    </Flex>
  );
};

export default Pagination;
