// generic base colors used across all themes
const colors = {
  white: '#ffffff',
  grey1000: '#f9fbfd',
  grey900: '#e9ecef',
  grey800: '#d3d6d9',
  grey700: '#bec2c5',
  grey600: '#939699',
  grey500: '#636669',
  grey400: '#46494b',
  grey300: '#323538',
  grey200: '#232629',
  grey100: '#090b0e',
  black: '#000000',

  greyFade: 'rgba(0,0,0,0.3)',
  blueWash: '#008E95',
  pinkWash: '#fff6f6',
  red: '#d6003d',
  redDark: '#aa0031',
  redWash: 'rgba(214, 0, 61, 0.05)',

  brandPrimary: '#AFD600',
  brandSecondary: '#8EAD00',

  loginGradient: 'linear-gradient(to top, #46494b, #232629)',
  buttonGradientCancel: 'linear-gradient(to top, #323538, #939699)',

  panelShadow: '0px 5px 15px rgba(100, 100, 100, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
};

const corners = {
  borderRadius: '2px',
  headerRadius: '2px 2px 0 0',
  footerRadius: '0 0 2px 2px',
};

// Carbon default theme - optional incase we have different theme configurations (e.g. dark mode)
const Theme = {
  ...colors,
  ...corners,
  heroBackground: 'linear-gradient(360deg, #323539 0%, #46494B 100%)',
};

export default Theme;
