// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/CentraNo2-Light/CentraNo2-Light.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/CentraNo2-Light/CentraNo2-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/fonts/CentraNo2-Light/CentraNo2-Light.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/fonts/CentraNo2-LightItalic/CentraNo2-LightItalic.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/fonts/CentraNo2-LightItalic/CentraNo2-LightItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./assets/fonts/CentraNo2-LightItalic/CentraNo2-LightItalic.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./assets/fonts/CentraNo2-Medium/CentraNo2-Medium.eot");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./assets/fonts/CentraNo2-Medium/CentraNo2-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./assets/fonts/CentraNo2-Medium/CentraNo2-Medium.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
exports.push([module.id, "\n@font-face {\n    font-family: \"CentraNo2\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    src: local(CentraNo2 Light),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");\n    font-weight: 300;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: \"CentraNo2\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n    src: local(CentraNo2 LightItalic),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"embedded-opentype\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");\n\n    font-weight: 300;\n    font-style: italic;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: \"CentraNo2\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n    src: local(CentraNo2 Medium),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"embedded-opentype\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\");\n\n    font-weight: 500;\n    font-display: swap;\n}\n", ""]);
// Exports
module.exports = exports;
