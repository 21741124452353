import { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { components as selectComponents } from 'react-select';
import { useLocation } from 'react-router';
import { Box, Flex } from 'theme-ui';
import { DropdownArrowSVG } from '@power-ledger/assets';

import { useTranslation } from 'react-i18next';
import {
  Select,
  LayoutSubheader,
  LayoutSubheaderProps,
  TimeFramePicker,
  WeatherWidget,
  SelectablePeriodType,
} from '../components';
import SearchableMeterModal from '../components/modal/SearchableMeter';
import {
  Settings as SettingsModal,
  IOASettings as IOASettingsModal,
} from '../components/modal/DataReadingsSettings';
import { useHostConfig } from '../contexts/host-config-context';
import { useAppDispatch } from '../hooks/use-app-dispatch';
import { useAppSelector } from '../hooks/use-app-selector';
import { useFeatureSet } from '../hooks/use-feature-set';
import { useIsAdmin } from '../hooks/use-is-admin';
import { useCurrentPage } from '../hooks/use-current-page';
import { useCurrentSubpage } from '../hooks/use-current-subpage';
import { camelise } from '../lib/helpers';
import { formatSiteWeather } from '../lib/weather-helpers';
import { updateSelectedMeter } from '../states/slices/controls';
import { Meter, MeterGroup } from '../../../types';
import { truthy } from '../utils/boolean';

const shouldDisplayMeterSelection = ({
  currentPage,
  isAdmin,
  hasDateRangeSelection,
}: {
  currentPage: string;
  isAdmin: boolean;
  hasDateRangeSelection: boolean;
}) => {
  if (currentPage === 'Pricing') return true;

  if (isAdmin) return hasDateRangeSelection && !['Trading'].includes(currentPage);

  return !['Account', 'Notifications', 'Community Map', 'Loyalty'].includes(currentPage);
};

const ControlsSelection = () => {
  const currentPage = useCurrentPage();
  const currentSubpage = useCurrentSubpage();
  const meters: Meter[] = useAppSelector(({ meters: metersStore }) => metersStore.meters, shallowEqual);
  const { selectedMeter } = useAppSelector(
    ({ controls }) => ({
      period: controls.period,
      startDate: controls.startDate,
      endDate: controls.endDate,
      selectedMeter: controls.selectedMeter,
    }),
    shallowEqual
  );

  const dispatch = useAppDispatch();
  const { isAdmin } = useIsAdmin();

  const { displayDatePickerYearOption } = useFeatureSet();

  const onUpdateCurrentMeter = useCallback(
    (selectedMeterUID: string) => {
      const filteredMeter = meters.filter(({ uid }) => uid === selectedMeterUID);
      const meter = filteredMeter.length ? filteredMeter[0] : null;

      dispatch(updateSelectedMeter(meter));
    },
    [dispatch, meters]
  );

  const enableSearchAll = !['Pricing', 'Energy Mix'].includes(currentPage) && currentSubpage !== 'pricing';

  const currentMeter = useMemo(() => {
    if (selectedMeter) return meters && meters.length ? selectedMeter.uid : selectedMeter.displayName;

    if (meters && meters.length) return meters[0].uid;

    return null;
  }, [meters, selectedMeter]);

  const hasDateRangeSelection =
    (!['Account', 'Notifications', 'Pricing', 'Trading', 'Community Map', 'Loyalty'].includes(currentPage) &&
      (!!currentSubpage.length || (currentPage !== 'Battery Sharing' && !currentSubpage.length))) ||
    ['excess-demand', 'excess-energy'].includes(currentSubpage);

  const hasMeterSelection = shouldDisplayMeterSelection({
    currentPage,
    isAdmin,
    hasDateRangeSelection,
  });

  const meterSelectOptions = useMemo(
    () => meters.map((meter) => ({ value: meter.uid, label: meter.displayName })),
    [meters]
  );

  const selectablePeriodsMapping = {
    DASHBOARD: ['week', 'month', 'custom', displayDatePickerYearOption ? 'year' : null, 'prevFourWeek'],
    DEFAULT: ['day', 'week', 'month', 'custom', displayDatePickerYearOption ? 'year' : null, 'prevFourWeek'],
    AGGREGATED_EXCESS: ['day'],
  };

  return (
    <Flex data-testid="meter-and-date-selectors">
      {hasMeterSelection &&
        currentPage !== 'Battery Sharing' &&
        (isAdmin ? (
          <SearchableMeterModal
            // @ts-ignore
            selectedMeter={selectedMeter}
            enableSearchAll={enableSearchAll}
          />
        ) : (
          meters &&
          meters.length > 1 && (
            <Select
              value={meterSelectOptions.find(({ value }) => value === currentMeter)}
              onChange={({ value }: { value: string }) => onUpdateCurrentMeter(value)}
              borderless
              dataTestid="meter-select"
              menuListDataTestid="meter-select-dropdown"
              sx={{
                display: 'flex',
                '& div:nth-of-type(2)': {
                  width: 'max-content',
                  maxWidth: [200, 320, 320, 400, 500],
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  '> div > div': {
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                },
              }}
              menuStyles={{
                right: 0,
                maxWidth: '300px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              options={meterSelectOptions}
              DropdownIndicator={(props) => (
                <selectComponents.DropdownIndicator {...props}>
                  <DropdownArrowSVG width="8px" />
                </selectComponents.DropdownIndicator>
              )}
            />
          )
        ))}
      {hasDateRangeSelection &&
        (currentPage === 'Dashboard' ? (
          <TimeFramePicker
            selectablePeriods={selectablePeriodsMapping.DASHBOARD.filter(truthy) as SelectablePeriodType[]}
          />
        ) : ['excess-demand', 'excess-energy'].includes(currentSubpage) ? (
          <TimeFramePicker
            selectablePeriods={
              selectablePeriodsMapping.AGGREGATED_EXCESS.filter(truthy) as SelectablePeriodType[]
            }
          />
        ) : (
          <TimeFramePicker
            selectablePeriods={selectablePeriodsMapping.DEFAULT.filter(truthy) as SelectablePeriodType[]}
          />
        ))}
    </Flex>
  );
};

/**
 * Sidebar tab items type
 * TODO: // This should be refactored as part of the larger TS migration.
 **/
interface SubpageItem {
  key: number;
  /** Tab name */
  tab: string;
  /** UI label */
  tabTitle: string;
  /** Path we should route to */
  route: string;
}

export const Subheader: React.FC<{
  currentPageTitle: LayoutSubheaderProps['currentPageTitle'];
}> = ({ currentPageTitle }) => {
  const { t } = useTranslation();
  const { hostHasFlag } = useHostConfig(true);
  const isVB = hostHasFlag('isVB');
  const location = useLocation();

  const meterGroup = useAppSelector(
    ({ meters: metersState }: any) => metersState.meterGroup,
    shallowEqual
  ) as MeterGroup;
  const subpages = useAppSelector(({ subpages: subpagesStore }) => subpagesStore, shallowEqual);
  const currentPage = useCurrentPage();

  const meters = useAppSelector(({ meters: metersStore }) => metersStore.meters, shallowEqual);
  const weatherBySite = useAppSelector(({ dashboard }) => dashboard.weatherBySite, shallowEqual);

  const {
    hasPrefTrade,
    hasPricing,
    hasEstimates,
    hasLP2PMeter,
    hasIOAManagement,
    hasProducer,
    hasConsumer,
    hasProsumer,
    hasExcessDemand,
    hasExcessEnergy,
  } = useFeatureSet();

  const { isAdmin } = useIsAdmin();

  const isDashboard = !currentPage || currentPage === 'Dashboard';

  const formattedSiteWeather = useMemo(
    () => formatSiteWeather(weatherBySite, meterGroup),
    [weatherBySite, meterGroup]
  );

  const subpageItems = useMemo(() => {
    let newSubpageItems: SubpageItem[] = subpages[camelise(currentPage) as keyof typeof subpages];

    if (newSubpageItems && hasPrefTrade && currentPage === 'Trading') {
      // Filter for appropriate subpage tabs to display
      newSubpageItems = newSubpageItems.filter((subpageItem) => {
        if (hasPricing && subpageItem.tab === 'Pricing') return true;

        if (isAdmin && subpageItem.tab === 'Preferential trading') return true;

        if (hasIOAManagement && subpageItem.tab === 'IOA Data') return true;

        if (hasExcessDemand && hasProsumer && subpageItem.tab === 'Excess demand') return true;

        if (hasExcessEnergy && hasConsumer && subpageItem.tab === 'Excess energy') return true;

        if (!isAdmin && subpageItem.tab === 'Direct trades') return true;

        return false;
      });

      if (hasProsumer) {
        newSubpageItems = newSubpageItems.sort(
          (a, b) => b.tab.indexOf('Sell') - a.tab.indexOf('Sell') || a.key - b.key
        );
      }

      if (hasPricing) {
        newSubpageItems = newSubpageItems.sort((a, b) => b.tab.indexOf('Pricing') - a.tab.indexOf('Pricing'));
      }
    }

    if (newSubpageItems && isVB) {
      newSubpageItems = newSubpageItems.filter((subpageItem) => !isAdmin && subpageItem.tab === 'Readings');
    }

    // Admins and any Users that own Prosumer or Producer meters can see: Loyalty tab, and the Vouchers and Campaigns sub-pages.
    // Regular users with a meter (consumer or prosumer) that has the flag: canOfferLoyaltyP2p=true can also see the Vouchers, Campaigns AND “My Campaigns” sub-pages
    if (newSubpageItems && currentPage === 'Loyalty') {
      if (isAdmin) {
        // TODO: There is currently no voucher page for admins so hide it for now
        newSubpageItems = newSubpageItems.filter(
          (subpageItem) => subpageItem.tab !== 'My Campaigns' && subpageItem.tab !== 'Vouchers'
        );
      } else {
        newSubpageItems = newSubpageItems.filter((subpageItem) => {
          return (
            (subpageItem.tab === 'My Campaigns' && hasLP2PMeter && (hasConsumer || hasProsumer)) ||
            (subpageItem.tab === 'Campaigns' && (hasLP2PMeter || hasProducer || hasProsumer)) ||
            (subpageItem.tab === 'Vouchers' && (hasLP2PMeter || hasProducer || hasProsumer))
          );
        });
      }
    }

    return newSubpageItems || [];
  }, [
    currentPage,
    hasConsumer,
    hasLP2PMeter,
    hasPrefTrade,
    hasPricing,
    hasProducer,
    hasProsumer,
    hasIOAManagement,
    hasExcessDemand,
    hasExcessEnergy,
    isAdmin,
    isVB,
    subpages,
  ]);

  const currentPath = location.pathname;

  const layoutActions = useMemo(
    () =>
      subpageItems
        .filter(
          (v) =>
            (v.tab !== 'Estimates' || (v.tab === 'Estimates' && hasEstimates && !hasIOAManagement)) &&
            (currentPage !== 'Battery Sharing' ||
              (currentPage === 'Battery Sharing' && (isAdmin || (!isAdmin && v.tab !== 'Events'))))
        )
        .map(({ key, tabTitle, route }) => ({
          path: route,
          key,
          content: t(tabTitle),
        })),
    [currentPage, hasEstimates, isAdmin, subpageItems, t, hasIOAManagement]
  );

  const shouldHideDataReadingSettings = /^\/trading\/?/.test(location.pathname);

  return (
    <LayoutSubheader
      currentPageTitle={currentPageTitle}
      currentPath={currentPath}
      actions={!!(meters && meters.length && subpageItems && subpageItems.length > 1) && layoutActions}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', columnGap: 2, rowGap: 1, flexWrap: 'wrap' }}>
        {isDashboard && !isVB && <WeatherWidget {...formattedSiteWeather} />}
        <ControlsSelection />
        {hasEstimates &&
          (hasIOAManagement ? (
            shouldHideDataReadingSettings ? null : (
              <IOASettingsModal />
            )
          ) : (
            <SettingsModal />
          ))}
      </Box>
    </LayoutSubheader>
  );
};

export default Subheader;
