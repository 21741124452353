import { lazy } from 'react';

const Dashboard = lazy(() => import('./dashboard'));
const Transactions = lazy(() => import('./transactions'));
const TransactionsRecords = lazy(() => import('./transactions-records'));
const Usage = lazy(() => import('./usage'));
// @ts-ignore
const UsageReadings = lazy(() => import('../common/usage-readings'));
const UsageEstimates = lazy(() => import('./usage-estimates'));
const Billing = lazy(() => import('./billing'));
const BatterySharing = lazy(() => import('./battery-sharing'));
const BatterySharingRecords = lazy(() => import('./battery-sharing-records'));
const DirectTrades = lazy(() => import('./direct-trades'));
const Pricing = lazy(() => import('./pricing'));
const Account = lazy(() => import('./account'));
const AppNotifications = lazy(() => import('./app-notifications'));
const Rankings = lazy(() => import('./rankings'));
const LoyaltyP2PCampaigns = lazy(() => import('./loyalty-p2p/campaigns'));
const LoyaltyP2PCampaignsUser = lazy(() => import('./loyalty-p2p/campaigns-user'));
const LoyaltyP2PCampaignDetails = lazy(() => import('../common/loyalty-p2p/campaign-details'));
const LoyaltyP2PVouchers = lazy(() => import('./loyalty-p2p/vouchers'));
const LoyaltyP2PRoutes = lazy(() => import('./loyalty-p2p/loyalty-p2p-routes'));
const IOAManagement = lazy(() => import('../common/ioa-management'));

const LeaderBoards = lazy(() => import('./victoria-bitter/LeaderBoards'));
const CommunityMap = lazy(() => import('../../components/community-map'));
const EnergyMix = lazy(() => import('../common/energy-mix'));
const ExcessDemand = lazy(() => import('../common/aggregated-excess/excess-demand'));
const ExcessEnergy = lazy(() => import('../common/aggregated-excess/excess-energy'));

const UserRoutes = {
  Dashboard,
  Transactions,
  TransactionsRecords,
  Usage,
  UsageReadings,
  UsageEstimates,
  Billing,
  BatterySharing,
  BatterySharingRecords,
  DirectTrades,
  Pricing,
  Account,
  AppNotifications,
  LeaderBoards,
  Rankings,
  CommunityMap,
  EnergyMix,
  LoyaltyP2PCampaigns,
  LoyaltyP2PCampaignsUser,
  LoyaltyP2PCampaignDetails,
  LoyaltyP2PVouchers,
  LoyaltyP2PRoutes,
  IOAManagement,
  ExcessDemand,
  ExcessEnergy,
};

export default UserRoutes;
