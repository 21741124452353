import axios from 'axios';
import { PasswordUpdateResponse, SignInResponse } from '../../auth-service-types';
import { MockAuthAdaptorEmitter } from './mock-adaptor-emitter';

/**
 * Contains a Mock Auth Client that fires real HTTP requests
 * to a dummy endpoint ready to be intercepted by a e2e mock
 * server
 */
export class MockAuthClient {
  private emitter = new MockAuthAdaptorEmitter();

  public accessToken: undefined | string;
  public idToken: undefined | string;
  public isAuth: undefined | boolean;

  /**
   * Initiates a Mock Auth Client instance
   */
  public init = () => this.getTokens();

  /**
   * Creates a subscription to Authentication Events, this will usually trigger if there is a
   * change in authentication state such as user signed in or signed out
   */
  public subscribe = (callback: (e: MockAuthClient) => void) => this.emitter.subscribe(() => callback(this));

  /**
   * Removes a listener from being subscribed to Authentication Events
   */
  public unSubscribe = (callback: (e: MockAuthClient) => void) =>
    this.emitter.unSubscribe(() => callback(this));

  /**
   * This will send out a get request, please return with the following body and
   * a 200 HTTP response code. Other response codes will return an un-authorised
   * user
   *
   * {
   *   accessToken: string
   *   idToken: string
   * }
   *
   */
  public getTokens = async () => {
    const resp = await axios.get('fake/auth/refresh-token');
    const priorAuth = this.isAuth;

    if (resp.status === 200) {
      this.isAuth = true;
      this.accessToken = resp.data.accessToken;
      this.idToken = resp.data.idToken;

      if (!priorAuth) {
        // Emit at end but only if change has occurred
        this.emitter.dispatchAuthenticated();
      }
    } else {
      this.isAuth = false;

      if (priorAuth !== false) {
        // Emit at end but only if change has occurred
        this.emitter.dispatchNotAuthenticated();
      }
    }

    return {
      accessToken: this.accessToken,
      idToken: this.idToken,
    };
  };

  /**
   * Sends post request with the username, please return 200 if all is good, other
   * responses will throw an exception. This call is seen to always show positive
   * regardless if an account is found or not. #SEKURE!
   */
  public requestPasswordReset = async (data: { username: string }) => {
    const resp = await axios.post('fake/auth/reset-password/request', data);

    if (resp.status !== 200) {
      throw new Error('Error Requesting Password Reset');
    }

    return null;
  };

  /**
   * Sends post request with code and password, return 200 if it is seen to be
   * approved, use another HTTP status code to fail request
   */
  public verifyPasswordReset = async (data: { code: string; password: string }) => {
    const resp = await axios.post('fake/auth/reset-password/verify', data);

    if (resp.status !== 200) {
      return false;
    }

    return true;
  };

  /**
   * This will send out a post request with the Username and Password. It is expected to have the call
   * respond with one of the following responses
   *
   * {
   *   status: success | pw_expired | req_mfa | req_mfa_enroll | locked_out | invalid_cred
   *   accessToken: string
   *   idToken: string
   * }
   *
   */
  public signIn = async (credentials: { username: string; password: string }): Promise<SignInResponse> => {
    const resp = await axios.post('fake/auth/signin', credentials);

    if (resp.status !== 200) {
      throw new Error('Sign In Failed');
    }

    switch (resp.data.status) {
      case 'success': {
        this.isAuth = true;
        this.accessToken = resp.data.accessToken;
        this.idToken = resp.data.idToken;
        this.emitter.dispatchAuthenticated();
        return SignInResponse.SUCCESS;
      }
      case 'pw_expired': {
        return SignInResponse.PW_EXPIRED;
      }
      case 'req_mfa': {
        return SignInResponse.REQ_MFA;
      }
      case 'req_mfa_enroll': {
        return SignInResponse.REQ_MFA_ENROLL;
      }
      case 'locked_out': {
        return SignInResponse.LOCKED_OUT;
      }
      case 'invalid_cred': {
        return SignInResponse.INVALID_CRED;
      }

      default:
        throw new Error(`Unknown Sign In Status: ${resp.data.status}`);
    }
  };

  public signInWithIDP = async (options: { idp: string }): Promise<void> => {
    const resp = await axios.post('fake/auth/idp-signin', options);

    if (resp.status !== 200) {
      throw new Error('Sign In Failed');
    }
  };

  /**
   * Signs the current user out of the client
   */
  public signOut = () => {
    this.isAuth = false;
    this.accessToken = undefined;
    this.idToken = undefined;
    this.emitter.dispatchNotAuthenticated();
  };

  /**
   * Updates a users password
   */
  public passwordUpdate = async (credentials: { oldPassword: string; newPassword: string }) => {
    const resp = await axios.post('fake/auth/password_update', credentials);

    if (resp.status === 200) {
      return PasswordUpdateResponse.SUCCESS;
    }
    return PasswordUpdateResponse.INVALID_CRED;
  };

  /**
   * Handles the login callback handle
   */
  public handelLogInCallback = async (params: Record<string, string | null | undefined>) => {
    await axios.post('fake/auth/login_callback', params);
  };

  /**
   * Handles the logout callback handle
   */
  public handelLogOutCallback = async (params: Record<string, string | null | undefined>) => {
    await axios.post('fake/auth/logout_callback', params);
  };
}
