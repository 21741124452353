import { useHostConfig } from '../../contexts/host-config-context';
import { HostLogoView } from './host-logo.view';
import { useHostLogoLogic } from './use-host-logo';

export const HostLogo: React.FC<{
  maxHeight?: string;
  maxWidth?: string;
  isInverse?: boolean;
}> = ({ maxHeight, maxWidth, isInverse = false }) => {
  const { logo } = useHostLogoLogic(isInverse);
  const { hostHasFlag } = useHostConfig();

  if (!logo && hostHasFlag('hidePowerledgerLogo')) return null;

  return <HostLogoView logo={logo} maxHeight={maxHeight} maxWidth={maxWidth} isInverse={isInverse} />;
};
