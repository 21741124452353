import { AnyAction } from 'redux';

import { UPDATE_ENERGY_MIX_STATISTICS, RESET_ENERGY_MIX } from '../constants';
import { initialState } from '../store/initial-state';
import { EnergyMixStatistics } from '../../../../types';

// Energy Mix reducer
const energyMix = (
  state: EnergyMixStatistics = initialState.energyMix,
  action: AnyAction
): EnergyMixStatistics => {
  switch (action.type) {
    case UPDATE_ENERGY_MIX_STATISTICS:
      return {
        ...state,
        intervals: action.intervals,
        totals: action.totals,
        co2Savings: action.co2Savings,
      };
    case RESET_ENERGY_MIX:
      return initialState.energyMix;
    default:
      return state;
  }
};

export default energyMix;
