import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Input, Label, Text } from 'theme-ui';

import { fitToRangeInclusive } from '../../lib/helpers';
import { Button, ButtonGroup } from '../Button';
import { Select } from '../Select';

const selectOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
];

export const Pagination = ({
  onChange,
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  pageSizeOptions,
  previousPage,
  nextPage,
}: any) => {
  const { t } = useTranslation();

  const pageSizeOpts = useMemo(
    () =>
      pageSizeOptions
        ? pageSizeOptions.map((val: any) => ({
            value: Number(val),
            label: val,
          }))
        : selectOptions,
    [pageSizeOptions]
  );

  const pageSizeValue = useMemo(
    () => pageSizeOpts.find(({ value: val }: any) => val === pageSize),
    [pageSize, pageSizeOpts]
  );

  const [gotoPageValue, setGotoPageValue] = useState(pageIndex + 1);

  const selectOnChange = useCallback(
    ({ value: newVal }: any) => {
      setPageSize(Number(newVal));
      gotoPage(0);
    },
    [gotoPage, setPageSize]
  );

  const inputOnChange = useCallback(
    (e) => {
      const val =
        e.target.value !== ''
          ? fitToRangeInclusive(Number(e.target.value), Number(e.target.min), Number(e.target.max))
          : '';
      setGotoPageValue(val);
      gotoPage(val ? val - 1 : 0);
    },
    [gotoPage]
  );

  const { goToFirst, goToLast, goToPrevious, goToNext } = useMemo(() => {
    const current = pageIndex + 1;

    const goToFirstHandler = () => {
      onChange?.({
        current,
        pageSize,
        pageIndex,
      });
      gotoPage(0);
    };

    const goToLastHandler = () => {
      onChange?.({
        current,
        pageSize,
        pageIndex,
      });
      gotoPage(pageCount - 1);
    };

    const goToPreviousHandler = () => {
      onChange?.({
        current,
        pageSize,
        pageIndex,
      });
      previousPage();
    };

    const goToNextHandler = () => {
      onChange?.({
        current,
        pageSize,
        pageIndex,
      });
      nextPage();
    };

    return {
      goToFirst: goToFirstHandler,
      goToLast: goToLastHandler,
      goToPrevious: goToPreviousHandler,
      goToNext: goToNextHandler,
    };
  }, [gotoPage, nextPage, onChange, pageCount, pageIndex, pageSize, previousPage]);

  if (pageCount === 0 || Number.isNaN(pageCount)) return null;

  return (
    <Flex
      sx={{
        flexDirection: ['column', null, null, 'row'],
        justifyContent: ['flex-start', null, null, 'center'],
        alignItems: 'center',
        px: 1,
        my: 2,
      }}
    >
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', rowGap: 1 }}>
        <ButtonGroup sx={{ display: 'flex', gap: 2, alignItems: 'center', '& > button': { margin: 0 } }}>
          <Button sx={{ minWidth: '36px' }} onClick={goToFirst} disabled={!canPreviousPage}>
            {'<<'}
          </Button>
          <Button sx={{ minWidth: '36px' }} onClick={goToPrevious} disabled={!canPreviousPage}>
            {'<'}
          </Button>
          <Button sx={{ minWidth: '36px' }} onClick={goToNext} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button sx={{ minWidth: '36px' }} onClick={goToLast} disabled={!canNextPage}>
            {'>>'}
          </Button>
        </ButtonGroup>
        <Text mx={2}>
          <Trans t={t} i18nKey="pagination.pageText">
            Page&nbsp;
            <strong>
              {{ pageStart: pageIndex + 1 }} of {{ pageEnd: pageCount || pageIndex + 1 }}
            </strong>
          </Trans>
        </Text>
      </Flex>
      <Text mx={1} sx={{ display: ['none', null, null, 'block'] }}>
        |
      </Text>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mt: [2, null, null, 0],
        }}
      >
        <Label
          sx={{ display: 'inline-block', width: 'auto', mx: 1, '& > input': { height: ['36px', '40px'] } }}
        >
          {t('goToPage', 'Go to page:')}
          <Input
            type="number"
            min={1}
            max={pageCount}
            defaultValue={1}
            value={gotoPageValue}
            onChange={inputOnChange}
            sx={{
              width: ['40px', '64px'],
              minWidth: ['40px', '64px'],
              py: 0,
              px: 2,
              display: 'inline-block',
              ml: 2,
              appearance: 'textfield',
              minHeight: ['36px', '40px'],
            }}
          />
        </Label>
        <Box sx={{ display: 'inline-block', mx: 1, zIndex: 0 }}>
          <Select
            inputStyles={{
              width: ['72px', '96px'],
              minWidth: ['72px', '96px'],
              height: ['36px', '40px'],
              minHeight: ['36px', '40px'],
            }}
            options={pageSizeOpts}
            value={pageSizeValue}
            onChange={selectOnChange}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
