import React from 'react';
import PropTypes from 'prop-types';

export const MenuDotSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    aria-labelledby="title-menu-dot"
    aria-describedby="desc-menu-dot"
    role="img"
  >
    <title id="title-menu-dot">Menu dot icon</title>
    <description id="desc-menu-dot">A symbol showing a filled circle.</description>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.608761161">
      <g transform="translate(-183.000000, -219.000000)" fill="#7CAFB9" fillRule="nonzero">
        <circle cx="188" cy="224" r="5" />
      </g>
    </g>
  </svg>
);

MenuDotSVG.defaultProps = {
  width: '12px',
  height: '12px',
};

MenuDotSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
