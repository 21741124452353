import { DateTime } from 'luxon';

import { IS_DEVELOPMENT, IS_TEST, NODE_ENV } from './envHelpers';

const getCurrentTime = () => DateTime.now().toSeconds();

export const setCookie = (name, value, timestamp) => {
  const envMode = NODE_ENV;
  if (envMode === 'test') {
    localStorage.setItem(name, value);
  } else if (name && value && timestamp) {
    const date = new Date();

    date.setTime(timestamp * 1000);

    const expires = `expires=${date.toUTCString()}`;

    const remainingTime = timestamp - getCurrentTime();

    const isSecure = envMode !== 'test' && !IS_TEST && !IS_DEVELOPMENT ? ' secure;' : '';

    let domain = 'localhost';
    if (envMode === 'production') {
      domain = window.location.host[0] === '.' ? window.location.host.substr(1) : window.location.host;
    }

    document.cookie = `${name}=${value}; path=/; ${expires}; Max-Age=${remainingTime};
    domain=${domain};${isSecure}`;
  }
};

export const getCookie = (name) => {
  const envMode = NODE_ENV;
  let token = null;

  if (envMode === 'test') {
    token = localStorage.getItem(name);
  } else if (name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        token = c.substring(nameEQ.length, c.length);
      }
    }
  }

  return token;
};

export const eraseCookie = (name) => {
  if (NODE_ENV !== 'test') {
    document.cookie = `${name}=; max-age=0; path=/;`;
  }

  localStorage.clear();
};
