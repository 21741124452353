import { Box } from 'theme-ui';

export interface SectionHeaderProps {
  children?: React.ReactNode;
  dataTestid?: string;
  title: React.ReactNode;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ children, dataTestid, title, ...rest }) => (
  <Box
    sx={{ variant: 'sectionHeaders', justifyContent: 'center', alignItems: 'center' }}
    data-testid={dataTestid}
    {...rest}
  >
    <Box sx={{ variant: 'sectionHeaders.title' }} data-testid="title">
      {title}
    </Box>
    {children}
  </Box>
);
