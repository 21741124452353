import { useCallback, useState } from 'react';
import { useAuthService } from '@power-ledger/auth-service';
import { genericKeyHandler } from '@power-ledger/react';
import { Formik } from 'formik';
import { Box, Button, Label, Paragraph, Spinner } from 'theme-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { defaultText } from '../../layouts/public/reset-password/defaultText';
import { LockOutlined } from '../icons';
import { Input } from '../Input';
import { TogglingEye } from '../TogglingEye';
import { ErrorMessage } from './ErrorMessage';
import { Form } from './Form';

export const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const passwordText = defaultText(t);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const togglePassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  const togglePasswordConfirm = useCallback(
    () => setShowPasswordConfirm(!showPasswordConfirm),
    [showPasswordConfirm]
  );
  const toggleNewPassword = useCallback(() => setShowNewPassword(!showNewPassword), [showNewPassword]);

  const { updatePassword } = useAuthService();

  return (
    <Formik
      initialValues={{
        password: '',
        new_password: '',
        newPasswordConfirmation: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required(t('Current password is required!')),
        new_password: Yup.string()
          .required(t('New password is required!'))
          .min(8, t('Password must contain at least 8 characters'))
          .matches(/^.*[A-Z]+.*$/, t('Password must include at least one uppercase letter'))
          .matches(/^.*[a-z]+.*$/, t('Password must include at least one lowercase letter'))
          .matches(/^.*[0-9]+.*$/, t('Password must include at least one number')),
        newPasswordConfirmation: Yup.string().oneOf(
          [Yup.ref('new_password'), null],
          t('Passwords must match')
        ),
      })}
      onSubmit={({ new_password, password }) =>
        updatePassword({
          oldPassword: password,
          newPassword: new_password,
        })
      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Form.Item sx={{ margin: 0 }}>
            <Label>
              {t('Current Password')}
              <Input
                prefix={<LockOutlined color="mutedDark" size={5} />}
                suffix={
                  <TogglingEye
                    color="mutedDark"
                    size={5}
                    hidden={!showPassword}
                    onClick={togglePassword}
                    onKeyPress={(e: any) => genericKeyHandler(e, 'Space', togglePassword)}
                  />
                }
                type={showPassword ? 'text' : 'password'}
                name="password"
                disabled={isSubmitting}
              />
            </Label>
            <ErrorMessage name="password" />
          </Form.Item>
          <Form.Item sx={{ margin: 0 }}>
            <Label>
              {t('New Password')}
              <Input
                prefix={<LockOutlined color="mutedDark" size={5} />}
                suffix={
                  <TogglingEye
                    color="mutedDark"
                    size={5}
                    hidden={!showNewPassword}
                    onClick={toggleNewPassword}
                    onKeyPress={(e: any) => genericKeyHandler(e, 'Space', toggleNewPassword)}
                  />
                }
                type={showNewPassword ? 'text' : 'password'}
                name="new_password"
                disabled={isSubmitting}
              />
            </Label>
            <ErrorMessage name="new_password" />
          </Form.Item>
          <Form.Item sx={{ margin: 0 }}>
            <Label>
              {t('Confirm New Password')}
              <Input
                prefix={<LockOutlined color="mutedDark" size={5} />}
                suffix={
                  <TogglingEye
                    color="mutedDark"
                    size={5}
                    hidden={!showPasswordConfirm}
                    onClick={togglePasswordConfirm}
                  />
                }
                type={showPasswordConfirm ? 'text' : 'password'}
                name="newPasswordConfirmation"
                disabled={isSubmitting}
              />
            </Label>
            <ErrorMessage name="newPasswordConfirmation" />
          </Form.Item>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Paragraph as="small" variant="small">
              {passwordText.description}
            </Paragraph>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Spinner /> : t('Confirm new password')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
