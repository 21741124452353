import { RequestToken } from '../../../../types';
import { POST } from '../api-methods';

export const uploadFile = (file: File, url: string, token: RequestToken) => {
  const formData = new FormData();

  formData.append('file', file);

  return POST(url, true, formData, token as unknown as string);
};
