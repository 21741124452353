import { Theme } from 'theme-ui';

export const links: Theme['links'] = {
  primary: {
    py: 2,
    px: 3,
    borderRadius: 6,
    fontSize: 1,
    color: 'primaryDarkest',
    bg: 'white',
    transition: 'base',
  },
};
