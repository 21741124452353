import { CustomThemeProperty } from './types';

export const timePickers = CustomThemeProperty('timePickers', {
  overlay: {
    backgroundColor: 'background',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 2,
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
  },
  scrollColumn: {
    overflowY: 'scroll',
    height: 10,
    width: 8,
    display: 'inline-flex',
    flexDirection: 'column',
    flex: 1,
  },
  timeButton: {
    textAlign: 'center',
    p: 2,
    m: 0,
    border: 'none',
    backgroundColor: 'background',
    cursor: 'pointer',
    outline: 'none',
    '&:hover,&:focus': {
      backgroundColor: 'primary',
      color: 'background',
    },
  },
});
