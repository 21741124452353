import { useState, useEffect } from 'react';
import { Box, Paragraph } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Select } from '../..';
import { Settings as SettingsIcon } from '../../icons';
import { toggleEstimate } from '../../../states/actions/settings';
import { useAppDispatch } from '../../../hooks/use-app-dispatch';
import { useAppSelector } from '../../../hooks/use-app-selector';

export const Settings = () => {
  const dispatch = useAppDispatch();
  const breakpointIndex = useBreakpointIndex();
  const { t } = useTranslation();
  const { includeEstimates } = useAppSelector(({ settings }) => settings);
  const [isVisible, setIsVisible] = useState(false);
  const [localIncludeEstimates, setLocalIncludeEstimates] = useState(includeEstimates);

  useEffect(() => {
    setLocalIncludeEstimates(includeEstimates);
  }, [includeEstimates]);

  const toggleModal = (visible: boolean) => setIsVisible(visible);

  const updateSettings = (e: React.MouseEvent) => {
    e.persist();
    dispatch(toggleEstimate(localIncludeEstimates));
    toggleModal(false);
  };

  const estimatesOptions = [
    { value: true, label: t('Include estimated readings') },
    { value: false, label: t('Exclude estimated readings') },
  ];

  return (
    <>
      <Button
        data-testid="display-settings"
        variant="secondary"
        sx={{ p: 0 }}
        onClick={() => toggleModal(true)}
      >
        {breakpointIndex !== 0 && <Box sx={{ mr: 1 }}>{t('Settings')}</Box>}
        <SettingsIcon size={5} />
      </Button>
      <Modal
        title={t('Display settings')}
        visible={isVisible}
        onOk={(e) => updateSettings(e)}
        okButtonContent={t('Apply')}
        onCancel={() => toggleModal(false)}
        afterClose={() => setLocalIncludeEstimates(includeEstimates)}
      >
        <div>
          <label>{t('Data sources')}</label>
          <br />
          <Paragraph as="small" variant="small">
            {t('note.estimated-readings')}
          </Paragraph>
          <br />
          <br />
          <Select
            value={estimatesOptions.find(({ value }) => value === localIncludeEstimates)}
            sx={{ width: '100%' }}
            options={estimatesOptions}
            menuPosition="fixed"
            isSearchable={false}
            onChange={({ value }: { value: boolean }) => setLocalIncludeEstimates(value)}
          />
        </div>
      </Modal>
    </>
  );
};
