import React from 'react';
import PropTypes from 'prop-types';

export const SavingsSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 220 220"
    aria-labelledby="title-savings-icon"
    aria-describedby="desc-savings-icon"
    role="img"
  >
    <title id="title-savings-icon">Savings icon</title>
    <description id="desc-savings-icon">A symbol representing a coin.</description>
    <path
      d="M117.3 46v14.1c5.5 1.1 10.3 3.4 14.5 6.9 4.2 3.5 7.4 8.1 9.7 13.6L127.3
    88c-1.1-2.5-2.4-4.9-4.1-6.9s-3.6-3.6-5.9-4.7v25.1l3.8 1.5c7.6 2.8 13.3 6.5 17 10.9 3.7 4.5 5.6
    9.9 5.6 16.4 0 4-.7 7.7-2.1 11-1.4 3.3-3.2 6.2-5.6 8.7-2.4 2.5-5.1 4.5-8.4 6.1-3.2 1.6-6.7
    2.6-10.4 3.2V174H105v-14.7c-6.7-1-12.7-3.5-17.8-7.6s-8.8-9.5-11.2-16.2l14.7-7c1.4 3.7 3.2 6.8
    5.7 9.3 2.4 2.5 5.3 4.3 8.6 5.4v-28.6l-2.2-.9c-7-2.8-12.6-6.3-16.7-10.4C82 99.2 80 93.7 80
    86.9c0-3.7.6-7.1 1.9-10.3 1.3-3.1 3-5.9 5.2-8.2 2.2-2.3 4.8-4.3 7.8-5.7 3-1.5 6.4-2.4
    10-2.9V46h12.4zM97.2 85.8c0 2.4.7 4.5 2 6.2 1.3 1.7 3.3 3.2 5.9 4.6v-21c-2.4.8-4.4 2.1-5.8
    3.8-1.4 1.8-2.1 3.9-2.1 6.4zm29.5 45.5c0-5.4-3.1-9.4-9.4-12.2v24.2c2.7-.9 5-2.3 6.7-4.3 1.8-2
    2.7-4.6 2.7-7.7z"
    />
    <path
      d="M110 10c55.1 0 100 44.9 100 100s-44.9 100-100 100S10 165.1 10 110 54.9 10 110
    10m0-10C49.2 0 0 49.2 0 110s49.2 110 110 110 110-49.2 110-110S170.8 0 110 0z"
    />
    <path
      d="M110 202.5c-50.7 0-92-41.3-92-92h4c0 48.5 39.5 88 88 88s88-39.5 88-88h4c0 50.7-41.3
    92-92 92z"
    />
  </svg>
);

SavingsSVG.defaultProps = {
  width: '22px',
  height: '22px',
};

SavingsSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
