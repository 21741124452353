/** @jsxImportSource theme-ui */
import { BarTooltipProps } from '@nivo/bar';
import { FC, memo } from 'react';
import { Heading } from 'theme-ui';

export type HorizontalPercentageChartTooltipProps = {
  point: BarTooltipProps<{
    date: number;
  }>;
  labels?: (id: string | number) => string;
};

export const HorizontalPercentageChartTooltip: FC<HorizontalPercentageChartTooltipProps> = memo(
  ({ labels, point }) => (
    <Heading as="h4">
      {labels ? labels(point.id) : point.id} {`${Math.floor((point.value || 0) * 10000) / 100}%`}
    </Heading>
  )
);
