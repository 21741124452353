import styled from 'styled-components';
import { Flex, ThemeUIStyleObject } from 'theme-ui';

const LoadingSvg = styled.svg`
  path {
    animation-name: fade-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transition: ease-in-out;
    &:nth-child(2) {
      animation-delay: ${(1 / 8) * 1}s;
    }
    &:nth-child(3) {
      animation-delay: ${(1 / 8) * 2}s;
    }
    &:nth-child(4) {
      animation-delay: ${(1 / 8) * 3}s;
    }
    &:nth-child(5) {
      animation-delay: ${(1 / 8) * 4}s;
    }
    &:nth-child(6) {
      animation-delay: ${(1 / 8) * 5}s;
    }
    &:nth-child(7) {
      animation-delay: ${(1 / 8) * 6}s;
    }
    &:nth-child(8) {
      animation-delay: ${(1 / 8) * 7}s;
    }
    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0.3;
      }
    }
  }
`;

export const Loading = ({ sx, size }: { sx?: ThemeUIStyleObject; size?: 'normal' | 'small' | 'tiny' }) => {
  return (
    <Flex sx={{ flexShrink: 0, alignItems: 'center', ...sx }}>
      <LoadingSvg
        width={size === 'tiny' ? 16 : size === 'small' ? 36 : 48}
        height={size === 'tiny' ? 16 : size === 'small' ? 36 : 48}
        aria-label="loading"
        role="alert"
        aria-busy="true"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.375 1.875V9.375"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.9"
          d="M40.2854 8.4646L34.9814 13.7686"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.8"
          d="M46.875 24.375H39.375"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.7"
          d="M40.2854 40.2854L34.9814 34.9814"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.6"
          d="M24.375 46.875V39.375"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M8.46436 40.2854L13.7684 34.9814"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M1.875 24.375H9.375"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M8.46436 8.4646L13.7684 13.7686"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </LoadingSvg>
    </Flex>
  );
};
