import { useCallback, useRef, useState } from 'react';
import { getCurrentPath } from '@power-ledger/react';

import { useClickAway } from '../../../hooks/use-click-away';
import { useLogOut } from '../../../hooks/use-log-out';
import { UseHeaderDropdownProps } from './header-dropdown.types';

export const useHeaderDropdown = ({ navigate }: UseHeaderDropdownProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownTriggerRef = useRef<HTMLDivElement | null>(null);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const myAccountRef = useRef<HTMLDivElement | null>(null);
  const { logOut } = useLogOut();
  useClickAway({
    containerRef: dropdownMenuRef,
    clickAwayHandler: () => setDropdownVisible(false),
    isClickingAway: (event) => {
      const targetClassName = (event.target as HTMLElement).className;

      return typeof targetClassName !== 'string' || !targetClassName.includes('avatar-dropdown');
    },
  });

  const handleDropdownTriggerClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setDropdownVisible(!dropdownVisible);
    },
    [dropdownVisible]
  );

  const contextNavigate = useCallback(() => {
    const currentPath = getCurrentPath();
    navigate(currentPath);
    setDropdownVisible(false);
    dropdownTriggerRef.current?.focus();
  }, [navigate]);

  return {
    dropdownVisible,
    dropdownTriggerRef,
    dropdownMenuRef,
    myAccountRef,
    logOut,
    handleDropdownTriggerClick,
    contextNavigate,
  };
};
