import { isPathBatterySharing } from '@power-ledger/react';
import {
  DateTime,
  DateTimeFormatOptions,
  Duration,
  DurationObjectUnits,
  IANAZone,
  Interval,
  Settings,
  Zone,
} from 'luxon';

import { getTimeFormatFromLocalStorage } from '../lib/storage';
import { TimePeriod } from '../../../types';
import i18n, { getCurrentLanguage } from './i18n';

const getUserTimeFormat = () => {
  return getTimeFormatFromLocalStorage();
};

// NOTE: 🤮 this should be decided within React land, a large app-refactor is required for this though
// and is currently out of scope... XGRID-3451
export const hostHasUSDateFormat = () => navigator.language.toUpperCase().includes('US');

export const DAY_MONTH_SHORT: DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
};

export const HOUR_MINUTE_WITH_TZ: DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
};

const getDateFormat = () => (hostHasUSDateFormat() ? 'MMM dd' : 'dd MMM');
const getSelectedDateFormat = () => `${getDateFormat()} yyyy`;
const getShortFullDateFormat = () => (hostHasUSDateFormat() ? 'yy/M/d' : 'd/M/yy');
const getShortDateFormat = () => (hostHasUSDateFormat() ? 'M/d' : 'd/M');
const getChartDateNiceFormat = () => `${getShortDateFormat()} (EEE)`;

export const getPresetTimeFormatWithSeconds = () => {
  const selectedTimeFormat = getUserTimeFormat();

  const timeFormatOptions: Record<string, DateTimeFormatOptions> = {
    '12': DateTime.TIME_WITH_SECONDS,
    '24': DateTime.TIME_24_WITH_SECONDS,
  };

  return timeFormatOptions[selectedTimeFormat];
};

export const getPresetTimeFormat = () => {
  const selectedTimeFormat = getUserTimeFormat();

  const timeFormatOptions: Record<string, DateTimeFormatOptions> = {
    '12': DateTime.TIME_SIMPLE,
    '24': DateTime.TIME_24_SIMPLE,
  };

  return timeFormatOptions[selectedTimeFormat];
};

export const getTimeFormat = () => {
  const selectedTimeFormat = getUserTimeFormat();

  const timeFormatOptions: Record<string, string> = {
    '12': 'h:mm a',
    '24': 'HH:mm',
  };

  return timeFormatOptions[selectedTimeFormat];
};

export const getChartDatetimeFormat = () => `${getShortDateFormat()} ${getTimeFormat()}`;

const getUsageReadingsCsvDatetimeFormat = () => `${getSelectedDateFormat()} HH:mm:ss ZZ`;
const getCsvDatetimeFormat = () => `${getSelectedDateFormat()} ${getTimeFormat()}`;
export const getCsvFullDatetimeFormat = () => `${getCsvDatetimeFormat()}:ss`;
export const timezoneDatetimeFormat = getDateFormat();
export const extendedDateTime = (useEUTimeFormat = false) =>
  `${useEUTimeFormat ? 'dd MMM' : 'MMM dd'}, ${getTimeFormat()}`;
export const isoFormat = "yyyy-MM-dd'T'HH:mm:ssZZ";
export const hh = 'HH';
export const mm = 'mm';
export const selectedDateFormat = getSelectedDateFormat();
export const selectedDatetimeFormat = getSelectedDateFormat();
export const requestDateFormat = 'yyyy-MM-dd';
export const requestDatetimeFormat = `${requestDateFormat}'T'HH:mm:00ZZ`;
export const chartDateNiceFormat = getChartDateNiceFormat();

export type UndeterminedDate = string | Date | DateTime | null | undefined | number;

export const parseDate = (val: UndeterminedDate, timeZone?: string): DateTime =>
  parseDateWithTimeZone(val, timeZone || Settings.defaultZone);

const badDateFormatRegex = /(\d{4}-[01]\d-[0-3]\d [\d:]{5})/;

export const parseDateWithoutTimeZone = (value: UndeterminedDate): DateTime => {
  if (typeof value === 'string') {
    const match = value.match(badDateFormatRegex);

    if (match) {
      return DateTime.fromFormat(match[1], `${requestDateFormat} HH:mm`);
    }

    return DateTime.fromISO(value);
  }

  if (typeof value === 'number') return DateTime.fromMillis(value);

  if (value instanceof Date) return DateTime.fromJSDate(value);

  if (DateTime.isDateTime(value)) return value;

  return DateTime.now();
};

export const parseDateWithTimeZone = (value: UndeterminedDate, timeZone: string | Zone = 'utc'): DateTime => {
  const timeZoneToUse = timeZone === '' ? 'utc' : timeZone;
  const zone = timeZoneToUse instanceof Zone ? timeZoneToUse : new IANAZone(timeZoneToUse);

  if (!zone.isValid) {
    throw new Error(`Invalid timezone: ${timeZoneToUse}, must be an IANAZone`);
  }

  if (typeof value === 'string') {
    const match = value.match(badDateFormatRegex);

    if (match) {
      return DateTime.fromFormat(match[1], `${requestDateFormat} HH:mm`).setZone(zone, {
        keepLocalTime: true,
      });
    }

    return DateTime.fromISO(value, { zone });
  }

  if (typeof value === 'number') return DateTime.fromMillis(value, { zone });

  if (value instanceof Date) return DateTime.fromJSDate(value, { zone });

  if (DateTime.isDateTime(value)) return value.setZone(zone);

  return DateTime.now().setZone(zone);
};

const parseCsvDatetimeFormat = (value: string) => {
  const dateTime = DateTime.fromFormat(value, getCsvDatetimeFormat());

  if (dateTime.isValid) return dateTime;

  return DateTime.fromFormat(value, getCsvFullDatetimeFormat());
};

export const convertDatetimeToDate = (value?: string) => {
  const dateTime = value ? parseCsvDatetimeFormat(value) : DateTime.now();

  return dateTime.toFormat(selectedDateFormat);
};

export const convertDatetimeToTime = (value: string, format?: string) => {
  const dateTime = value ? parseCsvDatetimeFormat(value) : DateTime.now();

  return dateTime.toFormat(format || getTimeFormat());
};

export const formatTimeToSeconds = (value: UndeterminedDate = DateTime.now()) =>
  parseDate(value).toMillis() / 1000;

export const formatRelativeTime = (value: UndeterminedDate = DateTime.now()) => parseDate(value).toRelative();

export const formatTimeZone = (value: UndeterminedDate = DateTime.now(), timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).toISO();

export const formatTimeZoneToDate = (value: UndeterminedDate = DateTime.now(), timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).toFormat(selectedDateFormat);

export const formatFuturePastDate = (
  value: UndeterminedDate | null = null,
  timeZone: string,
  days: number,
  format = 'date'
) => {
  let newFormat;

  switch (format) {
    case 'date':
      newFormat = requestDateFormat;
      break;
    case 'time':
      newFormat = '00:00';
      break;
    case 'datetime':
      newFormat = `${requestDateFormat} 00:00`;
      break;
    default:
      newFormat = format;
      break;
  }

  // TODO find out why this does not work
  // const datetime = value !== null ? moment(value) : DateTime.now();
  const datetime = value !== null && value !== '' ? parseDate(value) : DateTime.now();
  const parsedDate = parseDateWithTimeZone(days !== 0 ? datetime.plus({ days }) : datetime, timeZone);

  return parsedDate.toFormat(newFormat);
};

const unicodeNumberMatchRegex = /\p{Number}/gu;

const handleIntlDateTimeSetup = (lng: string | string[], opts: DateTimeFormatOptions = {}) => {
  const fallbackFormat = new Intl.DateTimeFormat(undefined, opts);

  try {
    const format = new Intl.DateTimeFormat(lng, opts);

    if (format.resolvedOptions().locale !== lng) return fallbackFormat;

    return format;
  } catch (e) {
    if (e instanceof RangeError) return fallbackFormat;

    throw e;
  }
};

export const formatToFormatStr = (lng = getCurrentLanguage(i18n)) => {
  const format = handleIntlDateTimeSetup(lng);
  const formatParts = format.formatToParts(new Date());

  return formatParts
    .map(({ type, value }) => {
      switch (type) {
        case 'day':
          return value.replace(unicodeNumberMatchRegex, 'd');
        case 'dayPeriod':
          return 'dayPeriod';
        case 'era':
          return 'era';
        case 'hour':
          return value.replace(unicodeNumberMatchRegex, 'h');
        case 'literal':
          return value;
        case 'minute':
          return value.replace(unicodeNumberMatchRegex, 'm');
        case 'month':
          return value.replace(unicodeNumberMatchRegex, 'M');
        case 'second':
          return value.replace(unicodeNumberMatchRegex, 's');
        case 'timeZoneName':
          return 'T';
        case 'weekday':
          return 'weekday';
        case 'year':
          return value.replace(unicodeNumberMatchRegex, 'y');
        default:
          return '';
      }
    })
    .join('');
};

export const getMonthsForLocale = (
  locale: string,
  monthFormat: Intl.DateTimeFormatOptions['month'] = 'long'
) => {
  const format = handleIntlDateTimeSetup(locale, { month: monthFormat });

  return Array.from({ length: 12 }, (_, i) => {
    const testDate = new Date(Date.UTC(2000, i, 1, 0, 0, 0));

    return format.format(testDate);
  });
};

const specialWeekThatStartsOnSunday = { year: 2000, month: 1, dayOffset: 6 };

export const getDaysOfWeekForLocale = (
  locale: string,
  monthFormat: Intl.DateTimeFormatOptions['weekday'] = 'short'
) => {
  const format = handleIntlDateTimeSetup(locale, { weekday: monthFormat });
  return Array.from({ length: 7 }, (_, i) => {
    const testDate = new Date(
      Date.UTC(
        specialWeekThatStartsOnSunday.year,
        specialWeekThatStartsOnSunday.month,
        i + specialWeekThatStartsOnSunday.dayOffset,
        0,
        0,
        0
      )
    );
    return format.format(testDate);
  });
};

// converts the given date into date format to present as the input placeholder or initial value
export const formatMonthYearDate = (value: UndeterminedDate, timeZone?: string) => {
  const datetime = timeZone ? parseDateWithTimeZone(value, timeZone) : parseDate(value);

  return datetime.toFormat('MMMM yyyy');
};

// constructs a single date-time string given separate date and time values
// if a timezone is given the appropriate offset is appended to the end of the string
export const constructDateTime = (date: UndeterminedDate, time: string, timeZone = 'utc') =>
  DateTime.fromISO(`${parseDate(date).toFormat(requestDateFormat)}T${time}:00`, {
    zone: timeZone,
  }).toISO();

export const getHourIntervals = (interval: number) => {
  const oneHourInMinutes = 3600;
  const numInterval = oneHourInMinutes / interval;
  const intervals = ['00'];

  if (oneHourInMinutes >= interval && numInterval.toString().indexOf('.') === -1) {
    const minuteInterval = interval / 60;
    let intervalInc = minuteInterval;

    for (let i = 1; i < numInterval; i++) {
      if (intervalInc > 0 && intervalInc < 10) {
        intervals.push(`0${intervalInc.toString()}`);
      } else {
        intervals.push(intervalInc.toString());
      }

      intervalInc += minuteInterval;
    }
  }

  return intervals;
};

const roundDateToNearestInterval = (date: DateTime, durationObj: DurationObjectUnits) => {
  const duration = Duration.fromObject(durationObj);
  // @ts-ignore
  return DateTime.fromMillis(Math.ceil(date / duration) * duration, {
    zone: date.zone,
  });
};

export const formatDatetimeWithInterval = (
  timeZone: string,
  intervalInSeconds: number,
  dateTime: DateTime
) => {
  const isWholeHourInterval = Number.isInteger(
    Duration.fromObject({ hours: 1 }).as('seconds') / intervalInSeconds
  );
  const intervalToUse = isWholeHourInterval
    ? intervalInSeconds
    : Duration.fromObject({ minutes: 30 }).as('seconds');

  const pastDate = parseDateWithTimeZone(dateTime, timeZone);

  const roundedDate = roundDateToNearestInterval(pastDate, {
    seconds: intervalToUse,
  });

  return roundedDate.set({ second: 0, millisecond: 0 }).toFormat(requestDatetimeFormat);
};

export const formatPast24HourDatetime = (timeZone: string, intervalInSeconds: number, days = 0) => {
  const isWholeHourInterval = Number.isInteger(
    Duration.fromObject({ hours: 1 }).as('seconds') / intervalInSeconds
  );
  const intervalToUse = isWholeHourInterval
    ? intervalInSeconds
    : Duration.fromObject({ minutes: 30 }).as('seconds');

  const pastDate = parseDateWithTimeZone(DateTime.now(), timeZone).minus({ days });

  const roundedDate = roundDateToNearestInterval(pastDate, {
    seconds: intervalToUse,
  });

  return roundedDate.set({ second: 0, millisecond: 0 }).toFormat(requestDatetimeFormat);
};

// subtracts an amount of given days onto the given formatted datetime
export const subtractDateTimeByDays = (value: UndeterminedDate, days: number) =>
  parseDate(value)
    .minus({ days: days - 1 })
    .set({ hour: 0, minute: 0 });

// converts the given date into date format to present as the input placeholder or initial value
export const formatDatepickerDate = (value: UndeterminedDate, timeZone?: string) => {
  const datetime = timeZone ? parseDateWithTimeZone(value, timeZone) : parseDate(value);

  return datetime.toFormat(selectedDateFormat);
};

// compares the given start and end dates and check if they are equal
export const areDatesEqual = (start: DateTime, end: DateTime = DateTime.now()) =>
  typeof start === 'object' && typeof end === 'object'
    ? +start === +end
    : formatDatepickerDate(start) === formatDatepickerDate(end);

// checks if the given start and end dates are within range based on the given limit
export const areDatesWithinRange = (start: UndeterminedDate, end: UndeterminedDate, limit: number) => {
  const diff = Math.floor(parseDate(end).diff(parseDate(start), 'days').as('days'));
  return !(limit > 0 && diff > limit);
};

export const areDatesWithinOneDay = (start: UndeterminedDate, end: UndeterminedDate) => {
  const days = parseDate(end).diff(parseDate(start), 'minutes').as('days');

  return days <= 1;
};

export const areDatesWithinOneMonth = (start: UndeterminedDate, end: UndeterminedDate) => {
  const startDate = parseDate(start);
  const endDate = parseDate(end);

  return endDate.diff(startDate, 'days').as('days') <= 31;
};

export const doDatesSpanOneYear = (start: UndeterminedDate, end: UndeterminedDate) => {
  const startDate = parseDate(start);
  const endDate = parseDate(end);

  return Math.ceil(endDate.diff(startDate, 'months').months) === 12;
};

// compare the given 2 timestamps to sort by with an optional format to parse to moment
export const compareTimestamps = (timestampA: string, timestampB: string, format: string) =>
  format
    ? DateTime.fromFormat(timestampA, format).toMillis() - DateTime.fromFormat(timestampB, format).toMillis()
    : DateTime.fromISO(timestampA).toMillis() - DateTime.fromISO(timestampB).toMillis();

// Formats the selected start and end dates into an appropriate description based on given period
export const formatSelectedDates = (
  period: TimePeriod,
  startDate: UndeterminedDate,
  endDate: UndeterminedDate,
  t: (text: string) => string,
  timeZone?: string
) => {
  if (period === 'past') return t('past 24 hours');

  if (period === 'day') return formatDatepickerDate(startDate, timeZone);

  if (period === 'week') {
    const formattedEndDate = formatDatepickerDate(subtractDateTimeByDays(endDate, 1), timeZone);

    return `${formatDatepickerDate(startDate)} to ${formattedEndDate}`;
  }

  if (period === 'month') return formatMonthYearDate(startDate, timeZone);

  return `${formatDatepickerDate(startDate, timeZone)} to ${formatDatepickerDate(endDate, timeZone)}`;
};

// converts the given date into full datetime format along with day name of the week
// blockchain transaction modal datetime
export const formatFullDaySelectedDatetime = (value: DateTime | number = DateTime.now()) =>
  parseDate(value).toFormat(`EEE, ${selectedDatetimeFormat}:ss`);

// converts the given date into date format to send as a request to data services
export const formatRequestDate = (value: UndeterminedDate, timeZone?: string) => {
  const datetime = timeZone ? parseDateWithTimeZone(value, timeZone) : parseDate(value);

  return datetime.toFormat(requestDateFormat);
};

// converts the given date into datetime format to send as a request to data services
export const formatRequestDatetime = (value = DateTime.now()) =>
  parseDate(value).toFormat(`${requestDateFormat} T`);

// converts the given date into full datetime format for selected date-range
export const formatFullRequestDateTime = (value: UndeterminedDate = DateTime.now(), timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).toFormat(requestDatetimeFormat);

// formats the date without spaces in between to insert as part of the CSV filename
export const formatCSVDate = (value = DateTime.now(), timeZone = 'utc') =>
  parseDate(value, timeZone).toFormat('yyyyMMdd');

// converts the given date into datetime format for CSV data rows
export const formatCsvDatetime = (value = DateTime.now(), tz = Settings.defaultZone) => {
  return parseDateWithTimeZone(value, tz).toFormat(getCsvDatetimeFormat());
};

export const formatUsageReadingsCsvDatetime = (value = DateTime.now(), tz = Settings.defaultZone) => {
  return parseDateWithTimeZone(value, tz).toFormat(getUsageReadingsCsvDatetimeFormat());
};

// converts the given date into full datetime format for CSV data rows
export const formatCsvFullDatetime = (value: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).toFormat(getCsvFullDatetimeFormat());

export const formatDateAndTime = (value: UndeterminedDate, timeZone: string, useEUTimeFormat = false) => {
  const parsedDate = parseDateWithTimeZone(value, timeZone);

  return {
    date: useEUTimeFormat
      ? `${parsedDate.toLocaleString({ day: 'numeric' })} 
      ${parsedDate.toLocaleString({ month: 'short' })} 
      ${parsedDate.toLocaleString({ year: 'numeric' })}`
      : parsedDate.toLocaleString(DateTime.DATE_MED),
    time: parsedDate.toLocaleString(getPresetTimeFormat()),
  };
};

export const formatAggregatedExcessDate = (ISOvalue: string) => {
  return DateTime.fromISO(ISOvalue).toLocaleString({
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  });
};

// converts the given date into day/month format displaying the month short name
export const formatWeatherDate = (value: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).weekdayShort;

// converts the given date into time format excluding seconds
export const formatTimeOnly = (value: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).toFormat(getTimeFormat());

// converts the given date into a nice time format showing AM/PM as well
export const formatNiceTime = (value: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(value, timeZone).toFormat(getTimeFormat());

// converts the given date into day/month format
export const formatDayMonthOnly = (value: UndeterminedDate, timeZone?: string) => {
  const parsedDate = timeZone ? parseDateWithTimeZone(value, timeZone) : parseDateWithoutTimeZone(value);

  return parsedDate.toFormat(getShortDateFormat());
};

export const formatDayMonthYear = (value: UndeterminedDate, timeZone?: string) => {
  const parsedDate = timeZone ? parseDateWithTimeZone(value, timeZone) : parseDateWithoutTimeZone(value);

  return parsedDate.toFormat(getShortFullDateFormat());
};

// converts the given date into month format
export const formatMonthOnly = (value = DateTime.now()) => parseDate(value).toFormat('M');

// converts the given date into year format
export const formatYearOnly = (value = DateTime.now()) => parseDate(value).toFormat('yy');

// formats a datetime given the period
export const formatDateByPeriod = (datetime: DateTime, period: TimePeriod = 'past', timeZone: string) =>
  period === 'week' || period === 'month' || period === 'custom'
    ? formatDayMonthOnly(datetime, timeZone)
    : formatTimeOnly(datetime, timeZone);

// converts the given datetime into a presentable datetime format
export const formatDatetime = (datetime: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(datetime, timeZone).toFormat(getChartDatetimeFormat());

// converts the given datetime into a presentable and nicer datetime format for chart
export const formatNiceDatetime = (datetime: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(datetime, timeZone).toFormat(chartDateNiceFormat);

// converts the given datetime into a presentable and nicer datetime format for offer details
export const formatNiceOfferDateTime = (datetime: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(datetime, timeZone).toFormat(`${selectedDateFormat}, ${getTimeFormat()}`);

// converts the given datetime from a LP2P campaign into a presentable and nicer date format
export const formatNiceCampaignDate = (
  datetime: UndeterminedDate,
  timeZone: string,
  isEUTimeFormat = false
) => {
  const parsedDate = parseDateWithTimeZone(datetime, timeZone);

  if (isEUTimeFormat) {
    return `${parsedDate.toLocaleString({ day: 'numeric' })} ${parsedDate.toLocaleString({
      month: 'short',
    })}, ${parsedDate.toLocaleString({ year: 'numeric' })}`;
  }

  return parsedDate.toLocaleString(DateTime.DATE_MED);
};

// converts the given datetime into a localised, medium length date string based on the
// system locale settings of the user
export const formatMediumDate = (datetime: UndeterminedDate, isEUTimeFormat = false) => {
  const parsedDate = parseDateWithoutTimeZone(datetime);

  if (isEUTimeFormat) {
    return `${parsedDate.toLocaleString({ day: 'numeric' })} ${parsedDate.toLocaleString({
      month: 'short',
    })}, ${parsedDate.toLocaleString({ year: 'numeric' })}`;
  }

  return parsedDate.toLocaleString(DateTime.DATE_MED);
};

// converts the given datetime into a notification format based on the current state by comparing
// past, present and future date-time
export const formatNotificationDateTime = (datetime: UndeterminedDate, timeZone: string) => {
  const formattedDateTime = parseDateWithTimeZone(datetime, timeZone);

  if (DateTime.now().hasSame(formattedDateTime, 'day')) return formattedDateTime.toFormat(getTimeFormat());

  if (
    DateTime.now().startOf('year') > formattedDateTime.startOf('year') ||
    DateTime.now().startOf('year') < formattedDateTime.startOf('year')
  ) {
    return formattedDateTime.toFormat(`${getShortDateFormat()}/yy`);
  }

  return formattedDateTime.toFormat(getDateFormat());
};

// converts the given datetime into a full-datetime format to be displayed in notification details
export const formatNotificationFullDateTime = (datetime: UndeterminedDate, timeZone: string) =>
  parseDateWithTimeZone(datetime, timeZone).toFormat(`${getTimeFormat()}, ${selectedDateFormat}`);

// converts the given date into date format specifically for issue dates
export const formatBillingIssueDatetime = (value = DateTime.now()) =>
  parseDate(value).toFormat('MMMM d, yyyy');

// gets the current year
export const getCurrentYear = () => DateTime.now().toFormat('yyyy');

// checks if given date-time is passed the current date-time
export const isFutureDateTime = (value: UndeterminedDate = DateTime.now()) =>
  DateTime.now().diff(parseDate(value)).as('milliseconds') < 0;

export const getForecastDateFormat = `${requestDateFormat}'T'04:30:00ZZ`;

// formats the forecast days with optional number of days to add/subtract
export const getForecastDate = (timeZone: string, days = 0) =>
  formatFuturePastDate(null, timeZone, days, getForecastDateFormat);

export const formatForecastDateFormat = `cccc ${selectedDateFormat}`;
export const formatForecastDate = (timeZone: string) =>
  parseDateWithTimeZone(DateTime.now(), timeZone).toFormat(formatForecastDateFormat);

// checks if the given datetime value is between the forecast period to display in chart
export const isBetweenForecast = (value: UndeterminedDate, timeZone: string) => {
  const format = `${requestDateFormat}'T'04:30:00ZZ`;
  const formattedVal = DateTime.fromISO(formatTimeZone(value, timeZone), {
    setZone: true,
  });
  const forecastStart = DateTime.fromFormat(getForecastDate(timeZone), format, {
    setZone: true,
  });
  const forecastEnd = DateTime.fromFormat(getForecastDate(timeZone, 1), format, { setZone: true });

  return Interval.fromDateTimes(forecastStart, forecastEnd).contains(formattedVal);
};

export const generateDefaultDateRange = (
  startDate: string,
  endDate: string,
  period: TimePeriod,
  timeZone: string,
  interval: number
) => {
  let defaults: { startDate: string; endDate: string; period?: string } = {
    startDate,
    endDate,
  };

  if (isPathBatterySharing() && (period === 'past' || !startDate || !endDate)) {
    const forecastDateFormat = `${requestDateFormat}'T'00:00:00ZZ`;

    defaults = {
      startDate: formatFuturePastDate(null, timeZone, 0, forecastDateFormat),
      endDate: formatFuturePastDate(null, timeZone, 1, forecastDateFormat),
      period: 'day',
    };
  } else if (period === 'past' || !startDate || !endDate) {
    defaults = {
      startDate: formatPast24HourDatetime(timeZone, interval, 1),
      endDate: formatPast24HourDatetime(timeZone, interval),
    };
  }

  return defaults;
};

// Get the previous four week period for TimeFrameSelector
export const getPrevFourWeekPeriod = (timezone = 'utc', hasIOAManagement = false) => {
  let now = DateTime.now();

  if (hasIOAManagement) {
    now = now.plus({ days: 1 });
  }

  const startDate = parseDateWithTimeZone(now, timezone).startOf('day').minus({ days: 27 }); // 27 since endDate counts as 1
  const endDate = parseDateWithTimeZone(now, timezone).endOf('day');
  const period = 'prevFourWeek';

  return { startDate, endDate, period };
};

// Get the previous four week period as ISO strings
export const getPrevFourWeekPeriodAsISO = (timezone = 'utc', hasIOAManagement = false) => {
  const { startDate, endDate, period } = getPrevFourWeekPeriod(timezone, hasIOAManagement);

  return {
    startDate: startDate.toISO({ includeOffset: true }),
    endDate: endDate.toISO({ includeOffset: true }),
    period,
  };
};

export const formatISOToFullDateAndTime = (value: string) => {
  return DateTime.fromISO(value).toFormat('dd-MMM-yyyy HH:mm');
};

export const formatDate = (value = DateTime.now()) => parseDate(value).toFormat('MMMM d, yyyy');

// Sorts an array of objects by datetime string
// If datetime key is not found on an object the pair will be returned in the original order presented
export const sortByDateTime = (list: Array<any>, dateTimeKey: string) => {
  const sortedList = list.sort((a, b) =>
    a[dateTimeKey] && b[dateTimeKey]
      ? new Date(a[dateTimeKey]).getTime() - new Date(b[dateTimeKey]).getTime()
      : 1
  );

  return sortedList;
};
