import { FC } from 'react';
import { Flex, Box, Slider as ThemeSlider } from 'theme-ui';

export type SliderProps = {
  children?: any;
  variant?: string;
  min?: number;
  max?: number;
  defaultValue: number;
  value: number;
  step?: number;
  onChange: any;
  marks?: any;
  active?: boolean;
};

export const Slider: FC<SliderProps> = ({
  active = true,
  marks,
  step = 1,
  onChange,
  min = 0,
  max = 100,
  defaultValue,
  value,
}) => {
  const onChangeSnap = (val: any) => {
    const newVal = Math.round(val / step) * step;
    onChange(Math.min(Math.max(newVal, min), max));
  };

  return (
    <>
      <ThemeSlider
        disabled={!active}
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        onChange={(e) => onChangeSnap(e.target.value)}
      />
      {marks && (
        <Flex
          sx={{
            alignItems: 'center',
            position: 'relative',
            mt: 3,
            width: '98%',
            transform: 'translate(1%)',
          }}
        >
          {marks.map((mark: any, idx: number) => (
            <Box
              key={mark.label}
              sx={{
                position: 'absolute',
                left: `${(100 * idx) / (marks.length - 1)}%`,
                transform: 'translateX(-50%)',
              }}
            >
              {mark.label}
            </Box>
          ))}
        </Flex>
      )}
    </>
  );
};
