import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useAuthService } from '@power-ledger/auth-service';

import { RESET_STORE } from './root-reducer';
import { store } from './redux-store';

export const ReduxStoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuthService();
  const authLoading = isLoading();
  const userAuthed = isAuthenticated();

  useEffect(() => {
    if (!authLoading && !userAuthed) {
      store.dispatch({ type: RESET_STORE });
    }
  }, [authLoading, userAuthed]);

  return <Provider store={store}>{children}</Provider>;
};
