import { CustomThemeProperty } from './types';

export const layouts = CustomThemeProperty('layouts', {
  layoutSider: {
    width: 300,
    collapsedWidth: 50,
  },
  siders: {
    bg: 'background',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    px: 0,
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'foregroundLight',
  },
  layoutHeader: {
    height: 80,
  },
  headers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    px: 4,
    bg: 'background',
    height: 80,
  },
  footers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 9,
    px: 4,
    py: 0,
    fontSize: 0,
    bg: 'background',
    width: '100%',
  },
});
