import { useBreakpointIndex } from '@theme-ui/match-media';

export const useScreenSize = () => {
  const breakpointIndex = useBreakpointIndex();

  return {
    isSmallDown: breakpointIndex < 1,
    isMediumDown: breakpointIndex < 2,
    isLargeDown: breakpointIndex >= 2,
  };
};
