import { useMemo } from 'react';
import { useStandardChartConfig, UseStandardChartConfigProps } from '.';

export const useStandardBarChartConfig = ({
  width,
  legendData,
  alwaysDisplayLegend = true,
}: UseStandardChartConfigProps) => {
  const standardConfig = useStandardChartConfig({ width, legendData, alwaysDisplayLegend });

  return useMemo(
    () => ({
      ...standardConfig,
      indexBy: 'date',
      enableLabel: false,
      padding: 0.3,
      valueScale: { type: 'linear' },
      indexScale: { type: 'band', round: false },
      borderColor: { from: 'color', modifiers: [['darker', 1.6]] },

      axisTop: null,
      axisRight: null,
      axisLeft: {
        tickSize: 1,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
      },

      gridYValues: 5,
    }),
    [standardConfig]
  );
};
