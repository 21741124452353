import { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { NewDropdownArrowSVG } from '@power-ledger/assets';
import { components as selectComponents } from 'react-select';

import { Meter } from '../../../../types';
import { useAppSelector } from '../../hooks/use-app-selector';
import { Select } from '../../components';

import { useIsAdmin } from '../../hooks/use-is-admin';
import SearchableMeterModal from '../../components/modal/SearchableMeter';
import { updateSelectedMeter } from '../../states/slices/controls';
import { useAppDispatch } from '../../hooks/use-app-dispatch';
import { useHasAllMetersOption } from '../../hooks/use-has-all-meters-option';
import { useAggregatedMeter } from '../../hooks/use-aggregated-meter';

export const MeterSelector = () => {
  const { isAdmin } = useIsAdmin();
  const meters: Meter[] = useAppSelector(({ meters: metersStore }) => metersStore.meters, shallowEqual);

  const { hasAllMeterOption } = useHasAllMetersOption();
  const aggregatedMeter = useAggregatedMeter();
  const allMeterSelectOptions = useMemo(() => {
    return [
      ...(hasAllMeterOption ? [{ value: aggregatedMeter.uid, label: aggregatedMeter.displayName }] : []),
      ...meters.map((meter) => ({ value: meter.uid, label: meter.displayName })),
    ];
  }, [hasAllMeterOption, meters, aggregatedMeter]);
  const { selectedMeter } = useAppSelector(
    ({ controls }) => ({
      period: controls.period,
      startDate: controls.startDate,
      endDate: controls.endDate,
      selectedMeter: controls.selectedMeter,
    }),
    shallowEqual
  );

  const dispatch = useAppDispatch();

  const onUpdateCurrentMeter = useCallback(
    (selectedMeterUID: string) => {
      const meter = selectedMeterUID ? meters.find(({ uid }) => uid === selectedMeterUID) : aggregatedMeter;
      dispatch(updateSelectedMeter(meter));
    },
    [aggregatedMeter, dispatch, meters]
  );

  if (isAdmin) {
    return <SearchableMeterModal />;
  }

  if (meters?.length > 1) {
    return (
      <Select
        value={allMeterSelectOptions.find(
          ({ value }) => value === (selectedMeter ? selectedMeter.uid : null)
        )}
        onChange={({ value }: { value: string }) => onUpdateCurrentMeter(value)}
        borderless
        dataTestid="meter-select"
        menuListDataTestid="meter-select-dropdown"
        sx={{
          display: 'flex',
          '& div:nth-of-type(2)': {
            width: 'max-content',
            maxWidth: [200, 320, 320, 400, 500],
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '> div > div': {
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '36px',
          minWidth: '94px',
          padding: '8px 14px',
          borderRadius: '8px',
          border: '1px solid #98A2B3',
          background: '#FFF',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        }}
        menuStyles={{
          right: 0,
          maxWidth: '300px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        singleValueStyles={{
          display: 'flex',
          fontSize: '14px',
          color: '#1D2939',
          fontWeight: 600,
          lineHeight: '20px',
        }}
        controlStyles={{
          '&:hover': {
            svg: {
              path: {
                fill: 'none',
              },
            },
          },
          svg: {
            path: {
              fill: 'none',
            },
          },
        }}
        DropdownIndicator={(props) => (
          <selectComponents.DropdownIndicator {...props}>
            <NewDropdownArrowSVG width="16px" />
          </selectComponents.DropdownIndicator>
        )}
        filterOption={(option) => (hasAllMeterOption ? true : Boolean(option.value))}
        options={allMeterSelectOptions}
      />
    );
  }

  return null;
};
