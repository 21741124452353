/** @jsxImportSource theme-ui */
import { ComponentType, FC, HTMLAttributes, useMemo } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { SizeProps } from 'styled-system';
import { pickBy } from 'lodash';
import { StandardCSSProperties } from '@styled-system/css';

export type IconWrapperProps = {
  icon: ComponentType;
  sx?: ThemeUIStyleObject;
  bg?: StandardCSSProperties['backgroundColor'];
  color?: StandardCSSProperties['color'];
  stroke?: StandardCSSProperties['color'];
  variant?: string;
  width?: number;
  height?: number;
  long?: boolean;
  noSvgPositioning?: boolean;
} & SizeProps &
  HTMLAttributes<'div'>;

export const longSVGStyles = {
  maxWidth: '75%',
  maxHeight: '50%',
};

export type IconProps = Omit<IconWrapperProps, 'icon'>;

const zeroSafeIdentity = (a: any) => a !== undefined;
const stripUndefined = (styleOBj: ThemeUIStyleObject): ThemeUIStyleObject =>
  pickBy(styleOBj, zeroSafeIdentity);

export const createIconStyles = (
  { variant, color, stroke, size, width, height, bg, sx, noSvgPositioning }: IconProps,
  long?: boolean
) => {
  const outlined = variant === 'outlined';
  return stripUndefined({
    variant: outlined ? 'icons.outlined' : 'icons',
    size,
    width,
    height,
    color,
    stroke,
    bg,
    borderColor: color,
    ...stripUndefined({
      svg: stripUndefined({
        variant: noSvgPositioning ? '' : outlined ? 'icons.outlined.svg' : 'icons.svg',
        fill: color,
        stroke: stroke,
        ...stripUndefined({
          path: stripUndefined({
            variant: outlined ? 'icons.outlined.svg.path' : 'icons.svg.path',
            fill: color,
            stroke: stroke,
          }),
        }),
        ...(long ? longSVGStyles : {}),
      }),
    }),
    ...sx,
  });
};

export const IconWrapper: FC<IconWrapperProps> = ({
  icon: Icon,
  color,
  bg,
  sx = {},
  stroke,
  size,
  width,
  height,
  variant,
  long,
  noSvgPositioning,
  ...props
}) => {
  const boxSx = useMemo(
    () => createIconStyles({ bg, color, stroke, size, sx, variant, width, height, noSvgPositioning }, long),
    [bg, color, stroke, size, width, height, sx, variant, long, noSvgPositioning]
  );

  return (
    <Box sx={boxSx} {...(props as any)}>
      <Icon />
    </Box>
  );
};
