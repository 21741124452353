import { Box, StylePropertyValue, ThemeUIStyleObject } from 'theme-ui';
import { Skeleton } from '../Skeleton';

import { ItemValue } from '..';

export interface StatisticProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  suffix?: React.ReactNode;
  type?: string;
  symbol?: React.ReactNode;
  contentStyle?: ThemeUIStyleObject;
  className?: string;
  sx?: ThemeUIStyleObject;
  numberType?: string;
  width?: StylePropertyValue<'width'>;
  loading?: boolean;
  dataTestid?: string;
}

export const Statistic: React.FC<StatisticProps> = ({
  suffix,
  width,
  loading = false,
  dataTestid,
  ...rest
}) => {
  return (
    <Box
      className={rest?.className}
      sx={{ width: width ?? '100%', mb: [3, 4], ...rest?.sx }}
      data-testid={dataTestid}
    >
      {rest.header && (
        <Box sx={{ variant: 'statistic.header' }} data-testid="label">
          {rest.header}
        </Box>
      )}
      <Box sx={{ variant: 'statistic.content', ...rest.contentStyle }} data-testid="value">
        {loading ? (
          <Skeleton width="50%" />
        ) : (
          <ItemValue value={rest.children as string | number | null} {...rest} />
        )}
      </Box>
      {suffix &&
        (loading ? (
          <Skeleton width="50%" />
        ) : (
          <Box sx={{ variant: 'statistic.suffix' }} data-testid="suffix">
            {suffix}
          </Box>
        ))}
    </Box>
  );
};
