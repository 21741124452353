import { FC } from 'react';
import { IconProps, IconWrapper } from './common';

export const Next: FC<IconProps> = (props) => (
  <IconWrapper
    icon={() => (
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.53333 15.0667L0.666667 14.2L6.86667 8L0.666667 1.8L1.53333 0.9L8.6 8L1.53333 15.0667Z"
          fill="#27D9E5"
        />
      </svg>
    )}
    {...props}
    long
  />
);
