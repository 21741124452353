import { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Label, Paragraph, Spinner } from 'theme-ui';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { genericKeyHandler } from '@power-ledger/react';
import { useTranslation } from 'react-i18next';

import { updatePassword } from '../../states/slices/auth';
import { isSuccess as statusCodeSuccessful } from '../../lib/notifications';
import { registerUserEvent } from '../../lib/auth-helpers';
import { Input } from '../Input';
import { LockOutlined } from '../icons';
import { TogglingEye } from '../TogglingEye';
import { defaultText, ResetPasswordText } from '../../layouts/public/reset-password/defaultText';
import { ErrorMessage } from './ErrorMessage';
import { Form } from './Form';

export const ResetPasswordForm = ({
  onSubmit,
  resetPasswordText,
}: {
  onSubmit?: () => void;
  resetPasswordText?: ResetPasswordText;
}) => {
  const { t } = useTranslation();
  const [passwordText, setPasswordText] = useState(defaultText(t));
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const { token } = useParams<{ token: string }>();

  const togglePassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);
  const togglePasswordConfirm = useCallback(
    () => setShowPasswordConfirm(!showPasswordConfirm),
    [showPasswordConfirm]
  );

  useLayoutEffect(() => {
    if (resetPasswordText) {
      setPasswordText(resetPasswordText);
    }
  }, [resetPasswordText]);

  return (
    <Formik
      initialValues={{
        newPassword: '',
        passwordConfirmation: '',
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .required(t('New password is required!'))
          .min(8, t('Password must contain at least 8 characters'))
          .matches(/^.*[A-Z]+.*$/, t('Password must include at least one uppercase letter'))
          .matches(/^.*[a-z]+.*$/, t('Password must include at least one lowercase letter'))
          .matches(/^.*[0-9]+.*$/, t('Password must include at least one number')),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], t('Passwords must match')),
      })}
      onSubmit={async ({ newPassword }, { setSubmitting }) => {
        registerUserEvent('reset password', 'data', token);
        const request = { resetUuid: token, newPassword };
        const {
          payload: { status },
        } = (await dispatch(updatePassword(request))) as any;
        setSubmitting(false);
        if (statusCodeSuccessful(status) && onSubmit) {
          onSubmit();
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            <Label>
              {t('Password')}
              <Input
                prefix={<LockOutlined color="mutedDark" size={5} />}
                suffix={
                  <TogglingEye
                    color="mutedDark"
                    size={5}
                    hidden={!showPassword}
                    onClick={togglePassword}
                    onKeyPress={(e: any) => genericKeyHandler(e, 'Space', togglePassword)}
                  />
                }
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                disabled={isSubmitting}
              />
            </Label>
            <ErrorMessage name="newPassword" />
          </Form.Item>
          <Form.Item>
            <Label>
              {t('Confirm Password')}
              <Input
                prefix={<LockOutlined color="mutedDark" size={5} />}
                suffix={
                  <TogglingEye
                    color="mutedDark"
                    size={5}
                    hidden={!showPasswordConfirm}
                    onClick={togglePasswordConfirm}
                  />
                }
                type={showPasswordConfirm ? 'text' : 'password'}
                name="passwordConfirmation"
                disabled={isSubmitting}
              />
            </Label>
            <ErrorMessage name="passwordConfirmation" />
          </Form.Item>
          <Paragraph as="small" variant="small" sx={{ marginBottom: 2 }}>
            {passwordText.description}
          </Paragraph>
          <Button type="submit" sx={{ marginTop: 4 }} disabled={isSubmitting}>
            {isSubmitting ? <Spinner size={24} /> : t('Confirm new password')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
