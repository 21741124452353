import { WeightUnitToLongNameMapping } from '../../../types';

export const WEIGHT_UNIT_TO_LONG_NAME_MAPPING: WeightUnitToLongNameMapping = {
  mg: 'milligrams',
  g: 'grams',
  kg: 'kilograms',
  t: 'metric tonnes',
  kt: 'kilotonnes',
  Mt: 'megatonnes',
  Gt: 'gigatonnes',
  Tt: 'teratonnes',
  Pt: 'petatonnes',
};
