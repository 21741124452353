import { Flex } from 'theme-ui';

export const TablePlaceholder: React.FC<{
  text: React.ReactNode;
  numColumns: number;
  loading?: boolean;
}> = ({ text, loading = false, numColumns }) => (
  <tr>
    <td colSpan={numColumns} className="placeholder">
      <Flex
        sx={{
          variant: 'tables.placeholder',
        }}
      >
        {loading ? null : text}
      </Flex>
    </td>
  </tr>
);
