import { useCallback, useMemo } from 'react';
import { AuthCallbackHandler } from '../adaptor-types';
import { OktaAdaptorTypes } from './okta-adaptor-types';

export const useAuthCallbackHandler: AuthCallbackHandler<OktaAdaptorTypes> = (client) => {
  if (!client) {
    throw new Error('Missing Okta Client');
  }

  const oktaAuth = client.okta;

  const loginCallback = useCallback(async () => {
    await oktaAuth.handleLoginRedirect();
  }, [oktaAuth]);

  const logoutCallback = useCallback(async () => {
    // Nothing is done here intentionally
  }, []);

  return useMemo(() => ({ loginCallback, logoutCallback }), [loginCallback, logoutCallback]);
};
