import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';

import { CO2Saving, EnergyType, EnergyMixStatisticsTotal } from '../../../../types';

export const getAverageCarbonFreeEnergyPercentage = (
  co2Savings: CO2Saving[],
  energyMixTotals: EnergyMixStatisticsTotal[]
) => {
  if (co2Savings.length === 0) return 0;

  const data = co2Savings.map((co2Saving) => {
    const foundEnergyMixTotal = energyMixTotals.find((energyMixTotal) => energyMixTotal.id === co2Saving.id);

    if (!foundEnergyMixTotal) return { co2Emission: 0, consumedEnergy: 0 };

    return {
      co2Emission: (co2Saving.base - co2Saving.grams) / 1000,
      consumedEnergy: foundEnergyMixTotal.kwh,
    };
  });

  return (
    (1 - sumBy(data, (saving) => saving.co2Emission) / sumBy(data, (saving) => saving.consumedEnergy)) * 100
  );
};

export const isEnergyTypeRenewable = (energyType: EnergyType) => {
  return [EnergyType.SOLAR_PV, EnergyType.WIND, EnergyType.HYDRO, EnergyType.BIOMASS].includes(energyType);
};

export const getRenewableConsumptionPercentage = (energyMixTotals: EnergyMixStatisticsTotal[]) => {
  const renewableEnergyConsumption = sum(
    energyMixTotals.filter((total) => isEnergyTypeRenewable(total.id)).map((total) => total.kwh)
  );
  const totalConsumption = sum(energyMixTotals.map((total) => total.kwh));

  if (totalConsumption === 0) return 0;

  return (renewableEnergyConsumption / totalConsumption) * 100;
};
