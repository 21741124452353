import { Children, cloneElement, FC, FormEvent, isValidElement } from 'react';
import { Box, BoxProps, Flex, FlexProps } from 'theme-ui';
import { useFormikContext } from 'formik';

const Item: FC<FlexProps> = ({ children, sx, ...props }) => (
  <Flex sx={{ flexDirection: 'column', my: 4, ...sx }} {...props}>
    {children}
  </Flex>
);

type FormBoxProps = Omit<BoxProps, 'onSubmit'> & {
  onSubmit?: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

type FormType = FC<FormBoxProps> & {
  Item: typeof Item;
};

export const Form: FormType = ({ children, onSubmit, sx = {}, ...props }) => {
  const { handleReset, handleSubmit } = useFormikContext();
  return (
    <Box
      as="form"
      onReset={handleReset}
      onSubmit={handleSubmit as any}
      sx={{
        width: '100%',
        flexDirection: 'column',
        ...sx,
      }}
      {...props}
    >
      {Children.map(children, (child: any) =>
        isValidElement(child) ? cloneElement(child, { style: { width: '100%' } } as any) : null
      )}
    </Box>
  );
};

Form.Item = Item;
