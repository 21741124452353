import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ROW_Y_POSITION_MAPPING = [{ mark: -40 }, { mark: -60 }, { mark: -80 }, { mark: -100 }];

const isLegendRow = (gElement: SVGGElement) => {
  return gElement.getAttribute('transform')?.includes('-');
};

const getNthLegendRowElement = (rowContainerGElement: SVGElement, rowIndex = 0) => {
  let firstLegendRowElementIndex: number | undefined = undefined;

  rowContainerGElement.childNodes.forEach((childNode, index) => {
    const child = childNode as SVGGElement;

    if (typeof firstLegendRowElementIndex === 'undefined' && isLegendRow(child))
      firstLegendRowElementIndex = index;
  });

  return rowContainerGElement.children[(firstLegendRowElementIndex ?? 2) + rowIndex];
};

const getNumberOfRows = (rowContainerGElement: SVGGElement) => {
  const firstRow = getNthLegendRowElement(rowContainerGElement, 0);

  const foundIndex = ROW_Y_POSITION_MAPPING.findIndex((mapping) =>
    firstRow?.getAttribute('transform')?.includes(String(mapping.mark))
  );

  return (foundIndex || 0) + 1;
};

const addShortDescriptionToLegend = (
  legendRow: SVGGElement,
  legendTexts: React.MutableRefObject<string[]>,
  t: (text: string) => string,
  currentLegendIndex = 0
) => {
  for (let legendIndex = 0; legendIndex < legendRow.children.length; legendIndex++) {
    const legendElement = legendRow.children[legendIndex];

    const textElement = legendElement.querySelector('text');

    if (!textElement) continue;

    if (!legendTexts.current[currentLegendIndex + legendIndex])
      legendTexts.current[currentLegendIndex + legendIndex] = textElement.innerHTML;

    textElement.innerHTML = `<tspan x="20">${
      legendTexts.current[currentLegendIndex + legendIndex]
    }</tspan><tspan style="font-weight: 500; font-size: 10px;" x="20" dy="12">(${
      (currentLegendIndex + legendIndex) % 2 ? t('Area graph') : t('Scatter graph')
    })</tspan>`;
  }
};

export const useCustomizedLegend = (
  ref: React.RefObject<HTMLDivElement>,
  displayLegendShortDescription = false
) => {
  const { t } = useTranslation();
  const legendTexts = useRef<string[]>([]);

  useEffect(() => {
    if (!ref.current || !displayLegendShortDescription) return;

    const mutationObserverCallback = (mutations: MutationRecord[]) => {
      for (const mutation of mutations) {
        if (mutation.type !== 'childList' || mutation.target.nodeName !== 'g') continue;

        const rowContainerGElement = mutation.target as SVGGElement;

        if (isLegendRow(rowContainerGElement)) continue;

        const numberOfRows = getNumberOfRows(rowContainerGElement);

        switch (numberOfRows) {
          case 1: {
            const firstLegendRow = getNthLegendRowElement(rowContainerGElement, 0) as SVGGElement;

            addShortDescriptionToLegend(firstLegendRow, legendTexts, t);

            break;
          }
          case 2: {
            const firstLegendRow = getNthLegendRowElement(rowContainerGElement, 0) as SVGGElement;

            addShortDescriptionToLegend(firstLegendRow, legendTexts, t);

            const secondLegendRow = getNthLegendRowElement(rowContainerGElement, 1) as SVGGElement;

            secondLegendRow.setAttribute('transform', 'translate(0, -32)');

            addShortDescriptionToLegend(secondLegendRow, legendTexts, t, firstLegendRow.children.length);

            break;
          }
          case 4: {
            const firstLegendRow = getNthLegendRowElement(rowContainerGElement, 0) as SVGGElement;

            firstLegendRow.setAttribute('transform', 'translate(0, -104)');

            addShortDescriptionToLegend(firstLegendRow, legendTexts, t);

            const secondLegendRow = getNthLegendRowElement(rowContainerGElement, 1) as SVGGElement;

            secondLegendRow.setAttribute('transform', 'translate(0, -78)');

            addShortDescriptionToLegend(secondLegendRow, legendTexts, t, 1);

            const thirdLegendRow = getNthLegendRowElement(rowContainerGElement, 2) as SVGGElement;

            thirdLegendRow.setAttribute('transform', 'translate(0, -54)');

            addShortDescriptionToLegend(thirdLegendRow, legendTexts, t, 2);

            const fourthLegendRow = getNthLegendRowElement(rowContainerGElement, 3) as SVGGElement;

            fourthLegendRow.setAttribute('transform', 'translate(0, -30)');

            addShortDescriptionToLegend(fourthLegendRow, legendTexts, t, 3);
          }
        }
      }
    };

    const observer = new MutationObserver(mutationObserverCallback);

    observer.observe(ref.current, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [ref, displayLegendShortDescription, t]);
};
