import { Theme } from 'theme-ui';

export const links: Theme['links'] = {
  primary: {
    py: 2,
    px: 3,
    borderRadius: 6,
    fontSize: 1,
    color: 'primaryDarkest',
    bg: 'white',
    transition: 'base',
  },
  simple: {
    color: 'primary',
    textDecoration: 'none',
    transition: 'base',
    lineHeight: '28px',
    '&:hover': {
      opacity: 0.85,
    },
  },
  simpleWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: 'primary',
    textDecoration: 'none',
    transition: 'base',
    lineHeight: '28px',
    '&:hover': {
      opacity: 0.85,
    },
  },
};
