import { useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';

import { useHostConfig } from '../../contexts/host-config-context';
import { useAppSelector } from '../../hooks/use-app-selector';
import { extendedDateTime, parseDateWithTimeZone } from '../../lib/datetime-helpers';
import { MeterGroup } from '../../../../types';
import { useFeatureSet } from '../../hooks/use-feature-set';

// NOTE: This is sadly a hack for host specific code... For just $1 a day you can stop hacks like these.
// Please, join us on the journey towards 0 implementation of host specific code. Say no to isVB!
const useHostSpecificFormat = (timezone: string) => {
  const {
    tradingGroup: { ref },
  } = useHostConfig(true);

  return useMemo(() => {
    switch (ref.toLocaleLowerCase()) {
      case 'bcpg':
      case 't77-bcpg':
      case 'ril-bcpg':
        return timezone.replace('+07', 'ICT');
      case 'seda':
        return timezone.replace('+08', 'MYT');
      default:
        return timezone;
    }
  }, [timezone, ref]);
};

export const useHostSpecificTimezone = () => {
  const [currentTime, setCurrentTime] = useState(DateTime.now());
  const { timeZone } = useAppSelector(
    ({ meters: metersState }) => metersState.meterGroup as unknown as MeterGroup,
    shallowEqual
  );
  const { useEUTimeFormat } = useFeatureSet();

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(DateTime.now()), 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTimezone = timeZone
    ? parseDateWithTimeZone(currentTime, timeZone).toFormat(extendedDateTime(useEUTimeFormat))
    : '';

  /**
   * Not even moment provide timezone abbreviation for all the timezones.
   * For timezones whose abbreviation is not in moment, moment simply returns
   * the timezone offset which is not alphabetic, so falling back to original timezone
   *
   * Ref: https://momentjs.com/timezone/docs/#/using-timezones/formatting/
   */
  const timeZoneAbbreviation = /^[a-zA-Z]+$/.test(moment.tz(timeZone).zoneAbbr())
    ? moment.tz(timeZone).zoneAbbr()
    : timeZone;

  const hostSpecificTimezone = useHostSpecificFormat(formattedTimezone);

  return { hostSpecificTimezone, timeZoneAbbreviation };
};
