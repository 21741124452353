import {
  LogoBscSVGLazy,
  LogoCleantechSVGLazy,
  LogoCurtinSVGLazy,
  LogoIcmSVGLazy,
  LogoSynergySVGLazy,
  LogoWesternPowerSVGLazy,
} from '@power-ledger/assets';
import { Link, Paragraph } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { Footer as LayoutFooter } from '../components';
import Faq from '../components/modal/Faq';
import PrivacyPolicy from '../components/modal/PrivacyPolicy';
import TermsAndConditions from '../components/modal/TermsAndConditions';
import { useHostSpecificFn } from '../hooks/use-host-specific-fn';
import { getCurrentYear } from '../lib/datetime-helpers';
import { useHostConfig } from '../contexts/host-config-context';
import { useFeatureSet } from '../hooks/use-feature-set';

export interface FooterProps {
  hasFAQ?: boolean;
}

const Footer: React.FC<FooterProps> = ({ hasFAQ = false }) => {
  const { t } = useTranslation();
  const { isHostRenewNexus, isHostWongan } = useHostSpecificFn();
  const hostConfig = useHostConfig();
  const { termsOfUseAccepted } = useFeatureSet();
  const hideCopyright = hostConfig?.hostHasFlag('hidePowerledgerLogo');

  return (
    <LayoutFooter role="contentinfo">
      <Paragraph>
        {!hideCopyright &&
          t('© {{currentYear}} Powerledger. All rights reserved.', {
            currentYear: getCurrentYear(),
          })}
      </Paragraph>
      <LayoutFooter.Content>
        {hasFAQ && <Faq />}
        {!termsOfUseAccepted && <TermsAndConditions />}
        <PrivacyPolicy />
      </LayoutFooter.Content>
      {isHostRenewNexus() && (
        <div>
          <Link href="https://www.curtin.edu.au" target="_blank" rel="noreferrer">
            <LogoCurtinSVGLazy width="180px" height="30px" />
            {t('Curtin University')}
          </Link>
          <Link href="https://www.synergy.net.au" target="_blank" rel="noreferrer">
            <LogoSynergySVGLazy width="100px" height="40px" />
            {t('Synergy')}
          </Link>
          <Link href="https://westernpower.com.au" target="_blank" rel="noreferrer">
            <LogoWesternPowerSVGLazy width="150px" height="30px" />
            {t('Western Power')}
          </Link>
        </div>
      )}
      {isHostWongan() && (
        <div>
          <Link href="https://www.bscsolar.com.au" target="_blank" rel="noreferrer">
            <LogoBscSVGLazy width="175px" height="50px" />
            {t('BSC Energy')}
          </Link>
          <Link href="https://www.facebook.com/ICMWAI/" target="_blank" rel="noreferrer">
            <LogoIcmSVGLazy width="110px" height="50px" />
            {t('ICM WA')}
          </Link>
          <Link href="https://www.cleantechenergy.com.au" target="_blank" rel="noreferrer">
            <LogoCleantechSVGLazy width="120px" height="40px" />
            {t('Cleantech')}
          </Link>
        </div>
      )}
    </LayoutFooter>
  );
};

export default Footer;
