import React from 'react';
import PropTypes from 'prop-types';

const NotificationError = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 30 31" fill="none">
    <g filter="url(#filter0_d)">
      <path
        d="M15 30C6.71631 30 -5.87076e-07 23.2846 -1.31134e-06 15C-2.03561e-06 6.71537 6.71631
      -5.87158e-07 15 -1.31134e-06C23.2855 -2.03568e-06 30 6.71537 30 15C30 23.2846 23.2855 30 15
      30ZM15 2.34375C8.01088 2.34375 2.34375 8.00994 2.34375 15C2.34375 21.9901 8.01088 27.6562 15
      27.6562C21.9909 27.6562 27.6562 21.9901 27.6562 15C27.6562 8.00994 21.9909 2.34375 15
      2.34375Z"
        fill="white"
      />
      <path
        d="M16.3403 7.5L16.3403 17.9255L13.6364 17.9255L13.6364 7.5L16.3403 7.5ZM13.3334
      20.9681C13.3334 20.5567 13.4965 20.2021 13.8229 19.9043C14.1337 19.6064 14.5299 19.4574
      15.0117 19.4574C15.4623 19.4574 15.8508 19.6064 16.1772 19.9043C16.5035 20.2021 16.6667
      20.5567 16.6667 20.9681C16.6667 21.3936 16.5035 21.7553 16.1772 22.0532C15.8508 22.3511
      15.4623 22.5 15.0117 22.5C14.5299 22.5 14.1337 22.344 13.8229 22.0319C13.4965 21.734 13.3334
      21.3794 13.3334 20.9681Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="30"
        height="31"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
        127 0"
        />
        <feOffset dy="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

NotificationError.defaultProps = {
  width: '30px',
  height: '30px',
};

NotificationError.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default NotificationError;
