import { CustomThemeProperty } from './types';

export const radioButtons = CustomThemeProperty('radioButtons', {
  height: 7,
  alignItems: 'center',
  borderColor: 'backgroundLight',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
  button: {
    flexGrow: 1,
    fontSize: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    px: 3,
    color: 'white',
    fontWeight: 'light',
    cursor: 'pointer',
    borderRadius: 4,
    transition: 'base',
  },
});
