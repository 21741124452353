import { DateTime } from 'luxon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IOAReadingsType, RequestToken } from '../../../../types';
import { errorNotification, successNotification } from '../../lib/notifications';
import { isResponseSuccessful } from '../../middleware/api-helpers';
import i18n from '../../lib/i18n';
import * as ioaApi from '../../middleware/ioa-management';
import { downloadFile } from '../../lib/helpers';

interface UploadReadingsOptions {
  file: File;
  token: RequestToken;
  settlementDate: DateTime;
}

const generateErrorNotificationOptions = (code: number) => {
  let message: string | null = null;

  switch (code) {
    case 400:
      message = 'Your request is not valid!';
      break;
    case 403:
      message = 'You do not have permissions to perform this action!';
      break;
  }

  return {
    code,
    errorCode: code,
    ...(message ? { message } : {}),
  };
};

export const uploadDayAheadReadings = createAsyncThunk(
  'ioaManagement/uploadDayAheadReadings',
  async ({ file, token, settlementDate }: UploadReadingsOptions, { rejectWithValue }) => {
    const response = await ioaApi.uploadDayAheadReadings(file, token, settlementDate);

    if (isResponseSuccessful(response)) {
      successNotification({
        message: i18n.t('Your day ahead readings file has been successfully uploaded.'),
      });

      return true;
    }

    errorNotification(generateErrorNotificationOptions(response.status));

    return rejectWithValue(false);
  }
);

export const uploadRealTimeReadings = createAsyncThunk(
  'ioaManagement/uploadRealTimeReadings',
  async ({ file, token, settlementDate }: UploadReadingsOptions, { rejectWithValue }) => {
    const response = await ioaApi.uploadRealTimeReadings(file, token, settlementDate);

    if (isResponseSuccessful(response)) {
      successNotification({
        message: i18n.t('Your real-time readings file has been successfully uploaded.'),
      });

      return true;
    }

    errorNotification(generateErrorNotificationOptions(response.status));

    return rejectWithValue(false);
  }
);

export const downloadReadingTemplates = createAsyncThunk(
  'ioaManagement/downloadReadingsTemplates',
  async (
    { settlementDate, readingsType }: { settlementDate: DateTime; readingsType: IOAReadingsType },
    { rejectWithValue }
  ) => {
    const response = await ioaApi.downloadReadingTemplates(settlementDate, readingsType);

    if (isResponseSuccessful(response)) {
      downloadFile(response.body, `${readingsType.toLowerCase()}_${settlementDate.toFormat('yyyyMMdd')}`);

      return true;
    }

    errorNotification(generateErrorNotificationOptions(response.status));

    return rejectWithValue(false);
  }
);
