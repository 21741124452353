import { memo } from 'react';
import { Flex } from 'theme-ui';

import { LoadingOverlay } from '../../../components';
import { AdminRouter, UserRouter } from '../../routes';
import { RouterContainerProps } from './router-container.types';
import { NoMeterPlaceHolder } from './no-meter-placeholder';

const RouterContainerBase: React.FC<RouterContainerProps> = ({
  isLoading,
  isAdmin,
  loadingText,
  displayNoMeterPlaceholder = false,
}) => {
  if (isLoading) return <LoadingOverlay active text={loadingText} />;

  if (displayNoMeterPlaceholder) {
    return (
      <Flex sx={{ alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <NoMeterPlaceHolder />
      </Flex>
    );
  }

  if (isAdmin) return <AdminRouter />;

  return <UserRouter />;
};

export const RouterContainer = memo(RouterContainerBase);
