import { Flex } from 'theme-ui';
import { Link } from 'react-router-dom';

import { Badge } from '../../components';

import { useUnreadNotifications } from '../../hooks/use-unread-notifications';

import { NotificationIcon } from '../../components/icons/app-bar/Notification';

export const NotificationInfo: React.FC<{ navigate: () => void }> = ({ navigate }) => {
  const { numberOfUnread } = useUnreadNotifications();

  return (
    <Badge bg="warning" count={numberOfUnread} countOverflow={9}>
      <Link tabIndex={-1} to="/notifications">
        <Flex
          onClick={navigate}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: 'newUIPrimary',
            padding: '10px',
            width: '40px',
            height: '40px',
            '&:hover': {
              bg: 'newUIPrimaryLighter',
              borderRadius: '8px',
            },
          }}
        >
          <NotificationIcon size={7} />
        </Flex>
      </Link>
    </Badge>
  );
};
