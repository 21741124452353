import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { currentTheme } from '../../styles/currentTheme';
import { ErrorNotificationParams, NotificationExportType, NotificationParams } from './notifications.types';

const createToastArgs = ({
  message,
  description,
  duration,
  bgColor,
}: NotificationParams): { content: ToastContent; options: ToastOptions } => {
  const options: ToastOptions = {
    style: {
      fontWeight: 300,
      ...(bgColor ? { backgroundColor: bgColor } : {}),
    },
    theme: 'colored',
  };

  if (duration) options.autoClose = duration * 1000;

  const content = `${message || ''}${description ? `\n${description}` : ''}`;

  options.toastId = content;

  return {
    content,
    options,
  };
};

export const notification: NotificationExportType = {
  success: (params) => {
    const { content, options } = createToastArgs({
      ...params,
      bgColor: currentTheme?.colors?.successToastMessage as string,
    });
    toast.success(content, options);
  },
  info: (params) => {
    const { content, options } = createToastArgs(params);
    toast.info(content, options);
  },
  error: (params: ErrorNotificationParams) => {
    const { content, options } = createToastArgs({
      ...params,
      bgColor: currentTheme?.colors?.errorToastMessage as string,
    });
    toast.error(content, options);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  destroy: () => {
    toast.dismiss();
  },
};
