import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';

import { getWeatherIcon } from '../../lib/weather-helpers';
import { WeatherItemProps, WeatherWidgetViewProps } from './weather-widget.types';
import { shouldDisplayWeatherItem } from './weather-widget.utils';

const WeatherItem = ({ weather, sx, first = false, last = false }: WeatherItemProps) => {
  const { t } = useTranslation();

  return (
    <Box
      key={weather.day}
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...(!first ? { paddingLeft: [3, 3, 3, 3, 4] } : {}),
        ...sx,
      }}
    >
      <Text sx={{ fontWeight: 'body' }}>{weather.day}</Text>
      {getWeatherIcon(weather.icon)}
      <Text sx={{ fontWeight: 'light' }}>{t('{{temperature}}°', { temperature: weather.temperature })}</Text>
      {!last && (
        <Box
          sx={{
            marginLeft: [3, 3, 3, 3, 4],
            height: 4,
            borderRadius: 4,
            width: '2px',
            backgroundColor: 'greyDarker',
          }}
        />
      )}
    </Box>
  );
};

const WeatherWidgetViewBase = ({ current, daily, sx }: WeatherWidgetViewProps) => {
  const { t } = useTranslation();
  const breakpointIndex = useBreakpointIndex();
  const numberOfDisplayedDailyItems = useMemo(
    () =>
      daily.reduce(
        (numberOfItems, currentItem, currentIndex) =>
          shouldDisplayWeatherItem(breakpointIndex, currentIndex) ? numberOfItems + 1 : numberOfItems,
        0
      ),
    [daily, breakpointIndex]
  );

  const dailyIcons = useMemo(
    () =>
      daily.map((weather, index) => (
        <WeatherItem
          key={weather.day}
          weather={weather}
          last={index === numberOfDisplayedDailyItems - 1}
          sx={{
            display: shouldDisplayWeatherItem(breakpointIndex, index) ? 'flex' : 'none',
          }}
        />
      )),
    [daily, breakpointIndex, numberOfDisplayedDailyItems]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mr: 2,
        ...sx,
      }}
    >
      {current && current.icon && current.temperature && (
        <WeatherItem weather={{ ...current, day: t('Now') }} first last={numberOfDisplayedDailyItems === 0} />
      )}
      {dailyIcons}
    </Box>
  );
};

export const WeatherWidgetView = memo(WeatherWidgetViewBase);
