import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../../../hooks/use-app-selector';
import { useTimeFrameDateData } from '../hooks';

export const useCommunityUsageData = () => {
  const { timeZone } = useTimeFrameDateData();
  const meterTrades = useAppSelector(({ dashboard }) => dashboard.meterTrades, shallowEqual);
  const lastMeterTrades = useAppSelector(({ dashboard }) => dashboard.lastMeterTrades, shallowEqual);
  const groupTrades = useAppSelector(({ dashboard }) => dashboard.groupTrades, shallowEqual);
  const lastGroupTrades = useAppSelector(({ dashboard }) => dashboard.lastGroupTrades, shallowEqual);
  const { billingStart, billingEnd } = useAppSelector(
    ({ dashboard }) => ({
      billingStart: dashboard.billingStart,
      billingEnd: dashboard.billingEnd,
    }),
    shallowEqual
  );

  return {
    timeZone,
    meterTrades,
    lastMeterTrades,
    groupTrades,
    lastGroupTrades,
    startDate: billingStart,
    endDate: billingEnd,
  };
};
