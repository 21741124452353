import { FC, useMemo } from 'react';
import { CountryRegionData } from 'react-country-region-selector';

import { Select } from '../..';
import { RegionSelectProps } from './RegionSelect.types';

export const RegionSelect: FC<RegionSelectProps> = ({
  disabled,
  placeholder,
  countryValue,
  inputValue,
  onChange,
  onBlur,
}) => {
  const regionOptions = useMemo(() => {
    if (!countryValue) return [];
    const countryData = CountryRegionData.find((country) => country[1] === countryValue);

    const regions = countryData?.[2];

    if (!regions) return [];

    return regions.split('|').map((region) => {
      const regionName = region.split('~')[0];
      const regionCode = region.split('~')[1];
      return { label: regionName, value: regionCode };
    });
  }, [countryValue]);

  return (
    <Select
      value={regionOptions.find((r) => r.value === inputValue) || ''}
      options={regionOptions}
      disabled={!countryValue || disabled}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default RegionSelect;
