import { memo } from 'react';
import { CurrencyItem, EnergyItem, PercentItem, PlainTextItem, TextItem, WeightItem } from './items';
import { ItemValueProps } from './item-value.types';

const ItemValueBase: React.FC<ItemValueProps> = ({ value, type, ...props }) => {
  if (value === null) return <span>-</span>;

  let compType = null;

  switch (type) {
    case 'plaintext':
      compType = <PlainTextItem value={value} {...props} />;
      break;
    case 'energy':
      compType = <EnergyItem value={value as number} {...props} />;
      break;
    case 'weight':
      compType = <WeightItem value={value as number} {...props} />;
      break;
    case 'percent':
      compType = <PercentItem value={value as number} {...props} />;
      break;
    case 'text':
      compType = <TextItem value={value} {...props} />;
      break;
    case 'currency':
      compType = <CurrencyItem value={value as number} {...props} />;
      break;
    default:
      compType = <CurrencyItem value={value as number} {...props} />;
  }
  return compType;
};

export const ItemValue = memo(ItemValueBase);
