import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Spinner, Text, ThemeUIStyleObject } from 'theme-ui';

import { Portal } from '../portal';
import { useTheme } from '../../../hooks/use-theme';

export interface ModalProps {
  open?: boolean;
  header?: React.ReactNode | string;
  description?: string;
  width?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
  headerIcon?: React.ReactNode;
  footer?: React.ReactNode;
  isSubmitting?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  disableSubmitBtn?: boolean;
  sx?: ThemeUIStyleObject;
}

const ModalBase: React.FC<ModalProps> = ({
  open = false,
  header,
  description,
  width,
  onClose,
  onSubmit,
  isSubmitting,
  headerIcon,
  children,
  footer,
  confirmBtnText,
  cancelBtnText,
  disableSubmitBtn = false,
  sx,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const modalMargin = 6;

  const marginPixelValue = useMemo(() => {
    if (!theme.space) return 0;

    const spaceValue = theme.space[modalMargin];

    if (typeof spaceValue !== 'number') return 0;

    return spaceValue;
  }, [theme.space]);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [open]);

  if (!open) return null;

  return (
    <Portal targetElementId="transactive-web-app-portal">
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          zIndex: 30,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#000000',
            opacity: 0.4,
            width: '100%',
            position: 'absolute',
            height: '100%',
          }}
          onClick={onClose}
        />
        <Flex
          sx={{
            padding: '24px',
            flexDirection: 'column',
            backgroundColor: 'white',
            width: width || 'fit-content',
            height: 'fit-content',
            zIndex: 40,
            borderRadius: 8,
            overflow: 'hidden',
            maxHeight: `calc(100vh - ${marginPixelValue * 2}px)`,
            maxWidth: ['90vw', null, '50vw'],
            gap: 2,
            ...sx,
          }}
        >
          {typeof header !== 'string' ? (
            header
          ) : (
            <Flex sx={{ flexDirection: 'column', gap: 2 }}>
              <Flex>
                <Box>{headerIcon && headerIcon}</Box>
                {typeof header === 'string' ? (
                  <Text
                    sx={{
                      color: 'gray.900',
                      fontWeight: 600,
                      fontSize: '1.125rem',
                      lineHeight: '1.75rem',
                    }}
                  >
                    {header}
                  </Text>
                ) : null}
              </Flex>
              {description && (
                <Text
                  sx={{
                    color: 'gray.500',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                  }}
                >
                  {description}
                </Text>
              )}
            </Flex>
          )}

          <Box sx={{ flexGrow: 1, paddingTop: 3, overflowY: 'scroll' }}>{children}</Box>

          {footer ?? (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                gap: 3,
                flex: 1,
              }}
            >
              {onClose && (
                <Button
                  onClick={onClose}
                  variant="newUI.outline"
                  disabled={isSubmitting}
                  data-testid="modal-cancel-button"
                  sx={{
                    bg: 'gray.100',
                    flexBasis: '0%',
                    flexGrow: 1,
                    height: '2.75rem',
                  }}
                >
                  <Text sx={{ fontWeight: 600, lineHeight: '1.5rem' }}>{cancelBtnText || t('Cancel')}</Text>
                </Button>
              )}
              {onSubmit && (
                <Button
                  onClick={onSubmit}
                  disabled={isSubmitting || disableSubmitBtn}
                  data-testid="modal-submit-button"
                  sx={{
                    bg: 'primary.600',
                    flexBasis: '0%',
                    flexGrow: 1,
                    height: '2.75rem',
                    ':hover': {
                      bg: 'primary.700',
                    },
                  }}
                >
                  <Flex sx={{ alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                    {isSubmitting && <Spinner size={24} />}
                    <Text sx={{ fontWeight: 600, lineHeight: '1.5rem' }}>
                      {confirmBtnText || t('Confirm')}
                    </Text>
                  </Flex>
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Portal>
  );
};

export const NewModal = memo(ModalBase);
