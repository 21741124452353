import { CustomThemeProperty } from './types';

export const statistic = CustomThemeProperty('statistic', {
  header: {
    color: 'primary',
    fontSize: 1,
    fontWeight: 'body',
  },
  content: {
    position: 'relative',
    mr: 1,
    mt: 1,
    lineHeight: ['1em', 'body'],
    fontSize: 24,
    fontWeight: 'light',
  },
  suffix: {
    fontWeight: 'light',
    fontSize: 0,
  },
});
