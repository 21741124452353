import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const MainTooltip = () => {
  const [container] = useState(document.createElement('div'));
  container.setAttribute('style', 'z-index: 1000');
  const [windowSize, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(
    <Tooltip
      className="react-tooltip"
      id="tooltip"
      place="top"
      style={{ maxWidth: Math.min(440, windowSize * 0.9), zIndex: 999 }}
    />,
    container
  );
};

export default MainTooltip;
