import { FC } from 'react';
import { IconProps, IconWrapper } from './common';

export const Arrow: FC<IconProps> = (props) => (
  <IconWrapper
    icon={() => (
      <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.75"
          d="M3.14553 6.84957C2.51287 6.84957 2 7.36244 2 7.9951C2 8.62775 2.51287 9.14062 3.14553 9.14062H12.3097C12.9424 9.14062 13.4553 8.62775 13.4553 7.9951C13.4553 7.36244 12.9424 6.84957 12.3097 6.84957H3.14553Z"
          fill="white"
        />
        <path
          d="M8.82852 14.0587C9.03719 14.2747 9.15265 14.5641 9.15004 14.8644C9.14743 15.1648 9.02696 15.4521 8.81457 15.6645C8.60218 15.8769 8.31486 15.9973 8.01451 16C7.71416 16.0026 7.42479 15.8871 7.20874 15.6784L0.335582 8.80527C0.126796 8.59652 0.00667263 8.31522 0.000269711 8.02004C-0.00613321 7.72487 0.101678 7.43862 0.301215 7.22101L6.60161 0.347846C6.80918 0.133917 7.09195 0.00931644 7.38989 0.000500679C7.68783 -0.00831413 7.97748 0.09935 8.19732 0.300633C8.41717 0.501917 8.5499 0.780965 8.56734 1.07853C8.58477 1.37609 8.48553 1.66873 8.2907 1.89431L2.73088 7.96102L8.82852 14.0587Z"
          fill="white"
        />
      </svg>
    )}
    {...props}
    long
  />
);
