import { memo, useCallback, useMemo } from 'react';
import { pageview } from 'react-ga';
import { useLocation } from 'react-router';
import { Box, Flex } from 'theme-ui';
import { getCurrentPath } from '@power-ledger/react';
import { LogoPoweredByPlSVGLazy } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { Menu, Sider } from '../../../components';
import { HostLogo } from '../../../components/host-logo';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useLogOut } from '../../../hooks/use-log-out';
import { useIsAdmin } from '../../../hooks/use-is-admin';
import { registerUserEvent } from '../../../lib/auth-helpers';
import { useHostConfig } from '../../../contexts/host-config-context';
import { isCurrentPath } from '../../../utils/url';
import { useNavRoutes } from '../../routes';
import { useCurrentSubpage } from '../../../hooks/use-current-subpage';
import { SidebarProps } from './sidebar.types';

const SidebarBase: React.FC<SidebarProps> = ({ menuCollapsed, collapseMenu }) => {
  const { t } = useTranslation();
  const currentSubpage = useCurrentSubpage();
  const { logOut } = useLogOut();
  const { hostHasFlag } = useHostConfig(true);
  const { isAdmin } = useIsAdmin();
  const location = useLocation();
  const {
    hasVPP,
    hasPrefTrade,
    hasProsumer,
    hasProducer,
    hasPricing,
    hasRankings,
    hasNotifications,
    hasCommunityMap,
    hasEnergyMix,
    hasStatementOfUse,
    hasLP2P,
    hasLP2PMeter,
  } = useFeatureSet();
  const routes = useNavRoutes({
    hasVPP,
    hasPrefTrade,
    hasProsumer,
    hasProducer,
    hasPricing,
    hasRankings,
    hasCommunityMap,
    hasEnergyMix,
    hasStatementOfUse,
    hasLP2P,
    hasLP2PMeter,
    t,
    isAdmin,
  }).filter((route) => !route.hide);

  const mappedRoutes = useMemo(() => routes.map((route) => `/${route.path}`), [routes]);

  let currentRouteKey = mappedRoutes.findIndex(
    (route) =>
      location.pathname.indexOf(route) !== -1 ||
      (hasPrefTrade && route.indexOf('trading') !== -1 && location.pathname.indexOf('trading') !== -1)
  );

  if (
    currentRouteKey === -1 &&
    location.pathname.indexOf('account') === -1 &&
    location.pathname.indexOf('notifications') === -1 &&
    location.pathname.indexOf('themes') === -1
  ) {
    currentRouteKey = 0;
  }

  const handleNavigate = useCallback(
    ({ key, path }) => {
      const currentPath = typeof path === 'string' ? path : window.location.pathname;
      const pagePath = currentPath + window.location.search;
      const pageTitle = getCurrentPath(currentPath);

      if (currentRouteKey !== key) {
        pageview(pagePath);
        registerUserEvent(`navigated to ${pagePath.substring(1)}`, 'navigation');
        window.scrollTo(0, 0);

        collapseMenu(true);
      }
    },
    [collapseMenu, currentRouteKey]
  );

  const secondaryNavRoutes = useMemo(
    () =>
      hasNotifications
        ? [
            { path: 'notifications', title: t('Notifications') },
            { path: 'account', title: t('My Account') },
          ]
        : [{ path: 'account', title: t('My Account') }],
    [hasNotifications, t]
  );

  const menu = useMemo(
    () => (
      <Menu dataTestid="sidebar-menu">
        {routes
          .filter((route) => route.path !== 'account')
          .map((route) => {
            const key = mappedRoutes.findIndex((mappedRoute) => mappedRoute.indexOf(route.path) !== -1);

            return (
              <Menu.Item
                dataTestid={route.path}
                active={isCurrentPath(route.path, location.pathname)}
                tabIndex={0}
                key={route.path}
                to={`/${route.path}`}
                onClick={() => handleNavigate({ key })}
              >
                {route.title}
              </Menu.Item>
            );
          })}
      </Menu>
    ),
    [mappedRoutes, handleNavigate, routes, location.pathname]
  );

  const secondaryMenu = useMemo(
    () => (
      <Menu>
        {
          secondaryNavRoutes.map((secoundaryRoute) => (
            <Menu.Item
              key={secoundaryRoute.path}
              to={`/${secoundaryRoute.path}`}
              tabIndex={menuCollapsed ? -1 : 0}
              active={currentSubpage === secoundaryRoute.title}
              onClick={() => handleNavigate({ key: secoundaryRoute.path, path: `/${secoundaryRoute.path}` })}
            >
              {secoundaryRoute.title}
            </Menu.Item>
          )) as any
        }
        <Menu.Item key="logout" to="/" tabIndex={menuCollapsed ? -1 : 0} active={false} onClick={logOut}>
          {t('Log out')}
        </Menu.Item>
      </Menu>
    ),
    [secondaryNavRoutes, menuCollapsed, t, currentSubpage, handleNavigate, logOut]
  );

  return (
    <Sider
      secondaryMenu={secondaryMenu}
      logo={
        <Box sx={{ height: '100%', display: ['flex'] }}>
          <Box
            sx={{
              display: ['none', null, 'flex'],
              alignItems: 'center',
              height: 64,
            }}
          >
            <HostLogo maxWidth="220px" maxHeight="56px" isInverse />
          </Box>
          <Box sx={{ display: ['flex', null, 'none'], width: '100px' }}>
            <HostLogo maxWidth="100px" maxHeight="40px" isInverse />
          </Box>
        </Box>
      }
      poweredByLogo={
        !hostHasFlag('hidePowerledgerLogo') && (
          <Flex
            sx={{
              mb: 3,
              pr: 4,
              justifyContent: 'center',
              display: ['none', null, 'flex'],
            }}
          >
            <LogoPoweredByPlSVGLazy isReverse={!hostHasFlag('useBlackLogoOnSidebar')} />
          </Flex>
        )
      }
      closed={menuCollapsed}
      collapseMenu={() => collapseMenu()}
    >
      {menu}
    </Sider>
  );
};

export const Sidebar = memo(SidebarBase);
