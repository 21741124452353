import { AuthServiceProvider } from '@power-ledger/auth-service';
import React, { useMemo } from 'react';
import { useHostConfig } from '../../contexts/host-config-context';
import { XGridOktaAuthAdaptor } from './okta-adaptor-overrides';

export const XGridAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { config } = useHostConfig();

  const auth = config?.auth;

  const authConfig = useMemo(
    () =>
      auth
        ? {
            issuerId: auth.issuerID,
            clientId: auth.clientID,
            baseURL: auth.baseURL,
            pkce: true,
          }
        : null,
    [auth]
  );

  return (
    <AuthServiceProvider adaptor={XGridOktaAuthAdaptor} config={authConfig}>
      {children}
    </AuthServiceProvider>
  );
};
