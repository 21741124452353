import { useCallback, useEffect, useRef, useState } from 'react';
import { AuthAdaptor, AuthAdaptorBase } from './adaptors/adaptor-types';
import { AuthService, AuthStatus } from './auth-service-types';
import { applyAdaptorsClient } from './utils/with-adaptor-client';

export const useAuthServiceLogic = <T extends AuthAdaptorBase>(
  authAdaptor: AuthAdaptor<T>,
  config: T['config'] | null
): AuthService => {
  const adaptorClient = useRef<AuthAdaptorBase['client'] | null>(null);
  const [status, setStatus] = useState<AuthStatus>(AuthStatus.INIT);

  useEffect(() => {
    setStatus(AuthStatus.INIT);

    if (config) {
      const { destroy, client } = authAdaptor.createClientInstance(config, { onStatusChange: setStatus });
      adaptorClient.current = client;
      return destroy;
    } else {
      adaptorClient.current = null;
      return () => {
        // Intentionally empty as there is nothing to destroy
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authAdaptor, config]);

  const isAuthenticated = useCallback<AuthService['isAuthenticated']>(
    () => status === AuthStatus.AUTHENTICATED,
    [status]
  );

  const isLoading = useCallback<AuthService['isLoading']>(() => status === AuthStatus.INIT, [status]);

  const signIn = useCallback<AuthService['signIn']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.signIn)(...params),
    [authAdaptor]
  );

  const signInWithIDP = useCallback<AuthService['signInWithIDP']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.signInWithIDP)(...params),
    [authAdaptor]
  );

  const signOut = useCallback<AuthService['signOut']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.signOut)(...params),
    [authAdaptor]
  );

  const requestPasswordReset = useCallback<AuthService['requestPasswordReset']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.requestPasswordReset)(...params),
    [authAdaptor]
  );

  const verifyPasswordReset = useCallback<AuthService['verifyPasswordReset']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.verifyPasswordReset)(...params),
    [authAdaptor]
  );

  const getTokens = useCallback<AuthService['getTokens']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.getTokens)(...params),
    [authAdaptor]
  );

  const updatePassword = useCallback<AuthService['updatePassword']>(
    (...params) => authAdaptor.passwordUpdate(adaptorClient.current, ...params),
    [authAdaptor]
  );

  const useAuthCallbackHandler = useCallback<AuthService['useAuthCallbackHandler']>(
    (...params) => applyAdaptorsClient(adaptorClient, authAdaptor.useAuthCallbackHandler)(...params),
    [authAdaptor]
  );

  return {
    status,
    isAuthenticated,
    isLoading,
    signIn,
    signInWithIDP,
    signOut,
    requestPasswordReset,
    verifyPasswordReset,
    getTokens,
    updatePassword,
    useAuthCallbackHandler,
  };
};
