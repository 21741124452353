import { FC, memo, useMemo } from 'react';
import { Box, get } from 'theme-ui';

import { useTheme } from '../../hooks/use-theme';
import { createIconStyles, IconProps } from './common';

export const Hamburger: FC<IconProps> = memo(({ bg, color, size, sx, variant }) => {
  const { theme } = useTheme();
  const boxSx = useMemo(
    () => createIconStyles({ bg, color, size, sx, variant }),
    [bg, color, size, sx, variant]
  );
  const backgroundColor = useMemo(() => color || get(theme, 'icons.color'), [color, theme]);
  const burgerStyles = { width: '100%', height: '3px', backgroundColor };

  return (
    <Box sx={{ ...boxSx, display: 'grid', alignItems: 'center' }}>
      <Box sx={burgerStyles} />
      <Box sx={burgerStyles} />
      <Box sx={burgerStyles} />
    </Box>
  );
});
