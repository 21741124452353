import { useMemo } from 'react';
import { Interval } from 'luxon';
import chunk from 'lodash/chunk';

import { TATA_GRID_TYPE, TransactionTypeTranslationKeyMapping } from '../../../../../types';
import { areDatesWithinOneDay, parseDateWithTimeZone } from '../../../lib/datetime-helpers';
import { calculateRollingAverage } from '../../../services';
import { useForbiddenTransactionTypes } from './useForbiddenTransactionTypes';

type Transaction = {
  type: string;
  boughtAmount: number;
  boughtKw: number;
  soldAmount: number;
  soldKw: number;
  boughtAmountNetTax: number;
  soldAmountNetTax: number;
};

type TransactionInterval = {
  dateTime: string;
  estimate: boolean;
  transactions: Transaction[];
};

type TransactionConfigData = {
  intervals: TransactionInterval[];
  hiddenIds: string[];
  unit: string;
  tradeType: string;
  startDate: string;
  endDate: string;
  timeZone: string;
  chunkSize: number;
  t: any;
  includesBuyerTax?: boolean;
  includesSellerTax?: boolean;
  isTATA?: boolean;
};

const getValueByTradeTypeAndUnit = (
  tradeType: string,
  unit: string,
  {
    boughtKw,
    boughtAmount,
    boughtAmountNetTax,
    soldKw,
    soldAmount,
    soldAmountNetTax,
  }: Pick<
    Transaction,
    'boughtKw' | 'boughtAmount' | 'boughtAmountNetTax' | 'soldKw' | 'soldAmount' | 'soldAmountNetTax'
  >,
  includesBuyerTax?: boolean,
  includesSellerTax?: boolean
) => {
  if (tradeType === 'Bought') {
    if (unit === 'usage') {
      return boughtKw;
    }
    return includesBuyerTax ? boughtAmountNetTax : boughtAmount;
  }
  if (unit === 'usage') {
    return soldKw;
  }
  return includesSellerTax ? soldAmountNetTax : soldAmount;
};

const transactionTypeOrder = (type: string) => {
  enum typeOrderMap {
    grid,
    missed,
    correction,
    preferentialTrade,
    renewableAllocation,
    ppa,
    p2p,
    LOYALTY_P2P,
  }
  return typeOrderMap[type as keyof typeof typeOrderMap] || 0;
};

const GRID_TRANSACTION_TYPE = 'GRID';

export const useFormattedTransactionsData = ({
  intervals,
  hiddenIds,
  unit,
  tradeType,
  startDate,
  endDate,
  timeZone,
  chunkSize,
  t,
  includesBuyerTax = false,
  includesSellerTax = false,
  isTATA = false,
}: TransactionConfigData): { data: any[]; keys: string[]; types: string[] } => {
  const forbiddenTypes = useForbiddenTransactionTypes();

  const parsedStart = parseDateWithTimeZone(startDate, timeZone);
  const parsedEnd = parseDateWithTimeZone(endDate, timeZone).plus({ days: 1 });
  const isLessThenADay = areDatesWithinOneDay(startDate, endDate);
  const intervalLengthMin = useMemo(() => calculateIntervalLength(intervals), [intervals]);

  const filteredAndChunkedIntervals = useMemo(() => {
    if (Number.isNaN(chunkSize)) {
      return [];
    }

    return chunk(
      intervals.filter(({ dateTime }) =>
        Interval.fromDateTimes(parsedStart, parsedEnd).contains(parseDateWithTimeZone(dateTime, timeZone))
      ),
      chunkSize
    );
  }, [chunkSize, intervals, parsedEnd, parsedStart, timeZone]);

  const { parsedData, keys, types } = useMemo(() => {
    const newTypeSet = new Set<string>();

    const newParsedData = filteredAndChunkedIntervals.reduce<any[]>((acc, chunkArr) => {
      /* eslint-disable no-param-reassign */
      const { dateTimes, typesSeenThisChunk, ...transactionAverages } = chunkArr.reduce(
        (sumAcc, interval) => {
          sumAcc.typesSeenThisChunk = {} as Record<string, number>;
          sumAcc.dateTimes = [
            ...sumAcc.dateTimes,
            parseDateWithTimeZone(interval.dateTime, timeZone).toMillis(),
          ];

          interval.transactions.forEach(({ type, ...intervalData }) => {
            if (!forbiddenTypes.includes(type)) {
              const niceTypeName =
                isTATA && type === GRID_TRANSACTION_TYPE
                  ? TATA_GRID_TYPE
                  : TransactionTypeTranslationKeyMapping[
                      type as keyof typeof TransactionTypeTranslationKeyMapping
                    ] || type;

              const translatedType =
                isTATA && type === GRID_TRANSACTION_TYPE
                  ? t('{{ gridMeterName }} (will be confirmed after final settlement)', {
                      gridMeterName: 'DISCOM',
                    })
                  : t(`transaction.chart.typeName.${niceTypeName}`);

              newTypeSet.add(niceTypeName);
              if (sumAcc.typesSeenThisChunk[translatedType] === undefined) {
                sumAcc.typesSeenThisChunk[translatedType] = 0;
              }
              if (!sumAcc[translatedType]) {
                sumAcc[translatedType] = {} as Record<string, number>;
              }
              sumAcc.typesSeenThisChunk[translatedType] += 1;
              sumAcc[translatedType] = Object.entries(intervalData).reduce((intervalDataAcc, [key, val]) => {
                if (!sumAcc[translatedType][key]) {
                  sumAcc[translatedType][key] = 0;
                }
                intervalDataAcc[key] = calculateRollingAverage(
                  val,
                  sumAcc[translatedType][key] as number,
                  sumAcc.typesSeenThisChunk[translatedType]
                );
                return intervalDataAcc;
              }, {} as any);
            }
          });
          return sumAcc;
        },
        { dateTimes: [] } as Record<string, any>
      );
      /* eslint-enable no-param-reassign */
      const dateTime = parseDateWithTimeZone(Math.min(...dateTimes), timeZone).minus({
        /**
         * The date on each chunk defines the timestamp end of that block of data. People are usually
         * use to using timestamp start when looking at analytics. This defines a negative offset of
         * the data block to allow that
         */
        minutes: isLessThenADay ? intervalLengthMin : 0,
      });
      acc.push({
        date: dateTime.toMillis(),
        ...transactionAverages,
      });

      return acc;
    }, []);
    const newTypes = Array.from(newTypeSet).sort((a, b) => transactionTypeOrder(a) - transactionTypeOrder(b));

    return {
      parsedData: newParsedData,
      keys: newTypes.map((type) => {
        return type === TATA_GRID_TYPE
          ? t('{{ gridMeterName }} (will be confirmed after final settlement)', {
              gridMeterName: 'DISCOM',
            })
          : t(`transaction.chart.typeName.${type}`);
      }),
      types: newTypes,
    };
  }, [filteredAndChunkedIntervals, forbiddenTypes, intervalLengthMin, isLessThenADay, t, timeZone, isTATA]);

  const data = useMemo(
    () =>
      parsedData.map(({ date, ...dateSeries }) => {
        const formattedDataSeries = Object.entries(dateSeries).reduce((acc, [type, val]) => {
          if (!hiddenIds.includes(type)) {
            /* eslint-disable no-param-reassign */
            acc[type] = getValueByTradeTypeAndUnit(
              tradeType,
              unit,
              val as any,
              includesBuyerTax,
              includesSellerTax
            );
            /* eslint-enable no-param-reassign */
          }
          return acc;
        }, {} as any);
        return { date, ...formattedDataSeries };
      }),
    [hiddenIds, includesBuyerTax, includesSellerTax, parsedData, tradeType, unit]
  );

  return { data, keys, types };
};

const calculateIntervalLength = (intervals: TransactionInterval[]) => {
  const firstDate = intervals[0]?.dateTime;
  const secondDate = intervals[1]?.dateTime;
  return firstDate && secondDate ? (Date.parse(secondDate) - Date.parse(firstDate)) / 1000 / 60 : 0;
};
