import {
  UPDATE_RATE_PERIODS,
  UPDATE_DAILY_RATES,
  UPDATE_GRID_RATES,
  UPDATE_GROUP_DYNAMIC_RATES,
  UPDATE_DYNAMIC_RATES,
  RESET_PRICING,
} from '../constants';
import { initialState } from '../store/initial-state';

// Pricing reducer
const pricing = (state = initialState.pricing, action) => {
  switch (action.type) {
    case UPDATE_RATE_PERIODS:
      return {
        ...state,
        ratePeriods: action.ratePeriods,
      };
    case UPDATE_DAILY_RATES:
      return {
        ...state,
        dailyRates: action.dailyRates,
      };
    case UPDATE_GRID_RATES:
      return {
        ...state,
        gridRates: action.gridRates,
      };
    case UPDATE_GROUP_DYNAMIC_RATES:
      return {
        ...state,
        meterRates: action.meterRates,
        totalMeterRates: action.totalMeterRates,
      };
    case UPDATE_DYNAMIC_RATES:
      return {
        ...state,
        dynamicRates: action.dynamicRates,
      };
    case RESET_PRICING:
      return initialState.pricing;
    default:
      return state;
  }
};

export default pricing;
