export const timePicker = {
  '.rc-time-picker-input, .rc-time-picker-panel-input': {
    bg: 'transparent',
    fontSize: 0,
    border: 'none',
    borderRadius: 0,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'primary',
    fontWeight: 'bold',
    transition: 'base',
    '&:disabled': {
      bg: 'muted',
    },
  },
  '.rc-time-picker-input': {
    color: 'text',
    px: 0,
  },
  '.rc-time-picker-panel-input': {
    height: 5,
    px: 2,
  },
  '.rc-time-picker-panel-input-wrap': {
    p: 0,
    borderRadius: 4,
  },
  '.rc-time-picker-panel-inner': {
    border: 0,
    borderRadius: 2,
    color: 'background',
  },
  'li.rc-time-picker-panel-select-option-selected': {
    bg: 'primary',
    color: 'text',
  },
  '.rc-time-picker-panel-select li:hover': {
    bg: 'primaryLightest',
  },
};
