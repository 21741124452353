import { shallowEqual } from 'react-redux';

import { containsAdminRole } from '../lib/auth-helpers';
import { useAppSelector } from './use-app-selector';

export const useIsAdmin = () => {
  const accountRoles = useAppSelector(({ auth }) => auth.profile?.userRoles, shallowEqual);

  return { isAdmin: containsAdminRole(accountRoles) };
};
