import React from 'react';
import PropTypes from 'prop-types';

export const ArrowDownSVG = ({ width, height }: { width?: number; height?: number }) => (
  <svg width={width} height={height} viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0.600001L21.6 11.2L20.5 12.3L11 2.8L1.5 12.3L0.399999 11.2L11 0.600001Z" fill="white" />
  </svg>
);

ArrowDownSVG.defaultProps = {
  width: '16px',
  height: '16px',
};

ArrowDownSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
