/* eslint-disable */
import { GET, POST, PUT, DELETE, stringify } from './api-methods';
import {
  account,
  auth,
  billing,
  blockchain,
  dashboard,
  notifications,
  prefTrade,
  pricing,
  rankings,
  usage,
  vpp,
  communityMap,
  loyaltyP2P,
} from './endpoints';
import i18n, { getCurrentLanguage } from '../lib/i18n';
import { MeterEventRequestType } from '../layouts/common/loyalty-p2p/Campaign';
import { UserCampaign } from '../../../types';

export { GET, POST, PUT, DELETE, stringify };
/////F
// AUTH //
// ///////
export const loginUser = (params: any) => POST(auth.loginUserURL, false, params);

export const acceptTerms = (username: any, secret?: any) =>
  PUT(`${auth.acceptTermsURL}${username}/terms-accepted`, true, secret);

export const resetPassword = (username: any, params?: any) =>
  POST(auth.resetPasswordURL + username, false, params);

export const checkResetToken = (resetToken: any) => GET(auth.checkResetTokenURL + resetToken, false);

export const updatePassword = (params: any) => POST(auth.updatePasswordURL, true, params);

export const getAuthProfile = () => GET(auth.profileURL, true);

// DashAPI MFA
export const getMFASettings = (token: any) => GET(auth.mfaSettingsURL, true, token);

export const updateMFAMobile = (params: any) =>
  POST(`${auth.updateMobileURL}${params.mfaMobile}`, true, params);

export const removeMFAMobile = (mfaMobile: any) => DELETE(`${auth.updateMobileURL}${mfaMobile}`, true);

export const verifyMobileCode = (params: any) =>
  POST(auth.verifyUserMFASMSURL + params.mfaCode, true, params);

export const resendMobileCode = () => POST(auth.resendMFASMSURL, true);

// AuthServer MFA
export const loginUserMFASMS = (params: any) => POST(auth.loginUserMFASMSURL + params.mfaCode, false, params);

export const initialUpdateMFAMobile = (params: any) =>
  POST(`${auth.initialUpdateMobileURL}${params.mfaMobile}`, false, params);

export const initialVerifyMobileCode = (params: any) =>
  POST(auth.initialVerifyUserMFASMSURL + params.mfaCode, false, params);

export const initialResendMobileCode = (params: any) => POST(auth.initialResendMFASMSURL, false, params);

export const requestMFAToken = (params: any) => POST(auth.requestMFATokenURL, false, params);

// ////////////
// DASHBOARD //
// ////////////
export const getSiteWeather = (meterGroupId: any, token: any) =>
  GET(`${dashboard.siteWeatherURL}?meterGroupId=${meterGroupId}`, true, token);

export const getMeterGroups = () => GET(dashboard.meterGroupsURL, true);

export const getAdminSearchMeters = (searchRequest: any, token: any) => {
  const { tradingGroupName } = searchRequest;
  delete searchRequest.tradingGroupName;

  const requestParams = stringify(searchRequest);
  const mainUrl = `${dashboard.groupURL}/${tradingGroupName}/meters/search?${requestParams}`;
  return GET(mainUrl, true);
};

export const getSearchMeters = (searchRequest: any, token: any) => {
  const { tradingGroupName } = searchRequest;
  delete searchRequest.tradingGroupName;

  const requestParams = stringify(searchRequest);
  const mainUrl = `${dashboard.groupURL}/${tradingGroupName}/meter-names/search?${requestParams}`;
  return GET(mainUrl, true, token);
};

export const updateDisplayName = (request: any) => {
  const mainUrl = `${dashboard.groupURL}/${request.group}/meters/${request.meterUid}/displayName`;
  return PUT(mainUrl, true, { displayName: request.displayName });
};

export const getAccountUsers = (params: any) => {
  const { tradingGroupName, page, size, sort, accountNumbers, token } = params;

  const requestParams = stringify({ page, size, sort });
  const requestBody = { arrayData: accountNumbers, token };
  const mainUrl = `${dashboard.groupURL}/${tradingGroupName}/meters/search?${requestParams}`;

  return POST(mainUrl, true, requestBody);
};

export const getUserAccounts = (token: any) => GET(dashboard.userAccountsURL, true, token);

export const getBillingPeriods = (meterUid: any, token: any) => {
  // const encodedCurrentDateTime = encodeURIComponent(currentDateTime);
  const queryUrl = `?meterUid=${meterUid}`;
  return GET(dashboard.billingPeriodsURL + queryUrl, true, token);
};

export const getFunFacts = (consumptionKw: any, token: any) => {
  const queryUrl = `?consumptionKw=${consumptionKw}`;
  return GET(dashboard.funfactsURL + queryUrl, true, token);
};

export const getGroupedTransactionSummary = (transactionRequest: any, token: any) => {
  const { groupName, fromDateTime, toDateTime, includeEstimates, isEstimate } = transactionRequest;

  const estimateParam = includeEstimates ? '?includeEstimates=true' : '?includeEstimates=false';
  const isEstimateParam = isEstimate ? '&isEstimate=true' : '';
  const paramsUrl = `${groupName}/dates/${fromDateTime}/${toDateTime}${estimateParam}${isEstimateParam}`;

  return GET(dashboard.transactionSummaryURL + paramsUrl, true, token);
};

export const getGroupedTransactionsList = (transactionRequest: any, token: any) => {
  const { groupName, fromDateTime, toDateTime, includeEstimates, isEstimate } = transactionRequest;

  const estimateParam = includeEstimates ? '?includeEstimates=true' : '?includeEstimates=false';
  const isEstimateParam = isEstimate ? '&isEstimate=true' : '';
  const paramsUrl = `${groupName}/dates/${fromDateTime}/${toDateTime}${estimateParam}${isEstimateParam}`;

  return GET(dashboard.groupTransactionsListURL + paramsUrl, true, token);
};

export const getTransactionSummary = (transactionRequest: any, token: any) => {
  const { groupName, meterUid, fromDateTime, toDateTime, includeEstimates, isEstimate } = transactionRequest;

  const estParam = includeEstimates ? '?includeEstimates=true' : '?includeEstimates=false';
  const isEstimateParam = isEstimate ? '&isEstimate=true' : '';
  const paramsUrl = `${groupName}/meter/${meterUid}/dates/${fromDateTime}/${toDateTime}${estParam}${isEstimateParam}`;

  return GET(dashboard.transactionSummaryURL + paramsUrl, true, token);
};

export const getTransactionsList = (transactionRequest: any, token: any) => {
  const { groupName, meterUid, fromDateTime, toDateTime, includeEstimates, isEstimate } = transactionRequest;

  const estParam = includeEstimates ? '?includeEstimates=true' : '?includeEstimates=false';
  const isEstimateParam = isEstimate ? '&isEstimate=true' : '';
  const paramsUrl = `${groupName}/meter/${meterUid}/dates/${fromDateTime}/${toDateTime}${estParam}${isEstimateParam}`;

  return GET(dashboard.transactionsListURL + paramsUrl, true, token);
};

export const getTotalSavings = (savingsRequest: any, token: any) => {
  const { groupName, meterUid, fromDateTime, toDateTime, includeEstimates } = savingsRequest;

  const estParam = includeEstimates ? '?includeEstimates=true' : '';
  const paramsUrl = `${groupName}/meter/${meterUid}/savings/total/${fromDateTime}/${toDateTime}${estParam}`;

  return GET(dashboard.transactionsListURL + paramsUrl, true, token);
};

export const getMeterRank = (rankRequest: any, token: any) => {
  const { groupName, meterUid, fromDateTime, toDateTime, includeEstimates, isEstimate } = rankRequest;

  const estParam = includeEstimates ? '?includeEstimates=true' : '?includeEstimates=false';
  const isEstimateParam = isEstimate ? '&isEstimate=true' : '';
  const paramsUrl = `${groupName}/meter/${meterUid}/dates/${fromDateTime}/${toDateTime}${estParam}${isEstimateParam}`;

  return GET(dashboard.meterRankURL + paramsUrl, true, token);
};

// ////////
// USAGE //
// ////////
export const getUsageStatistics = (params: any, token: any) => {
  const requestParams = stringify(params);
  return GET(`${usage.statisticsURL}?${requestParams}`, true, token);
};

export const getReadings = (readingsRequest: any, options: any, token: any) => {
  const { tradingGroupName, meterUid, fromDateTime, toDateTime, includeEstimates } = readingsRequest;
  const meterUidUrl = meterUid ? `meter/${meterUid}/` : '';

  const optionParams = stringify(options);
  const estimatesParam = includeEstimates ? '&isEstimate=true' : '&isEstimate=false';
  const queryParams = `?${optionParams}${estimatesParam}`;

  const mainUrl = `${tradingGroupName}/${meterUidUrl}date/${fromDateTime}/${toDateTime}`;

  return GET(`${usage.readingsURL}${mainUrl}${queryParams}`, true, token);
};

// //////////
// BILLING //
// //////////
export const downloadGroupBillingCSV = (billingRequest: any, token: any) => {
  const { tradingGroupName, fromDateTime, toDateTime, combineMeterRows } = billingRequest;
  const paramsUrl = `${tradingGroupName}/dates/${fromDateTime}/${toDateTime}?combineMeterRows=${combineMeterRows}&locale=${getCurrentLanguage(
    i18n
  )}&includesTax=${billingRequest.includesTax}`;
  return GET(billing.downloadCSVURL + paramsUrl, true, token);
};

export const downloadMeterBillingCSV = (billingRequest: any, token: any) => {
  const { tradingGroupName, meterUid, fromDateTime, toDateTime, combineMeterRows } = billingRequest;
  const paramsUrl = `${tradingGroupName}/meter/${meterUid}/dates/${fromDateTime}/${toDateTime}?combineMeterRows=${combineMeterRows}&locale=${getCurrentLanguage(
    i18n
  )}&includesTax=${billingRequest.includesTax}`;
  return GET(billing.downloadCSVURL + paramsUrl, true, token);
};

export const downloadStatementPDF = (params: any) => POST(billing.downloadStatementURL, true, params);
// //////
// VPP //
// //////
export const getVppConfig = (groupName: any, token: any) => GET(vpp.vppConfigURL + groupName, true, token);

export const updateSmartArbitrage = (vppRequest: any, token: any) => {
  const { groupName, region, priceThreshold } = vppRequest;
  const reqBody = { region, priceThreshold, token };
  return PUT(vpp.vppConfigURL + groupName, true, reqBody);
};

export const getVppPrices = (vppRequest: any, token: any) => {
  const { fromDateTime, toDateTime } = vppRequest;
  const paramsUrl = `dates/${fromDateTime}/${toDateTime}`;
  return GET(vpp.forecastPricesURL + paramsUrl, true, token);
};

// UNUSED
// export const getVppEvents = (vppRequest, token) => {
//   const { fromDateTime, toDateTime } = vppRequest;
//   const paramsUrl = `dates/${fromDateTime}/${toDateTime}`;
//   return GET(vpp.vppEventsURL + paramsUrl, true, token);
// };

export const getDermEvents = (vppRequest: any, token: any) => {
  const { fromDateTime, toDateTime } = vppRequest;
  const paramsUrl = `dates/${fromDateTime}/${toDateTime}`;
  return GET(vpp.dermEventsURL + paramsUrl, true, token);
};

export const scheduleManualEvents = (params: any) =>
  POST(`${vpp.dermEventsCommandURL}schedule-charge-discharge`, true, params);

export const updateVppConfig = (groupName: any, config: any, token: any) =>
  PUT(vpp.vppConfigURL + groupName, true, { ...config, token });

export const getVppMeterBattery = (meterUid: any, token: any) =>
  GET(vpp.meterBatteryURL + meterUid, true, token);

export const getVppMeterBatteries = (token: any) => GET(vpp.meterBatteriesURL, true, token);

export const getVppClusterSummary = (token: any) => GET(vpp.clusterSummaryURL, true, token);

export const getBatteryVppSummary = (meterUid: any, token: any) =>
  GET(`${vpp.transactionsURL}/meter/${meterUid}`, true, token);

export const getEventRecords = (eventRequest: any, options: any, token: any) => {
  const { groupName, fromDateTime, toDateTime } = eventRequest;

  const optionParams = stringify(options);
  const mainUrl = `${groupName}/dates/${fromDateTime}/${toDateTime}`;

  return GET(`${vpp.eventRecordsURL}${mainUrl}?${optionParams}`, true, token);
};

// //////////
// PRICING //
// /////////
export const getRatePeriods = (tradingGroupName: any, token: any) => {
  const paramsUrl = `${tradingGroupName}/rate-periods`;
  return GET(pricing.groupURL + paramsUrl, true, token);
};

export const getDailyGroupRates = (pricingRequest: any, token: any) => {
  const { tradingGroupName, fromDateTime, toDateTime } = pricingRequest;
  const paramsUrl = `${tradingGroupName}/dates/${fromDateTime}/${toDateTime}`;
  return GET(pricing.groupURL + paramsUrl, true, token);
};

export const getGroupGridRates = (tradingGroupName: any, token: any) =>
  GET(`${pricing.groupURL}${tradingGroupName}/grid-rates`, true, token);

export const getGroupDynamicRates = (pricingRequest: any, token: any) => {
  const { tradingGroupName, category = '', sort = 'asc', pageIndex, pageSize = 1000 } = pricingRequest;
  const options = { category, sort, ...(pageIndex && { pageIndex }), pageSize };

  const optionParams = stringify(options);

  return GET(`${pricing.groupURL + tradingGroupName}?${optionParams}`, true, token);
};

export const getMeterDynamicRates = (pricingRequest: any, token: any) => {
  const { tradingGroupName, meterUid } = pricingRequest;
  return GET(`${pricing.groupURL}${tradingGroupName}/meter/${meterUid}`, true, token);
};

export const updateMeterDynamicRates = (params: any) => POST(pricing.updateRatesURL, true, params);

// ///////////////////////
// PREFERENTIAL TRADING //
// ///////////////////////
export const getOffers = (offersRequest: any, token: any) => {
  const { sort, ...props } = offersRequest;

  return GET(`${prefTrade.ppaURL}/search-all-categories?${stringify(props)}${sort}`, true, token);
};

export const createOffer = (params: any) => POST(prefTrade.ppaURL, true, params);

export const signOffer = (params: any) => {
  const { uid } = params;
  delete params.uid;
  delete params.isSeller;

  return POST(`${prefTrade.ppaURL}/${uid}/events`, true, params);
};

export const getOfferUserDecisions = (uid: any, token: any) =>
  GET(`${prefTrade.ppaURL}/${uid}/user-decisions`, true, token);

export const getAllocation = (token: any) => GET(`${prefTrade.ppaURL}/allocation`, true, token);

// /////////////
// BLOCKCHAIN //
// /////////////
export const getBlockchainTransaction = (txid: any, token: any) =>
  GET(blockchain.transactionURL + txid, true, token);

export const getBlockchainTransfer = (txn_signature: string, token: any) =>
  GET(blockchain.transferURL + txn_signature, true, token);

// //////////
// ACCOUNT //
// //////////
export const getUserProfile = (token: any) => GET(account.indexURL, true, token);

export const getUserProfileAuth = (token: any) => GET(auth.profileURL, true, token);

export const updateCurrentPassword = (params: any) => POST(account.updateCurrentPasswordURL, true, params);

// ////////////////
// NOTIFICATIONS //
// ////////////////
export const getAppNotifications = (notificationsRequest: any, token: any) => {
  const paramsUrl = `?${stringify(notificationsRequest)}`;
  return GET(notifications.notificationsURL + paramsUrl, true, token);
};

export const getAppNotification = (uid: any, token: any) =>
  GET(`${notifications.notificationsURL}/${uid}`, true, token);

export const getScheduledAppNotifications = (notificationsRequest: any, token: any) => {
  const paramsUrl = `?${stringify(notificationsRequest)}`;
  return GET(notifications.scheduledNotificationsURL + paramsUrl, true, token);
};

export const getScheduledAppNotification = (uid: any, token: any) =>
  GET(`${notifications.scheduledNotificationsURL}/${uid}`, true, token);

export const updateReadNotification = (uid: any, token: any) =>
  POST(`${notifications.notificationsURL}/${uid}/read`, true, { token });

export const createAppNotification = (params: any) => {
  const { toAllAccounts } = params;
  delete params.timeZone;

  let paramsUrl = '';

  if (toAllAccounts) {
    delete params.toAllAccounts;
    paramsUrl = `?${stringify({ toAllAccounts })}`;
  }

  return POST(notifications.scheduledNotificationsURL + paramsUrl, true, params);
};

export const deleteAppNotification = (params: any) =>
  DELETE(notifications.scheduledNotificationsURL, true, params);

// ///////////
// RANKINGS //
// ///////////
export const getInterstateRankings = () => GET(`${rankings.rankingsURL}/interstate`, true);

export const getIntrastateRankings = (meterUID: any) =>
  GET(`${rankings.rankingsURL}/${meterUID}/intrastate`, true);

export const getHomestateRankings = (meterUID: any) =>
  GET(`${rankings.rankingsURL}/${meterUID}/intrastate/homestate`, true);

// ////////////////
// COMMUNITY MAP //
// ////////////////
export const getMyMeterLocations = () => GET(`${communityMap.communityMapURL}/map/my-meter-locations`, true);

export const getMyNearbyMeterLocations = (params: any) => {
  const { latitude, longitude, radiusInMetres } = params;
  const requestBody = { latitude, longitude, radiusInMetres };
  const url = `${communityMap.communityMapURL}/map/my-nearby-meter-locations`;
  return POST(url, true, requestBody);
};

// //////////////
// LOYALTY P2P //
// //////////////
export const checkBalance = (campaignId: any, meterUid: any) =>
  GET(loyaltyP2P().campaignsURL + `/${campaignId}/meters/${meterUid}/balance`, true);

export const createVoucher = (request: any) => POST(loyaltyP2P().vouchersURL, true, request);

export const meterEvent = (campaignId: any, request: MeterEventRequestType) =>
  POST(loyaltyP2P(campaignId).meterEventURL, true, request);

export const getUserCampaigns = (params: any) => {
  const requestParams = stringify(params);
  return GET(loyaltyP2P().userCampaignsURL + `?${requestParams}`, true);
};

export const getUserVouchers = () => {
  return GET(loyaltyP2P().userVouchersURL, true);
};

export const getCampaigns = (params: any) => {
  const requestParams = stringify(params);
  return GET(loyaltyP2P().campaignsURL + `?${requestParams}`, true);
};

export const getUserMeters = (groupName: any) => GET(dashboard.metersURL + `/${groupName}/meters`, true);

export const getLP2PMeters = () => GET(loyaltyP2P().metersURL, true);

export const createCampaign = (campaign: any) => POST(loyaltyP2P().createCampaignURL, true, campaign);

export const updateCampaign = (campaign: any) => PUT(loyaltyP2P(campaign.id).updateCampaignURL, true, campaign) 


export const updateMaxNumberOfParticipants = (campaign: UserCampaign) => {
  const updateCampaignMaxNumberOfParticipantsSearchURL = new URLSearchParams({ campaignMeterUid: campaign.meterUid, maxNumberOfParticipants: campaign.maxNumberOfParticipants.toString() });

  const updateCampaignMaxNumberOfParticipantsURL = `${loyaltyP2P(campaign.id).updateCampaignMaxNumberOfParticipantsURL}?${updateCampaignMaxNumberOfParticipantsSearchURL}`;

  return PUT(updateCampaignMaxNumberOfParticipantsURL, true, campaign.id);
}
