import { memo, useCallback, useMemo } from 'react';
import { pageview } from 'react-ga';
import { useLocation } from 'react-router';
import { Box, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { Menu } from '../../../components';
import { HostLogo } from '../../../components/host-logo';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useLogOut } from '../../../hooks/use-log-out';
import { useIsAdmin } from '../../../hooks/use-is-admin';
import { registerUserEvent } from '../../../lib/auth-helpers';
import { isCurrentPath } from '../../../utils/url';
import { useNavRoutes } from '../../routes';
import { useCurrentSubpage } from '../../../hooks/use-current-subpage';
import { transactiveNavigate } from '../../../hooks/use-transactive-web-app';
import { DashboardIcon } from '../../../components/icons/sidebar/Dashboard';
import { YourEnergyMixIcon } from '../../../components/icons/sidebar/YourEnergyMix';
import { TransactionsIcon } from '../../../components/icons/sidebar/Transactions';
import { UsageIcon } from '../../../components/icons/sidebar/Usage';
import { BillingIcon } from '../../../components/icons/sidebar/Billing';
import { BatterySharingIcon } from '../../../components/icons/sidebar/BatterySharing';
import { CommunityMapIcon } from '../../../components/icons/sidebar/CommunityMap';
import { LoyaltyIcon } from '../../../components/icons/sidebar/Loyalty';
import { useHostConfig } from '../../../contexts/host-config-context';
import { TradingIcon } from '../../../components/icons/sidebar/Trading';
import { useScreenSize } from '../../../hooks/use-screen-size';
import { NewSider } from '../../../components/new-sider';
import { SidebarProps } from './sidebar.types';

const routePathIconMapping = {
  dashboard: <DashboardIcon size={7} />,
  'your-energy-mix/consumption': <YourEnergyMixIcon size={7} />,
  'transactions/overview': <TransactionsIcon size={7} />,
  'trading/pricing': <TradingIcon size={7} />,
  'trading/overview': <TradingIcon size={7} />,
  'usage/overview': <UsageIcon size={7} />,
  billing: <BillingIcon size={7} />,
  'battery-sharing': <BatterySharingIcon size={7} />,
  'community-map': <CommunityMapIcon size={7} />,
  'loyalty/user-campaigns': <LoyaltyIcon size={7} />,
  'loyalty/campaigns': <LoyaltyIcon size={7} />,
};

const getNavItemIcon = (routePath: string) => {
  if (Object.keys(routePathIconMapping).includes(routePath)) {
    return routePathIconMapping[routePath as keyof typeof routePathIconMapping];
  }

  return <DashboardIcon size={7} />;
};

const NewSidebarBase: React.FC<SidebarProps> = ({ menuCollapsed, collapseMenu }) => {
  const { t } = useTranslation();
  const currentSubpage = useCurrentSubpage();
  const { logOut } = useLogOut();
  const { isAdmin } = useIsAdmin();
  const location = useLocation();
  const { tradingGroup } = useHostConfig();

  const {
    hasVPP,
    hasPrefTrade,
    hasProsumer,
    hasProducer,
    hasPricing,
    hasRankings,
    hasNotifications,
    hasCommunityMap,
    hasEnergyMix,
    hasStatementOfUse,
    hasLP2P,
    hasLP2PMeter,
  } = useFeatureSet();
  const { isMediumDown } = useScreenSize();

  const routes = useNavRoutes({
    hasVPP,
    hasPrefTrade,
    hasProsumer,
    hasProducer,
    hasPricing,
    hasRankings,
    hasCommunityMap,
    hasEnergyMix,
    hasStatementOfUse,
    hasLP2P,
    hasLP2PMeter,
    t,
    isAdmin,
  }).filter((route) => !route.hide);

  const mappedRoutes = useMemo(() => routes.map((route) => `/${route.path}`), [routes]);

  let currentRouteKey = mappedRoutes.findIndex(
    (route) =>
      location.pathname.indexOf(route) !== -1 ||
      (hasPrefTrade && route.indexOf('trading') !== -1 && location.pathname.indexOf('trading') !== -1)
  );

  if (
    currentRouteKey === -1 &&
    location.pathname.indexOf('account') === -1 &&
    location.pathname.indexOf('notifications') === -1 &&
    location.pathname.indexOf('themes') === -1
  ) {
    currentRouteKey = 0;
  }

  const handleNavigate = useCallback(
    ({ key, path }) => {
      const currentPath = typeof path === 'string' ? path : window.location.pathname;
      const pagePath = currentPath + window.location.search;

      setTimeout(() => transactiveNavigate?.(pagePath), 0);

      if (currentRouteKey !== key) {
        pageview(pagePath);
        registerUserEvent(`navigated to ${pagePath.substring(1)}`, 'navigation');
        window.scrollTo(0, 0);

        collapseMenu(true);
      }
    },
    [collapseMenu, currentRouteKey]
  );

  const secondaryNavRoutes = useMemo(
    () =>
      hasNotifications
        ? [
            { path: 'notifications', title: t('Notifications') },
            { path: 'account', title: t('My Account') },
          ]
        : [{ path: 'account', title: t('My Account') }],
    [hasNotifications, t]
  );

  const menu = useMemo(
    () => (
      <Menu dataTestid="sidebar-menu">
        {routes
          .filter((route) => route.path !== 'account')
          .map((route) => {
            const key = mappedRoutes.findIndex((mappedRoute) => mappedRoute.indexOf(route.path) !== -1);

            return (
              <Menu.Item
                dataTestid={route.path}
                active={isCurrentPath(route.path, location.pathname)}
                tabIndex={0}
                key={route.path}
                to={`/${route.path}`}
                onClick={() => handleNavigate({ key })}
                newStyles={!isMediumDown}
              >
                <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  {!isMediumDown && getNavItemIcon(route.path)}
                  <span>{route.title}</span>
                </Flex>
              </Menu.Item>
            );
          })}
      </Menu>
    ),
    [mappedRoutes, handleNavigate, routes, location.pathname, isMediumDown]
  );

  const secondaryMenu = useMemo(
    () => (
      <Menu>
        {
          secondaryNavRoutes.map((secoundaryRoute) => (
            <Menu.Item
              key={secoundaryRoute.path}
              to={`/${secoundaryRoute.path}`}
              tabIndex={menuCollapsed ? -1 : 0}
              active={currentSubpage === secoundaryRoute.title}
              onClick={() => handleNavigate({ key: secoundaryRoute.path, path: `/${secoundaryRoute.path}` })}
            >
              <span>{secoundaryRoute.title}</span>
            </Menu.Item>
          )) as any
        }
        <Menu.Item key="logout" to="/" tabIndex={menuCollapsed ? -1 : 0} active={false} onClick={logOut}>
          {t('Log out')}
        </Menu.Item>
      </Menu>
    ),
    [secondaryNavRoutes, menuCollapsed, t, currentSubpage, handleNavigate, logOut]
  );

  return (
    <NewSider
      secondaryMenu={secondaryMenu}
      logo={
        <Box sx={{ height: '100%', display: ['flex'], ml: [null, null, -24] }}>
          <Box
            sx={{
              display: ['none', null, 'flex'],
              alignItems: 'center',
              height: 64,
              marginBottom: 20,
            }}
          >
            <HostLogo maxWidth={`${tradingGroup?.logoWidth?.sm || 220}px`} maxHeight="56px" />
          </Box>
          <Box sx={{ display: ['flex', null, 'none'], width: '100px' }}>
            <HostLogo maxWidth="100px" maxHeight="40px" isInverse />
          </Box>
        </Box>
      }
      sx={
        isMediumDown
          ? {}
          : {
              bg: 'newUISecondary',
              borderRight: '1px solid #F9FAFB',
              padding: '28px !important',
            }
      }
      closed={menuCollapsed}
      collapseMenu={() => collapseMenu()}
    >
      {menu}
    </NewSider>
  );
};

export const NewSidebar = memo(NewSidebarBase);
