import { merge, Theme } from 'theme-ui';
import { colors } from './colors';
import {
  badges,
  barChart,
  breakpoints,
  buttons,
  card,
  contextMenus,
  dropdowns,
  fonts,
  fontSizes,
  fontWeights,
  forms,
  graphs,
  headingStyles,
  icons,
  infoBox,
  layouts,
  lineHeights,
  links,
  menus,
  popconfirms,
  sectionHeaders,
  statistic,
  switches,
  tables,
  tabs,
  tags,
  timePickers,
  zindex,
} from './defaultThemeStyles';

const makeTheme = <T extends Theme>(t: T) => t;
export const defaultTheme = makeTheme({
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: [0, 4, 8, 16, 24, 32, 40, 48, 56, 80, 160, 320],
  transitions: {
    base: 'all 0.3s ease',
  },
  fonts,
  fontSizes,
  fontWeights,
  radii: [0, 1, 2, 4, 8, 16, 32, 64],
  borders: [0, 1, 2],
  borderWidths: [0, 1, 2, 4, 6],
  shadows: {
    focus: '0 0 0 3px rgb(0 103 244 / 50%)',
  },
  icons,
  tables,
  lineHeights,
  colors,
  graphs,
  buttons,
  breakpoints,
  menus,
  contextMenus,
  dropdowns,
  layouts,
  tags,
  forms,
  links,
  tabs,
  popconfirms,
  badges,
  card,
  sectionHeaders,
  switches,
  statistic,
  timePickers,
  barChart,
  loadingOverlay: {
    color: 'primary',
  },
  infoBox,
  text: {
    heading: {
      my: 2,
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    small: {
      variant: 'paragraph',
      color: 'grey',
      fontWeight: 'light',
    },
    light: {
      fontWeight: 'light',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      backgroundColor: 'background',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      '& > div:first-of-type': {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
      },
      '*::selection': {
        color: 'background',
        backgroundColor: 'primary',
      },
      '.Toastify__toast--info': {
        backgroundColor: 'accent',
      },
      '.Toastify__toast--success': {
        backgroundColor: 'primaryLighter',
      },
      '.Toastify__toast--warning': {
        backgroundColor: 'warningLight',
      },
      '.Toastify__toast--error': { backgroundColor: 'warningLight' },
      ...headingStyles,
    },
    hr: {
      color: 'accent',
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    img: {
      maxWidth: '100%',
    },
    table: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
      borderSpacing: 0,
      td: {
        fontSize: 1,
        px: 2,
      },
      th: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'black',
      },
      'td:not(:last-child), th:not(:last-child)': {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: 'black',
      },
    },
  },
  box: {
    batteryIcon: {
      p: [2, null, null, '12px'],
      m: '1px',
      borderRadius: [0, null, null, 1],
      display: 'flex',
    },
    mobileSiderHeight: 58,
  },
  zIndices: {
    ...zindex,
  },
});

export type ExactTheme = typeof defaultTheme;

export const mergeWithDefaultTheme = (theme: Theme) => merge(defaultTheme, theme);
