export enum EnergyType {
  SOLAR_PV = 'SOLAR_PV',
  WIND = 'WIND',
  BIOMASS = 'BIOMASS',
  HYDRO = 'HYDRO',
  NUCLEAR = 'NUCLEAR',
  GRID = 'GRID',
  UNKNOWN = 'UNKNOWN',
}

export interface EnergyMixStatistics {
  co2Savings: CO2Saving[];
  intervals?: EnergyMixInterval[];
  totals: EnergyMixStatisticsTotal[];
}

export interface EnergyMixStatisticsTotal {
  id: EnergyType;
  kwh: number;
  money: number;
  moneyNetTax: number;
  moneyTax: number;
}

export interface EnergyMixInterval {
  biomassMoney: number;
  biomassMoneyNetTax: number;
  biomassMoneyTax: number;
  biomassUsage: number;
  dateTime: string;
  gridMoney: number;
  gridMoneyNetTax: number;
  gridMoneyTax: number;
  gridUsage: number;
  hydroMoney: number;
  hydroMoneyNetTax: number;
  hydroMoneyTax: number;
  hydroUsage: number;
  nuclearMoney: number;
  nuclearMoneyNetTax: number;
  nuclearMoneyTax: number;
  nuclearUsage: number;
  solarMoney: number;
  solarMoneyNetTax: number;
  solarMoneyTax: number;
  solarUsage: number;
  unknownMoney: number;
  unknownMoneyNetTax: number;
  unknownMoneyTax: number;
  unknownUsage: number;
  windMoney: number;
  windMoneyNetTax: number;
  windMoneyTax: number;
  windUsage: number;
}

export interface CO2Saving {
  id: EnergyType;
  base: number;
  grams: number;
  type: EnergyType;
}
