import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { ElementSize } from '../../../types';

export const defaultElementSize: ElementSize = {
  scrollWidth: undefined,
  scrollHeight: undefined,
  offsetWidth: undefined,
  offsetHeight: undefined,
};

export const useElementSize = <T extends HTMLElement>(ref: React.RefObject<T | null> | null) => {
  const [elementSize, setElementSize] = useState<ElementSize>(defaultElementSize);
  const [observer, setObserver] = useState<ResizeObserver | null>(null);

  useEffect(() => {
    const element = ref?.current;

    if (!element) return;

    const debounceHandleResize = debounce(() => {
      setElementSize({
        scrollWidth: element.scrollWidth,
        scrollHeight: element.scrollHeight,
        offsetWidth: element.offsetWidth,
        offsetHeight: element.offsetHeight,
      });
    });

    if (window.ResizeObserver && !observer) {
      setObserver(new ResizeObserver(debounceHandleResize));
    }

    if (!observer) return;

    observer.observe(element);
    debounceHandleResize();

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return elementSize;
};
