import React from 'react';
import PropTypes from 'prop-types';

export const Notification = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    aria-labelledby="title-notification"
    aria-describedby="desc-notification"
    role="img"
  >
    <title id="title-notification">Western Power Logo</title>
    <description id="desc-notification">The brand logo for Western Power.</description>
    <circle cx="16" cy="16" r="16" fill="#AFD600" />
    <g filter="url(#filter0_d)">
      <path
        d="M14.5703 25.7778V12.8038H17.4545V25.7778H14.5703ZM17.7777 9.0175C17.7777 9.5294
      17.6037 9.97069 17.2556 10.3414C16.9241 10.7121 16.5014 10.8974 15.9875 10.8974C15.5068
      10.8974 15.0924 10.7121 14.7443 10.3414C14.3962 9.97069 14.2222 9.5294 14.2222 9.0175C14.2222
      8.48795 14.3962 8.03783 14.7443 7.66714C15.0924 7.29646 15.5068 7.11111 15.9875
      7.11111C16.5014 7.11111 16.9241 7.30528 17.2556 7.69362C17.6037 8.06431 17.7777 8.5056 17.7777
      9.0175Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="14.2222"
        y="7.11111"
        width="3.55556"
        height="18.6667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feColorMatrix type="matrix" values="0 0 0 0 0.196058 0 0 0 0 0.196092 0 0 0 0 0.196048 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

Notification.defaultProps = {
  width: '32px',
  height: '32px',
};

Notification.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
