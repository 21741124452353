import { memo, useLayoutEffect } from 'react';
import { Box, Text } from 'theme-ui';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { RouterContainer } from '../router-container';

import { transactiveNavigate, useTransactiveWebApp } from '../../../hooks/use-transactive-web-app';
import { LoadingOverlay } from '../../../components';
import { LayoutMainProps } from './layout-main.types';

const LayoutMainBase: React.FC<LayoutMainProps> = ({
  areMeterGroupOrControlsInitialized,
  areMetersInitialized,
  meterGroup,
  isAdmin,
  selectedMeter,
  loadingText,
}) => {
  const { t } = useTranslation();
  const { isLoadingTransactiveWebApp, isVisionUIEnabled, newAppContainerRef } = useTransactiveWebApp();
  const location = useLocation();

  useLayoutEffect(() => {
    setTimeout(() => transactiveNavigate?.(location.pathname, { replace: true }), 0);
  }, [location.pathname]);

  return (
    <Box role="main">
      <Box id="new-app-container" ref={newAppContainerRef} />
      {isVisionUIEnabled && isLoadingTransactiveWebApp && (
        <LoadingOverlay active text={<Text>{t('Loading...')}</Text>} />
      )}
      {!isLoadingTransactiveWebApp && (
        <RouterContainer
          isLoading={!(areMeterGroupOrControlsInitialized && areMetersInitialized && meterGroup?.id)}
          displayNoMeterPlaceholder={!isAdmin && areMetersInitialized && !selectedMeter}
          loadingText={loadingText}
          isAdmin={isAdmin}
        />
      )}
    </Box>
  );
};

export const LayoutMain = memo(LayoutMainBase);
