import {
  UPDATE_USER_ACCOUNT,
  UPDATE_BILLING_PERIOD,
  UPDATE_FUN_FACTS,
  UPDATE_TRANSACTION_SUMMARY,
  UPDATE_LAST_TRANSACTION_SUMMARY,
  UPDATE_TRANSACTIONS_LIST,
  UPDATE_LAST_TRANSACTIONS_LIST,
  UPDATE_METER_RANK,
  UPDATE_SITE_WEATHER,
  RESET_DASHBOARD,
  UPDATE_TOTAL_SAVINGS,
} from '../constants';
import { initialState } from '../store/initial-state';

// Dashboard reducer
const dashboard = (state = initialState.dashboard, action) => {
  switch (action.type) {
    case UPDATE_USER_ACCOUNT:
      return {
        ...state,
        accountId: action.accountId,
        accountNumber: action.accountNumber,
        accountBalance: action.accountBalance,
        billingFrequency: action.billingFrequency,
      };
    case UPDATE_BILLING_PERIOD:
      return {
        ...state,
        billingStart: action.billingStart,
        billingEnd: action.billingEnd,
        billingFrequency: action.billingFrequency || state.billingFrequency,
      };
    case UPDATE_FUN_FACTS:
      return {
        ...state,
        funFacts: action.funFacts,
      };
    case UPDATE_TRANSACTION_SUMMARY:
      return {
        ...state,
        ...(action.meterTradeSummary
          ? {
              meterTradeSummary: action.meterTradeSummary,
            }
          : {
              groupTradeSummary: action.groupTradeSummary,
            }),
      };
    case UPDATE_LAST_TRANSACTION_SUMMARY:
      return {
        ...state,
        ...(action.meterTradeSummary
          ? {
              lastMeterTradeSummary: action.meterTradeSummary,
            }
          : {
              lastGroupTradeSummary: action.groupTradeSummary,
            }),
      };
    case UPDATE_TRANSACTIONS_LIST:
      return {
        ...state,
        ...(action.meterTrades ? { meterTrades: action.meterTrades } : { groupTrades: action.groupTrades }),
      };
    case UPDATE_LAST_TRANSACTIONS_LIST:
      return {
        ...state,
        ...(action.meterTrades
          ? { lastMeterTrades: action.meterTrades }
          : { lastGroupTrades: action.groupTrades }),
      };
    case UPDATE_METER_RANK:
      return {
        ...state,
        rankings: action.rankings,
      };
    case UPDATE_SITE_WEATHER:
      return {
        ...state,
        weatherBySite: action.weatherBySite,
      };
    case RESET_DASHBOARD:
      return initialState.dashboard;
    default:
      return state;
    case UPDATE_TOTAL_SAVINGS:
      return {
        ...state,
        totalSavings: action.totalSavings,
      };
  }
};

export default dashboard;
