import { ThemeStyles } from 'theme-ui';

export const headingStyles: Pick<ThemeStyles, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'> = [
  1, 2, 3, 4, 5, 6,
].reduce(
  (acc, num) =>
    Object.assign(acc, {
      [`h${num}`]: {
        fontSize: [Math.max(4 - num, 0), Math.max(5 - num, 0), 6 - num],
      },
    }),
  {}
);
