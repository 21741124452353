/* eslint-disable i18next/no-literal-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const initialLevels = [0.18, 0.23, 0.28, 0.33, 0.38, 0.43, 0.48, 0.53, 0.58, 0.63, 0.68, 0.73];

class LogoPLDeviceSVG extends Component {
  constructor(props) {
    super(props);
    this.state = { levels: initialLevels.reverse() };
  }

  componentDidMount = () => {
    if (this.props.animate) {
      this.timerID = setInterval(() => this.updateOpacity(), 100);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.animate !== this.props.animate) {
      clearInterval(this.timerID);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ levels: initialLevels.reverse() });
    }
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
    this.setState({ levels: initialLevels.reverse() });
  }

  updateOpacity() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newLevels = this.state.levels;

    newLevels.forEach((item, index) => {
      if (index >= newLevels.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        newLevels[index] = newLevels[0];
      } else {
        newLevels[index] = newLevels[index + 1];
      }
    });

    this.setState({ levels: newLevels });
  }

  render() {
    return (
      <svg
        width="50px"
        height="50px"
        viewBox="0 0 62 61"
        aria-labelledby="title-pl-device"
        aria-describedby="desc-pl-device"
        role="img"
      >
        <title id="title-pl-device">Powerledger Logo Device</title>
        <description id="desc-pl-device">
          The brand device &quot;star&quot; from the Powerledger logo.
        </description>
        <path
          opacity={this.state.levels[11]}
          fill="#4BEBE1"
          d="M32.0278 7.82151L46.005
        4.09932L49.7502 17.9917L35.7727 21.7142L32.0278 7.82151Z"
        />
        <path
          opacity={this.state.levels[10]}
          fill="#74EF6B"
          d="M39.5059 25.4035L43.2508
        11.5108L57.2282 15.2333L53.483 29.1257L39.5059 25.4035Z"
        />
        <path
          opacity={this.state.levels[9]}
          fill="#82EF7A"
          d="M40.8994 30.4907L51.1317
        20.3205L61.3643 30.4907L51.1317 40.6609L40.8994 30.4907Z"
        />
        <path
          opacity={this.state.levels[8]}
          fill="#83EF80"
          d="M39.5225 35.5686L53.4999
        31.8464L57.2448 45.7388L43.2677 49.4613L39.5225 35.5686Z"
        />
        <path
          opacity={this.state.levels[7]}
          fill="#85EF83"
          d="M32.0381 53.1814L35.7833
        39.2887L49.7605 43.0112L46.0156 56.9036L32.0381 53.1814Z"
        />
        <path
          opacity={this.state.levels[6]}
          fill="#7DEF93"
          d="M20.4546 50.8208L30.6869
        40.6506L40.9191 50.8208L30.6869 60.991L20.4546 50.8208Z"
        />
        <path
          opacity={this.state.levels[5]}
          fill="#68EDAE"
          d="M11.5786 43.0253L25.5561
        39.3031L29.301 53.1955L15.3235 56.918L11.5786 43.0253Z"
        />
        <path
          opacity={this.state.levels[4]}
          fill="#59ECC3"
          d="M4.09863 45.7313L7.84354
        31.8386L21.821 35.5611L18.0761 49.4535L4.09863 45.7313Z"
        />
        <path
          opacity={this.state.levels[3]}
          fill="#4BEBD7"
          d="M0 30.4997L10.2323 20.3295L20.4648
        30.4997L10.2323 40.6699L0 30.4997Z"
        />
        <path
          opacity={this.state.levels[2]}
          fill="#47EADF"
          d="M4.104 15.2428L18.0815
        11.5206L21.8264 25.413L7.84891 29.1355L4.104 15.2428Z"
        />
        <path
          opacity={this.state.levels[1]}
          fill="#4BEADF"
          d="M11.5874 17.989L15.3323
        4.09627L29.3098 7.81875L25.5649 21.7112L11.5874 17.989Z"
        />
        <path
          opacity={this.state.levels[0]}
          fill="#4FEBE0"
          d="M20.4453 10.1702L30.6776 0L40.9101
        10.1702L30.6776 20.3404L20.4453 10.1702Z"
        />
      </svg>
    );
  }
}

LogoPLDeviceSVG.defaultProps = {
  animate: true,
};

LogoPLDeviceSVG.propTypes = {
  animate: PropTypes.bool,
};

export default LogoPLDeviceSVG;
