import { MeterGroup } from '../../../types';
import { NODE_ENV } from './envHelpers';

const groupHasFeature = (feature: string, meterGroup: MeterGroup | null = null) => {
  if (NODE_ENV === 'test') return true;

  if (!meterGroup?.features.length) return false;

  return meterGroup.features.includes(feature);
};

export default groupHasFeature;
