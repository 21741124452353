import { Theme } from 'theme-ui';
import { colors } from './colours';
import {
  breakpoints,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  buttons,
  forms,
  icons,
  menu,
  layouts,
  links,
  headingStyles,
  subheading,
  contextMenu,
  datePicker,
  timePicker,
  toast,
  card,
  dropdown,
  radioButtons,
  tabs,
  tables,
  recGraph,
  texts,
  loadingOverlay,
  recBadge,
  attributeSelector,
  mdxTableStyling,
  toastTracex,
} from './defaultThemeStyles';

const makeTheme = <T extends Theme>(t: T) => t;

export const defaultTheme = makeTheme({
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512, 720, 960, 1140],
  sizes: [0, 4, 8, 16, 24, 32, 40, 48, 56, 80, 160, 320],
  transitions: {
    base: 'all 0.3s ease',
    opacity: 'opacity 0.3s ease',
  },
  fonts,
  fontSizes,
  fontWeights,
  breakpoints,
  radii: [0, 1, 2, 4, 8, 16, 32, 64],
  borders: [0, 1, 2],
  borderWidths: [0, 1, 2, 3, 4],
  shadows: {
    focus: '0 0 0 3px rgb(0 103 244 / 50%)',
  },
  lineHeights,
  letterSpacings,
  colors,
  icons,
  buttons,
  forms,
  menu,
  texts,
  layout: {
    container: {},
  },
  layouts,
  links,
  headingStyles,
  subheading,
  contextMenu,
  card,
  dropdown,
  radioButtons,
  tabs,
  tables,
  recGraph,
  loadingOverlay,
  recBadge,
  attributeSelector,
  styles: {
    // .mdx file styling goes here
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      backgroundColor: 'background',
      ...mdxTableStyling,
      ...(datePicker as any),
      ...(timePicker as any),
      ...toast,
      'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        ' -webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
});

export const lemTheme = {
  ...defaultTheme,
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      backgroundColor: 'background',
      ...(datePicker as any),
      ...(timePicker as any),
      ...toast,
      'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        ' -webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
};

export const tracexTheme = {
  ...defaultTheme,
  styles: {
    ...defaultTheme.styles,
    root: {
      ...defaultTheme.styles.root,
      ...toastTracex,
    },
  },
};

export type ExactTheme = typeof defaultTheme;
