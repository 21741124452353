import { useMemo } from 'react';
import { css } from 'theme-ui';
import { getColor } from '@theme-ui/color';
import { Theme } from '@nivo/core';
import { deepMap } from '@power-ledger/react';

import { useTheme } from './use-theme';

const convertRemToPixels = (rem: number) =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

const remRegex = /([\d|.]+)rem/;

const parseRemToNumber = (remStr: string) => {
  const match = remStr.toString().match(remRegex);

  if (!match) return match;

  return Number(match[1]);
};

export const useThemedColorScale = () => {
  const { theme } = useTheme();
  const { colorScheme, margins, chartConfig, ...nivoTheme } = theme.graphs;

  const themeToUse = useMemo(() => {
    const colorKeys = Object.keys(theme.colors);

    const themeCss = css(
      deepMap(
        nivoTheme,
        (val: string) => {
          if (colorKeys.includes(val)) return getColor(theme, val);

          return val;
        },
        {}
      ) as any
    )(theme) as Theme;

    return deepMap(
      themeCss,
      (val: string) => {
        const parsedVal = parseRemToNumber(val);

        if (parsedVal) return convertRemToPixels(parsedVal);

        return val;
      },
      {}
    );
  }, [nivoTheme, theme]);

  return { colorScheme, chartTheme: themeToUse, margin: margins, chartConfig };
};
