export { default as ChartBar } from './chart-bar.svg';
export { default as CogWheel } from './cogwheel.svg';
export { default as Document } from './document.svg';
export { default as FDashboard } from './f-dashboard.svg';
export { default as LogOut } from './log-out.svg';
export { default as RightArrow } from './right-arrow.svg';
export { default as UserHollow } from './user-hollow.svg';
export { default as Car } from './car-side.svg';
export { default as Battery } from './battery-three-quarters.svg';
export { default as SolarPanel } from './solar-panel.svg';
export { default as Turbine } from './turbine.svg';
export { default as AdminUser } from './admin-user.svg';
