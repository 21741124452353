import { useMemo } from 'react';
import { getColor } from '@theme-ui/color';
import { useTranslation } from 'react-i18next';

import { safelyParseNumber } from '../../../../utils/number';
import { useTheme } from '../../../../hooks/use-theme';
import { useThemedColorScale } from '../../../../hooks/use-themed-color-scale';
import {
  TransactionSummary,
  TransactionType,
  TransactionTypeTranslationKeyMapping,
} from '../../../../../../types';
import { formatAmount } from '../../../../lib/helpers';
import { transactionChartColorScheme } from '../../../../../app/styles/colors';
import { AvailableTransactionSummaryField, useTradingSummary } from '../trading-summary';
import { UseTransactionPieChartStatisticsProps } from './pie-chart-statistics.types';

export const usePieChartStatistics = ({
  tradeType,
  unit,
  transactionSummaries,
  isTATA = true,
}: UseTransactionPieChartStatisticsProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { colorScheme } = useThemedColorScale();
  const { viewStrategy, allowedTransactionSummaries, translatedLabels } = useTradingSummary({
    transactionSummaries,
  });

  const normalizedPieChartData = useMemo(() => {
    const transactionKey = viewStrategy[unit].valueKey[tradeType];
    const totalValue = allowedTransactionSummaries.reduce(
      (accumulator: number, transactionSummary: TransactionSummary) =>
        accumulator + transactionSummary[transactionKey as AvailableTransactionSummaryField],
      0
    );

    return allowedTransactionSummaries
      .map((transactionSummary: TransactionSummary) => {
        const foundDisplayName = translatedLabels.find((label) =>
          label.type.includes(transactionSummary.name)
        );

        let transactionValue = 0;
        let id = foundDisplayName?.labelText || t('Unknown');

        if (isTATA && transactionSummary.name === TransactionType.GRID) {
          id = 'DISCOM';
        }

        transactionValue = transactionSummary[transactionKey as AvailableTransactionSummaryField];

        return {
          key: transactionSummary.name,
          id,
          label: foundDisplayName?.labelText || t('Unknown'),
          // TODO: We need to find a proper way to fix this instead of this hack.
          value: formatAmount(transactionValue / totalValue, 4).replace(',', '.'),
        };
      })
      .filter((transactionSummary) => safelyParseNumber(transactionSummary.value) > 0);
  }, [allowedTransactionSummaries, tradeType, unit, t, translatedLabels, viewStrategy, isTATA]);

  const colorSchemeWithOverrides: string[] = useMemo(
    () =>
      normalizedPieChartData.map(({ key }, index: number) => {
        const transactionChartColorKeyName =
          TransactionTypeTranslationKeyMapping[key as keyof typeof TransactionTypeTranslationKeyMapping] ||
          key;

        const overrideColor = transactionChartColorScheme[transactionChartColorKeyName];

        return overrideColor ? getColor(theme, overrideColor) : colorScheme[index % colorScheme.length];
      }),
    [colorScheme, theme, normalizedPieChartData]
  );

  return {
    normalizedPieChartData,
    chartColor: colorSchemeWithOverrides,
  };
};
