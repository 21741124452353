import { FC, ReactNode } from 'react';
import ReactSelect from 'react-select';
import { Box, css, ThemeUIStyleObject, Label } from 'theme-ui';
import { getColor } from '@theme-ui/color';
import { useTheme } from '@power-ledger/hooks';

export type AttributeSelectorOptionType = FC;

export type AttributeSelectorOptGroupType = FC;

export type AttributeSelectorType = FC<{
  sx?: ThemeUIStyleObject;
  label: string;
  value?: any;
  onChange?: any;
  options: Array<any>;
  placeholder?: string | ReactNode;
  controlStyles?: any;
  disabled?: boolean;
}>;

const getSelectStyles = ({ theme = {}, controlStyles = {} }) => {
  const menu = (styles = {}) => ({
    ...styles,
    background: getColor(theme, 'foreground'),
  });

  const noOptionsMessage = (styles = {}) => ({
    ...styles,
    color: getColor(theme, 'text'),
  });

  const singleValue = (styles = {}) => ({
    ...styles,
    width: '100%',
    color: getColor(theme, 'text'),
  });

  const option = (_: any, state: any) =>
    css({
      ...(state.isSelected
        ? {
            variant: 'attributeSelector.primary.optionActive',
          }
        : { variant: 'attributeSelector.primary.option' }),
    })(theme);

  const control = () =>
    css({
      display: 'flex',
      variant: 'attributeSelector.primary.control',
      ...controlStyles,
    })(theme);

  const dropdownIndicator = (_: any, state: any) =>
    css({
      variant: 'attributeSelector.primary.dropdownIndicator',
      svg: {
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      },
    })(theme);

  const indicatorSeparator = () =>
    css({
      variant: 'attributeSelector.primary.indicatorSeparator',
    })(theme);

  return {
    control,
    singleValue,
    menu,
    option,
    dropdownIndicator,
    indicatorSeparator,
    noOptionsMessage,
  };
};

export const AttributeSelector: AttributeSelectorType = ({
  sx,
  label,
  placeholder,
  options,
  value,
  onChange,
  controlStyles,
  disabled,
}) => {
  const { theme } = useTheme();

  const selectStyles = getSelectStyles({ theme, controlStyles });

  return (
    <Box sx={sx}>
      <label hidden htmlFor={label}>
        {label}
      </label>
      <ReactSelect
        name={label}
        inputId={label}
        placeholder={placeholder}
        // @ts-ignore
        styles={selectStyles}
        isSearchable={false}
        isClearable={false}
        options={options}
        value={options.find((opt) => opt.value === value)}
        onChange={onChange}
        isDisabled={disabled}
      />
    </Box>
  );
};
