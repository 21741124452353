import { useTranslation } from 'react-i18next';
import { AssetType } from '../../../types';
import { AGGREGATED_METER_ID } from '../constants';
import { useFeatureSet } from './use-feature-set';

export const useAggregatedMeter = () => {
  const { hasProducer, hasConsumer, hasProsumer } = useFeatureSet();
  const { t } = useTranslation();
  let assetType = AssetType.PRODUCER;

  if ((hasConsumer && hasProducer) || hasProsumer) {
    assetType = AssetType.PROSUMER;
  }

  if (hasConsumer && !hasProducer) {
    assetType = AssetType.CONSUMER;
  }

  return {
    id: AGGREGATED_METER_ID,
    displayName: t('All Meters'),
    uid: null,
    assetType,
  };
};
