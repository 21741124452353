export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const UPDATE_BILLING_PERIOD = 'UPDATE_BILLING_PERIOD';
export const UPDATE_FUN_FACTS = 'UPDATE_FUN_FACTS';
export const UPDATE_TRANSACTION_SUMMARY = 'UPDATE_TRANSACTION_SUMMARY';
export const UPDATE_LAST_TRANSACTION_SUMMARY = 'UPDATE_LAST_TRANSACTION_SUMMARY';
export const UPDATE_TRANSACTIONS_LIST = 'UPDATE_TRANSACTIONS_LIST';
export const UPDATE_LAST_TRANSACTIONS_LIST = 'UPDATE_LAST_TRANSACTIONS_LIST';
export const UPDATE_TOTAL_SAVINGS = 'UPDATE_TOTAL_SAVINGS';
export const UPDATE_LAST_TOTAL_SAVINGS = 'UPDATE_LAST_TOTAL_SAVINGS';
export const UPDATE_METER_RANK = 'UPDATE_METER_RANK';
export const UPDATE_SITE_WEATHER = 'UPDATE_SITE_WEATHER';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
