import {
  areDatesWithinOneDay,
  formatCsvDatetime,
  formatDateByPeriod,
  formatNiceDatetime,
  formatTimeToSeconds,
  formatTimeZone,
  parseDate,
  parseDateWithTimeZone,
  requestDateFormat,
  requestDatetimeFormat,
} from '../../lib/datetime-helpers';
import {
  capitalise,
  formatCurrency,
  formatAmount,
  formatStr,
  isIE,
  formatDecimalSeparator,
} from '../../lib/helpers';
import groupHasFeature from '../../lib/group-has-feature';
import { checkCommonUpdates, checkPaginationUpdates, checkSearchOptionUpdates } from './controls';

// constantly checks if child component needs to be re-rendered based on current state and props
export const checkRecordsUpdates = (currentProps, nextProps, currentState, nextState) => {
  if (
    checkCommonUpdates(currentProps, nextProps, currentState, nextState) ||
    currentState.isExporting !== nextState.isExporting
  ) {
    return true;
  }

  if (checkPaginationUpdates(currentState, nextState)) {
    return true; // pagination options updated
  }

  if (checkSearchOptionUpdates(currentState, nextState)) {
    return true; // pagination options updated
  }

  return (
    JSON.stringify(currentProps.blockchainTransaction) !== JSON.stringify(nextProps.blockchainTransaction)
  );
};

// all the trade type labels
export const tradeTypeLabels = (t) => [
  {
    type: ['GRID'],
    label: 'Grid',
    labelText: t('Grid'),
    legend: 'grid',
  },
  {
    type: ['GRID_BUY', 'CATCH_ALL_BUY'],
    label: 'Bought from grid',
    labelText: t('Bought from grid'),
    legend: 'grid',
  },
  {
    type: ['GRID_SELL', 'CATCH_ALL_SELL'],
    label: 'Sold to grid',
    labelText: t('Sold to grid'),
    legend: 'grid',
  },
  {
    type: ['P2P'],
    label: 'Peer to peer',
    labelText: t('Peer to peer'),
    legend: 'p2p',
  },
  {
    type: ['DYNAMIC'],
    label: 'Dynamic',
    labelText: t('Dynamic'),
    legend: 'p2p',
  },
  {
    type: ['LOYALTY_P2P'],
    label: 'Loyalty peer to peer',
    labelText: t('Loyalty peer to peer'),
    legend: 'lp2p',
  },
  {
    type: ['VPP_BUY', 'VPP_SELL'],
    label: 'VPP Event',
    labelText: t('VPP Event'),
    legend: 'vpp',
  },
  {
    type: ['ALLOCATION'],
    label: 'Renewable allocation',
    labelText: t('Renewable allocation'),
    legend: 'allocation',
  },
  {
    type: ['PPA'],
    label: 'Direct Trades',
    labelText: t('Direct Trades'),
    legend: 'Direct Trades',
  },
  {
    type: ['DYNAMIC_ALLOCATION'],
    label: 'Dynamic allocation',
    labelText: t('Dynamic allocation'),
    legend: 'Dynamic allocation',
  },
  {
    type: ['CATCH_UP_BUY', 'CATCH_UP_SELL'],
    label: 'Missed',
    labelText: t('Missed'),
    legend: 'missed',
  },
  {
    type: ['CORRECTION_GRID_BUY', 'CORRECTION_GRID_SELL'],
    label: 'Correction',
    labelText: t('Correction'),
    legend: 'correction',
  },
  {
    type: ['PER_DAY_FEE_OTHER', 'PER_DAY_FEE_AH', 'PER_DAY_FEE_PL'],
    label: 'Daily fee',
    labelText: t('Daily fee'),
  },
  {
    type: ['PER_WH_FEE_OTHER', 'PER_WH_FEE_AH', 'PER_WH_FEE_PL'],
    label: 'Energy fee',
    labelText: t('Energy fee'),
  },
  {
    type: ['SELF_TRADE'],
    label: 'Self Trade',
    labelText: t('Self trade'),
    legend: 'selftrade',
  },
  {
    type: ['COLLECTIVE_SELF_TRADE'],
    label: 'Collective Self Trade',
    labelText: t('Collective self trade'),
    legend: 'collective-selftrade',
  },
];

// application host's specific trade type labels
export const hostTradeTypeLabels = (t, meterGroup, isESTMK = false) =>
  tradeTypeLabels(t).filter(
    ({ label }) =>
      !!(
        (groupHasFeature('P2P', meterGroup) && label === 'Peer to peer') ||
        (groupHasFeature('PREFERENTIAL_TRADES', meterGroup) && label === 'Preferential trade') ||
        (groupHasFeature('RENEWABLES', meterGroup) && label === 'Renewable allocation') ||
        !['Peer to peer', 'Preferential trade', 'Renewable allocation', 'Grid'].includes(label)
      ) &&
      // TODO: This is a dirty solution to remove redundant trade types for ESTMK, remove this when the  backend API is ready
      (!isESTMK ||
        ![
          'Daily fee',
          'Dynamic allocation',
          'Renewable allocation',
          'Correction',
          'Energy fee',
          'Peer to peer',
          'Self Trade',
        ].includes(label))
  );

// converts the given raw trade types into labels
export const getTypeLabels = (filterTypes, t) => {
  const filters = filterTypes && filterTypes.length ? filterTypes.split(',').filter((val) => val) : [];
  return filters && filters.length ? tradeTypeLabels(t).filter(({ type }) => filters.includes(type[0])) : [];
};
// sorts the transactions by date-time followed by buyer, seller, rate and amount in ascending order
export const sortTransactions = (transactions = []) =>
  transactions.sort((a, b) => {
    const buyerA = formatStr(a.buyer_name);
    const buyerB = formatStr(b.buyer_name);
    const sellerA = formatStr(a.seller_name);
    const sellerB = formatStr(b.seller_name);

    return (
      formatTimeToSeconds(a.time) - formatTimeToSeconds(b.time) ||
      buyerA.localeCompare(buyerB, undefined, { numeric: true }) ||
      sellerA.localeCompare(sellerB, undefined, { numeric: true }) ||
      a.type.localeCompare(b.type, undefined, { numeric: true }) ||
      a.rate - b.rate ||
      a.amount - b.amount
    );
  });

// formats transactions data
// passes a unique key per reading
// formats the date to display date and time
// math rounding for amount_kw
export const mapTransactions = (
  transactions = [],
  doSort = false,
  t,
  timeZone,
  meterGroup,
  preserveDecimals = false,
  isSearch = false
) => {
  let hostTradeTypes = hostTradeTypeLabels(t, meterGroup).map(({ type }) => type);

  if (isIE) {
    // Alternative method as IE does not support flat()
    hostTradeTypes = hostTradeTypes.reduce((acc, val) => acc.concat(val), []);
  } else {
    hostTradeTypes = hostTradeTypes.flat();
  }

  const mappedTransactions = transactions
    .filter(({ type }) => hostTradeTypes.indexOf(type) !== -1)
    .map((val) => {
      if (isSearch) {
        return val;
      }

      return {
        ...val,
        amountKw: val.amount_kw,
        amountNetTax: val.amount_net_tax,
        amountTax: val.amount_tax,
        buyerId: val.buyer_id,
        buyerName: val.buyer_name,
        sellerId: val.seller_id,
        sellerName: val.seller_name,
      };
    })
    .map((val, key) => {
      const {
        type,
        time,
        rate,
        amountKw,
        amount,
        amountNetTax,
        amountTax,
        buyerId,
        buyerName,
        sellerId,
        sellerName,
      } = val;

      let tradeTypeLabel = '';

      if (type && type.length) {
        tradeTypeLabel = hostTradeTypeLabels(t, meterGroup).filter(
          (hostTradeType) => hostTradeType.type.indexOf(type) !== -1
        );

        if (tradeTypeLabel.length) {
          tradeTypeLabel = tradeTypeLabel[0].labelText;
        } else {
          const formattedTradeType = type.replace('_', ' ').toLowerCase();
          tradeTypeLabel = capitalise(formattedTradeType);
        }
      }

      return {
        ...val,
        key,
        type: tradeTypeLabel,
        rawType: type,
        date: formatCsvDatetime(time, timeZone),
        ...(preserveDecimals
          ? {
              rate: formatDecimalSeparator(rate),
              amount_kw: formatDecimalSeparator(amountKw),
              amount: formatDecimalSeparator(amount),
              amount_net_tax: formatDecimalSeparator(amountNetTax),
              amount_tax: formatDecimalSeparator(amountTax),
            }
          : {
              rate: formatAmount(rate),
              amount_kw: formatAmount(amountKw),
              amount: formatCurrency(amount),
              amount_net_tax: formatCurrency(amountNetTax),
              amount_tax: formatCurrency(amountTax),
            }),
        buyer_id: buyerId && buyerId.length ? buyerId : '',
        buyer_name: buyerName && buyerName.length ? buyerName : '',
        seller_id: sellerId && sellerId.length ? sellerId : '',
        seller_name: sellerName && sellerName.length ? sellerName : '',
      };
    });

  return doSort ? sortTransactions(mappedTransactions) : mappedTransactions;
};

// groups the transactions by date and fills each unique trade in a row item
// to be used in stacked area charts
export const mapStackTransactions = ({
  meterGroup,
  period: p,
  startDate,
  endDate,
  intervals,
  unit,
  tradeType,
  activeLegends,
}) => {
  const { timeZone, settlementInterval } = meterGroup;
  const groupHasP2p = groupHasFeature('P2P', meterGroup);
  const groupHasRenewables = groupHasFeature('RENEWABLES', meterGroup);
  const groupHasVpp = groupHasFeature('VPP', meterGroup);

  const timeDiff = parseDate(endDate).diff(parseDate(startDate)).milliseconds;
  const decimals = unit === 'amount' ? 2 : 3;

  // custom period selected, determines whether to show every interval similar to past
  // or show aggregated dates similar to week/month
  const customCondition = !!(p === 'custom' && !areDatesWithinOneDay(startDate, endDate));
  const period = p !== 'custom' || customCondition ? p : 'past';

  // if selected period is meant to be grouped by dates
  const nonInterval = !!(period === 'week' || period === 'month' || customCondition);

  const stackedTransactions = [];

  // sort the transactions by time in ascending order and fetch start and end transactions datetimes
  // calculate number of transactions that occur between start and end using the interval
  // initialise and populate the stacked transactions with interval datetimes from start to end
  let startTrade = formatTimeZone(startDate, timeZone);
  const mInterval = Math.round(settlementInterval / 60); // in minutes
  let intervalPoints = timeDiff > 0 ? Math.ceil(timeDiff / (settlementInterval * 1000)) : 0;

  if (nonInterval) {
    const totalIntervalsPerDay = 86400 / settlementInterval;
    intervalPoints = Math.ceil(intervalPoints / totalIntervalsPerDay);
  } else {
    const parsedStartTrade = parseDateWithTimeZone(startTrade, timeZone);
    startTrade = parsedStartTrade.plus({ minutes: mInterval }).toFormat(requestDatetimeFormat);
  }

  for (let i = 0; i < intervalPoints; i++) {
    if (i === 0 && nonInterval) {
      startTrade = parseDateWithTimeZone(startTrade, timeZone).toFormat(`${requestDateFormat}'T'00:00:00ZZ`);
    }

    stackedTransactions.push({
      date: formatDateByPeriod(startTrade, period, timeZone),
      niceDate: formatNiceDatetime(startTrade, timeZone),
      total: null,
    });

    startTrade = nonInterval
      ? parseDateWithTimeZone(startTrade, timeZone).plus({ day: 1 }).toFormat(requestDatetimeFormat)
      : parseDateWithTimeZone(startTrade, timeZone)
          .plus({ minutes: mInterval })
          .toFormat(requestDatetimeFormat);
  }

  intervals.forEach((interval) => {
    const { dateTime, transactions } = interval;
    const formattedDate = formatDateByPeriod(dateTime, period, timeZone);
    const txIdx = stackedTransactions.findIndex((val) => val.date === formattedDate);

    if (txIdx !== -1) {
      transactions
        .filter(
          ({ type }) =>
            !!(
              (activeLegends.indexOf('grid') !== -1 && ['GRID', 'CATCH_ALL'].includes(type)) ||
              (activeLegends.indexOf('missed') !== -1 && type === 'CATCH_UP') ||
              (activeLegends.indexOf('ppa') !== -1 && type === 'PPA') ||
              (activeLegends.indexOf('p2p') !== -1 && groupHasP2p && ['P2P', 'DYNAMIC'].includes(type)) ||
              (activeLegends.indexOf('allocation') !== -1 && groupHasRenewables && type === 'ALLOCATION') ||
              (activeLegends.indexOf('vpp') !== -1 && groupHasVpp && type === 'VPP')
            )
        )
        .forEach((transaction) => {
          const { type, boughtAmount, boughtKw, soldAmount, soldKw } = transaction;
          const existingTx = stackedTransactions[txIdx];
          let txType = '';

          if (['GRID', 'CATCH_ALL'].includes(type)) {
            txType = 'grid';
          } else if (['P2P', 'DYNAMIC'].includes(type)) {
            txType = 'p2p';
          } else if (type === 'CATCH_UP') {
            txType = 'missed';
          } else if (type === 'ALLOCATION') {
            txType = 'allocation';
          } else if (type === 'VPP') {
            txType = 'vpp';
          } else if (type === 'PPA') {
            txType = 'ppa';
          }

          let typeAmount;

          if (tradeType === 'Bought') {
            typeAmount = unit === 'usage' ? formatAmount(boughtKw, 3) : formatAmount(boughtAmount, 2);
          } else {
            typeAmount = unit === 'usage' ? formatAmount(soldKw, 3) : formatAmount(soldAmount, 2);
          }

          stackedTransactions[txIdx] = {
            ...existingTx,
            [txType]: existingTx[txType]
              ? formatAmount(existingTx[txType] + typeAmount, decimals)
              : typeAmount,
            total: formatAmount(existingTx.total + typeAmount, decimals),
          };
        });
    }
  });

  return stackedTransactions;
};

// maps the trade infos based on the name and returns the trade object
export const mapTradeInfo = (tradeInfo = []) => {
  const defaults = { bought: null, imported: null, sold: null, exported: null };
  let tradeTypes = {
    p2pTrade: defaults,
    gridTrade: defaults,
    renewablesTrade: defaults,
    vppTrade: defaults,
    lp2pTrade: defaults,
    ppaTrade: defaults,
    dailyFeeAH: defaults,
    transactionFeeAH: defaults,
    dailyFeePL: defaults,
    transactionFeePL: defaults,
    dailyFeeOther: defaults,
    transactionFeeOther: defaults,
  };

  tradeInfo.forEach((val) => {
    if (val.name && val.name.length) {
      switch (val.name.toLowerCase()) {
        case 'p2p':
          tradeTypes = { ...tradeTypes, p2pTrade: val };
          break;
        case 'grid':
          tradeTypes = { ...tradeTypes, gridTrade: val };
          break;
        case 'allocation':
          tradeTypes = { ...tradeTypes, renewablesTrade: val };
          break;
        case 'vpp':
          tradeTypes = { ...tradeTypes, vppTrade: val };
          break;
        case 'loyalty_p2p':
          tradeTypes = { ...tradeTypes, lp2pTrade: val };
          break;
        case 'ppa':
          tradeTypes = { ...tradeTypes, ppaTrade: val };
          break;
        case 'per_wh_fee_ah':
          tradeTypes = { ...tradeTypes, transactionFeeAH: val };
          break;
        case 'per_day_fee_ah':
          tradeTypes = { ...tradeTypes, dailyFeeAH: val };
          break;
        case 'per_wh_fee_pl':
          tradeTypes = { ...tradeTypes, transactionFeePL: val };
          break;
        case 'per_day_fee_pl':
          tradeTypes = { ...tradeTypes, dailyFeePL: val };
          break;
        case 'per_wh_fee_other':
          tradeTypes = { ...tradeTypes, transactionFeeOther: val };
          break;
        case 'per_day_fee_other':
          tradeTypes = { ...tradeTypes, dailyFeeOther: val };
          break;
        default:
          break;
      }
    }
  });

  return tradeTypes;
};

// sorts the top 3 traders by amount in descending order and maps the traders value amount
export const mapTopTraders = (traders = []) =>
  traders
    .filter((val) => val.amountKw > 0)
    .sort((a, b) => b.amountKw - a.amountKw)
    .map((val) => ({
      label: val.meterDisplayName,
      value: val.amountKw,
      suffix: ` - ${val.percentageOfTotal}%`,
    }));
