/** @jsxImportSource theme-ui */
import { FC, memo } from 'react';
import { Heading } from 'theme-ui';
import { DAY_MONTH_SHORT, formatNiceTime, parseDateWithTimeZone } from '../../../lib/datetime-helpers';
import { useFeatureSet } from '../../../hooks/use-feature-set';

export type EnergyMixChartTooltipProps = {
  point: { indexValue: any; data: Record<string, number> };
  isDayFormat: boolean;
  timeZone: string;
  t: any;
  getLabel: (key: string) => string;
};

export const EnergyMixChartTooltip: FC<EnergyMixChartTooltipProps> = memo(
  ({ point: { indexValue, data }, isDayFormat, timeZone, t, getLabel }: EnergyMixChartTooltipProps) => {
    const Data = Object.entries(data).map(([key, val]) => {
      if (key === 'date') return null;
      return (
        <div key={key} sx={{ fontSize: 1 }}>
          <span sx={{ fontWeight: 'body' }}>{getLabel(key)}</span>&nbsp;
          <span>{Math.round(val * 100) / 100}</span>
        </div>
      );
    });
    const { useEUTimeFormat } = useFeatureSet();
    const date = parseDateWithTimeZone(indexValue, timeZone);

    return (
      <>
        <Heading as="h4">
          {t('transaction.chart.tooltip.time', {
            defaultValue: '{{date}}',
            replace: {
              date: isDayFormat
                ? formatNiceTime(indexValue, timeZone)
                : `${
                    useEUTimeFormat
                      ? `${date.toLocaleString({ day: 'numeric' })} ${date.toLocaleString({
                          month: 'short',
                        })}`
                      : date.toLocaleString(DAY_MONTH_SHORT)
                  } (${date.toLocaleString({
                    weekday: 'short',
                  })})`,
            },
          })}
        </Heading>
        {Data}
      </>
    );
  }
);
