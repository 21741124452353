import { alpha } from '@theme-ui/color';
import { ThemeUIStyleObject } from 'theme-ui';

interface GetDatePickerStylesProps {
  fullWidth?: boolean;
}

export const getDatePickerStyles = ({ fullWidth = false }: GetDatePickerStylesProps) => {
  const selectStyle: ThemeUIStyleObject = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'primary',
    borderRadius: '2px',
    padding: '8px 12px',
    appearance: 'none',
    outline: 'none',
    cursor: 'pointer',
    color: 'primaryDark',
    fontWeight: 500,
    transition: 'all 0.3s ease',
    '&:hover': {
      background: alpha('primary', 0.1),
    },
  };

  const containerSx: ThemeUIStyleObject = {
    '.react-datepicker-wrapper': {
      width: fullWidth ? '100%' : 'unset',
    },
    '.react-datepicker__input-container': {
      input: {
        height: '56px',
        fontWeight: 300,
        borderColor: 'greyLightest',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        fontSize: '0.875rem',
        boxShadow: 'none',
        outline: 'none',
        padding: '0 8px',
        width: '100%',
        transition: 'all 0.3s ease',
        '&:not(:disabled)': {
          '&:hover': {
            borderColor: 'primary',
          },
          '&:focus': {
            borderColor: 'primary',
          },
          '&:active': {
            borderColor: 'primary',
          },
        },
      },
    },
    '.react-datepicker-popper': {
      paddingTop: '4px',
    },
    '.react-datepicker': {
      '--shadow-color': 'rgba(0, 42, 88, 0.08)',
      padding: '16px',
      border: '1px solid #f0f0f0',
      boxShadow:
        '0px 2px 2px var(--shadow-color) , 0px 4px 4px var(--shadow-color) , 0px 8px 8px var(--shadow-color)',
      '.react-datepicker__day-names': {
        marginTop: '8px',
        '.react-datepicker__day-name': {
          fontWeight: 500,
          color: 'greyDarker',
        },
      },
      '.react-datepicker__header__dropdown': {
        display: 'flex',
        gap: '8px',
      },
      '.react-datepicker__navigation': {
        top: '16px',
        left: 'unset',
        borderRadius: '2px',
        transition: 'all 0.3s ease',
        '&:hover': {
          background: alpha('primary', 0.1),
        },
        '.react-datepicker__navigation-icon': {
          '&::before': {
            borderColor: 'primary',
            borderWidth: '2px 2px 0 0',
          },
        },
      },
      '.react-datepicker__navigation--next': {
        right: '16px',
        '.react-datepicker__navigation-icon': {
          '&::before': {
            left: '-6px',
          },
        },
      },
      '.react-datepicker__navigation--previous': {
        right: '52px',
        '.react-datepicker__navigation-icon': {
          '&::before': {
            right: '-6px',
          },
        },
      },
      '.react-datepicker__month-dropdown-container': {
        margin: 0,
        '.react-datepicker__month-select': selectStyle,
        '.react-datepicker__month-read-view--down-arrow': {
          display: 'none',
        },
      },
      '.react-datepicker__year-dropdown-container': {
        margin: 0,
        '.react-datepicker__year-select': selectStyle,
        '.react-datepicker__year-read-view--down-arrow': {
          display: 'none',
        },
      },
      '.react-datepicker__header': {
        padding: 0,
        border: 'none',
        background: 'none',
        '.react-datepicker__current-month': {
          display: 'none',
        },
        '.react-datepicker__month-read-view': {
          visibility: 'visible !important' as 'visible',
        },
        '.react-datepicker__year-read-view': {
          visibility: 'visible !important' as 'visible',
        },
      },
      '.react-datepicker__day': {
        fontWeight: 400,
        '&.react-datepicker__day--selected': {
          backgroundColor: 'primary',
        },
        '&.react-datepicker__day--outside-month:not(.react-datepicker__day--disabled)': {
          color: 'greyDark',
        },
      },
    },
  };

  return containerSx;
};
