import { memo } from 'react';

import { useHostSpecificTimezone } from './use-host-specific-timezone';
import { HostSpecificTimezoneView } from './host-specific-timezone.view';

export const HostSpecificTimezone = memo(() => {
  const logicProps = useHostSpecificTimezone();

  return <HostSpecificTimezoneView {...logicProps} />;
});
