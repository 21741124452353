import { memo } from 'react';

import { TransactionPieChartStatisticsProps } from './pie-chart-statistics.types';
import { usePieChartStatistics } from './use-pie-chart-statistics';
import { TransactionPieChartStatisticsView } from './pie-chart-statistics.view';

const TransactionPieChartStatisticsBase: React.FC<TransactionPieChartStatisticsProps> = ({
  unit,
  tradeType,
  symbol,
  transactionSummaries,
  isTATA,
}) => {
  const logicProps = usePieChartStatistics({ tradeType, unit, transactionSummaries, isTATA });

  return (
    <TransactionPieChartStatisticsView {...logicProps} unit={unit} tradeType={tradeType} symbol={symbol} />
  );
};

export const TransactionPieChartStatistics = memo(TransactionPieChartStatisticsBase);
