import { useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { Box } from 'theme-ui';
import { getColor } from '@theme-ui/color';

import { TradeUnit, TradeType } from '../../../../../types';
import { ChartNavArrows } from '../chart-nav-arrows';
import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { useDynamicGraphTicks } from '../hooks';
import { ChartPlaceholder } from '../chart-placeholder';
import { checkIfChartDataIsValid, formatAxisLeftValue, determineTimeScale } from '../charts.utils';
import { useStandardLineChartConfig } from '../usage';
import { Choose, When } from '../..';
import { useAppSelector } from '../../../hooks/use-app-selector';
import { communityUsageComparisonChartColorScheme } from '../../../styles/colors';
import { useTheme } from '../../../hooks/use-theme';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useScreenSize } from '../../../hooks/use-screen-size';
import { useCommunityUsageData } from './useCommunityUsageData';
import { DataType, useFormattedCommunityUsageData } from './useFormattedCommunityUsageData';
import { CommunityUsageChartTooltip } from './CommunityUsageChartTooltip';
import { useCustomizedLegend } from './use-customized-legend';

const styleById: any = {
  default: {
    strokeWidth: 2,
  },
};

const DashedLine = ({ series, lineGenerator, xScale, yScale }: any) =>
  series.map(({ id, data, color }: any) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d: any) => ({
          x: d.data.x ? xScale(d.data.x) : null,
          y: d.data.y ? yScale(d.data.y) : null,
        }))
      )}
      fill="none"
      stroke={color}
      style={styleById[id] || styleById.default}
    />
  ));

export const CommunityUsageComparisonChart = ({
  tradeType,
  onNextTimeFramePeriodOffset,
  onPreviousTimeFramePeriodOffset,
  loading = false,
  isAdmin = false,
}: {
  tradeType: TradeType;
  onNextTimeFramePeriodOffset: () => void;
  onPreviousTimeFramePeriodOffset: () => void;
  loading?: boolean;
  isAdmin?: boolean;
}) => {
  const { t } = useTranslation();
  const breakpointIndex = useBreakpointIndex();
  const { isSmallDown } = useScreenSize();

  const { theme } = useTheme();
  const { chartTheme, chartConfig } = useThemedColorScale();
  const { displayLegendShortDescription, useEUTimeFormat } = useFeatureSet();

  const chartColor = useMemo(
    () => ({
      /** "Meter current" and "meter previous 4 week average" */
      currentMeterColor: getColor(theme, communityUsageComparisonChartColorScheme.meterCurrentPeriod),
      currentGroupColor: getColor(theme, communityUsageComparisonChartColorScheme.meter4WeeksPrior),
      /** "Community current period" and "community previous 4 week average" */
      previousMeterColorArea: getColor(theme, communityUsageComparisonChartColorScheme.community4WeeksPrior),
      previousGroupColorArea: getColor(theme, communityUsageComparisonChartColorScheme.community4WeeksPrior),
    }),
    [theme]
  );

  const colorOverrideLineMap = useMemo(
    () => ({
      currentMeter: chartColor.currentMeterColor,
      currentGroup: chartColor.previousGroupColorArea,
      previousMeter: 'transparent',
      previousGroup: 'transparent',
    }),
    [chartColor.currentMeterColor, chartColor.previousGroupColorArea]
  );

  const colorOverrideAreaMap = useMemo(
    () => ({
      currentMeter: 'transparent',
      currentGroup: 'transparent',
      previousMeter: chartColor.currentMeterColor,
      previousGroup: chartColor.previousMeterColorArea,
    }),
    [chartColor.currentMeterColor, chartColor.previousMeterColorArea]
  );

  const colorOverrideLegendMap = useMemo(
    () => ({
      currentMeter: chartColor.currentMeterColor,
      currentGroup: chartColor.previousGroupColorArea,
      previousMeter: chartColor.currentGroupColor,
      previousGroup: chartColor.previousMeterColorArea,
    }),
    [
      chartColor.currentMeterColor,
      chartColor.previousGroupColorArea,
      chartColor.currentGroupColor,
      chartColor.previousMeterColorArea,
    ]
  );

  const getChartLayers = (someChartsWithValidData: boolean) => {
    if (someChartsWithValidData) {
      return ['grid', 'markers', 'axes', 'areas', DashedLine, 'slices', 'points', 'mesh', 'legends'];
    }

    return ['markers', 'axes', 'legends'];
  };

  const { timeZone, meterTrades, lastMeterTrades, groupTrades, lastGroupTrades } = useCommunityUsageData();

  const { ref, chunkSize, width } = useDynamicGraphTicks(
    meterTrades.length > groupTrades.length ? meterTrades : groupTrades,
    chartConfig.line.tickWidth
  );
  useCustomizedLegend(ref, displayLegendShortDescription);

  const { selectedMeter, startDate, endDate } = useAppSelector(
    ({ controls }) => ({
      period: controls.period,
      startDate: controls.startDate,
      endDate: controls.endDate,
      selectedMeter: controls.selectedMeter,
    }),
    shallowEqual
  );

  const shouldDisplayGroupData = isAdmin && !selectedMeter;

  const [hiddenIds, setHiddenIds] = useState(
    shouldDisplayGroupData ? [] : ['previousMeter', 'previousGroup']
  );

  const { data, legendData, types } = useFormattedCommunityUsageData({
    lastMeterTrades,
    meterTrades,
    groupTrades,
    lastGroupTrades,
    hiddenIds,
    timeZone,
    chunkSize,
    tradeType,
    shouldDisplayGroupData,
    t,
  });

  const { isDayFormat, ...xScaleProps } = useMemo(
    () =>
      determineTimeScale({
        startDate,
        endDate,
        timeZone,
        width,
        numValues: data.length,
        isMobile: isSmallDown,
        isEUTimeFormat: useEUTimeFormat,
      }),
    [data, endDate, startDate, timeZone, width, isSmallDown, useEUTimeFormat]
  );

  const doesChartHaveValidData = useMemo(
    () => data.some((item) => checkIfChartDataIsValid(item.data)),
    [data]
  );

  const { legends, ...lineProps } = useStandardLineChartConfig({
    width,
    legendData,
  });

  const { colorSchemeWithOverridesLegend } = useMemo(
    () =>
      legends.reduce(
        (acc: any, { legendData: legendDataSubSet }: any) => {
          legendDataSubSet.forEach(({ id }: any) => {
            acc.colorSchemeWithOverridesLegend.push(colorOverrideLegendMap[id as DataType]);
          });
          return acc;
        },
        {
          colorSchemeWithOverridesLegend: [],
        }
      ),
    [legends, colorOverrideLegendMap]
  );

  const colorSchemeWithOverridesLine = useMemo(
    () => data.map(({ id }) => colorOverrideLineMap[id as DataType]),
    [data, colorOverrideLineMap]
  );

  const chartLegends = useMemo(
    () =>
      legends.map(({ legendData: legendDataSubSet, ...legend }: any) => ({
        ...legend,
        translateX: isSmallDown && !doesChartHaveValidData ? 0 : legend.translateX,
        data: legendDataSubSet.map(({ id, label }: any) => ({
          id,
          label,
          color: hiddenIds.includes(id)
            ? 'rgba(1, 1, 1, .1)'
            : colorSchemeWithOverridesLegend[types.indexOf(id)],
        })),
        onClick: (datum: any) =>
          setHiddenIds((state) =>
            state.includes(datum.id) ? state.filter((item) => item !== datum.id) : [...state, datum.id]
          ),
      })),
    [colorSchemeWithOverridesLegend, doesChartHaveValidData, hiddenIds, isSmallDown, legends, types]
  );

  const Tooltip = useMemo(
    () => (point: any) =>
      (
        <CommunityUsageChartTooltip
          {...point}
          isDayFormat={isDayFormat}
          timeZone={timeZone}
          fullData={data}
          isEUTimeFormat={useEUTimeFormat}
        />
      ),
    [data, isDayFormat, timeZone, useEUTimeFormat]
  );

  const hideChartData = loading || !doesChartHaveValidData;
  const chartData = hideChartData ? [] : data;

  return (
    <Box
      sx={{
        height: 350,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: isSmallDown ? 4 : 3,
      }}
      ref={ref}
      data-testid="community-usage-chart"
    >
      <Choose>
        <When condition={loading}>
          <ChartPlaceholder label="Loading..." />
        </When>
        <When condition={!doesChartHaveValidData}>
          <ChartPlaceholder />
        </When>
      </Choose>
      <ResponsiveLine
        {...lineProps}
        margin={{ ...lineProps.margin, left: isSmallDown && !doesChartHaveValidData ? 0 : 80, right: 40 }}
        colors={colorSchemeWithOverridesLine}
        defs={[
          linearGradientDef('noGradient', [
            { offset: 0, color: 'inherit', opacity: 0 },
            { offset: 0.1, color: 'inherit', opacity: 0 },
          ]),
          linearGradientDef('previousMeter', [
            {
              offset: 0,
              color: colorOverrideAreaMap.previousMeter,
              opacity: 0.6,
            },
            {
              offset: 100,
              color: colorOverrideAreaMap.previousMeter,
              opacity: 0,
            },
          ]),
          linearGradientDef('previousGroup', [
            {
              offset: 0,
              color: colorOverrideAreaMap.previousGroup,
              opacity: 0.6,
            },
            {
              offset: 100,
              color: colorOverrideAreaMap.previousGroup,
              opacity: 0,
            },
          ]),
        ]}
        fill={[
          { match: (d) => d.id === 'previousMeter', id: 'previousMeter' },
          { match: (d) => d.id === 'previousGroup', id: 'previousGroup' },
          { match: (d) => !d.id.includes('previous'), id: 'noGradient' },
        ]}
        {...xScaleProps}
        axisLeft={{
          ...lineProps.axisLeft,
          tickValues: 5,
          format: (value) => formatAxisLeftValue(value, TradeUnit.USAGE),
        }}
        areaOpacity={0.75}
        gridYValues={5}
        data={chartData}
        theme={chartTheme}
        tooltip={Tooltip}
        legends={chartLegends}
        layers={getChartLayers(doesChartHaveValidData)}
      />

      <ChartNavArrows
        onClickLeft={onPreviousTimeFramePeriodOffset}
        onClickRight={onNextTimeFramePeriodOffset}
        placeNavArrowsBelowChart={isSmallDown}
        disabled={loading}
        style={{ opacity: loading ? 0.35 : 1 }}
      />
    </Box>
  );
};
