import { UPDATE_BLOCKCHAIN_TRANSACTION, UPDATE_BLOCKCHAIN_TRANSFER } from '../constants';
import { initialState } from '../store/initial-state';

// Blockchain reducer
const blockchain = (state = initialState.blockchain, action) => {
  switch (action.type) {
    case UPDATE_BLOCKCHAIN_TRANSACTION:
      return {
        ...state,
        transaction: action.transaction,
      };
    case UPDATE_BLOCKCHAIN_TRANSFER:
      return {
        ...state,
        transfer: action.transfer,
      };
    default:
      return state;
  }
};

export default blockchain;
