import { FC } from 'react';
import RCTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';

import { ChevronDown } from '../icons';

export type TimePickerProps = {
  value: any;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
  showSecond?: boolean;
  label?: string;
  sx?: ThemeUIStyleObject;
};
export type TimePickerType = FC<TimePickerProps>;

export const TimePicker: TimePickerType = ({
  value,
  showSecond = false,
  label,
  placeholder,
  onChange,
  disabled,
  format,
  sx,
}) => (
  <Flex sx={{ alignItems: 'center', ...sx }}>
    {label && (
      <Box
        sx={{
          fontSize: 0,
          color: 'textDarker',
          fontWeight: 'medium',
          mr: 2,
          fontFamily: 'MintGroteskV08',
        }}
      >
        {label}
      </Box>
    )}
    <Box sx={{ maxWidth: !showSecond ? '50px' : '70px', position: 'relative' }}>
      <RCTimePicker
        value={value}
        format={format}
        showSecond={showSecond}
        placeholder={placeholder}
        allowEmpty={false}
        disabled={disabled}
        onChange={onChange}
      />
      <Box sx={{ position: 'absolute', top: 0, right: 0, pointerEvents: 'none' }}>
        <ChevronDown size={4} color="text" />
      </Box>
    </Box>
  </Flex>
);
