import { createSlice } from '@reduxjs/toolkit';

import { updateMeterGroup, getMeterGroup } from './meters';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    features: [],
  },
  extraReducers: {
    [updateMeterGroup]: (state, { payload }) => {
      state.features = payload.updatedMeterGroup.features;
    },
    [getMeterGroup.fulfilled]: (state, { payload }) => {
      state.features = payload.updatedMeterGroup.features;
    },
  },
});

export default pageSlice.reducer;
