export const UPDATE_VPP_MODE = 'UPDATE_VPP_MODE';
export const UPDATE_VPP_CONFIG = 'UPDATE_VPP_CONFIG';
export const UPDATE_VPP_PRICE_THRESHOLD = 'UPDATE_VPP_PRICE_THRESHOLD';
export const UPDATE_BATTERY_FLEET = 'UPDATE_BATTERY_FLEET';
export const UPDATE_CLUSTER_SUMMARY = 'UPDATE_CLUSTER_SUMMARY';
export const UPDATE_BATTERY_SUMMARY = 'UPDATE_BATTERY_SUMMARY';
export const UPDATE_VPP_PRICES = 'UPDATE_VPP_PRICES';
export const UPDATE_DERM_EVENTS = 'UPDATE_DERM_EVENTS';
export const UPDATE_EVENT_RECORDS = 'UPDATE_EVENT_RECORDS';
export const UPDATE_VPP_SUMMARY = 'UPDATE_VPP_SUMMARY';
export const RESET_BATTERY_SHARING = 'RESET_BATTERY_SHARING';
