import i18nBase, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { DateTime, Settings } from 'luxon';
import numeral from 'numeral';
// @ts-ignore
import LastUsed from 'locize-lastused';
// @ts-ignore
import { locizePlugin } from 'locize';
import isChromatic from 'chromatic';
import {
  CI_MODE,
  isProduction,
  LOCIZE_APIKEY,
  LOCIZE_PROJECTID,
  LOCIZE_REFLNG,
  LOCIZE_VERSION,
} from './envHelpers';

export const fallbackLng = 'en';
export const languageKey = 'lang';

type DateTimeFormat =
  | 'DATETIME_FULL'
  | 'DATETIME_FULL_WITH_SECONDS'
  | 'DATETIME_HUGE'
  | 'DATETIME_HUGE_WITH_SECONDS'
  | 'DATETIME_MED'
  | 'DATETIME_MED_WITH_SECONDS'
  | 'DATETIME_MED_WITH_WEEKDAY'
  | 'DATETIME_SHORT'
  | 'DATETIME_SHORT_WITH_SECONDS'
  | 'DATE_FULL'
  | 'DATE_HUGE'
  | 'DATE_MED'
  | 'DATE_MED_WITH_WEEKDAY'
  | 'DATE_SHORT'
  | 'TIME_24_SIMPLE'
  | 'TIME_24_WITH_LONG_OFFSET'
  | 'TIME_24_WITH_SECONDS'
  | 'TIME_24_WITH_SHORT_OFFSET'
  | 'TIME_SIMPLE'
  | 'TIME_WITH_LONG_OFFSET'
  | 'TIME_WITH_SECONDS'
  | 'TIME_WITH_SHORT_OFFSET';

const locizeOptions = {
  projectId: LOCIZE_PROJECTID as string,
  apiKey: LOCIZE_APIKEY as string, // YOU should not expose your apps API key to production!!!
  referenceLng: LOCIZE_REFLNG as string,
  version: LOCIZE_VERSION as string,
  allowedAddOrUpdateHosts: [
    'localhost',
    'staging-projectpear.powerledger.io',
    'staging-vb.powerledger.io',
    'staging-energie.powerledger.io',
    'staging-evermore.powerledger.io',
    'staging-apn.powerledger.io',
    'staging-tpddl.powerledger.io',
    'staging-vpp-demo.powerledger.io',
    'staging-slabtarrifs.powerledger.io',
    'staging-ekwateur.powerledger.io',
    'dev-vb.powerledger.io',
    'dev-evermore.powerledger.io',
    'dev-tpddl.powerledger.io',
    'dev-atrdevtest.powerledger.io',
    'dev-vpp-demo.powerledger.io',
    'dev-renewnexus.powerledger.io',
    'dev-apn.powerledger.io',
    'dev-dehavilland.powerledger.io',
    'dev-geny.powerledger.io',
    'dev-readings-secure.powerledger.io',
    'dev-shac.powerledger.io',
    'dev-energie.powerledger.io',
    'dev-energie-test.powerledger.io',
    'dev-ekwateur.powerledger.io',
    'dev-smartcommunity.powerledger.io',
    'energie-test.powerledger.io',
  ],
};

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18nBase.use(LastUsed);
}

export interface I18n extends i18n {
  /**
   * @deprecated You don't need to use this function
   */
  _: (a: unknown) => typeof a;
}

export const getBaseLanguage = (lng: string) => lng.split('-')[0];

i18nBase
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18nBase instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    returnObjects: true,
    nsSeparator: false,
    keySeparator: false,
    debug: !isProduction(),
    lng: CI_MODE || isChromatic() ? 'cimode' : undefined,
    fallbackLng,
    saveMissing: !isProduction(), // you should not use saveMissing in production
    // keySeparator: false,
    interpolation: {
      format: (value, format, lng) => {
        if (value instanceof Date) {
          return DateTime.fromJSDate(value)
            .setLocale(lng ? getBaseLanguage(lng) : fallbackLng)
            .toLocaleString(format ? DateTime[format as DateTimeFormat] : DateTime.DATE_SHORT);
        }
        return value;
      },
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    detection: {
      lookupLocalStorage: languageKey,
      lookupSessionStorage: languageKey,
      caches: isProduction() ? ['sessionStorage'] : [],
    },
  });

/**
 * @deprecated You don't need to use this function
 */
(i18nBase as I18n)._ = (a) => a;

export const getCurrentLanguage = (currentI18n: i18n, ns = 'translation') =>
  (currentI18n?.languages || []).find(
    (lng) => Object.keys(currentI18n.store?.data?.[lng]?.[ns] || {}).length > 0
  ) || fallbackLng;

export const changeNumeralLocale = (lng: string) => {
  const baseLng = getBaseLanguage(lng);
  numeral.locale(baseLng);
  if (!numeral.localeData()) {
    numeral(numeral.locale(fallbackLng));
  }
};

export const changeI18nLanguage = (language: string) => {
  i18nBase.changeLanguage(language);
};

i18nBase.on('languageChanged', (lng) => {
  const baseLng = getBaseLanguage(lng);
  changeNumeralLocale(lng);

  Settings.defaultLocale = baseLng;
});

export default i18nBase as I18n;
