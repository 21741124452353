import { camelCase } from 'lodash';
import { shade, tint } from 'polished';
import { ColorModesScale } from 'theme-ui';

export type BasePalette = Record<keyof ColorModesScale, string>;

export const basePalette: BasePalette = {
  // ColorMode Definitions
  /**
   * Body background color
   */
  background: '#171638',

  /**
   * Body foreground color
   */
  text: '#FFFFFF',
  textDarker: '#9E99C8',

  /**
   * Primary brand color for links, buttons, etc.
   */
  primary: '#00CDDA',

  /**
   * A secondary brand color for alternative styling
   */
  secondaryLighter: '#B9B4E5',
  secondaryLight: '#837FAD',
  secondary: '#6C66FB',
  secondaryDark: '#504E78',
  secondaryDarker: '#393860',
  /**
   * A contrast color for emphasizing UI
   */
  accent: '#049AA4',

  /**
   * A background color for highlighting text
   */
  highlight: '#21DEAB',

  /**
   * A faint color for backgrounds, borders, and accents that do not require
   * high contrast with the background color
   */
  muted: '#EAEEEF',

  // Lem-ui Colors
  purple: '#b7a5ff',
  teal: '#27D8E5',
  red: '#ff0f0f',
  graphBackground: '#212247',
  lightBlue: '#648FFF',
  redOrange: '#FE6100',
  lightYellow: '#FFE14F',
  graphRed: '#DC267F',
  graphBlue: '#648FFF',

  // Define addition colors at your own risk, they may not be accept by all color theming systems
  grey: '#92a4b4',
  green: '#00D4A2',
  yellow: '#F99909',
  warning: '#FF909D',
  foreground: '#232245',
  positive: '#22DEAB',
  negative: '#E23145',
  negativeDarker: '#b52b3b',
  input: '#6A6793',
  inputLight: '#7d7aa9',
  inactiveLight: '#CFD8DC',
  inactiveDark: '#546E7A',
};

export type LightAdjustmentScale = {
  light: number;
  lighter: number;
  lightest: number;
};

export type DarkAdjustmentScale = {
  dark: number;
  darker: number;
  darkest: number;
};

export const adjustmentScale: LightAdjustmentScale & DarkAdjustmentScale = {
  light: 0.2,
  lighter: 0.3,
  lightest: 0.7,
  dark: 0.2,
  darker: 0.3,
  darkest: 0.7,
};

// Not the nicest but it makes the typing good
type ColorKeys =
  | 'backgroundDark'
  | 'textDark'
  | 'primaryDark'
  | 'secondaryDark'
  | 'accentDark'
  | 'highlightDark'
  | 'mutedDark'
  | 'greyDark'
  | 'warningDark'
  | 'backgroundDarker'
  | 'textDarker'
  | 'primaryDarker'
  | 'secondaryDarker'
  | 'accentDarker'
  | 'highlightDarker'
  | 'mutedDarker'
  | 'greyDarker'
  | 'warningDarker'
  | 'backgroundDarkest'
  | 'textDarkest'
  | 'primaryDarkest'
  | 'secondaryDarkest'
  | 'accentDarkest'
  | 'highlightDarkest'
  | 'mutedDarkest'
  | 'greyDarkest'
  | 'warningDarkest'
  | 'backgroundLight'
  | 'textLight'
  | 'primaryLight'
  | 'secondaryLight'
  | 'accentLight'
  | 'highlightLight'
  | 'mutedLight'
  | 'greyLight'
  | 'warningLight'
  | 'backgroundLighter'
  | 'textLighter'
  | 'primaryLighter'
  | 'secondaryLighter'
  | 'accentLighter'
  | 'highlightLighter'
  | 'mutedLighter'
  | 'greyLighter'
  | 'warningLighter'
  | 'backgroundLightest'
  | 'textLightest'
  | 'primaryLightest'
  | 'secondaryLightest'
  | 'accentLightest'
  | 'highlightLightest'
  | 'mutedLightest'
  | 'greyLightest'
  | 'warningLightest'
  | 'positive'
  | 'negative';

type FullPalette = Record<keyof BasePalette | ColorKeys, string>;

export const generateFullPalette = (palette: BasePalette): FullPalette =>
  Object.entries<string>(palette).reduce<FullPalette>((acc, [key, val]) => {
    /* eslint-disable no-param-reassign */
    acc[key as keyof FullPalette] = val;
    Object.entries(adjustmentScale).forEach(([scaleKey, scaleValue]) => {
      const adjustedColorKey = camelCase(`${key} ${scaleKey}`);
      if (scaleKey.startsWith('dark')) {
        acc[adjustedColorKey as keyof FullPalette] = shade(scaleValue)(val);
      } else {
        acc[adjustedColorKey as keyof FullPalette] = tint(scaleValue)(val);
      }
    });
    /* eslint-enable no-param-reassign */
    return acc;
  }, {} as FullPalette);

export const colors: FullPalette = generateFullPalette(basePalette);

export type Colors = FullPalette;
