import { shallowEqual, useSelector } from 'react-redux';
import type { ControlsState } from '../../../states/slices/controls';

export const useTimeFrameDateData = () => {
  const timeZone = useSelector(({ meters }: any) => meters.meterGroup.timeZone);
  const { startDate, endDate, period } = useSelector(
    ({ controls }: { controls: ControlsState }) => ({
      startDate: controls.startDate,
      endDate: controls.endDate,
      period: controls.period,
    }),
    shallowEqual
  );

  return { timeZone, startDate, endDate, period };
};
