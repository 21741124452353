import { Box, Flex, FlexProps } from 'theme-ui';

import { Children, FC, isValidElement } from 'react';

export type FooterContentProps = { children?: any };
export type FooterContentType = FC<FooterContentProps>;

export type FooterType = FC<FlexProps> & {
  Content: FooterContentType;
};

export const Footer: FooterType = ({ children }) => <Box sx={{ variant: 'layouts.footers' }}>{children}</Box>;

const FooterContent: FooterContentType = ({ children }) => (
  <Flex sx={{ flexDirection: ['column', null, 'row'], alignItems: 'center' }}>
    {Children.map(children, (child) => {
      if (isValidElement(child)) {
        return <Box sx={{ px: 2, mt: [2, null, 0] }}>{child}</Box>;
      }
      return null;
    })}
  </Flex>
);

Footer.Content = FooterContent;
