import { lazy } from 'react';

export const LogoWesternPowerSVGLazy = lazy(() => import('./logo-western-power'));
export const LogoSynergySVGLazy = lazy(() => import('./logo-synergy'));
export const LogoIcmSVGLazy = lazy(() => import('./logo-icm'));
export const LogoCurtinSVGLazy = lazy(() => import('./logo-curtin'));
export const LogoCleantechSVGLazy = lazy(() => import('./logo-cleantech'));
export const LogoBscSVGLazy = lazy(() => import('./logo-bsc'));
export const LogoPlSVGLazy = lazy(() => import('./logo-pl'));
export const LogoPlDeviceSVGLazy = lazy(() => import('./logo-pl-device'));
export { default as LogoPlDeviceSVG } from './logo-pl-device';
export const LogoPoweredByPlSVGLazy = lazy(() => import('./logo-powered-by-pl'));
