import React from 'react';
import PropTypes from 'prop-types';

const NotificationSuccess = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 30 31" fill="none">
    <g filter="url(#filter0_d)">
      <path
        d="M12.3834 17.9709L9.375 15L7.5 16.875L12.3875 21.5625L22.5 10.3125L20.6076
      8.4375L12.3834 17.9709Z"
        fill="white"
      />
      <path
        d="M15 0C6.71537 0 0 6.71537 0 15C0 23.2846 6.71537 30 15 30C23.2846 30 30 23.2846 30
      15C30 6.71537 23.2846 0 15 0ZM15 27.6562C8.00994 27.6562 2.34375 21.9901 2.34375 15C2.34375
      8.00994 8.00994 2.34375 15 2.34375C21.9901 2.34375 27.6562 8.00994 27.6562 15C27.6562 21.9901
      21.9901 27.6562 15 27.6562Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="30"
        height="31"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
        127 0"
        />
        <feOffset dy="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

NotificationSuccess.defaultProps = {
  width: '30px',
  height: '30px',
};

NotificationSuccess.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default NotificationSuccess;
