import React from 'react';
import PropTypes from 'prop-types';

export const CommunitySVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 9"
    aria-labelledby="title-community"
    aria-describedby="desc-community"
    role="img"
  >
    <title id="title-community">Community icon</title>
    <description id="desc-community">A symbol representing two people.</description>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1.000000, -4.000000)" fill="#636363">
        <path
          d="M12.2727273,9 C11.4000001,9 10.0400002,9.22666673 9,9.66666667
        C7.95999971,9.21999993 6.59999985,9 5.72727273,9 C4.14909085,9 1,9.71999993 1,11.1666667
        L1,13 L17,13 L17,11.1666667 C17,9.71999993 13.8509091,9 12.2727273,9 Z M9,12 L2,12
        L2,11.1666667 C2,10.8066667 3.79199996,10 5.5,10 C7.20800037,10 9,10.8066667 9,11.1666667
        L9,12 Z M16,12 L10.3890278,12 L10.3890278,11.1666667 C10.3890278,10.8599999
        10.2394019,10.5933335 10,10.3533331 C10.6583541,10.153333 11.4663341,10 12.2593519,10
        C14.0847885,10 16,10.8066667 16,11.1666667 L16,12 Z M5.5,9 C6.87857164,9 8,7.87857164 8,6.5
        C8,5.12142835 6.87857164,4 5.5,4 C4.12142835,4 3,5.12142869 3,6.5 C3,7.87857164 4.12142869,9
        5.5,9 Z M5.5,5 C6.32500028,5 7,5.67500007 7,6.5 C7,7.32500028 6.32500028,8 5.5,8
        C4.67499971,8 4,7.32500028 4,6.5 C4,5.67499971 4.67500007,5 5.5,5 Z M12.5,9 C13.8785716,9
        15,7.87857164 15,6.5 C15,5.12142835 13.8785716,4 12.5,4 C11.1214283,4 10,5.12142869 10,6.5
        C10,7.87857164 11.1214283,9 12.5,9 Z M12.5,5 C13.3250002,5 14,5.67500007 14,6.5
        C14,7.32500028 13.3250002,8 12.5,8 C11.6749997,8 11,7.32500028 11,6.5 C11,5.67499971
        11.6749997,5 12.5,5 Z"
        />
      </g>
    </g>
  </svg>
);

CommunitySVG.defaultProps = {
  width: '18px',
  height: '18px',
};

CommunitySVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
