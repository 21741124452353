import React from 'react';
import PropTypes from 'prop-types';

export const GridSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    aria-labelledby="title-grid"
    aria-describedby="desc-grid"
    role="img"
  >
    <title id="title-grid">Grid icon</title>
    <description id="desc-grid">A symbol representing a simple grid.</description>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, -2.000000)" fill="#636363">
        <path
          d="M14.6,2 L3.4,2 C2.63000006,2 2,2.63000006 2,3.4 L2,14.6 C2,15.3700002
        2.63000006,16 3.4,16 L14.6,16 C15.3700002,16 16,15.3700002 16,14.6 L16,3.4 C16,2.63000006
        15.3700002,2 14.6,2 Z M6.2,14.6 L3.4,14.6 L3.4,11.8 L6.2,11.8 L6.2,14.6 Z M6.2,10.4
        L3.4,10.4 L3.4,7.6 L6.2,7.6 L6.2,10.4 Z M6.2,6.2 L3.4,6.2 L3.4,3.4 L6.2,3.4 L6.2,6.2 Z
        M10.4,14.6 L7.6,14.6 L7.6,11.8 L10.4,11.8 L10.4,14.6 Z M10.4,10.4 L7.6,10.4 L7.6,7.6
        L10.4,7.6 L10.4,10.4 Z M10.4,6.2 L7.6,6.2 L7.6,3.4 L10.4,3.4 L10.4,6.2 Z M14.6,14.6
        L11.8,14.6 L11.8,11.8 L14.6,11.8 L14.6,14.6 Z M14.6,10.4 L11.8,10.4 L11.8,7.6 L14.6,7.6
        L14.6,10.4 Z M14.6,6.2 L11.8,6.2 L11.8,3.4 L14.6,3.4 L14.6,6.2 Z"
        />
      </g>
    </g>
  </svg>
);

GridSVG.defaultProps = {
  width: '18px',
  height: '18px',
};

GridSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
