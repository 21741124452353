import React from 'react';
import PropTypes from 'prop-types';

export const BinSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    aria-labelledby="title-bin"
    aria-describedby="desc-bin"
    role="img"
  >
    <title id="title-bin">Bin icon</title>
    <description id="desc-bin">A symbol representing a simple bin.</description>
    <path
      d="M0.927734 10.4683C0.927734 11.3141 1.63317 12 2.50354 12H7.49786C8.36798 12 9.07366
    11.3143 9.07366 10.4683V4.04304H0.927734V10.4683ZM2.03885 5.12304H7.9623V10.4686C7.9623 10.7175
    7.75391 10.9203 7.49761 10.9203H2.50354C2.24749 10.9203 2.03885 10.7177 2.03885
    10.4686V5.12304Z"
      fill="#ffffff"
    />
    <path
      d="M9.44444 1.88808H7.37185V1.08792C7.37185 0.48696 6.87086 0 6.25259 0H3.74741C3.12914 0
    2.62815 0.48696 2.62815 1.08792V1.88808H0.555556C0.248642 1.88808 0 2.13 0 2.42808C0 2.72616
    0.248642 2.96808 0.555556 2.96808H9.44444C9.75136 2.96808 10 2.7264 10 2.42808C10 2.12976
    9.75136 1.88808 9.44444 1.88808ZM3.73926 1.08792C3.73926 1.086 3.73951 1.08528 3.73951
    1.08528C3.74 1.08384 3.74321 1.08072 3.74469 1.08024C3.74469 1.08024 3.74543 1.08 3.74765
    1.08H6.25284C6.25482 1.08 6.25556 1.08024 6.25556 1.08024C6.25704 1.08072 6.26025 1.08384
    6.26074 1.08528C6.26074 1.08528 6.26099 1.086 6.26099 1.08816V1.88784H3.73926V1.08792Z"
      fill="#ffffff"
    />
  </svg>
);

BinSVG.defaultProps = {
  width: '10px',
  height: '12px',
};

BinSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
