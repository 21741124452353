import { useResize } from '../../../hooks/use-resize';

export const useDynamicGraphTicks = (data: any[] = [], tickWidth: number) => {
  const {
    dimensions: { width: actualWidth },
    ref,
  } = useResize();

  const width = actualWidth || 0;
  const numTicks = width / tickWidth;
  const chunkSize = Math.ceil(data.length / numTicks);

  return { ref, chunkSize, width };
};
