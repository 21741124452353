import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { parseDateWithTimeZone } from '../../../lib/datetime-helpers';
import { convertAndFormatUnitValue } from '../../../lib/unit';
import { isNumber } from '../../../utils/number';
import { WattHour } from '../../../../../types';
import { useFeatureSet } from '../../../hooks/use-feature-set';

export interface UsageChartTooltipProps {
  timeZone: string;
  importValue?: number;
  exportValue?: number;
  epochTime?: number;
}

export const UsageChartTooltip: React.FC<UsageChartTooltipProps> = ({
  importValue,
  exportValue,
  epochTime,
  timeZone,
}) => {
  const { t } = useTranslation();
  const date = parseDateWithTimeZone(epochTime, timeZone);
  const { useEUTimeFormat } = useFeatureSet();
  const convertedImportAmount = useMemo(
    () => convertAndFormatUnitValue(importValue, WattHour.kWh),
    [importValue]
  );
  const convertedExportAmount = useMemo(
    () => convertAndFormatUnitValue(exportValue, WattHour.kWh),
    [exportValue]
  );

  return (
    <>
      <div>
        {t('Date: {{date}}', {
          date: useEUTimeFormat
            ? `${date.toLocaleString({ day: 'numeric' })} ${date.toLocaleString({
                month: 'short',
              })}, ${date.toLocaleString({ year: 'numeric' })}`
            : date.toLocaleString(DateTime.DATE_FULL),
        })}
      </div>

      {isNumber(importValue) && (
        <div>
          {t('Import: {{amount}}', {
            amount: convertedImportAmount.formattedNewValue,
          })}
        </div>
      )}

      {isNumber(exportValue) && (
        <div>
          {t('Export: {{amount}}', {
            amount: convertedExportAmount.formattedNewValue,
          })}
        </div>
      )}
    </>
  );
};
