import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { Tooltip } from '..';
import { useFeatureSet } from '../../hooks/use-feature-set';
import { QuestionCircleOutlined } from '../icons';

export interface TaxIncludedProps {
  tooltip?: boolean;
  Container?: React.ElementType;
}

export const TaxIncluded: React.FC<TaxIncludedProps> = ({ tooltip, Container = Fragment }) => {
  const { t } = useTranslation();
  const { includesBuyerTax: includesBuyerTaxFlag, includesSellerTax: includesSellerTaxFlag } =
    useFeatureSet();
  const labels = useSelector(({ host }: any) => host.labels);

  const includesTax = (includesBuyerTaxFlag || includesSellerTaxFlag) && validateTaxParams(labels.tax);
  if (!includesTax) return null;

  const translatedTaxTest = t('All prices include {{percent}}% {{name}}', labels.tax);

  return (
    <Container>
      {tooltip ? (
        <Tooltip title={translatedTaxTest}>
          <QuestionCircleOutlined tabIndex={0} size={3} />
        </Tooltip>
      ) : (
        <Box variant="forms.label" color="textLighter">
          {translatedTaxTest}
        </Box>
      )}
    </Container>
  );
};

const validateTaxParams = (params?: { name: string; percent: string } | null) =>
  Boolean(params && params.name && params.percent);
