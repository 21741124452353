import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NoData } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { Table } from '..';

const columns = (t) => [
  {
    title: t('BATTERY SERIAL NUMBER'),
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: t('STATUS'),
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: t('CURRENT CHARGE (%)'),
    dataIndex: 'chargePercent',
    key: 'chargePercent',
    render: (record) => <span>{record !== null ? record : '-'}</span>,
  },
  {
    title: t('MAX POWER (kW)'),
    dataIndex: 'maxPower',
    key: 'maxPower',
    render: (record) => <span>{record !== null ? record : '-'}</span>,
  },
];

export const FleetRegisterTable = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const tableData = useMemo(
    () => data.slice(currentPage * currentPageSize - currentPageSize, currentPage * currentPageSize),
    [data, currentPage, currentPageSize]
  );
  const { t } = useTranslation();

  return (
    <Table
      locale={{
        emptyText: <NoData description={t('No assets found')} />,
      }}
      columns={columns(t)}
      dataSource={tableData}
      pagination={{
        pageIndex: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSize: currentPageSize,
        pageSizeOptions: ['10', '25', '50', '100'],
        total: data.length,
        showTotal: (total, range) => t(`${range[0]} - ${range[1]} of ${total}`),
        onChange: ({ current, pageSize }) => {
          if (currentPage !== current && current != null) setCurrentPage(current);
          if (currentPageSize !== pageSize) setCurrentPageSize(pageSize);
        },
      }}
    />
  );
};

FleetRegisterTable.defaultProps = {
  data: [],
};

FleetRegisterTable.propTypes = {
  data: PropTypes.array,
};
