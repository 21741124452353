import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { AssetType, Meter } from '../../../types';
import { useIsAdmin } from './use-is-admin';
import { useAppSelector } from './use-app-selector';

const groupHasFeature = (feature: string, features: string[]) => features.includes(feature);

export const useFeatureSet = () => {
  const assetType: AssetType = useAppSelector(
    ({ controls }) => controls?.selectedMeter?.assetType,
    shallowEqual
  );
  const meters = useAppSelector(({ meters: metersStore }: any) => metersStore.meters, shallowEqual);
  const loyaltyMeters = useAppSelector(({ loyaltyP2P }: any) => loyaltyP2P.meters, shallowEqual);
  const features: string[] = useAppSelector(
    ({ meters: metersStore }) => metersStore?.meterGroup?.features || [],
    shallowEqual
  );

  const { isAdmin } = useIsAdmin();

  return useMemo(
    () => ({
      hasCommunityChart: groupHasFeature('COMMUNITY_CHART', features),
      hasCommunityStats: groupHasFeature('COMMUNITY_STATS', features),
      hasYesterdayStats: groupHasFeature('YESTERDAY_STATS', features),
      hasCommunityRanking: groupHasFeature('COMMUNITY_RANKING', features),
      hasVPP: groupHasFeature('VPP', features),
      hasSavings: groupHasFeature('SAVINGS', features),
      hasEstimates: groupHasFeature('ESTIMATES', features),
      hasPrefTrade: !!(
        groupHasFeature('PREFERENTIAL_TRADES', features) &&
        (isAdmin || [AssetType.CONSUMER, AssetType.PROSUMER, AssetType.PRODUCER].includes(assetType))
      ),
      hasProsumer: !!(meters && meters.some((meter: Meter) => meter.assetType === AssetType.PROSUMER)),
      hasConsumer: !!(meters && meters.some((meter: Meter) => meter.assetType === AssetType.CONSUMER)),
      hasProducer: !!(meters && meters.some((meter: Meter) => meter.assetType === AssetType.PRODUCER)),
      hasPricing: groupHasFeature('PRICING', features),
      hasNotifications: groupHasFeature('NOTIFICATIONS', features),
      hasRankings: groupHasFeature('STATE_RANKINGS', features),
      hasFAQ: groupHasFeature('FAQ', features),
      hasCommunityMap: groupHasFeature('COMMUNITY_MAP', features),
      hasIncludeEstimates: groupHasFeature('ESTIMATES', features),
      hasP2P: groupHasFeature('P2P', features),
      hasRenewables: groupHasFeature('RENEWABLES', features),
      hasEnergyMix: groupHasFeature('ENERGY_MIX', features),
      includesBuyerTax: groupHasFeature('INCLUDES_TAX', features),
      includesSellerTax: false, // Stubbed
      defaultPrivacyPolicy: groupHasFeature('DEFAULT_PRIVACY_POLICY', features),
      hasEnergyFacts: groupHasFeature('ENERGY_FACTS', features),
      defaultTermsOfAccess: groupHasFeature('DEFAULT_TERMS_OF_ACCESS', features),
      hasLP2P: groupHasFeature('LOYALTY_P2P', features),
      hasLP2PMeter: !!(
        loyaltyMeters &&
        loyaltyMeters.some((loyaltyMeter: Record<string, unknown>) => loyaltyMeter.canOfferLoyaltyP2p)
      ),
      hasStatementOfUse: groupHasFeature('STATEMENT_OF_USE', features),
      hasGridEquivalentComparison: groupHasFeature('GRID_EQUIVALENT_COMPARISON', features),
      hasDisplayFeesAdmin: groupHasFeature('DISPLAY_FEES_ADMIN', features),
      hasIOAManagement: groupHasFeature('IOA_MANAGEMENT', features),
      hasExcessDemand: groupHasFeature('AGGREGATED_EXCESS_DEMAND', features),
      hasExcessEnergy: groupHasFeature('AGGREGATED_EXCESS_ENERGY', features),
      hideBoughtSoldDashboard: groupHasFeature('HIDE_BOUGHT_SOLD_DASHBOARD', features),
      hasCapDynamicP2PBuy: groupHasFeature('CAP_DYNAMIC_P2P_BUY', features),
      ppaUsesEnergyAmount: groupHasFeature('PPA_USES_ENERGY_AMOUNT', features),
      termsOfUseAccepted: groupHasFeature('TERMS_OF_USE_ACCEPTED', features),
      displayDatePickerYearOption: groupHasFeature('DISPLAY_DATE_PICKER_YEAR_OPTION', features),
      displayLegendShortDescription: groupHasFeature('DISPLAY_LEGEND_SHORT_DESCRIPTION', features),
      hidePerformanceHighlights: groupHasFeature('HIDE_PERFORMANCE_HIGHLIGHTS', features),
      showVisionEnergyMixPage: groupHasFeature('VISION_UI_YOUR_ENERGY_MIX_PAGE', features),
      showVisionBillingPage: groupHasFeature('VISION_UI_BILLING_PAGE', features),
      useEUTimeFormat: groupHasFeature('USE_EU_TIME_FORMAT', features),
      includesTaxInBillingCSV: groupHasFeature('INCLUDES_TAX_IN_BILLING_CSV', features),
      showNewDashboardPage: groupHasFeature('NEW_UI_DASHBOARD_PAGE', features),
      showNewUsagePage: groupHasFeature('NEW_UI_USAGE_PAGE', features),
      showNewTransactionsPage: groupHasFeature('NEW_UI_TRANSACTIONS_PAGE', features),
      showNewTradingPage: groupHasFeature('NEW_UI_TRADING_PAGE', features),
      showEndOfProjectSplashPage: groupHasFeature('END_OF_PROJECT', features),
      displayZeroCurrencyValueAsDash: groupHasFeature('DISPLAY_ZERO_CURRENCY_VALUE_AS_DASH', features),
      displaySetGridRateButton: groupHasFeature('DISPLAY_SET_GRID_RATE_BUTTON', features),
      hideDashboardPage: groupHasFeature('HIDE_DASHBOARD_PAGE', features),
      hideEnergyMixEmissionsTab: groupHasFeature('NEW_UI_HIDE_ENERGY_MIX_EMISSIONS_TAB', features),
      hideUsageRankingTab: groupHasFeature('NEW_UI_HIDE_USAGE_RANKING_TAB', features),
      hideTradingPeerToPeerTab: groupHasFeature('NEW_UI_HIDE_TRADING_PEER_TO_PEER_TAB', features),
      hideTradingPreferentialTradingTab: groupHasFeature(
        'NEW_UI_HIDE_TRADING_PREFERENTIAL_TRADING_TAB',
        features
      ),
      hideTradingOverviewTab: groupHasFeature('NEW_UI_HIDE_TRADING_OVERVIEW_TAB', features),
      hideTransactionsSavingsTab: groupHasFeature('NEW_UI_HIDE_TRANSACTIONS_SAVINGS_TAB', features),
      hideTransactionsTaxAndFeeTab: groupHasFeature('NEW_UI_HIDE_TRANSACTIONS_TAX_AND_FEE_TAB', features),
      hideTransactionsBlockchainRecordsTab: groupHasFeature(
        'NEW_UI_HIDE_TRANSACTIONS_BLOCKCHAIN_RECORDS',
        features
      ),
    }),
    [assetType, features, isAdmin, loyaltyMeters, meters]
  );
};
