import React from 'react';
import { useTranslation } from 'react-i18next';

import { Menu } from '../components';
import { capitalise, getUsername, hasMatchingText, sortItems } from './helpers';

// checks if the given secondary details
const isDetailKeyValid = (searchText, secondaryDetails, key, keyValue) =>
  !!(
    secondaryDetails.includes(key) &&
    keyValue &&
    !!keyValue.length &&
    hasMatchingText(searchText, keyValue)
  );

// given search text, list of secondary details and list of secondaries
// filter secondaries that contain any of the secondary details that matches the searched text
const getMatchingSecondaries = (searchText, secondaryDetails, secondaries) =>
  secondaries.filter(
    (v) => !!Object.keys(v).filter((x) => isDetailKeyValid(searchText, secondaryDetails, x, v[x])).length
  );

// given a list of secondaries, sort the properties for each secondary item based on the
// sortDetails function
const sortSecondaryKeys = (secondaries, sortDetails) =>
  secondaries.map((val) =>
    sortDetails(Object.keys(val)).reduce((acc, v) => {
      // eslint-disable-next-line no-param-reassign
      acc[v] = val[v];

      return acc;
    }, {})
  );

// given the search text and the item as the base
// loop through each character in searched text and highlight matches using 'match' className
const renderSearchedItem = (searchText, item) =>
  item.split('').map((v, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={key}>{v}</span>
  ));

// given the search text and meter details
// renders a list item containing the meter details
// primary details contains the meter display name
// secondary details contains the meter uid placed below the primary
export const renderMeterItem = (searchText, meter) => {
  const matchingPrimary = hasMatchingText(searchText, meter.displayName);
  const matchingSecondary = hasMatchingText(searchText, meter.uid);

  return (
    <div>
      <span>
        <span>{renderSearchedItem(searchText, meter.displayName)}</span>
      </span>
      {!matchingPrimary && matchingSecondary && <span>{renderSearchedItem(searchText, meter.uid)}</span>}
    </div>
  );
};

// given the searched text and a list of meter-user data sharing the same meter display name
// renders a list of item containing a mix of meter and user details
// primary details contains the meter display name
// secondary details contains a mix of meter and user details depending which one matches first
// the priority of secondary details is deteremined on line 61
export const renderMeterUserItem = (searchText, meterUsers) => {
  const matchingPrimary = hasMatchingText(searchText, meterUsers[0].meterDisplayName);

  // given a list of users sharing the same meter
  // filter matching secondary details of the same meter, disregarding the rest
  const secondaryDetails = ['meterUid', 'fullName', 'address'];
  const matchingSecondaries = getMatchingSecondaries(searchText, secondaryDetails, meterUsers);

  const sortDetails = (list) =>
    list.sort(
      (a, b) => b.indexOf('meterUid') - a.indexOf('meterUid') || b.indexOf('fullName') - a.indexOf('fullName')
    );

  const sortedSecondaries = sortSecondaryKeys(matchingSecondaries, sortDetails);

  let hasUid = false;

  return (
    <div>
      <span>
        <span>{renderSearchedItem(searchText, meterUsers[0].meterDisplayName)}</span>
        <div>
          <span>{meterUsers[0].meterActive ? 'Active' : 'Inactive'}</span>
        </div>
      </span>
      {!matchingPrimary && !!sortedSecondaries.length && (
        <span>
          {
            // loops through each matching meter-user data
            // sorts the data by meterUid followed by fullname
            // workout the key set for the secondary details that match the searched text
            // sort details by comparing keys, followed by another sort to ensure meterUid is first
            sortDetails(
              Object.keys(
                sortedSecondaries.sort((x, y) => {
                  let keyA = -1;
                  let keyB = -1;

                  Object.keys(x).forEach((v, k) => {
                    if (isDetailKeyValid(searchText, secondaryDetails, v, x[v])) {
                      keyA = k;
                    }
                  });

                  Object.keys(y).forEach((v, k) => {
                    if (isDetailKeyValid(searchText, secondaryDetails, v, y[v])) {
                      keyB = k;
                    }
                  });

                  return keyA - keyB;
                })[0]
              )
            )
              .filter((v) => {
                // ensures meter uid is only displayed once as it is common between the users
                const isValid = !hasUid
                  ? isDetailKeyValid(searchText, secondaryDetails, v, sortedSecondaries[0][v])
                  : false;

                if (isValid && v === 'meterUid') {
                  hasUid = true;
                }

                return isValid;
              })
              .map((v, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`${v}-${k}`}>{renderSearchedItem(searchText, sortedSecondaries[0][v])}</span>
              ))
          }
        </span>
      )}
    </div>
  );
};

// given the searched text and a list of meter-user data sharing the same meter display name
// renders a list of item containing a mix of meter and user details
// primary details contains the meter display name
// secondary details contains a mix of meter and user details depending which one matches first
// the priority of secondary details is deteremined on line 150
export const renderAccountUserItem = (searchText, accountUsers) => {
  const matchingPrimary = hasMatchingText(searchText, accountUsers[0].primaryName);

  const secondaryDetails = ['meterDisplayName', 'meterUid', 'address'];
  const matchingSecondaries = getMatchingSecondaries(searchText, secondaryDetails, accountUsers);

  const sortDetails = (list) =>
    list.sort(
      (a, b) =>
        b.indexOf('meterDisplayName') - a.indexOf('meterDisplayName') ||
        b.indexOf('meterUid') - a.indexOf('meterUid')
    );

  const sortedSecondaries = sortSecondaryKeys(matchingSecondaries, sortDetails);

  let hasPrimary = false;

  return (
    <div>
      <span>
        <span>{renderSearchedItem(searchText, accountUsers[0].primaryName)}</span>
        <div>
          <span>{capitalise(accountUsers[0].meterAssetType)}</span>
        </div>
      </span>
      {!matchingPrimary && !!sortedSecondaries.length && (
        <span>
          {
            // loops through each matching account-user data
            // sorts the data by meterDisplayName followed by meterUid
            // workout the key set for the secondary details that match the searched text
            // sort details by comparing keys, followed by another sort to ensure meterUid is first
            sortDetails(
              Object.keys(
                sortedSecondaries.sort((x, y) => {
                  let keyA = -1;
                  let keyB = -1;

                  Object.keys(x).forEach((v, k) => {
                    if (isDetailKeyValid(searchText, secondaryDetails, v, x[v])) {
                      keyA = k;
                    }
                  });

                  Object.keys(y).forEach((v, k) => {
                    if (isDetailKeyValid(searchText, secondaryDetails, v, y[v])) {
                      keyB = k;
                    }
                  });

                  return keyA - keyB;
                })[0]
              )
            )
              .filter((v) => {
                const isValid = !hasPrimary
                  ? isDetailKeyValid(searchText, secondaryDetails, v, sortedSecondaries[0][v])
                  : false;

                if (isValid && v === 'primaryName') {
                  hasPrimary = true;
                }

                return isValid;
              })
              .slice(0, 1)
              .map((v, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`${v}-${k}`}>{renderSearchedItem(searchText, sortedSecondaries[0][v])}</span>
              ))
          }
        </span>
      )}
    </div>
  );
};

// groups the meter-users list by meter uid
// returns an object that contains a distinct meter uids with list of meter-users as values
export const categoriseMeterUsers = (meters) => {
  const sortedMeters = sortItems(meters, 'meterDisplayName');

  // Group meters by meterUid
  return sortedMeters.reduce((acc, val) => {
    // eslint-disable-next-line no-param-reassign
    acc[val.meterUid] = acc[val.meterUid] || [];
    acc[val.meterUid].push(val);
    return acc;
  }, {});
};

// groups the meters by valid meter asset type
// returns an object that contains a distinct meter uids with list of meters as values
export const categoriseMeters = (meters) => {
  const filteredMeters = meters.filter(
    (v) => v.meterAssetType && v.meterAssetType.toUpperCase() !== 'UNKNOWN'
  );
  const sortedMeters = sortItems(filteredMeters, 'meterDisplayName');

  return sortedMeters.reduce((acc, val) => {
    const meterType = capitalise(val.meterAssetType);
    // eslint-disable-next-line no-param-reassign
    acc[meterType] = acc[meterType] || [];
    acc[meterType].push(val);
    return acc;
  }, {});
};

// filters meters with invalid asset type to group them under 'Others'
export const categoriseOtherMeters = (meters) => {
  const filteredMeters = meters.filter(
    (v) => !v.meterAssetType || v.meterAssetType.toUpperCase() === 'UNKNOWN'
  );
  return sortItems(filteredMeters, 'meterDisplayName');
};

// filters users with a valid account number and a firstname/lastname/username
// followed by a map to generate a formatted primary name based on firstname, lastname and username
// uniqueAccountMeters returns distinct account users - this means only the first match from
// users with same account numbers will be returned
export const categoriseAccountUsers = (users) => {
  const sortedUsers = sortItems(
    users
      .filter((v) => v.accountNumber && (v.firstName || v.lastName || v.username))
      .map((v) => ({ ...v, primaryName: getUsername(v) })),
    'primaryName'
  );

  const uniqueAccountMeters = sortedUsers.filter(
    (value, index, arr) =>
      arr.findIndex(
        (user) => user.accountNumber === value.accountNumber && user.meterUid === value.meterUid
      ) === index
  );

  return uniqueAccountMeters.reduce((acc, val) => {
    // eslint-disable-next-line no-param-reassign
    acc[val.primaryName] = acc[val.primaryName] || [];
    acc[val.primaryName].push(val);
    return acc;
  }, {});
};

// renders a dropdown menu component that groups categorised meters
export const GroupedMeterDropdownMenu = (meters, selectedMeter, updateSelectedMeter, hasAllMeters = true) => {
  const { t } = useTranslation();
  const otherTypeMeters = categoriseOtherMeters(meters);
  const categorisedMeters = categoriseMeters(meters);
  let defaultSelectedMeter = '';

  if (hasAllMeters) {
    defaultSelectedMeter = 'all';
  } else if (meters && meters.length) {
    defaultSelectedMeter = meters[0].uid;
  }

  return (
    <Menu
      selectedKeys={[selectedMeter ? selectedMeter.uid : defaultSelectedMeter]}
      onClick={({ key }) => updateSelectedMeter(key)}
    >
      {hasAllMeters && <Menu.Item key="all">{t('All meters')}</Menu.Item>}
      {Object.keys(categorisedMeters)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .map((val, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <Menu.Group key={key} title={val}>
            {categorisedMeters[val].map(({ uid, displayName }) => (
              <Menu.Item key={uid}>
                <span>{displayName}</span>
              </Menu.Item>
            ))}
          </Menu.Group>
        ))}
      {!!otherTypeMeters.length && (
        <Menu.Group title={t('Others')}>
          {otherTypeMeters.map(({ uid, displayName }) => (
            <Menu.Item key={uid}>{displayName}</Menu.Item>
          ))}
        </Menu.Group>
      )}
    </Menu>
  );
};
