import { memo } from 'react';
import { Box } from 'theme-ui';

import { HostSpecificTimezoneViewProps } from './host-specific-timezone.types';

export const HostSpecificTimezoneView: React.FC<HostSpecificTimezoneViewProps> = memo(
  ({ hostSpecificTimezone }) => {
    return <Box>{hostSpecificTimezone}</Box>;
  }
);
