import React from 'react';
import PropTypes from 'prop-types';

export const MenuSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 12"
    aria-labelledby="title-menu-icon"
    aria-describedby="desc-menu-icon"
    role="img"
  >
    <title id="title-menu-icon">Menu icon</title>
    <description id="desc-menu-icon">
      A symbol showing three horizontal lines, representing a menu.
    </description>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-23.000000, -24.000000)" fill="#F7F7F7">
        <g transform="translate(20.000000, 18.000000)">
          <path
            d="M2.99999982,17.9999989 L20.9999987,17.9999989 L20.9999987,15.999999 L2.99999982,
          15.999999 L2.99999982, 17.9999989 Z M2.99999982,12.9999992 L20.9999987,12.9999992
          L20.9999987,10.9999993 L2.99999982,10.9999993 L2.99999982, 12.9999992 Z M2.99999982,
          5.99999964 L2.99999982,7.99999952 L20.9999987,7.99999952 L20.9999987,5.99999964
          L2.99999982, 5.99999964 Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

MenuSVG.defaultProps = {
  width: '18px',
  height: '18px',
};

MenuSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
