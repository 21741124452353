import { TOGGLE_ESTIMATE, TOGGLE_IOA_ESTIMATE } from '../constants';

export const toggleEstimate = (includeEstimates) => ({
  type: TOGGLE_ESTIMATE,
  includeEstimates,
});

export const toggleIOAEstimate = (isEstimate) => ({
  type: TOGGLE_IOA_ESTIMATE,
  isEstimate,
});
