import { memo, useCallback, useEffect, useRef } from 'react';
import { pageview } from 'react-ga';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from 'theme-ui';
import { getCurrentPath } from '@power-ledger/react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Header as LayoutHeader, Message } from '../components';
import { updateSelectedMeter } from '../states/slices/controls';
import { useHostConfig } from '../contexts/host-config-context';
import { useAppDispatch } from '../hooks/use-app-dispatch';
import { useAppSelector } from '../hooks/use-app-selector';
import { useFeatureSet } from '../hooks/use-feature-set';
import { useUnreadNotifications } from '../hooks/use-unread-notifications';
import { useIsAdmin } from '../hooks/use-is-admin';
import { registerUserEvent } from '../lib/auth-helpers';
import { MeterGroup } from '../../../types';
import { HostSpecificTimezone } from '../components/host-specific-timezone';
import { useCurrentPage } from '../hooks/use-current-page';
import { useCurrentSubpage } from '../hooks/use-current-subpage';
import Subheader from './subheader';
import { HeaderDropdown } from './common/header-dropdown';

const usePageTranslations = () => {
  const { t } = useTranslation();
  const { hostHasFlag } = useHostConfig(true);

  const isVB = hostHasFlag('isVB');

  const getPageTitleTranslations: () => Record<string, string> = () => ({
    Dashboard: isVB ? t('Beer') : t('Dashboard'),
    Transactions: t('Transactions'),
    Usage: isVB ? t('Energy') : t('Usage'),
    Billing: t('Billing'),
    'Battery Sharing': t('Battery Sharing'),
    Loyalty: t('Loyalty'),
    Trading: t('Trading'),
    Pricing: t('Pricing'),
    Account: t('My Account'),
    Themes: t('Edit Themes'),
    Notifications: t('Notifications'),
    Rankings: t('Rankings'),
    'Community Map': t('Community Map'),
    'Your Energy Mix': t('Your Energy Mix'),
  });

  return getPageTitleTranslations;
};

export const Header = () => {
  const getPageTitleTranslations = usePageTranslations();
  const headerRef = useRef<HTMLElement | null>(null);
  const { isAdmin } = useIsAdmin();
  const { hasNotifications } = useFeatureSet();
  const { numberOfUnread } = useUnreadNotifications();
  const dispatch = useAppDispatch();

  const meterGroup = useAppSelector(
    ({ meters: metersState }: any) => metersState.meterGroup,
    shallowEqual
  ) as MeterGroup;
  const currentPage = useCurrentPage();
  const currentSubpage = useCurrentSubpage();

  const meters = useAppSelector(({ meters: metersStore }) => metersStore.meters, shallowEqual);
  const selectedMeter = useAppSelector(({ controls }) => controls.selectedMeter, shallowEqual);

  const navigate = useCallback((path) => {
    const pagePath = window.location.pathname + window.location.search;

    pageview(pagePath);
    registerUserEvent(`navigated to ${pagePath.substring(1)}`, 'navigation');
    window.scrollTo(0, 0);
  }, []);

  const pageTitle = getPageTitleTranslations()[currentPage || 'Dashboard'];

  useEffect(() => {
    const pageTitleCurrent = getCurrentPath();
    const pages = pageTitleCurrent.split('/');

    if (!isAdmin && meters && meters.length && !selectedMeter) {
      dispatch(updateSelectedMeter(meters[0]));
    }
  }, [currentPage, currentSubpage, dispatch, isAdmin, meters, selectedMeter]);

  return (
    <header role="banner" ref={headerRef}>
      <LayoutHeader
        heading={meterGroup && meterGroup.id && pageTitle}
        subheading={<HostSpecificTimezone />}
        subheader={<Subheader currentPageTitle={'currentPageTitle'} />}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {hasNotifications && (
            <Badge bg="warning" count={numberOfUnread} countOverflow={9}>
              <Link tabIndex={-1} to="/notifications">
                <Button variant="avatar" onClick={() => navigate('Notifications')}>
                  <Message size={5} />
                </Button>
              </Link>
            </Badge>
          )}

          <HeaderDropdown navigate={navigate} />
        </Box>
      </LayoutHeader>
    </header>
  );
};

export default memo(Header);
