import { useEffect, useMemo } from 'react';
import axios from 'axios';

import { RequestToken } from '../../../types';
import { usePrevious } from './use-previous';

type UseCancelToken = (refreshCallback?: null | (() => void), deps?: unknown[]) => RequestToken;

export const useCancelToken: UseCancelToken = (refreshCallback = null, deps = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestToken = useMemo(
    () => axios.CancelToken.source(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, refreshCallback]
  );
  const previousToken = usePrevious(requestToken);

  useEffect(() => {
    if (previousToken && previousToken !== requestToken) {
      if (refreshCallback) refreshCallback();
      previousToken.cancel();
    }
  });

  useEffect(() => () => requestToken.cancel(), [requestToken]);

  return requestToken;
};
