import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../../hooks/use-app-selector';

export const useTermsOfAccessOverlayLogic = () => {
  const authProfile = useAppSelector(({ auth }) => auth.profile, shallowEqual);

  return {
    username: authProfile?.username,
    hasAccepted: !!authProfile?.termsAcceptedDateTime,
  };
};
