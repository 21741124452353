import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getMyMeterLocations as getMyMeterLocationsMiddle,
  getMyNearbyMeterLocations as getMyNearbyMeterLocationsMiddle,
} from '../../middleware/api';
import { errorNotification, successRegex } from '../../lib/notifications';
import i18n from '../../lib/i18n';

export const getMyMeterLocations = createAsyncThunk(
  'energy-community/map/getMyMeterLocations',
  async (_, { rejectWithValue }) => {
    const { status, body } = await getMyMeterLocationsMiddle();
    return successRegex.test(status) ? { ...body } : rejectWithValue({ body: { ...body }, status });
  }
);

export const getMyNearbyMeterLocations = createAsyncThunk(
  'energy-community/map/getMyNearbyMeterLocations',
  async ({ props }, { rejectWithValue }) => {
    const { status, body } = await getMyNearbyMeterLocationsMiddle(props);
    return successRegex.test(status) ? { ...body } : rejectWithValue({ body: { ...body }, status });
  }
);

const initialState = {
  myMeterLocations: [],
  myNearbyMeterLocations: [],
};

const slice = createSlice({
  name: 'communityMap',
  initialState,
  extraReducers: {
    [getMyMeterLocations.fulfilled]: (state, { payload: { meterLocations } }) => {
      state.myMeterLocations = meterLocations.filter(
        (item) => typeof item.latitude !== 'undefined' && typeof item.longitude !== 'undefined'
      );
    },
    [getMyMeterLocations.rejected]: (state, { payload: { body, status } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve your meter locations at this time!'),
      });
    },
    [getMyNearbyMeterLocations.fulfilled]: (state, { payload: { meterLocations } }) => {
      state.myNearbyMeterLocations = meterLocations;
    },
    [getMyNearbyMeterLocations.rejected]: (state, { payload: { body, status } }) => {
      errorNotification({
        code: status,
        errorCode: body.errorCode,
        description: i18n.t('Could not retrieve your community meter locations at this time!'),
      });
    },
  },
});

// reducer
export default slice.reducer;
