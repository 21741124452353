export enum TradeUnit {
  USAGE = 'usage',
  AMOUNT = 'amount',
}

export enum TradeType {
  BOUGHT = 'Bought',
  SOLD = 'Sold',
}

export enum PpaType {
  PERCENTAGE_OF_EXPORT = 'PERCENTAGE_OF_EXPORT',
  ENERGY_AMOUNT = 'ENERGY_AMOUNT',
}

export enum OfferCancelReason {
  WITHDRAWN = 'WITHDRAWN',
  DECLINED = 'DECLINED',
  TERMINATED = 'TERMINATED',
}

export enum OfferStatus {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  ACCEPTED = 'ACCEPTED',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  CANCELLED = 'CANCELLED',
}

export enum TraderType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export interface ContractUser {
  type: TraderType;
  username: string;
}

export interface Offer {
  uid: string;
  buyerMeterUid: string;
  buyerMeterDisplayName: string;
  sellerMeterUid: string;
  sellerMeterDisplayName: string;
  ppaType: PpaType;
  amount: number;
  energyPrice: number;
  energyPriceTax: number;
  energyPriceNetTax: number;
  gstFree: boolean;
  effectiveFrom: string;
  effectiveTo: string;
  createdBy: string;
  users: ContractUser[];
  status: OfferStatus;
  cancelReason: OfferCancelReason;
  creationTime: string;
}

export enum RatePeriodCategory {
  ANY_DAY = 'ANY_DAY',
  WEEKDAY = 'WEEKDAY',
  WEEKEND = 'WEEKEND',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum RatePeriodSubCategory {
  PEAK = 'PEAK',
  OFF_PEAK = 'OFF_PEAK',
  SHOULDER = 'SHOULDER',
  ANY_TIME = 'ANY_TIME',
}

export interface RatePeriod {
  buyRate: number;
  buyRateNetTax: number;
  buyRateTax: number;
  category: RatePeriodCategory;
  subCategory: RatePeriodSubCategory;
  description: string;
  displayName: string;
  fromUsage?: number;
  proRataTier?: boolean;
  rateUid: string;
  sellRate: number;
  sellRateNetTax: number;
  sellRateTax: number;
  tierCycleRefreshInterval?: string;
  toUsage: number;
}
