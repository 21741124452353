import { useMemo } from 'react';
import { Box, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';

export const OrdersList = ({ orders }: { orders: { id: string; volume: number; price: number }[] }) => {
  const { t } = useTranslation();

  const ColumnLabel = ({ label }: { label: string }) => (
    <Box sx={{ flex: 1, px: 5, pt: 4 }}>
      <Box sx={{ color: 'textDarker' }}>{label}</Box>
    </Box>
  );

  const ordersRows = useMemo(
    () =>
      orders.map(({ id, volume, price }, key) => (
        <Flex
          key={id}
          sx={{
            justifyContent: 'space-between',
            ...(key !== orders.length - 1
              ? {
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'foregroundLight',
                }
              : {}),
          }}
        >
          <Box sx={{ flex: 1, px: 5, py: 4 }}>
            {currency(volume, {
              symbol: '',
              precision: 0,
              separator: ',',
            }).format()}
          </Box>
          <Box sx={{ flex: 1, px: 5, py: 4 }}>{currency(price, { separator: ',' }).format()}</Box>
        </Flex>
      )),
    [orders]
  );

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <ColumnLabel label={t('Volume')} />
        <ColumnLabel label={t('Price')} />
      </Flex>

      {ordersRows}
    </Box>
  );
};

export default OrdersList;
