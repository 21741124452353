export enum ContractStatus {
  ACCEPTED = 'ACCEPTED',
}

export interface Contract {
  contractId: string;
  contractEffectiveFrom: string;
  contractEffectiveTo: string;
  sellerMeterUid: string;
  sellerMeterDisplayName: string;
  status: ContractStatus;
  energyDonated: number;
  valueAccrued: number;
  redeemed: number;
}
