import { CustomThemeProperty } from './types';

export const dropdown = CustomThemeProperty('dropdown', {
  position: 'absolute',
  top: 5,
  width: '200px',
  background: 'white',
  overflow: 'hidden',
  borderRadius: 4,
  transformOrigin: 'top',
  transform: 'scaleY(0)',
  opacity: 0,
  transition: 'base',
  boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
});
