export const STATUS_BADGE_COLOR_MAPPING = {
  success: {
    backgroundColor: '#4BB543',
    dotColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
  warning: {
    backgroundColor: '#FFBB33',
    dotColor: 'highlightDarkest',
    textColor: 'highlightDarkest',
  },
  error: {
    backgroundColor: '#DC3545',
    dotColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
  disabled: {
    backgroundColor: '#EAEEEF',
    dotColor: '#464747',
    textColor: '#464747',
  },
  info: {
    backgroundColor: '#007BFF',
    dotColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
};
