import PropTypes from 'prop-types';
import { NoData } from '@power-ledger/assets';
import { useTranslation } from 'react-i18next';

import { Box, Text } from 'theme-ui';

import { Tooltip } from '../../../../src/app/components';
import { WattHour } from '../../../../types';
import { LoadingOverlay, Table } from '..';
import { paginationOpts } from '../../lib/pagination';
import { convertAndFormatUnitValue } from '../../lib/unit';
import { useScreenSize } from '../../hooks/use-screen-size';

const columns = (t, isSmallScreen) => {
  const timestampColumnWidth = isSmallScreen ? 140 : 200;
  const reveivedAtColumnWidth = isSmallScreen ? 140 : 200;

  return [
    {
      title: t('READING TIMESTAMP'),
      dataIndex: 'timestamp',
      key: 'key',
      left: 0,
      width: timestampColumnWidth,
      sticky: true,
      render: ({ date, time }) =>
        date &&
        time && (
          <Box>
            <strong>{date}</strong> <Text>{time}</Text>
          </Box>
        ),
    },
    {
      title: t('RECEIVED AT'),
      dataIndex: 'receivedAt',
      key: 'receivedAt',
      ...(isSmallScreen ? {} : { left: timestampColumnWidth, width: reveivedAtColumnWidth, sticky: true }),
      render: ({ date, time }) =>
        date &&
        time && (
          <Box>
            <strong>{date}</strong> <Text>{time}</Text>
          </Box>
        ),
    },
    {
      title: t('METER'),
      dataIndex: 'meterName',
      key: 'meterUid',
      render: (meterName) => (
        <Tooltip title={meterName}>
          <Text
            sx={{
              width: isSmallScreen ? 200 : 320,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {meterName}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: t('TOTAL IMPORT'),
      dataIndex: 'rawTotalImport',
      key: 'rawTotalImport',
      render: (value) => {
        const { formattedNewValue, newUnit } = convertAndFormatUnitValue(value, WattHour.Wh);
        const formattedValueWithUnit = `${formattedNewValue} ${newUnit}`;

        return <Text>{value !== null ? (formattedNewValue === 0 ? 0 : formattedValueWithUnit) : '-'}</Text>;
      },
    },
    {
      title: t('TOTAL EXPORT'),
      dataIndex: 'rawTotalExport',
      key: 'rawTotalExport',
      render: (value) => {
        const { formattedNewValue, newUnit } = convertAndFormatUnitValue(value, WattHour.Wh);
        const formattedValueWithUnit = `${formattedNewValue} ${newUnit}`;

        return <Text>{value !== null ? (formattedNewValue === 0 ? 0 : formattedValueWithUnit) : '-'}</Text>;
      },
    },
  ];
};

export const ReadingsTable = ({
  data,
  hasMultipleMeters,
  selectedMeter,
  total,
  offset,
  limit,
  isLoading,
  isEstimate,
  onUpdateReadingsByPageOptions,
}) => {
  const { t } = useTranslation();
  const pageIndex = Math.floor((offset + limit) / limit);
  const isConsumer = !!(selectedMeter && selectedMeter.assetType === 'CONSUMER');
  const { isSmallDown } = useScreenSize();

  const customColumns = columns(t, isSmallDown).filter(
    (val) =>
      (hasMultipleMeters || (!hasMultipleMeters && val.key !== 'meterUid')) &&
      (!isConsumer || (isConsumer && val.key !== 'totalExport'))
  );

  return (
    <Table
      locale={{
        emptyText: <NoData description={isEstimate ? t('No estimates found') : t('No readings found')} />,
      }}
      columns={total > 0 ? customColumns : []}
      dataSource={total > 0 ? data.map((val, key) => ({ ...val, key })) : []}
      loading={{
        spinning: isLoading,
        indicator: (
          <LoadingOverlay
            active
            text={isEstimate ? t('Loading estimates...') : t('Loading readings...')}
            color="text"
          />
        ),
      }}
      pagination={paginationOpts(pageIndex, limit, total, onUpdateReadingsByPageOptions, t)}
    />
  );
};

ReadingsTable.defaultProps = {
  data: [],
  hasMultipleMeters: false,
  total: 0,
  offset: 0,
  limit: 10,
  isLoading: false,
  isEstimate: false,
};

ReadingsTable.propTypes = {
  selectedMeter: PropTypes.any,
  data: PropTypes.array,
  hasMultipleMeters: PropTypes.bool,
  total: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
  isEstimate: PropTypes.bool,
  onUpdateReadingsByPageOptions: PropTypes.func.isRequired,
};
