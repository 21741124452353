import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ThemeUIStyleObject, Box, Flex, Button } from 'theme-ui';
import { alpha } from '@theme-ui/color';
import { css, Global } from '@emotion/react';
import { useTheme } from '@power-ledger/hooks';
import { Card } from '../Card';
import { Close } from '../icons';

export type ModalProps = {
  afterClose?: MouseEventHandler<any>;
  onCancel?: MouseEventHandler<any>;
  maskClosable?: boolean;
  element?: string;
  visible: boolean;
  hideCard?: boolean;
  title?: string;
  subtitle?: string;
  sx?: ThemeUIStyleObject;
  compact?: boolean;
  hideCloseButton?: boolean;
  outerContent?: any;
  modalMaxWidth?: string | Array<string | null>;
  containerOverflow?:
    | 'hidden'
    | 'auto'
    | 'scroll'
    | 'visible'
    | 'inherit'
    | 'initial'
    | 'overlay'
    | 'revert'
    | 'unset';
};

export type ModalActionProps = {
  disabled?: boolean;
  primary?: boolean;
  children?: any;
  onClick?: any;
};

export const Modal: FC<ModalProps> = ({
  children,
  afterClose,
  onCancel,
  maskClosable,
  element = 'div',
  visible,
  containerOverflow,
  hideCard,
  title = '',
  subtitle = '',
  sx,
  compact = true,
  hideCloseButton,
  outerContent,
  modalMaxWidth,
}) => {
  const [container] = useState(document.createElement(element));
  const { theme } = useTheme();

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  const onClose = useCallback(
    async (e) => {
      if (onCancel) await onCancel(e);
      if (afterClose) afterClose(e);
    },
    [afterClose, onCancel]
  );

  const bodyStyles = visible && (
    <Global
      styles={css`
        body {
          min-height: 100vh;
          max-width: 100vw;
          overflow: ${containerOverflow ? containerOverflow : 'hidden'};
          opacity: 1;
        }
      `}
    />
  );

  const modalMargin = 4;

  const marginPixelValue: number = useMemo(() => {
    if (!theme.space) return 0;

    const spaceValue = theme.space[modalMargin];

    return spaceValue as number;
  }, [theme.space]);

  return visible
    ? createPortal(
        <Box
          onClick={async (e) => {
            if (maskClosable) {
              await onClose(e);
            }
          }}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bg: alpha('background', 0.95),
            zIndex: 999,
          }}
        >
          {bodyStyles}
          <Flex
            sx={{
              flexDirection: 'column',
              width: '100%',
              maxWidth: modalMaxWidth || '1140px',
              m: 3,
              borderRadius: 3,
              maxHeight: marginPixelValue ? `calc(100vh - ${marginPixelValue}px)` : '720px',
              overflow: outerContent ? 'visible' : containerOverflow ? containerOverflow : 'hidden',
              position: 'relative',
            }}
          >
            {(!!title || !!subtitle || !hideCloseButton) && (
              <Flex sx={{ alignItems: 'center', width: '100%', mb: 5 }}>
                {(!!title || !!subtitle) && (
                  <Flex sx={{ flexDirection: 'column' }}>
                    <Box sx={{ fontSize: 4 }}>{title}</Box>
                    {!!subtitle && <Box sx={{ fontSize: 2 }}>{subtitle}</Box>}
                  </Flex>
                )}
                {!hideCloseButton && (
                  <Flex
                    sx={{
                      ml: 'auto',
                      width: 7,
                      height: 7,
                      p: 1,
                      borderRadius: 6,
                      bg: 'backgroundLight',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      transition: 'base',
                      '&:hover': {
                        bg: 'backgroundLighter',
                      },
                      '&:enabled:hover,&:enabled:focus': { backgroundColor: 'negativeDarker' },
                    }}
                    onClick={(e) => onClose(e)}
                  >
                    <Close color="text" size={5} />
                  </Flex>
                )}
              </Flex>
            )}

            <Box
              sx={{ overflow: containerOverflow ? containerOverflow : 'auto' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Card
                sx={{ ...(hideCard ? { bg: 'transparent' } : {}), ...sx }}
                variant={compact ? 'compact' : 'primary'}
              >
                {children}
              </Card>
            </Box>
            {outerContent}
          </Flex>
        </Box>,
        container
      )
    : null;
};
