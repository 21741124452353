import { memo } from 'react';
import { IconButton as ThemeUIIconButton } from 'theme-ui';

import { IconButtonProps } from './icon-button.types';

const IconButtonBase: React.FC<IconButtonProps> = ({ sx, ...rest }) => {
  return (
    <ThemeUIIconButton
      sx={{
        cursor: 'pointer',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        ...sx,
      }}
      {...rest}
    />
  );
};

export const IconButton = memo(IconButtonBase);
