export const toast = {
  '.Toastify__toast--info': {
    backgroundColor: 'foregroundLight',
    color: 'white',
  },
  '.Toastify__toast--success': {
    backgroundColor: 'positiveLight',
    color: 'text',
  },
  '.Toastify__toast--error': {
    backgroundColor: 'warningLighter',
    color: 'text',
  },
};
