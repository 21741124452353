import { useMemo } from 'react';
import chunk from 'lodash/chunk';
import zip from 'lodash/zip';

import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { parseDateWithTimeZone } from '../../../lib/datetime-helpers';
import { calculateRollingAverage } from '../../../services';
import { TradeType } from '../../../../../types';

interface MeterTrade {
  transactionDate: string;
  p2pBought: string;
  p2pSold: string;
  gridBought: string;
  gridSold: string;
  renewableBought: string;
  renewableSold: string;
  totalImport: string;
  totalExport: string;
  averageImport: string;
  averageExport: string;
  savingAmount: string;
}

type UseFormattedCommunityUsageDataArgs = {
  meterTrades?: MeterTrade[];
  lastMeterTrades?: MeterTrade[];
  groupTrades?: MeterTrade[];
  lastGroupTrades?: MeterTrade[];
  hiddenIds: string[];
  timeZone: string;
  chunkSize: number;
  tradeType: TradeType;
  shouldDisplayGroupData?: boolean;
  t: (...args: any[]) => string;
};

type FormattedCommunityUsageData = {
  data: {
    id: string;
    data: {
      x: number;
      y: number;
    }[];
  }[];
  legendData: {
    id: string;
    color: string;
    label: string;
  }[];
  types: DataType[];
  keys: string[];
};

export type DataType = 'currentMeter' | 'currentGroup' | 'previousMeter' | 'previousGroup';

export const useFormattedCommunityUsageData = ({
  lastMeterTrades = [],
  meterTrades = [],
  groupTrades = [],
  lastGroupTrades = [],
  hiddenIds,
  tradeType,
  timeZone,
  chunkSize,
  shouldDisplayGroupData = false,
  t,
}: UseFormattedCommunityUsageDataArgs): FormattedCommunityUsageData => {
  const { colorScheme } = useThemedColorScale();
  const usageTradeType = tradeType === 'Sold' ? 'Export' : 'Import';

  const filteredAndChunkedMeterTrades = useMemo(() => {
    if (Number.isNaN(chunkSize)) return [];

    return chunk(zip(lastMeterTrades, meterTrades, lastGroupTrades, groupTrades), chunkSize);
  }, [chunkSize, groupTrades, lastGroupTrades, lastMeterTrades, meterTrades]);

  const { parsedData } = useMemo(() => {
    const newParsedData = filteredAndChunkedMeterTrades.reduce<any>((acc, chunkArr) => {
      const { dateTimes, previousDateTimes, ...meterTradeAverages } = chunkArr.reduce<any>(
        (sumAcc, dataArr, index) => {
          const [previousMeter, meterTrade, previousGroup, groupTrade] = dataArr;

          if (meterTrade === undefined && groupTrade === undefined) return sumAcc;

          sumAcc.dateTimes = [
            ...sumAcc.dateTimes,
            parseDateWithTimeZone(
              meterTrade?.transactionDate || groupTrade?.transactionDate,
              timeZone
            ).toMillis(),
          ];

          sumAcc.previousDateTimes = [
            ...sumAcc.dateTimes,
            parseDateWithTimeZone(
              previousMeter?.transactionDate || previousGroup?.transactionDate,
              timeZone
            ).toMillis(),
          ];

          [
            ['previousMeter', 'average'],
            ['currentMeter', 'total'],
            ['previousGroup', 'average'],
            ['currentGroup', 'average'],
          ].forEach(([type, key], i) => {
            if (
              dataArr[i] === undefined ||
              dataArr[i]?.[`${key}${usageTradeType}` as keyof MeterTrade] === undefined
            ) {
              sumAcc[type] = null;

              return;
            }

            if (!sumAcc[type]) {
              sumAcc[type] = 0;
            }

            sumAcc[type] = calculateRollingAverage(
              // @ts-ignore
              Number(dataArr[i][`${key}${usageTradeType}`]),
              sumAcc[type] as number,
              index + 1
            );
          });

          return sumAcc;
        },
        { dateTimes: [], previousDateTimes: [] }
      );

      const dateTime = parseDateWithTimeZone(Math.min(...dateTimes), timeZone);
      const previousDateTime = parseDateWithTimeZone(Math.min(...previousDateTimes), timeZone);

      acc.push({
        date: dateTime.toMillis(),
        previousDate: previousDateTime.toMillis(),
        ...meterTradeAverages,
      });

      return acc;
    }, []);

    return {
      parsedData: newParsedData,
    };
  }, [filteredAndChunkedMeterTrades, timeZone, usageTradeType]);

  // The ordering of the types here will affect the ordering of the legends output on the graph.
  const types: DataType[] = shouldDisplayGroupData
    ? ['currentGroup', 'previousGroup']
    : ['currentMeter', 'previousMeter', 'currentGroup', 'previousGroup'];

  const keys = types.map((type) => t(`dashboard.chart.typeName.${type}`, { defaultValue: type }));

  const legendData = keys.map((label, i) => ({
    id: types[i],
    color: hiddenIds.includes(types[i]) ? 'rgba(1, 1, 1, .1)' : colorScheme[i % colorScheme.length],
    label,
  }));

  const data = useMemo(() => {
    const seenTypes: Record<string, number> = {};
    let currentIndex = 0;

    return parsedData.reduce((acc: any, { date, previousDate, ...parsedDataObj }: any) => {
      Object.entries(parsedDataObj).forEach(([type, val]) => {
        if (hiddenIds.includes(type)) return;
        if (shouldDisplayGroupData && (type === 'currentMeter' || type === 'previousMeter')) return;

        const dataObj = { x: date, y: val, previousDate, type };

        if (seenTypes[type] === undefined) {
          seenTypes[type] = currentIndex;
          acc.push({ id: type, data: [dataObj] });
          currentIndex += 1;
        } else {
          acc[seenTypes[type]].data.push(dataObj);
        }
      });

      return acc;
    }, []);
  }, [hiddenIds, parsedData, shouldDisplayGroupData]);

  return { data, legendData, types, keys };
};
