import { ThemeUIStyleObject } from 'theme-ui';

export const sectionHeaders: ThemeUIStyleObject = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  pb: 2,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: 'muted',
  title: {
    flexGrow: 1,
    py: 2,
    fontWeight: 500,
    fontSize: 2,
    width: ['100%', 'auto'],
    textAlign: ['center', 'left'],
  },
};
