import { FC, useRef, useEffect, useCallback, MouseEventHandler } from 'react';
import { Box, Flex } from 'theme-ui';

export type PopconfirmProps = {
  visible?: boolean;
  children?: any;
  title?: string;
  okText?: string;
  cancelText?: string;
  left?: boolean;
  onConfirm?: MouseEventHandler<any>;
  onCancel?: MouseEventHandler<any>;
};

export const Popconfirm: FC<PopconfirmProps> = ({
  title = 'Popconfirm Title',
  okText = 'Yes',
  cancelText = 'No',
  visible = false,
  children,
  left,
  onConfirm,
  onCancel,
}) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (onCancel && wrapperRef.current && !(wrapperRef as any).current.contains(event.target)) {
        onCancel(event);
      }
    },
    [onCancel]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => document.removeEventListener('click', handleClickOutside, false);
  });

  return (
    <Box sx={{ position: 'relative' }} ref={wrapperRef}>
      <Box>{children}</Box>
      <Box
        sx={{
          variant: 'popconfirms',
          ...(visible ? { transform: 'scaleY(1)', opacity: 1, overflow: 'visible' } : {}),
          ...(left ? { right: 0 } : { left: 0 }),
        }}
      >
        <Box sx={{ py: 2 }}>{title}</Box>
        <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end', mt: 3 }}>
          <Box
            sx={{
              variant: 'popconfirms.confirmButton',
            }}
            onClick={onCancel}
          >
            {cancelText}
          </Box>
          <Box
            sx={{
              variant: 'popconfirms.cancelButton',
            }}
            onClick={onConfirm}
          >
            {okText}
          </Box>
        </Flex>
        <Box
          sx={{
            variant: 'popconfirms.arrow',
          }}
        />
      </Box>
    </Box>
  );
};
