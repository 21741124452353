import { formatAmount } from '../../lib/helpers';
import { safelyParseNumber } from '../../utils/number';

export const getValueFromSparkzCurrency = (
  sparkzCurrencyValue: number,
  sparkzConversionRate?: number | null
) => {
  const convertedValue = sparkzConversionRate
    ? sparkzCurrencyValue / sparkzConversionRate
    : sparkzCurrencyValue;

  return safelyParseNumber(formatAmount(convertedValue, 2));
};
