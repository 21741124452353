import { TFunction } from 'i18next';

/**
 * Provides translated label for Asset Generation Type Id like "grid" or "GRID"
 * @param t
 * @param id
 * @returns
 */
export const labelOfAssetGenTypeId = (t: TFunction, id: string) => {
  switch (id.toUpperCase()) {
    case 'GRID':
      return t('Grid');

    case 'WIND':
      return t('Wind');

    case 'SOLAR_PV':
      return t('Solar');

    case 'HYDRO':
      return t('Hydro');

    case 'BIOMASS':
      return t('Biomass');

    case 'NUCLEAR':
      return t('Nuclear');

    default:
      return t('Unknown');
  }
};

/**
 * Position of where to display asset on screen
 * @param id
 * @returns
 */
export const orderOfAssetGenTypeId = (id: string) => {
  switch (id.toUpperCase()) {
    case 'SOLAR_PV':
      return 0;

    case 'WIND':
      return 1;

    case 'HYDRO':
      return 2;

    case 'BIOMASS':
      return 3;

    case 'NUCLEAR':
      return 4;

    case 'GRID':
      return 5;

    default:
      return 6;
  }
};
