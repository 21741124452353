import React from 'react';
import PropTypes from 'prop-types';

export const Message = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 13"
    fill="none"
    aria-labelledby="title-message"
    aria-describedby="desc-message"
    role="img"
  >
    <title id="title-message">Message icon</title>
    <description id="desc-message">A symbol representing a simple message.</description>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.10602 2.10578C0.0372836 2.30793 0 2.52461 0
    2.75V10.75C0 11.8546 0.895431 12.75 2 12.75H14C15.1046 12.75 16 11.8546 16 10.75V2.75C16 2.52461
    15.9627 2.30793 15.894 2.10578L8.43196 7.36311C8.17291 7.54563 7.82709 7.54563 7.56804
    7.36311L0.10602 2.10578ZM1.09362 0.966692C1.36571 0.828126 1.67373 0.75 2 0.75H14C14.3263 0.75
    14.6343 0.828126 14.9064 0.966692L8 5.83255L1.09362 0.966692Z"
      fill="#003943"
    />
  </svg>
);

Message.defaultProps = {
  width: '16px',
  height: '13px',
};

Message.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
