import { createAction, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { getPrevFourWeekPeriodAsISO } from '../../lib/datetime-helpers';

type ControlsPeriod = 'prevFourWeek' | 'day' | 'week' | 'month' | 'year' | 'custom';

export type ControlsState = {
  searchMeterIsVisible: boolean;
  selectedMeter: Record<string, any> | null;
  startDate: string;
  endDate: string;
  daysInRange: number;
  period: ControlsPeriod;
};

const emptyState: ControlsState = {
  searchMeterIsVisible: false,
  selectedMeter: null,
  startDate: '',
  endDate: '',
  daysInRange: 28,
  period: 'prevFourWeek',
};

// gets the temporarily stored controls selection in local storage
export const getControls = (): ControlsState => {
  const existingControls = localStorage.getItem('controls');

  if (typeof existingControls === 'string') {
    try {
      return JSON.parse(existingControls);
    } catch {
      return {
        searchMeterIsVisible: false,
        selectedMeter: null,
        startDate: '',
        endDate: '',
        daysInRange: 28,
        period: 'prevFourWeek',
      };
    }
  }
  localStorage.setItem('controls', JSON.stringify(emptyState));
  return emptyState;
};

export const getDaysInRange = (startDate: string, endDate: string) => {
  const startDateMillis = new Date(startDate).getTime();
  const endDateMillis = new Date(endDate).getTime();
  const daysInRange = Math.ceil((endDateMillis - startDateMillis) / (1000 * 3600 * 24));
  return daysInRange;
};

// gets the temporarily stored control object by JSON parsing
export const getControlObject = (key: keyof ControlsState) => {
  const sessionControls = getControls();
  return sessionControls[key];
};

// updates and stores the controls selection in local storage given key and value pair
const updateControls = (key: keyof ControlsState, val: any) => {
  const controls: Partial<ControlsState> = getControls() || {};

  const newControls = { ...controls, [key]: val };

  localStorage.setItem('controls', JSON.stringify(newControls));
};

export const clearControls = () => localStorage.removeItem('controls');

export const initializeControlsFromTimeZone = createAction(
  'controls/initializeControlsFromTimeZone',
  (timezone: string, hasIOAManagement?: boolean, forceDefault?: boolean) => ({
    payload: {
      timezone,
      hasIOAManagement,
      forceDefault,
    },
  })
);

const initialState = getControls();

export const controlsSlice = createSlice<ControlsState, SliceCaseReducers<ControlsState>, 'controls'>({
  name: 'controls',
  initialState,
  reducers: {
    toggleSearchMeterVisible: (state, { payload }) => {
      updateControls('searchMeterIsVisible', payload);
      state.searchMeterIsVisible = payload;
    },
    updateSelectedMeter: (state, { payload }) => {
      updateControls('selectedMeter', payload);
      state.selectedMeter = payload;
    },
    updatePeriodicalDates: {
      prepare: (startDate, endDate, period = 'prevFourWeek') => {
        const daysInRange = getDaysInRange(startDate, endDate);
        return {
          payload: {
            startDate,
            endDate,
            period,
            daysInRange,
          },
        };
      },
      reducer(state, { payload: { startDate, endDate, period, daysInRange } }) {
        if (startDate) {
          updateControls('startDate', startDate);
          state.startDate = startDate;
        }
        if (period) {
          updateControls('period', period);
          state.period = period;
        }
        if (endDate) {
          updateControls('endDate', endDate);
          state.endDate = endDate;
        }
        if (daysInRange) {
          updateControls('daysInRange', daysInRange);
          state.daysInRange = daysInRange;
        }
      },
    },
    resetControls: () => {
      clearControls();
      return emptyState;
    },
    initializeControlsFromTimeZone: (
      state,
      { payload: { timezone, hasIOAManagement, forceDefault = false } }
    ) => {
      const { startDate, endDate, period } = getPrevFourWeekPeriodAsISO(timezone, hasIOAManagement);
      const {
        startDate: localStartDate,
        endDate: localEndDate,
        daysInRange: localDaysInRange,
        period: localPeriod,
      } = getControls();

      if (localStartDate && localEndDate && localDaysInRange && localPeriod && !forceDefault) return;

      state.startDate = startDate;
      state.endDate = endDate;
      state.period = period as ControlsPeriod;
      state.daysInRange = 28;
      updateControls('startDate', startDate);
      updateControls('endDate', endDate);
      updateControls('period', 'prevFourWeek');
      updateControls('daysInRange', 28);
    },
  },
});

export const { toggleSearchMeterVisible, updateSelectedMeter, updatePeriodicalDates, resetControls } =
  controlsSlice.actions;

export default controlsSlice.reducer;
