/* eslint-disable jsx-a11y/no-noninteractive-tabindex,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Paragraph } from 'theme-ui';

import { Tabs, Modal } from '..';
import { getAccountUsers } from '../../states/actions/app-notifications';
import { getUsername } from '../../lib/helpers';
import { withTranslation } from '../../hocs/with-translation';

const initialState = {
  tabKey: 'content',
  users: [],
  totalUsers: 0,
  size: 100,
};

class NotificationDetails extends Component {
  _isMounted = false;

  tabContentRef = createRef();

  tabRecipientsRef = createRef();

  constructor(props) {
    super(props);
    this.state = { ...initialState, requestToken: axios.CancelToken.source() };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { tradingGroupName, details, visible } = this.props;
    const { tabKey, users, size, requestToken } = this.state;

    if (prevProps.visible !== visible && visible) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(initialState);

      this.tabContentRef.current && this.tabContentRef.current.scrollIntoView();
      this.tabRecipientsRef.current && this.tabRecipientsRef.current.scrollIntoView();
    } else if (
      prevState.size !== size ||
      (prevState.tabKey !== tabKey &&
        (!users.length || (prevState.users.length !== users.length && users.length)))
    ) {
      requestToken.cancel();

      if (tabKey === 'recipients') {
        const { toAccountNumbers = [] } = details;

        const updatedToken = axios.CancelToken.source();

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ requestToken: updatedToken });

        const request = {
          tradingGroupName,
          accountNumbers: toAccountNumbers,
          size,
        };

        Promise.resolve(getAccountUsers(request, updatedToken)).then((resp) => {
          const { content, totalElements } = resp;
          this._isMounted && this.setState({ users: content, totalUsers: totalElements });
        });
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.state.requestToken.cancel();
  };

  render() {
    const { category, details, visible, closeModal, t } = this.props;
    const { tabKey, users, totalUsers, size } = this.state;
    const { subject, content, fullDateTime } = details;

    return (
      <Modal
        title={subject}
        visible={visible}
        onCancel={closeModal}
        bodyStyle={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
        footer={null}
      >
        <div>
          <span>{fullDateTime}</span>
          {category === 'inbox' ? (
            <Paragraph>{content}</Paragraph>
          ) : (
            <Tabs
              defaultActiveKey="content"
              activeKey={tabKey}
              onChange={(val) => this.setState({ tabKey: val })}
            >
              <Tabs.TabPane tab={t('Content')} key="content">
                <Paragraph ref={this.tabContentRef}>{content}</Paragraph>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('Recipients')} key="recipients">
                {!!(users && users.length) && (
                  <ul ref={this.tabRecipientsRef}>
                    {users.map((val) => (
                      <li key={`${val.username}`} title={getUsername(val)}>
                        {getUsername(val)}
                      </li>
                    ))}
                    {size < totalUsers && (
                      <span tabIndex={0} onClick={() => this.setState({ size: size + 100 })}>
                        {t('Show more')}
                      </span>
                    )}
                  </ul>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </div>
      </Modal>
    );
  }
}

NotificationDetails.defaultProps = {
  details: {
    subject: '',
    content: '',
    fullDateTime: '',
  },
  visible: false,
};

NotificationDetails.propTypes = {
  details: PropTypes.any,
  visible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  tradingGroupName: PropTypes.any,
  category: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(NotificationDetails);
