import { withTranslation as withTranslationBase } from 'react-i18next';

export const withTranslation = () => (Component: any) =>
  withTranslationBase()(({ t: originalT, ...props }: any) => {
    const t = (strings: TemplateStringsArray | string, ...values: any[]) => {
      if (!Array.isArray(strings)) {
        return originalT(strings, ...values);
      }

      let key: string = strings[0];

      values.forEach((val, i) => {
        key = `${key}{{${i}}}${strings[i + 1]}`;
      });

      const vars = values.reduce((acc, val, i) => Object.assign(acc, { [`${i}`]: val }), {});
      return originalT(key, vars);
    };
    return <Component {...props} t={t} />;
  });
