// Do not change the order of this enum
export enum WattHour {
  Wh = 'Wh',
  kWh = 'kWh',
  MWh = 'MWh',
  GWh = 'GWh',
  TWh = 'TWh',
  PWh = 'PWh',
}

export enum Weight {
  mg = 'mg',
  g = 'g',
  kg = 'kg',
  t = 't',
  kt = 'kt',
  Mt = 'Mt',
  Gt = 'Gt',
  Tt = 'Tt',
  Pt = 'Pt',
}

export enum CO2Mass {
  tCO2 = 't CO2',
  ktCO2 = 'kt CO2',
  MtCO2 = 'Mt CO2',
  GtCO2 = 'Gt CO2',
  TtCO2 = 'Tt CO2',
  PtCO2 = 'Pt CO2',
}

export type Unit = WattHour | Weight | CO2Mass;

export type WeightUnitToLongNameMapping = {
  [key in Weight]: string;
};
