import React from 'react';
import PropTypes from 'prop-types';

export const CalendarSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    aria-labelledby="title-calendar-icon"
    aria-describedby="desc-calendar-icon"
    role="img"
  >
    <title id="title-calendar-icon">Calendar icon</title>
    <description id="desc-calendar-icon">A symbol representing a calendar.</description>
    <path
      d="M1.49902 13V10.75H3.74902V13H1.49902ZM4.24902 13V10.75H6.74902V13H4.24902ZM1.49902
    10.25V7.75H3.74902V10.25H1.49902ZM4.24902 10.25V7.75H6.74902V10.25H4.24902ZM1.49902
    7.25V5H3.74902V7.25H1.49902ZM7.24902 13V10.75H9.74902V13H7.24902ZM4.24902
    7.25V5H6.74902V7.25H4.24902ZM10.249 13V10.75H12.499V13H10.249ZM7.24902
    10.25V7.75H9.74902V10.25H7.24902ZM4.49902 3.5C4.49902 3.63281 4.38184 3.75 4.24902
    3.75H3.74902C3.61621 3.75 3.49902 3.63281 3.49902 3.5V1.25C3.49902 1.11719 3.61621 0.999999
    3.74902 0.999999H4.24902C4.38184 0.999999 4.49902 1.11719 4.49902 1.25V3.5ZM10.249
    10.25V7.75H12.499V10.25H10.249ZM7.24902 7.25V5H9.74902V7.25H7.24902ZM10.249
    7.25V5H12.499V7.25H10.249ZM10.499 3.5C10.499 3.63281 10.3818 3.75 10.249 3.75H9.74902C9.61621
    3.75 9.49902 3.63281 9.49902 3.5V1.25C9.49902 1.11719 9.61621 0.999999 9.74902
    0.999999H10.249C10.3818 0.999999 10.499 1.11719 10.499 1.25V3.5ZM13.499 3C13.499 2.45312
    13.0459 2 12.499 2H11.499V1.25C11.499 0.562499 10.9365 -9.53674e-07 10.249
    -9.53674e-07H9.74902C9.06152 -9.53674e-07 8.49902 0.562499 8.49902 1.25V2H5.49902V1.25C5.49902
    0.562499 4.93652 -9.53674e-07 4.24902 -9.53674e-07H3.74902C3.06152  -9.53674e-07 2.49902
    0.562499 2.49902 1.25V2H1.49902C0.952148 2 0.499023 2.45312 0.499023 3V13C0.499023 13.5469
    0.952148 14 1.49902 14H12.499C13.0459 14 13.499 13.5469 13.499 13V3Z"
      fill="white"
    />
  </svg>
);

CalendarSVG.defaultProps = {
  width: '14px',
  height: '14px',
};

CalendarSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
