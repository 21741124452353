import React from 'react';
import PropTypes from 'prop-types';

export const SparkzSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 115 171"
    aria-labelledby="title-sparkz"
    aria-describedby="desc-sparkz"
    role="img"
  >
    <title id="title-sparkz">Sparkz icon</title>
    <description id="desc-sparkz">A symbol representing the Sparkz unit.</description>
    <path d="M54.9 100.1L25.8 71 84 12.9 71 0 12.9 58.1 0 71l12.9 13L42 113z" fill="#00cdd7" />
    <path d="M73 58L60 70.9 89.2 100 31 158.1 43.9 171l58.1-58.1 13-12.9-13-13z" fill="#00cdd7" />
  </svg>
);

SparkzSVG.defaultProps = {
  width: '20px',
  height: '30px',
};

SparkzSVG.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
