/* NOTE: Disabling the following as this this component should encompass i18next */
/* eslint-disable i18next/no-literal-string */

import React from 'react';
import { captureException, withScope } from '@sentry/react';

import MyTestImg from './error.svg';

// Would love this to be modules but the loaders are not installed...
import './error.css';

type GlobalErrorBoundaryState = {
  hasError: boolean;
};

/**
 * Performs a reload of the Application, needs to be called separately
 * as it is bound to the Location Instance
 */
const reloadPage = () => window.location.reload();

/**
 * Provides a catch all root error boundary to inform users to attempt to reload the
 * application if something expectant occurs
 *
 * Currently React only supports this functionality within a Class Component with
 * possible hooks coming in the future...
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */
export class GlobalErrorBoundary extends React.Component<any, GlobalErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true });
    withScope((scope) => {
      scope.setExtras(errorInfo);
      captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // Skipping the use of a component library in case that is the cause of the error
      return (
        <div className="errorContainer">
          <div className="errorFloat">
            <MyTestImg />
            <h1>This is Awkward</h1>
            <p>Unfortunately an unexpected issue occurred, please reload the page and try again.</p>
            <button onClick={reloadPage}>Reload Page</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
