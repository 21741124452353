import { ToastContainer } from 'react-toastify';

import { FullScreenLoadingOverlay } from './components';
import MainTooltip from './components/Tooltip/MainTooltip';
import { useHostConfig } from './contexts/host-config-context';
import { SplashPageRouter } from './layouts/routes';
import { AuthedRouteSelector } from './routes/AuthedRouteSelector';
import { useHostTranslation } from './hooks/use-host-translation';

import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import '../fonts.css';
import { useFeatureSet } from './hooks/use-feature-set';

const App = () => {
  const { isLoading } = useHostConfig();
  useHostTranslation();

  const { showEndOfProjectSplashPage } = useFeatureSet();

  if (isLoading) return <FullScreenLoadingOverlay active />;

  if (showEndOfProjectSplashPage) return <SplashPageRouter />;

  return (
    <>
      <ToastContainer />
      <AuthedRouteSelector />
      <MainTooltip />
    </>
  );
};

export default App;
