import React from 'react';
import PropTypes from 'prop-types';

const NotificationInfo = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 30 31" fill="none">
    <g filter="url(#filter0_d)">
      <path
        d="M15 0C23.2837 0 30 6.71537 30 15C30 23.2846 23.2837 30 15 30C6.71449 30 0 23.2846 0
      15C0 6.71537 6.71449 0 15 0ZM15 27.6562C21.9891 27.6562 27.6562 21.9901 27.6562 15C27.6562
      8.00994 21.9891 2.34375 15 2.34375C8.00906 2.34375 2.34375 8.00994 2.34375 15C2.34375 21.9901
      8.00906 27.6562 15 27.6562Z"
        fill="white"
      />
      <path
        d="M13.6597 22.5V12.0745H16.3636V22.5H13.6597ZM16.6666 9.03192C16.6666 9.44326 16.5035
      9.79787 16.1771 10.0957C15.8663 10.3936 15.4701 10.5426 14.9883 10.5426C14.5377 10.5426
      14.1492 10.3936 13.8228 10.0957C13.4965 9.79787 13.3333 9.44326 13.3333 9.03192C13.3333
      8.60638 13.4965 8.24468 13.8228 7.94681C14.1492 7.64894 14.5377 7.5 14.9883 7.5C15.4701 7.5
      15.8663 7.65603 16.1771 7.96808C16.5035 8.26596 16.6666 8.62057 16.6666 9.03192Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="30"
        height="31"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
        127 0"
        />
        <feOffset dy="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

NotificationInfo.defaultProps = {
  width: '30px',
  height: '30px',
};

NotificationInfo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default NotificationInfo;
