import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from 'theme-ui';
import { useTranslation } from 'react-i18next';

import { useHostConfig } from '../contexts/host-config-context';

const Icon = styled.img`
  display: inline-block;
  height: 40px;
  width: 40px;
`;

interface NumberTextProps {
  value: string | number;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: boolean;
  renderText?: (props: any) => void;
  inlined?: boolean;
}

const NumberText: React.FC<NumberTextProps> = ({ value, prefix = '', suffix = '', inlined = false }) => {
  const { t } = useTranslation();
  const { hostHasFlag } = useHostConfig(true);
  const isVB = hostHasFlag('isVB');

  // if the host is VB use absolute value so it doesn't look like the user owes beer to CUB
  const absValue = useMemo(
    () =>
      isVB
        ? String(value).replace(/^-/g, '') // Abs value function that retains formatting
        : value,
    [isVB, value]
  );

  const translatedPrefix = t(`number.format.prefix`, { prefix });

  const PrefixComponent = useMemo(() => {
    if (!prefix) {
      return null;
    }
    return translatedPrefix.startsWith('url:') ? (
      <Icon src={translatedPrefix.replace('url:', '')} alt="number prefix symbol" />
    ) : (
      <span>{translatedPrefix}</span>
    );
  }, [prefix, translatedPrefix]);

  return (
    <Box sx={{ display: inlined ? 'inline-flex' : 'flex', alignItems: 'center' }}>
      {PrefixComponent}
      <span>
        {absValue} {suffix}
      </span>
    </Box>
  );
};

export default NumberText;
