export enum AuthStatus {
  // Service is yet to be initiated or the client is starting for the first time
  INIT = 'init',

  // Current session is not authenticated
  NOT_AUTHENTICATED = 'not_authed',

  // Current session is authenticated
  AUTHENTICATED = 'authenticated',
}

export enum SignInResponse {
  // User authenticated successfully
  SUCCESS = 'success',

  // Account requires a password reset, please use Password Reset flow
  PW_EXPIRED = 'pw_expired',

  // Authentication requires MFA, please use `verifyMFA` to continue Auth
  REQ_MFA = 'req_mfa',

  // User requires an MFA device to be enrolled under their account
  REQ_MFA_ENROLL = 'req_mfa_enroll',

  // Account is either disabled or locked out
  LOCKED_OUT = 'locked_out',

  // Invalid credentials given
  INVALID_CRED = 'invalid_cred',
}

export enum SignInMFAResponse {
  // User authenticated successfully with MFA
  SUCCESS = 'success',
}

export enum RequestPasswordResetResponse {
  // Password reset was successfully performed
  SUCCESS = 'success',
}

export enum VerifyPasswordResetResponse {
  // Verification for password reset was successfully performed
  SUCCESS = 'success',

  // An invalid password reset code was given
  INVALID_CODE = 'invalid_code',
}

export enum PasswordUpdateResponse {
  SUCCESS = 'success',
  INVALID_CRED = 'invalid_cred',
}

export type AuthServiceUser = {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
};

export type TokenResponse = {
  accessToken: string;
  idToken: string;
} | null;

export type AuthCallbackFunctions = {
  loginCallback: (params: Record<string, string | undefined | null>) => Promise<void>;
  logoutCallback: (params: Record<string, string | undefined | null>) => Promise<void>;
};

export type AuthService = {
  /**
   * Describes the current state of the Auth Service, this could be one of the following:
   *
   * - **INIT** - Service is yet to be initiated or the client is starting for the first time
   * ⚠️ Please note that the service could display INIT even while no config is given...
   *
   * - **NOT_AUTHENTICATED** - Current session is not authenticated
   *
   * - **AUTHENTICATED** - Current session is authenticated
   *
   */
  status: AuthStatus;

  /**
   * Shortcut function to determine if the current session is authenticated.
   *
   * This is the same as running as performing the following:
   * ```ts
   * status === AuthStatus.AUTHENTICATED
   * ```
   */
  isAuthenticated: () => boolean;

  /**
   * Shortcut function to determine if the adaptor is still initialising.
   *
   * This is the same as running as performing the following:
   * ```ts
   * status === AuthStatus.INIT
   * ```
   */
  isLoading: () => boolean;

  /**
   * Basic user authentication flow using a username and password. This will return a response
   * of the given type:
   *
   *  - **SUCCESS** - Authentication was successful, this is usually followed up with a status change
   *
   *  - **PW_EXPIRED** - Users password has expired, follow through with `// TODO: impl fn here` to set new password
   *
   *  - **REQ_MFA** - Users account has active MFA and requires validation. This can be done using the `verifyMFA()` function
   *
   *  - **REQ_MFA_ENROLL** - Users account requires MFA to be setup before continuing. This be
   *
   *  - **LOCKED_OUT** - Users account is currently locked out. // NOTE: This will be expanded soon
   *
   *  - **INVALID_CRED** - Credentials given by the user are invalid...
   *
   */
  signIn: (credentials: { username: string; password: string }) => Promise<SignInResponse>;

  /**
   * Authentication flow to be able to allow users to authenticate using an external
   * Identity provider. This will generally lead up to a re-direct or a popup-window
   * requesting the authentication of the user
   */
  signInWithIDP: (idpId: string) => Promise<void>;

  /**
   * Signs the current session out, this will be confirmed with a status change from
   * `AUTHENTICATED` -> `NOT_AUTHENTICATED`
   */
  signOut: () => Promise<void>;

  /**
   * Initiates a password reset flow for the given username. The flow can be completed by using the
   * `verifyPasswordReset` function by passing the sent code and new password.
   */
  requestPasswordReset: (opts: { username: string }) => Promise<RequestPasswordResetResponse>;

  /**
   * Completes the password reset flow for a given code.
   */
  verifyPasswordReset: (credentials: {
    recoveryToken: string;
    password: string;
  }) => Promise<VerifyPasswordResetResponse>;

  /**
   * Returns the **Access** and **Identity** tokens active for the current session. If the current stored
   * tokens have expired, this function will wait until a refresh has occurred, then return the new tokens.
   */
  getTokens: () => Promise<TokenResponse>;

  /**
   * Performs an update of password for the current user
   */
  updatePassword: (credentials: {
    oldPassword: string;
    newPassword: string;
  }) => Promise<PasswordUpdateResponse>;

  /**
   * Returns a series of callback functions designed to handle
   * redirect authentication flows
   */
  useAuthCallbackHandler: () => AuthCallbackFunctions;

  // NOTE: Add setupMFA Function
  // NOTE: Add verifyMFA Function
  // NOTE: Add removeMFA Function
};
