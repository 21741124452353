import { useMemo, useState } from 'react';
import { Datum, ResponsiveLine, Serie } from '@nivo/line';
import { LegendProps } from '@nivo/legends';
import { Interval } from 'luxon';
import chunk from 'lodash/chunk';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { Box } from 'theme-ui';

import { AssetType, TradeUnit } from '../../../../../types';
import { ChartNavArrows } from '../chart-nav-arrows';
import { checkIfChartDataIsValid, formatAxisLeftValue, determineTimeScale } from '../charts.utils';
import { ChartPlaceholder } from '../chart-placeholder';
import { parseDateWithTimeZone } from '../../../lib/datetime-helpers';
import { useThemedColorScale } from '../../../hooks/use-themed-color-scale';
import { useTimeFramePeriodOffset } from '../../../hooks/use-date-switcher';
import { useDynamicGraphTicks } from '../hooks';
import { useAppSelector } from '../../../hooks/use-app-selector';
import { Choose, When } from '../..';
import { useFeatureSet } from '../../../hooks/use-feature-set';
import { useScreenSize } from '../../../hooks/use-screen-size';
import { useUsageChartData } from './useUsageChartData';
import { useStandardLineChartConfig } from './useStandardLineChartConfig';
import { UsageChartTooltip } from './UsageChartTooltip';

interface UsageData {
  dateTime: string;
  estimate: boolean;
  totalImport: number;
  totalExport: number;
}

type UsageDataIds = 'import' | 'export';

interface UsageConfigData {
  usages: UsageData[];
  timeZone: string;
  startDate: string;
  endDate: string;
  t: any;
  hiddenIds: UsageDataIds[];
  colorScheme: string[];
  chunkSize: number;
  isConsumer: boolean;
  isProducer: boolean;
}

const formatUsageData = ({
  usages,
  timeZone,
  startDate,
  endDate,
  chunkSize,
  t,
  isConsumer,
  isProducer,
  hiddenIds,
  colorScheme,
}: UsageConfigData): { data: Serie[]; legendData: any[] } => {
  const parsedStart = parseDateWithTimeZone(startDate, timeZone);
  const parsedEnd = parseDateWithTimeZone(endDate, timeZone);

  const legendData: LegendProps['data'] = [];
  if (!isProducer)
    legendData.push({
      color: hiddenIds.includes('import') ? 'rgba(1, 1, 1, .1)' : colorScheme[0],
      id: 'import',
      label: t('transaction.chart.import', { defaultValue: 'Import' }),
    });

  if (!isConsumer)
    legendData.push({
      color: hiddenIds.includes('export') ? 'rgba(1, 1, 1, .1)' : colorScheme[1],
      id: 'export',
      label: t('transaction.chart.export', { defaultValue: 'Export' }),
    });

  const data = chunk(usages, chunkSize)
    .reduce<Serie[]>(
      (acc, chunkArr) => {
        const { dateTimes, totalImportSum, totalExportSum } = chunkArr.reduce<{
          dateTimes: number[];
          totalImportSum: number;
          totalExportSum: number;
        }>(
          (sumAcc, usage) => {
            /* eslint-disable no-param-reassign */
            sumAcc.dateTimes = [
              ...sumAcc.dateTimes,
              parseDateWithTimeZone(usage.dateTime, timeZone).toMillis(),
            ];
            sumAcc.totalImportSum += usage.totalImport;
            sumAcc.totalExportSum += usage.totalExport;
            /* eslint-enable no-param-reassign */
            return sumAcc;
          },
          { dateTimes: [], totalImportSum: 0, totalExportSum: 0 }
        );

        const dateTime = parseDateWithTimeZone(Math.min(...dateTimes), timeZone);
        const totalImport = totalImportSum / chunkArr.length;
        const totalExport = totalExportSum / chunkArr.length;

        if (!Interval.fromDateTimes(parsedStart, parsedEnd).contains(dateTime)) {
          return acc;
        }
        if (!isProducer) {
          acc[0].data.push({ x: dateTime.toMillis(), y: totalImport });
        }
        if (!isConsumer) {
          acc[1].data.push({ x: dateTime.toMillis(), y: totalExport });
        }
        return acc;
      },
      [
        {
          id: 'import',
          data: [],
        },
        {
          id: 'export',
          data: [],
        },
      ]
    )
    .filter(({ id }) => !hiddenIds.includes(id as UsageDataIds));
  return { data, legendData };
};

export const UsageChart = ({ loading = false }) => {
  const { t } = useTranslation();
  const breakpointIndex = useBreakpointIndex();
  const { useEUTimeFormat } = useFeatureSet();
  const { isSmallDown } = useScreenSize();

  const { colorScheme, chartTheme, chartConfig } = useThemedColorScale();

  const selectedMeterType: AssetType | undefined = useAppSelector(
    ({ controls }) => controls?.selectedMeter?.assetType,
    shallowEqual
  );

  const isConsumer = selectedMeterType === AssetType.CONSUMER;
  const isProducer = selectedMeterType === AssetType.PRODUCER;

  const { timeZone, startDate, endDate, usages } = useUsageChartData();

  const { ref, chunkSize, width } = useDynamicGraphTicks(usages, chartConfig.line.tickWidth);

  const [hiddenIds, setHiddenIds] = useState<UsageDataIds[]>([]);
  const { onPrevious, onNext } = useTimeFramePeriodOffset();

  const { data, legendData } = useMemo(
    () =>
      formatUsageData({
        usages,
        timeZone,
        startDate,
        endDate,
        chunkSize,
        t,
        hiddenIds,
        isConsumer,
        isProducer,
        colorScheme,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chunkSize, colorScheme, hiddenIds, t, timeZone, isConsumer, isProducer, usages]
  );

  const { isDayFormat, ...xScaleProps } = useMemo(
    () =>
      determineTimeScale({
        startDate,
        endDate,
        timeZone,
        width,
        numValues: data[0]?.data?.length,
        isEUTimeFormat: useEUTimeFormat,
      }),
    [data, endDate, startDate, timeZone, width, useEUTimeFormat]
  );

  const { legends, ...lineProps } = useStandardLineChartConfig({
    width,
    legendData,
  });

  const chartLegends = useMemo(
    () =>
      legends.map(({ legendData: legendDataSubSet, ...legend }: any) => ({
        ...legend,
        data: legendDataSubSet,
        onClick: (datum: any) => {
          setHiddenIds((state) => {
            if (state && !state.includes(datum.id)) {
              return [datum.id];
            }
            return state.includes(datum.id)
              ? state.filter((item) => item !== datum.id)
              : [...state, datum.id];
          });
        },
      })),
    [legends]
  );

  const Tooltip = useMemo(
    () =>
      ({ point }: { point: any }) => {
        const displayedData: { import?: number; export?: number; epochTime: number } = {
          epochTime: point.data.x,
        };

        data.forEach((usageData) => {
          const foundDataPoint = usageData.data.find(
            (usageDataPoint: Datum) => usageDataPoint.x === point.data.x
          );

          if (foundDataPoint) {
            displayedData[usageData.id as keyof typeof displayedData] = foundDataPoint.y as number;
          }
        });

        return (
          <UsageChartTooltip
            importValue={displayedData.import}
            exportValue={displayedData.export}
            epochTime={displayedData.epochTime}
            timeZone={timeZone}
          />
        );
      },
    [timeZone, data]
  );

  const doesChartHaveValidData = useMemo(
    () => data.some((item) => checkIfChartDataIsValid(item.data)),
    [data]
  );

  const dataNotYetFormatted = useMemo(() => {
    return usages.length && (!data.length || data.every((item) => !item.data.length));
  }, [data, usages]);

  const hideChartData = loading || dataNotYetFormatted || !doesChartHaveValidData;
  const chartData = hideChartData ? [] : data;

  return (
    <Box
      sx={{
        height: 350,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: isSmallDown ? 4 : 3,
        alignItems: 'right',
      }}
      ref={ref}
    >
      <Choose>
        <When condition={loading || dataNotYetFormatted}>
          <ChartPlaceholder label="Loading..." />
        </When>
        <When condition={!doesChartHaveValidData}>
          <ChartPlaceholder />
        </When>
      </Choose>
      <ResponsiveLine
        {...lineProps}
        {...xScaleProps}
        margin={{ ...lineProps.margin, left: 64, right: 32 }}
        axisLeft={{
          ...lineProps.axisLeft,
          tickValues: 5,
          tickSize: 0,
          tickPadding: 4,
          format: (value: number) => formatAxisLeftValue(value, TradeUnit.USAGE),
        }}
        gridYValues={5}
        data={chartData}
        theme={chartTheme}
        tooltip={Tooltip}
        legends={chartLegends}
        motionConfig={{
          mass: 1,
          tension: 500,
          friction: 1,
          clamp: true,
          precision: 0.01,
          velocity: 0,
        }}
      />
      <ChartNavArrows onClickLeft={onPrevious} onClickRight={onNext} placeNavArrowsBelowChart={isSmallDown} />
    </Box>
  );
};
