import { LogoPlSVGLazy } from '@power-ledger/assets';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Flex, Heading, Paragraph } from 'theme-ui';

import { FullPageFormLayout, LoadingOutlined } from '../../../components';
import { useHostConfig } from '../../../contexts/host-config-context';
import { useLanguageOverride } from '../../../hooks/use-language-override';
import { useTranslatedFile } from '../../../hooks/use-translated-file';
import { errorNotification } from '../../../lib/notifications';
import * as api from '../../../middleware/api';
import { loadUserProfile } from '../../../states/slices/auth';

export const AcceptTerms = ({ username }: { username: string }) => {
  const { t } = useTranslation();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { lngOverride, toggleLngOverride, browserIsEnglish } = useLanguageOverride();
  const { hostHasFlag } = useHostConfig(true);
  const isVB = hostHasFlag('isVB');
  const dispatch = useDispatch();

  const toggleTermsButtonText = lngOverride ? t('View in local language') : t('View in English');
  const { File: Terms, loading } = useTranslatedFile('translation', 'termsOfAccess', lngOverride);

  const handleScroll = useCallback((e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom) {
      setHasScrolled(true);
    }
  }, []);

  const acceptTerms = useCallback(async () => {
    setIsSubmitting(true);

    const { body, status } = await api.acceptTerms(username);
    if (status !== 204) {
      errorNotification({
        errorCode: body.errorCode,
        description: t('An unexpected error occured. Please try again or contact an administrator.'),
      });
    }
    await dispatch(loadUserProfile());
  }, [dispatch, username, t]);

  return (
    <FullPageFormLayout sx={{ maxHeight: '100vh', py: 5 }} noContainer>
      <Flex
        as="main"
        sx={{
          flexDirection: 'column',
          maxWidth: '720px',
          width: '100%',
          height: '100%',
          backgroundColor: 'background',
          padding: 4,
          borderRadius: 5,
        }}
      >
        <Box my={3}>
          <Flex sx={{ justifyContent: 'center', alignItems: 'center', mb: 3 }}>
            <LogoPlSVGLazy width="203px" height="70px" />
          </Flex>
          {isVB && <Heading as="h1">{t('Terms and conditions')}</Heading>}
          <Paragraph>
            <Trans t={t}>
              Please read all of the following details carefully. By clicking{' '}
              <strong>Agree and log in</strong>, you agree to these terms and conditions.
            </Trans>
          </Paragraph>
        </Box>
        <Box
          sx={{
            overflowY: 'scroll',
            my: 3,
            p: 2,
            borderRadius: 3,
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: 'muted',
            fontSize: '0.8em',
          }}
          onScroll={handleScroll}
        >
          <div style={{ paddingBottom: 50 }}>{loading ? null : Terms}</div>
        </Box>
        <Flex
          sx={{
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          {!browserIsEnglish && (
            <Button
              my={2}
              mx={1}
              type="submit"
              onClick={toggleLngOverride}
              sx={{ flex: '1 1 auto', width: '45%', minWidth: 10 }}
            >
              {toggleTermsButtonText}
            </Button>
          )}
          <Button
            my={2}
            mx={1}
            type="submit"
            disabled={!hasScrolled || (hasScrolled && isSubmitting)}
            onClick={acceptTerms}
            sx={{ flex: '1 1 auto', width: '45%', minWidth: 10 }}
          >
            {isSubmitting ? <LoadingOutlined color="background" /> : t('Agree and log in')}
          </Button>
        </Flex>
      </Flex>
    </FullPageFormLayout>
  );
};

export default AcceptTerms;
