import React, { createContext } from 'react';
import { AuthAdaptor, AuthAdaptorBase } from './adaptors/adaptor-types';
import { useAuthServiceLogic } from './auth-service-logic';
import { AuthService } from './auth-service-types';

export const AuthServiceContext = createContext<AuthService | null>(null);
AuthServiceContext.displayName = 'PL_Auth_Service';

export const AuthServiceProvider: <T extends AuthAdaptorBase>(props: {
  children: React.ReactNode;
  adaptor: AuthAdaptor<T>;
  config: T['config'] | null;
}) => React.ReactElement = ({ children, config, adaptor }) => {
  const value = useAuthServiceLogic(adaptor, config);
  return <AuthServiceContext.Provider value={value}>{children}</AuthServiceContext.Provider>;
};
