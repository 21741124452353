import { FC } from 'react';
import { Textarea as ThemeTextarea } from 'theme-ui';

export type TextareaProps = {
  rows?: number;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (newValue: string | undefined) => void;
  value?: string;
};

export const Textarea: FC<TextareaProps> = ({ value, rows, name, placeholder, disabled, onChange }) => (
  <ThemeTextarea
    value={value}
    rows={rows}
    name={name}
    disabled={disabled}
    placeholder={placeholder}
    onChange={(event: any) => onChange && onChange(event?.target?.value)}
    style={{ resize: 'none' }}
  />
);
