/** @jsxImportSource theme-ui */
import { Flex, ThemeUIStyleObject, Text, Box } from 'theme-ui';
import { FC, useEffect, useMemo, useState } from 'react';
import { intervalToDuration, isBefore, add } from 'date-fns';
import { useTranslation } from 'react-i18next';

export type CountDownProps = {
  time: string | Date;
  datePassedMessage: string;
  style?: ThemeUIStyleObject;
  boxColor?: string;
};

type TimeType = {
  hours: number | undefined;
  minutes: number | undefined;
  seconds: number | undefined;
};

export const CountDown: FC<CountDownProps> = ({ time, style, boxColor, datePassedMessage }) => {
  const [timeUntil, setTimeUntil] = useState<TimeType>();
  const [timeLeft, setTimeLeft] = useState<number>();
  const [inThePast, setInThePast] = useState(false);
  const { t } = useTranslation();

  useMemo(() => {
    if (Date.parse(time.toString())) {
      const now = new Date();
      // Calculate timezone offset from UTC time zone
      const offset = now.getTimezoneOffset();
      isBefore(new Date(time), add(now, { minutes: offset })) && setInThePast(true);
      const { hours, minutes, seconds } = intervalToDuration({
        start: add(now, { minutes: offset }),
        end: new Date(time),
      });
      // If page is loaded at exact interval time set seconds to 24 hours
      !hours && !minutes && !seconds && setTimeLeft(86400);
      const secondsUntil =
        (hours ? hours * 3600 : 0) + (minutes ? minutes * 60 : 0) + (seconds ? seconds : 0);
      setTimeLeft(secondsUntil);
      // Slicing to cut out the unnecessary date info from string e.g. 1970-01-01T19:59:17.000Z -> 19:59:17
      const [h, m, s] = new Date(secondsUntil * 1000).toISOString().slice(11, 19).split(':');
      setTimeUntil({
        hours: parseInt(h),
        minutes: parseInt(m),
        seconds: parseInt(s),
      });
    } else {
      setInThePast(true);
      setTimeUntil({ hours: 0, minutes: 0, seconds: 0 });
    }
  }, [time]);

  useEffect(() => {
    timeLeft === 0 && setTimeLeft(86400);
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      const hhmmss = new Date(timeLeft * 1000).toISOString().slice(11, 19).split(':');
      setTimeUntil({
        hours: parseInt(hhmmss[0]),
        minutes: parseInt(hhmmss[1]),
        seconds: parseInt(hhmmss[2]),
      });
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const commonStyle = {
    width: 7,
    height: 7,
    borderRadius: 4,
    fontSize: 3,
    backgroundColor: boxColor ? boxColor : 'inactiveLight',
    p: 2,
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Box>
      {inThePast && <Text sx={{ color: 'warning', fontSize: 1 }}>{t(datePassedMessage)}</Text>}
      {timeUntil && (
        <Flex sx={{ alignItems: 'center', ...style }} data-testid="countDown">
          <Flex sx={{ ...commonStyle, mr: 2 }}>
            <time dateTime={`PT${timeUntil?.hours}H`}>{inThePast ? '00' : timeUntil?.hours}</time>
          </Flex>
          :
          <Flex sx={{ ...commonStyle, mx: 2 }}>
            <time dateTime={`PT${timeUntil?.minutes}M`}>{inThePast ? '00' : timeUntil?.minutes}</time>
          </Flex>
          :
          <Flex sx={{ ...commonStyle, ml: 2 }}>
            <time dateTime={`PT${timeUntil?.seconds}S`}>{inThePast ? '00' : timeUntil?.seconds}</time>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
