import { FC } from 'react';
import { Label, Flex, Box, Text, ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '..';

import ErrorMessage from './ErrorMessage';

export type FormFieldLabelProps = {
  name?: string;
  hasErrorMessage?: boolean;
  label?: string;
  wrapLabel?: boolean;
  small?: boolean;
  sx?: ThemeUIStyleObject;
  info?: string;
};

export const FormFieldLabel: FC<FormFieldLabelProps> = ({
  name = '',
  hasErrorMessage = true,
  label,
  wrapLabel = false,
  info,
  sx = {},
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Label id={name} sx={sx}>
      <Flex sx={{ alignItems: 'center', flexShrink: wrapLabel ? 1 : 0, mb: 3 }}>
        {label}
        {info && (
          <Tooltip
            id={`form-field-label-${label}-tooltip`}
            content={<Text>{t(info)}</Text>}
            sx={{
              ml: 1,
            }}
          />
        )}
      </Flex>
      {children}
      {name && hasErrorMessage && <ErrorMessage name={name} sx={{ textAlign: 'left', mt: 3 }} />}
    </Label>
  );
};

export default FormFieldLabel;
