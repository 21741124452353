import {
  UPDATE_TRANSACTION_STATISTICS,
  UPDATE_TRANSACTION_TOTALS,
  UPDATE_TABLE_TRANSACTIONS,
  RESET_TRANSACTIONS,
} from '../constants';
import { initialState } from '../store/initial-state';

// Transactions reducer
const transactions = (state = initialState.transactions, action) => {
  switch (action.type) {
    case UPDATE_TRANSACTION_STATISTICS:
      return {
        ...state,
        p2pTrade: action.p2pTrade,
        gridTrade: action.gridTrade,
        renewablesTrade: action.renewablesTrade,
        vppTrade: action.vppTrade,
        lp2pTrade: action.lp2pTrade,
        ppaTrade: action.ppaTrade,
        topSellers: action.topSellers,
        topBuyers: action.topBuyers,
        intervals: action.intervals,
        dailyFeeAH: action.dailyFeeAH,
        transactionFeeAH: action.transactionFeeAH,
        dailyFeePL: action.dailyFeePL,
        transactionFeePL: action.transactionFeePL,
        dailyFeeOther: action.dailyFeeOther,
        transactionFeeOther: action.transactionFeeOther,
        transactionSummaries: action.transactionSummaries,
      };
    case UPDATE_TRANSACTION_TOTALS:
      return {
        ...state,
        totals: action.data,
      };
    case UPDATE_TABLE_TRANSACTIONS:
      return {
        ...state,
        tableTransactions: action.tableTransactions,
        totalTableTransactions: action.totalTableTransactions,
      };
    case RESET_TRANSACTIONS:
      return initialState.transactions;
    default:
      return state;
  }
};

export default transactions;
