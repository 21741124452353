import { useState, useEffect } from 'react';
import { Box, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { DropdownArrowSVG } from '@power-ledger/assets';

import { Modal, Button, Select } from '../..';
import { toggleIOAEstimate } from '../../../states/actions/settings';
import { useAppDispatch } from '../../../hooks/use-app-dispatch';
import { useAppSelector } from '../../../hooks/use-app-selector';

export const IOASettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isEstimate = useAppSelector(({ settings }) => settings.isEstimate);
  const [isVisible, setIsVisible] = useState(false);
  const [localIsEstimate, setLocalIsEstimate] = useState(isEstimate);

  useEffect(() => {
    setLocalIsEstimate(isEstimate);
  }, [isEstimate]);

  const toggleModal = (visible: boolean) => setIsVisible(visible);

  const updateSettings = (event: React.MouseEvent) => {
    event.persist();
    dispatch(toggleIOAEstimate(localIsEstimate));
    toggleModal(false);
  };

  const estimatesOptions = [
    { value: true, label: t('Show day ahead readings') },
    { value: false, label: t('Show real-time readings') },
  ];

  return (
    <>
      <Button
        data-testid="display-settings"
        variant="secondary"
        sx={{ p: 0 }}
        onClick={() => toggleModal(true)}
      >
        <Box>{isEstimate ? 'Day ahead readings' : 'Real-time readings'}</Box>
        <Box
          sx={{
            marginLeft: 2,
            display: 'inline-flex',
            alignItems: 'center',
            minWidth: '8px',
            svg: {
              minWidth: '8px',
              minHeight: '6px',
            },
          }}
        >
          <DropdownArrowSVG width="8px" />
        </Box>
      </Button>
      <Modal
        title={t('Display settings')}
        visible={isVisible}
        onOk={(e) => updateSettings(e)}
        okButtonContent={t('Apply')}
        onCancel={() => toggleModal(false)}
        afterClose={() => setLocalIsEstimate(isEstimate)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Text>{t('Data sources')}</Text>
          <Text as="small" variant="small">
            {t('note.ioa-estimated-readings')}
          </Text>
          <Select
            value={estimatesOptions.find(({ value }) => value === localIsEstimate)}
            sx={{ width: '100%', marginTop: 3 }}
            options={estimatesOptions}
            menuPosition="fixed"
            isSearchable={false}
            onChange={({ value }: { value: boolean }) => setLocalIsEstimate(value)}
          />
        </Box>
      </Modal>
    </>
  );
};
